import { chatSlice } from '@redux/reducers/chatSlice'
import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '@services/api'

export const getAdminMessages = createAsyncThunk(
  'chat/adminMessages',
  async (id?: number | string) => {
    const response = await api.client.getAdminMessages(id)
    return response.data
  }
)

export const getAdminNewMessages = createAsyncThunk(
  'chat/adminMessages',
  async (id?: number | string) => {
    const response = await api.client.getAdminNewMessages(id)
    return response.data
  }
)

export const getUserMessages = createAsyncThunk('chat/userMessages', async () => {
  const response = await api.client.getUserMessages()
  return response.data
})

export const getUserNewMessages = createAsyncThunk('chat/userMessages', async () => {
  const response = await api.client.getUserNewMessages()
  return response.data
})
