import {
  Gender,
  MaritalStatus,
  MethodApplication,
  NationalPage,
  DirectionTrainingPage,
  PersonalDataResponse,
  TypeIdentityDocument,
  RelationDegree,
  PassportResponse,
  FormEducation,
  LevelEducation,
  TypeDocEducation,
  EducationResponse,
  ResidenceResponse,
  LegalRepresentativeResponse,
  PhoneCodePage,
  LocationPage,
  SubjectsRussia,
  KladrPage,
  TypePreviousEduDocument,
  VisaResponse,
  VisaCity,
  VisaCountryPage,
  ForeignLanguage,
  TypeIdentityDocumentRus,
  PartnerPage,
} from '@models'
import {
  getFormEducation,
  getGenders,
  getWorks,
  getLevelEducation,
  getLocations,
  getSubjectsRussia,
  getMaritalStatuses,
  getMethodApplication,
  getNationals,
  getNationalsRus,
  getNationalsIno,
  getDirectionTrainings,
  getPassportData,
  getPersonalData,
  getPhoneCodes,
  getProfileEducation,
  getRelationDegrees,
  getResidence,
  getTypeDocEducation,
  getTypeIdentityDocuments,
  getTypeIdentityDocumentsRus,
  getKladr,
  getKladrRegion,
  getKladrDistrict,
  getKladrCity,
  getKladrStreet,
  getLegalRepresentative,
  getKladrHouse,
  getPreviousEduForMastersDocument,
  getVisaCity,
  getVisaCountry,
  getForeignLanguage,
  getVisa,
  getPartnersList,
  getPassportKeys,
  getScenarioKeys,
  getLevelEducationKeys,
} from '@redux/action-types'
import { StateStatus } from '@redux/types'
import {
  ActionReducerMapBuilder,
  AsyncThunk,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import { ResponsePage } from 'types/custom-types'
import api from '@services/api'

type ProfileStateEntity<D> = {
  data: D | null
  status: StateStatus
  error: unknown
}

type PaginatedStateEntity<D> = {
  data: D
  status: StateStatus
  error: unknown
  nextPage: number
}

type ProfileInitialState = {
  entities: {
    visa: ProfileStateEntity<VisaResponse>
    genders: ProfileStateEntity<Gender[]>
    works: ProfileStateEntity<any>
    foreignLanguage: ProfileStateEntity<ForeignLanguage[]>
    residence: ProfileStateEntity<ResidenceResponse>
    nationals: PaginatedStateEntity<NationalPage['data']>
    directionTraining: PaginatedStateEntity<DirectionTrainingPage['data']>
    nationalsRus: PaginatedStateEntity<NationalPage['data']>
    nationalsIno: PaginatedStateEntity<NationalPage['data']>
    location: PaginatedStateEntity<LocationPage['data']>
    subjectsRussia: PaginatedStateEntity<SubjectsRussia['data']>
    kladr: PaginatedStateEntity<KladrPage['data']>
    kladrRegion: PaginatedStateEntity<KladrPage['data']>
    kladrDistrict: PaginatedStateEntity<KladrPage['data']>
    kladrCity: PaginatedStateEntity<KladrPage['data']>
    kladrStreet: PaginatedStateEntity<KladrPage['data']>
    kladrHouse: PaginatedStateEntity<KladrPage['data']>
    visaCities: ProfileStateEntity<VisaCity[]>
    phoneCodes: PaginatedStateEntity<PhoneCodePage['data']>
    personalData: ProfileStateEntity<PersonalDataResponse>
    passportData: ProfileStateEntity<PassportResponse[]>
    formEducation: ProfileStateEntity<FormEducation[]>
    visaCountries: PaginatedStateEntity<VisaCountryPage['data']>
    levelEducation: ProfileStateEntity<LevelEducation[]>
    relationDegrees: ProfileStateEntity<RelationDegree[]>
    maritalStatuses: ProfileStateEntity<MaritalStatus[]>
    typeDocEducation: ProfileStateEntity<TypeDocEducation[]>
    profileEducation: ProfileStateEntity<EducationResponse[]>
    methodApplication: ProfileStateEntity<MethodApplication[]>
    legalRepresentative: ProfileStateEntity<LegalRepresentativeResponse>
    typeIdentityDocuments: ProfileStateEntity<TypeIdentityDocument[]>
    typeIdentityDocumentsRus: ProfileStateEntity<TypeIdentityDocumentRus[]>
    previousEduForMasters: ProfileStateEntity<TypePreviousEduDocument[]>
    partners: PaginatedStateEntity<PartnerPage['data']>
    typeDocs: ProfileStateEntity<any>
    scenarioKeys: ProfileStateEntity<any>
    levelEducationKeys: ProfileStateEntity<any>
  }
  showMobileMenu: boolean
}

const initialProfileEntityState = {
  data: null,
  status: 'idle',
  error: null,
} as const

const initialPaginatedEntityState = {
  ...initialProfileEntityState,
  data: [],
  nextPage: 1,
}

const initialState: ProfileInitialState = {
  entities: {
    foreignLanguage: initialProfileEntityState,
    visa: initialProfileEntityState,
    genders: initialProfileEntityState,
    works: initialProfileEntityState,
    residence: initialProfileEntityState,
    nationals: initialPaginatedEntityState,
    nationalsRus: initialPaginatedEntityState,
    nationalsIno: initialPaginatedEntityState,
    location: initialPaginatedEntityState,
    subjectsRussia: initialPaginatedEntityState,
    phoneCodes: initialPaginatedEntityState,
    personalData: initialProfileEntityState,
    kladr: initialPaginatedEntityState,
    kladrRegion: initialPaginatedEntityState,
    kladrDistrict: initialPaginatedEntityState,
    kladrCity: initialPaginatedEntityState,
    kladrStreet: initialPaginatedEntityState,
    kladrHouse: initialPaginatedEntityState,
    visaCities: initialProfileEntityState,
    passportData: initialProfileEntityState,
    formEducation: initialProfileEntityState,
    visaCountries: initialPaginatedEntityState,
    levelEducation: initialProfileEntityState,
    directionTraining: initialPaginatedEntityState,
    relationDegrees: initialProfileEntityState,
    maritalStatuses: initialProfileEntityState,
    profileEducation: initialProfileEntityState,
    typeDocEducation: initialProfileEntityState,
    methodApplication: initialProfileEntityState,
    legalRepresentative: initialProfileEntityState,
    typeIdentityDocuments: initialProfileEntityState,
    typeIdentityDocumentsRus: initialProfileEntityState,
    previousEduForMasters: initialPaginatedEntityState,
    partners: initialPaginatedEntityState,
    typeDocs: initialProfileEntityState,
    scenarioKeys: initialProfileEntityState,
    levelEducationKeys: initialProfileEntityState,
  },
  showMobileMenu: false,
}
export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    changeMobileMenu: (state) => {
      state.showMobileMenu = !state.showMobileMenu
    },
  },
  extraReducers(builder) {
    setupExtraReducer(builder, getVisa, 'visa')

    setupExtraReducer(builder, getGenders, 'genders')
    setupExtraReducer(builder, getWorks, 'works')
    setupExtraReducer(builder, getNationals, 'nationals', { paginated: true })
    setupExtraReducer(builder, getDirectionTrainings, 'directionTraining', {
      paginated: true,
    })
    setupExtraReducer(builder, getForeignLanguage, 'foreignLanguage')
    setupExtraReducer(builder, getNationalsRus, 'nationalsRus', { paginated: true })
    setupExtraReducer(builder, getNationalsIno, 'nationalsIno', { paginated: true })
    setupExtraReducer(builder, getLocations, 'location', { paginated: true })
    setupExtraReducer(builder, getSubjectsRussia, 'subjectsRussia', {
      paginated: true,
    })
    setupExtraReducer(builder, getKladr, 'kladr', { paginated: true })
    setupExtraReducer(builder, getKladrRegion, 'kladrRegion', { paginated: true })
    setupExtraReducer(builder, getKladrDistrict, 'kladrDistrict', {
      paginated: true,
    })
    setupExtraReducer(builder, getKladrCity, 'kladrCity', { paginated: true })
    setupExtraReducer(builder, getKladrStreet, 'kladrStreet', { paginated: true })
    setupExtraReducer(builder, getKladrHouse, 'kladrHouse', { paginated: true })
    setupExtraReducer(builder, getPhoneCodes, 'phoneCodes', { paginated: true })
    setupExtraReducer(builder, getVisaCity, 'visaCities')
    setupExtraReducer(builder, getVisaCountry, 'visaCountries', { paginated: true })
    setupExtraReducer(builder, getFormEducation, 'formEducation')
    setupExtraReducer(builder, getLevelEducation, 'levelEducation')
    setupExtraReducer(builder, getResidence, 'residence')
    setupExtraReducer(builder, getMaritalStatuses, 'maritalStatuses')
    setupExtraReducer(builder, getPersonalData, 'personalData')
    setupExtraReducer(builder, getPassportData, 'passportData')
    setupExtraReducer(builder, getTypeDocEducation, 'typeDocEducation')
    setupExtraReducer(builder, getMethodApplication, 'methodApplication')
    setupExtraReducer(builder, getRelationDegrees, 'relationDegrees')
    setupExtraReducer(builder, getProfileEducation, 'profileEducation')
    setupExtraReducer(builder, getLegalRepresentative, 'legalRepresentative')
    setupExtraReducer(builder, getTypeIdentityDocuments, 'typeIdentityDocuments')
    setupExtraReducer(builder, getPassportKeys, 'typeDocs')
    setupExtraReducer(builder, getScenarioKeys, 'scenarioKeys')
    setupExtraReducer(builder, getLevelEducationKeys, 'levelEducationKeys')
    setupExtraReducer(
      builder,
      getTypeIdentityDocumentsRus,
      'typeIdentityDocumentsRus'
    )
    setupExtraReducer(
      builder,
      getPreviousEduForMastersDocument,
      'previousEduForMasters'
    )
    setupExtraReducer(builder, getPartnersList, 'partners', { paginated: true })
  },
})

function setupExtraReducer(
  builder: ActionReducerMapBuilder<ProfileInitialState>,
  asyncThunk: AsyncThunk<any, any, {}>,
  key: keyof ProfileInitialState['entities'],
  params: { paginated: boolean } = { paginated: false }
) {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state.entities[key].status = 'loading'
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      state.entities[key].status = 'succeeded'
      if (params.paginated) {
        const type = action.meta?.arg?.type ?? 'initial'

        const entity = state.entities[key] as PaginatedStateEntity<
          ResponsePage<[]>['data']
        >
        entity.data =
          type === 'initial'
            ? [...entity.data, ...action.payload.data]
            : action.payload.data

        entity.nextPage =
          action.payload.currentPage < action.payload.lastPage
            ? action.payload.currentPage + 1
            : -1
      } else {
        state.entities[key].data = action.payload
      }
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      state.entities[key].status = 'failed'
      state.entities[key].error = action.payload
      state.entities[key].data = null
    })
}

export default profileSlice.reducer
