import React from 'react'

import terms from '@common/terms'
import LevelStatementsList from '@components/LevelStatementsList'
import LevelStatementsPaymentFormList from '@components/LevelStatementsPaymentFormList'
import Button from '@ui/Button'
import { useLocation } from 'react-router-dom'
import useLevelPaymentPage from '@hooks/useLevelPaymentFormPage'

const LevelPaymentFormPage = () => {
  const { onSubmit } = useLevelPaymentPage()

  const location = useLocation()

  const scenarioPaymentType: any = location.state

  return (
    <main className={'level-page'}>
      <LevelStatementsPaymentFormList
        scenarioPaymentType={scenarioPaymentType.scenario}
      />
      <Button onClick={() => onSubmit()} theme={'success'}>
        {terms.SAVE_AND_NEXT}
      </Button>
    </main>
  )
}

export default LevelPaymentFormPage
