import React from 'react'

import classnames from 'classnames'

const LevelListElementPayment = ({
  id,
  name,
  active,
  setActive,
  className,
  paymentType,
  scenarioId,
}) => {
  const changeTrajectory = () => {
    setActive({
      id: id,
      value: name,
      paymentType: paymentType,
      scenarioId: scenarioId,
    })
  }
  return (
    <div
      className={classnames('level-list__level-element', { active }, className)}
      onClick={changeTrajectory}
    >
      <h2>{name}</h2>
    </div>
  )
}

export default LevelListElementPayment
