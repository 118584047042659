import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { RoutesContext } from 'types/custom-types'

import { TABLET_SCREEN_WIDTH } from '@common/constants'
import terms from '@common/terms'
import Sidenav from '@components/Sidenav'
import useAppSelector from '@hooks/useAppSelector'
import useWindowsSize from '@hooks/useWindowsSize'
import { createToast } from '@redux/action-types'
import { selectCanSend, selectStatementsElement } from '@redux/actions/selectors'
import Button from '@ui/Button'

type PageWithSidenavProps<T> = React.PropsWithChildren<{
  next?: boolean
  title: string
  useHooks: () => RoutesContext<T>
  useSubmitFormHook: () => { onSubmitForm: () => void }
  scenarioKeys?: any
}>

const PageWithSidenav = <Key extends string>({
  next = true,
  children,
  title,
  useHooks,
  useSubmitFormHook,
  scenarioKeys,
}: PageWithSidenavProps<Key>) => {
  const checkAnketaBeforeSubmit = () =>
    checkFillingAnketa &&
    checkFillingAnketa(checkboxses)
      .then(() => onSubmitForm())
      .catch(() => dispatch(createToast('Не все поля заполнены', 'danger')))

  const { isMobile, windowSize } = useWindowsSize()
  const isTabletScreen = windowSize.innerWidth <= TABLET_SCREEN_WIDTH
  const dispatch = useDispatch()

  const { onSubmitForm } = useSubmitFormHook()
  const {
    routes,
    visitedRoutes,
    unvisitedRoutes,
    lastBtn,
    checkFillingAnketa,
    checkboxses,
  } = useHooks()

  const canSend = useAppSelector(selectCanSend)

  const [dis, setDis] = useState(false)
  useEffect(() => {
    if (routes.find((el) => el.to === 'blank') && lastBtn !== '') {
      setDis(!canSend)
    } else {
      setDis(false)
    }
  })

  const statement = useAppSelector(selectStatementsElement)

  const currentDate = new Date()
  const endDate1 = new Date('2024-08-20T23:59:00+03:00')
  const endDate2 = new Date('2024-08-21T23:59:00+03:00')

  const isDisabledButtonNextBachelor =
    location.pathname.includes('blank') &&
    currentDate > endDate1 &&
    (statement?.scenarioId === scenarioKeys.level_training_bachelor.tdId ||
      statement?.scenarioId === scenarioKeys.level_training_bachelor_ino.tdId) &&
    (statement?.statusStatement?.code === '2' ||
      statement?.statusStatement?.code === '6' ||
      statement?.statusStatement?.code === '8')

  const isDisabledButtonNextMaster =
    location.pathname.includes('blank') &&
    currentDate > endDate2 &&
    statement?.scenario?.code === scenarioKeys.magistra_rf.tdId &&
    (statement?.statusStatement?.code === '2' ||
      statement?.statusStatement?.code === '6' ||
      statement?.statusStatement?.code === '8')

  return (
    <div className="sidenav-page-wrapper">
      <div className="sidenav-page">
        {title && <h1 className="sidenav-page__title">{title}</h1>}
        {isMobile ? (
          <div className="sidenav-page__content">
            <div className="sidenav-page__nav-container">
              <Sidenav
                routes={routes}
                className="sidenav-page__nav"
                statement={statement}
                mobileMenu={{
                  routeContent: children,
                  checkFillingAnketa,
                  onSubmitForm,
                  checkAnketaBeforeSubmit,
                  isMobile,
                  dis,
                  lastBtn,
                  next,
                }}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="sidenav-page__content">
              <div className="sidenav-page__nav-container">
                <Sidenav
                  routes={isTabletScreen ? visitedRoutes : routes}
                  className="sidenav-page__nav"
                />
              </div>
              <div className="sidenav-page__inner">{children}</div>
              {isTabletScreen && (
                <div className="sidenav-page__nav-container">
                  <Sidenav routes={unvisitedRoutes} className="sidenav-page__nav" />
                </div>
              )}
            </div>
            {next && !location.pathname.includes('contract') && (
              <Button
                theme="success"
                className="sidenav-page__button"
                onClick={
                  lastBtn &&
                  !location.pathname.includes(
                    '/personal-information/educational-background'
                  )
                    ? () => checkAnketaBeforeSubmit()
                    : () => onSubmitForm()
                }
                disabled={
                  dis //||
                  // (location.pathname.includes('admin/users') &&
                  // location.pathname.includes('blank')) ||
                  // isDisabledButtonNextBachelor ||
                  // isDisabledButtonNextMaster ||
                  // (location.pathname.includes('filling-statements')))//&&
                  // (statement?.scenarioId === 5 ||
                  //   statement?.scenarioId === 6 ||
                  //   statement?.scenarioId === 9) &&
                  // statement?.statusStatement.translate[0].name === 'Черновик')
                }
              >
                {lastBtn ? lastBtn : terms.NEXT}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default PageWithSidenav
