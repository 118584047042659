import usePaginatedEntity from '@hooks/usePaginatedEntity'
import { getSubjectsRussia } from '@redux/action-types'
import {
  selectPaginatedSubjectsRussia,
  selectPaginatedSubjectsRussiaLoading,
} from '@redux/actions/selectors'

const usePaginatedSubjectsRussia = () => {
  return usePaginatedEntity({
    action: getSubjectsRussia,
    dataSelector: selectPaginatedSubjectsRussia,
    loaderSelector: selectPaginatedSubjectsRussiaLoading,
  })
}

export default usePaginatedSubjectsRussia
