import React from 'react'

import AccordionTab from '@components/AccordionTab'
import LogFilter from '@components/LogFilter'
import LogList from '@components/LogList'

const LogPage = () => {
  return (
    <main className="moderator-statements-page">
      <h1 className="moderator-statements-page__title">Лог действий</h1>
      <AccordionTab title={'Фильтры'}>
        <LogFilter />
      </AccordionTab>
      <LogList />
    </main>
  )
}

export default LogPage
