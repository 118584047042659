import React, { ReactElement, useState } from 'react'

import useAppDispatch from './useAppDispatch'
import { mimeTypes } from '@common/manual'
import terms from '@common/terms'
import { createToast } from '@redux/action-types'

const useFileUploaderComponent = (
  files: File[],
  inputRef: React.RefObject<HTMLInputElement>,
  onDrop: (files: File[]) => void,
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined,
  onChange: (files: File[]) => void
) => {
  const dispatch = useAppDispatch()
  const error: ReactElement = (
    <p>
      Документ(ы) не были загружены по причине(ам): <br />
      -превышен размер файла(ов);
      <br />
      -не соответствует формат загружаемого файла(ов);
      <br />
      -превышено количество загружаемых файлов.'
    </p>
  )
  const [isActive, setIsActive] = useState(false)

  const getNewFiles = (newFiles: FileList) => {
    if (files.length && files[0].type === mimeTypes.pdf) {
      dispatch(createToast(error, 'danger'))
      return []
    }

    let haveFormat: string | null = null
    let size = 0

    if (files.length) {
      haveFormat = files[0].type

      files.forEach((file) => {
        size += file.size
      })
    }

    return Array.from(newFiles).filter((file) => {
      if (haveFormat === mimeTypes.pdf) {
        dispatch(createToast(error, 'danger'))
        return false
      }

      if (file.type !== mimeTypes.jpeg && file.type !== mimeTypes.pdf) {
        dispatch(createToast(error, 'danger'))

        return false
      }

      if (haveFormat === mimeTypes.jpeg && file.type === mimeTypes.pdf) {
        dispatch(createToast(error, 'danger'))

        return false
      }

      if (files.find((x) => x.name === file.name)) {
        return false
      }

      haveFormat = file.type
      size += file.size

      // > 5МБ
      if (size > 5242880) {
        dispatch(createToast(error, 'danger'))

        return false
      }

      return true
    })
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target

    if (!target?.files) {
      return () => undefined
    }

    const newFiles = getNewFiles(target.files)

    if (newFiles.length) {
      onChange(newFiles)
    }

    return () => event
  }

  const handleTriggerInput = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): React.MouseEventHandler<HTMLButtonElement> => {
    if (onClick) onClick(event)

    inputRef.current?.click()

    return () => event
  }

  const handleDragEnter = (event) => {
    event.preventDefault()

    setIsActive(true)
  }

  const handleDragOver = (event) => {
    event.preventDefault()

    setIsActive(true)
  }

  const handleDragLeave = (event) => {
    event.preventDefault()
  }

  const handleDrop = (event: DragEvent) => {
    event.preventDefault()
    setIsActive(false)

    if (!event.dataTransfer?.files) {
      return
    }

    const newFiles = getNewFiles(event.dataTransfer.files)

    if (newFiles.length) {
      onDrop(newFiles)
    }
  }

  return {
    handleChange,
    handleTriggerInput,
    handleDragEnter,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    isActive,
  }
}

export default useFileUploaderComponent
