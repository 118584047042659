import React from 'react'

import terms from '@common/terms'
import useChangePasswordForm from '@hooks/useChangePasswordForm'
import TemplateModal from '@modals/TemplateModal'
import Button from '@ui/Button'
import Input from '@ui/Input'

const ChangePasswordModal = () => {
  const {
    fields,
    onSubmit,
    loading,
    formState: { errors },
  } = useChangePasswordForm()

  return (
    <TemplateModal title={terms.CHANGE_PASSWORD} className="change-password-modal">
      <form onSubmit={onSubmit}>
        <Input
          className="change-password-modal__field"
          placeholder={terms.CURRENT_PASSWORD}
          enableSwitcher={true}
          {...fields.passwordCurrent}
          error={errors.passwordCurrent?.message}
        />
        <Input
          className="change-password-modal__field change-password-modal__field--min-h-90"
          placeholder={terms.NEW_PASSWORD}
          hint={terms.PASS_HINT}
          enableSwitcher={true}
          {...fields.passwordNew}
          error={errors.passwordNew?.message}
        />
        <Input
          className="change-password-modal__field"
          placeholder={terms.REPEAT_NEW_PASSWORD}
          enableSwitcher={true}
          {...fields.passwordNewConfirm}
          error={errors.passwordNewConfirm?.message}
        />
        <Button
          className="change-password-modal__button"
          theme="success"
          disabled={loading}
        >
          {terms.SAVE}
        </Button>
      </form>
    </TemplateModal>
  )
}

export default ChangePasswordModal
