import React, { useEffect, useState } from 'react'

import terms from '@common/terms'
import FillingStatementsForm from '@components/FillingStatementsForm'
import PrivilegesTable from '@components/PrivilegesTable'
import ProfileSection from '@components/ProfileSection'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useHandlerLgota from '@hooks/useHandlerLgota'
import useHandlerLgotaInitialization from '@hooks/useHandlerLgotaInitialization'
import useReadOnlyFillingStatements from '@hooks/useReadOnlyFillingStatements'
import useSpecialConditionsForm from '@hooks/useSpecialConditionsForm'
import { IHeadTable, LgotaSpecialDocs } from '@models'
import {
  listLgotaDoc,
  listPreemptiveRight,
  selectStatementsElement,
  selectUserIdProfile,
  selectSpecialCondition,
  selectUploadedLgotaDocsState,
  selectLevelValue,
  selectTrajectoryValue,
} from '@redux/actions/selectors'
import { getUserLgota } from '@redux/reducers/'
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import { base64ToPdf, trimFileExtension } from '@utils/common'

const PrivilegesPage = (scenarioKeys) => {
  const dispatch = useAppDispatch()
  const listLgota = useAppSelector(listLgotaDoc)
  const listPreemptive = useAppSelector(listPreemptiveRight)
  const listSpecialCondition = useAppSelector(selectSpecialCondition)
  const uploadedLgotaDocs = useAppSelector(selectUploadedLgotaDocsState)
  const [listLgotaReason, setListLgotaReason] = useState<LgotaSpecialDocs[]>([])
  const [listLgotaSpecial, setListLgotaSpecial] = useState<LgotaSpecialDocs[]>([])
  const [listPresidentLgota, setListPresidentLgota] = useState<LgotaSpecialDocs[]>(
    []
  )
  const [listPresidentLgotaB, setListPresidentLgotaB] = useState<LgotaSpecialDocs[]>(
    []
  )

  const {
    setValue,
    fields,
    formState: { errors },
    haveSpecialConditions,
    onSubmit,
    onDelete,
    onEdit,
    onDownload,
    onDownloadPreemptiveRight,
    addLgota,
    haveReasonConditions,
    addUploadedLgotaDocsState,
    deleteUploadedLgotaDocsState,
    getUploadedLgotaDocs,
    clearUploadedDocsState,
    watch,
  } = useHandlerLgota(setListLgotaReason, setListLgotaSpecial)

  useHandlerLgotaInitialization({ setValue })

  const {
    getDoc,
    formState: {},
  } = useSpecialConditionsForm()

  const { readOnly } = useReadOnlyFillingStatements()
  const statement = useAppSelector(selectStatementsElement)
  const userId = useAppSelector(selectUserIdProfile)

  const isPaidTraining = statement?.paymentType === 2

  useEffect(() => {
    if (statement && userId) {
      dispatch(getUserLgota({ statementId: statement.id, userId: userId }))
      dispatch(clearUploadedDocsState({}))
    }
  }, [statement, userId])

  useEffect(() => {
    if (uploadedLgotaDocs['lgota_special_conditions'])
      setValue('fileSpecialCondition', uploadedLgotaDocs['lgota_special_conditions'])
  }, [uploadedLgotaDocs['lgota_special_conditions']])

  useEffect(() => {
    if (uploadedLgotaDocs['lgota_reason_conditions'])
      setValue('fileReasonCondition', uploadedLgotaDocs['lgota_reason_conditions'])
  }, [uploadedLgotaDocs['lgota_reason_conditions']])

  useEffect(() => {
    if (uploadedLgotaDocs['presidential_decree_268'])
      setValue(
        'filePresidentialDecree_268',
        uploadedLgotaDocs['presidential_decree_268']
      )
  }, [uploadedLgotaDocs['presidential_decree_268']])

  useEffect(() => {
    if (uploadedLgotaDocs['presidential_decree_268B'])
      setValue(
        'filePresidentialDecree_268B',
        uploadedLgotaDocs['presidential_decree_268B']
      )
  }, [uploadedLgotaDocs['presidential_decree_268B']])

  useEffect(() => {
    listSpecialCondition[0]?.statementDoc.length &&
      setListLgotaReason(
        listSpecialCondition[0]?.statementDoc.filter(
          (file) => file.userDoc.getTypeDocs.name === 'lgota_reason_conditions'
        )
      )
    listSpecialCondition[0]?.statementDoc.length &&
      setListLgotaSpecial(
        listSpecialCondition[0]?.statementDoc.filter(
          (file) => file.userDoc.getTypeDocs.name === 'lgota_special_conditions'
        )
      )
    listSpecialCondition[0]?.statementDoc.length &&
      setListPresidentLgota(
        listSpecialCondition[0]?.statementDoc.filter(
          (file) => file.userDoc.getTypeDocs.name === 'presidential_decree_268'
        )
      )
    listSpecialCondition[0]?.statementDoc.length &&
      setListPresidentLgotaB(
        listSpecialCondition[0]?.statementDoc.filter(
          (file) => file.userDoc.getTypeDocs.name === 'presidential_decree_268_b'
        )
      )
  }, [listSpecialCondition])

  useEffect(() => {
    listLgotaSpecial.length &&
      getUploadedLgotaDocs('LGOTA_SPECIAL', listLgotaSpecial[0].userDocId)
        .then(({ data }) =>
          base64ToPdf(
            data.document,
            trimFileExtension(listLgotaSpecial[0].userDoc.name)
          )
        )
        .then((data) =>
          dispatch(
            addUploadedLgotaDocsState({
              ['lgota_special_conditions']: [data],
            })
          )
        )
    listLgotaReason.length &&
      getUploadedLgotaDocs('LGOTA_REASON', listLgotaReason[0].userDocId)
        .then(({ data }) =>
          base64ToPdf(
            data.document,
            trimFileExtension(listLgotaReason[0].userDoc.name)
          )
        )
        .then((data) =>
          dispatch(
            addUploadedLgotaDocsState({
              ['lgota_reason_conditions']: [data],
            })
          )
        )
    listPresidentLgota.length &&
      getUploadedLgotaDocs('PRESIDENT_DECREE', listPresidentLgota[0].userDocId)
        .then(({ data }) =>
          base64ToPdf(
            data.document,
            trimFileExtension(listPresidentLgota[0].userDoc.name)
          )
        )
        .then((data) =>
          dispatch(
            addUploadedLgotaDocsState({
              ['presidential_decree_268']: [data],
            })
          )
        )
    listPresidentLgotaB.length &&
      getUploadedLgotaDocs('PRESIDENT_DECREE_B', listPresidentLgotaB[0].userDocId)
        .then(({ data }) =>
          base64ToPdf(
            data.document,
            trimFileExtension(listPresidentLgotaB[0].userDoc.name)
          )
        )
        .then((data) =>
          dispatch(
            addUploadedLgotaDocsState({
              ['presidential_decree_268B']: [data],
            })
          )
        )
  }, [listLgotaSpecial, listLgotaReason, listPresidentLgota, listPresidentLgotaB])

  const handleClickAddPreemptive = () => {
    addLgota('PREEMPTIVE')
  }
  const handleClickAddLgota = () => {
    addLgota('PRIVILEGES')
  }

  const handleClickEditLgota = (id: string) => {
    onEdit('PRIVILEGES', id)
  }

  const handleClickEditPreemptive = (id: string) => {
    onEdit('PREEMPTIVE', id)
  }
  const headForLgota: IHeadTable = {
    typeLgota: terms.TYPE_LGOTA,
    typeDoc: terms.CONFIRMATION_DOCUMENT,
    number: terms.SERIES_AND_NUMBER,
    dateIssue: terms.DATE_ISSUE,
  }

  const headForPreemptive: IHeadTable = {
    typeLgota: terms.TYPE_PREEMPTIVE,
    typeDoc: terms.CONFIRMATION_DOCUMENT,
    number: terms.SERIES_AND_NUMBER,
    dateIssue: terms.DATE_ISSUE,
  }

  const scenario = useAppSelector(selectLevelValue) // 7
  const trajectory = useAppSelector(selectTrajectoryValue) //1

  return (
    <FillingStatementsForm
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <div className="privileges-page">
        <Checkbox
          readOnly
          disabled={readOnly}
          defaultChecked={listLgota.length === 0 && listPreemptive.length === 0}
          text={terms.CHECKBOX_LGOTA}
        />
        <span className="privileges-page__title">{terms.LGOTS}</span>
        <div>
          <Button type={'button'} disabled={readOnly} onClick={handleClickAddLgota}>
            {terms.ADD_BUTTON}
          </Button>
        </div>
        {listLgota.length > 0 && (
          <PrivilegesTable
            readOnly={readOnly}
            head={headForLgota}
            rows={listLgota}
            buttons={true}
            onDelete={onDelete}
            onDownload={onDownload}
            onEdit={handleClickEditLgota}
          />
        )}
        <span className="privileges-page__title">{terms.PREEMPTIVE_RIGHTS}</span>
        <div>
          <Button
            type={'button'}
            disabled={readOnly}
            onClick={handleClickAddPreemptive}
          >
            {terms.ADD_BUTTON}
          </Button>
        </div>
        {listPreemptive.length > 0 && (
          <PrivilegesTable
            readOnly={readOnly}
            head={headForPreemptive}
            rows={listPreemptive}
            buttons={true}
            onDelete={onDelete}
            onDownload={onDownloadPreemptiveRight}
            onEdit={handleClickEditPreemptive}
          />
        )}
        <div>
          <Checkbox
            disabled={readOnly}
            text={'Нужны специальные условия для проведения вступительных испытаний'}
            {...fields.haveSpecialConditions}
          />
          {haveSpecialConditions && (
            <div className="special-docs__container">
              <a
                className={'special-docs__link'}
                target={'_blank'}
                href="https://abiturient.spbu.ru/medialibrary/ru/2024/bac/ovz_2024.pdf"
              >
                Особенности проведения вступительных испытаний для инвалидов и лиц с
                ограниченными возможностями здоровья
              </a>
              <div className="profile-form__fields mt-24">
                <div onClick={getDoc} className="blanc__upload-doc-btn">
                  {terms.DOWNLOAD_THE_APPLICATION_FORM_FOR_SPECIAL_CONDITION}
                </div>
              </div>
              <Input
                disabled={readOnly}
                className="profile-form__field profile-form__field--fb-100"
                {...fields.commentSpecialConditions}
                placeholder={'Cпециальные условия'}
                markRequired={true}
                error={errors.commentSpecialConditions?.message}
              />
              <ProfileSection
                title="Копия документа"
                hint={
                  'Заполненный бланк заявления для специальных условий при проведении вступительных испытаний'
                }
                markRequired={true}
                fileUploadingHint
              >
                <FileUploader
                  files={uploadedLgotaDocs['lgota_special_conditions'] || []}
                  onDrop={(file) =>
                    dispatch(
                      addUploadedLgotaDocsState({
                        ['lgota_special_conditions']: file,
                      })
                    )
                  }
                  onChange={(file) =>
                    dispatch(
                      addUploadedLgotaDocsState({
                        ['lgota_special_conditions']: file,
                      })
                    )
                  }
                  onDelete={() =>
                    dispatch(
                      deleteUploadedLgotaDocsState({
                        id: 'lgota_special_conditions',
                      })
                    )
                  }
                  className="profile-form__file-uploader lgota-file-uploader"
                />
              </ProfileSection>
            </div>
          )}
        </div>
        <div>
          <Checkbox
            disabled={readOnly}
            text={
              'Имею основание для сдачи вступительных испытаний в СПбГУ на основании пункта 7.2.3 раздела 7 Правил приема в Университет'
            }
            {...fields.haveReasonConditions}
          />
          {haveReasonConditions && (
            <div className="special-docs__container">
              <ProfileSection
                title="Копия документа"
                markRequired={true}
                fileUploadingHint
              >
                <FileUploader
                  files={uploadedLgotaDocs['lgota_reason_conditions'] || []}
                  onDrop={(file) =>
                    dispatch(
                      addUploadedLgotaDocsState({
                        ['lgota_reason_conditions']: file,
                      })
                    )
                  }
                  onChange={(file) =>
                    dispatch(
                      addUploadedLgotaDocsState({
                        ['lgota_reason_conditions']: file,
                      })
                    )
                  }
                  onDelete={() =>
                    dispatch(
                      deleteUploadedLgotaDocsState({
                        id: 'lgota_reason_conditions',
                      })
                    )
                  }
                  className="profile-form__file-uploader lgota-file-uploader"
                />
              </ProfileSection>
            </div>
          )}
          <div className="special-docs__container">
            {!isPaidTraining && (
              <Checkbox
                text={
                  statement?.scenarioId ===
                    scenarioKeys.level_training_bachelor.tdId ||
                  statement?.scenarioId ===
                    scenarioKeys.level_training_bachelor_ino.tdId
                    ? 'Поступаю на места в пределах отдельной квоты на основании пункта 7.10.2 Правил приема в Университет (по результатам ЕГЭ или вступительных испытаний, проводимых Университетом)'
                    : 'Поступаю на основании пункта «а» Указа Президента РФ от 9 мая 2022 года №268 "О дополнительных мерах поддержки семей военнослужащих и сотрудников некоторых федеральных государственных органов" (на основании результатов общеобразовательных или профильных ВИ, проводимых Университетом)'
                }
                {...fields.presidentialDecree268}
              />
            )}
            {watch('presidentialDecree_268') && (
              <div className="special-docs__container">
                <ProfileSection
                  title="Подтверждающий документ"
                  markRequired={watch('presidentialDecree_268')}
                >
                  <FileUploader
                    files={uploadedLgotaDocs['presidential_decree_268'] || []}
                    onDrop={(file) =>
                      dispatch(
                        addUploadedLgotaDocsState({
                          ['presidential_decree_268']: file,
                        })
                      )
                    }
                    onChange={(file) =>
                      dispatch(
                        addUploadedLgotaDocsState({
                          ['presidential_decree_268']: file,
                        })
                      )
                    }
                    onDelete={() =>
                      dispatch(
                        deleteUploadedLgotaDocsState({
                          id: 'presidential_decree_268',
                        })
                      )
                    }
                    className="profile-form__file-uploader lgota-file-uploader"
                  />
                </ProfileSection>
              </div>
            )}
          </div>
          <div className="special-docs__container">
            {!isPaidTraining && (
              <Checkbox
                text="Поступаю на места в пределах отдельной квоты на основании пункта 7.10.1 Правил приема в Университет (без вступительных испытаний (за исключением дополнительных вступительных испытаний творческой и (или) профессиональной направленности))"
                {...fields.presidentialDecree268B}
              />
            )}
            {watch('presidentialDecree_268B') && (
              <div className="special-docs__container">
                <ProfileSection
                  title="Подтверждающий документ"
                  markRequired={watch('presidentialDecree_268B')}
                >
                  <FileUploader
                    files={uploadedLgotaDocs['presidential_decree_268B'] || []}
                    onDrop={(file) =>
                      dispatch(
                        addUploadedLgotaDocsState({
                          ['presidential_decree_268B']: file,
                        })
                      )
                    }
                    onChange={(file) =>
                      dispatch(
                        addUploadedLgotaDocsState({
                          ['presidential_decree_268B']: file,
                        })
                      )
                    }
                    onDelete={() =>
                      dispatch(
                        deleteUploadedLgotaDocsState({
                          id: 'presidential_decree_268B',
                        })
                      )
                    }
                    className="profile-form__file-uploader lgota-file-uploader"
                  />
                </ProfileSection>
              </div>
            )}
          </div>

          {scenario.id === 7 && trajectory.id === 1 && (
            <div>
              <h2>
                <b>ОСОБЫЕ УСЛОВИЯ ПРИЕМА или ОСОБЕННОСТИ ПРИЕМА</b>
              </h2>

              <div className="special-docs__container">
                <Checkbox
                  disabled={readOnly}
                  text="Поступаю на основании Приказа Министерства Просвещения № 245 от 07.04.2023 об особенностях приема на обучение лиц, обучающихся по программам основного общего и среднего общего образования в 2023 году на территории Белгородской, Брянской и Курской областей"
                  {...fields.decree_245_070423}
                />
              </div>

              <div className="special-docs__container">
                <Checkbox
                  disabled={readOnly}
                  text="Поступаю в соответствии с XIX разделом Правил приема СПбГУ, на основании приказа Минобрнауки России от 01.03.2023 № 231"
                  {...fields.decree_231_010323}
                />
              </div>

              <div className="special-docs__container">
                <Checkbox
                  disabled={readOnly}
                  text="Поступаю в соответствии с Постановлением Правительства Российской Федерации от 03.04.2023 № 528"
                  {...fields.decree_528_030423}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </FillingStatementsForm>
  )
}

export default PrivilegesPage
