import React from 'react'

import Toast from 'components/Toast'
import useAppDispatch from 'hooks/useAppDispatch'
import useAppSelector from 'hooks/useAppSelector'
import { deleteToast } from 'redux/action-types'
import { selectToasts } from 'redux/actions/selectors'

const ToastsContainer = () => {
  const dispatch = useAppDispatch()
  const toasts = useAppSelector(selectToasts)

  return (
    <div className="toast-container">
      <ul className="toast-container__items">
        {toasts.map((toast) => {
          return (
            <li className="toast-container__item" key={toast.id}>
              <Toast {...toast} onClose={(id) => dispatch(deleteToast(id))} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default ToastsContainer
