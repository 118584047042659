import React from 'react'

import ResetPasswordForm from 'components/ResetPasswordForm'
import TemplateAuthPage from 'pages/TemplateAuthPage'

import terms from '@common/terms'

const ResetPasswordPage = () => {
  return (
    <TemplateAuthPage>
      <ResetPasswordForm title={terms.PASSWORD_RESET} />
    </TemplateAuthPage>
  )
}

export default ResetPasswordPage
