import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { createToast } from '@redux/action-types'
import { getAdminUserList } from '@redux/action-types/admin'
import {
  selectAdminUsersFilterData,
  selectRoleIdProfile,
} from '@redux/actions/selectors'
import api, { ResponseOk, UpdateAdminDelete, UpdateUserStatus } from '@services/api'

const useAdminUserListElement = () => {
  const dispatch = useAppDispatch()

  const filterData = useAppSelector(selectAdminUsersFilterData)

  const deleteUser = (id: number) => {
    api.client
      .deleteAdminUserId(id)
      .then((response) => {
        const { data } = response as ResponseOk
        dispatch(createToast(data.message, 'success'))
        dispatch(getAdminUserList(filterData))
      })
      .catch((response: Exclude<UpdateAdminDelete, ResponseOk>) => {
        const { status } = response
        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
  }
  const roleId = useAppSelector(selectRoleIdProfile)

  const changeStatusUser = (id: number, code: number | string) => {
    api.client
      .putAdminChangeUserStatus({ id: id, status: Number(code), roleId: roleId })
      .then((response) => {
        const { data } = response as ResponseOk
        dispatch(createToast(data.message, 'success'))
        dispatch(getAdminUserList(filterData))
      })
      .catch((response: Exclude<UpdateUserStatus, ResponseOk>) => {
        const { status } = response
        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
  }

  return {
    deleteUser,
    changeStatusUser,
  }
}

export default useAdminUserListElement
