import { useEffect } from 'react'

import useInitializeInputs from './useInitializeInputs'

const useJuridicalContractFormInitialization = (setValue, contract?) => {
  useEffect(() => {
    if (contract) {
      setValue('directionTrainingId', {
        label:
          contract?.competitiveGroupStatement?.competitiveGroup?.directionTraining
            ?.translations[0]?.name,
        value:
          contract?.competitiveGroupStatement?.competitiveGroup?.directionTraining
            ?.translations[0]?.directionTrainingId,
      })
      setValue('competitiveGroupStatementId', contract?.competitiveGroupStatementId)
    }
  }, [])

  useInitializeInputs({
    data: contract?.contractJuridical,
    omitKeys: [],
    onInit: (key, value) => {
      setValue(key, value)
      setValue('directionTrainingId', {
        label:
          contract.competitiveGroupStatement.competitiveGroup.directionTraining
            .translations[0].name,
        value:
          contract.competitiveGroupStatement.competitiveGroup.directionTraining
            .translations[0].directionTrainingId,
      })
      setValue('bankIdentifierCode', contract?.contractJuridical.bicBank)
    },
  })
}

export default useJuridicalContractFormInitialization
