import React, { useEffect, useState } from 'react'

import DirectionsTrainingElement from './DirectionsTrainingElement'
import { LEVEL_TRAINING_MASTER, MAGISTRA_RF } from '@common/constants'
import terms from '@common/terms'
import useAppSelector from '@hooks/useAppSelector'
import useDirectionTrainingList from '@hooks/useDirectionTrainingList'
import useReadOnlyFillingStatements from '@hooks/useReadOnlyFillingStatements'
import {
  selectFilteredDirectionsList,
  selectDirectionTrainingSort,
  selectLevelValue,
} from '@redux/actions/selectors/statements'
import Button from '@ui/Button'
import Loader from '@ui/Loader'

const DirectionsTrainingList = (scenarioKeys) => {
  const { choseArr, onSubmit, selectDirection } = useDirectionTrainingList()
  const directionsList = useAppSelector(selectFilteredDirectionsList)
  const sortValue = useAppSelector(selectDirectionTrainingSort)

  const [count, setCount] = useState(10)
  const [list, setList] = useState(directionsList || [])
  const level = useAppSelector(selectLevelValue)

  useEffect(() => {
    if (directionsList === null) {
      return
    }
    setList(
      directionsList
        .filter((direction) =>
          (
            level.scenarioId == undefined
              ? level.id === scenarioKeys.magistra_rf.tdId
              : level.scenarioId === scenarioKeys.magistra_rf.tdId
          )
            ? direction.formPaymentsId !== 10
            : direction
        )
        .filter((direction) => !direction?.exclude)
    )
  }, [count, directionsList])

  const { fixDirection } = useReadOnlyFillingStatements()

  if (directionsList === null) {
    return <Loader />
  }

  if (directionsList.length === 0) {
    return <p>{terms.DATA_NOT_RECEIVED}</p>
  }

  return (
    <section className="directions-training-list">
      {list?.length > 0 &&
        list
          .filter((item) => (sortValue ? item[sortValue] : item))
          .slice(0, count)
          .map((el, index) => (
            <DirectionsTrainingElement
              readOnly={fixDirection}
              choseArr={choseArr}
              changeArr={selectDirection}
              key={index}
              item={el}
              scenarioKeys={scenarioKeys}
            />
          ))}
      {count < list.length && (
        <Button type={'button'} onClick={() => setCount(count + 10)}>
          {terms.ADD_MORE} 10
        </Button>
      )}
      <Button
        type={'button'}
        style={{ marginLeft: 'auto' }}
        className={'self-end'}
        disabled={fixDirection}
        onClick={onSubmit}
        theme={'success'}
      >
        {terms.CONFIRM}
      </Button>
    </section>
  )
}

export default DirectionsTrainingList
