import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import LangChanger from './components/LangChanger'
import ModeratorMenu from './components/ModeratorMenu'
import UserMenu from './components/UserMenu'
import HeaderIcon from '@assets/images/HeaderIcon.svg'
import HeaderIconEn from '@assets/images/HeaderIconEn.svg'
import HeaderIconEnMini from '@assets/images/HeaderIconEnMini.svg'
import HeaderIconMini from '@assets/images/HeaderIconMini.svg'
import { MOBILE_SCREEN_WIDTH, ROLE_USER } from '@common/constants'
import terms, { locale } from '@common/terms'
import useAppSelector from '@hooks/useAppSelector'
import { getData, saveData } from '@hooks/useViewData'
import useWindowsSize from '@hooks/useWindowsSize'
import { useCode } from '@hooks/useСode'
import { PageDataView } from '@models'
import { selectRoleIdProfile, selectToken } from '@redux/actions/selectors'
import api from '@services/api'
import Button from '@ui/Button'

const Header = () => {
  const token = useAppSelector(selectToken)
  const roleId = useAppSelector(selectRoleIdProfile)

  const { isMobile } = useWindowsSize()

  const location = useLocation()

  const [user, setUser] = useState<PageDataView | null | undefined>(null)
  const code = useCode(location)

  useEffect(() => {
    api.client.getAuthPageData(code).then((response) => {
      const { data } = response as unknown as PageDataView

      setUser(data)
    })
  }, [setUser])

  let data = []
  if (user?.data) {
    data = user.data[0]
    saveData(user.data[0])
  }

  if (data['titleLogin'] == null) {
    data = getData()
  }

  let image = `data:image/jpeg;base64,${data['logo']}`

  return (
    <header className="header">
      <div className="header__content">
        {locale === 'ru'
          ? (isMobile && (
              <img
                src={image}
                alt=""
                style={{
                  width: '32px',
                  height: '32px',
                }}
              />
            )) || (
              // <HeaderIconMini width="108" height="32" />
              // <HeaderIcon width="246" height="70" />
              <div className="row__left_corner">
                <img
                  src={image}
                  alt=""
                  style={{
                    width: '70px',
                    height: '70px',
                  }}
                />
                <p className="text-logo__left_corner">{data['textLogo']}</p>
              </div>
            )
          : (isMobile && (
              <img
                src={image}
                alt=""
                style={{
                  width: '32px',
                  height: '32px',
                }}
              />
            )) || (
              // <HeaderIconMini width="108" height="32" />
              <div className="row__left_corner">
                <img
                  src={image}
                  alt=""
                  style={{
                    width: '70px',
                    height: '70px',
                  }}
                />
                <p className="text-logo__left_corner">{data['textLogo']}</p>
              </div>
            )}

        {/*{locale === 'ru'*/}
        {/*  ? (isMobile && <HeaderIconMini width="108" height="32" />) || (*/}
        {/*  <HeaderIcon width="246" height="70" />*/}
        {/*)*/}
        {/*  : (isMobile && <HeaderIconMini width="108" height="32" />) || (*/}
        {/*  <HeaderIconEn width="246" height="70" />*/}
        {/*)}*/}

        <div className="header__right-corner">
          {!token || roleId === ROLE_USER ? <LangChanger /> : ''}
          {token &&
            roleId &&
            (roleId === ROLE_USER ? <UserMenu /> : <ModeratorMenu />)}
          {!token && location.pathname.includes('applicants') && (
            <NavLink to={'rus-sign-in'} className={'btn-link'}>
              <Button type={'button'} theme={'success'}>
                {terms.LOG_IN}
              </Button>
            </NavLink>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header
