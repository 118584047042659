import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import classnames from 'classnames'

import profileImagePlaceholder from '@assets/images/profile-image-placeholder.png'
import { RUSSIA_NATIONAL_ID } from '@common/constants'
import terms from '@common/terms'
import ModeratorDataList from '@components/ModeratorDataList'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { DataList } from '@models'
import {
  clearSupportPersonalData,
  getModeratorUserPersonalData,
} from '@redux/action-types'
import { selectModeratorUserPersonalData } from '@redux/actions/selectors'
import { formatDateYYYYMMDD } from '@utils/date'

const SupportBasicDataPage = () => {
  const dispatch = useAppDispatch()

  const { userId } = useParams<{ userId?: string }>()
  useEffect(() => {
    if (userId) {
      dispatch(getModeratorUserPersonalData(userId))
    }
  }, [userId])

  const users = useAppSelector(selectModeratorUserPersonalData)

  const image = users?.photo ? `data:image/jpeg;base64,${users?.photo}` : null

  const smallList: DataList = [
    {
      title: 'Гражданство:',
      label:
        users?.userNational[0]?.nationality?.nationalityTranslate[0]?.name || '-',
    },
    {
      title: 'Иностранец:',
      label:
        users?.userNational[0]?.nationality?.id === RUSSIA_NATIONAL_ID
          ? 'Нет'
          : 'Да',
    },
    {
      title: 'Соотечественник:',
      label: users?.compatriot ? 'Да' : 'Нет',
    },
  ]
  const list: DataList = [
    {
      title: 'ID в системе:',
      label: users?.id || '-',
    },
    {
      title: 'Email:',
      label: users?.email || '-',
    },
    {
      title: 'Фамилия:',
      label: users?.usersTranslate?.find((el) => el?.localeId === 1)?.surname || '-',
    },
    {
      title: 'Имя:',
      label: users?.usersTranslate?.find((el) => el?.localeId === 1)?.name || '-',
    },
    {
      title: 'Отчество:',
      label:
        users?.usersTranslate?.find((el) => el?.localeId === 1)?.patronymic || '-',
    },
    {
      title: 'Дата рождения:',
      label: users?.dateBirthday ? formatDateYYYYMMDD(users?.dateBirthday) : '-',
    },
    {
      title: 'Пол:',
      label: users?.gender?.name || '-',
    },
    {
      title: 'Семейное положение:',
      label: users?.maritalStatus?.name || '-',
    },
    {
      title: 'Количество детей:',
      label: users?.amountChild === undefined ? '-' : Number(users?.amountChild),
    },
    {
      title: 'Работа:',
      label: users?.work?.translate[0].name || '-',
    },
    {
      title: 'Мобильный телефон:',
      label: users?.mobile || '-',
    },
    {
      title: 'СНИЛС:',
      label: users?.snils || '-',
    },
    {
      title: 'Дата СНИЛС:',
      label: users?.dateSnils ? formatDateYYYYMMDD(users?.dateSnils) : '-',
    },
  ]

  const englishName = [
    {
      title: 'Фамилия латинскими буквами:',
      label: users?.usersTranslate?.find((el) => el?.localeId === 2)?.surname || '-',
    },
    {
      title: 'Имя латинскими буквами:',
      label: users?.usersTranslate?.find((el) => el?.localeId === 2)?.name || '-',
    },
    {
      title: 'Отчество латинскими буквами:',
      label:
        users?.usersTranslate?.find((el) => el?.localeId === 2)?.patronymic || '-',
    },
  ]

  if (
    users?.usersTranslate?.find((el) => el?.localeId === 2)?.name ||
    users?.usersTranslate?.find((el) => el?.localeId === 2)?.surname ||
    users?.usersTranslate?.find((el) => el?.localeId === 2)?.patronymic
  ) {
    list.splice(6, 0, ...englishName)
  }

  return (
    <div className="moderator-basic-data-page">
      <div
        className={classnames(
          'moderator-basic-data-page__image',
          'profile-image-block'
        )}
      >
        <div className="profile-image-block__img-wrapper">
          <img
            src={image || profileImagePlaceholder}
            alt={terms.PHOTO}
            className="profile-image-block__img"
          />
        </div>
        <ModeratorDataList list={smallList} />
      </div>
      <ModeratorDataList list={list} />
    </div>
  )
}

export default SupportBasicDataPage
