import React from 'react'

import Button from 'components/generic/Button'
import Input from 'components/generic/Input'
import RouteButtonLink from 'components/generic/RouteButtonLink'
import TemplateAuthForm from 'components/TemplateAuthForm'
import useResetPasswordForm from 'hooks/useResetPasswordForm'

import terms from '@common/terms'

type ResetPasswordFormProps = {
  title: string
}

const ResetPasswordForm = ({ title }: ResetPasswordFormProps) => {
  const {
    formState: { errors },
    fields,
    onSubmit,
  } = useResetPasswordForm()

  return (
    <TemplateAuthForm title={title}>
      <form onSubmit={onSubmit}>
        <div className="auth-form__body">
          <div className="auth-form__policy">
            <p className="auth-form__paragraph">
              {terms.ENTER_YOUR_EMAIL_FOR_PASSWORD_RESET}
            </p>
          </div>
          <div className="auth-form__fields">
            <Input
              placeholder={terms.EMAIL}
              className="auth-form__input"
              {...fields['email']}
              error={errors.email?.message}
            />
          </div>
        </div>
        <div className="auth-form__buttons">
          <RouteButtonLink to="/rus-sign-in" className="auth-form__button">
            {terms.BACK}
          </RouteButtonLink>
          <Button theme="success" className="auth-form__button">
            {terms.RESET}
          </Button>
        </div>
      </form>
    </TemplateAuthForm>
  )
}

export default ResetPasswordForm
