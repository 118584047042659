import React from 'react'
import { useNavigate } from 'react-router-dom'

import classnames from 'classnames'

import Button from '../Button'
import TabArrowDown from '@assets/images/TabArrowDown.svg'

const Breadcrumbs = ({
  link,
  title,
  className,
}: {
  link?: string
  title: string
  className: string
}) => {
  const navigate = useNavigate()

  return (
    <div className={classnames('breadcrumbs', className)}>
      <Button
        className={'breadcrumbs__button'}
        onClick={() => {
          link ? navigate(link) : navigate(-1)
        }}
      >
        <TabArrowDown />
      </Button>
      <h1 className={'breadcrumbs__title'}>{title}</h1>
    </div>
  )
}

export default Breadcrumbs
