import { UploadDocKey } from '@common/uploadDocsTypes'
import { StudentsFilterFormData, ViewerDirectionsTrainingSearch } from '@models'
import { statementsSlice } from '@redux/reducers'
import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '@services/api'

export const {
  cleanStatementsElement,
  setTrajectoryValue,
  setLevelValue,
  setDirectionsListStatements,
  setCanSend,
  setSignedContractList,
  setSignedPaidReceiptList,
  deleteSignedContractList,
  deleteSignedPaidReceiptList,
  cleanContract,
  setDirectionTrainingSort,
} = statementsSlice.actions

export const getStatementsList = createAsyncThunk(
  'statements/statementsList',
  async (userId?: number) => {
    const response = await api.client.getStatementsList(userId)
    return response.data
  }
)

export const getStatementsElement = createAsyncThunk(
  'statements/statementsElement',
  async (id: number) => {
    const response = await api.client.getStatementsElement(id)
    return response.data
  }
)
export const getAchivementDocTypes = createAsyncThunk(
  'statements/achivementDocTypes',
  async (achivementId: number) => {
    const response = await api.client.getAchivementDocTypes(achivementId)
    return response.data
  }
)

export const getAchivementTypes = createAsyncThunk(
  'statements/achivementTypes',
  async (statementId: number) => {
    const response = await api.client.getAchivements(statementId)
    return response.data
  }
)

export const getOlympiadTypes = createAsyncThunk(
  'statements/olympiadTypes',
  async (data: { year: string | number; statementId: string | number }) => {
    const response = await api.client.getOlympiadsByYear(data)
    return response.data
  }
)

export const getOlympiadDocTypes = createAsyncThunk(
  'statements/olympiadDocTypes',
  async () => {
    const response = await api.client.getOlympiadsDocType()
    return response.data
  }
)

export const getTrajectoryList = createAsyncThunk(
  'statements/trajectoryList',
  async () => {
    const response = await api.client.getTrajectoryList()
    return response.data
  }
)

export const getContractsList = createAsyncThunk(
  'statements/contractsList',
  async (statementid: string | number) => {
    const response = await api.client.getContractsList(statementid)
    return response.data
  }
)

export const getContract = createAsyncThunk(
  'statements/contract',
  async (contractId: number) => {
    const response = await api.client.getContractById(contractId)
    return response.data
  }
)

export const getDirectionsList = createAsyncThunk(
  'statements/directionsList',
  async (data: ViewerDirectionsTrainingSearch) => {
    const response = await api.client.getDirectionsListSearch(data)
    return response.data
  }
)

export const getDirectionsListStatements = createAsyncThunk(
  'statements/directionsListStatements',
  async (id: number) => {
    const response = await api.client.getDirectionsListStatements(id)
    return response.data
  }
)

export const getDirectionsListOptions = createAsyncThunk(
  'statements/directionListOptions',
  async (statementId: number | string) => {
    const response = await api.client.getDirectionTrainingsOptions(statementId)
    return response.data
  }
)

export const getFormDirectionsTrainingSearch = createAsyncThunk(
  'statements/formDirectionsTrainingSearch',
  async (data?: StudentsFilterFormData) => {
    const response = await api.client.getFormEducation(data)
    return response.data
  }
)

export const getFaculty = createAsyncThunk(
  'statements/faculty',
  async (data?: StudentsFilterFormData) => {
    const response = await api.client.getFaculty(data)
    return response.data
  }
)

export const getDirectionTrainingsList = createAsyncThunk(
  'statements/directionTrainingsList',
  async (data?: StudentsFilterFormData) => {
    const response = await api.client.getDirectionTrainingsList(data)
    return response.data
  }
)

export const getStudentsList = createAsyncThunk(
  'statements/studentsList',
  async (data?: StudentsFilterFormData) => {
    const response = await api.client.getStudentsList(data)
    return response.data
  }
)

export const cleanStudentsList = createAsyncThunk(
  'statements/studentsList',
  async () => {
    return []
  }
)

export const getCompetitiveGroups = createAsyncThunk(
  'statements/competitiveGroups',
  async (data?: StudentsFilterFormData) => {
    const response = await api.client.getCompetitiveGroups(data)
    return response.data
  }
)

export const getTrainingLevels = createAsyncThunk(
  'statements/trainingLevels',
  async () => {
    const response = await api.client.getTrainingLevels()
    return response.data
  }
)

export const getEntranceExams = createAsyncThunk(
  'statements/entranceExams',
  async (statementId: number | string) => {
    const response = await api.client.getEntranceExams(statementId)
    return response.data
  }
)

export const getHousingUsePaymentsOptions = createAsyncThunk(
  'statements/housingUsePaymentsOptions',
  async () => {
    const response = await api.client.getHousingUsePaymentsOptions()
    return response.data
  }
)

export const getPaymentForEdusOptions = createAsyncThunk(
  'statements/paymentForEdusOptions',
  async () => {
    const response = await api.client.getPaymentForEdusOptions()
    return response.data
  }
)

export const getFarePaymentsOptions = createAsyncThunk(
  'statements/farePaymentsOptions',
  async () => {
    const response = await api.client.getFarePaymentsOptions()
    return response.data
  }
)

export const getMonetaryPaymentsOptions = createAsyncThunk(
  'statements/monetaryPaymentsOptions',
  async () => {
    const response = await api.client.getMonetaryPaymentsOptions()
    return response.data
  }
)

export const getLevelDirectionsTrainingSearch = createAsyncThunk(
  'statements/levelDirectionsTrainingSearch',
  async () => {
    const response = await api.client.getLevelEducation()
    return response.data
  }
)
export const getPaymentDirectionsTrainingSearch = createAsyncThunk(
  'statements/paymentDirectionsTrainingSearch',
  async (data?: StudentsFilterFormData) => {
    const response = await api.client.getFormPayment(data)
    return response.data
  }
)

export const getTrainingLevelList = createAsyncThunk(
  'statements/trainingLevel',
  async (id: number) => {
    const response = await api.client.getTrainingLevelList(id)
    return response.data
  }
)

export const getStatementDisciplines = createAsyncThunk(
  'statements/discipline',
  async (statementId: number) => {
    const response = await api.client.getStatementDisciplines(statementId)
    return response.data
  }
)

export const getDisciplineEgeOptions = createAsyncThunk(
  'statements/disciplineEgeOptions',
  async (statementId: number) => {
    const response = await api.client.getDisciplineEge(statementId)
    return response.data
  }
)

export const getCentralExamOptions = createAsyncThunk(
  'statements/disciplineCentralExamOptions',
  async (statementId: number) => {
    const response = await api.client.getDisciplineCentralExam(statementId)
    return response.data
  }
)

export const getTabEge = createAsyncThunk(
  'statements/tabEgeData',
  async (statementId: number) => {
    const response = await api.client.getTabEge(statementId)
    return response.data
  }
)

export const getTabEgeV2 = createAsyncThunk(
  'statements/tabEgeDataV2',
  async (statemntId: number) => {
    const response = await api.client.getTabEgeV2(statemntId)
    return response.data
  }
)

export const getTargetContract = createAsyncThunk(
  'statements/targetContract',
  async (statementId: number) => {
    const response = await api.client.getTargetContract(statementId)
    return response.data
  }
)

export const getBlankStatement = createAsyncThunk(
  'statements/blankStatement',
  async (id: number) => {
    const response = await api.client.getBlankStatement(id)
    return response.data
  }
)

export const getBlankPriorityStatement = createAsyncThunk(
  'statements/blankStatement',
  async (id: number) => {
    const response = await api.client.getBlankPriorityStatement(id)
    return response.data
  }
)

export const getAchivements = createAsyncThunk(
  'statements/userAchivement',
  async (statementId: number) => {
    const response = await api.client.getUserAchivements(statementId)
    return response.data
  }
)

export const getConclusionBaseDepartment = createAsyncThunk(
  'statements/conclusionBaseDepartment',
  async (statementId: number) => {
    const response = await api.client.getConclusionBaseDepartment(statementId)
    return response.data
  }
)

export const getOlympiads = createAsyncThunk(
  'statements/userOlympiads',
  async (statementId: number) => {
    const response = await api.client.getUserOlympiads(statementId)
    return response.data
  }
)

export const getMoodleCourses = createAsyncThunk(
  'statements/moodleCourses',
  async (data: any) => {
    const response = await api.client.getMoodleCourses(data)
    return response.data
  }
)

export const getDisciplineTrials = createAsyncThunk(
  'statements/disciplineTrials',
  async (userId: string) => {
    const response = await api.client.getDisciplineTrials(userId)
    return response.data
  }
)

export const getListGroups = createAsyncThunk('statements/listGroups', async () => {
  const response = await api.client.getListGroups()
  return response.data
})

export const getIncomingList = createAsyncThunk(
  'statements/incomingList',
  async (data: any) => {
    const response = await api.client.getIncomingList(data)
    return response
  }
)

export const getPlaceEmploymentsList = createAsyncThunk(
  'statements/placeEmploymentsList',
  async () => {
    return await api.client.getPlaceEmployments()
  }
)

export const getSchoolSubjects = createAsyncThunk(
  'attestat/school_subjects',
  async () => {
    const response = await api.client.getSchoolSubjects()
    return response.data
  }
)

export const getUserSchoolSubjects = createAsyncThunk(
  'attestat/user_school_subjects',
  async (userId: any) => {
    const response = await api.client.getUserSchoolSubjects(userId)
    return response.data
  }
)

export const getEstimates = createAsyncThunk(
  'attestat/estimate',
  async (userId: any) => {
    const response = await api.client.getEstimates(userId)
    return response.data
  }
)

export const getConsentToEnrollmentHistory = createAsyncThunk(
  'statement/blank/history',
  async (data: { id: number | string; user?: number | string }) => {
    const response = await api.client.getConsentToEnrollmentHistory(data)
    return response.data
  }
)
