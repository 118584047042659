import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import SignInInoForm from '@components/SignInInoForm'
import { getData, saveData } from '@hooks/useViewData'
import { useCode } from '@hooks/useСode'
import { PageDataView } from '@models'
import TemplateInoAuthPage from '@pages/TemplateAuthPage'
import api from '@services/api'

const SignInInoPage = () => {
  const location = useLocation()

  const [user, setUser] = useState<PageDataView | null | undefined>(null)
  const code = useCode(location)

  useEffect(() => {
    api.client.getAuthPageData(code).then((response) => {
      const { data } = response as unknown as PageDataView

      setUser(data)
    })
  }, [setUser])

  let data = []
  if (user?.data) {
    data = user.data[0]
    saveData(user.data[0])
  }

  if (data['titleLogin'] == null) {
    data = getData()
  }

  return (
    <TemplateInoAuthPage>
      <SignInInoForm title={data['titleLogin']} dataView={data} />
    </TemplateInoAuthPage>
  )
}

export default SignInInoPage
