import React, { useEffect } from 'react'

import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useSiteExchangeSetting from '@hooks/useSiteExchangeSetting'
import TemplateSettingModal from '@modals/TemplateSettingModal'
import { modalDataSelector } from '@redux/actions/selectors'
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'
import Input from '@ui/Input'
import './ExchangeAdminModal.scss'
import Loader from '@ui/Loader'
import TemplateExchangeModal from '@modals/TemplateExchangeModal'

const ExchangeAdminModal = () => {
  const { onSubmit, fields, loading } = useSiteExchangeSetting()

  const { data } = useAppSelector(modalDataSelector)

  // if (loading) {
  //   return (<Loader />)
  // }
  return (
    <TemplateExchangeModal
      title={terms.EXCHANGE_UPDATE_MODAL_TITLE + ' ' + '"' + data[1] + '"'}
    >
      {/*+ ' ' + data.name*/}
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <div className="profile-form__fields ">
          {/*год + отметка на удаление*/}
          <div className="profile-form__fields auth-image-block__center-text-wrapper">
            <div className="auth-exchange-block__text-wrapper">
              <Input
                placeholder={terms.EXCHANGE_UPDATE_YEAR}
                {...fields.exchangeYear}
                className="profile-form__field profile-form__field--fb-100"
              />
              <div>{data[2]}</div>

              <Input hidden={true} value={data[0]} {...fields.url} />
              <Checkbox
                text={terms.EXCHANGE_DELETE_OLD}
                className="profile-form__checkbox"
                {...fields.clearDatabase}
              />
              <input hidden={true} {...fields.clearDatabase} />
            </div>
          </div>
        </div>
        {loading ? (
          <Button
            disabled={true}
            theme="success"
            className="profile-form__button profile-form__button--align-left"
          >
            {terms.EXCHANGE_MESSAGE}
          </Button>
        ) : (
          <Button
            theme="success"
            className="profile-form__button profile-form__button--align-left"
          >
            {terms.EXCHANGE_ACCEPT}
          </Button>
        )}
      </form>
    </TemplateExchangeModal>
  )
}

export default ExchangeAdminModal
