import React, { PropsWithChildren } from 'react'

import ExclamationMarkIcon from '@assets/images/Exclamation-mark.svg'

type TemplateFormProps = PropsWithChildren<{
  title: string
  message?: boolean
}>

export const TemplateAuthForm = ({
  title,
  children,
  message,
}: TemplateFormProps) => {
  return (
    <div className="template-auth-form">
      <h1 className="template-auth-form__title">{title}</h1>
      {message && (
        <>
          <div className="template-auth-form__message">
            <div className="template-auth-form__message-item">
              <ExclamationMarkIcon width={16} height={17} />
            </div>
            <div className="template-auth-form__message-item">
              <p>Внимание!</p>
              <p>
                Учетные записи прошлых лет не действительны. Просим
                зарегистрироваться заново.
              </p>
              <p>
                Необходимые для поступления документы принимаются приемной комиссией
                только если они были предоставлены (в том числе в электронной форме)
                в университет не позднее 17:00 по московскому времени, относительно
                срока завершения приема документов, установленного Правилами приема
              </p>
            </div>
          </div>
          <div className="template-auth-form__message">
            <div className="template-auth-form__message-item">
              <ExclamationMarkIcon width={16} height={17} />
            </div>
            <div className="template-auth-form__message-item">
              <p>
                Прием на обучение по программам бакалавриата, программам
                специалитета, программам магистратуры, программам аспирантуры,
                программам ассистентуры-стажировки, программам среднего
                профессионального образования:
              </p>
              <ol>
                <li>1. граждан Российской Федерации</li>
                <li>2. соотечественников, проживающих за рубежом</li>
                <li>
                  3. иностранных граждан, имеющих равные с гражданами России права
                  приема на обучение на бюджетных местах:
                  <div className="text-left">
                    <ol>
                      <li> · Республики Беларусь</li>
                      <li> · Республики Казахстан</li>
                      <li> · Киргизской республики</li>
                      <li> · Республики Таджикистан</li>
                    </ol>
                  </div>
                </li>
              </ol>
            </div>
          </div>
        </>
      )}
      <div className="template-auth-form__content">{children}</div>
    </div>
  )
}

export default TemplateAuthForm
