import { useEffect } from 'react'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { Datetime } from 'types/shared-kernel'

import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useInitializeInputs from '@hooks/useInitializeInputs'
import usePaginatedVisaCountries from '@hooks/usePaginatedVisaCountires'
import { SelectOption, Visa, VisaCity, VisaCountry } from '@models'
import { getVisa, getVisaCity, getVisaCountry } from '@redux/action-types'
import { selectVisa, selectVisaCityOptions } from '@redux/actions/selectors'

type useVisaFormSelectorsProps = {
  setValue: UseFormSetValue<Visa>
  watch: UseFormWatch<Visa>
}

const useVisaFormInitialization = ({
  setValue,
  watch,
}: useVisaFormSelectorsProps) => {
  const visa = useAppSelector(selectVisa)
  const visaCityOptions = useAppSelector(selectVisaCityOptions)

  const dispatch = useAppDispatch()
  const visaCountriesSelectProps = usePaginatedVisaCountries()

  useEffect(() => {
    dispatch(getVisaCountry({ value: '', page: 1 }))
    dispatch(getVisa())
  }, [])

  const covidOption: SelectOption[] = [
    { value: 0, label: 'Нет' },
    { value: 1, label: 'Да' },
  ]

  const visaCountry = watch('countryVisaId') as unknown as SelectOption | undefined
  useEffect(() => {
    if (visaCountry?.value) {
      dispatch(getVisaCity(visaCountry.value))
    }
  }, [visaCountry])

  useInitializeInputs<Visa>({
    data: visa,
    omitKeys: [],
    onInit: (key, value) => {
      const mapper = {
        countryVisaId: 'countryVisaOption',
        cityVisaId: 'cityVisaOption',
      }

      if (Object.keys(mapper).includes(key)) {
        setValue(key, visa?.[mapper[key]] as any)
      } else {
        setValue(key, value)
      }
    },
  })

  return {
    visaCountry,
    visaCityOptions,
    visaCountriesSelectProps,
    covidOption,
  }
}

export default useVisaFormInitialization
