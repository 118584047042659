import React from 'react'

import classnames from 'classnames'

import CloseIcon from '@assets/images/CloseIcon.svg'
import QuestionMark from '@assets/images/questionMark.svg'
import { UploadDocKey } from '@common/uploadDocsTypes'
import useAppDispatch from '@hooks/useAppDispatch'
import { clearDocs, hideModal } from '@redux/action-types'
import Button from '@ui/Button'

type TemplateModalProps = {
  children: React.ReactNode
  title: string
  className?: string
  clearTypeDoc?: UploadDocKey
  hint?: string
}

const TemplateModal = ({
  children,
  title,
  className,
  clearTypeDoc,
  hint,
}: TemplateModalProps) => {
  const dispatch = useAppDispatch()

  const closeModal = () => {
    dispatch(hideModal())
    if (clearTypeDoc) {
      dispatch(clearDocs(clearTypeDoc))
    }
  }

  return (
    <div className="modal-layer">
      <div className="modal-layer__overlay" onClick={closeModal} />
      <div className={classnames('modal-layer__modal template-modal', className)}>
        <header className="template-modal__header">
          <h1 className="template-modal__title">{title}</h1>
          {hint && (
            <div title-hint={hint} className="template-modal__svg">
              <QuestionMark width="24" height="24" fill="#37B34A" />
            </div>
          )}
          <Button className="template-modal__close-btn" onClick={closeModal}>
            <CloseIcon />
          </Button>
        </header>
        <div className="template-modal__body">{children}</div>
      </div>
    </div>
  )
}

export default TemplateModal
