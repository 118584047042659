import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import { createToast, showModal } from '@redux/action-types'
import {
  selectAdminDirectoryInfo,
  setAdminPageDirectoriesInfo,
} from '@redux/actions/selectors'

const useDirectoryInfo = () => {
  const editDirectory = (data) => {
    dispatch(showModal({ name: 'ADMIN_EDIT_AUTH_SETTING', data: { data } }))
  }

  const dispatch = useAppDispatch()

  const directoryInfo = useAppSelector(selectAdminDirectoryInfo)

  if (!directoryInfo?.data.length) {
    dispatch(setAdminPageDirectoriesInfo(1))
  }

  return {
    editDirectory,
  }
}

export default useDirectoryInfo
