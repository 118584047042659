import React, { useState } from 'react'

import classnames from 'classnames'

import Button from '../Button'
import TabArrowDown from '@assets/images/TabArrowDown.svg'

const Dropdown = ({ title, children }) => {
  const [open, setOpen] = useState(false)
  return (
    <section className={'dropdown'}>
      <header className="dropdown__header">
        {title}
        <Button
          className={classnames('dropdown__header-btn', open && 'rotate')}
          onClick={() => setOpen(!open)}
        >
          <TabArrowDown />
        </Button>
      </header>
      {open && <div className="dropdown__main right">{children}</div>}
    </section>
  )
}

export default Dropdown
