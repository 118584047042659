import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useAppSelector from '@hooks/useAppSelector'
import { ProfileDataResponse } from '@models'
import { selectLocalizedViewerProfile } from '@redux/actions/selectors'

const useProtectProfileRoute = (
  key: keyof ProfileDataResponse,
  navigateTo: string
) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const profile = useAppSelector(selectLocalizedViewerProfile)

  useEffect(() => {
    if (!profile && !loading) {
      return setLoading(true)
    } else if (profile && loading) {
      return setLoading(false)
    }
    if (profile && !profile[key] && !loading) {
      navigate(navigateTo)
    }
  }, [profile, loading])

  return { loading }
}

export default useProtectProfileRoute
