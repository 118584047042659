import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'

import {
  UserLgota,
  PrivilegesCreate,
  LgotaType,
  LgotaDocType,
  PreemptiveRightType,
  PreemptiveRightDocType,
  PreemptiveRightCreate,
  LgotaSpecialConditions,
} from '@models'
import { ResponseOk, ResponseUnprocessableEntity } from '@services/api/types'

export default {
  /** @method GET */
  getUserLgota: (id: number, userId: number) =>
    axios.get<UserLgota>(`/userLgota?statementId=${id}&user_id=${userId}`),

  /** @method GET */
  getUserLgotaType: () => axios.get<LgotaType>(`/lgota`),

  /** @method GET */
  getPreemptiveRightType: () => axios.get<PreemptiveRightType>(`/preemptiveRights`),

  /** @method GET */
  getPreemptiveRightDocType: (id: number) =>
    axios.get<PreemptiveRightDocType>(
      `/preemptiveRightsDoc?preemptive_right_id=${id}`
    ),

  /** @method DELETE */
  userLgotaDelete: ({
    docId,
    statementId,
    userId,
  }: {
    docId: number
    statementId: number
    userId: number
  }) =>
    axios.delete<ResponseOk>(
      `/userLgota?doc_id=${docId}&user_id=${userId}&statement_id=${statementId}`
    ),

  /** @method POST */
  postLgotaSpecialConditions: (data: LgotaSpecialConditions) =>
    axios.post<AddLgotaSpecialConditions>('/nextTabUserLgota', snakecaseKeys(data)),

  /** @method POST */
  postLgotaSpecialDocs: (data, statementId?) => {
    if (!!statementId) {
      return axios.post(
        `/statements/upload?statement_id=${Number(statementId)}`,
        data
      )
    } else {
      return axios.post('/statements/upload', data)
    }
  },

  /** @method POST */
  postConclusionBaseDepartmentDocs: (data) => axios.post('/statements/upload', data),

  /** @method GET */
  getUserLgotaDocType: (id: number) =>
    axios.get<LgotaDocType>(`/lgotaDoc?lgota_id=${id}`),

  /** @method GET */
  getUserPreemptiveRightsType: () =>
    axios.get<PreemptiveRightType>(`/preemptiveRights`),

  /** @method POST */
  addUserLgota: (data: PrivilegesCreate | PreemptiveRightCreate) => {
    const updData = {
      ...snakecaseKeys(data),
      typeDoc: data.typeDoc,
      statementId: data.statementId,
    }

    const formData = new FormData()
    Object.keys(updData).forEach((key) => formData.append(key, updData[key]))
    formData.delete('documents')
    formData.delete('doc_id')
    for (const doc of data.documents) {
      formData.append('documents[]', doc)
    }

    return axios.post<AddLgotaEntrantsResponse>('/userLgota', formData)
  },

  /** @method PUT */
  updateUserLgota: (data: PrivilegesCreate | PreemptiveRightCreate) => {
    const updData = {
      ...snakecaseKeys(data),
      statementId: data.statementId,
      typeDoc: data.typeDoc,
      documents: undefined,
    }

    return axios.put<AddLgotaEntrantsResponse>('/userLgota', updData)
  },
}

//AddLgota
export type AddLgotaEntrantsResponse =
  | ResponseOk
  | ResponseUnprocessableEntity<PrivilegesCreate>

//AddLgota
export type AddLgotaSpecialConditions =
  | ResponseOk
  | ResponseUnprocessableEntity<LgotaSpecialConditions>
