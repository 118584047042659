import React, { useEffect } from 'react'
import { UseFormSetValue } from 'react-hook-form'

import useInitializeInputs from '../hooks/useInitializeInputs'
import { Olympiad, SelectOption } from '../models'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import useUploadDocuments from './useUploadDocuments'
import {
  getDisciplineEgeOptions,
  getOlympiadDocTypes,
  getOlympiadTypes,
} from '@redux/action-types'
import {
  selectDisciplineEgeOptions,
  selectOlympiadsDocTypesOptions,
  selectOlympiadTypesOptions,
} from '@redux/actions/selectors'

type usePersonalDataFormSelectorsProps = {
  setValue: UseFormSetValue<Olympiad>
  olympiad?: Olympiad
  statementId: number
  year: SelectOption | undefined
}

const useOlympiadFormInitialization = ({
  setValue,
  olympiad = {} as Olympiad,
  statementId,
  year,
}: usePersonalDataFormSelectorsProps) => {
  const dispatch = useAppDispatch()

  const olympiads = useAppSelector(selectOlympiadTypesOptions)
  const olympiadDoc = useAppSelector(selectOlympiadsDocTypesOptions)

  const olympiadFile = useUploadDocuments('ACHIVMENT', olympiad.name)

  useEffect(() => {
    if (statementId) {
      dispatch(getDisciplineEgeOptions(statementId))
    }
  }, [statementId])

  const disciplineEgeOptions = useAppSelector(selectDisciplineEgeOptions)

  useEffect(() => {
    dispatch(getOlympiadDocTypes())
    if (statementId) {
      setValue('statementId', Number(statementId))
    }
    if (Object.keys(olympiad).length) {
      olympiadFile.getUploadedDoc(olympiad.userDocId)
    }
  }, [])

  useEffect(() => {
    if (year?.label && statementId) {
      dispatch(getOlympiadTypes({ year: year.label, statementId }))
    }
  }, [year])

  useInitializeInputs({
    data: olympiad,
    omitKeys: [],
    onInit: (key, value) => {
      const mapper = {
        olympiadId: 'olympiadTypeOption',
        olympiadDocId: 'olympiadDocOption',
      }
      setValue('year', olympiad['year'])
      setValue('olympiadDocId', olympiad['olympiadDocOption'])
      dispatch(getDisciplineEgeOptions(statementId)).then((res) => {
        // @ts-ignore
        const current = res.payload.find((item) => item.id === olympiad['subject'])
        if (current) {
          const changeCurrent = {
            label: current.name,
            value: current.disciplineId,
            score: current.score || '0',
          }
          setValue('subject', changeCurrent)
        }
      })

      if (Object.keys(mapper).includes(key)) {
        setValue(key, olympiad?.[mapper[key]] as any)
      } else {
        setValue(key, value)
      }
    },
  })

  return {
    olympiadFile,
    olympiads,
    olympiadDoc,
  }
}

export default useOlympiadFormInitialization
