import React, { useEffect } from 'react'

import classnames from 'classnames'

import terms from '@common/terms'
import useCreatePageHeader from '@hooks/useCreatePageHeader'
import Button from '@ui/Button'
import useAppDispatch from '@hooks/useAppDispatch'
import { getTrajectoryList } from '@redux/action-types'
import useAppSelector from '@hooks/useAppSelector'
import { selectTrajectoryList } from '@redux/actions/selectors'

const CreatePageHeader = ({ list }) => {
  const { onSubmit } = useCreatePageHeader()

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getTrajectoryList())
  }, [])

  const TrajectoryDataList = useAppSelector(selectTrajectoryList)

  return (
    <header
      className={classnames(
        'create-page__header',
        list && list.length > 0 ? 'row' : 'column'
      )}
    >
      <h1 className="create-page__title">{terms.APPLICATION}</h1>
      {TrajectoryDataList != null && Object.keys(TrajectoryDataList).length != 0 ? (
        <div onClick={onSubmit}>
          <Button type="button" theme={'success'}>
            {terms.CREATE_APPLICATION}
          </Button>
        </div>
      ) : (
        <div></div>
      )}
    </header>
  )
}

export default CreatePageHeader
