import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { FilterLogList } from '@models'
import { getLogList } from '@redux/action-types/admin'
import {
  selectAdminPageLogList,
  selectAdminPerPageLogList,
} from '@redux/actions/selectors'

const useLogListFilter = () => {
  const dispatch = useAppDispatch()
  const { register, handleSubmit, reset, setValue } = useForm<FilterLogList>({
    mode: 'all',
  })

  const fields = {
    enrolleeId: register('enrolleeId'),
    moderatorId: register('moderatorId'),
    dateFrom: register('dateFrom'),
    dateTo: register('dateTo'),
  }

  const page = useAppSelector(selectAdminPageLogList)
  const perPage = useAppSelector(selectAdminPerPageLogList)

  useEffect(() => {
    setValue('page', page)
    setValue('perPage', perPage)
    onSubmit()
  }, [page, perPage])

  const onSubmit = handleSubmit((data: any) => {
    dispatch(getLogList(data))
  })

  return {
    fields,
    reset,
    onSubmit,
  }
}

export default useLogListFilter
