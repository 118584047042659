import client from './client-api'

export * from './types'
export * from './auth-api'
export * from './profile-api'
export * from './moderator-api'
export * from './document-api'
export * from './statements-api'
export * from './admin-api'
export * from './lgota-api'
export * from './chat-api'
export * from './foreign-language-study-api'
export * from './service-keys-api'

export default {
  client,
}
