import React, { ReactElement } from 'react'

import cn from 'classnames'
import { NavRoute } from 'types/custom-types'

import SidenavLink from './components/SidenavLink'
import terms from '@common/terms'
import Button from '@ui/Button'

type SideNavProps = {
  className?: string
  routes: NavRoute[]
  children?: React.ReactNode
  mobileMenu?: any
  statement?: any
}

const Sidenav = ({
  className,
  routes,
  children,
  mobileMenu,
  statement,
}: SideNavProps) => {
  return (
    <div className={className}>
      <nav>
        <ul>
          {routes.map(({ to, text, title, status }) => {
            return (
              <li
                key={to}
                className={cn({ ['mobile-border']: mobileMenu?.isMobile })}
              >
                <SidenavLink
                  to={to}
                  text={text}
                  status={
                    status === 'valid' || status === 'invalid' ? status : undefined
                  }
                >
                  {title}
                </SidenavLink>
                {mobileMenu?.isMobile &&
                  (location.pathname.includes(to) ||
                    location.pathname === '/personal-information' ||
                    location.pathname.slice(-19) === '/filling-statements') && (
                    <div>
                      <div className="sidenav-page__inner">
                        {mobileMenu.routeContent}
                        {mobileMenu?.next && (
                          <Button
                            theme="success"
                            className="sidenav-page__button"
                            onClick={
                              mobileMenu.lastBtn &&
                              !(
                                location.pathname ===
                                '/personal-information/educational-background'
                              )
                                ? () => mobileMenu.checkAnketaBeforeSubmit()
                                : () => mobileMenu.onSubmitForm()
                            }
                            disabled={
                              mobileMenu.dis //||
                              // (location.pathname.includes('filling-statements') &&
                              //   (statement?.scenarioId === 5 ||
                              //     statement?.scenarioId === 6 ||
                              //     statement?.scenarioId === 9) &&
                              //   statement?.statusStatement.translate[0].name ===
                              //     'Черновик')
                            }
                          >
                            {mobileMenu.lastBtn ? mobileMenu.lastBtn : terms.NEXT}
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
              </li>
            )
          })}
        </ul>
        <div>{children}</div>
      </nav>
    </div>
  )
}

export default Sidenav
