import { useParams } from 'react-router-dom'

import useAppDispatch from '@hooks/useAppDispatch'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import { ChosenTrials } from '@models'
import { createToast, getDirectionsListStatements } from '@redux/action-types'
import api, { ResponseOk } from '@services/api'

const useTrialsDirectionTrainingList = () => {
  const { statementId } = useParams<{ statementId?: string }>()
  const dispatch = useAppDispatch()
  const { onNextPageNavigate } = useFillingStatementsRoutesContext()

  const selectTrials = (trials: Array<ChosenTrials>) => {
    api.client.statementTrials(trials, statementId).then((response) => {
      const { data } = response as unknown as ResponseOk
      if (data.success) {
        dispatch(createToast(data.message, 'success'))
        onNextPageNavigate()
      } else {
        dispatch(getDirectionsListStatements(Number(statementId)))
        dispatch(createToast(data.message, 'danger'))
      }
    })
  }
  return { selectTrials }
}

export default useTrialsDirectionTrainingList
