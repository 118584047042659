import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Crop } from 'react-image-crop'

import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { createToast, hideModal, showModal } from '@redux/action-types'
import {
  modalDataSelector,
  selectAdminDirectoriesList,
  selectViewerProfileImage,
} from '@redux/actions/selectors'
import { getViewerProfile, getViewerProfileImage } from '@redux/reducers'
import api, { ResponseOk } from '@services/api'
import { getCroppedImg, rotateBase64Image90deg } from '@utils/canvas-site'
import { getAuthPagesList } from '@redux/action-types/admin'

const initialCrop: Crop = {
  aspect: 3 / 3,
  width: 180,
  height: 180,
  x: 0,
  y: 0,
  unit: 'px',
}

const useViewerProfileImage = () => {
  const dispatch = useAppDispatch()
  const [crop, setCrop] = useState<Partial<Crop>>(initialCrop)
  const [image, setImage] = useState('')

  const viewerImage = useAppSelector(selectViewerProfileImage)

  const imgRef = useRef(new Image())
  const canvasRef = useRef(document.createElement('canvas'))

  useEffect(() => {
    if (!image) {
      setCrop({})
    }
  }, [image])

  useEffect(() => {
    if (viewerImage) {
      setImage(viewerImage)
      setCrop(initialCrop)
    }
  }, [viewerImage])

  const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const [img] = e.target.files

      if (img.size > 3_000_000) {
        return dispatch(
          createToast('Размер файла не может быть больше 3мб', 'danger')
        )
      } else if (
        ![
          'image/png',
          'image/webp',
          'image/jpeg',
          'image/gif',
          'image/avif',
        ].includes(img.type)
      ) {
        return dispatch(
          createToast(`Формат файла ${img.type} не поддерживается`, 'danger')
        )
      }

      setImage(URL.createObjectURL(img))
      setCrop(initialCrop)
    }
  }

  const onRotate = (direction: 'left' | 'right') => {
    const result = rotateBase64Image90deg(image, direction === 'right')

    if (result) {
      setImage(result)
      setCrop(initialCrop)
    }
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img
  }, [])

  //const { data } = useAppSelector(modalDataSelector)
  let { data } = useAppSelector(modalDataSelector)

  const onSave = async () => {
    const base64 = image
      ? await getCroppedImg(crop as Crop, canvasRef.current, imgRef.current)
      : ''

    type Org = Record<string, string>
    let DefaultData: Org = {}

    DefaultData.name = data.name
    DefaultData.picture = data.picture
    DefaultData.textLogo = data.textLogo
    DefaultData.rightTextTitle = data.rightTextTitle
    DefaultData.rightSideText = data.rightSideText
    DefaultData.leftTextTitle = data.leftTextTitle
    DefaultData.leftSideText = data.leftSideText
    DefaultData.titleRegister = data.titleRegister
    DefaultData.titleLogin = data.titleLogin
    DefaultData.textPictureLink = data.textPictureLink
    DefaultData.link = data.link
    DefaultData.textPicture = data.textPicture
    DefaultData.visible = data.visible
    DefaultData.translation = data.translation
    DefaultData.id = data.id
    DefaultData.updatedAt = data.updatedAt
    DefaultData.cretedAt = data.cretedAt

    api.client
      .uploadAuthLogo(base64 ?? '', data)
      .then((response) => {
        ;(function () {
          let { data } = response as unknown as ResponseOk
          DefaultData.logo = data.message
        })()

        let data: Org = {}
        data = DefaultData
        dispatch(createToast(terms.SUCCESS_IMAGE_DOWNLOAD, 'success'))
        dispatch(getAuthPagesList())
        dispatch(showModal({ name: 'ADMIN_EDIT_AUTH_SETTING', data: { data } }))
      })
      .catch(() => dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger')))
  }

  const onClear = () => {
    setImage(viewerImage || '')
    setCrop({})
  }

  return {
    crop,
    image,
    onLoad,
    onSave,
    onClear,
    setCrop,
    onUpload,
    onRotate,
  }
}

export default useViewerProfileImage
