import { useEffect, useState } from 'react'
import {
  addResponseMessage,
  addUserMessage,
  deleteMessages,
} from 'react-chat-widget'

import useAppDispatch from './useAppDispatch'
import useChatResize from '@hooks/useChatResize'
import { getAdminMessages } from '@redux/action-types'
import api from '@services/api'
import { formatDateTimeToRuFormat } from '@utils/date'

const useChat = (id) => {
  const [messagesToDelete, setMessagesToDelete] = useState(0)
  const [chatId, setChatId] = useState()
  const { onRendered } = useChatResize()
  const dispatch = useAppDispatch()

  const adminHandleNewMessage = (message) => {
    chatId
      ? api.client.addAdminMessageExistingChat(chatId, { user_id: id, message })
      : api.client
          .addAdminMessageNewChat({ user_id: id, message })
          .then(({ data }) => setChatId(data.thread?.id))
    // .then(() => dispatch(getAdminMessages(id)))
    deleteMessages(1)
    addUserMessage(
      `**Администратор**\n\n${message}\n\n*${new Date()
        .toLocaleString('ru')
        .slice(0, -3)
        .replace(',', '')}*`
    )
  }

  const userHandleNewMessage = (message, responsibleId, profileData) => {
    chatId
      ? api.client.addUserMessageExistingChat(chatId, {
          message,
          recipient_id: responsibleId,
        })
      : api.client
          .addUserMessageNewChat({ message })
          .then(({ data }) => setChatId(data.thread?.id))
    deleteMessages(1)
    addUserMessage(
      `**${profileData.usersTranslate.name}**\n\n${message}\n\n*${new Date()
        .toLocaleString('ru')
        .slice(0, -3)
        .replace(',', '')}*`
    )
  }

  const addAdminMesages = ({ thread }) => {
    setMessagesToDelete(thread?.messages?.length)
    setChatId(thread?.id)
    thread &&
      thread.messages?.map((item) => {
        item.userId === thread?.metaInfo?.applicantId
          ? addResponseMessage(
              `**${item.user?.usersTranslate[0]?.name}**\n\n${
                item.body
              }\n\n*${formatDateTimeToRuFormat(item.createdAt)}*`
            )
          : addUserMessage(
              `**${item.user?.usersTranslate[0]?.name}**\n\n${
                item.body
              }\n\n*${formatDateTimeToRuFormat(item.createdAt)}*`
            )
      })
  }

  const addUserMessages = ({ thread }) => {
    setMessagesToDelete(thread?.messages?.length)
    setChatId(thread?.id)
    thread &&
      thread.messages?.map((item) => {
        item.userId === thread?.metaInfo?.applicantId
          ? addUserMessage(
              `**${item.user?.usersTranslate[0]?.name}**\n\n${
                item.body
              }\n\n*${formatDateTimeToRuFormat(item.createdAt)}*`
            )
          : addResponseMessage(
              `**${item.user?.usersTranslate[0]?.name}**\n\n${
                item.body
              }\n\n*${formatDateTimeToRuFormat(item.createdAt)}*`
            )
      })
  }

  return {
    addAdminMesages,
    addUserMessages,
    chatId,
    messagesToDelete,
    onRendered,
    adminHandleNewMessage,
    userHandleNewMessage,
  }
}

export default useChat
