import useAppDispatch from './useAppDispatch'
import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppSelector from '@hooks/useAppSelector'
import { createToast, showModal } from '@redux/action-types'
import { getAdminUserList } from '@redux/action-types/admin'
import {
  selectAdminPageUsersList,
  selectAdminPerPageUsersList,
} from '@redux/actions/selectors'
import api, { ResponseOk, UpdateUser } from '@services/api'

const useAdminAdministrationTable = () => {
  const dispatch = useAppDispatch()

  const fixUser = (item) => {
    dispatch(
      showModal({
        name: 'ADMIN_USER',
        data: {
          adminUserIdData: {
            id: item.id,
            email: item?.email,
            dateBirthday: item?.dateBirthday,
            roleId: item?.roleId,
            status: item?.confirmed,
            password: '',
            passwordConfirmation: '',
            nameRu: item?.usersTranslate[0]?.name,
          },
        },
      })
    )
  }

  const page = useAppSelector(selectAdminPageUsersList)
  const perPage = useAppSelector(selectAdminPerPageUsersList)

  const deleteUser = (id: number) => {
    api.client
      .deleteAdminUserId(id)
      .then((response) => {
        const { data } = (response as unknown) as ResponseOk
        dispatch(createToast(data.message, 'success'))
        dispatch(getAdminUserList({ page: page, perPage: perPage }))
      })
      .catch((response: Exclude<UpdateUser, ResponseOk>) => {
        const { status } = response
        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
  }
  return { fixUser, deleteUser }
}

export default useAdminAdministrationTable
