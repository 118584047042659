import { useState, useEffect } from 'react'

import { MOBILE_SCREEN_WIDTH } from '@common/constants'

export default (): {
  windowSize: { innerWidth: number; innerHeight: number }
  isMobile: boolean
} => {
  const [windowSize, setWindowSize] = useState({
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
  })

  useEffect(() => {
    const resize = () => {
      setWindowSize({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      })
    }

    window.addEventListener('resize', resize)

    return () => window.removeEventListener('resize', resize)
  }, [])

  const isMobile = windowSize.innerWidth <= MOBILE_SCREEN_WIDTH

  return { windowSize, isMobile }
}
