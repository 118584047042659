import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

import useInitializeInputs from './useInitializeInputs'
import useAppSelector from '@hooks/useAppSelector'
import { SiteSettings } from '@models'
import {
  modalDataSelector,
  selectAdminAuthPageSettings,
} from '@redux/actions/selectors'

type useSettingFormSelectorsProps = {
  watch: UseFormWatch<SiteSettings>
  setValue: UseFormSetValue<SiteSettings>
}

const useSettingFormInitialization = ({
  setValue,
  watch,
}: useSettingFormSelectorsProps) => {
  const personalData = useAppSelector(selectAdminAuthPageSettings)
  const { data } = useAppSelector(modalDataSelector)

  useInitializeInputs({
    data: personalData,
    omitKeys: [],
    onInit: (key, value) => {
      setValue('name', data.name)
      setValue('textLogo', data.textLogo)
      setValue('id', data.id)

      setValue('textPicture', data.textPicture)
      setValue('link', data.link)
      setValue('textPictureLink', data.textPictureLink)
      setValue('titleLogin', data.titleLogin)
      setValue('titleRegister', data.titleRegister)
      setValue('leftSideText', data.leftSideText)
      setValue('leftTextTitle', data.leftTextTitle)
      setValue('rightSideText', data.rightSideText)
      setValue('rightTextTitle', data.rightTextTitle)
    },
  })
  return {
    personalData,
  }
}

export default useSettingFormInitialization
