import React from 'react'
import { useLocation } from 'react-router-dom'

import terms from '@common/terms'

const currentYear = new Date().getUTCFullYear()

const Footer = () => {
  const { pathname } = useLocation()

  return (
    <footer className="footer">
      <span>
        © {currentYear} {terms.SPBPU_ONLINE_APPLICATION_SYSTEM}
      </span>
    </footer>
  )
}

export default Footer
