import authApi from './auth-api'
import documentApi from './document-api'
import lgotaApi from './lgota-api'
import moderatorApi from './moderator-api'
import profileApi from './profile-api'
import statementsApi from './statements-api'
import adminApi from '@services/api/admin-api'
import chatApi from '@services/api/chat-api'
import serviceKeysApi from '@services/api/service-keys-api'
import foreignLanguageStudyApi from '@services/api/foreign-language-study-api'

/** @name CLIENT */

export default {
  ...authApi,
  ...profileApi,
  ...moderatorApi,
  ...adminApi,
  ...documentApi,
  ...statementsApi,
  ...lgotaApi,
  ...chatApi,
  ...foreignLanguageStudyApi,
  ...serviceKeysApi,
}
