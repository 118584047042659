import React from 'react'

import { BLANK_AGREEMENT_TO_ENTER, BLANK_STATEMENT } from '@common/constants'
import CardForPriorityDownloadFile from '@components/CardForPriorityDownloadFile'
const BlankPriority = ({ blankStatements, flag }) => {
  return (
    <>
      {blankStatements &&
        blankStatements.blank.map((blankEl) => {
          // if (
          //   blankEl.blankId === BLANK_AGREEMENT_TO_ENTER &&
          //   !blankStatements.accept
          // ) {
          //   return null
          // } else {
          return (
            <CardForPriorityDownloadFile
              key={blankEl.id}
              blankId={blankEl.blank?.code}
              title={
                blankEl.blank.blankTranslate[0].name +
                (blankEl.blankId === BLANK_STATEMENT
                  ? ' №' + blankStatements.number
                  : '')
              }
              direction={
                blankEl.blankId === BLANK_AGREEMENT_TO_ENTER
                  ? blankStatements.trainingLevelName
                  : ''
              }
              date={blankEl.dateGeneration}
              showCard={flag}
              acceptBlank={blankEl.acceptBlank}
            />
          )
          //  }
        })}
    </>
  )
}

export default BlankPriority
