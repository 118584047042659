import React, { useRef, useEffect } from 'react'

import classNames from 'classnames'

import CrossIcon from '@assets/images/Cross.svg'
import FileIcon from '@assets/images/File.svg'
import FileDownloadIcon from '@assets/images/FileDownload.svg'
import terms from '@common/terms'
import useFileUploaderComponent from '@hooks/useFileUploaderComponent'
import { downloadFile } from '@utils/common'

import './fileUploader.scss'

type FileUploaderProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'onChange' | 'onDrop' | 'onDelete'
> & {
  files: File[]
  disabled?: boolean
  onChange: (files: File[]) => void
  onDrop: (files: File[]) => void
  onDelete: (file: File) => void
  onError?: (error: string) => void
  description?: string
}

const FileUploader = ({
  files,
  disabled,
  className,
  onClick,
  onChange,
  onDrop,
  onDelete,
  description = '', //terms.FILE_UPLOADER_DESCRIPTION
}: FileUploaderProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const inputAreaRef = useRef<HTMLLabelElement>(null)

  const {
    handleChange,
    handleTriggerInput,
    handleDragEnter,
    handleDragOver,
    handleDragLeave,
    handleDrop,
    isActive,
  } = useFileUploaderComponent(files, inputRef, onChange, onClick, onDrop)

  const addDndEventListeners = () => {
    if (inputAreaRef.current) {
      const inputArea = inputAreaRef.current

      inputArea.addEventListener('dragenter', handleDragEnter)
      inputArea.addEventListener('dragover', handleDragOver)
      inputArea.addEventListener('dragleave', handleDragLeave)
      inputArea.addEventListener('drop', handleDrop)
    }
  }

  const removeDndEventListeners = () => {
    if (inputAreaRef.current) {
      const inputArea = inputAreaRef.current

      inputArea.removeEventListener('dragenter', handleDragEnter)
      inputArea.removeEventListener('dragover', handleDragOver)
      inputArea.removeEventListener('dragleave', handleDragLeave)
      inputArea.removeEventListener('drop', handleDrop)
    }
  }

  useEffect(() => {
    addDndEventListeners()

    return () => {
      removeDndEventListeners()
    }
  }, [files])

  return (
    <div className={classNames('file-uploader__wrapper', className)}>
      {!!files.length && (
        <div className="file-uploader__files">
          {files.map((file) => (
            <div key={file.name} className="file-uploader__file">
              <FileIcon
                className="file-uploader__file-icon"
                onClick={() => {
                  return downloadFile(file)
                }}
              />

              {!disabled && (
                <button
                  className="file-uploader__cross"
                  onClick={() => onDelete(file)}
                  type="button"
                >
                  <CrossIcon className="file-uploader__cross-icon" />
                </button>
              )}

              <span
                className="file-uploader__filename"
                onClick={() => downloadFile(file)}
                title={file.name}
              >
                {file.name}
              </span>
            </div>
          ))}
        </div>
      )}

      <label
        className={classNames(
          'file-uploader__area',
          {
            'file-uploader__active': isActive,
          },
          disabled && 'disabled'
        )}
        ref={inputAreaRef}
      >
        <input
          type="file"
          className="file-uploader"
          multiple
          disabled={disabled}
          onChange={handleChange}
          ref={inputRef}
        />

        <button
          className="file-uploader__button"
          onClick={handleTriggerInput}
          disabled={disabled}
          type="button"
        >
          <div className="file-uploader__info">
            <FileDownloadIcon width="18" height="24" />

            <div className="file-uploader__description">
              <div>{terms.FILE_UPLOADER_TITLE}</div>

              {!files.length && <div>{description}</div>}
            </div>

            <div className="file-uploader__mobile-description">{description}</div>
          </div>
        </button>
      </label>
    </div>
  )
}

export default FileUploader
