import React, { forwardRef, useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import mergeRefs from 'lib/mergeRefs'
import { getRandomStr } from 'utils/common'

type InputProps = React.InputHTMLAttributes<HTMLTextAreaElement> & {
  inputClassName?: string
}

const Textarea = forwardRef(
  ({ placeholder, ...rest }: InputProps, ref: React.Ref<HTMLTextAreaElement>) => {
    const textAreaId = useRef(placeholder + getRandomStr())
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    const [active, setActive] = useState(false)
    const [initialized, setInitialized] = useState(false)
    const [height, setHeight] = useState(textAreaRef?.current?.scrollHeight)

    const handleFocusOut = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      const value = e.target.value
      if (value === '') {
        setActive(false)
      }
    }

    const initializeState = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (!initialized) {
        setInitialized(true)
        const value = e.target.value
        if (value) {
          setActive(true)
        }
      }
    }
    useEffect(() => {
      if (textAreaRef.current?.value !== '' && !active) {
        setActive(true)
      }
    }, [active, textAreaRef.current?.value])

    return (
      <div
        className={classNames('input-container textarea-container', rest.className, {
          ['input-container--active']: active || rest.type === 'date',
        })}
      >
        <div className="input-container__inner">
          <textarea
            {...rest}
            style={{ height: height + 'px' }}
            ref={mergeRefs([textAreaRef, ref])}
            onFocus={(e) => {
              rest.onFocus && rest.onFocus(e)
              setActive(true)
            }}
            onBlur={(e) => {
              rest.onBlur && rest.onBlur(e)
              handleFocusOut(e)
            }}
            onChange={(e) => {
              setHeight(e.target.scrollHeight > 60 ? e.target.scrollHeight : 60)
              rest.onChange && rest.onChange(e)
              initializeState(e)
            }}
            className={classNames(
              'input-container__input textarea',
              rest.inputClassName
            )}
            id={textAreaId.current}
          />
          <label
            htmlFor={textAreaId.current}
            className={classNames('input-container__placeholder')}
          >
            {placeholder}
          </label>
        </div>
      </div>
    )
  }
)

Textarea.displayName = 'Textarea'

export default Textarea
