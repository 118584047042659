import React, { useEffect, useState } from 'react'

import cn from 'classnames'

import TableIncomingStudents from '@components/TableIncomingStudents'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useDebouncedFunction from '@hooks/useDebouncedFunction'
import { getIncomingList, getListGroups } from '@redux/action-types'
import { selectIncomingList, selectListGroups } from '@redux/actions/selectors'
import H1 from '@ui/H1'
import Input from '@ui/Input'
import Link from '@ui/Link'

const IncomingStudents = () => {
  const dispatch = useAppDispatch()
  const [currentGroup, setCurrentGroup] = useState<number[]>([])
  const [inputValue, setInputValue] = useState<string>()
  const debouncedDispatch = useDebouncedFunction(
    () => dispatch(getIncomingList({ groups: currentGroup, filters: inputValue })),
    500
  )

  const listGroups = useAppSelector(selectListGroups)
  const incomingList = useAppSelector(selectIncomingList)

  useEffect(() => {
    !listGroups?.length && dispatch(getListGroups())
  }, [])

  useEffect(() => {
    dispatch(getIncomingList({ groups: currentGroup, filters: inputValue }))
  }, [currentGroup])

  useEffect(() => {
    if (inputValue || inputValue === '') {
      debouncedDispatch()
    }
  }, [inputValue])

  return (
    <div className={'viewer-layout__content'}>
      <div className="students-page">
        <H1>Списки поступающих</H1>

        <div className="students-page__box">
          <a
            href="https://application.spbu.ru/enrollee_lists/list"
            target="_blank"
            className="students-page__link"
          >
            Открыть списки поступающих в отдельном окне
          </a>
        </div>

        <iframe
          src="https://application.spbu.ru/enrollee_lists/list"
          style={{ width: '100%', height: '1000px' }}
        />
      </div>
    </div>
  )
}
export default IncomingStudents
