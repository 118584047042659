import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import HttpStatusCode from '../common/httpStatusCodes'
import terms from '../common/terms'
import api, { ResponseOk, UpdateStatementsCreateResponse } from '../services/api'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import { ViewerCreateStatements } from '@models'
import { createToast } from '@redux/action-types'
import {
  selectLevelValue,
  selectStatementsElement,
  selectTrajectoryValue,
} from '@redux/actions/selectors'

const useLevelPaymentPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const trajectory = useAppSelector(selectTrajectoryValue)
  const statementEl = useAppSelector(selectStatementsElement)
  useEffect(() => {
    if (statementEl && statementEl.numberStatement !== null) {
      navigate('../filling-statements')
    }
  }, [statementEl])

  const level = useAppSelector(selectLevelValue)

  const onSubmit = () => {
    if (level.id === 0) {
      return
    }

    api.client
      .statementsCreate({
        trajectoryId: trajectory.id,
        trainingLevel: level.id,
        scenarioId: level.scenarioId,
        paymentType: level.paymentType,
      })
      .then((response) => {
        const { data } = response as ResponseOk<ViewerCreateStatements>
        navigate(`../${data.id}`)
      })
      .catch((response: Exclude<UpdateStatementsCreateResponse, ResponseOk>) => {
        const { status } = response
        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
  }
  return { onSubmit }
}

export default useLevelPaymentPage
