import { useEffect } from 'react'
import { UseFormSetValue } from 'react-hook-form'

import { RUSSIA_NATIONAL_ID } from '@common/constants'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useInitializeInputs from '@hooks/useInitializeInputs'
import useInitializeSelect from '@hooks/useInitializeSelect'
import usePaginatedNationals from '@hooks/usePaginatedNationals'
import { Passport, SelectOption } from '@models'
import {
  getTypeIdentityDocuments,
  getTypeIdentityDocumentsRus,
  getPassportData,
  getPersonalData,
} from '@redux/action-types'
import {
  selectLocalizedViewerProfile,
  selectPassportFormData,
  selectPersonalFormData,
  selectTypeIdentityDocumentOptions,
  selectTypeIdentityDocumentOptionsRus,
} from '@redux/actions/selectors'

type usePersonalDataFormSelectorsProps = {
  setValue: UseFormSetValue<Passport>
}

const usePassportDataFormInitialization = (
  passportIds,
  { setValue }: usePersonalDataFormSelectorsProps
) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    !passportData && dispatch(getPassportData())
    !personalData && dispatch(getPersonalData())
    !typeIdentityDocumentOptions.length && dispatch(getTypeIdentityDocuments())
    !typeIdentityDocumentOptionsRus.length && dispatch(getTypeIdentityDocumentsRus())
  }, [])

  const nationalSelectProps = usePaginatedNationals()
  let typeIdentityDocumentId

  const profile = useAppSelector(selectLocalizedViewerProfile)
  const isNationalRussia =
    profile?.userNational &&
    profile.userNational[0]?.nationalityId === RUSSIA_NATIONAL_ID
  const passportData = useAppSelector(selectPassportFormData)
  const personalData = useAppSelector(selectPersonalFormData)
  const typeIdentityDocumentOptions = useAppSelector(
    selectTypeIdentityDocumentOptions
  )
  const typeIdentityDocumentOptionsRus = useAppSelector(
    selectTypeIdentityDocumentOptionsRus
  )

  useInitializeInputs({
    data: passportData,
    omitKeys: ['typeIdentityDocumentId'],
    onInit: (key, value) => {
      const mapper = {
        nationalityId: 'nationalOption',
      }

      if (Object.keys(mapper).includes(key)) {
        setValue(key, passportData?.[mapper[key]] as any)
      } else {
        setValue(key, value)
      }
    },
  })

  useInitializeSelect({
    data: passportData,
    options: typeIdentityDocumentOptions,
    findValue: passportData?.typeIdentityDocumentId,
    onInit: (option) => {
      setValue('typeIdentityDocumentId', option as any)
      typeIdentityDocumentId = option
    },
  })

  const listIssuingAuthority: SelectOption[] = []
  if (passportData?.whoIssueDocument) {
    listIssuingAuthority.push({
      value: passportIds.russia_citizen_passport_code.tdId,
      label: passportData?.whoIssueDocument,
    })
  }

  useInitializeSelect({
    data: passportData,
    options: listIssuingAuthority,
    findValue: passportData?.typeIdentityDocumentId,
    onInit: (option) => setValue('whoIssueDocument', option as any),
  })

  return {
    isNationalRussia,
    nationalSelectProps,
    typeIdentityDocumentOptions,
    typeIdentityDocumentOptionsRus,
    personalData,
    typeIdentityDocumentId,
  }
}

export default usePassportDataFormInitialization
