import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'

import classNames from 'classnames'
import mergeRefs from 'lib/mergeRefs'
import { getRandomStr } from 'utils/common'

import EyeIcon from '@assets/images/Eye.svg'
import SearchSVG from '@assets/images/search.svg'

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  inputClassName?: string
  hint?: string
  enableSwitcher?: boolean
  markRequired?: boolean
  error?: string
  search?: boolean
}

const Input = forwardRef(
  (
    {
      placeholder,
      error,
      hint,
      inputClassName,
      enableSwitcher = false,
      markRequired = false,
      search = false,
      ...rest
    }: InputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const inputId = useRef(placeholder + getRandomStr())
    const inputRef = useRef<HTMLInputElement>(null)
    const [active, setActive] = useState(true)
    const [isSecret, setIsSecret] = useState(true)
    const [initialized, setInitialized] = useState(false)

    const handleFocusOut = (e: React.FocusEvent<HTMLInputElement>) => {
      const value = e.target.value
      if (!value || value === '') {
        setActive(false)
      } else {
        setActive(true)
      }
    }

    const initializeState = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!initialized) {
        setInitialized(true)
        const value = e.target.value
        if (value) {
          setActive(true)
        } else {
          setActive(false)
        }
      }
    }

    useEffect(() => {
      if (inputRef?.current?.value && inputRef?.current?.value !== '' && !active) {
        setActive(true)
      } else {
        !initialized && setActive(false)
      }
    }, [inputRef?.current?.value, initialized])

    return (
      <div
        className={classNames('input-container', rest.className, {
          ['input-container--active']: active || rest.type === 'date',
        })}
      >
        <div className="input-container__inner">
          <input
            {...rest}
            type={enableSwitcher && isSecret ? 'password' : rest.type || 'text'}
            ref={mergeRefs([inputRef, ref])}
            onFocus={(e) => {
              rest.onFocus && rest.onFocus(e)
              setActive(true)
            }}
            onBlur={(e) => {
              rest.onBlur && rest.onBlur(e)

              handleFocusOut(e)
            }}
            onChange={(e) => {
              setActive(true)
              rest.onChange && rest.onChange(e)
              initializeState(e)
            }}
            className={classNames(
              'input-container__input',
              inputClassName && inputClassName,
              {
                ['input-container__input--error']: Boolean(error),
              }
            )}
            id={inputId.current}
          />
          {enableSwitcher && (
            <EyeIcon
              width="16"
              height="11"
              className="input-container__icon"
              onClick={() => setIsSecret(!isSecret)}
            />
          )}
          <label
            htmlFor={inputId.current}
            className={classNames('input-container__placeholder', {
              ['input-container__placeholder--required']: markRequired,
            })}
          >
            {placeholder}
          </label>
          {search && !active && (
            <SearchSVG className={'input-container__search-icon'} />
          )}
        </div>
        {error ? (
          <div className="input-container__error">{error}</div>
        ) : (
          hint && <span className="input-container__hint">{hint}</span>
        )}
      </div>
    )
  }
)

Input.displayName = 'Input'

export default Input
