import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import ModeratorDataList from '@components/ModeratorDataList'
import { DataList } from '@models'
import {
  getEstimates,
  getModeratorStatementsPersonalData,
  getSchoolSubjects,
  getUserSchoolSubjects,
} from '@redux/action-types'
import {
  selectSchoolSubjects,
  selectUserEstimates,
  selectUserIdProfile,
  selectUserSchoolSubjects,
} from '@redux/actions/selectors'
import { getViewerProfile } from '@redux/reducers'

const CertificatePage = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id?: string }>()
  useEffect(() => {
    if (id) {
      dispatch(getModeratorStatementsPersonalData(id)).then((response) => {
        // @ts-ignore
        dispatch(getUserSchoolSubjects(response?.payload?.userId))
        // @ts-ignore
        dispatch(getEstimates(response?.payload?.userId)).then(() => {})
      })
    }

    dispatch(getSchoolSubjects())
  }, [id])

  const schoolSubjects = useAppSelector(selectSchoolSubjects)
  const subjects = useAppSelector(selectUserSchoolSubjects)
  const estimates = useAppSelector(selectUserEstimates)

  let averageInfo: DataList = [
    {
      title: 'Количество оценок «3»',
      label: String(estimates?.three) ?? '-',
    },
    {
      title: 'Количество оценок «4»',
      label: String(estimates?.four) ?? '-',
    },
    {
      title: 'Количество оценок «5»',
      label: String(estimates?.five) ?? '-',
    },
    {
      title: 'Средний балл',
      label: estimates?.average ?? '-',
    },
  ]

  return (
    <div>
      <ModeratorDataList list={averageInfo} />

      <h2 className={'visa-title'}>Профильные предметы</h2>

      <div className={'moderator-data-list'}>
        {subjects?.map((el, index) => (
          <div key={index} className="moderator-data-list__element">
            <p>{el.schoolSubjectsName}</p>
            <span>{el.estimate ?? '-'}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CertificatePage
