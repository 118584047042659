import api from '../../services/api'
import { FilterAdminUserList, FilterLogList, VisaResponse } from '@models'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getAdminUserList = createAsyncThunk(
  'admin/users',
  async (normalizedData?: FilterAdminUserList) => {
    if (normalizedData) {
      const { support, ...data } = normalizedData
      if (support) {
        const response = await api.client.getSupportUserList(data)
        return response.data
      } else {
        const response = await api.client.getAdminUserList(data)
        return response.data
      }
    }
    const response = await api.client.getAdminUserList()
    return response.data
  }
)

export const getAdminUserId = createAsyncThunk(
  'admin/userId',
  async (id: string | number) => {
    const response = await api.client.getAdminUserId(id)
    return response.data
  }
)

export const getAdminRole = createAsyncThunk('admin/adminRoleOptions', async () => {
  const response = await api.client.getAdminRole()
  return response.data
})

export const getAdminStatus = createAsyncThunk(
  'admin/adminStatusOptions',
  async (roleId: number | null) => {
    const response = await api.client.getAdminStatus(roleId)
    return response.data
  }
)

export const getDirectoriesList = createAsyncThunk(
  'admin/directoriesList',
  async (params: { page; perPage; ids? }) => {
    const response = await api.client.getDirectoriesList(params)
    return response.data
  }
)

export const getAuthPagesList = createAsyncThunk(
  'admin/directoriesList',
  async () => {
    const response = await api.client.getAuthPagesList()
    return response.data
  }
)

export const getDirectoryInfo = createAsyncThunk(
  'admin/directoryInfo',
  async (data: {
    directoryName: string
    params: { page: number; perPage: number }
  }) => {
    const response = await api.client.getDirectoryInfo(data)
    return response.data
  }
)

export const getLogList = createAsyncThunk(
  'admin/logList',
  async (data?: FilterLogList) => {
    const response = await api.client.getLogList(data)
    return response.data
  }
)

export const getAdminLegalRepresentative = createAsyncThunk(
  'admin/statements/legal-representative',
  async (statementId: number | string) => {
    const response = await api.client.getAdminLegalRepresentative(statementId)
    return response.data
  }
)

export const getAdminVisa = createAsyncThunk(
  'api/admin/statements/visa',
  async (id: string | number) => {
    const response = await api.client.getAdminVisa(id)
    return response.data
  }
)
