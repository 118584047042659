import { useForm } from 'react-hook-form'

import { otherDocs, SelectOption } from '../models'
import terms from '@common/terms'

const AddOtherFiles = () => {
  const docOption: SelectOption[] = [
    { label: 'Смена фамилии', value: 0 },
    { label: 'Иное', value: 1 },
  ]

  const { register, watch, setValue, getValues, setError, clearErrors, ...rest } =
    useForm<otherDocs>({ mode: 'all' })

  const fields = {
    typeDoc: register('typeDoc', {
      required: { message: terms.REQUIRED_FIELD, value: true },
    }),
    name: register('name', {
      required: { message: terms.REQUIRED_FIELD, value: true },
    }),
    file: register('file', {
      required: { message: terms.REQUIRED_FIELD, value: true },
    }),
  }

  return {
    register,
    docOption,
    fields,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
    ...rest,
  }
}

export default AddOtherFiles
