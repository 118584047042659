import { useEffect } from 'react'
import { UseFormSetValue } from 'react-hook-form'

import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useInitializeInputs from '@hooks/useInitializeInputs'
import useInitializeSelect from '@hooks/useInitializeSelect'
import usePaginatedPhoneCodes from '@hooks/usePaginatedPhoneCodes'
import { LegalRepresentative, SelectOption } from '@models'
import {
  getLegalRepresentative,
  getPassportData,
  getRelationDegrees,
  getTypeIdentityDocuments,
} from '@redux/action-types'
import {
  selectLegalRepresentativeFormData,
  selectLocalizedViewerProfile,
  selectRelationDegreesOptions,
  selectTypeIdentityDocumentOptions,
} from '@redux/actions/selectors'
import usePaginatedNationals from '@hooks/usePaginatedNationals'
import usePaginatedNationalsIno from '@hooks/usePaginatedNationalsIno'
import usePaginatedNationalsRus from '@hooks/usePaginatedNationalsRus'

type useLegalRepresentativeSelectorsProps = {
  setValue: UseFormSetValue<LegalRepresentative>
}

const useLegalRepresentativeFormInitialization = ({
  setValue,
}: useLegalRepresentativeSelectorsProps) => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(selectLocalizedViewerProfile)
  const nationalityId = profile?.userNational['0'].nationalityId
  let nationalSelectProps: any
  if (nationalityId == 1) {
    nationalSelectProps = usePaginatedNationalsRus()
  } else {
    nationalSelectProps = usePaginatedNationalsIno()
  }
  useEffect(() => {
    !typeIdentityDocumentOptions.length && dispatch(getTypeIdentityDocuments())
  }, [])

  const typeIdentityDocumentOptions = useAppSelector(
    selectTypeIdentityDocumentOptions
  )
  useEffect(() => {
    !representative && dispatch(getLegalRepresentative())
    !relationDegreesOptions.length && dispatch(getRelationDegrees())
  }, [])

  const phoneCodesSelectProps = usePaginatedPhoneCodes()

  const representative = useAppSelector(selectLegalRepresentativeFormData)
  const relationDegreesOptions = useAppSelector(selectRelationDegreesOptions)
  const shouldGetDocument = representative?.userDoc?.length

  useInitializeInputs({
    data: representative,
    omitKeys: ['relationDegreeId'],
    onInit: (key, value) => {
      const mapper = {
        nationality: 'nationalOption',
        countryOfBirth: 'countryOfBirthOption',
        placeOfBirthday: 'placeOfBirthdayOption',
        phoneCode: 'phoneCodeOption',
        typeIdentityDocumentId: 'typeIdentityDocumentIdOption',
      }

      if (Object.keys(mapper).includes(key)) {
        setValue(key, representative?.[mapper[key]] as any)
      } else {
        setValue(key, value)
      }
    },
  })

  useInitializeSelect({
    data: representative,
    options: relationDegreesOptions,
    findValue: representative?.relationDegreeId,
    onInit: (option) => setValue('relationDegreeId', option as any),
  })
  useInitializeSelect({
    data: representative,
    options: typeIdentityDocumentOptions,
    findValue: representative?.typeIdentityDocumentId,
    onInit: (option) => setValue('typeIdentityDocumentId', option as any),
  })

  const listIssuingAuthority: SelectOption[] = []
  if (representative?.whoIssueDocument) {
    listIssuingAuthority.push({ value: 2, label: representative?.whoIssueDocument })
  }

  useInitializeSelect({
    data: representative,
    options: listIssuingAuthority,
    findValue: representative?.typeIdentityDocumentId,
    onInit: (option) => setValue('whoIssueDocument', option as any),
  })

  return {
    typeIdentityDocumentOptions,
    nationalSelectProps,
    phoneCodesSelectProps,
    relationDegreesOptions,
    shouldGetDocument,
  }
}

export default useLegalRepresentativeFormInitialization
