import axios from 'axios'

/** @name CHAT */

export default {
  /** @method GET */
  getAdminMessages: (id?: string | number) =>
    axios.get(`/moderator/chat?user_id=${id}`),

  /** @method GET */
  getAdminNewMessages: (id?: string | number) =>
    axios.get(`/moderator/chat/new?user_id=${id}`),

  /** @method GET */
  getUserMessages: () => axios.get(`/chat`),

  /** @method GET */
  getUserNewMessages: () => axios.get(`/chat/new`),

  /** @method PUT */
  addAdminMessageExistingChat: (chatId, data) =>
    axios.put(`/moderator/chat/${chatId}`, data),

  /** @method PUT */
  addUserMessageExistingChat: (chatId, data) => {
    axios.put(`/chat/${chatId}`, data)
  },

  /** @method POST */
  addAdminMessageNewChat: (data) => axios.post('/moderator/chat', data),

  /** @method POST */
  addUserMessageNewChat: (data) => axios.post(`/chat`, data),
}
