import React from 'react'

import { DataList } from '@models'

type ModeratorDataListProps = {
  list: DataList
}
const ModeratorDataList = ({ list }: ModeratorDataListProps) => {
  return (
    <div className={'moderator-data-list'}>
      {list.map((el, index) => (
        <div key={index} className="moderator-data-list__element">
          <p>{el.title}</p>
          <span>{el.label}</span>
        </div>
      ))}
    </div>
  )
}

export default ModeratorDataList
