import React, { useRef, useEffect, useState, useMemo } from 'react'
import { AddressSuggestions, DaDataSuggestion, DaDataAddress } from 'react-dadata'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import 'react-dadata/dist/react-dadata.css'

import classNames from 'classnames'

// import {
//   MOSCOW_REGION,
//   SPB_REGION,
//   ZERO_DISTRICT_CODE,
//   ZERO_STREET_CODE,
// } from '@common/constants'
import terms from '@common/terms'
import ProfileForm from '@components/ProfileForm'
import ProfileSection from '@components/ProfileSection'
import useAppSelector from '@hooks/useAppSelector'
import useProfileRoutesContext from '@hooks/useProfileRoutesContext'
import useResidenceForm from '@hooks/useResidenceForm'
import useResidenceFormInitialization from '@hooks/useResidenceFormInitialization'
import { SelectCodeOption, SelectOption } from '@models'
import {
  selectIsQuestionnaireBlockProfile,
  selectLocalizedViewerProfile,
} from '@redux/actions/selectors'
import Checkbox from '@ui/Checkbox'
import Input from '@ui/Input'
import RadioButton from '@ui/RadioButton'
import ReactSelect from '@ui/Select'

const ResidenceForm = () => {
  const {
    watchAllFields,
    isRegRussia,
    isActRussia,
    isRussianCitizenship,
    fields,
    watch,
    control,
    setValue,
    onSubmit,
    actisreg,
    clearErrors,
    suggestionsRef,
    suggestionsRefActual,
    value,
    setValue2,
    handleClick,
    handleOption,
    handleClickActual,
    handleFocusActual,
    handleOptionActual,
    handleFocus,
    formState: { errors },
  } = useResidenceForm()

  const {
    residence,
    code,
    //  nationalSelectProps,
    nationalSelectPropsRus,
    nationalSelectPropsIno,
    // kladrSelectProps,
    // kladrSelectPropsRegion,
    // kladrSelectPropsDistrict,
    // kladrSelectPropsCity,
    // kladrSelectPropsStreet,
    // kladrSelectPropsHouse,
    key,
    // kladrSelectPropsActualDistrict,
    // kladrSelectPropsActualCity,
    // kladrSelectPropsActualStreet,
    // kladrSelectPropsActualHouse,
  } = useResidenceFormInitialization({ setValue, isRegRussia, watchAllFields })
  const readOnly = useAppSelector(selectIsQuestionnaireBlockProfile)
  const { onNextPageNavigate } = useProfileRoutesContext()
  const [active, setActive] = useState<boolean>(false)

  const profileSectionTitle = useMemo(() => {
    if (isRussianCitizenship) {
      return terms.PERMANENT_ADDRESS
    }

    return undefined
  }, [isRussianCitizenship, terms.PERMANENT_ADDRESS])

  return (
    <ProfileForm
      onSubmit={
        readOnly
          ? (e) => {
              e.preventDefault()
              onNextPageNavigate()
            }
          : (e) => {
              onSubmit(e)
            }
      }
    >
      <ProfileSection title={profileSectionTitle}>
        <div className="profile-form__fields">
          <Controller
            name="nationalRegistration"
            control={control}
            render={({ field }) => (
              <>
                <div
                  className={classNames(
                    'select-container profile-form__field profile-form__field--fb-100',
                    {
                      ['select-container--active']: residence?.nationalActual,
                    }
                  )}
                >
                  {code == '2' ? (
                    <Select
                      noOptionsMessage={() => 'Нет данных'}
                      placeholder={
                        <span
                          className={classNames('select-container__placeholder', {
                            ['select-container__placeholder--required']: true,
                          })}
                        >
                          {terms.COUNTRY}
                        </span>
                      }
                      className="react-select-container"
                      classNamePrefix="react-select"
                      autoFocus={false}
                      {...nationalSelectPropsIno}
                      {...field}
                      onFocus={(e) => {
                        setActive(true)
                      }}
                      onMenuScrollToBottom={() => {
                        nationalSelectPropsIno.onPaginate()
                      }}
                      onChange={(newValue) => {
                        setValue('nationalRegistration', newValue)
                        setValue('regionRegistration', '')
                        setValue('regionCodeRegistration', null)
                        setValue('districtRegistration', null)
                        setValue('districtCodeRegistration', null)
                        setValue('cityRegistration', null)
                        setValue('localityCodeRegistration', null)
                        setValue('streetRegistration', null)
                        setValue('noStreetRegistration', false)
                        setValue('streetCodeRegistration', null)
                        setValue('houseRegistration', null)
                        setValue('kladrRegistration', null)
                        setValue('apiKladr', null)
                        setValue('apiFias', null)
                        setValue('dadataField', null)
                        setValue('dadataValue', null)
                        setValue('dadataUnrestrictedValue', null)
                      }}
                    />
                  ) : (
                    <Select
                      noOptionsMessage={() => 'Нет данных'}
                      placeholder={
                        <span
                          className={classNames('select-container__placeholder', {
                            ['select-container__placeholder--required']: true,
                          })}
                        >
                          {terms.COUNTRY}
                        </span>
                      }
                      className="react-select-container"
                      classNamePrefix="react-select"
                      autoFocus={false}
                      {...nationalSelectPropsRus}
                      {...field}
                      onFocus={(e) => {
                        setActive(true)
                      }}
                      onMenuScrollToBottom={() => {
                        nationalSelectPropsRus.onPaginate()
                      }}
                      onChange={(newValue) => {
                        setValue('nationalRegistration', newValue)
                        setValue('regionRegistration', '')
                        setValue('regionCodeRegistration', null)
                        setValue('districtRegistration', null)
                        setValue('districtCodeRegistration', null)
                        setValue('cityRegistration', null)
                        setValue('localityCodeRegistration', null)
                        setValue('streetRegistration', null)
                        setValue('noStreetRegistration', false)
                        setValue('streetCodeRegistration', null)
                        setValue('houseRegistration', null)
                        setValue('kladrRegistration', null)
                        setValue('apiKladr', null)
                        setValue('apiFias', null)
                        setValue('dadataField', null)
                        setValue('dadataValue', null)
                        setValue('dadataUnrestrictedValue', null)
                      }}
                      isDisabled={readOnly}
                    />
                  )}
                </div>

                {/* Подсказка по выбору адреса */}
                <div className="profile-form__field--fb-100">
                  {isRegRussia ? (
                    <p>
                      Для ввода адреса в России наберите в полях страну, регион,
                      населенный пункт, наименование улицы и номер дома. Далее в
                      выпавшем поле подсказки будут указан адрес в соответствии с
                      государственным реестром. После чего выбор корпуса и квартиры
                      происходит в поле «Проверка адреса» путем ввода данных по
                      шаблону, например: «….к 1 кв 1» Если Вы не нашли свою улицу,
                      указанную в паспорте, необходимо уточнить актуальное название
                      улицы с помощью Яндекс карт
                    </p>
                  ) : (
                    <p>Указать адрес в стране гражданства</p>
                  )}
                </div>
              </>
            )}
          />

          {isRegRussia ? (
            <Controller
              defaultValue={null}
              name="regionRegistration"
              control={control}
              render={({ field }) => (
                <Input
                  className="profile-form__field"
                  placeholder={terms.REGION}
                  markRequired={isRegRussia}
                  {...fields.regionRegistration}
                  onInput={handleClick}
                  error={errors.regionRegistration?.message}
                  disabled={readOnly}
                />
              )}
            />
          ) : (
            <Input
              className="profile-form__field"
              placeholder={terms.REGION}
              markRequired={false}
              {...fields.regionRegistration}
              error={
                errors.regionRegistration?.message === terms.REQUIRED_FIELD
                  ? undefined
                  : errors.regionRegistration?.message
              }
              disabled={readOnly}
            />
          )}
          {isRegRussia ? (
            <Input
              type={'hidden'}
              markRequired={true}
              {...fields.districtRegistration}
              disabled={readOnly}
            />
          ) : (
            <Input
              type={'hidden'}
              markRequired={true}
              {...fields.districtRegistration}
              disabled={readOnly}
            />
          )}
          {isRegRussia ? (
            // <Controller
            //   name="cityRegistration"
            //   control={control}
            //   render={({field}) => (
            //     <ReactSelect<SelectCodeOption>
            //       placeholder={terms.CITY}
            //       className="profile-form__field"
            //       markRequired={true}
            //       filterOption={() => true}
            //       {...kladrSelectPropsCity}
            //       {...(field as unknown)}
            //       onChange={(newValue) => {
            //         setValue('cityRegistration', newValue)
            //         setValue('localityCodeRegistration', newValue.code)
            //
            //         setValue('streetRegistration', null)
            //         setValue('noStreetRegistration', false)
            //         setValue('streetCodeRegistration', null)
            //
            //         setValue('houseRegistration', null)
            //         setValue('kladrRegistration', null)
            //       }}
            //       error={errors.cityRegistration?.message}
            //       isDisabled={readOnly || !watchAllFields.regionCodeRegistration}/>
            //   )}/>
            <Input
              className="profile-form__field"
              placeholder={terms.CITY}
              markRequired={true}
              {...fields.cityRegistration}
              onInput={handleClick}
              error={errors.cityRegistration?.message}
              disabled={readOnly}
            />
          ) : (
            <Input
              className="profile-form__field"
              placeholder={terms.CITY}
              markRequired={true}
              {...fields.cityRegistration}
              error={errors.cityRegistration?.message}
              disabled={readOnly}
            />
          )}
          <div className="profile-form__field">
            {isRegRussia ? (
              // <Controller
              //   name="streetRegistration"
              //   control={control}
              //   render={({field}) => (
              //     <ReactSelect<SelectCodeOption>
              //       placeholder={terms.STREET}
              //       markRequired={isRegRussia}
              //       filterOption={() => true}
              //       {...(field as unknown)}
              //       {...kladrSelectPropsStreet}
              //       onChange={(newValue) => {
              //         setValue('streetRegistration', newValue)
              //         setValue('streetCodeRegistration', newValue.code)
              //         setValue('noStreetRegistration', false)
              //
              //         setValue('houseRegistration', null)
              //         setValue('kladrRegistration', null)
              //       }}
              //       error={errors.streetRegistration?.message}
              //       isDisabled={readOnly ||
              //       watchAllFields.noStreetRegistration ||
              //       !watchAllFields.localityCodeRegistration}/>
              //   )}/>
              <Input
                placeholder={terms.STREET}
                markRequired={isRegRussia}
                {...fields.streetRegistration}
                onInput={handleClick}
                error={errors.streetRegistration?.message}
                disabled={readOnly || watchAllFields.noStreetRegistration}
              />
            ) : (
              <Input
                placeholder={terms.STREET}
                markRequired={!watchAllFields.noStreetRegistration}
                {...fields.streetRegistration}
                error={errors.streetRegistration?.message}
                disabled={readOnly || watchAllFields.noStreetRegistration}
              />
            )}
            <Checkbox
              text={terms.NO_STREET}
              className="profile-form__checkbox"
              {...fields.noStreetRegistration}
              disabled={readOnly}
            />
            {!isRussianCitizenship && (
              <Input
                className="profile-form__field"
                placeholder={terms.ZIP_CODE}
                markRequired={true}
                {...fields.postcodeRegistration}
                error={errors.postcodeRegistration?.message}
                disabled={readOnly}
              />
            )}
          </div>
          <div className="profile-form__field profile-form__field--container">
            {isRegRussia && !watchAllFields.noStreetRegistration ? (
              <>
                <Input
                  className="profile-form__input profile-form__input--fb-33"
                  placeholder={terms.BUILDING}
                  markRequired={true}
                  {...fields.houseRegistration}
                  onChange={handleFocus}
                  error={errors.houseRegistration?.message}
                  disabled={readOnly}
                />
                <Input
                  className="profile-form__input profile-form__input--fb-33"
                  placeholder={terms.SITE}
                  {...fields.buildingRegistration}
                  onInput={handleClick}
                  error={errors.buildingRegistration?.message}
                  disabled={readOnly}
                />
              </>
            ) : (
              <>
                <Input
                  className="profile-form__input profile-form__input--fb-33"
                  placeholder={terms.BUILDING}
                  markRequired={true}
                  {...fields.houseRegistration}
                  error={errors.houseRegistration?.message}
                  disabled={readOnly}
                />
                <Input
                  className="profile-form__input profile-form__input--fb-33"
                  placeholder={terms.SITE}
                  {...fields.buildingRegistration}
                  error={errors.buildingRegistration?.message}
                  disabled={readOnly}
                />
              </>
            )}
            <Input
              className="profile-form__input profile-form__input--fb-33"
              placeholder={terms.Apartment}
              {...fields.apartmentRegistration}
              onInput={handleClick}
              disabled={readOnly}
              error={errors.apartmentRegistration?.message}
            />
          </div>
          {/*<Input*/}
          {/*  type={'hidden'}*/}
          {/*  // className="profile-form__field"*/}
          {/*  //placeholder={terms.ZIP_CODE}*/}
          {/*  {...fields.postcodeRegistration}*/}
          {/*  disabled={readOnly}*/}
          {/*  //error={errors.postcodeRegistration?.message}*/}
          {/*/>*/}
        </div>
        {isRegRussia &&
          isRussianCitizenship &&
          watch('cityRegistration') &&
          watch('regionRegistration') && (
            <div className="profile-form__field profile-form-dadata__filed">
              <>
                <label>{terms.CHECK_ADDRESS}</label>
                <AddressSuggestions
                  token={key}
                  value={value}
                  ref={suggestionsRef}
                  onChange={handleOption}
                  delay={1000}
                />
                <Input
                  type={'hidden'}
                  {...fields.apiKladr}
                  disabled={readOnly}
                  error={errors.apiKladr?.message}
                />
                <Input
                  type={'hidden'}
                  {...fields.dadataField}
                  disabled={readOnly}
                  error={errors.apiKladr?.message}
                />
                <Input
                  type={'hidden'}
                  {...fields.dadataValue}
                  disabled={readOnly}
                  error={errors.apiKladr?.message}
                />
                <Input
                  type={'hidden'}
                  {...fields.dadataUnrestrictedValue}
                  disabled={readOnly}
                  error={errors.apiKladr?.message}
                />

                <Input
                  type={'hidden'}
                  {...fields.apiFias}
                  disabled={readOnly}
                  // error={errors.apiFias?.message}
                />
                {/*<a onClick={handleClick}>Проверить адрес</a>*/}
              </>
            </div>
          )}
        {isRussianCitizenship && (
          <Checkbox
            text={terms.PERMANENT_PASSPORT_ADDRESS_AND_HOME_ADDRESS_THE_SAME}
            className="profile-form__checkbox  profile-form__checkbox--mt-24"
            {...fields.actisreg}
            disabled={readOnly || !isRussianCitizenship}
          />
        )}
      </ProfileSection>

      {!actisreg && isRussianCitizenship && (
        <ProfileSection title={terms.CURRENT_HOME_ADDRESS}>
          <div className="profile-form__fields">
            <Controller
              name="nationalActual"
              control={control}
              render={({ field }) => (
                <div
                  className={classNames(
                    'select-container profile-form__field profile-form__field--fb-100',
                    {
                      ['select-container--active']: residence?.nationalActual,
                    }
                  )}
                >
                  {code == '2' ? (
                    <Select
                      noOptionsMessage={() => 'Нет данных'}
                      placeholder={
                        <span
                          className={classNames('select-container__placeholder', {
                            ['select-container__placeholder--required']: true,
                          })}
                        >
                          {terms.COUNTRY}
                        </span>
                      }
                      className="react-select-container"
                      classNamePrefix="react-select"
                      autoFocus={false}
                      {...nationalSelectPropsIno}
                      {...field}
                      onFocus={(e) => {
                        setActive(true)
                      }}
                      onMenuScrollToBottom={() => {
                        nationalSelectPropsIno.onPaginate()
                      }}
                      onChange={(newValue) => {
                        setValue('nationalActual', newValue)
                        setValue('regionActual', '')
                        setValue('regionCodeActual', null)
                        setValue('districtActual', null)
                        setValue('districtCodeActual', null)
                        setValue('cityActual', null)
                        setValue('localityCodeActual', null)
                        setValue('streetActual', null)
                        setValue('noStreetActual', false)
                        setValue('streetCodeActual', null)
                        setValue('houseActual', null)
                        setValue('kladrActual', null)
                        setValue('apiFiasActual', null)
                        setValue('apiKladrActual', null)
                        setValue('dadataFieldActual', null)
                        setValue('dadataValueActual', null)
                        setValue('dadataUnrestrictedValueActual', null)
                      }}
                    />
                  ) : (
                    <Select
                      noOptionsMessage={() => 'Нет данных'}
                      placeholder={
                        <span
                          className={classNames('select-container__placeholder', {
                            ['select-container__placeholder--required']: true,
                          })}
                        >
                          {terms.COUNTRY}
                        </span>
                      }
                      className="react-select-container"
                      classNamePrefix="react-select"
                      autoFocus={false}
                      {...nationalSelectPropsRus}
                      {...field}
                      onFocus={(e) => {
                        setActive(true)
                      }}
                      onMenuScrollToBottom={() => {
                        nationalSelectPropsRus.onPaginate()
                      }}
                      onChange={(newValue) => {
                        setValue('nationalActual', newValue)
                        setValue('regionActual', '')
                        setValue('regionCodeActual', null)
                        setValue('districtActual', null)
                        setValue('districtCodeActual', null)
                        setValue('cityActual', null)
                        setValue('localityCodeActual', null)
                        setValue('streetActual', null)
                        setValue('noStreetActual', false)
                        setValue('streetCodeActual', null)
                        setValue('houseActual', null)
                        setValue('kladrActual', null)
                        setValue('apiFiasActual', null)
                        setValue('apiKladrActual', null)
                        setValue('dadataFieldActual', null)
                        setValue('dadataValueActual', null)
                        setValue('dadataUnrestrictedValueActual', null)
                      }}
                    />
                  )}
                </div>
              )}
            />
            {isActRussia ? (
              <Input
                className="profile-form__field"
                placeholder={terms.REGION}
                markRequired={isActRussia}
                {...fields.regionActual}
                onInput={handleClickActual}
                error={errors.regionActual?.message}
                disabled={readOnly}
              />
            ) : (
              <Input
                className="profile-form__field"
                placeholder={terms.REGION}
                markRequired={isActRussia}
                {...fields.regionActual}
                error={errors.regionActual?.message}
                disabled={readOnly}
              />
            )}
            {isActRussia ? (
              <Input
                type={'hidden'}
                //className="profile-form__field"
                //placeholder={terms.DISTRICT}
                markRequired={isActRussia}
                {...fields.districtActual}
                //error={errors.districtActual?.message}
                disabled={readOnly}
              />
            ) : (
              <Input
                type={'hidden'}
                //className="profile-form__field"
                //placeholder={terms.DISTRICT}
                markRequired={isActRussia}
                {...fields.districtActual}
                //error={errors.districtActual?.message}
                disabled={readOnly}
              />
            )}
            {isActRussia ? (
              <Input
                className="profile-form__field"
                placeholder={terms.CITY}
                markRequired={isActRussia}
                {...fields.cityActual}
                onInput={handleClickActual}
                disabled={readOnly}
                error={errors.cityActual?.message}
              />
            ) : (
              <Input
                className="profile-form__field"
                placeholder={terms.CITY}
                markRequired={isActRussia}
                {...fields.cityActual}
                disabled={readOnly}
                error={errors.cityActual?.message}
              />
            )}
            <div className="profile-form__field">
              {isActRussia ? (
                <Input
                  placeholder={terms.STREET}
                  markRequired={isActRussia}
                  {...fields.streetActual}
                  onInput={handleClickActual}
                  disabled={readOnly}
                  error={errors.streetActual?.message}
                />
              ) : (
                <Input
                  placeholder={terms.STREET}
                  markRequired={isActRussia}
                  {...fields.streetActual}
                  disabled={readOnly}
                  error={errors.streetActual?.message}
                />
              )}
              <Checkbox
                text={terms.NO_STREET}
                className="profile-form__checkbox"
                {...fields.noStreetActual}
                disabled={readOnly}
              />
            </div>
            <div className="profile-form__field profile-form__field--container">
              {isActRussia && !watchAllFields.noStreetActual ? (
                <>
                  <Input
                    className="profile-form__input profile-form__input--fb-33"
                    placeholder={terms.BUILDING}
                    markRequired={
                      !isActRussia || (isActRussia && !watchAllFields.noStreetActual)
                    }
                    {...fields.houseActual}
                    onChange={handleFocusActual}
                    disabled={readOnly}
                    error={errors.houseActual?.message}
                  />
                  <Input
                    className="profile-form__input profile-form__input--fb-33"
                    placeholder={terms.SITE}
                    {...fields.buildingActual}
                    onInput={handleClickActual}
                    disabled={readOnly}
                    error={errors.buildingActual?.message}
                  />
                </>
              ) : (
                <>
                  <Input
                    className="profile-form__input profile-form__input--fb-33"
                    placeholder={terms.BUILDING}
                    markRequired={
                      !isActRussia || (isActRussia && !watchAllFields.noStreetActual)
                    }
                    {...fields.houseActual}
                    disabled={readOnly}
                    error={errors.houseActual?.message}
                  />
                  <Input
                    className="profile-form__input profile-form__input--fb-33"
                    placeholder={terms.SITE}
                    {...fields.buildingActual}
                    disabled={readOnly}
                    error={errors.buildingActual?.message}
                  />
                </>
              )}
              <Input
                className="profile-form__input profile-form__input--fb-33"
                placeholder={terms.Apartment}
                {...fields.apartmentActual}
                onInput={handleClickActual}
                disabled={readOnly}
                error={errors.apartmentActual?.message}
              />
            </div>
          </div>
          <div className="profile-form__field profile-form-dadata__filed">
            {isActRussia && watch('cityActual') && watch('regionActual') ? (
              <>
                <label>{terms.CHECK_ADDRESS}</label>
                <AddressSuggestions
                  token={key}
                  value={value}
                  ref={suggestionsRefActual}
                  onChange={handleOptionActual}
                  delay={1000}
                />
                <Input
                  type={'hidden'}
                  {...fields.apiKladrActual}
                  disabled={readOnly}
                  error={errors.apiKladrActual?.message}
                />
                <Input
                  type={'hidden'}
                  {...fields.apiFiasActual}
                  disabled={readOnly}
                  // error={errors.postcodeRegistration?.message}
                />
                <Input
                  type={'hidden'}
                  {...fields.dadataFieldActual}
                  disabled={readOnly}
                  // error={errors.postcodeRegistration?.message}
                />
                <Input
                  type={'hidden'}
                  {...fields.dadataValueActual}
                  disabled={readOnly}
                  // error={errors.postcodeRegistration?.message}
                />
                <Input
                  type={'hidden'}
                  {...fields.dadataUnrestrictedValueActual}
                  disabled={readOnly}
                  // error={errors.postcodeRegistration?.message}
                />
                {/*<a onClick={handleClick}>Проверить адрес</a>*/}
              </>
            ) : (
              <></>
            )}
          </div>
        </ProfileSection>
      )}

      <ProfileSection markRequired={true} title={terms.I_NEED_PLACE_OF_DORMITORY}>
        <RadioButton className="profile-form__radio">
          <RadioButton.Radio
            disabled={readOnly}
            {...fields.needHostel}
            value="need"
            text={terms.NEED}
          />
          <RadioButton.Radio
            disabled={readOnly}
            {...fields.needHostel}
            value="no need"
            text={terms.NO_NEED}
          />
        </RadioButton>
        {errors.needHostel?.message && (
          <div className="profile-form__error legal-representative-form__checkbox">
            {errors.needHostel?.message}
          </div>
        )}
        <div className={'profile-form__field'}>
          <p style={{ whiteSpace: 'break-spaces' }}>
            {terms.NOTICE} (
            <a
              href="https://students.spbu.ru/files/10793_1_17082023.pdf"
              target="_blank"
              className="link_to_information"
            >
              https://students.spbu.ru/files/10793_1_17082023.pdf
            </a>
            ).
            <br />
            <br />
            <p>{terms.NOTICE_LIST_1}</p>
            <br />
            <a
              href="https://students.spbu.ru/mmen-obwezhitija.html"
              target="_blank"
              className="link_to_information"
            >
              https://students.spbu.ru/mmen-obwezhitija.html
            </a>
            <br />
            <br />
            <a
              href="https://campus.spbu.ru/"
              target="_blank"
              className="link_to_information"
            >
              https://campus.spbu.ru/
            </a>
          </p>
        </div>
      </ProfileSection>
    </ProfileForm>
  )
}

export default ResidenceForm
