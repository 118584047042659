import React, { useEffect } from 'react'
import { Controller, FieldError } from 'react-hook-form'

import TemplateModal from '../TemplateModal'
import terms from '@common/terms'
import uploadDocsTypes from '@common/uploadDocsTypes'
import ProfileSection from '@components/ProfileSection'
import useAddPrivilegesForm from '@hooks/useAddPrivilegesForm'
import useAddPrivilegesForminitialization from '@hooks/useAddPrivilegesForminitialization'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useUploadDocuments from '@hooks/useUploadDocuments'
import { LgotaDocs, SelectCodeOption, SelectOption } from '@models'
import { modalDataSelector } from '@redux/actions/selectors'
import { getLgotaDocType } from '@redux/reducers'
import Button from '@ui/Button'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import Select from '@ui/Select'

const AddPrivilegesModal = () => {
  const defaultData: LgotaDocs = useAppSelector(modalDataSelector)

  const {
    control,
    fields,
    onSubmit,
    setValue,
    loading,
    watch,
    clearErrors,
    formState: { errors },
  } = useAddPrivilegesForm(defaultData ? 'EDIT' : 'ADD')

  const { typeDoc, lgotaDocType } = useAddPrivilegesForminitialization({
    setValue,
  })

  const dispatch = useAppDispatch()
  const {
    onChange,
    docs,
    onDrop,
    onDelete,
    docId,
    getUploadedDoc,
  } = useUploadDocuments('LGOTA')

  const isEdit = defaultData
    ? terms.EDIT_PRIVILEGES_TITLE
    : terms.ADD_PRIVILEGES_TITLE

  useEffect(() => {
    setValue('typeDoc', uploadDocsTypes['LGOTA'])
    if (defaultData?.docId) {
      getUploadedDoc(defaultData.docId)
    }
  }, [defaultData])

  const lgotaId = (watch('lgotaId') as unknown) as SelectOption | undefined

  useEffect(() => {
    if (lgotaId?.value) {
      dispatch(getLgotaDocType(lgotaId.value))
    }
  }, [lgotaId])

  useEffect(() => {
    if (docId) {
      setValue('docId', docId)
    }
  }, [docId])

  useEffect(() => {
    if (docs.length) {
      clearErrors('documents')
    }
    setValue('documents', docs)
  }, [docs])

  return (
    <TemplateModal title={isEdit} clearTypeDoc="LGOTA">
      <form onSubmit={onSubmit}>
        <div className="profile-form__fields">
          <Controller
            name="lgotaId"
            control={control}
            render={({ field }) => (
              <Select<SelectCodeOption>
                options={typeDoc}
                placeholder={terms.TYPE_LGOTA}
                className="profile-form__field"
                markRequired={true}
                {...(field as unknown)}
                error={errors.lgotaId?.message}
              />
            )}
          />

          <Controller
            name="lgotaDocId"
            control={control}
            render={({ field }) => (
              <Select<SelectCodeOption>
                options={lgotaDocType}
                placeholder={terms.TYPE_DOCUMENT}
                className="profile-form__field"
                markRequired={true}
                {...(field as unknown)}
                error={errors.lgotaDocId?.message}
              />
            )}
          />
          <Input
            className="profile-form__field"
            placeholder={terms.NUMBER_DOCUMENT}
            markRequired={true}
            {...fields.number}
            error={errors.number?.message}
          />
          <Input
            className="profile-form__field"
            placeholder={terms.DATE_ISSUE}
            type="date"
            {...fields.dateIssue}
            error={errors.dateIssue?.message}
          />
        </div>
        <ProfileSection title={terms.DOCUMENT} markRequired={true} fileUploadingHint>
          <FileUploader
            className="profile-form__file-uploader"
            onChange={onChange}
            onDelete={onDelete}
            onDrop={onDrop}
            files={docs}
          />
          {errors.documents && (
            <div className="profile-form__error">
              {((errors.documents as unknown) as FieldError | undefined)?.message}
            </div>
          )}
        </ProfileSection>
        <Button
          theme="success"
          loading={loading}
          className="profile-form__button profile-form__button--align-left"
        >
          {terms.SAVE}
        </Button>
      </form>
    </TemplateModal>
  )
}

export default AddPrivilegesModal
