import React from 'react'

import Footer from 'components/Footer'
import Header from 'components/Header'
import Routing from 'components/Routing'
import ToastsContainer from 'components/ToastsContainer'
import compose from 'compose-function'
import { withRedux } from 'containers/with-redux-provider'
import { withRouter } from 'containers/with-router-provider'
import '@fontsource/pt-sans/400.css'
import '@fontsource/pt-sans/700.css'

import { locale } from '@common/terms'
import ModalLayer from '@modals/ModalLayer'

export const Root = () => {
  return (
    <div className="app" lang={locale}>
      <Header />
      <ToastsContainer />
      <div className="app__body">
        <Routing />
      </div>
      <Footer />
      <ModalLayer />
    </div>
  )
}

const withProviders = compose(withRedux, withRouter)
export default withProviders(Root)
