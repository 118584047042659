import React from 'react'

import Button from 'components/generic/Button'
import Input from 'components/generic/Input'
import Link from 'components/generic/Link'
import RouteButtonLink from 'components/generic/RouteButtonLink'
import RouteLink from 'components/generic/RouteLink'
import TemplateRusAuthForm from 'components/TemplateRusAuthForm'
import useSignInForm from 'hooks/useSignInForm'

import terms from '@common/terms'

type SignInFormProps = {
  title: string
  moderator?: boolean
  dataView: any
}

const SignInForm = ({ dataView, title, moderator }: SignInFormProps) => {
  const {
    fields,
    onSubmit,
    loading,
    formState: { errors },
  } = useSignInForm()
  let image
  if (dataView['picture']) {
    image = `data:image/jpeg;base64,${dataView['picture']}`
  }
  if (dataView['link'] == null) {
    dataView['link'] = '/sign-up'
  }
  return (
    <TemplateRusAuthForm title={title} data={dataView} message>
      <form onSubmit={onSubmit}>
        {moderator ? (
          <>
            <div className="auth-form__body auth-form__body-moderator">
              <div className="auth-form__policy">
                <Input
                  placeholder={terms.EMAIL}
                  className="auth-form__input"
                  error={errors.email?.message}
                  {...fields.email}
                />
              </div>
              <div className="auth-form__fields">
                <Input
                  placeholder={terms.PASSWORD}
                  enableSwitcher={true}
                  className="auth-form__input"
                  error={errors.password?.message}
                  {...fields.password}
                />
              </div>
            </div>
            <div className="auth-form__buttons">
              <Button
                theme="success"
                className="auth-form__button"
                type="submit"
                disabled={loading}
              >
                {terms.LOG_IN}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="auth-form__body">
              <div className="auth-form__policy">
                <div className="auth-form__image">
                  {/*<img*/}
                  {/*  src={require('../../assets/images/SignTestImage.png')}*/}
                  {/*  alt=""*/}
                  {/*  style={{ width: '100%', minWidth:'80%', height: '100%', marginBottom: '15px' }}*/}
                  {/*/>*/}
                  <img
                    src={image}
                    alt=""
                    style={{
                      width: '100%',
                      minWidth: '80%',
                      height: 'auto',
                      marginBottom: '15px',
                    }}
                  />
                  <div className="auth_invite_text_message ">
                    {dataView['textPicture'] + ' '}
                    {/*{terms.REVIEW_INFORMATION_ABOUT_ADMISSION + ' '}*/}
                    <a className="auth-invite__link" href={dataView['link']}>
                      {dataView['textPictureLink']}
                    </a>
                    {/*<RouteLink className="auth-invite__link" to={dataView['link']}>*/}
                    {/*  {dataView['textPictureLink']}*/}
                    {/*  /!*{terms.COMPLETE_REGISTRATION_PROCEDURE}*!/*/}
                    {/*</RouteLink>*/}
                  </div>
                </div>
                {/*<p className="auth-form__paragraph">*/}
                {/*  {terms.REVIEW_INFORMATION_ABOUT_ADMISSION + ' '}*/}
                {/*  <RouteLink to="/sign-up">*/}
                {/*    {terms.COMPLETE_REGISTRATION_PROCEDURE}*/}
                {/*  </RouteLink>*/}
                {/*</p>*/}
                <p className="auth-form__paragraph">
                  <Link
                    href="https://abiturient.spbu.ru/programs/bakalavriat/"
                    target="_blank"
                  >
                    {terms.USER_MANUAL_FOR_APPLICANTS}
                  </Link>
                </p>
                {/*<p className="auth-form__paragraph">*/}
                {/*  <Link*/}
                {/*    href="/documents/User manual for Russian applicants.pdf"*/}
                {/*    target="_blank"*/}
                {/*  >*/}
                {/*    {terms.USER_MANUAL}*/}
                {/*  </Link>*/}
                {/*</p>*/}
              </div>
              <div className="auth-form__fields">
                <p className="auth-form__join">{terms.IN_TO_CABINET}</p>
                <div className="auth-login__input">
                  <Input
                    placeholder={terms.EMAIL}
                    className="auth-form__input"
                    error={errors.email?.message}
                    {...fields.email}
                  />
                </div>
                <div className="auth-login__input">
                  <Input
                    placeholder={terms.PASSWORD}
                    enableSwitcher={true}
                    className="auth-form__input"
                    error={errors.password?.message}
                    {...fields.password}
                  />
                </div>
                <RouteLink
                  to="/reset-password"
                  className="auth-form__question auth-form__forgotten"
                >
                  {terms.FORGOT_PASSWORD}
                </RouteLink>
                <div>
                  <p className="auth-form__alreadyHave">
                    {terms.IF_ALREADY_HAVE_ACCOUNT}
                  </p>
                </div>
                <div className="auth-form__buttons">
                  <RouteButtonLink to="/rus-sign-up" className="auth-form__button">
                    {terms.REGISTER}
                  </RouteButtonLink>
                  <Button
                    theme="success"
                    className="auth-form__button"
                    type="submit"
                    disabled={loading}
                  >
                    {terms.LOG_IN}
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </form>
    </TemplateRusAuthForm>
  )
}

export default SignInForm
