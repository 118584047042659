import terms from '@common/terms'

export const routes: { title: string; to: string; id: string }[] = [
  {
    to: 'trajectory',
    title: terms.TRAJECTORY_FOR_ADMISSION,
    id: '1',
  },
  {
    to: 'training-level',
    title: terms.LEVEL_OF_TRAINING,
    id: '2',
  },
  {
    to: 'filling-statements',
    title: terms.FILLING_APPLICATION,
    id: '3',
  },
]
