import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import TrashIcon from '@assets/images/Trash.svg'
import {
  ASPERATNUTA_RF,
  BACHELOR_INO,
  BACHELOR_RF,
  BACHELOR_TASHKENT,
  MAGISTRA_INO,
  MAGISTRA_RF,
  MAGISTRA_TASHKENT,
  QUOTA_ID,
  SPO_ID,
  STATUS_APPROVED,
  STATUS_CONSIDERATION,
  STATUS_DRAFT,
  STATUS_EDIT_DIRECTION,
  STATUS_SEND_EDITING,
  TRAINING_PREPARATION,
} from '@common/constants'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useStatementsList from '@hooks/useStatementsList'
import { ViewerElementStatements } from '@models'
import { getScenarioKeys, showModal } from '@redux/action-types'
import Button from '@ui/Button'
import useAppSelector from '@hooks/useAppSelector'
import { selectScenarioKeysOptions } from '@redux/actions/selectors'

type CreatePageStatementsListProps = {
  list: ViewerElementStatements[]
}

const StatementsList = ({ list }: CreatePageStatementsListProps, scenarioKeys) => {
  const navigate = useNavigate()
  const { fixDirection, fixStatements, deleteStatements, revoke } =
    useStatementsList()
  const goStatements = (id: number) => {
    navigate(`../${id}/filling-statements`)
  }
  if (!scenarioKeys) {
    useEffect(() => {
      dispatch(getScenarioKeys('Scenario'))
    }, [])
  }
  let scenario_Keys
  const sKeys = useAppSelector(selectScenarioKeysOptions)
  if (sKeys) {
    scenario_Keys = {
      level_training_spo: sKeys.find((value) => value.uniqueKeyOnSite == SPO_ID),
      level_training_preparation: sKeys.find(
        (value) => value.uniqueKeyOnSite == TRAINING_PREPARATION
      ),
      level_training_bachelor: sKeys.find(
        (value) => value.uniqueKeyOnSite == BACHELOR_RF
      ),
      level_training_bachelor_ino: sKeys.find(
        (value) => value.uniqueKeyOnSite == BACHELOR_INO
      ),
      level_training_bachelor_tashkent: sKeys.find(
        (value) => value.uniqueKeyOnSite == BACHELOR_TASHKENT
      ),
      magistra_tashkent: sKeys.find(
        (value) => value.uniqueKeyOnSite == MAGISTRA_TASHKENT
      ),
      magistra_rf: sKeys.find((value) => value.uniqueKeyOnSite == MAGISTRA_RF),
      magistra_ino: sKeys.find((value) => value.uniqueKeyOnSite == MAGISTRA_INO),
      asperatnuta_rf: sKeys.find((value) => value.uniqueKeyOnSite == ASPERATNUTA_RF),
      quota: sKeys.find((value) => value.uniqueKeyOnSite == QUOTA_ID),
    }
  }
  scenarioKeys = scenario_Keys

  const goSendStatements = (id: number) => {
    navigate(`../${id}/filling-statements/blank`)
  }
  const dispatch = useAppDispatch()

  const sortList: ViewerElementStatements[] = list
    .filter((el) => el.statusStatement !== null)
    .sort((a, b) => a.id - b.id)

  const currentDate = new Date()
  const endDate1 = new Date('2024-08-20T23:59:00+03:00')
  const endDate2 = new Date('2024-08-21T23:59:00+03:00')

  const isHiddenWithdrawApplicationButton = (statement: any) => {
    if (
      (statement.scenarioId === scenarioKeys.level_training_bachelor.tdId ||
        statement.scenarioId === scenarioKeys.level_training_bachelor_ino.tdId) &&
      currentDate > endDate1
    ) {
      return false
    }

    if (
      statement.scenario.code === scenarioKeys.magistra_rf.onesKey &&
      currentDate > endDate2
    ) {
      return false
    }

    return true
  }

  return (
    <section className={'statements-container'}>
      {sortList.map((el: ViewerElementStatements, index) => (
        <div
          key={index}
          className="statements-container__element"
          onClick={() => goStatements(el.id)}
        >
          <h2>
            {terms.STATEMENT} {el.numberStatement ? '№' + el.numberStatement : null}
          </h2>
          <div className={'statements-container__element-text'}>
            <p>
              {terms.LEVEL_OF_TRAINING}:{' '}
              <b>{el.scenario?.scenarioTranslate[0]?.name}</b>
            </p>
            {el.paymentType == null ? (
              <p>
                {terms.PAYMENT_TYPE_FORM}: <b>{terms.BUDGET_CONTRACT} </b>
              </p>
            ) : (
              <p>
                {terms.PAYMENT_TYPE_FORM}:{' '}
                <b>{el.paymentType == 1 ? terms.FREE_EDU : terms.PAYMENT_EDU} </b>
              </p>
            )}
            <p>
              {terms.DATE_AND_TIME}: <b>{el.dateSendToReview}</b>
            </p>
            <p>
              {terms.STATUS}:{' '}
              <b className={'success'}>{el.statusStatement?.translate[0]?.name}</b>
            </p>
          </div>
          {Number(el.statusStatement.code) === STATUS_DRAFT && (
            <div className={'statements-container__element-btn-panel'}>
              <Button
                type={'button'}
                theme={'success'}
                onClick={(event) => {
                  event.stopPropagation()
                  goSendStatements(el.id)
                }}
              >
                Отправить заявление
              </Button>
              <Button
                type={'button'}
                className="statements-container__element-btn-panel_icon"
                onClick={(event) => {
                  event.stopPropagation()
                  dispatch(
                    showModal({
                      name: 'IS_DELETE_APPLICATION_ADMISSION',
                      data: {
                        statementId: el.id,
                        content: terms.STATEMENT + ' №' + el.numberStatement,
                      },
                    })
                  )
                }}
                disabled={false}
              >
                <TrashIcon />
              </Button>
            </div>
          )}
          {Number(el.statusStatement.code) === STATUS_CONSIDERATION && (
            <div className={'statements-container__element-btn-panel'}>
              <Button
                type={'button'}
                theme={'success'}
                onClick={(event) => {
                  event.stopPropagation()
                  fixStatements(el.id)
                }}
              >
                Забрать на редактирование
              </Button>
            </div>
          )}
          {Number(el.statusStatement.code) === STATUS_SEND_EDITING && (
            <div className={'statements-container__element-btn-panel'}>
              <Button
                type={'button'}
                theme={'success'}
                onClick={(event) => {
                  event.stopPropagation()
                  goSendStatements(el.id)
                }}
              >
                Отправить заявление
              </Button>
              <Button
                type={'button'}
                className="statements-container__element-btn-panel_icon"
                onClick={(event) => {
                  event.stopPropagation()
                  deleteStatements(el.id)
                }}
              >
                <TrashIcon />
              </Button>
            </div>
          )}

          {Number(el.statusStatement.code) === STATUS_APPROVED &&
            isHiddenWithdrawApplicationButton(el) && (
              <div className={'statements-container__element-btn-panel'}>
                <Button
                  type={'button'}
                  className="statements-container__element-btn-panel_icon"
                  disabled={Number(el.trajectoryId) === 2}
                  onClick={(event) => {
                    event.stopPropagation()
                    dispatch(
                      showModal({
                        name: 'WITHDRAW_MODAL',
                        data: {
                          revoke: () => revoke(el.id),
                        },
                      })
                    )
                  }}
                >
                  Отозвать заявление
                </Button>
              </div>
            )}
          {Number(el.statusStatement.code) === STATUS_EDIT_DIRECTION && (
            <div className={'statements-container__element-btn-panel'}>
              <Button
                type={'button'}
                theme={'success'}
                onClick={(event) => {
                  event.stopPropagation()
                  goSendStatements(el.id)
                }}
              >
                Отправить заявление
              </Button>
            </div>
          )}
        </div>
      ))}
    </section>
  )
}

export default StatementsList
