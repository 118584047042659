import { TFileType, TMimeType } from '@common/manual'
import terms from '@common/terms'
import { UploadDocKey } from '@common/uploadDocsTypes'
import useAppDispatch from '@hooks/useAppDispatch'
import { createToast } from '@redux/action-types'
import api from '@services/api'
import { base64toFile, downloadFile } from '@utils/common'

const useDownloadDocument = (moderator = false) => {
  const dispatch = useAppDispatch()

  const downloadDocument = (
    typeDoc: UploadDocKey,
    mimeType: TMimeType,
    fileType: TFileType,
    docId: number,
    filename?: string,
    statementId?: number,
    user_id?: string | number
  ) => {
    if (moderator) {
      api.client
        .getModeratorDocs(typeDoc, docId, statementId, user_id)
        .then((resp) =>
          base64toFile(resp.data.document, mimeType, fileType, filename)
        )
        .then(downloadFile)
        .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')))
    } else {
      api.client
        .getDocs(typeDoc, docId, user_id)
        .then((resp) =>
          base64toFile(resp.data.document, mimeType, fileType, filename)
        )
        .then(downloadFile)
        .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')))
    }
  }

  return { downloadDocument }
}

export default useDownloadDocument
