import React, { useEffect, useMemo, useState } from 'react'
import type { Control, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import InputMask from 'react-input-mask'

import terms from '@common/terms'
import ProfileSection from '@components/ProfileSection'
import type {
  AnotherPassports,
  SelectOption,
  ViewerResultExamFormData,
} from '@models'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import ReactSelect from '@ui/Select'
import { ViewerPersonalDataEgeForm } from '@models'
import useUploadDocuments from '@hooks/useUploadDocuments'
import useAppSelector from '@hooks/useAppSelector'
import { selectTabEgeList } from '@redux/actions/selectors'
import { base64ToPdf } from '@utils/common'

type Props = {
  item: AnotherPassports | any
  isReadOnly: boolean
  index: number
  register: UseFormRegister<ViewerResultExamFormData>
  options: SelectOption[]
  setValue: UseFormSetValue<ViewerResultExamFormData>
  control: Control<ViewerResultExamFormData, any>
  passportIds: any
  file: any
}

const AnotherDocs = (props: Props) => {
  const {
    item,
    isReadOnly,
    index,
    register,
    options,
    setValue,
    control,
    passportIds,
    file,
  } = props

  const [docs, setDocs] = useState<File[]>([])
  const [type, setType] = useState<number | null>(null)
  const basePropsInput = {
    className: 'result-exam__document-field result-exam__document-field-full-width',
    disabled: isReadOnly,
  }
  useEffect(() => {
    if (file) {
      base64ToPdf(file.file, file.name).then((file) => setDocs([file]))
    }
  }, [])
  const isRussianPassport = useMemo(() => {
    return type === passportIds.russia_citizen_passport_code.tdId
  }, [type, passportIds])

  const isRussianForeignPassport = useMemo(() => {
    return type === passportIds.russia_foreign_passport_code.tdId
  }, [type, passportIds])

  const isForeignPassport = useMemo(() => {
    return !isRussianPassport && !isRussianForeignPassport
  }, [isRussianPassport, isRussianForeignPassport])

  const onChange = (files: File[]) => {
    setDocs(files)
  }

  const onDrop = (files: File[]) => {
    setDocs((prev) => [...prev, ...files])
  }

  const onDelete = (file: File) =>
    setDocs((prev) => prev.filter((f) => f.name !== file.name))

  useEffect(() => {
    if (docs) {
      setValue(`passports.${index}.files`, docs)
    } else {
      setValue(`passports.${index}.files`, [])
    }
  }, [docs])

  return (
    <div>
      <div>
        {/* Тип документа */}
        <Controller
          name={`passports.${index}.typeIdentityDocumentId`}
          control={control}
          render={({ field }) => (
            <ReactSelect<SelectOption>
              options={options}
              placeholder={terms.DOCUMENT_TYPE}
              className={basePropsInput.className}
              markRequired={true}
              {...(field as unknown)}
              onChange={(e) => {
                if (e) {
                  setType(e.value)
                  setValue(`passports.${index}.typeIdentityDocumentId`, e.score)
                  field.onChange(e)
                } else {
                  setType(null)
                }
              }}
              isDisabled={isReadOnly}
            />
          )}
        />
      </div>

      <div>
        {/* Фамилия */}
        <Input
          {...basePropsInput}
          placeholder={terms.SURNAME}
          markRequired={true}
          {...register(`passports.${index}.surname` as const, { required: true })}
        />
        {/* Имя */}
        <Input
          {...basePropsInput}
          placeholder={terms.NAME}
          markRequired={true}
          {...register(`passports.${index}.name` as const, { required: true })}
        />
        {/* Отчество */}
        <Input
          {...basePropsInput}
          placeholder={terms.PATRONYMIC_IN_RUSSIAN}
          markRequired={false}
          {...register(`passports.${index}.patronymic` as const, {
            required: false,
          })}
        />
      </div>

      <div>
        {isRussianPassport || isRussianForeignPassport ? (
          <div className="input-container input-container--active profile-form__input--fb-55">
            <div className="input-container__inner">
              <InputMask
                mask={isRussianPassport || isRussianForeignPassport ? '9999' : ''}
                className="input-container__input"
                markRequired={isRussianPassport}
                disabled={isReadOnly}
                {...register(`passports.${index}.serial` as const, {
                  required: isRussianPassport,
                })}
              />
              <label className="input-container__placeholder input-container__placeholder--required">
                {terms.SERIES}
              </label>
            </div>
          </div>
        ) : (
          <Input
            className={`profile-form__input profile-form__input--fb-50 ${
              isForeignPassport && 'profile-form__input--hint-with-spaces'
            }`}
            placeholder={terms.SERIES}
            markRequired={true}
            disabled={isReadOnly}
            {...register(`passports.${index}.serial` as const, { required: true })}
          />
        )}
      </div>

      <div>
        {isRussianPassport || isRussianForeignPassport ? (
          <div className="input-container input-container--active profile-form__input--fb-55">
            <div className="input-container__inner">
              <InputMask
                mask={isRussianPassport || isRussianForeignPassport ? '999999' : ''}
                className="input-container__input"
                markRequired={isRussianPassport}
                disabled={isReadOnly}
                {...register(`passports.${index}.number` as const, {
                  required: isRussianPassport,
                })}
              />
              <label className="input-container__placeholder input-container__placeholder--required">
                {terms.NUMBER}
              </label>
            </div>
          </div>
        ) : (
          <Input
            className={`profile-form__input profile-form__input--fb-50 ${
              isForeignPassport && 'profile-form__input--hint-with-spaces'
            }`}
            placeholder={terms.NUMBER}
            markRequired={true}
            disabled={isReadOnly}
            {...register(`passports.${index}.number` as const, { required: true })}
          />
        )}
      </div>

      <div>
        <Input
          {...basePropsInput}
          placeholder={terms.DATE_OF_ISSUE}
          type="date"
          markRequired={true}
          required={true}
          {...register(`passports.${index}.dateOfIssue` as const, {
            required: true,
          })}
        />
      </div>

      <ProfileSection title={'Добавить документ'} markRequired={true}>
        <p className={'profile-section__hint'}>
          Страница паспорта со сведениями о ранее выданных паспортах
        </p>
        <FileUploader
          files={docs}
          onDrop={onDrop}
          onChange={onChange}
          onDelete={onDelete}
          className="profile-form__file-uploader"
        />
      </ProfileSection>
    </div>
  )
}

export default AnotherDocs
