import React, { useEffect } from 'react'

import './SettingAuthPage.scss'
import Edit from '@assets/images/Edit.svg'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useSiteSettingInfo from '@hooks/useSiteSettingInfo'
import { getAuthPagesList } from '@redux/action-types/admin'
import { selectAdminDirectoriesList } from '@redux/actions/selectors'

const SettingAuthPage = () => {
  const { editDirectory } = useSiteSettingInfo()

  const dispatch = useAppDispatch()

  const directoriesList = useAppSelector(selectAdminDirectoriesList) || { data: [] }

  let data
  useEffect(() => {
    dispatch(getAuthPagesList())
  }, [data])

  return (
    <div>
      <div className="settings-page-header">
        <h1 className="directory-page__title">{terms.LOGIN_REGISTER_PAGES}</h1>
      </div>
      <div className="table-incoming-students">
        {directoriesList['data']?.map(function (d) {
          return (
            <div
              key={d.id}
              className="table-incoming-students__link edit-line-row"
              onClick={() => editDirectory(d)}
            >
              <div>{d.name}</div>
              <div>
                <Edit width="18" height="18" />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SettingAuthPage
