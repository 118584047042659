import { useEffect, useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'

import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import { SelectOption, ViewerDirectionsTrainingSearchFormData } from '@models'
import {
  getFaculty,
  getFormDirectionsTrainingSearch,
  getLevelDirectionsTrainingSearch,
  getPaymentDirectionsTrainingSearch,
} from '@redux/action-types'
import {
  selectFacultyOptions,
  selectLevelDirectionsTrainingSearchOptions,
  selectPaymentDirectionsTrainingSearchOptions,
} from '@redux/actions/selectors/statements'

type useDirectionsTrainingSearchSelectorsProps = {
  setValue: UseFormSetValue<ViewerDirectionsTrainingSearchFormData>
}

const useDirectionsTrainingSearchInitialization = ({
  setValue,
}: useDirectionsTrainingSearchSelectorsProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (formDirectionsTrainingSearchOptions.length === 0)
      dispatch(getFormDirectionsTrainingSearch())
    if (levelDirectionsTrainingSearchOptions.length === 0)
      dispatch(getLevelDirectionsTrainingSearch())
    if (paymentDirectionsTrainingSearchOptions.length === 0)
      dispatch(getPaymentDirectionsTrainingSearch())
    if (facultyOptions.length === 0) {
      dispatch(getFaculty())
    }
  }, [])

  const formDirectionsTrainingSearchOptions = useAppSelector(
    selectLevelDirectionsTrainingSearchOptions
  )
  const levelDirectionsTrainingSearchOptions = useAppSelector(
    selectLevelDirectionsTrainingSearchOptions
  )
  const paymentDirectionsTrainingSearchOptions = useAppSelector(
    selectPaymentDirectionsTrainingSearchOptions
  )
  const facultyOptions = useAppSelector(selectFacultyOptions)

  const useInitializeSelect = (
    formFieldKey: keyof ViewerDirectionsTrainingSearchFormData,
    options: SelectOption[],
    findValue?: number | null
  ) => {
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
      if (!initialized && options.length > 0) {
        const candidate = options.find((o) => o.value === findValue)
        setValue(formFieldKey, candidate || (null as any))
        setInitialized(true)
      }
    }, [options])
  }

  useInitializeSelect('faculty', facultyOptions)
  useInitializeSelect('formEducation', formDirectionsTrainingSearchOptions)
  useInitializeSelect('formPayment', paymentDirectionsTrainingSearchOptions)

  return {
    formDirectionsTrainingSearchOptions,
    levelDirectionsTrainingSearchOptions,
    paymentDirectionsTrainingSearchOptions,
    facultyOptions,
  }
}

export default useDirectionsTrainingSearchInitialization
