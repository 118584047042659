import usePaginatedEntity from '@hooks/usePaginatedEntity'
import { getNationalsRus } from '@redux/action-types'
import {
  selectPaginatedNationals,
  selectPaginatedNationalsLoading,
  selectPaginatedNationalsRus,
} from '@redux/actions/selectors'

const usePaginatedNationalsRus = () => {
  return usePaginatedEntity({
    action: getNationalsRus,
    dataSelector: selectPaginatedNationalsRus,
    loaderSelector: selectPaginatedNationalsLoading,
  })
}

export default usePaginatedNationalsRus
