import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'

import terms from '@common/terms'
import ProfileForm from '@components/ProfileForm'
import ProfileSection from '@components/ProfileSection'
import useAppSelector from '@hooks/useAppSelector'
import useLegalRepresentativeForm from '@hooks/useLegalRepresentativeForm'
import useLegalRepresentativeFormInitialization from '@hooks/useLegalRepresentativeFormInitialization'
import useProfileRoutesContext from '@hooks/useProfileRoutesContext'
import useUploadDocuments from '@hooks/useUploadDocuments'
import { SelectOption } from '@models'
import { selectIsQuestionnaireBlockProfile } from '@redux/actions/selectors'
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import ReactSelect, { SingleValue } from '@ui/Select'
import Select from '@ui/Select'

const LegalRepresentativeForm = (passportids) => {
  const {
    isRussia,
    fields,
    control,
    setValue,
    onSubmit,
    required,
    watch,
    isSeriesRequired,
    isRussianPassport,
    listIssuingAuthority,
    IssuingAuthorityOnChange,
    isDateOfExpirationExists,
    formState: { errors },
  } = useLegalRepresentativeForm(passportids)

  const readOnly = useAppSelector(selectIsQuestionnaireBlockProfile)
  const { onNextPageNavigate } = useProfileRoutesContext()

  const {
    docs,
    onDrop,
    onChange,
    onDelete,
    onUploadDocs,
    getUploadedDoc,
    documentLoaded,
  } = useUploadDocuments(
    'TARGET_AGREEMENT',
    terms.CONSENT_OF_PERSONAL_DATA_PROCESSING
  )

  const {
    phoneCodesSelectProps,
    relationDegreesOptions,
    shouldGetDocument,
    nationalSelectProps,
    typeIdentityDocumentOptions,
  } = useLegalRepresentativeFormInitialization({ setValue })

  const numberOnly = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault()
    }
  }

  useEffect(() => {
    shouldGetDocument && !documentLoaded && getUploadedDoc()
  }, [shouldGetDocument])

  return (
    <ProfileForm
      onSubmit={
        readOnly
          ? (e) => {
              e.preventDefault()
              onNextPageNavigate()
            }
          : (e) => {
              onSubmit(e)
              if (Object.keys(errors).length === 0) {
                onUploadDocs(() => getUploadedDoc())
              }
            }
      }
    >
      <ProfileSection title={terms.BASIC_DATA}>
        <div className="profile-form__fields">
          <Controller
            name="nationality"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                placeholder={terms.CITIZENSHIP}
                className="select-container profile-form__field profile-form__field--fb-100"
                markRequired={true}
                {...nationalSelectProps}
                {...(field as unknown)}
                isDisabled={readOnly}
                error={errors.nationality?.message}
              />
            )}
          />

          {/*<Input*/}
          {/*  className="profile-form__field"*/}
          {/*  placeholder={terms.PATRONYMIC}*/}
          {/*  {...fields.patronymic}*/}
          {/*  disabled={readOnly}*/}
          {/*  error={errors.patronymic?.message}*/}
          {/*/>*/}
          <div className="profile-form__field">
            <Input
              className="legal-representative-form__field"
              placeholder={terms.SURNAME}
              markRequired={!isRussia}
              {...fields.surname}
              disabled={readOnly}
              error={errors.surname?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={terms.NAME}
              markRequired={!isRussia}
              {...fields.name}
              disabled={readOnly}
              error={errors.name?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={terms.PATRONYMIC}
              hint={terms.CYRILLIC_CHARACTERS_ONLY}
              disabled={readOnly || watch('noPatronymic')}
              markRequired={!isRussia}
              {...fields.patronymic}
              error={errors.patronymic?.message}
            />
            <Checkbox
              text={terms.NO_PATRONYMIC}
              className="legal-representative-form__checkbox"
              {...fields.noPatronymic}
              disabled={readOnly}
            />
          </div>
          {!isRussia && (
            <div className="profile-form__field">
              <Input
                className="legal-representative-form__field"
                placeholder={terms.SURNAME_IN_LATIN}
                markRequired={!isRussia}
                {...fields.surnameEn}
                disabled={readOnly}
                error={errors.surnameEn?.message}
              />
              <Input
                className="profile-form__field"
                placeholder={terms.NAME_IN_LATIN}
                {...fields.nameEn}
                disabled={readOnly}
                markRequired={!isRussia}
                error={errors.nameEn?.message}
              />
              <Input
                className="profile-form__field"
                placeholder={terms.PATRONYMC_IN_LATIN}
                disabled={readOnly || watch('noPatronymic')}
                {...fields.patronymicEn}
                error={errors.patronymicEn?.message}
              />
            </div>
          )}
          <div className="legal-representative__field--container">
            <div className="profile-form__field profile-form__field--container">
              <Input
                className="legal-representative-form__field"
                placeholder={terms.DATE_OF_BIRTH}
                type="date"
                max="100"
                min="10"
                markRequired={!isRussia}
                {...fields.dateBirthday}
                disabled={readOnly}
                error={errors.dateBirthday?.message}
              />
              <Controller
                name="relationDegreeId"
                control={control}
                render={({ field }) => (
                  <ReactSelect<SelectOption>
                    options={relationDegreesOptions}
                    placeholder={terms.RELATIONSHIP}
                    className="legal-representative-form__field"
                    markRequired={!isRussia}
                    {...(field as unknown)}
                    isDisabled={readOnly}
                    error={errors.relationDegreeId?.message}
                  />
                )}
              />
            </div>
          </div>
          <div className="profile-form__field profile-form__field--container">
            <Controller
              name="phoneCode"
              control={control}
              render={({ field }) => (
                <ReactSelect<SelectOption>
                  placeholder={terms.COUNTRY_CODE}
                  className="profile-form__input profile-form__input--fb-33"
                  markRequired={isRussia}
                  {...phoneCodesSelectProps}
                  {...(field as unknown)}
                  isDisabled={readOnly}
                  error={errors.phoneCode?.message}
                  styles={{ menu: (base) => ({ ...base, minWidth: '300px' }) }}
                  components={{ SingleValue }}
                />
              )}
            />
            <Input
              className="profile-form__input profile-form__input--fb-66"
              placeholder={terms.MOBILE_PHONE}
              type="tel"
              markRequired={true}
              onKeyPress={numberOnly}
              {...fields.mobile}
              disabled={readOnly}
              error={errors.mobile?.message}
            />
          </div>
          <Input
            className="profile-form__field"
            placeholder={terms.ADDITIONAL_EMAIL_IF_ANY}
            {...fields.dopEmail}
            disabled={readOnly}
            error={errors.dopEmail?.message}
          />
        </div>
      </ProfileSection>
      {/*<ProfileSection title={terms.PASSPORT_DATA_NOT_NECESSARY}>*/}
      {/*  <div className="profile-form__fields">*/}
      {/*    <Controller*/}
      {/*      name="typeIdentityDocumentId"*/}
      {/*      control={control}*/}
      {/*      render={({ field }) => (*/}
      {/*        <Select<SelectOption>*/}
      {/*          className="profile-form__field"*/}
      {/*          {...(field as unknown)}*/}
      {/*          options={typeIdentityDocumentOptions}*/}
      {/*          placeholder={terms.DOCUMENT_TYPE}*/}
      {/*          error={errors.typeIdentityDocumentId?.message}*/}
      {/*          markRequired={true}*/}
      {/*          isClearable={true}*/}
      {/*          isDisabled={readOnly}*/}
      {/*        />*/}
      {/*      )}*/}
      {/*    />*/}
      {/*    <div className="profile-form__field profile-form__field--container">*/}
      {/*      <Input*/}
      {/*        className="profile-form__input profile-form__input--fb-50"*/}
      {/*        placeholder={terms.SERIES}*/}
      {/*        markRequired={isSeriesRequired}*/}
      {/*        {...fields.serialDocument}*/}
      {/*        disabled={readOnly}*/}
      {/*        error={errors.serialDocument?.message}*/}
      {/*      />*/}
      {/*      <Input*/}
      {/*        className="profile-form__input profile-form__input--fb-50"*/}
      {/*        placeholder={terms.NUMBER}*/}
      {/*        markRequired={true}*/}
      {/*        {...fields.numberDocument}*/}
      {/*        disabled={readOnly}*/}
      {/*        error={errors.numberDocument?.message}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    {isRussianPassport && (*/}
      {/*      <Input*/}
      {/*        className="profile-form__field"*/}
      {/*        placeholder={terms.DIVISION_CODE}*/}
      {/*        markRequired={true}*/}
      {/*        {...fields.divisionCode}*/}
      {/*        maxLength={7}*/}
      {/*        disabled={readOnly}*/}
      {/*        onChange={IssuingAuthorityOnChange}*/}
      {/*        error={errors.divisionCode?.message}*/}
      {/*      />*/}
      {/*    )}*/}

      {/*    {isRussianPassport && (*/}
      {/*      <Controller*/}
      {/*        name="whoIssueDocument"*/}
      {/*        control={control}*/}
      {/*        render={({ field }) => (*/}
      {/*          <Select<SelectOption>*/}
      {/*            className="profile-form__field"*/}
      {/*            {...(field as unknown)}*/}
      {/*            options={listIssuingAuthority}*/}
      {/*            placeholder={terms.ISSUING_AUTHORITY}*/}
      {/*            error={errors.whoIssueDocument?.message}*/}
      {/*            markRequired={true}*/}
      {/*            isClearable={true}*/}
      {/*            isDisabled={readOnly}*/}
      {/*          />*/}
      {/*        )}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*    {!isRussianPassport && (*/}
      {/*      <Input*/}
      {/*        className="profile-form__field"*/}
      {/*        placeholder={terms.ISSUING_AUTHORITY}*/}
      {/*        markRequired={true}*/}
      {/*        {...fields.whoIssueDocument}*/}
      {/*        disabled={readOnly}*/}
      {/*        error={errors.whoIssueDocument?.message}*/}
      {/*      />*/}
      {/*    )}*/}
      {/*    <Input*/}
      {/*      className="profile-form__field"*/}
      {/*      placeholder={terms.DATE_OF_ISSUE}*/}
      {/*      type="date"*/}
      {/*      markRequired={true}*/}
      {/*      {...fields.dateOfIssue}*/}
      {/*      disabled={readOnly}*/}
      {/*      error={errors.dateOfIssue?.message}*/}
      {/*    />*/}
      {/*    {isDateOfExpirationExists && (*/}
      {/*      <Input*/}
      {/*        className="profile-form__field"*/}
      {/*        placeholder={terms.DATE_OF_EXPIRATION}*/}
      {/*        type="date"*/}
      {/*        markRequired={true}*/}
      {/*        {...fields.documentValidPeriod}*/}
      {/*        disabled={readOnly}*/}
      {/*        error={errors.documentValidPeriod?.message}*/}
      {/*      />*/}
      {/*    )}*/}

      {/*    <Input*/}
      {/*      className="profile-form__field"*/}
      {/*      placeholder={terms.PLACE_OF_BIRTH}*/}
      {/*      markRequired={true}*/}
      {/*      {...fields.placeBirthday}*/}
      {/*      disabled={readOnly}*/}
      {/*      error={errors.placeBirthday?.message}*/}
      {/*    />*/}
      {/*    <div className="profile-form__field">*/}
      {/*      <Controller*/}
      {/*        name="countryOfBirth"*/}
      {/*        control={control}*/}
      {/*        render={({ field }) => (*/}
      {/*          <Select<SelectOption>*/}
      {/*            placeholder={terms.COUNTRY_OF_BIRTH}*/}
      {/*            markRequired={true}*/}
      {/*            {...nationalSelectProps}*/}
      {/*            {...(field as unknown)}*/}
      {/*            error={errors.countryOfBirth?.message}*/}
      {/*            isClearable={true}*/}
      {/*            isDisabled={readOnly}*/}
      {/*          />*/}
      {/*        )}*/}
      {/*      />*/}
      {/*      /!*{!isNationalRussia && (*!/*/}
      {/*      /!*  <>*!/*/}
      {/*      /!*    <Checkbox*!/*/}
      {/*      /!*      text={terms.I_AM_COMPATRIOT}*!/*/}
      {/*      /!*      className="profile-form__checkbox profile-form__checkbox--mt-24"*!/*/}
      {/*      /!*      {...fields.compatriot}*!/*/}
      {/*      /!*    />*!/*/}
      {/*      /!*  </>*!/*/}
      {/*      /!*)}*!/*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</ProfileSection>*/}

      {/*<ProfileSection
        title={terms.CONSENT_OF_PERSONAL_DATA_PROCESSING}
        markRequired={required}
      >
        <FileUploader
          files={docs}
          disabled={readOnly}
          onChange={onChange}
          onDrop={onDrop}
          onDelete={onDelete}
          className="profile-form__file-uploader"
          description={terms.CONSENT_OF_PERSONAL_DATA_PROCESSING}
        />
      </ProfileSection>*/}
    </ProfileForm>
  )
}

export default LegalRepresentativeForm
