import React, { useEffect } from 'react'

import CreatePageHeader from '@components/CreatePageHeader'
import StatementsList from '@components/StatementsList'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { cleanStatementsElement, getStatementsList } from '@redux/action-types'
import { selectStatementsList } from '@redux/actions/selectors'

const StatementsListPage = (scenarioKeys) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getStatementsList())
    dispatch(cleanStatementsElement())
  }, [])

  const statements = useAppSelector(selectStatementsList)

  return (
    <main className="create-page">
      <CreatePageHeader list={StatementsList} />
      {statements && (
        <StatementsList
          list={statements.filter((el) => el.scenarioId)}
          {...scenarioKeys}
        />
      )}
    </main>
  )
}

export default StatementsListPage
