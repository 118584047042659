import React from 'react'

import EditIcon from '@assets/images/Edit.svg'
import TrashIcon from '@assets/images/Trash.svg'
import {
  ROLE_ADMIN,
  ROLE_MODERATOR,
  ROLE_SUPPORT,
  STATUS_USER_ACTIVE,
  STATUS_USER_BLOCKED,
  STATUS_USER_NOT_ACTIVE,
} from '@common/constants'
import useAdminAdministrationTable from '@hooks/useAdminAdministrationTable'

const headers = ['id', 'email', 'ФИО', 'Активность', 'Роль']

const AdminAdministrationTable = ({ adminUserList }) => {
  const { fixUser, deleteUser } = useAdminAdministrationTable()

  return (
    <table className="administration-page-table">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {adminUserList.map((item, id) => {
          return (
            <tr key={id}>
              <td>{item.id}</td>
              <td>{item.email}</td>
              <td>{`${item.usersTranslate[0]?.name || ''} ${
                item.usersTranslate[0]?.surname || ''
              } ${item.usersTranslate[0]?.patronymic || ''}`}</td>
              <td>
                {item.confirmed === STATUS_USER_ACTIVE
                  ? 'Активный'
                  : item.confirmed === STATUS_USER_BLOCKED
                  ? 'Заблокированный'
                  : item.confirmed === STATUS_USER_NOT_ACTIVE
                  ? 'Неактивный '
                  : '-'}
              </td>
              <td>
                {item.roleId === ROLE_ADMIN
                  ? 'Админ'
                  : item.roleId === ROLE_SUPPORT
                  ? 'Тех. поддержка'
                  : item.roleId === ROLE_MODERATOR
                  ? 'Модератор'
                  : '-'}
              </td>
              <td>
                <div className="buttons">
                  <button type="button" onClick={() => fixUser(item)}>
                    <EditIcon />
                  </button>
                </div>
              </td>
              <td>
                <div className="buttons">
                  <button type="button" onClick={() => deleteUser(item.id)}>
                    <TrashIcon />
                  </button>
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default AdminAdministrationTable
