import React from 'react'
import { useNavigate } from 'react-router-dom'

import classnames from 'classnames'

import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { setModeratorStatementsListOrder } from '@redux/action-types'
import { selectModeratorStatementsListOrder } from '@redux/actions/selectors'
import Button from '@ui/Button'

const TableList = ({ link, mappedData, columns }) => {
  const dispatch = useAppDispatch()

  const setOrder = (value) => {
    dispatch(setModeratorStatementsListOrder(value))
  }
  const order = useAppSelector(selectModeratorStatementsListOrder)

  const navigate = useNavigate()

  return (
    <section className={'table-list'}>
      <header>
        <div className={'table-list__sort'}>
          <p>Сортировать:</p>
          <button
            onClick={() => setOrder(undefined)}
            className={classnames(
              'table-list__radio-btn',
              order === undefined && 'active'
            )}
          >
            по умолчанию
          </button>
          <button
            onClick={() => setOrder('ASC')}
            className={classnames(
              'table-list__radio-btn',
              order === 'ASC' && 'active'
            )}
          >
            по дате (возрастанию)
          </button>
          <button
            onClick={() => setOrder('DESC')}
            className={classnames(
              'table-list__radio-btn',
              order === 'DESC' && 'active'
            )}
          >
            по дате (убыванию)
          </button>
        </div>
      </header>
      <div className="table-list__main">
        <table className="table-list__body">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.accessor}>{column.Header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {mappedData.map((item, i) => {
              return (
                <tr
                  key={item.id + '.' + i}
                  onClick={() => {
                    window.open(`../admin/${link}/${item.id}`)
                  }}
                >
                  {columns.map((column) => (
                    <td key={item.id + '.' + i + '.' + column.accessor}>
                      {item[column.accessor]}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </section>
  )
}

export default TableList
