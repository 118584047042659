import { useForm } from 'react-hook-form'

import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import { SelectOption } from '@models'
import { createToast } from '@redux/action-types'
import api, {
  ResponseOk,
  UpdateDirectionTrainingAssignResponse,
} from '@services/api'

const useEntranceExamsRowForm = () => {
  const { register, control, setValue, handleSubmit } = useForm<{
    discipline: number
  }>({
    mode: 'all',
  })
  const dispatch = useAppDispatch()

  const fields = {
    discipline: register('discipline'),
  }

  const onSubmit = handleSubmit((data: any) => {
    const normalizedData: { discipline: number } = {
      discipline: data.discipline.value,
    }
    api.client
      .postStatementDisciplines(normalizedData)
      .then((response) => {
        const { data } = response as ResponseOk
        dispatch(createToast(data.message, 'success'))
      })
      .catch(
        (response: Exclude<UpdateDirectionTrainingAssignResponse, ResponseOk>) => {
          const { status } = response
          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        }
      )
  })
  return { fields, control }
}

export default useEntranceExamsRowForm
