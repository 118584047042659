import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'

import { ROLE_SUPPORT } from '@common/constants'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useModeratorStatementsFilter from '@hooks/useModeratorStatementsFilter'
import useModeratorStatementsInitialization from '@hooks/useModeratorStatementsInitialization'
import { SelectOption } from '@models'
import { createToast } from '@redux/action-types'
import {
  selectModeratorStatementsList,
  selectRoleIdProfile,
} from '@redux/actions/selectors'
import api from '@services/api'
import Button from '@ui/Button'
import Input from '@ui/Input'
import Select from '@ui/Select'
import { MultiValue, Option } from '@ui/Select/view'
import { base64toFile, downloadFile } from '@utils/common'

const ModeratorStatementsFilter = () => {
  const dispatch = useAppDispatch()
  const { fields, control, reset, onSubmit, exportCSV } =
    useModeratorStatementsFilter()
  const {
    paymentFormOptions,
    educationProfileOptions,
    levelOptions,
    facultyOptions,
    scenarioOptions,
    moderatorStatusStatementOptions,
    trajectoryOptions,
    moderatorsListOptions,
    statusesListOptions,
    nationalSelectProps,
    partnersSelectOptions,
    messageOptions,
  } = useModeratorStatementsInitialization()

  const statementsList = useAppSelector(selectModeratorStatementsList) || []
  const roleId = useAppSelector(selectRoleIdProfile)

  useEffect(() => {
    const handleEnterPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        onSubmit()
      }
    }

    window.addEventListener('keydown', handleEnterPress)

    return () => {
      window.removeEventListener('keydown', handleEnterPress)
    }
  }, [])

  return (
    <section>
      <form onSubmit={onSubmit} className={'moderator-statements-filter'}>
        <Input
          className={'filter-form__field'}
          {...fields.userId}
          placeholder="ID пользователя"
        />
        <Input
          className={'filter-form__field'}
          {...fields.userSurname}
          placeholder="Фамилия"
        />
        <Input
          className={'filter-form__field'}
          {...fields.userName}
          placeholder="Имя"
        />
        <Input
          className={'filter-form__field'}
          {...fields.userPatronymic}
          placeholder="Отчество"
        />
        <Input
          className={'filter-form__field'}
          {...fields.userEmail}
          placeholder="Email"
        />
        <Controller
          defaultValue={null}
          name="formPayment"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={paymentFormOptions}
              placeholder={'Основания поступления'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="formEducation"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={levelOptions}
              placeholder={terms.MODE_OF_STUDY}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="userNationality"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              placeholder={terms.CITIZENSHIP}
              className="filter-form__field"
              {...nationalSelectProps}
              {...(field as unknown)}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              invariant
              components={{ Option, MultiValue }}
            />
          )}
        />
        {roleId !== ROLE_SUPPORT && (
          <>
            <Controller
              defaultValue={null}
              name="statementStatus"
              control={control}
              render={({ field }) => (
                <Select<SelectOption, true>
                  isMulti
                  styles={{
                    valueContainer: (provided) => ({
                      ...provided,
                      maxHeight: '30px',
                      overflowY: 'auto',
                    }),
                    menu: (base) => ({ ...base, minWidth: '300px' }),
                  }}
                  className="filter-form__field"
                  {...(field as unknown)}
                  options={moderatorStatusStatementOptions}
                  placeholder={'Статус заявления'}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  allowSelectAll={true}
                  components={{ Option, MultiValue }}
                />
              )}
            />
          </>
        )}
        <Controller
          defaultValue={null}
          name="messages"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={messageOptions}
              placeholder={'Работа с сообщениями'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Input
          className={'filter-form__field'}
          {...fields.directionTraining}
          placeholder="Направление"
        />
        <Controller
          defaultValue={null}
          name="faculty"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={facultyOptions}
              placeholder={'Подразделение'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="statementScenario"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={scenarioOptions}
              placeholder={'Сценарий поступления'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="trajectory"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={trajectoryOptions}
              placeholder={'Приемная кампания'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="educationProfile"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={educationProfileOptions}
              placeholder={'Программа'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              invariant
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Input
          className={'filter-form__field'}
          type={'date'}
          {...fields.dateSendToReview}
          placeholder="Дата подачи заявки"
        />
        <Controller
          defaultValue={null}
          name="moderator"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={moderatorsListOptions}
              placeholder={'Модератор'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="other"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={[]}
              placeholder={'Условия поступления'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="contractStatuses"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={statusesListOptions}
              placeholder={'Статус договора'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="recruitingCompanies"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={partnersSelectOptions}
              placeholder={'Рекрутинговая компания'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <footer>
          <h2>Количество заявлений: {statementsList?.total | 0}</h2>

          <div>
            <Button onClick={exportCSV}>Выгрузить в CSV</Button>
            <Button type={'submit'} onClick={() => reset()}>
              Сбросить фильтры
            </Button>
            <Button theme={'success'} type={'submit'}>
              Отфильтровать
            </Button>
          </div>
        </footer>
      </form>
    </section>
  )
}
export default ModeratorStatementsFilter
