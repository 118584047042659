import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'

import terms from '@common/terms'
import ProfileSection from '@components/ProfileSection'
import AddOtherFiles from '@hooks/addOtherFiles'
import useAdditionalFileUploading from '@hooks/useAdditionalFIleUploading'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import TemplateModal from '@modals/TemplateModal'
import { SelectOption } from '@models'
import {
  createToast,
  getModeratorStatementsIdStatus,
  getStatementsList,
  hideModal,
} from '@redux/action-types'
import { selectStatementsList } from '@redux/actions/selectors'
import { modalDataSelector } from '@redux/actions/selectors/modal'
import { getAdditionalDocs, getAdditionalDocsModerator } from '@redux/reducers'
import api, { ResponseOk } from '@services/api'
import Button from '@ui/Button'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import Select from '@ui/Select'
import { base64ToPdf } from '@utils/common'

const AddFileModal = () => {
  const dispatch = useAppDispatch()
  const statementsList = useAppSelector(selectStatementsList)
  const data = useAppSelector(modalDataSelector)
  const { inputProps } = useAdditionalFileUploading(
    data?.name,
    data?.docId,
    data?.date,
    data?.id
  )
  const [statementId, setStatementId] = useState(
    window.location.pathname.split('/')[2] === 'statements'
      ? Number(window.location.pathname.split('/')[3])
      : 0
  )

  const [userId, setUserId] = useState(0)

  useEffect(() => {
    dispatch(getStatementsList(userId)).then(() => {
      if (statementsList && statementsList.length > 0) {
        setStatementId(statementsList[0].id)
      }

      if (statementId !== 0) {
        dispatch(getModeratorStatementsIdStatus(Number(statementId))).then(
          (response) => {
            if (window.location.pathname.split('/')[2] === 'statements') {
              // @ts-ignore
              setUserId(response.payload.userId)
            } else {
              setUserId(Number(window.location.pathname.split('/')[3]))
            }
          }
        )
      }
    })
  }, [])

  const {
    fields,
    watch,
    getValues,
    setValue,
    control,
    docOption,
    setError,
    clearErrors,
    formState: { errors },
  } = AddOtherFiles()

  useEffect(() => {
    if (data) {
      setValue('name', data.name)
      setValue(
        'typeDoc',
        data.date === 'other'
          ? { label: 'Иное', value: 1 }
          : { label: 'Смена фамилии', value: 0 }
      )

      api.client
        .getDocs(data.date === 'other' ? 'OTHER' : 'CHANGES_SURNAME', data.docId)
        .then((response) => {
          if (typeof response.data.document === 'string') {
            base64ToPdf(response.data.document, data.name).then((file) =>
              setValue('file', [file])
            )
          } else {
            base64ToPdf(
              response.data.document[
                Object.keys(response.data.document).sort(
                  (a, b) => Number(b) - Number(a)
                )[0]
              ],
              data.name
            ).then((file) => setValue('file', [file]))
          }
        })
    }
  }, [])

  function onSave() {
    if (!getValues('typeDoc')) {
      setError('typeDoc', {
        type: 'manual',
        message: terms.REQUIRED_FIELD,
      })
      return
    }

    if (getValues('name').length === 0) {
      setError('name', {
        type: 'manual',
        message: terms.REQUIRED_FIELD,
      })
      return
    }

    if (!getValues('file')) {
      setError('file', {
        type: 'manual',
        message: terms.REQUIRED_FIELD,
      })
      return
    }

    const formData = new FormData()
    formData.append(
      'typeDoc',
      getValues('typeDoc.value') === 1 ? 'other' : 'change_surname'
    )

    formData.append(
      'statement_id',
      !!statementsList && statementsList.length > 0
        ? String(statementsList[0].id)
        : window.location.pathname.split('/')[3]
    )
    // @ts-ignore
    formData.append(
      'documents[0]',
      // @ts-ignore
      new Blob(getValues('file'), { type: getValues('file[0].type') })
    )
    formData.append('name', getValues('name'))
    formData.append('user_id', `${userId}`)

    api.client
      .postLgotaSpecialDocs(
        formData,
        !!statementsList && statementsList.length > 0 ? statementsList[0].id : null
      )
      .then((response) => {
        const { data } = response as unknown as ResponseOk
        dispatch(createToast(data.message, 'success'))
      })
      .catch((response: ResponseOk) => {
        const { data } = response
        dispatch(createToast(data.message, 'danger'))
      })
      .finally(() => {
        dispatch(getStatementsList(userId)).then((response) => {
          // @ts-ignore
          if (response.payload && response.payload.length > 0) {
            // @ts-ignore
            dispatch(getAdditionalDocs(response.payload[0].id)).then(() => {
              dispatch(hideModal())
            })
          } else {
            dispatch(getAdditionalDocsModerator({ statementId, userId })).then(
              () => {
                dispatch(hideModal())
              }
            )
          }
        })
      })
  }

  useEffect(() => {
    if (data) inputProps.value = data
  }, [data])

  return (
    <TemplateModal
      title={data?.name ? terms.EDIT_FILE : terms.ADD_FILE}
      clearTypeDoc="ADDITIONAL_FILES"
    >
      <div style={{ padding: '20px 0 0 0' }}>
        <Controller
          name="typeDoc"
          control={control}
          render={({ field }) => (
            <Select<SelectOption>
              {...(field as unknown)}
              options={docOption}
              placeholder={'Тип документа'}
              markRequired={true}
              error={errors.typeDoc?.message}
            />
          )}
        />
      </div>

      <Input
        className="add-file-modal__input"
        placeholder={terms.DESIGNATION}
        markRequired={true}
        {...fields.name}
        error={errors.name?.message}
      />

      <ProfileSection title={terms.FILE} markRequired={true}>
        <FileUploader
          className="add-file-modal__uploader"
          files={watch('file') || []}
          onChange={(e) => {
            setValue('file', e)
            clearErrors('file')
          }}
          onDrop={(e) => {
            setValue('file', e)
            clearErrors('file')
          }}
          onDelete={() => {
            setValue('file', [])
            clearErrors('file')
          }}
        />
        {errors.file && <p style={{ color: 'red' }}>{errors.file.message}</p>}
      </ProfileSection>

      <Button theme="success" className="add-file-modal__button" onClick={onSave}>
        {data?.name ? terms.EDIT : terms.SAVE}
      </Button>
    </TemplateModal>
  )
}

export default AddFileModal
