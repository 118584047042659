import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { FilterStatement, FilterStatementData } from '@models'
import { createToast, getModeratorStatementsList } from '@redux/action-types'
import {
  selectModeratorStatementsListOrder,
  selectModeratorStatementsListPage,
  selectModeratorStatementsListPerPage,
  selectRoleIdProfile,
} from '@redux/actions/selectors'
import api from '@services/api'
import { base64toFile, downloadFile } from '@utils/common'

const useModeratorStatementsFilter = () => {
  const dispatch = useAppDispatch()
  const { register, handleSubmit, control, reset, setValue, getValues } =
    useForm<FilterStatement>({ mode: 'all' })

  const fields = {
    userId: register('userId'),
    userSurname: register('userSurname'),
    userName: register('userName'),
    userPatronymic: register('userPatronymic'),
    userEmail: register('userEmail'),
    dateSendToReview: register('dateSendToReview'),
    directionTraining: register('directionTraining'),

    formPayment: register('formPayment'),
    faculty: register('faculty'),
    trajectory: register('trajectory'),
    formEducation: register('formEducation'),
    educationProfile: register('educationProfile'),
    userNationality: register('userNationality'),
    userPartner: register('userPartner'),
    statementStatus: register('statementStatus'),
    statementScenario: register('statementScenario'),
    orderCreateDate: register('orderCreateDate'),
    moderator: register('moderator'),
    other: register('other'),
    contractStatuses: register('contractStatuses'),
    recruitingCompanies: register('recruitingCompanies'),

    perPage: register('perPage'),
    page: register('page'),
    messages: register('messages'),
  }

  const order = useAppSelector(selectModeratorStatementsListOrder)
  const page = useAppSelector(selectModeratorStatementsListPage)
  const perPage = useAppSelector(selectModeratorStatementsListPerPage)
  const roleId = useAppSelector(selectRoleIdProfile)

  useEffect(() => {
    setValue('page', page)
    setValue('perPage', perPage)
    setValue('orderCreateDate', order)
    onSubmit()
  }, [page, perPage, order])

  const onSubmit = handleSubmit((data: any) => {
    if (!roleId) {
      return
    }

    const normalizedData: FilterStatement = {
      ...data,
      formPayment: data.formPayment?.map((el) => {
        return el.value
      }),
      formEducation: data.formEducation?.map((el) => {
        return el.value
      }),
      faculty: data.faculty?.map((el) => {
        return el.value
      }),
      trajectory: data.trajectory?.map((el) => {
        return el.value
      }),
      educationProfile: data.educationProfile?.map((el) => {
        return el.value
      }),
      userNationality: data.userNationality?.map((el) => {
        return el.value
      }),
      userPartner: data.userPartner?.map((el) => {
        return el.value
      }),
      statementStatus: data.statementStatus?.map((el) => {
        return el.value
      }),
      statementScenario: data.statementScenario?.map((el) => {
        return el.value
      }),
      moderator: data.moderator?.map((el) => {
        return el.value
      }),
      contractStatuses: data.contractStatuses?.map((el) => {
        return el.value
      }),
      recruitingCompanies: data.recruitingCompanies?.map((el) => {
        return el.value
      }),
      messages: data.messages?.map((el) => {
        return el.value
      }),
    }

    dispatch(getModeratorStatementsList({ roleId, normalizedData }))
  })

  const exportCSV = () => {
    let data: any = {
      userId: getValues('userId'),
      userSurname: getValues('userSurname'),
      userName: getValues('userName'),
      userPatronymic: getValues('userPatronymic'),
      userEmail: getValues('userEmail'),
      dateSendToReview: getValues('dateSendToReview'),
      directionTraining: getValues('directionTraining'),
      formPayment: getValues('formPayment'),
      faculty: getValues('faculty'),
      trajectory: getValues('trajectory'),
      formEducation: getValues('formEducation'),
      educationProfile: getValues('educationProfile'),
      userNationality: getValues('userNationality'),
      userPartner: getValues('userPartner'),
      statementStatus: getValues('statementStatus'),
      statementScenario: getValues('statementScenario'),
      orderCreateDate: getValues('orderCreateDate'),
      moderator: getValues('moderator'),
      other: getValues('other'),
      contractStatuses: getValues('contractStatuses'),
      recruitingCompanies: getValues('recruitingCompanies'),
      messages: getValues('messages'),
    }

    const normalizedData: FilterStatement = {
      ...data,
      formPayment: data.formPayment?.map((el) => {
        return el!.value
      }),
      formEducation: data.formEducation?.map((el) => {
        return el.value
      }),
      faculty: data.faculty?.map((el) => {
        return el.value
      }),
      trajectory: data.trajectory?.map((el) => {
        return el.value
      }),
      educationProfile: data.educationProfile?.map((el) => {
        return el.value
      }),
      userNationality: data.userNationality?.map((el) => {
        return el.value
      }),
      userPartner: data.userPartner?.map((el) => {
        return el.value
      }),
      statementStatus: data.statementStatus?.map((el) => {
        return el.value
      }),
      statementScenario: data.statementScenario?.map((el) => {
        return el.value
      }),
      moderator: data.moderator?.map((el) => {
        return el.value
      }),
      contractStatuses: data.contractStatuses?.map((el) => {
        return el.value
      }),
      recruitingCompanies: data.recruitingCompanies?.map((el) => {
        return el.value
      }),
      messages: data.messages?.map((el) => {
        return el.value
      }),
    }

    api.client
      .getStatementsCSV(normalizedData)
      .then((resp) =>
        base64toFile(resp.data.document, 'text/csv', 'csv', resp.data.name)
      )
      .then(downloadFile)
      .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')))
  }

  return {
    fields,
    control,
    reset,
    setValue,
    onSubmit,
    exportCSV,
  }
}

export default useModeratorStatementsFilter
