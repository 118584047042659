import React from 'react'
import { Navigate } from 'react-router-dom'

import Loader from '../components/generic/Loader'
import { ROLE_ADMIN } from '@common/constants'
import useAppSelector from '@hooks/useAppSelector'
import { selectRoleIdProfile } from '@redux/actions/selectors'

const AdminAuth = ({ children }: { children: JSX.Element }) => {
  const roleId = useAppSelector(selectRoleIdProfile)

  if (!roleId) {
    return <Loader />
  }

  if (roleId && roleId !== ROLE_ADMIN) {
    return <Navigate to="-1" />
  }

  return children
}

export default AdminAuth
