import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'

import terms from '@common/terms'
import useAppSelector from '@hooks/useAppSelector'
import useDirectionsTrainingSearch from '@hooks/useDirectionsTrainingSearch'
import useDirectionsTrainingSearchInitialization from '@hooks/useDirectionsTrainingSearchInitialization'
import { SelectOption, SelectOptionConstants } from '@models'
import { selectStatementsElement } from '@redux/actions/selectors'
import Button from '@ui/Button'
import Input from '@ui/Input'
import Select from '@ui/Select'
import { MultiValue, Option } from '@ui/Select/view'

const DirectionsTrainingSearch = (scenarioKeys) => {
  const {
    fields,
    control,
    onSubmit,
    resetForm,
    setValue,
    sortValue,
    onChangeSortFilter,
  } = useDirectionsTrainingSearch()
  const statementEl = useAppSelector(selectStatementsElement)
  const {
    formDirectionsTrainingSearchOptions,
    paymentDirectionsTrainingSearchOptions,
    facultyOptions,
  } = useDirectionsTrainingSearchInitialization({ setValue })

  let statement = useAppSelector(selectStatementsElement)
  const scenario =
    statement?.scenario.code == scenarioKeys.level_training_bachelor_ino.onesKey ||
    statement?.scenario.code == scenarioKeys.level_training_bachelor.onesKey ||
    statement?.scenario.code == scenarioKeys.level_training_bachelor_tashkent.onesKey

  const isForeigner = statement?.trajectoryId !== 1

  // удаление очно-заочной формы обучения для платной магистратуры РФ
  if (
    formDirectionsTrainingSearchOptions &&
    statementEl?.paymentType == 2 &&
    scenarioKeys.magistra_rf.onesKey == statement?.scenario.code
  ) {
    formDirectionsTrainingSearchOptions.splice(2, 1)
  }

  return (
    <div className="directions-training-search mb-24">
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <Input
          className={'directions-training-search__input'}
          placeholder={terms.DIRECTIONS_TRAINING}
          type="text"
          {...fields.name}
        />
        <Button type={'submit'} theme={'success'}>
          {terms.FIND}
        </Button>
      </form>
      <Controller
        defaultValue={null}
        name="faculty"
        control={control}
        render={({ field }) => (
          <Select<SelectOption, true>
            className="directions-training-search__select directions-training-search__select--full-width"
            isMulti
            styles={{
              valueContainer: (provided) => ({
                ...provided,
                maxHeight: '30px',
                overflowY: 'auto',
              }),
              menu: (base) => ({ ...base, minWidth: '300px' }),
            }}
            {...(field as unknown)}
            options={facultyOptions}
            placeholder={'Филиал/Факультет/Институт'}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            components={{ Option, MultiValue }}
          />
        )}
      />
      <Controller
        name="formEducation"
        control={control}
        render={({ field }) => (
          <Select<SelectOption>
            className={`directions-training-search__select ${
              isForeigner ? 'directions-training-search__select--full-width' : ''
            }`}
            {...(field as unknown)}
            options={formDirectionsTrainingSearchOptions}
            placeholder={terms.MODE_OF_STUDY}
            isClearable={true}
          />
        )}
      />

      {scenario && statement?.trajectoryId == 1 && statement?.paymentType != 2 ? (
        <Select<SelectOptionConstants>
          className="directions-training-search__select"
          options={[
            { label: 'Особая квота', value: 'benefits' },
            { label: 'Отдельная квота', value: 'specialQuota' },
          ]}
          placeholder="Льгота"
          onChange={onChangeSortFilter}
          value={sortValue}
          isClearable={true}
        />
      ) : (
        <></>
      )}

      {statement?.paymentType == 2 ? (
        <></>
      ) : (
        <Controller
          name="formPayment"
          control={control}
          render={({ field }) => (
            <Select<SelectOption>
              className="directions-training-search__select"
              {...(field as unknown)}
              options={paymentDirectionsTrainingSearchOptions}
              placeholder={terms.FORM_PAYMENT_ID}
              isClearable={true}
            />
          )}
        />
      )}
      <Button
        className={'directions-training-search__reset-filter'}
        onClick={() => resetForm()}
      >
        {terms.RESET_FILTER}
      </Button>
    </div>
  )
}

export default DirectionsTrainingSearch
