import React from 'react'

export default ({ contractNotEntrants }) => (
  <div className="moderator-contract-info__inline">
    <div className="moderator-contract-info__block">
      <h2>Основные данные плательщика:</h2>
      <div className="moderator-contract-info__block-row juridical">
        <p>Фамилия, имя, отчество:</p>
        <span>
          {`${contractNotEntrants?.surname || ''} ${
            contractNotEntrants?.name || ''
          } ${contractNotEntrants?.patronymic || ''}`}
        </span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>Дата рождения:</p>
        <span>{contractNotEntrants?.birthDate || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>Телефон(ы):</p>
        <span>{contractNotEntrants?.phone || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>Адрес по прописке:</p>
        <span>{contractNotEntrants?.addressResidential || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>Адрес фактический:</p>
        <span>{contractNotEntrants?.address || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>Email:</p>
        <span>{contractNotEntrants?.email || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>Место рождения:</p>
        <span>{contractNotEntrants?.placeOfBirth || '-'}</span>
      </div>
    </div>
    <div className="moderator-contract-info__block">
      <h2>Паспортные данные плательщика:</h2>

      <div className="moderator-contract-info__block-row juridical">
        <p>Название документа:</p>
        <span>{contractNotEntrants?.typeIdentityDocumentId || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>Серия:</p>
        <span>{contractNotEntrants?.series || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>Номер:</p>
        <span>{contractNotEntrants?.number || '-'}</span>
      </div>
      {contractNotEntrants?.divisionCode && (
        <div className="moderator-contract-info__block-row juridical">
          <p>Код подразделения:</p>
          <span>{contractNotEntrants?.divisionCode || '-'}</span>
        </div>
      )}
      {contractNotEntrants?.validityPeriod && (
        <div className="moderator-contract-info__block-row juridical">
          <p>Действителен до:</p>
          <span>{contractNotEntrants?.validityPeriod || '-'}</span>
        </div>
      )}
      <div className="moderator-contract-info__block-row juridical">
        <p>Когда выдан:</p>
        <span>{contractNotEntrants?.whenIssued || '-'}</span>
      </div>
      <div className="moderator-contract-info__block-row juridical">
        <p>Кем выдан:</p>
        <span>{contractNotEntrants?.issuedByWhom || '-'}</span>
      </div>
    </div>
  </div>
)
