import { TFileType, TMimeType } from '@common/manual'
import terms from '@common/terms'
import { UploadDocKey } from '@common/uploadDocsTypes'
import useAppDispatch from '@hooks/useAppDispatch'
import {
  createToast,
  getBlankPriorityStatement,
  getBlankStatement,
} from '@redux/action-types'
import api from '@services/api'
import { base64toFile, downloadFile } from '@utils/common'

const useDownloadTemplateDocumentPriority = () => {
  const dispatch = useAppDispatch()

  const downloadTemplateDoc = (
    typeDoc: UploadDocKey,
    mimeType: TMimeType,
    fileType: TFileType,
    filename?: string
  ) => {
    api.client
      .downloadTemplateDoc(typeDoc)
      .then((resp) => base64toFile(resp.data.document, mimeType, fileType, filename))
      .then(downloadFile)
      .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')))
  }

  const downloadBlankPriority = (
    statement: number,
    blank_id: number,
    mimeType: TMimeType,
    fileType: TFileType,
    filename?: string,
    userId?: number
  ) => {
    api.client
      .downloadBlankPriority(blank_id, statement, userId)
      .then((resp) => base64toFile(resp.data.document, mimeType, fileType, filename))
      .then(downloadFile)
      .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')))
      .finally(() => dispatch(getBlankPriorityStatement(statement)))
  }

  return { downloadTemplateDoc, downloadBlankPriority }
}

export default useDownloadTemplateDocumentPriority
