import React from 'react'

import classNames from 'classnames'

import useCardForDownloadFile from '@hooks/useCardForDownloadFile'
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'
import { formatDateTimeToRuFormat } from '@utils/date'

type CardForDownloadFileProps = {
  className?: string
  showCard: boolean
  title: string
  date: string
  direction?: string
  type?: string
  blankId: number
  acceptBlank: boolean
}

const CardForDownloadFile = ({
  className,
  showCard,
  title,
  date,
  direction,
  blankId,
  acceptBlank,
}: CardForDownloadFileProps) => {
  const { handleClickDownloadDocs, subscribeBlank } = useCardForDownloadFile(
    acceptBlank,
    blankId,
    title
  )

  return (
    <div className={classNames('card', className)}>
      <span className="card__header">{title}</span>
      <span className="card__content">
        Дата формирования:{' '}
        <strong>{date ? formatDateTimeToRuFormat(date) : '-'}</strong>
      </span>
      {direction && (
        <div className="card__content-direction">
          {' '}
          <span className="card__content">Приемная кампания / Направление: </span>
          <div>
            <strong>{direction}</strong>
          </div>
        </div>
      )}
      <div className="card__download">
        <Button
          type={'button'}
          disabled={acceptBlank ? false : !showCard}
          onClick={handleClickDownloadDocs}
        >
          Скачать Документ
        </Button>
        <span className="card__download-text">
          {title === 'Заявление'
            ? 'Скачайте, проверьте данные и нажмите кнопку “Подписать”'
            : 'Скачайте и проверьте данные'}
        </span>
      </div>
      <div className="card__signature">
        <Checkbox
          className={
            acceptBlank ? 'active' : !showCard ? 'disabled' : date ? '' : 'disabled'
          }
          disabled={acceptBlank ? false : !showCard ? true : !date}
          theme="success-filled"
          checked={acceptBlank}
          onChange={subscribeBlank}
          text={'Подписать'}
        />
      </div>
    </div>
  )
}

export default CardForDownloadFile
