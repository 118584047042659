import React, { PropsWithChildren, useEffect } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

import classNames from 'classnames'

import Checkmark from '@assets/images/Check-mark.svg'
import Exclamation from '@assets/images/Exclamation-mark.svg'
import useAppSelector from '@hooks/useAppSelector'
import { selectLocalizedViewerProfile } from '@redux/actions/selectors'
import terms from '@common/terms'

type SidenavLinkProps = PropsWithChildren<NavLinkProps> & {
  text: string
  status?: 'valid' | 'invalid'
}

const SidenavLink = ({ text, children, status, ...rest }: SidenavLinkProps) => {
  const profile = useAppSelector(selectLocalizedViewerProfile)
  let nationalityId: any
  if (profile?.userRole?.roleTranslate[0]?.roleId != 1) {
    nationalityId = profile?.userNational['0'].nationalityId
  } else {
    nationalityId = false
  }

  return (
    <NavLink {...rest} className={classNames('sidenav-link', rest.className)}>
      <h4
        className={classNames('sidenav-link__title', {
          [`sidenav-link__title--${status}`]: status,
        })}
      >
        {status === 'valid' && (
          <Checkmark className="sidenav-link__icon sidenav-link__icon--check" />
        )}
        {status === 'invalid' && (
          <div className="inlineExclamation">
            {profile?.userRole?.roleTranslate[0]?.roleId != 1 ? (
              <div className="inlineExclamation">
                {nationalityId == 1 && rest.to == 'legal-representative' ? (
                  <Exclamation className="sidenav-link__icon sidenav-link__icon--exclamation-null" />
                ) : (
                  <Exclamation className="sidenav-link__icon sidenav-link__icon--exclamation" />
                )}
              </div>
            ) : (
              <Exclamation className="sidenav-link__icon sidenav-link__icon--exclamation" />
            )}
          </div>
        )}
        {children}
      </h4>
      <span className="sidenav-link__text">{text}</span>
    </NavLink>
  )
}

export default SidenavLink
