import { RootState } from '../../types'
import { ROLE_ADMIN } from '@common/constants'
import { ModeratorDirectionsTrainings, SelectOption } from '@models'

export const selectModeratorStatementsList = (state: RootState) =>
  state.moderator.entities.statementsList.data

export const selectModeratorStatementsPersonalData = (state: RootState) =>
  state.moderator.entities.personalData.data

export const selectModeratorUserPersonalData = (state: RootState) =>
  state.moderator.entities.supportPersonalData.data

export const selectModeratorStatementsPassportData = (state: RootState) =>
  state.moderator.entities.passportData.data

export const selectModeratorStatementsAddressData = (state: RootState) =>
  state.moderator.entities.addressData.data

export const selectModeratorStatementsEducationData = (state: RootState) =>
  state.moderator.entities.educationData.data

export const selectModeratorExamResultData = (state: RootState) =>
  state.moderator.entities.examResultData.data

export const selectModeratorStatementsDocumentsData = (state: RootState) =>
  state.moderator.entities.documentsData.data

export const selectModeratorStatementsTargetContractData = (state: RootState) =>
  state.moderator.entities.targetContractData.data

export const selectModeratorStatementsAchievementsAndPrivilegesData = (
  state: RootState
) => state.moderator.entities.achievementsAndPrivilegesData.data

export const selectModeratorStatementsBlankData = (state: RootState) =>
  state.moderator.entities.blankStatementData.data

export const selectModeratorStatementsIdStatus = (state: RootState) =>
  state.moderator.entities.statementsIdStatus.data

export const selectModeratorStatementsIdStatusCode = (state: RootState) => {
  const { data } = state.moderator.entities.statementsIdStatus
  if (!data) return null
  return Number(data.statusStatement.code)
}

export const selectModeratorStatementsIdStatusAdmin = (state: RootState) => {
  const { data } = state.moderator.entities.statementsIdStatus
  if (!data) return null
  if (!data.statusStatementSpecial.length) return null
  return Number(
    data.statusStatementSpecial.find(
      (el) => el?.roleStatusStatement[0]?.roleId === ROLE_ADMIN
    )?.roleStatusStatement[0]?.statusStatement?.code
  )
}

export const selectModeratorStatementsIdComment = (state: RootState) =>
  state.moderator.entities.statementsIdComment.data

export const selectModeratorContractsList = (state: RootState) =>
  state.moderator.entities.contractsList.data

export const selectModeratorContractInfo = (state: RootState) =>
  state.moderator.entities.contractInfo.data

export const selectModeratorContractIdStatusCode = (state: RootState) => {
  const { data } = state.moderator.entities.contractInfo
  if (!data) return null
  return Number(data.data.status.code)
}

export const selectModeratorPersonalInformationList = (state: RootState) =>
  state.moderator.entities.personalInformationList.data

export const selectModeratorStatementsListOrder = (state: RootState) =>
  state.moderator.orderStatements

export const selectModeratorStatementsListPage = (state: RootState) =>
  state.moderator.pageStatements

export const selectModeratorStatementsListPerPage = (state: RootState) =>
  state.moderator.perPageStatements

export const selectSearchStudents = (state: RootState) =>
  state.moderator.searchStudents

export const selectModeratorStatementsAdditionalFile = (state: RootState) =>
  state.moderator.entities.additionalFiles.data

export const selectModeratorScenario = (state: RootState): SelectOption[] => {
  const { data } = state.moderator.entities.scenario
  if (!data || !data.length) return []
  return data.map((level) => {
    const [translate] = level.scenarioTranslate
    return {
      value: translate.scenarioId,
      label: translate.name,
    }
  })
}
export const selectModeratorLevelEducation = (state: RootState): SelectOption[] => {
  const { data } = state.moderator.entities.levelEducation
  if (!data || !data.length) return []
  return data.map((level) => {
    const [translate] = level.levelTranslate
    return {
      value: translate.levelEducationId,
      label: translate.name,
    }
  })
}

export const selectAllStatusStatement = (state: RootState): SelectOption[] => {
  const { data } = state.moderator.entities.statusStatement
  if (!data || !data.length) return []
  return data.map((level) => {
    const [translate] = level.translate
    return {
      value: translate.statusStatementId,
      label: translate.name,
    }
  })
}

export const selectModeratorStatusStatement = (state: RootState): SelectOption[] => {
  const { data } = state.moderator.entities.statusStatementModerator
  if (!data || !data.length) return []
  return data.map((level) => {
    const [translate] = level.translate
    return {
      value: translate.statusStatementId,
      label: translate.name,
    }
  })
}

export const selectModeratorModeratorsList = (state: RootState): SelectOption[] => {
  const { data } = state.moderator.entities.moderatorsList
  if (!data || !data.length) return []
  return data.map((moderator) => {
    const [translate] = moderator.usersTranslate
    return {
      value: moderator.id,
      label: `${translate.name || ''} ${translate.surname || ''} ${
        translate.patronymic || ''
      }`,
    }
  })
}

export const selectModeratorModeratorsListWithNoModerator = (
  state: RootState
): SelectOption[] => {
  const { data } = state.moderator.entities.moderatorsList
  if (!data || !data.length) return []
  let moderators = data.map((moderator) => {
    const [translate] = moderator.usersTranslate
    return {
      value: moderator.id,
      label: `${translate.name || ''} ${translate.surname || ''} ${
        translate.patronymic || ''
      }`,
    }
  })

  moderators.unshift({ value: 0, label: `Нет модератора` })

  return moderators
}

export const selectModeratorStatusesList = (state: RootState): SelectOption[] => {
  const { data } = state.moderator.entities.statusesList
  if (!data || !data.length) return []
  return data.map((status) => {
    const [translate] = status.translate

    return {
      value: status.id,
      label: translate.name,
    }
  })
}

export const selectModeratorStatusContract = (state: RootState): SelectOption[] => {
  const { data } = state.moderator.entities.statusContract
  if (!data || !data.length) return []
  return data.map((level) => {
    const [translate] = level.translate
    return {
      value: translate.statusContractId,
      label: translate.name,
    }
  })
}

export const selectModeratorEducationProfile = (
  state: RootState
): SelectOption[] => {
  const { data } = state.moderator.entities.educationProfile
  if (!data || !data.length) return []
  return data.map((n) => {
    const [translate] = n.translate
    return {
      value: translate.educationProfileId,
      label: translate.name,
    }
  })
}

export const selectModeratorTrajectory = (state: RootState): SelectOption[] => {
  const { data } = state.moderator.entities.trajectory
  if (!data || !data.length) return []
  return data.map((level) => {
    const [translate] = level.translate
    return {
      value: translate.trajectoryId,
      label: translate.name,
    }
  })
}

export const selectModeratorDirectionsTraining = (
  state: RootState
): ModeratorDirectionsTrainings => {
  return state.moderator.entities.directionTrainings.data
}
