import React, { useEffect } from 'react'

import CrossIcon from '@assets/images/Cross.svg'
import EditIcon from '@assets/images/Edit.svg'
import FileIcon from '@assets/images/File.svg'
import { ROLE_ADMIN, ROLE_MODERATOR } from '@common/constants'
import terms from '@common/terms'
import useAdditionalFiles from '@hooks/useAdditionalFIles'
import useAdditionalFilesModerator from '@hooks/useAdditionalFilesModerator'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import Button from '@ui/Button'

import './ModeratorAdditionalFormsPage.scss'

import { formatDateTimeToRuFormat } from 'utils/date'

import { getStatementsList } from '@redux/action-types'
import {
  selectAdditionalFiles,
  selectIsQuestionnaireBlockProfile,
  selectLocalizedViewerProfile,
  selectRoleIdProfile,
  selectStatementsList,
} from '@redux/actions/selectors'
import { getAdditionalDocs } from '@redux/reducers'
import { trimFileExtension } from '@utils/common'

const ModeratorAdditionalFormsPage = () => {
  const { onOpenModal, files, onEditFile, onDownload } =
    useAdditionalFilesModerator()
  const readOnly = useAppSelector(selectIsQuestionnaireBlockProfile)

  const profile = useAppSelector(selectLocalizedViewerProfile)

  return (
    <section className="add-files-page">
      <div className="add-files-page__wrapper">
        <Button
          theme="success"
          className="add-files__button"
          onClick={onOpenModal}
          disabled={readOnly}
        >
          {terms.ADD_FILE}
        </Button>

        <ul className="add-files-page__items">
          {files.map((file) => (
            <li key={file.id} className="add-files-page__item additional-file">
              <div className="additional-file__icon-wrapper">
                <button
                  className="additional-file__button"
                  onClick={() => {
                    onDownload(file)
                  }}
                >
                  <FileIcon
                    width={46}
                    height={61}
                    className="additional-file__icon"
                  />
                </button>
                {file.userDoc.uploaderId === profile?.id ? (
                  <button
                    className="additional-file__del-button"
                    onClick={() => {
                      onEditFile(
                        trimFileExtension(file.userDoc.name),
                        file.userDoc.id,
                        file.userDoc.getTypeDocs.name
                      )
                    }}
                  >
                    <EditIcon
                      width={23}
                      viewBox="-5 0 20 25"
                      height={20}
                      className="additional-file__cross"
                    />
                  </button>
                ) : null}
              </div>
              <div className="additional-file__info">
                <div className="additional-file__name">
                  <p>{trimFileExtension(file.userDoc.name)}</p>
                </div>
                <div className="additional-file__date">
                  <div>
                    {terms.CREATED}: {formatDateTimeToRuFormat(file.createdAt)}
                  </div>
                  {file.createdAt !== file.updatedAt && (
                    <div>
                      Редактирован:{' '}
                      {formatDateTimeToRuFormat(new Date(file.updatedAt))}
                    </div>
                  )}
                  {file.userDoc.uploaderId === profile?.id && (
                    <span className="additional-file__user">
                      {profile?.usersTranslate?.surname || ' '}{' '}
                      {profile?.usersTranslate?.name || ' '}{' '}
                      {profile?.usersTranslate?.patronymic || ' '}
                    </span>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default ModeratorAdditionalFormsPage
