import type { Locale } from 'types/custom-types'

import { locale } from '@common/terms'
import { RegistrationDataEn, RegistrationDataRu } from '@models'

export const MOBILE_SCREEN_WIDTH = 640
export const TABLET_SCREEN_WIDTH = 900

const isRuLocale = locale === 'ru'
export const registrationFieldsByLocale: Record<
  string,
  keyof RegistrationDataRu | keyof RegistrationDataEn
> = {
  name: isRuLocale ? 'name_ru' : 'name_en',
  surname: isRuLocale ? 'surname_ru' : 'surname_en',
  patronymic: isRuLocale ? 'patronymic_ru' : 'patronymic_en',
}

export const RU_LOCALE_ID = 1
export const EN_LOCALE_ID = 2

export const RU_LANG_KEY: Locale = 'ru'
export const EN_LANG_KEY: Locale = 'en'

export const NATIONALITY_BY_CODE = '112'

//коды сценариев поступления
export const BACHALOR_INO_CODE = 47
export const BACHALOR_RF_CODE = 46
export const BACHALOR_TASHKENT_CODE = 49

export const RUSSIA_NATIONAL_ID = 1

export const WEB_METHOD_APPLICATION_ID = 1

export const RUSSIA_CITIZEN_PASSPORT_ID = 2
export const FOREIGN_CITIZEN_PASSPORT_ID = 1
export const RUSSIA_FOREIGN_CITIZEN_PASSPORT_ID = 3

export const RUSSIA_FOREIGN_PASSPORT_ID = 6

// Паспорт иностранного гражданина
export const FOREIGN_CITIZEN_PASSPORT_CODE = '49'

// Удостоверение личности лица без гражданства
export const IDENTITY_CARD_STATELESS_PERSON_CODE = '90'

// Свидетельство о предоставлении временного убежища на территории РФ
export const RUSSIA_CERTIFICATE_TEMPORARY_ASYLUM_CODE = '152'

// Паспорт негражданина
export const PASSPORT_NON_CITIZEN_CODE = '153'

// Иной документ
export const OTHER_DOCUMENT_CODE = '128'

export const BLANK_STATEMENT = 1
export const BLANK_AGREEMENT_TO_ENTER = 2
export const BLANK_AGREEMENT_TO_PERSONALITY_DATA = 3

export const LEVEL_TRAINING_SPO = 999999999999999
//export const LEVEL_TRAINING_BACHELOR = 7
export const LEVEL_TRAINING_BACHELOR_VOL = 1
export const LEVEL_TRAINING_BACHELOR_VIB = 2
export const LEVEL_TRAINING_BACHELOR_DAG = 3
export const LEVEL_TRAINING_MASTER = 5 //магистратура
export const LEVEL_TRAINING_PHD = 7 //аспирант
export const LEVEL_TRAINING_PREPARATION = 6 //Подготовительный факультет

//новые константы после смены базы
export const LEVEL_TRAINING_BACHELOR = 1 //Бакалавр рф 47
export const LEVEL_TRAINING_BACHELOR_INO = 7 //Бакалавр ино 46
//export const LEVEL_TRAINING_PREPARATION = 6 //Подготовительный факультет

// statusStatement /admin/statements/statusStatement
export const STATUS_PEREPODACHA = 1 //Переподача заявления
export const STATUS_DRAFT = 2 //Черновик
export const STATUS_CONSIDERATION = 3 //На рассмотрении
export const STATUS_WORK = 4 //В работе
export const STATUS_APPROVED = 5 //Одобрено
export const STATUS_EDIT_DIRECTION = 6 //Редактирование направлений
export const STATUS_DIRECTION_EDITING = 7 // Отредактированы направления
export const STATUS_SEND_EDITING = 8 //Отправлено на редактирование
export const STATUS_USER_EDIT = 9 //Отредактировано пользователем
export const STATUS_DENIED = 10 //Отказано
export const STATUS_REVOKE = 11 //Отозвано
export const STATUS_ENLISTED = 12 //Зачислен

export const STATUS_EXPERTISE_SEND = 14 //Отправлено на экспертизу

export const STATUS_CORD_SEND = 17 //Отправлено координатору
export const STATUS_CORD_DENIED = 18 //Отказано координатором
export const STATUS_CORD_APPROVED = 19 //Одобрено координатором
export const STATUS_REVOKE_APPROVED = 20 //Одобрено координатором

export const ROLE_ADMIN = 1
export const ROLE_MODERATOR = 3
export const ROLE_SUPPORT = 4
export const ROLE_USER = 6

export const MOSCOW_REGION = '77'
export const SPB_REGION = '78'
export const ZERO_STREET_CODE = '0000'
export const ZERO_DISTRICT_CODE = '000'

export const STATUS_USER_NOT_ACTIVE = '0'
export const STATUS_USER_ACTIVE = '1'
export const STATUS_USER_BLOCKED = '2'

export const SCENARIO_MASTER = '3'

// Статус договоров statuses /moderator/contracts/statuses
export const CONTRACT_DRAFT = 1 // Черновик
export const CONTRACT_WORK = 2 // В работе
export const CONTRACT_EDITING = 3 // Отправлено на редактирование
export const CONTRACT_EDITED = 4 // Отредактировано пользователем
export const CONTRACT_APPROVED = 5 // Одобрен
export const CONTRACT_REVOKE = 6 // Отозван
export const CONTRACT_DENIED = 7 // Отказано / Отклонен
export const CONTRACT_SIGNED = 8 // Подписан
export const CONTRACT_RECEIPT_SENT = 9 // Отправлена квитанция
export const CONTRACT_RECEIPT_RECEIVED = 10 // Получена квитанция
export const CONTRACT_SIGNED_APPLICANT = 11 // Подписан абитуриентом

// Значения для селектора непрочитанных сообщений
export const HAVE_UNREAD_MESSAGE = 1
export const DOESNT_UNREAD_MESSAGE = 0

export const PAYER_TYPE_MATERNAL_CAPITAL = 'MT-04'

export const HIGHER_EDUCATION = [13, 14, 15, 16, 17, 18] // Значение диплома о высшем образовании
export const WITHOUT_PREV_EDUCATION = [1, 12, 13] // Значение при которых не заполняется направление предыдущего образования

/**
 *  код для выбора всех высших образований
 */
export const HIGH_EDUCATION_CODE = 0
/**
 * код для выбора всех средних образований
 */
export const MIDDLE_EDUCATION_CODE = 1
/**
 * код для выбора направлений при которых не заполняется направление предыдущего образования
 */
export const WITHOUT_PREV_EDUCATION_CODE = 2
//Значения предметов егэ
export const EGE_CODE_ENGLISH = '110'
export const EGE_CODE_SPANISH = '112'
export const EGE_CODE_CHINESE = '210'
export const EGE_CODE_DEUTSCH = '116'
export const EGE_CODE_FRENCH = '118'
export const TRIAL_CODE_FOEIGN = '208'

//Бакалавр/Магитратура/аспирантура
export const MAGISTRA_TASHKENT = 50
export const MAGISTRA_RF = 41
export const MAGISTRA_INO = 42
export const ASPERATNUTA_RF = 44
export const BACHELOR_RF = 47 //БАКАЛАВР РФ
export const BACHELOR_INO = 46 //БАКАЛАВР ИНО
export const QUOTA_ID = 43
export const TRAINING_PREPARATION = 45 //Подготовительный факультет(ассисентура)
export const SPO_ID = 48
export const BACHELOR_TASHKENT = 49
