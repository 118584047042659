import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import {
  getPassportNumber,
  getPassportSeries,
  normalizeYearTo4Characters,
} from '../lib/validators'
import { SelectOption } from '../models/common'
import { ContractPayer, ContractPayerWithOptions } from '../models/statements'
import regexp from '../common/regexp'
import terms from '../common/terms'
import { selectTypeIdentityDocumentOptions } from '../redux/actions/selectors/profile'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import {
  createToast,
  getDirectionsListOptions,
  getModeratorContractInfo,
  getTypeIdentityDocuments,
} from '@redux/action-types'
import {
  selectContract,
  selectModeratorContractInfo,
} from '@redux/actions/selectors'
import api, { ResponseOk } from '@services/api'

const useChangePayerDetailsJuridical = (
  competitiveGroupStatementId,
  payerTypeId,
  contractJuridical
) => {
  const dispatch = useAppDispatch()
  const {
    register,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    setValue,
    trigger,
    getValues,
    ...rest
  } = useForm<ContractPayerWithOptions>({ mode: 'all' })

  const { id: contractId } = useParams()

  const fields = {
    organization: register('organization'),
    managementBases: register('managementBases'),
    nameBank: register('nameBank'),
    paymentAccount: register('paymentAccount'),
    bicBank: register('bicBank'),
    correspondentAccount: register('correspondentAccount'),
    cityBank: register('cityBank'),
    phone: register('phone'),
    email: register('email'),
    organizationAddress: register('organizationAddress'),
    shortName: register('shortName'),
    generalManager: register('generalManager'),
    inn: register('inn'),
    kpp: register('kpp'),
    actualAddress: register('actualAddress'),
    ogrn: register('ogrn'),
  }

  useEffect(() => {
    if (contractJuridical) {
      initializationData()
    }
  }, [contractJuridical])

  function initializationData() {
    if (contractJuridical) {
      setValue('organization', contractJuridical?.organization || '')
      setValue('managementBases', contractJuridical?.managementBases || '')
      setValue('nameBank', contractJuridical?.nameBank || '')
      setValue('paymentAccount', contractJuridical?.paymentAccount || '')
      setValue('bicBank', contractJuridical?.bicBank || '')
      setValue('correspondentAccount', contractJuridical?.correspondentAccount || '')
      setValue('cityBank', contractJuridical?.cityBank || '')
      setValue('phone', contractJuridical?.phone || '')
      setValue('email', contractJuridical?.email || '')
      setValue('organizationAddress', contractJuridical?.organizationAddress || '')
      setValue('shortName', contractJuridical?.shortName || '')
      setValue('generalManager', contractJuridical?.generalManager || '')
      setValue('inn', contractJuridical?.inn || '')
      setValue('kpp', contractJuridical?.kpp || '')
      setValue('actualAddress', contractJuridical?.actualAddress || '')
      setValue('ogrn', contractJuridical?.ogrn || '')
    }
  }

  const onSubmit = handleSubmit((data: any) => {
    const normalizedData: ContractPayer = {
      ...data,
      competitive_group_statement_id: competitiveGroupStatementId,
      contract_id: contractId,
      payer_type_id: payerTypeId,
    }

    api.client
      .editContractModeratorJuridical(normalizedData)
      .then((response) => {
        const { data } = response
        console.log(data)
        dispatch(createToast(data.message, 'success'))
      })
      .catch((error) => console.log(error))
      .finally(() => {
        dispatch(getModeratorContractInfo(String(contractId)))
      })
  })

  return {
    fields,
    onSubmit,
    getValues,
    ...rest,
  }
}

export default useChangePayerDetailsJuridical
