import React from 'react'

import cn from 'classnames'

import terms from '@common/terms'
import useAddDictionaryAdminModal from '@hooks/useAddDictionaryAdminModal'
import useAppSelector from '@hooks/useAppSelector'
import TemplateModal from '@modals/TemplateModal'
import { modalDataSelector } from '@redux/actions/selectors'
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'
import Input from '@ui/Input'

import './AddDictionaryAdminModal.scss'

const AddDictionaryAdminModal = () => {
  const { data } = useAppSelector(modalDataSelector)
  const { onSubmit, fields } = useAddDictionaryAdminModal(data)

  return (
    <TemplateModal
      title={'Добавить раздел'}
      hint="Зависимости необходимо указывать через запятую"
      className={cn({
        'template-modal-big': data.directoryName === 'competitive_groups',
      })}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <div className="profile-form__fields">
          {[...data.connections]
            .sort()
            .filter((item) => item !== 'visible' && item !== 'duplicate')
            .map((item, index) => (
              <Input
                key={index + 300}
                placeholder={`${item}`}
                className="profile-form__field profile-form__field--fb-100"
                {...fields[item]}
              />
            ))}
        </div>
        <div className="profile-form__fields-checkboxes">
          {[...data.connections]
            .sort()
            .filter((item) => item === 'visible' || item === 'duplicate')
            .map((item, index) => (
              <Checkbox
                key={index + 300}
                text={`${item === 'visible' ? 'Видимость' : item}`}
                theme="success-filled"
                {...fields[item]}
              />
            ))}
        </div>
        <Button
          theme="success"
          className="profile-form__button profile-form__button--align-left"
        >
          {terms.SAVE}
        </Button>
      </form>
    </TemplateModal>
  )
}

export default AddDictionaryAdminModal
