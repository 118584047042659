import React, { useEffect, useState } from 'react'

import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { getDisciplineTrials, getMoodleCourses } from '@redux/action-types'
import {
  selectDisciplineTrials,
  selectModeratorStatementsIdStatus,
  selectMoodleCourses,
} from '@redux/actions/selectors'
import Button from '@ui/Button'
import { formatDateYYYYMMDD } from '@utils/date'

const ModeratorEntranceExamsPage = () => {
  const headersCourses = [
    'Предмет',
    'Дата и время экзамена',
    'Форма испытаний',
    'Оценка',
    'Место проведения',
    'Ссылка на экзамен',
  ]

  const headersTrials = [
    'Предмет',
    'Дата и время экзамена',
    'Форма испытаний',
    'Оценка',
    'Место проведения',
  ]

  const dispatch = useAppDispatch()
  const statement = useAppSelector(selectModeratorStatementsIdStatus)
  // const entranceExams = useAppSelector(selectEntranceExams)
  const courses = useAppSelector(selectMoodleCourses)
  const trials = useAppSelector(selectDisciplineTrials)
  // let [trailsIds] = useState<Array<any>>([])
  // let [trailsList, setTrailsList] = useState<Array<any>>([])

  useEffect(() => {
    const externalId = statement?.users?.externalId
    const userId = statement.userId

    externalId &&
      dispatch(
        getMoodleCourses({
          externalId: externalId,
          userId: userId,
          statementId: statement?.id,
        })
      )

    userId && dispatch(getDisciplineTrials(`${userId}`))
  }, [])

  // useEffect(() => {
  //   if (entranceExams) {
  //     entranceExams.forEach((exam) => {
  //       exam.trials.forEach((item) => {
  //         if (
  //           !trailsIds.find((i) => i === item.discipline.translate[0].id) &&
  //           item.trialTypeId !== '2'
  //         ) {
  //           trailsIds.push(item.discipline.translate[0].id)
  //           setTrailsList((current) => [...current, item])
  //         }
  //       })
  //     })
  //   }
  // })

  return (
    <div className={'moderator-data-list'}>
      {trials && trials?.length && (
        <>
          <p className={'h1'}>Расписание</p>
          <table className="moderator-hallmarks-table">
            <thead>
              <tr>
                {headersTrials.map((header) => (
                  <th key={header}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {trials.map((trial, id) => {
                return (
                  <tr key={id}>
                    <td>{trial?.disciplineName || '-'}</td>
                    <td>{`${
                      trial?.schedule.split(' ')[0]
                        ? formatDateYYYYMMDD(trial?.schedule.split(' ')[0])
                        : '-'
                    } ${trial?.schedule.split(' ')[1] || '-'}`}</td>
                    <td>{trial?.trialTypeName || '-'}</td>
                    <td>Нет оценки</td>
                    <td>{trial?.place || '-'}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
      )}

      {courses?.courses?.length && (
        <>
          <p className={'h1'}>Вход на экзамен</p>
          <table className="moderator-hallmarks-table">
            <thead>
              <tr>
                {headersCourses.map((header) => (
                  <th key={header}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {courses.courses.map((course, id) => {
                return (
                  <tr key={id}>
                    <td>{course?.subject || '-'}</td>
                    <td>
                      {course?.data ? formatDateYYYYMMDD(course?.data) : '-'}{' '}
                      {course?.time || '-'}
                    </td>
                    <td>Вступительные испытания</td>
                    <td>{course?.value ? course.value : 'Нет оценки'}</td>
                    <td>{course?.form || '-'}</td>
                    <td>
                      {course?.link && (
                        <Button
                          theme="success"
                          onClick={(e) => {
                            e.preventDefault()
                            window.open(course?.link)
                          }}
                        >
                          Начать экзамен
                        </Button>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  )
}

export default ModeratorEntranceExamsPage
