import React, { useEffect } from 'react'

import AddIcon from '@assets/images/Add.svg'
import FullscreenIcon from '@assets/images/Fullscreen.svg'
import logoPlaceholder from '@assets/images/logo.png'
import profileImagePlaceholder from '@assets/images/profile-image-placeholder.png'
import terms from '@common/terms'
import useAddSettingAdminModal from '@hooks/useAddSettingAdminModal'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useSettingFormInitialization from '@hooks/useSettingFormInitialization'
import TemplateSettingModal from '@modals/TemplateSettingModal'
import { showModal } from '@redux/action-types'
import { modalDataSelector } from '@redux/actions/selectors'
import { getViewerAuthPicture, getViewerProfileImage } from '@redux/reducers'
import Button from '@ui/Button'
import Input from '@ui/Input'
import './AddSettingAuthAdminModal.scss'
import Textarea from '@ui/Textarea'

const AddSettingAuthAdminModal = () => {
  const { watch, setValue, onSubmit, fields } = useAddSettingAdminModal()

  const { personalData } = useSettingFormInitialization({ watch, setValue })
  const dispatch = useAppDispatch()

  const onOpenAuthImageModal = (mode: 'upload' | 'edit' | 'delete') => {
    dispatch(showModal({ name: 'EDIT_AUTH_IMAGE_MODAL', data: { mode, data } }))
  }

  const onOpenLogoImageModal = (mode: 'upload' | 'edit' | 'delete') => {
    dispatch(showModal({ name: 'EDIT_AUTH_LOGO_MODAL', data: { mode, data } }))
  }

  const { data } = useAppSelector(modalDataSelector)

  const image = `data:image/jpeg;base64,${data.picture}`
  const logo = `data:image/jpeg;base64,${data.logo}`

  useEffect(() => {
    dispatch(getViewerAuthPicture())
  }, [image])
  return (
    <TemplateSettingModal title={terms.AUTH_SETTING_TITLE + ' ' + data.name}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <div className="profile-form__fields modal-body">
          {/*заголовки + название страницы в админке*/}
          <div className="profile-form__fields auth-image-block__center-text-wrapper">
            <div className="auth-image-block__text-wrapper">
              <Input
                placeholder={terms.LOGIN_PAGE_TITLE}
                {...fields.titleLogin}
                className="profile-form__field profile-form__field--fb-100"
              />
              <Input
                placeholder={terms.REGISTER_PAGE_TITLE}
                {...fields.titleRegister}
                className="profile-form__field profile-form__field--fb-100"
              />
            </div>
            <div className="auth-image-block__text-wrapper auth-image-block__position-wrapper ">
              <Input
                placeholder={terms.LOGIN_PAGE_NAME}
                {...fields.name}
                className="profile-form__field profile-form__field--fb-100"
              />
            </div>

            <Input hidden={true} {...fields.id} />
          </div>
          {/*логотип + текст+ссылка*/}
          <div className="profile-form__fields auth-image-block__center-text-wrapper">
            <div className="auth-image-block__text-wrapper">
              <Input
                placeholder={terms.SITE_AUTH_LOGO_TEXT}
                {...fields.textLogo}
                className="profile-form__field profile-form__field--fb-100"
              />
            </div>
            <div className="auth-image-block__text-wrapper auth-image-block__position-wrapper ">
              <div className="image-controls__bottom-row">
                <label>
                  <p className="site-setting-image-label">{terms.SITE_AUTH_LOGO}</p>
                </label>
                <div className="auth-logo-flex-center__img-wrapper">
                  <img
                    placeholder={terms.SITE_AUTH_LOGO}
                    src={logo || logoPlaceholder}
                    alt={terms.SITE_AUTH_LOGO}
                    className="auth-logo-block__img-wrapper"
                  />
                </div>
                <button
                  className="image-controls__button"
                  onClick={() => onOpenLogoImageModal('upload')}
                  type="button"
                >
                  <AddIcon className="image-controls__icon" />
                  <span className="image-controls__label">{terms.SELECT_LOGO}</span>
                </button>
                {/*<button*/}
                {/*  type="button"*/}
                {/*  className="image-controls__button"*/}
                {/*  onClick={() => onOpenAuthImageModal('edit')}*/}
                {/*>*/}
                {/*  <FullscreenIcon className="image-controls__icon" />*/}
                {/*  <span className="image-controls__label">*/}
                {/*    {terms.EDIT_SIGN_IMAGE}*/}
                {/*  </span>*/}
                {/*</button>*/}
              </div>
            </div>

            <Input hidden={true} {...fields.id} />
          </div>
          {/*картинка + текст + текст ссылки*/}
          <div className="profile-form__fields auth-image-block__center-text-wrapper">
            <div className="auth-image-block__text-wrapper">
              <Input
                placeholder={terms.TEXT_ON_IMAGE}
                {...fields.textPicture}
                className="profile-form__field profile-form__field--fb-100"
              />
              <Input
                placeholder={terms.LINK_ON_IMAGE}
                {...fields.textPictureLink}
                className="profile-form__field profile-form__field--fb-100"
              />
              <Input
                placeholder={terms.URL_LINK_ON_IMAGE}
                {...fields.link}
                className="profile-form__field profile-form__field--fb-100"
              />
            </div>
            <div className="auth-image-block__text-wrapper auth-image-block__position-wrapper ">
              <div className="image-controls__bottom-row">
                <label>
                  <p className="site-setting-image-label">{terms.SIGN_IMAGE}</p>
                </label>
                <img
                  placeholder={terms.SIGN_IMAGE}
                  src={image || profileImagePlaceholder}
                  alt={terms.SIGN_IMAGE}
                  className="auth-image-block__img-wrapper"
                />
                <button
                  className="image-controls__button"
                  onClick={() => onOpenAuthImageModal('upload')}
                  type="button"
                >
                  <AddIcon className="image-controls__icon" />
                  <span className="image-controls__label">{terms.SELECT_IMAGE}</span>
                </button>
                {/*<button*/}
                {/*  type="button"*/}
                {/*  className="image-controls__button"*/}
                {/*  onClick={() => onOpenAuthImageModal('edit')}*/}
                {/*>*/}
                {/*  <FullscreenIcon className="image-controls__icon" />*/}
                {/*  <span className="image-controls__label">*/}
                {/*    {terms.EDIT_SIGN_IMAGE}*/}
                {/*  </span>*/}
                {/*</button>*/}
              </div>
            </div>

            <Input hidden={true} {...fields.id} />
          </div>
          {/*Заголовки и текст табличек*/}
          <div className="profile-form__fields auth-image-block__center-text-wrapper">
            <div className="auth-image-block__text-wrapper">
              <Input
                placeholder={terms.LEFT_CELL_TITLE}
                {...fields.leftTextTitle}
                className="profile-form__field profile-form__field--fb-100"
              />
              <Textarea
                placeholder={terms.LEFT_CELL_TEXT}
                {...fields.leftSideText}
                className="profile-form__field profile-form__field--fb-100"
              />
            </div>
            <div className="auth-image-block__text-wrapper">
              <Input
                placeholder={terms.RIGHT_CELL_TITLE}
                {...fields.rightTextTitle}
                className="profile-form__field profile-form__field--fb-100"
              />
              <Textarea
                placeholder={terms.RIGHT_CELL_TEXT}
                {...fields.rightSideText}
                className="profile-form__field profile-form__field--fb-100"
              />
            </div>
          </div>
        </div>
        {/*<div className="profile-form__fields-checkboxes"></div>*/}
        <Button
          theme="success"
          className="profile-form__button profile-form__button--align-left"
        >
          {terms.SAVE}
        </Button>
      </form>
    </TemplateSettingModal>
  )
}

export default AddSettingAuthAdminModal
