import React from 'react'
import { NavLink } from 'react-router-dom'

import { DirectoriesListResponse } from '@models'

type TableDirectoriesProps = {
  rows: DirectoriesListResponse[]
}

const TableDirectories = ({ rows }: TableDirectoriesProps): JSX.Element => {
  return (
    <div className="table-incoming-students">
      {rows.map((item) => (
        <NavLink to={`${item.table}-${item.id}`} key={item.id} target="_blank">
          <div className="table-incoming-students__link">
            <p>{item.translation[0].name}</p>
          </div>
        </NavLink>
      ))}
    </div>
  )
}

export default TableDirectories
