import usePaginatedEntity from '@hooks/usePaginatedEntity'
import { getVisaCountry } from '@redux/action-types'
import {
  selectPaginatedVisaCountries,
  selectPaginatedVisaCountryLoading,
} from '@redux/actions/selectors'

const usePaginatedVisaCountries = () => {
  return usePaginatedEntity({
    action: getVisaCountry,
    dataSelector: selectPaginatedVisaCountries,
    loaderSelector: selectPaginatedVisaCountryLoading,
  })
}

export default usePaginatedVisaCountries
