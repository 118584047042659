import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import HttpStatusCode from '@common/httpStatusCodes'
import regexp from '@common/regexp'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import { createToast } from '@redux/action-types'
import api, {
  ResetPasswordRespOk,
  ResetPasswordRespNotFound,
  ResetPasswordResponse,
} from '@services/api'

const useResetPasswordForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { register, handleSubmit, ...rest } = useForm<{
    email: string
  }>()

  const fields = {
    email: register('email', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: regexp.email,
        message: terms.VALID_EMAIL,
      },
    }),
  }

  const onSubmit = handleSubmit(async ({ email }) => {
    api.client
      .resetPassword(email)
      .then((response) => {
        if (response.status === HttpStatusCode.OK) {
          dispatch(createToast(`${terms.TEMP_PASSWORD_SEND} ${email}`, 'success'))
          switch (response.data.code) {
            case '1':
              navigate('/rus-sign-in')
              break
            case '2':
              navigate('/ino-sign-in')
              break
            case '3':
              navigate('/asp-sign-in')
              break
            default:
              navigate('/rus-sign-in')
              break
          }
          //  navigate('/rus-sign-in')
        }
      })
      .catch((response: Exclude<ResetPasswordResponse, ResetPasswordRespOk>) => {
        const { status } = response

        if (status === HttpStatusCode.NOT_FOUND) {
          const { data } = response as ResetPasswordRespNotFound
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
  })

  return {
    fields,
    onSubmit,
    ...rest,
  }
}

export default useResetPasswordForm
