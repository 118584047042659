import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import useAppDispatch from './useAppDispatch'
import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppSelector from '@hooks/useAppSelector'
import { validateDate } from '@lib/validators'
import { AddLgotaWithOptions, PrivilegesCreate } from '@models'
import {
  clearDocs,
  createToast,
  getPersonalData,
  hideModal,
} from '@redux/action-types'
import {
  selectPersonalFormData,
  selectStatementsElement,
  selectUserIdProfile,
} from '@redux/actions/selectors'
import { getUserLgota } from '@redux/reducers'
import api, {
  AddLgotaEntrantsResponse,
  ResponseOk,
  UploadDocsRespOk,
  UploadDocsResponse,
} from '@services/api'

const useAddPrivilegesForm = (type: 'ADD' | 'EDIT') => {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  const statement = useAppSelector(selectStatementsElement)
  const userId = useAppSelector(selectUserIdProfile)

  const { register, handleSubmit, setError, getValues, setValue, ...rest } =
    useForm<PrivilegesCreate>()

  useEffect(() => {
    !personalData && dispatch(getPersonalData())
  }, [])

  const personalData = useAppSelector(selectPersonalFormData)

  const fields = {
    typeDoc: register('typeDoc', { required: terms.REQUIRED_FIELD }),
    lgotaId: register('lgotaId', {
      required: terms.REQUIRED_FIELD,
      onChange: () => {
        setValue('lgotaDocId', null)
      },
    }),
    number: register('number', { required: terms.REQUIRED_FIELD }),
    dateIssue: register('dateIssue', {
      validate: (value) => {
        return validateDate(value, personalData?.dateBirthday || '')
          ? true
          : terms.DATE_INVALID
      },
    }),
    lgotaDocId: register('lgotaDocId', { required: terms.REQUIRED_FIELD }),
    documents: register('documents', { required: terms.REQUIRED_FIELD }),
    docId: register('docId'),
  }

  const onSubmit = handleSubmit((data: any) => {
    if (!(statement && userId)) {
      return
    }
    setLoading(true)
    const normalizedData: PrivilegesCreate = {
      ...data,
      statementId: statement.id,
      lgotaId: data.lgotaId.value || null,
      lgotaCode: data.lgotaId.code || null,
      lgotaDocId: data.lgotaDocId.value || null,
      lgotaDocCode: data.lgotaDocId.code || null,
    }
    if (type === 'EDIT') {
      let status = false
      api.client
        .uploadDocs({
          documents: normalizedData.documents,
          typeDoc: 'LGOTA',
          docId: normalizedData.docId,
        })
        .then(() => {
          status = true
        })
        .catch((response: Exclude<UploadDocsResponse, UploadDocsRespOk>) => {
          const { status } = response

          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
        .finally(() => {
          if (status) {
            api.client
              .updateUserLgota(normalizedData)
              .then((response) => {
                const { data } = response as unknown as ResponseOk
                dispatch(createToast(data.message, 'success'))
                dispatch(getUserLgota({ statementId: statement.id, userId: userId }))
                dispatch(clearDocs('LGOTA'))
                dispatch(hideModal())
              })
              .catch((response: Exclude<AddLgotaEntrantsResponse, ResponseOk>) => {
                const { status } = response

                if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
                  const { data } = response
                  dispatch(createToast(data.message, 'danger'))

                  Object.keys(data.errors).forEach((key) => {
                    setError(key as keyof PrivilegesCreate, {
                      type: 'manual',
                      message: data.errors[key][0],
                    })
                  })
                } else {
                  dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
                }
              })
              .finally(() => setLoading(false))
          }
        })
    } else {
      api.client
        .addUserLgota(normalizedData)
        .then((response) => {
          const { data } = response as unknown as ResponseOk
          dispatch(createToast(data.message, 'success'))
          dispatch(getUserLgota({ statementId: statement.id, userId: userId }))
          dispatch(hideModal())
        })
        .catch((response: Exclude<AddLgotaEntrantsResponse, ResponseOk>) => {
          const { status } = response

          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))

            Object.keys(data.errors).forEach((key) => {
              setError(key as keyof PrivilegesCreate, {
                type: 'manual',
                message: data.errors[key][0],
              })
            })
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  })

  return {
    fields,
    onSubmit,
    loading,
    setValue,
    getValues,
    ...rest,
  }
}

export default useAddPrivilegesForm
