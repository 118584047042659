import React, { useEffect, useState } from 'react'

import DirectionsTrainingList from '@components/DirectionsTrainingList'
import DirectionsTrainingSearch from '@components/DirectionsTrainingSearch'

const DirectionsTrainingCreatePage = (scenarioKeys) => {
  return (
    <section className={'directions-training-create-page'}>
      <DirectionsTrainingSearch {...scenarioKeys} />
      <DirectionsTrainingList {...scenarioKeys} />
    </section>
  )
}

export default DirectionsTrainingCreatePage
