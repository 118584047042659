export const swap = <T extends string, U extends string>(
  a: { [key in T]: U }
): {
  [key in U]: T
} =>
  (Object.entries(a) as any).reduce(
    (r, [k, v]) => ({
      ...r,
      [v]: k,
    }),
    {}
  )
