import React, { useEffect } from 'react'
import { Controller, FieldError } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import terms from '@common/terms'
import FillingStatementsForm from '@components/FillingStatementsForm'
import ProfileSection from '@components/ProfileSection'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useNotEntrantsContractForm from '@hooks/useNotEntrantsContractForm'
import { SelectOption } from '@models'
import {
  getDirectionsListOptions,
  getTypeIdentityDocuments,
} from '@redux/action-types'
import { selectDirectionListOptions } from '@redux/actions/selectors'
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import Select from '@ui/Select'

const NotEntrantsContractForm = ({
  payerTypeId,
  trajectoryId,
  contract,
}: {
  payerTypeId: number
  trajectoryId?: number
  contract?: any
}) => {
  const { statementId } = useParams()

  const {
    fields,
    formState: { errors },
    control,
    setValue,
    onSubmit,
    clearErrors,
    isDateOfExpirationExists,
    isSeriesRequired,
    docs,
    onDrop,
    onDelete,
    onChange,
    typeIdentityDocumentOptions,
    isCodeActive,
    forIno,
    forInoPassport,
  } = useNotEntrantsContractForm(contract, trajectoryId, statementId)

  const dispatch = useAppDispatch()

  const directionOptions = useAppSelector(selectDirectionListOptions)
  let filterOptions = directionOptions.filter((item) => item.accessСonclusion)

  useEffect(() => {
    dispatch(getDirectionsListOptions(String(statementId)))
    setValue('payerTypeId', payerTypeId)
    if (contract) {
      setValue('contractId', contract.id)
    }
    if (statementId) {
      setValue('statementId', Number(statementId))
    }
    !typeIdentityDocumentOptions.length && dispatch(getTypeIdentityDocuments())
  }, [])

  useEffect(() => {
    if (contract) {
      setValue('snils', contract.snils)
      setValue('payerEmail', contract.payerEmail)
    }
  }, [contract])

  useEffect(() => {
    if (docs.length) {
      clearErrors('document')
    }
    setValue('document', docs)
  }, [docs])

  return (
    <FillingStatementsForm
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <ProfileSection title="">
        <div className="profile-form__fields contract">
          <Controller
            name="directionTrainingId"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                options={
                  trajectoryId === 2
                    ? filterOptions.filter(
                        (option: any) => option.formPaymentCode === '2'
                      )
                    : filterOptions
                }
                placeholder={terms.DIRECTIONS_TRAINING}
                className="profile-form__field"
                markRequired={true}
                {...(field as unknown)}
                error={errors.directionTrainingId?.message}
              />
            )}
          />
          {forIno && (
            <Checkbox
              text="Планирую брать образовательный кредит"
              className="profile-form__checkbox"
              {...fields.eduCredit}
            />
          )}
        </div>
      </ProfileSection>

      <ProfileSection title="Основные данные о плательщике">
        <div className="profile-form__fields">
          <Input
            className="profile-form__field"
            placeholder={terms.SURNAME_IN_RUSSIAN}
            markRequired={true}
            {...fields.surname}
            error={errors.surname?.message}
          />
          <Input
            className="profile-form__field"
            placeholder={terms.NAME_IN_RUSSIAN}
            markRequired={true}
            {...fields.name}
            error={errors.name?.message}
          />
          <Input
            placeholder={terms.PATRONYMIC}
            hint={terms.CYRILLIC_CHARACTERS_ONLY}
            className="profile-form__field"
            {...fields.patronymic}
            error={errors.patronymic?.message}
          />
          <Input
            className="profile-form__field"
            placeholder={terms.DATE_OF_BIRTH}
            type="date"
            max="100"
            markRequired={true}
            {...fields.birthDay}
            error={errors.birthDate?.message}
          />
          <Input
            className="profile-form__field"
            placeholder={terms.MOBILE_PHONE}
            type="tel"
            markRequired={true}
            {...fields.phone}
            error={errors.phone?.message}
          />
          <Input
            placeholder="Email"
            className="profile-form__field"
            {...fields.payerEmail}
            markRequired={true}
            error={errors.payerEmail?.message}
          />
          <Input
            className="profile-form__field profile-form__field--fb-100"
            placeholder="Адрес по прописке"
            markRequired={true}
            {...fields.addressResidential}
            error={errors.addressResidential?.message}
          />
          <Input
            className="profile-form__field profile-form__field--fb-100"
            placeholder="Адрес фактического проживания"
            markRequired={true}
            {...fields.address}
            error={errors.address?.message}
          />
          <Input
            className="profile-form__field profile-form__field--fb-100"
            placeholder="СНИЛС"
            markRequired={!forIno}
            {...fields.snils}
            error={errors.snils?.message}
          />
        </div>
      </ProfileSection>

      <ProfileSection title="Паспортные данные плательщика">
        <div className="profile-form__fields">
          <Controller
            name="typeIdentityDocumentId"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                className="profile-form__field"
                {...(field as unknown)}
                options={typeIdentityDocumentOptions}
                placeholder={terms.DOCUMENT_TYPE}
                error={errors.typeIdentityDocumentId?.message}
                markRequired={true}
              />
            )}
          />
          <div className="profile-form__field profile-form__field--container">
            <Input
              className="profile-form__input profile-form__input--fb-50"
              placeholder={terms.SERIES}
              markRequired={isSeriesRequired || forInoPassport}
              {...fields.series}
              error={errors.series?.message}
            />
            <Input
              className="profile-form__input profile-form__input--fb-50"
              placeholder={terms.NUMBER}
              markRequired={true}
              {...fields.number}
              error={errors.number?.message}
            />
          </div>
          <Input
            disabled={!isCodeActive()}
            markRequired={isCodeActive()}
            className="profile-form__field"
            placeholder={terms.DIVISION_CODE}
            {...fields.code}
            error={errors.code?.message}
          />
          <Input
            className="profile-form__field"
            placeholder={terms.DATE_OF_ISSUE}
            type="date"
            markRequired={true}
            {...fields.whenIssued}
            error={errors.whenIssued?.message}
          />
          {isDateOfExpirationExists && (
            <Input
              className="profile-form__field"
              placeholder={terms.DATE_OF_EXPIRATION}
              type="date"
              markRequired={true}
              {...fields.validityPeriod}
              error={errors.validityPeriod?.message}
            />
          )}
          <Input
            className="profile-form__field"
            placeholder={terms.ISSUING_AUTHORITY}
            markRequired={true}
            {...fields.issuedByWhom}
            error={errors.issuedByWhom?.message}
          />
          <Input
            className="profile-form__field profile-form__field--fb-100"
            placeholder={terms.PLACE_OF_BIRTH}
            markRequired={true}
            {...fields.placeOfBirth}
            error={errors.placeOfBirth?.message}
          />
          <div>
            <Checkbox
              text="Есть доверенность представителя"
              className="profile-form__checkbox"
              {...fields.attorneyRepresentative}
            />
            <Checkbox
              text="Есть доверенность заказчика"
              className="profile-form__checkbox"
              {...fields.attorneyCustomer}
            />
          </div>
        </div>
      </ProfileSection>

      <ProfileSection
        title={terms.COPY_OF_PASSPORT}
        markRequired={true}
        fileUploadingHint
      >
        <br />
        Необходимо загрузить первую страницу паспорта и страницу с регистрацией
        <FileUploader
          files={docs}
          onDrop={onDrop}
          onChange={onChange}
          onDelete={onDelete}
          className="profile-form__file-uploader"
        />
        {errors.document && (
          <div className="profile-form__error">
            {(errors.document as unknown as FieldError | undefined)?.message}
          </div>
        )}
      </ProfileSection>
      {/* TODO: rename class  */}
      <span className="contract__create">
        Заполните раздел, если Вы планируете поступать на платное обучение
      </span>
      <Button className="create-contract-form__button" theme="success">
        {terms.SUBMIT_CONTRACT_FOR_APPROVAL}
      </Button>
    </FillingStatementsForm>
  )
}

export default NotEntrantsContractForm
