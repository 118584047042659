import React, { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

import useAppDispatch from '@hooks/useAppDispatch'
import useModeratorStatementsRoutesContext, {
  ModeratorStatementsRoutesContextProvider,
} from '@hooks/useModeratorStatementsRoutesContext'
import useSubmitFormContext, {
  SubmitFormContextProvider,
} from '@hooks/useSubmitFormContext'
import SidenavPage from '@pages/SidenavPage'
import { clearSupportPersonalData } from '@redux/action-types'
import Breadcrumbs from '@ui/Breadcrumbs'
import { isNumeric } from '@utils/common'

const ModeratorPersonalInformationIdPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { userId } = useParams<{ userId?: string }>()

  useEffect(() => {
    if (userId) {
      if (!isNumeric(userId)) {
        navigate('../')
      }
    }
    return () => {
      dispatch(clearSupportPersonalData())
    }
  }, [userId])

  return (
    <div className={'moderator-statements-id-page'}>
      <div className={'moderator-statements-id-page__header'}>
        <Breadcrumbs
          link={'/admin/personal-information'}
          className={'moderator-statements-id-page__header-title'}
          title={'Просмотр анкеты'}
        />
      </div>
      <SubmitFormContextProvider>
        <ModeratorStatementsRoutesContextProvider>
          <SidenavPage
            useSubmitFormHook={useSubmitFormContext}
            useHooks={useModeratorStatementsRoutesContext}
            title=""
            next={false}
          >
            <Outlet />
          </SidenavPage>
        </ModeratorStatementsRoutesContextProvider>
      </SubmitFormContextProvider>
    </div>
  )
}

export default ModeratorPersonalInformationIdPage
