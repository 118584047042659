import React from 'react'

import TargetAgreementForm from '@components/TargetAgreementForm'

const TargetAgreementPage = (scenarioKeys) => {
  return (
    <div>
      <TargetAgreementForm {...scenarioKeys} />
    </div>
  )
}

export default TargetAgreementPage
