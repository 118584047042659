import { NavRoute } from 'types/custom-types'

import terms from '@common/terms'

export const statementsTabRotes: NavRoute[] = [
  {
    to: '/trajectory',
    title: terms.NEW_APPLICATION,
    text: '1',
    key: 'trajectory',
    status: 'not visited',
  },
  {
    to: '/training-level',
    title: terms.NEW_APPLICATION,
    text: '2',
    key: 'level',
    status: 'not visited',
  },
  {
    to: '/filling-statements',
    title: terms.NEW_APPLICATION,
    text: '3',
    key: 'filling',
    status: 'not visited',
  },
]
