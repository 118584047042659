import React from 'react'
import { NavLink } from 'react-router-dom'

import NotFound from '@assets/images/NotFound.svg'
import Button from '@ui/Button'

const NotFound404 = () => {
  return (
    <div className={'not-found-page'}>
      <NotFound className={'not-found-page__image'} />
      <h1>Страница не найдена</h1>
      <NavLink to={'/'} className={'not-found-page__btn'}>
        <Button theme={'success'} className={'not-found-page__btn'}>
          Перейти на главную страницу
        </Button>
      </NavLink>
    </div>
  )
}

export default NotFound404
