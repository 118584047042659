import React from 'react'
import { Outlet } from 'react-router-dom'

import terms from '@common/terms'
import useAuthInitialization from '@hooks/useAuthInitialization'
import useProfileRoutesContext, {
  ProfileRoutesContextProvider,
} from '@hooks/useProfileRoutesContext'
import useSubmitFormContext, {
  SubmitFormContextProvider,
} from '@hooks/useSubmitFormContext'
import SidenavPage from '@pages/SidenavPage'

const ProfilePage = () => {
  useAuthInitialization()

  return (
    <SubmitFormContextProvider>
      <ProfileRoutesContextProvider>
        <SidenavPage
          title={terms.PERSONAL_INFORMATION}
          useHooks={useProfileRoutesContext}
          useSubmitFormHook={useSubmitFormContext}
        >
          <Outlet />
        </SidenavPage>
      </ProfileRoutesContextProvider>
    </SubmitFormContextProvider>
  )
}

export default ProfilePage
