import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { NavLink, useParams } from 'react-router-dom'

import { NATIONALITY_BY_CODE } from '@common/constants'
import terms from '@common/terms'
import BlankList from '@components/BlankList'
import BlankPriority from '@components/BlankPriority'
import DirectionsTrainingElementPriorityStatement from '@components/DirectionsTrainingListPriority/DirectionsTrainingElementPriorityStatement'
import FillingStatementsForm from '@components/FillingStatementsForm'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useDirectionsTrainingElement from '@hooks/useDirectionsTrainingElement'
import useDownloadFilesForm from '@hooks/useDownloadFilesForm'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import useReadOnlyFillingStatements from '@hooks/useReadOnlyFillingStatements'
import useTrialsDirectionTrainingList from '@hooks/useTrialsDirectionTrainingList'
import {
  ChosenTrials,
  IndexForSelect,
  ViewerDirectionsListStatements,
} from '@models'
import {
  setDirectionsListStatements,
  getDirectionsListStatements,
  getTabEge,
  getBlankStatement,
  getPassportData,
  getBlankPriorityStatement,
} from '@redux/action-types'
import {
  selectBlankStatement,
  selectLocalizedViewerProfile,
  selectStatementsElement,
} from '@redux/actions/selectors'
import {
  selectDirectionsListStatements,
  selectTabEgeList,
} from '@redux/actions/selectors/statements'
import Button from '@ui/Button'

const DirectionsTrainingPriorityListPage = () => {
  const { statementId } = useParams()
  const { order } = useDirectionsTrainingElement()
  const { fixDirection } = useReadOnlyFillingStatements()
  const dispatch = useAppDispatch()
  const profileData = useAppSelector(selectLocalizedViewerProfile)
  const [isNationalityBy, setIsNationalityBy] = useState(false)
  const DirectionsListStatements = useAppSelector(selectDirectionsListStatements)

  const blankStatements = useAppSelector(selectBlankStatement)

  const { formValues } = useDownloadFilesForm()

  useEffect(() => {
    if (statementId) {
      dispatch(getDirectionsListStatements(Number(statementId)))
    }
  }, [])
  const statement = useAppSelector(selectStatementsElement)

  useEffect(() => {
    setIsNationalityBy(
      profileData?.userNational[0].nationality.code === NATIONALITY_BY_CODE
    )
  }, [profileData])

  const reorder = (
    list: ViewerDirectionsListStatements[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination || DirectionsListStatements === null) {
      return
    }
    const items = reorder(
      DirectionsListStatements,
      result.source.index,
      result.destination.index
    )
    const itemsId = items.map((el) => {
      return el.id
    })
    order(itemsId)
    dispatch(setDirectionsListStatements(items))
  }

  const { onNextPageNavigate } = useFillingStatementsRoutesContext()
  let indexForSelect: IndexForSelect[] = []
  let count: number | null | undefined = null

  DirectionsListStatements?.map((el, index) => {
    count = el.selectCount

    indexForSelect.push({
      label: String(index + 1),
      value: index + 1,
    })
  })

  if (count != null) {
    indexForSelect = indexForSelect.slice(0, -count)
  }

  useEffect(() => {
    if (statement) {
      dispatch(getBlankPriorityStatement(statement.id))
      dispatch(getPassportData())
    }
  }, [statement])
  return (
    <FillingStatementsForm
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      Вы можете поменять приоритеты направлений поступления до дня завершения приема
      заявлений.
      <section className={'directions-training-list-page'}>
        {DirectionsListStatements && DirectionsListStatements.length > 0 && (
          <div className={'mt-24'}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {DirectionsListStatements.map((el, index) => (
                      <Draggable
                        key={el.id}
                        draggableId={el.id.toString()}
                        index={index}
                        isDragDisabled={true}
                      >
                        {(provided) => (
                          <div
                            style={{ height: '50px', background: 'red' }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <DirectionsTrainingElementPriorityStatement
                              readOnly={fixDirection}
                              item={el}
                              index={index + 1}
                              selectNumberProps={indexForSelect}
                              isNationalityBy={isNationalityBy}
                              acceptId={
                                DirectionsListStatements.find(
                                  (el) => el.pivot.accept
                                )?.id
                              }
                              first={
                                DirectionsListStatements[index - 1]
                                  ? DirectionsListStatements[index - 1].id
                                  : true
                              }
                              last={
                                DirectionsListStatements[index + 1]
                                  ? DirectionsListStatements[index + 1].id
                                  : true
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <BlankPriority
              blankStatements={blankStatements}
              flag={
                formValues?.findIndex(
                  (el) => el.accept === false && el.statementCheckboxId !== 6
                ) === -1
              }
            />
          </div>
        )}
      </section>
    </FillingStatementsForm>
  )
}

export default DirectionsTrainingPriorityListPage
