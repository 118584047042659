import { useEffect } from 'react'

import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import usePaginatedNationals from '@hooks/usePaginatedNationals'
import {
  getFaculty,
  getFormDirectionsTrainingSearch,
  getModeratorEducationProfile,
  getModeratorModeratorsList,
  getModeratorStatusesList,
  getModeratorScenario,
  getModeratorStatusStatement,
  getModeratorTrajectory,
  getPaymentDirectionsTrainingSearch,
  getPartnersList,
} from '@redux/action-types'
import {
  selectFacultyOptions,
  selectLevelDirectionsTrainingSearchOptions,
  selectModeratorEducationProfile,
  selectModeratorModeratorsList,
  selectModeratorStatusesList,
  selectModeratorScenario,
  selectModeratorStatusStatement,
  selectModeratorTrajectory,
  selectPaymentDirectionsTrainingSearchOptions,
  selectRoleIdProfile,
  selectPartnersList,
  selectModeratorModeratorsListWithNoModerator,
} from '@redux/actions/selectors'

const useModeratorStatementsInitialization = () => {
  const dispatch = useAppDispatch()
  const roleId = useAppSelector(selectRoleIdProfile)

  useEffect(() => {
    levelOptions.length === 0 && dispatch(getFormDirectionsTrainingSearch())
    facultyOptions.length === 0 && dispatch(getFaculty())
    paymentFormOptions.length === 0 && dispatch(getPaymentDirectionsTrainingSearch())
    educationProfileOptions.length === 0 && dispatch(getModeratorEducationProfile())
    scenarioOptions.length === 0 && dispatch(getModeratorScenario())
    trajectoryOptions.length === 0 && dispatch(getModeratorTrajectory())
    moderatorStatusStatementOptions.length === 0 &&
      dispatch(getModeratorStatusStatement(roleId))
    moderatorsListOptions.length === 0 && dispatch(getModeratorModeratorsList())
    statusesListOptions.length === 0 && dispatch(getModeratorStatusesList())
    partnersSelectOptions.length === 0 &&
      dispatch(getPartnersList({ value: '', page: 1, perPage: 100 }))
  }, [])
  const nationalSelectProps = usePaginatedNationals()

  const levelOptions = useAppSelector(selectLevelDirectionsTrainingSearchOptions)
  const educationProfileOptions = useAppSelector(selectModeratorEducationProfile)
  const facultyOptions = useAppSelector(selectFacultyOptions)
  const scenarioOptions = useAppSelector(selectModeratorScenario)
  const moderatorStatusStatementOptions = useAppSelector(
    selectModeratorStatusStatement
  )
  const trajectoryOptions = useAppSelector(selectModeratorTrajectory)
  const moderatorsListOptions = useAppSelector(
    selectModeratorModeratorsListWithNoModerator
  )
  const statusesListOptions = useAppSelector(selectModeratorStatusesList)
  const paymentFormOptions = useAppSelector(
    selectPaymentDirectionsTrainingSearchOptions
  )
  const partnersSelectOptions = useAppSelector(selectPartnersList)
  const messageOptions = [
    { value: 0, label: 'Непрочитанные' },
    { value: 1, label: 'Прочитанные' },
  ]

  return {
    paymentFormOptions,
    educationProfileOptions,
    levelOptions,
    facultyOptions,
    scenarioOptions,
    moderatorStatusStatementOptions,
    trajectoryOptions,
    moderatorsListOptions,
    nationalSelectProps,
    statusesListOptions,
    partnersSelectOptions,
    messageOptions,
  }
}

export default useModeratorStatementsInitialization
