import { useEffect } from 'react'
import { UseFormSetValue } from 'react-hook-form'

import useAppSelector from '@hooks/useAppSelector'
import { LgotaSpecialConditions } from '@models'
import { selectSpecialCondition } from '@redux/actions/selectors'

type useHandlerLgotaInitializationProps = {
  setValue: UseFormSetValue<LgotaSpecialConditions>
}

const useHandlerLgotaInitialization = ({
  setValue,
}: useHandlerLgotaInitializationProps) => {
  const specialCondition = useAppSelector(selectSpecialCondition)
  useEffect(() => {
    if (specialCondition.length) {
      setValue('haveSpecialConditions', specialCondition[0].haveSpecialConditions)
      setValue(
        'commentSpecialConditions',
        specialCondition[0].commentSpecialConditions
      )
      setValue('haveReasonConditions', specialCondition[0].haveReasonConditions)
      setValue('presidentialDecree_268', specialCondition[0].presidentialDecree268)
      setValue('presidentialDecree_268B', specialCondition[0].presidentialDecree268B)
      setValue('haveReferenceMse', specialCondition[0]?.haveReferenceMse)
      setValue('decree_245_070423', specialCondition[0]?.decree245070423)
      setValue('decree_231_010323', specialCondition[0]?.decree231010323)
      setValue('decree_528_030423', specialCondition[0]?.decree528030423)
      if (
        specialCondition[0]?.haveReferenceMse ||
        specialCondition[0]?.haveSpecialConditions
      ) {
        setValue('notHaveLgot', false)
      } else {
        setValue('notHaveLgot', true)
      }
    }
  }, [specialCondition])
}

export default useHandlerLgotaInitialization
