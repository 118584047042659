import React from 'react'
import ReactSelect, { components } from 'react-select'

import { Locale } from 'types/custom-types'

import { locale, changeLocale } from '@common/terms'

type OptionValue = {
  label: Locale
  value: number
}

const options: OptionValue[] = [
  {
    label: 'ru',
    value: 0,
  },
  {
    label: 'en',
    value: 1,
  },
]

const Option = (props) => {
  if (props.data.label === locale) {
    return null
  }

  return <components.Option {...props} />
}

const LangChanger = () => (
  <div className="lang-changer">
    <ReactSelect<OptionValue>
      defaultValue={options.find((x) => x.label === locale)}
      options={options}
      classNamePrefix="lang-changer"
      onChange={(option) => {
        changeLocale(option!.label)
      }}
      components={{ Option }}
    />
  </div>
)

export default LangChanger
