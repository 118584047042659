import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import DownloadIcon from '@assets/images/Download.svg'
import { ROLE_ADMIN, ROLE_MODERATOR } from '@common/constants'
import { mimeTypes } from '@common/manual'
import ModeratorDataList from '@components/ModeratorDataList'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useDownloadDocument from '@hooks/useDownloadDocument'
import useModeratorExamResultPage from '@hooks/useModeratorExamResultPage'
import { DataList } from '@models'
import ModeratorDocumentsPage from '@pages/moderator/ModeratorDocumentsPage'
import { getModeratorExamResultData } from '@redux/action-types'
import {
  selectModeratorExamResultData,
  selectRoleIdProfile,
} from '@redux/actions/selectors'
import { getDocs } from '@redux/reducers'
import api from '@services/api'
import Button from '@ui/Button'

const headers = ['Предмет', 'ЕГЭ/ЦТ/ЦЭ', 'Балл', 'Год сдачи', 'Проверено', 'Файл']

const ModeratorExamResultPage = () => {
  const dispatch = useAppDispatch()
  const { checkLoading, checkResult } = useModeratorExamResultPage()
  const { id } = useParams<{ id?: string }>()

  useEffect(() => {
    if (id) {
      dispatch(getModeratorExamResultData(id))
    }
  }, [id])

  const { downloadDocument } = useDownloadDocument(true)

  const examResult = useAppSelector(selectModeratorExamResultData)
  const roleId = useAppSelector(selectRoleIdProfile)

  const onDownload = (type: 'CONFIRM_RESULT_EGE', docId: number) => {
    downloadDocument(type, mimeTypes.pdf, 'pdf', docId, '', Number(id))
  }

  useEffect(() => {
    api.client.getDocs('PREVIOUS_DOCUMENTS')
  })

  const list: DataList = [
    {
      title: 'Тип документа:',
      label: examResult?.personalData?.typeIdentityDocumentName || '-',
    },
    {
      title: 'Серия:',
      label: examResult?.personalData?.serial || '-',
    },
    {
      title: 'Номер:',
      label: examResult?.personalData?.number || '-',
    },
    {
      title: 'Фамилия:',
      label: examResult?.personalData?.surname || '-',
    },
    {
      title: 'Имя:',
      label: examResult?.personalData?.name || '-',
    },
    {
      title: 'Отчество:',
      label: examResult?.personalData?.patronymic || '-',
    },
    {
      title: 'Когда выдан:',
      label: examResult?.personalData?.dateOfIssue || '-',
    },
  ]

  return (
    <div className={'moderator-exam-result-page'}>
      <table className="moderator-exam-result-table">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {examResult?.resultEge?.map((item, id) => {
            return (
              <tr key={id}>
                <td>{item.name}</td>
                <td>
                  {!(item.resultCentralExam || item.resultCentralTesting)
                    ? 'ЕГЭ'
                    : item.resultCentralExam
                    ? 'ЦЭ'
                    : 'ЦТ'}

                  {}
                </td>
                <td>{item.result}</td>
                <td>{item.year}</td>
                <td className={item.verified ? 'success' : 'danger'}>
                  {item.resultCentralTesting || item.resultCentralExam
                    ? '-'
                    : item.verified === null || undefined
                    ? item.verified
                      ? 'Действительно '
                      : 'Не проверено'
                    : 'Не найдено'}
                </td>

                <td>
                  {item.resultCentralExam || item.resultCentralTesting ? (
                    <div className="buttons">
                      <button
                        type="button"
                        onClick={() => onDownload('CONFIRM_RESULT_EGE', item.docId)}
                      >
                        <DownloadIcon />
                      </button>
                    </div>
                  ) : (
                    <div> - </div>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {(roleId === ROLE_MODERATOR || roleId === ROLE_ADMIN) && (
        <Button theme={'success'} loading={checkLoading} onClick={checkResult}>
          Проверить результаты ЕГЭ
        </Button>
      )}
      <h2>Данные предыдущего документа</h2>
      <ModeratorDataList list={list} />
      <ModeratorDocumentsPage
        params={[{ typeDoc: 'previous_documents', title: 'Копия документа' }]}
      />
    </div>
  )
}

export default ModeratorExamResultPage
