import React, { useEffect } from 'react'
import './SettingPage.scss'
import { NavLink } from 'react-router-dom'

import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { getDirectoriesList } from '@redux/action-types/admin'
import {
  //  selectAdminDirectoriesList,
  selectAdminPageDirectoriesList,
  selectAdminPerPageDirectoriesList,
} from '@redux/actions/selectors'

const SettingPage = () => {
  const dispatch = useAppDispatch()

  //  const directoriesList = useAppSelector(selectAdminDirectoriesList) || { data: [] }

  const page = useAppSelector(selectAdminPageDirectoriesList)
  const perPage = useAppSelector(selectAdminPerPageDirectoriesList)

  useEffect(() => {
    dispatch(getDirectoriesList({ page, perPage }))
  }, [page, perPage])

  return (
    <main className="directory-page">
      <div className="directory-page-header">
        <h1 className="directory-page__title">Настройки сайта</h1>
      </div>
      <div className="table-incoming-students">
        <NavLink to={'/admin/settings/auth'} target="_blank">
          <div className="table-incoming-students__link">
            <p>Настройка страниц авторизации</p>
          </div>
        </NavLink>
        <NavLink to={'/admin/settings/exchange'} target="_blank">
          <div className="table-incoming-students__link">
            <p>Обновление справочников через 1С</p>
          </div>
        </NavLink>
      </div>
    </main>
  )
}

export default SettingPage
