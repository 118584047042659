import React, { useEffect } from 'react'
import { Controller, FieldError } from 'react-hook-form'
import InputMask from 'react-input-mask'

import terms from '@common/terms'
import ProfileSection from '@components/ProfileSection'
import useAchivementFormInitialization from '@hooks/useAchivementsFormInitialization'
import useAddIndividualAchivementForm from '@hooks/useAddIndividualAchivementForm'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import usePassportDataFormInitialization from '@hooks/usePassportDataFormInitialization'
import TemplateModal from '@modals/TemplateModal'
import { Achivement, SelectOption } from '@models'
import { getAchivementDocTypes } from '@redux/action-types'
import { modalDataSelector, selectLevelValue } from '@redux/actions/selectors'
import Button from '@ui/Button'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import Select from '@ui/Select'

const AddIndividualAchivementModal = () => {
  const {
    selectAchievement,
    requiredAchvments,
    control,
    fields,
    watch,
    onSubmit,
    setValue,
    loading,
    clearErrors,
    formState: { errors },
  } = useAddIndividualAchivementForm()

  const dispatch = useAppDispatch()

  const { statementId, achivement } = useAppSelector(modalDataSelector) as {
    statementId: number
    achivement?: Achivement
  }

  const scenarioId = useAppSelector(selectLevelValue)

  const {
    achivementFile,
    docTypesOptions,
    achivementTypesOptions,
    copyCertificateFile,
  } = useAchivementFormInitialization({ setValue, achivement, statementId })

  const isEditing = Boolean(achivement)

  const achivementId = watch('achivmentId') as unknown as SelectOption | undefined

  const achivementsIdsForHint = [19, 20, 21, 22]
  const isShowHint = !!(
    achivementId && achivementsIdsForHint.includes(achivementId?.value)
  )

  const isShowLinkToPubPage = achivementId?.value === 48

  useEffect(() => {
    if (achivementId?.value) {
      dispatch(getAchivementDocTypes(achivementId.value))
    }
  }, [achivementId])

  useEffect(() => {
    if (achivementFile.docs.length) {
      clearErrors('documents')
    }
    setValue('documents', achivementFile.docs)
  }, [achivementFile.docs])

  useEffect(() => {
    if (copyCertificateFile.docs.length) {
      clearErrors('secondDocuments')
    }
    setValue('secondDocuments', copyCertificateFile.docs)
  }, [copyCertificateFile.docs])

  return (
    <TemplateModal
      title={isEditing ? 'Редактировать достижение' : 'Добавить достижение'}
      clearTypeDoc={'OLYMPIAD'}
    >
      {/* TODO: Переименовать компонент ProfileForm и классы profile-form... */}
      <form onSubmit={onSubmit}>
        <div className="profile-form__fields">
          <Controller
            name="achivmentId"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                options={achivementTypesOptions}
                placeholder="Тип достижения"
                className="profile-form__field"
                markRequired={true}
                {...(field as unknown)}
                error={errors.achivmentId?.message}
              />
            )}
          />
          <Input
            className="profile-form__field"
            placeholder="Дата выдачи"
            type="date"
            markRequired={true}
            {...fields.dateIssue}
            error={errors.dateIssue?.message}
          />
          <Controller
            name="achivmentDocId"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                options={achivementTypesOptions && docTypesOptions}
                placeholder="Подтверждающий документ"
                className="profile-form__field"
                markRequired={true}
                {...(field as unknown)}
                error={errors.achivmentDocId?.message}
              />
            )}
          />
          <Input
            className="profile-form__field"
            placeholder="Серия (если есть) и номер документа"
            markRequired={true}
            {...fields.number}
            error={errors.number?.message}
          />
          {isShowLinkToPubPage && (
            <Input
              className="profile-form__field"
              placeholder="Ссылка на страницу публикации на сайте журнала"
              markRequired={true}
              hint={
                'Если электронного издания нет, поставьте «-» (прочерк) и загрузите сканы печатного издания ниже.'
              }
              {...fields.linkToPubPage}
              error={errors.linkToPubPage?.message}
            />
          )}
          <Input
            className="profile-form__field"
            placeholder="Количество"
            {...fields.quantity}
            error={errors.quantity?.message}
          />
          <Input
            className="profile-form__field"
            placeholder="Дополнительная информация"
            markRequired={
              requiredAchvments.find((value) => value === selectAchievement)
                ? true
                : false
            }
            hint={
              isShowHint
                ? 'Укажите ссылку на публикацию на сайтах: elibrary.ru, scopus.com'
                : ''
            }
            {...fields.comment}
            error={errors.requireDopInfo?.message}
          />
          {achivementId?.value === 43 && (
            <div className="input-container input-container--active profile-form__field profile-form__field--mb-12">
              <div className="input-container__inner">
                <InputMask
                  mask={'999'}
                  className="input-container__input"
                  {...fields.numberInList}
                  markRequired={true}
                  error={errors.numberInList?.message}
                />
                <label className="input-container__placeholder input-container__placeholder--required">
                  {terms.NUMBER_IN_LIST}
                </label>
              </div>
            </div>
          )}
        </div>
        <ProfileSection
          title={
            isShowLinkToPubPage
              ? 'Копии титульного листа, оборота, и содержания журнала'
              : 'Документ'
          }
          markRequired={!isShowLinkToPubPage}
          fileUploadingHint
        >
          <FileUploader
            className="profile-form__file-uploader"
            {...achivementFile}
            files={achivementFile.docs}
          />
          {errors.documents && (
            <div className="profile-form__error">
              {(errors.documents as unknown as FieldError | undefined)?.message}
            </div>
          )}
        </ProfileSection>
        {isShowLinkToPubPage && (
          <ProfileSection
            title="Копия свидетельства"
            markRequired={!isShowLinkToPubPage}
            fileUploadingHint
          >
            <FileUploader
              className="profile-form__file-uploader"
              {...copyCertificateFile}
              files={copyCertificateFile.docs}
            />
            {errors.documents && (
              <div className="profile-form__error">
                {
                  (errors.secondDocuments as unknown as FieldError | undefined)
                    ?.message
                }
              </div>
            )}
          </ProfileSection>
        )}
        <Button
          theme="success"
          loading={loading}
          className="profile-form__button profile-form__button--align-left"
        >
          {terms.SAVE}
        </Button>
      </form>
    </TemplateModal>
  )
}

export default AddIndividualAchivementModal
