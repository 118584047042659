import React from 'react'
import { Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import terms from '@common/terms'
import useAppSelector from '@hooks/useAppSelector'
import useStudentsFilterForm from '@hooks/useStudentsFilterForm'
import { SelectOption } from '@models'
import {
  selectDirectionTrainingsList,
  selectFacultyOptions,
  selectLevelDirectionsTrainingSearchOptions,
  selectModeratorEducationProfile,
  selectPaymentDirectionsTrainingSearchOptions,
  selectTrainingLevels,
} from '@redux/actions/selectors'
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'
import Input from '@ui/Input'
import ReactSelect from '@ui/Select'

const StudentsFilterForm = ({ lastUpdate = '' }) => {
  const { scenario } = useParams<{ scenario: string }>()
  const trajectory = scenario?.split('-')[0] || 1

  const level = scenario?.split('-')[1]
  const {
    fields,
    watchAllFields,
    control,
    onSubmit,
    setValue,
    userIdValue,
    formState: { errors },
  } = useStudentsFilterForm(trajectory)

  const trainingLevels = useAppSelector(selectTrainingLevels) || []
  const levelOptions =
    useAppSelector(selectLevelDirectionsTrainingSearchOptions) || []
  const paymentFormOptions =
    useAppSelector(selectPaymentDirectionsTrainingSearchOptions) || []
  const facultyOptions = useAppSelector(selectFacultyOptions) || []
  const directionTrainingsListOptions =
    useAppSelector(selectDirectionTrainingsList) || []
  const educationProfileOptions =
    useAppSelector(selectModeratorEducationProfile) || []

  return (
    <form onSubmit={onSubmit} className="students-form">
      <div className="students-form__fields">
        <Controller
          name="trainingLevel"
          control={control}
          render={({ field }) => (
            <ReactSelect<SelectOption>
              placeholder={'Уровень образования'}
              className="students-form__field"
              markRequired={true}
              {...(field as unknown)}
              options={[
                trainingLevels?.find(
                  (el) => el.value === Number(level)
                ) as SelectOption,
              ]}
            />
          )}
        />
        <Controller
          name="formEducation"
          control={control}
          render={({ field }) => (
            <ReactSelect<SelectOption>
              placeholder={'Форма образования'}
              className="students-form__field"
              markRequired={true}
              {...(field as unknown)}
              isDisabled={!watchAllFields.trainingLevel}
              options={levelOptions}
            />
          )}
        />
        <Controller
          name="formPayment"
          control={control}
          render={({ field }) => (
            <ReactSelect<SelectOption>
              placeholder={'Основания поступления'}
              className="students-form__field"
              markRequired={true}
              {...(field as unknown)}
              isDisabled={!watchAllFields.formEducation}
              options={paymentFormOptions}
            />
          )}
        />
        <Controller
          name="faculty"
          control={control}
          render={({ field }) => (
            <ReactSelect<SelectOption>
              placeholder={'Институт/Факультет'}
              className="students-form__field"
              markRequired={true}
              {...(field as unknown)}
              isDisabled={!watchAllFields.formPayment}
              options={facultyOptions}
            />
          )}
        />
        <Controller
          name="directionTraining"
          control={control}
          render={({ field }) => (
            <ReactSelect<SelectOption>
              placeholder={'Направление'}
              className="students-form__field"
              markRequired={true}
              {...(field as unknown)}
              isDisabled={!watchAllFields.faculty}
              options={directionTrainingsListOptions}
            />
          )}
        />
        <Controller
          name="educationProfile"
          control={control}
          render={({ field }) => (
            <ReactSelect<SelectOption>
              placeholder={'Профиль'}
              className="students-form__field"
              markRequired={true}
              {...(field as unknown)}
              isDisabled={!watchAllFields.directionTraining}
              options={
                educationProfileOptions.length > 0
                  ? educationProfileOptions
                  : [
                      {
                        label: 'Без профиля',
                        value: 0,
                      },
                    ]
              }
            />
          )}
        />
        <Input
          className="students-form__field"
          {...fields.userId}
          placeholder="Введите ID поступающего"
          disabled={!watchAllFields.educationProfile}
          onFocus={() => !userIdValue?.length && setValue('userId', '22-')}
          error={errors.userId?.message}
        />
        <div className="students-form__field">
          <Button
            theme="success"
            disabled={!watchAllFields.educationProfile}
            type="submit"
          >
            Поиск
          </Button>
        </div>
      </div>
      <Checkbox
        text="Льготная конкурсная группа"
        disabled={watchAllFields?.formPayment?.value !== 4}
        className="profile-form__checkbox "
        {...fields.benefits}
      />
      <div className="students-form__amount">
        {lastUpdate && <span>Последнее обновление: {lastUpdate}</span>}
      </div>
    </form>
  )
}

export default StudentsFilterForm
