import React from 'react'

import SignInForm from '@components/SignInForm'
import TemplateAuthPage from '@pages/TemplateAuthPage'

const ModeratorSignInPage = () => {
  return (
    <TemplateAuthPage moderator>
      <SignInForm title={'Авторизация'} moderator />
    </TemplateAuthPage>
  )
}

export default ModeratorSignInPage
