import React from 'react'

import { columnsPersonalInformation } from '@common/table/moderator'
import TableList from '@components/TableList'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import {
  setModeratorStatementsPage,
  setModeratorStatementsPerPage,
} from '@redux/action-types'
import { selectModeratorPersonalInformationList } from '@redux/actions/selectors'
import Loader from '@ui/Loader'
import Pagination from '@ui/Pagination'
import { formatDateTimeToRuFormat } from '@utils/date'

function personalInformationMapper(arr) {
  if (!arr) return []
  const rows: any[] = []
  arr.forEach((item) => {
    rows.push({
      id: item?.id || '-',
      idWithPrefix: item?.idWithPrefix || '-',
      fullName: item?.fullName || '-',
      email: item?.email || '-',
      createDate: item?.createdAt ? formatDateTimeToRuFormat(item?.createdAt) : '-',
      updateDate: item?.createdAt ? formatDateTimeToRuFormat(item?.updatedAt) : '-',
      nationality: item?.nationality || '-',
      educationLevel: item?.education || '-',
      message: '-',
    })
  })
  return rows
}

const ModeratorPersonalInformationList = () => {
  const dispatch = useAppDispatch()

  const personalInformationList =
    useAppSelector(selectModeratorPersonalInformationList) || []

  if (personalInformationList.length === 0) {
    return <Loader />
  }
  const mappedData = personalInformationMapper(personalInformationList.data)

  if (mappedData.length === 0) {
    dispatch(setModeratorStatementsPage(1))
  }
  return (
    <div className={'moderator-contracts-list'}>
      <TableList
        link={'personal-information'}
        mappedData={mappedData}
        columns={columnsPersonalInformation}
      />
      <Pagination
        lastPage={personalInformationList.lastPage}
        currentPage={personalInformationList.currentPage}
        perPage={personalInformationList.perPage}
        setPage={setModeratorStatementsPage}
        setPerPage={setModeratorStatementsPerPage}
      />
    </div>
  )
}

export default ModeratorPersonalInformationList
