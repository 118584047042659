import React from 'react'

import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import TemplateModal from '@modals/TemplateModal'
import { hideModal } from '@redux/action-types'
import { modalDataSelector } from '@redux/actions/selectors'
import Button from '@ui/Button'

import './WithdrawModal.scss'

const WithdrawModal = () => {
  const dispatch = useAppDispatch()
  const { revoke } = useAppSelector(modalDataSelector)

  const closeModal = () => {
    dispatch(hideModal())
  }

  return (
    <TemplateModal title={'Отзыв заявки'} clearTypeDoc="ACHIVMENT">
      <p className="template-modal__text">
        Вы уверены что хотите отозвать заявление?
      </p>
      <div className="template-modal__content">
        <Button
          onClick={() => {
            revoke().then(() => closeModal())
          }}
          theme="success"
        >
          Да
        </Button>
        <Button onClick={closeModal}>Нет</Button>
      </div>
    </TemplateModal>
  )
}

export default WithdrawModal
