import React from 'react'
import ReactSelect, { components } from 'react-select'

import Button from '../Button'
import TabArrowDown from '@assets/images/TabArrowDown.svg'
import useAppDispatch from '@hooks/useAppDispatch'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

type OptionValue = {
  label: string
  value: number
}

const options: OptionValue[] = [
  {
    label: '10',
    value: 0,
  },
  {
    label: '25',
    value: 1,
  },
  {
    label: '50',
    value: 1,
  },
  {
    label: '75',
    value: 1,
  },
  {
    label: '100',
    value: 1,
  },
]

const Option = (props) => {
  return <components.Option {...props} />
}

const range = (from: number, to: number): number[] => {
  const data: number[] = []

  for (let i = from; i <= to; i++) {
    data.push(i)
  }

  return data
}

const fetchPageNumbers = (lastPage: number, currentPage: number): number[] => {
  const pageNeighbours = 1

  const totalNumbers = pageNeighbours * 2 + 3
  const totalBtn = totalNumbers + 2

  if (lastPage > totalBtn) {
    const startPage = Math.max(2, currentPage - pageNeighbours)
    const endPage = Math.min(lastPage - 1, currentPage + pageNeighbours)
    let pages = range(startPage, endPage)

    const hasLeftSpill = startPage > 2
    const hasRightSpill = lastPage - endPage > 1
    const spillOffset = totalNumbers - (pages.length + 1)

    switch (true) {
      case hasLeftSpill && !hasRightSpill: {
        const extraPages = range(startPage - spillOffset, startPage - 1)
        pages = [-1, ...extraPages, ...pages]
        break
      }

      case !hasLeftSpill && hasRightSpill: {
        const extraPages = range(endPage + 1, endPage + spillOffset)
        pages = [...pages, ...extraPages, -1]
        break
      }

      case hasLeftSpill && hasRightSpill:
      default: {
        pages = [-1, ...pages, -1]
        break
      }
    }

    return [1, ...pages, lastPage]
  }

  return range(1, lastPage)
}

type PaginationProps = {
  lastPage: number
  currentPage: number
  perPage: string
  setPage: ActionCreatorWithPayload<any>
  setPerPage: ActionCreatorWithPayload<any>
}

const Pagination = ({
  lastPage,
  currentPage,
  perPage,
  setPage,
  setPerPage,
}: PaginationProps) => {
  const dispatch = useAppDispatch()
  const pages = fetchPageNumbers(lastPage, currentPage)

  return (
    <div className={'pagination'}>
      <div className={'pagination__btn-count'}>
        <p>Отображать на странице:</p>
        <div className="lang-changer">
          <ReactSelect<OptionValue>
            defaultValue={
              options.find((x) => x.label === perPage) || {
                label: perPage,
                value: -1,
              }
            }
            options={options}
            classNamePrefix="lang-changer"
            onChange={(option) => {
              dispatch(setPerPage(option?.label))
            }}
            components={{ Option }}
          />
        </div>
      </div>
      <div className={'pagination__btn-panel'}>
        <Button
          className={'pagination__btn-panel-first'}
          onClick={() => dispatch(setPage(currentPage - 1))}
          disabled={currentPage === 1}
        >
          <TabArrowDown />
        </Button>
        {pages.map((page, index) => {
          if (page === -1) {
            return (
              <Button key={index} disabled>
                ...
              </Button>
            )
          }
          return (
            <Button
              key={index}
              theme={currentPage === page ? 'success' : undefined}
              onClick={() => dispatch(setPage(page))}
            >
              {page}
            </Button>
          )
        })}
        <Button
          className={'pagination__btn-panel-last'}
          onClick={() => dispatch(setPage(currentPage + 1))}
          disabled={currentPage === lastPage}
        >
          <TabArrowDown />
        </Button>
      </div>
    </div>
  )
}
export default Pagination
