import { useEffect } from 'react'
import { UseFormSetValue } from 'react-hook-form'

import useAppDispatch from './useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useInitializeInputs from '@hooks/useInitializeInputs'
import useInitializeSelect from '@hooks/useInitializeSelect'
import { NewUserForm } from '@models'
import { getAdminRole, getAdminStatus } from '@redux/action-types/admin'
import {
  selectAdminRoleOptions,
  selectAdminStatusOptions,
  selectRoleIdProfile,
} from '@redux/actions/selectors'

type useAddUserAdminModalInitializationProps = {
  setValue: UseFormSetValue<NewUserForm>
  adminUserIdData?: NewUserForm
}

const useAddUserAdminModalInitialization = ({
  setValue,
  adminUserIdData = {} as NewUserForm,
}: useAddUserAdminModalInitializationProps) => {
  const dispatch = useAppDispatch()

  const statusTypesOptions = useAppSelector(selectAdminStatusOptions)
  const roleTypesOptions = useAppSelector(selectAdminRoleOptions)
  const roleId = useAppSelector(selectRoleIdProfile)

  useEffect(() => {
    !roleTypesOptions.length && dispatch(getAdminRole())
    !statusTypesOptions.length && dispatch(getAdminStatus(roleId))
  }, [])

  useInitializeInputs({
    data: adminUserIdData,
    omitKeys: [],
    onInit: (key, value) => {
      setValue(key, value)
    },
  })

  useInitializeSelect({
    data: adminUserIdData,
    options: roleTypesOptions,
    findValue: adminUserIdData?.roleId,
    onInit: (option) => setValue('roleId', option as any),
  })

  useInitializeSelect({
    data: adminUserIdData,
    options: statusTypesOptions,
    findValue: Number(adminUserIdData?.status),
    onInit: (option) => setValue('status', option as any),
  })

  return {
    statusTypesOptions,
    roleTypesOptions,
  }
}

export default useAddUserAdminModalInitialization
