import { useEffect, useState } from 'react'

import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { setToken } from '@redux/action-types'
import { selectToken } from '@redux/actions/selectors'
import { getViewerProfile } from '@redux/reducers'
import { getAuthTokenFromStorage } from '@utils/auth'

const useAuthInitialization = () => {
  const dispatch = useAppDispatch()
  const token = useAppSelector(selectToken)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (!token) {
      const token = getAuthTokenFromStorage()
      if (token) {
        dispatch(setToken(token))
      }
    }
    setLoading(false)
  }, [])
  useEffect(() => {
    if (token) {
      dispatch(getViewerProfile())
    }
  }, [token])
  return { loading, token }
}

export default useAuthInitialization
