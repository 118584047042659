import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { BLANK_STATEMENT } from '@common/constants'
import { mimeTypes } from '@common/manual'
import ModeratorFile from '@components/ModeratorFile'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useDownloadTemplateDocument from '@hooks/useDownloadTemplateDocument'
import { getModeratorBlankStatementsData } from '@redux/action-types'
import {
  selectModeratorStatementsBlankData,
  selectModeratorStatementsPersonalData,
} from '@redux/actions/selectors'
import Checkbox from '@ui/Checkbox'

const ModeratorDownloadsPage = () => {
  const { id } = useParams<{ id?: string }>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (id) {
      dispatch(getModeratorBlankStatementsData(Number(id)))
    }
  }, [id])
  const blankStatements = useAppSelector(selectModeratorStatementsBlankData)
  const statement = useAppSelector(selectModeratorStatementsPersonalData)

  const { downloadBlank } = useDownloadTemplateDocument()

  return (
    <div className={'moderator-documents-page'}>
      {blankStatements?.blank?.map(
        (blankEl, index) =>
          blankEl.dateGeneration && (
            <ModeratorFile
              key={index}
              title={blankEl.blank.blankTranslate[0].name}
              content={blankEl.dateGeneration}
              upload={() => {
                downloadBlank(
                  Number(id),
                  blankEl.blank.code,
                  mimeTypes.docx,
                  'docx',
                  blankEl.blank.blankTranslate[0].name +
                    (blankEl.blankId === BLANK_STATEMENT
                      ? ' №' + blankStatements.number
                      : ''),
                  statement?.userId
                )
              }}
            />
          )
      )}
      {blankStatements?.checkbox?.map((check, index) => (
        <Checkbox
          key={index}
          checked={check.accept}
          text={check.statementCheckbox.statementCheckboxTranslate[0].name}
          disabled={true}
        />
      ))}
    </div>
  )
}

export default ModeratorDownloadsPage
