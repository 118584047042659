import React from 'react'

import { columnsLogList } from '@common/table/moderator'
import LogTable from '@components/LogTable'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import {
  selectAdminLogList,
  setAdminPageLog,
  setAdminPerPageLog,
} from '@redux/actions/selectors'
import Loader from '@ui/Loader'
import Pagination from '@ui/Pagination'
import { formatDateTimeToRuFormat } from '@utils/date'

function statementMapper(arr) {
  if (!arr) return []
  const rows: any[] = []
  arr.forEach((item) => {
    rows.push({
      id: item.id || '-',
      userId: item.enrolleeIdWithPrefix || '-',
      moderatorFullname: item.moderatorFullname || '-',
      fullNameUser: item.enrolleeFullname || '-',
      updateDate: item.updatedAt ? formatDateTimeToRuFormat(item.updatedAt) : '-',
      status: item.data || '-',
    })
  })
  return rows
}

const LogList = () => {
  const dispatch = useAppDispatch()
  const logList = useAppSelector(selectAdminLogList) || []

  if (logList.length === 0) {
    return <Loader />
  }
  const mappedData = statementMapper(logList.data)

  if (mappedData.length === 0) {
    dispatch(setAdminPageLog(1))
  }

  return (
    <div className={'moderator-contracts-list'}>
      <LogTable mappedData={mappedData} columns={columnsLogList} />
      <Pagination
        lastPage={logList.lastPage}
        currentPage={logList.currentPage}
        perPage={logList.perPage}
        setPage={setAdminPageLog}
        setPerPage={setAdminPerPageLog}
      />
    </div>
  )
}

export default LogList
