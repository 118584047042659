import React from 'react'

import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useModeratorStatementIdForm from '@hooks/useModeratorStatementIdForm'
import TemplateModal from '@modals/TemplateModal'
import { hideModal } from '@redux/action-types'
import { modalDataSelector } from '@redux/actions/selectors'
import Button from '@ui/Button'
import './WithdrawModal.scss'

const WithdrawModal = () => {
  const { statementId, content } = useAppSelector(modalDataSelector) as {
    statementId: number
    content: string
  }
  const dispatch = useAppDispatch()

  const closeModal = () => {
    dispatch(hideModal())
  }

  const { deleteStatements } = useModeratorStatementIdForm()
  return (
    <TemplateModal title={'Заявление'} clearTypeDoc="ACHIVMENT">
      <p className="template-modal__text">
        Вы действительно хотите удалить "{content}"?
      </p>
      <div className="template-modal__content">
        <Button
          onClick={(event) => {
            event.stopPropagation()
            deleteStatements(statementId)
            closeModal()
          }}
          theme="success"
        >
          Да
        </Button>
        <Button onClick={closeModal}>Нет</Button>
      </div>
    </TemplateModal>
  )
}

export default WithdrawModal
