import { useState } from 'react'
import { useForm } from 'react-hook-form'

import HttpStatusCode from '@common/httpStatusCodes'
import regexp from '@common/regexp'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { NewUserForm, NewUserFormData } from '@models'
import { createToast, hideModal } from '@redux/action-types'
import { getAdminUserList } from '@redux/action-types/admin'
import {
  selectAdminPageUsersList,
  selectAdminPerPageUsersList,
} from '@redux/actions/selectors'
import api, { ResponseOk, UpdateUser } from '@services/api'

type useAddUserAdminModalFormProps = {
  adminUserIdData?: NewUserForm
  isEditing: boolean
}

const useAddUserAdminModalForm = ({
  adminUserIdData = {} as NewUserForm,
  isEditing,
}: useAddUserAdminModalFormProps) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  const { register, handleSubmit, setError, setValue, getValues, ...rest } = useForm<
    NewUserForm
  >()

  const fields = {
    email: register('email', { required: terms.REQUIRED_FIELD }),
    dateBirthday: register('dateBirthday', { required: terms.REQUIRED_FIELD }),
    roleId: register('roleId', { required: terms.REQUIRED_FIELD }),
    status: register('status', { required: terms.REQUIRED_FIELD }),
    nameRu: register('nameRu'),
    password: register('password', {
      required: { value: !isEditing, message: terms.REQUIRED_FIELD },
      pattern: {
        value: regexp.password,
        message: terms.PASSWORD_MIXED,
      },
      minLength: {
        value: 6,
        message: terms.PASSWORD_MIN_LENGTH,
      },
    }),
    passwordConfirmation: register('passwordConfirmation', {
      required: { value: !isEditing, message: terms.REQUIRED_FIELD },
      validate: (value) => {
        return value === getValues('password') || terms.PASSWORDS_MUST_MATCH
      },
    }),
  }

  const page = useAppSelector(selectAdminPageUsersList)
  const perPage = useAppSelector(selectAdminPerPageUsersList)

  const onSubmit = handleSubmit((data: any) => {
    setLoading(true)

    const normalizedData: NewUserForm = {
      ...data,
      roleId: data.roleId.value,
      status: data.status.value,
    }

    if (isEditing) {
      api.client
        .putAdminChangeUser({ ...normalizedData, id: adminUserIdData.id })
        .then((response) => {
          const { data } = (response as unknown) as ResponseOk
          dispatch(createToast(data.message, 'success'))
          dispatch(getAdminUserList({ page: page, perPage: perPage }))
          dispatch(hideModal())
        })
        .catch((response: Exclude<UpdateUser, ResponseOk>) => {
          const { status } = response

          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))

            Object.keys(data.errors).forEach((key) => {
              setError(key as keyof NewUserForm, {
                type: 'manual',
                message: data.errors[key][0],
              })
            })
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
        .finally(() => setLoading(false))
    } else {
      api.client
        .postAdminNewUser(normalizedData)
        .then((response) => {
          const { data } = (response as unknown) as ResponseOk
          dispatch(createToast(data.message, 'success'))
          dispatch(getAdminUserList({ page: page, perPage: perPage }))
          dispatch(hideModal())
        })
        .catch((response: Exclude<UpdateUser, ResponseOk>) => {
          const { status } = response

          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
            Object.keys(data.errors).forEach((key) => {
              setError(key as keyof NewUserForm, {
                type: 'manual',
                message: data.errors[key][0],
              })
            })
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
        .finally(() => setLoading(false))
    }
  })

  return {
    fields,
    loading,
    setValue,
    onSubmit,
    ...rest,
  }
}

export default useAddUserAdminModalForm
