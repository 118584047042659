import { useEffect } from 'react'

import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import useInitializeInputs from '@hooks/useInitializeInputs'
import useInitializeSelect from '@hooks/useInitializeSelect'
import usePaginatedSubjectsRussia from '@hooks/usePaginatedSubjectsRussia'
import {
  getFarePaymentsOptions,
  getHousingUsePaymentsOptions,
  getMonetaryPaymentsOptions,
  getPaymentForEdusOptions,
  getTargetContract,
} from '@redux/action-types'
import {
  selectFarePaymentsOptions,
  selectHousingUsePaymentsOptions,
  selectMonetaryPaymentsOptions,
  selectPaymentForEdusOptions,
  selectStatementsElement,
  selectTargetContract,
} from '@redux/actions/selectors'

const useTargetAgreementFormInitialization = ({ setValue, fields }) => {
  const dispatch = useAppDispatch()
  const statement = useAppSelector(selectStatementsElement)

  useEffect(() => {
    if (statement) {
      dispatch(getTargetContract(statement.id))
      !housingUsePaymentsOptions.length && dispatch(getHousingUsePaymentsOptions())
      !paymentForEdusOptions.length && dispatch(getPaymentForEdusOptions())
      !farePaymentsOptions.length && dispatch(getFarePaymentsOptions())
      !monetaryPaymentsOptions.length && dispatch(getMonetaryPaymentsOptions())
    }
  }, [statement])

  const subjectsRussiaSelectProps = usePaginatedSubjectsRussia()
  const housingUsePaymentsOptions = useAppSelector(selectHousingUsePaymentsOptions)
  const paymentForEdusOptions = useAppSelector(selectPaymentForEdusOptions)
  const farePaymentsOptions = useAppSelector(selectFarePaymentsOptions)
  const monetaryPaymentsOptions = useAppSelector(selectMonetaryPaymentsOptions)
  const targetContract = useAppSelector(selectTargetContract)

  useEffect(() => {
    if (!targetContract) {
      Object.keys(fields)?.map((field) => {
        setValue(field, null)
      })

      setValue('noTarget', true)
    } else {
      setValue('noTarget', false)
    }
  }, [targetContract])

  useInitializeInputs({
    data: targetContract,
    omitKeys: [],
    onInit: (key, value) => {
      console.log(key)
      if (key === 'customerName' && value !== null) {
        setValue('customerName', value.toString())
      }
      if (key === 'idNumberWorkInRussia' && value !== null) {
        setValue('idNumberWorkInRussia', value.toString())
      }
      if (key === 'userDocId' && value !== null) {
        setValue('userDocId', value)
      }
    },
  })

  return {
    targetContract,
    subjectsRussiaSelectProps,
    housingUsePaymentsOptions,
    paymentForEdusOptions,
    farePaymentsOptions,
    monetaryPaymentsOptions,
  }
}

export default useTargetAgreementFormInitialization
