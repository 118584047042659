import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'

import { ROLE_ADMIN, ROLE_MODERATOR, ROLE_SUPPORT } from '@common/constants'
import {
  BlankStatement,
  EducationProfileModerator,
  FilterContract,
  FilterPersonalInformation,
  FilterStatement,
  LevelEducation,
  ModeratorAchievementsAndPrivilegesData,
  ModeratorAddressData,
  ModeratorContact,
  ModeratorDirectionsTrainings,
  ModeratorDocumentsData,
  ModeratorEducationData,
  ModeratorModeratorsList,
  ModeratorPassportData,
  ModeratorPersonalInformation,
  ModeratorStatement,
  ModeratorStatementPersonalDate,
  ModeratorStatementsStatus,
  ModeratorStatusContract,
  ModeratorTargetContractDataResponse,
  PersonalDataResponse,
  StatusStatement,
  StudentsFilterFormData,
  ViewerScenarioStatements,
  ViewerTabEgeData,
  ViewerTrajectoryListStatements,
} from '@models'
import {
  ResponseNotAcceptable,
  ResponseOk,
  ResponseUnprocessableEntity,
} from '@services/api/types'

export default {
  /** @method GET */
  getModeratorStatementsList: (roleId: number, data?: FilterStatement) => {
    const params = new URLSearchParams()
    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key]) {
          if (Array.isArray(snakeData[key])) {
            snakeData[key].forEach((id) => {
              params.append(key + '[]', id)
            })
          } else {
            params.append(key, snakeData[key])
          }
        }
      })
    }
    if (roleId === ROLE_SUPPORT) {
      return axios.get<ModeratorStatement>(`/support/statements`, { params: params })
    }
    if (roleId === ROLE_ADMIN) {
      return axios.get<ModeratorStatement>(`/admin/statements`, { params: params })
    }
    if (roleId === ROLE_MODERATOR) {
      return axios.get<ModeratorStatement>(`/moderator/statements`, {
        params: params,
      })
    }
    return { data: [] }
  },

  /** @method GET */
  getModeratorPersonalInformationList: (data?: FilterPersonalInformation) => {
    const params = new URLSearchParams()
    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key]) {
          if (Array.isArray(snakeData[key])) {
            snakeData[key].forEach((id) => {
              params.append(key + '[]', id)
            })
          } else {
            params.append(key, snakeData[key])
          }
        }
      })
    }
    return axios.get<ModeratorPersonalInformation>(
      `/support/users-without-statements`,
      { params: params }
    )
  },

  /** @method PUT */
  putApproveContract: (params) => {
    return axios.put(`/admin/statements/directionTrainings/history`, params)
  },

  /** @method GET */
  getModeratorStatementsPersonalData: (id: string | number) => {
    return axios.get<ModeratorStatementPersonalDate>(
      `/admin/statements/personalData?statement=${id}`
    )
  },

  /** @method GET */
  getModeratorUserPersonalData: (id: string | number) => {
    return axios.get<PersonalDataResponse>(
      `/support/statements/personal-data?user_id=${id}`
    )
  },

  /** @method GET */
  getModeratorStatementsAdditionalFiles: (id?: string | number) => {
    return axios.get<any>(`/admin/statements/forms?user_id=${id}`)
  },

  /** @method GET */
  getModeratorStatementsAdditionalFilesDownload: (id: string | number) => {
    return axios.get<any>(`/admin/statements/forms/docs?doc_id=${id}`)
  },

  /** @method GET */
  getModeratorStatementsTargetContractData: (id: string | number) =>
    axios.get<ModeratorTargetContractDataResponse>(
      `/admin/statements/target-contract?statement_id=${id}`
    ),

  /** @method GET */
  getModeratorContractInfo: (id: string | number) =>
    axios.get<any>(`/admin/statements/contract?contract_id=${id}`),

  /** @method GET */
  getModeratorStatementsPassportData: (id: string | number) => {
    return axios.get<ModeratorPassportData[]>(
      `/admin/statements/passportData?statement=${id}`
    )
  },

  /** @method GET */
  getModeratorUserPassportData: (id: string | number) => {
    return axios.get<ModeratorPassportData[]>(
      `/admin/statements/passportData?user_id=${id}`
    )
  },

  /** @method GET */
  getModeratorStatementsAddressData: (id: string | number) => {
    return axios.get<ModeratorAddressData>(
      `/admin/statements/address?statement=${id}`
    )
  },

  /** @method GET */
  getModeratorUserAddressData: (id: string | number) => {
    return axios.get<ModeratorAddressData>(`/admin/statements/address?user_id=${id}`)
  },

  /** @method GET */
  getModeratorStatementsEducationData: (id: string | number) => {
    return axios.get<ModeratorEducationData[]>(
      `/admin/statements/education?statement=${id}`
    )
  },

  /** @method GET */
  getModeratorUserEducationData: (id: string | number) => {
    return axios.get<ModeratorEducationData[]>(
      `/admin/statements/education?user_id=${id}`
    )
  },

  /** @method GET */
  getModeratorStatementsDocumentsData: (id: string | number) => {
    return axios.get<ModeratorDocumentsData>(
      `/admin/statements/copies-documents?statement=${id}`
    )
  },

  /** @method GET */
  getModeratorUserDocumentsData: (id: string | number) => {
    return axios.get<ModeratorDocumentsData>(
      `/admin/statements/copies-documents?user_id=${id}`
    )
  },

  /** @method GET */
  getModeratorBlankStatementsData: (id: string | number) => {
    return axios.get<BlankStatement>(`/admin/statements/${id}/download`)
  },

  /** @method GET */
  getModeratorStatementsAchievementsAndPrivilegesData: (id: string | number) => {
    return axios.get<ModeratorAchievementsAndPrivilegesData>(
      `/admin/statements/achievements-and-privileges?statement_id=${id}`
    )
  },

  /** @method GET */
  getModeratorExamResultData: (id: string | number) => {
    return axios.get<ViewerTabEgeData>(`/admin/statements/ege?statement_id=${id}`)
  },
  /** @method GET */
  getModeratorCheckExamResult: (id: string | number) => {
    return axios.get<any>(`/admin/statements/ege/check?statement_id=${id}`)
  },

  /** @method GET */
  getModeratorStatementsIdStatus: (id: string | number) => {
    return axios.get<ModeratorStatementsStatus>(
      `/admin/statements/status?statement=${id}`
    )
  },

  /** @method GET */
  getModeratorStatementsIdComment: (id: string | number) => {
    return axios.get<{ text: string }>(
      `/admin/statements/comment?statement_id=${id}`
    )
  },

  /** @method GET */
  getModeratorContractsList: (data?: FilterContract) => {
    const params = new URLSearchParams()
    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key]) {
          if (Array.isArray(snakeData[key])) {
            snakeData[key].forEach((id) => {
              params.append(key + '[]', id)
            })
          } else {
            params.append(key, snakeData[key])
          }
        }
      })
    }
    return axios.get<ModeratorContact>(`/moderator/contracts`, { params: params })
  },

  /** @method GET */
  getModeratorScenario: () =>
    axios.get<ViewerScenarioStatements[]>('/admin/statements/scenario'),

  /** @method GET */
  getModeratorEducationProfile: (data?: StudentsFilterFormData) => {
    const params = new URLSearchParams()
    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key]) {
          params.append(key, snakeData[key].value || snakeData[key])
        }
      })
    }
    return axios.get<EducationProfileModerator[]>(
      '/admin/statements/educationProfile',
      { params: params }
    )
  },

  /** @method GET */
  getModeratorModeratorsList: () =>
    axios.get<ModeratorModeratorsList[]>('/admin/statements/moderators'),

  /** @method GET */
  getModeratorStatusesList: () => axios.get<any[]>('/moderator/contracts/statuses'),

  /** @method GET */
  getModeratorTrajectory: () =>
    axios.get<ViewerTrajectoryListStatements[]>('/admin/statements/trajectory'),

  /** @method GET */
  getModeratorLevelEducation: () => axios.get<LevelEducation[]>('/levelEducation'),

  /** @method GET */
  getStatusStatement: (type?: string) => {
    const params = new URLSearchParams()
    if (type) {
      params.append('type', type)
    }
    return axios.get<StatusStatement[]>(`/admin/statements/statusStatement`, {
      params: params,
    })
  },

  /** @method GET */
  getModeratorDirectionsTrainings: (statement_id: number) =>
    axios.get<ModeratorDirectionsTrainings>(
      `/admin/statements/directionTrainings/history?statement_id=${statement_id}`
    ),

  /** @method GET */
  getModeratorStatusContract: () =>
    axios.get<ModeratorStatusContract[]>('/moderator/contracts/statuses'),

  /** @method PUT */
  putModeratorStatusStatement: (
    roleId,
    data: { id: number; code: number },
    responsePersonId?: number
  ) => {
    if (responsePersonId) {
      return axios.put<{ id: number; code: number }, UpdateStatus>(
        '/admin/statements/status',
        { ...data, response_person_id: responsePersonId }
      )
    }

    return axios.put<{ id: number; code: number }, UpdateStatus>(
      '/admin/statements/status',
      data
    )
  },

  /** @method POST */
  postModeratorStatementIdComment: (data: {
    statementId: string
    comment: string
  }) =>
    axios.post<{ statementId: string; comment: string }, UpdateComment>(
      `/admin/statements/comment`,
      snakecaseKeys(data)
    ),

  /** @method POST */
  postNewModeratorStatements: (data: { statementId: string; moderatorId: string }) =>
    axios.post<{ statementId: string; moderatorId: string }, UpdateModerator>(
      `/admin/addStatementModerator`,
      snakecaseKeys(data)
    ),

  /** @method PUT */
  putModeratorStatusContract: (roleId, data: { id: number; code: number }) =>
    axios.put<{ id: number; code: number }, UpdateStatus>(
      '/moderator/contracts/status',
      data
    ),

  /** @method POST */
  postNewModeratorContract: (data: { statementId: string; moderatorId: string }) =>
    axios.post<{ statementId: string; moderatorId: string }, UpdateModerator>(
      `/admin/addContractModerator`,
      snakecaseKeys(data)
    ),

  /** @method GET */
  getSendTo1C: (id: number) => {
    return axios.get<any>(`/moderator/contracts/send-to-1c?contract_id=${id}`)
  },

  putBackToWork(payload) {
    return axios.put('moderator/contracts/status', payload)
  },
}

export type UpdateStatus =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<{ id: number; code: number }>

export type UpdateModerator =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<{ id: number; code: number }>

export type UpdateComment =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<{ statementId: string; comment: string }>

export type UpdateGpa =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<{
      statementId: string
      competitiveGroupId: number
    }>

export class getSendTo1C {}
