import {
  ViewerScenarioStatements,
  ViewerTrajectoryListStatements,
  ViewerDirectionsListStatements,
  ViewerFormDirectionsTrainingSearch,
  ViewerLevelDirectionsTrainingSearch,
  ViewerPaymentDirectionsTrainingSearch,
  AchivementType,
  AchivementDocType,
  DisciplineResponse,
  ViewerElementStatements,
  BlankStatement,
  DisciplineEge,
  ViewerTabEgeData,
  FacultyType,
  Checkbox,
  TargetResponseOption,
  ViewerTargetContractRespForm,
  AchivementResponseFrom,
  ViewerTrainingLevelsResponse,
  MoodleCourses,
  listGroupResponse,
  incomingListResponse,
  DisciplineCentralExam,
  placeEmploymentsListResponse,
  SchoolSubjects,
  UserSchoolSubjects,
  UserEstimate,
} from '@models'
import {
  getAchivementDocTypes,
  getAchivementTypes,
  getBlankStatement,
  getDirectionsList,
  getDirectionsListStatements,
  getDisciplineEgeOptions,
  getFaculty,
  getFormDirectionsTrainingSearch,
  getLevelDirectionsTrainingSearch,
  getPaymentDirectionsTrainingSearch,
  getStatementDisciplines,
  getStatementsElement,
  getStatementsList,
  getTabEge,
  getTrainingLevelList,
  getTrajectoryList,
  getAchivements,
  getOlympiadTypes,
  getOlympiadDocTypes,
  getOlympiads,
  getHousingUsePaymentsOptions,
  getPaymentForEdusOptions,
  getFarePaymentsOptions,
  getMonetaryPaymentsOptions,
  getTargetContract,
  getDirectionTrainingsList,
  getStudentsList,
  getDirectionsListOptions,
  getContractsList,
  getContract,
  getTrainingLevels,
  getCompetitiveGroups,
  getMoodleCourses,
  getListGroups,
  getIncomingList,
  getPlaceEmploymentsList,
  getCentralExamOptions,
  getEntranceExams,
  getConclusionBaseDepartment,
  getDisciplineTrials,
  getSchoolSubjects,
  getUserSchoolSubjects,
  getEstimates,
  getConsentToEnrollmentHistory,
  getTabEgeV2,
} from '@redux/action-types'
import { StateStatus } from '@redux/types'
import { ActionReducerMapBuilder, AsyncThunk, createSlice } from '@reduxjs/toolkit'

type StatementsStateEntity<D> = {
  data: D | null
  status: StateStatus
  error: unknown
}

type StatementsInitialState = {
  entities: {
    achivementDocTypes: StatementsStateEntity<AchivementDocType[]>
    addFiles: StatementsStateEntity<any>
    contractsList: StatementsStateEntity<any>
    contract: StatementsStateEntity<any>
    achivementTypes: StatementsStateEntity<AchivementType[]>
    olympiadTypes: StatementsStateEntity<any>
    olympiadDocTypes: StatementsStateEntity<any>
    disciplines: StatementsStateEntity<DisciplineResponse[]>
    statementsElement: StatementsStateEntity<ViewerElementStatements>
    statementsList: StatementsStateEntity<ViewerElementStatements[]>
    trajectoryList: StatementsStateEntity<ViewerTrajectoryListStatements[]>
    levelList: StatementsStateEntity<ViewerScenarioStatements[]>
    directionsList: StatementsStateEntity<ViewerDirectionsListStatements[]>
    directionListOptions: StatementsStateEntity<any>
    directionsListStatements: StatementsStateEntity<ViewerDirectionsListStatements[]>
    formDirectionsTrainingSearch: StatementsStateEntity<
      ViewerFormDirectionsTrainingSearch[]
    >
    levelDirectionsTrainingSearch: StatementsStateEntity<
      ViewerLevelDirectionsTrainingSearch[]
    >
    blankStatement: StatementsStateEntity<BlankStatement>
    tabEgeData: StatementsStateEntity<ViewerTabEgeData>
    tabEgeDataV2: StatementsStateEntity<ViewerTabEgeData>
    conclusionBaseDepartment: StatementsStateEntity<any>
    targetContract: StatementsStateEntity<ViewerTargetContractRespForm>
    disciplineEgeOptions: StatementsStateEntity<DisciplineEge[]>
    disciplineCentralExamOptions: StatementsStateEntity<DisciplineCentralExam[]>
    paymentDirectionsTrainingSearch: StatementsStateEntity<
      ViewerPaymentDirectionsTrainingSearch[]
    >
    faculty: StatementsStateEntity<FacultyType[]>
    directionTrainingsList: StatementsStateEntity<any>
    achivements: StatementsStateEntity<AchivementResponseFrom>
    olympiads: StatementsStateEntity<any>
    housingUsePaymentsOptions: StatementsStateEntity<TargetResponseOption>
    paymentForEdusOptions: StatementsStateEntity<TargetResponseOption>
    farePaymentsOptions: StatementsStateEntity<TargetResponseOption>
    monetaryPaymentsOptions: StatementsStateEntity<TargetResponseOption>
    studentsList: StatementsStateEntity<any>
    competitiveGroups: StatementsStateEntity<any>
    trainingLevels: StatementsStateEntity<ViewerTrainingLevelsResponse>
    entranceExams: StatementsStateEntity<any>
    moodleCourses: StatementsStateEntity<MoodleCourses>
    disciplineTrials: StatementsStateEntity<any>
    listGroups: StatementsStateEntity<listGroupResponse>
    incomingList: StatementsStateEntity<incomingListResponse>
    placeEmploymentsList: StatementsStateEntity<placeEmploymentsListResponse>
    schoolSubjects: StatementsStateEntity<SchoolSubjects>
    userSchoolSubjects: StatementsStateEntity<UserSchoolSubjects>
    userEstimates: StatementsStateEntity<UserEstimate>
    userConsentToEnrollmentHistory: StatementsStateEntity<any[]>
  }
  canSend: boolean
  checkboxStatement: Checkbox[]
  signedContractsList: object
  signedPaidReceiptList: object
  directionTrainingFilter: string
  tabs: {
    trajectoryValue: {
      id: number
      value: string
    }
    levelValue: {
      id: number
      value: string
      paymentType: number
      scenarioId: number
    }
    emptyValue: {
      id: number
      value: string
    }
  }
}

const initialStatementsEntityState = {
  data: null,
  status: 'idle',
  error: null,
} as const

const initialState: StatementsInitialState = {
  entities: {
    achivementDocTypes: initialStatementsEntityState,
    addFiles: initialStatementsEntityState,
    contractsList: initialStatementsEntityState,
    contract: initialStatementsEntityState,
    achivementTypes: initialStatementsEntityState,
    olympiadTypes: initialStatementsEntityState,
    olympiadDocTypes: initialStatementsEntityState,
    disciplines: initialStatementsEntityState,
    statementsElement: initialStatementsEntityState,
    statementsList: initialStatementsEntityState,
    trajectoryList: initialStatementsEntityState,
    levelList: initialStatementsEntityState,
    blankStatement: initialStatementsEntityState,
    tabEgeData: initialStatementsEntityState,
    tabEgeDataV2: initialStatementsEntityState,
    conclusionBaseDepartment: initialStatementsEntityState,
    targetContract: initialStatementsEntityState,
    disciplineEgeOptions: initialStatementsEntityState,
    disciplineCentralExamOptions: initialStatementsEntityState,
    directionsList: initialStatementsEntityState,
    directionListOptions: initialStatementsEntityState,
    directionsListStatements: initialStatementsEntityState,
    formDirectionsTrainingSearch: initialStatementsEntityState,
    levelDirectionsTrainingSearch: initialStatementsEntityState,
    paymentDirectionsTrainingSearch: initialStatementsEntityState,
    faculty: initialStatementsEntityState,
    directionTrainingsList: initialStatementsEntityState,
    achivements: initialStatementsEntityState,
    olympiads: initialStatementsEntityState,
    housingUsePaymentsOptions: initialStatementsEntityState,
    paymentForEdusOptions: initialStatementsEntityState,
    farePaymentsOptions: initialStatementsEntityState,
    monetaryPaymentsOptions: initialStatementsEntityState,
    studentsList: initialStatementsEntityState,
    competitiveGroups: initialStatementsEntityState,
    trainingLevels: initialStatementsEntityState,
    entranceExams: initialStatementsEntityState,
    moodleCourses: initialStatementsEntityState,
    disciplineTrials: initialStatementsEntityState,
    listGroups: initialStatementsEntityState,
    incomingList: initialStatementsEntityState,
    placeEmploymentsList: initialStatementsEntityState,
    schoolSubjects: initialStatementsEntityState,
    userSchoolSubjects: initialStatementsEntityState,
    userEstimates: initialStatementsEntityState,
    userConsentToEnrollmentHistory: initialStatementsEntityState,
  },
  canSend: false,
  signedContractsList: {},
  signedPaidReceiptList: {},
  checkboxStatement: [],
  directionTrainingFilter: '',
  tabs: {
    trajectoryValue: {
      id: 0,
      value: '',
    },
    levelValue: {
      id: 0,
      value: '',
      scenarioId: 0,
      paymentType: 0,
    },
    emptyValue: {
      id: 0,
      value: '',
    },
  },
}

export const statementsSlice = createSlice({
  name: 'statements',
  initialState,
  reducers: {
    setSignedContractList(state, action) {
      state.signedContractsList = {
        ...state.signedContractsList,
        [action.payload.id]: action.payload.value,
      }
    },
    setSignedPaidReceiptList(state, action) {
      state.signedPaidReceiptList = {
        ...state.signedPaidReceiptList,
        [action.payload.id]: action.payload.value,
      }
    },
    deleteSignedPaidReceiptList(state, action) {
      const deleted = state.signedPaidReceiptList
      delete deleted[action.payload.id]
      state.signedPaidReceiptList = { ...deleted }
    },
    deleteSignedContractList(state, action) {
      const deleted = state.signedContractsList
      delete deleted[action.payload.id]
      state.signedContractsList = { ...deleted }
    },
    cleanStatementsElement(state) {
      state.entities.statementsElement = initialStatementsEntityState
    },
    cleanContract(state) {
      state.entities.contract = initialStatementsEntityState
    },
    setTrajectoryValue(state, action) {
      state.tabs.trajectoryValue = {
        id: action.payload.id,
        value: action.payload.value,
      }
    },
    setLevelValue(state, action) {
      state.tabs.levelValue = {
        id: action.payload.id,
        value: action.payload.value,
        scenarioId: action.payload.scenarioId,
        paymentType: action.payload.paymentType,
      }
    },
    setDirectionsListStatements(state, action) {
      state.entities.directionsListStatements.data = action.payload
    },
    setCanSend(state, action) {
      state.canSend = action.payload
    },
    setDirectionTrainingSort(state, action) {
      state.directionTrainingFilter = action.payload
    },
  },
  extraReducers(builder) {
    setupExtraReducer(builder, getContract, 'contract')
    setupExtraReducer(builder, getAchivements, 'achivements')
    setupExtraReducer(
      builder,
      getConclusionBaseDepartment,
      'conclusionBaseDepartment'
    )
    setupExtraReducer(builder, getOlympiads, 'olympiads')
    setupExtraReducer(builder, getStatementsList, 'statementsList')
    setupExtraReducer(builder, getContractsList, 'contractsList')
    setupExtraReducer(builder, getDirectionsListOptions, 'directionListOptions')
    setupExtraReducer(builder, getStatementsElement, 'statementsElement')
    setupExtraReducer(builder, getTrajectoryList, 'trajectoryList')
    setupExtraReducer(builder, getTrainingLevelList, 'levelList')
    setupExtraReducer(builder, getDirectionsList, 'directionsList')
    setupExtraReducer(builder, getAchivementDocTypes, 'achivementDocTypes')
    setupExtraReducer(builder, getAchivementTypes, 'achivementTypes')
    setupExtraReducer(builder, getOlympiadTypes, 'olympiadTypes')
    setupExtraReducer(builder, getOlympiadDocTypes, 'olympiadDocTypes')
    setupExtraReducer(builder, getStatementDisciplines, 'disciplines')
    setupExtraReducer(builder, getBlankStatement, 'blankStatement')
    setupExtraReducer(builder, getDisciplineEgeOptions, 'disciplineEgeOptions')
    setupExtraReducer(builder, getCentralExamOptions, 'disciplineCentralExamOptions')
    setupExtraReducer(builder, getTabEge, 'tabEgeData')
    setupExtraReducer(builder, getTabEgeV2, 'tabEgeDataV2')
    setupExtraReducer(builder, getTargetContract, 'targetContract')
    setupExtraReducer(builder, getFaculty, 'faculty')
    setupExtraReducer(builder, getDirectionTrainingsList, 'directionTrainingsList')
    setupExtraReducer(
      builder,
      getHousingUsePaymentsOptions,
      'housingUsePaymentsOptions'
    )
    setupExtraReducer(builder, getPaymentForEdusOptions, 'paymentForEdusOptions')
    setupExtraReducer(builder, getFarePaymentsOptions, 'farePaymentsOptions')
    setupExtraReducer(builder, getMonetaryPaymentsOptions, 'monetaryPaymentsOptions')
    setupExtraReducer(
      builder,
      getDirectionsListStatements,
      'directionsListStatements'
    )
    setupExtraReducer(
      builder,
      getFormDirectionsTrainingSearch,
      'formDirectionsTrainingSearch'
    )
    setupExtraReducer(
      builder,
      getLevelDirectionsTrainingSearch,
      'levelDirectionsTrainingSearch'
    )
    setupExtraReducer(
      builder,
      getPaymentDirectionsTrainingSearch,
      'paymentDirectionsTrainingSearch'
    )
    setupExtraReducer(builder, getStudentsList, 'studentsList')
    setupExtraReducer(builder, getCompetitiveGroups, 'competitiveGroups')
    setupExtraReducer(builder, getTrainingLevels, 'trainingLevels')
    setupExtraReducer(builder, getEntranceExams, 'entranceExams')
    setupExtraReducer(builder, getMoodleCourses, 'moodleCourses')
    setupExtraReducer(builder, getDisciplineTrials, 'disciplineTrials')
    setupExtraReducer(builder, getListGroups, 'listGroups')
    setupExtraReducer(builder, getIncomingList, 'incomingList')
    setupExtraReducer(builder, getPlaceEmploymentsList, 'placeEmploymentsList')
    setupExtraReducer(builder, getSchoolSubjects, 'schoolSubjects')
    setupExtraReducer(builder, getUserSchoolSubjects, 'userSchoolSubjects')
    setupExtraReducer(builder, getEstimates, 'userEstimates')
    setupExtraReducer(
      builder,
      getConsentToEnrollmentHistory,
      'userConsentToEnrollmentHistory'
    )
  },
})

//TODO: извлечь функцию
function setupExtraReducer(
  builder: ActionReducerMapBuilder<StatementsInitialState>,
  asyncThunk: AsyncThunk<any, any, {}>,
  key: keyof StatementsInitialState['entities']
) {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state.entities[key].status = 'loading'
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      state.entities[key].status = 'succeeded'
      if (
        key === 'blankStatement' &&
        JSON.stringify(state.checkboxStatement) !==
          JSON.stringify(action.payload.checkbox)
      ) {
        state.checkboxStatement = action.payload.checkbox
      }
      state.entities[key].data = action.payload
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      state.entities[key].status = 'failed'
      state.entities[key].error = action.payload
      state.entities[key].data = null
    })
}

export default statementsSlice.reducer
