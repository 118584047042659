import React from 'react'

import terms from '@common/terms'
import LevelStatementsList from '@components/LevelStatementsList'
import useLevelPage from '@hooks/useLevelPage'
import Button from '@ui/Button'

const LevelPage = (scenarioKeys) => {
  const { onSubmit } = useLevelPage(scenarioKeys)

  return (
    <main className={'level-page'}>
      <LevelStatementsList />
      <Button onClick={() => onSubmit()} theme={'success'}>
        {terms.SAVE_AND_NEXT}
      </Button>
    </main>
  )
}

export default LevelPage
