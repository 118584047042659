import React from 'react'

import FileIcon from '@assets/images/File.svg'
import { formatDateTimeToRuFormat } from '@utils/date'

type ModeratorFileProps = {
  title: string
  content?: string
  upload: () => void
}

const ModeratorFile = ({ title, content, upload }: ModeratorFileProps) => {
  return (
    <div className="moderator-file" onClick={upload}>
      <FileIcon width="80" height="80" />
      <div>
        <h5>{title}</h5>
        {content && <p>{formatDateTimeToRuFormat(content)}</p>}
      </div>
    </div>
  )
}

export default ModeratorFile
