import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { StudentsFilterForm, StudentsFilterFormData } from '@models'
import {
  getCompetitiveGroups,
  getDirectionTrainingsList,
  getFaculty,
  getFormDirectionsTrainingSearch,
  getModeratorEducationProfile,
  getPaymentDirectionsTrainingSearch,
  getStudentsList,
} from '@redux/action-types'
import {
  selectAdminPageDirectoriesList,
  selectAdminPerPageDirectoriesList,
  selectSearchStudents,
} from '@redux/actions/selectors'

const useStudentsFilterForm = (trajectory) => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()

  const { register, watch, handleSubmit, control, setValue, ...rest } = useForm<
    StudentsFilterFormData
  >({ mode: 'all' })

  const watchAllFields = watch()
  const userIdValue = watch('userId')

  const fields = {
    scenario: register('trainingLevel', {
      onChange: ({ target: { value } }) => {
        setValue('trainingLevel', value)
        setValue('trajectory', trajectory)
        dispatch(
          getFormDirectionsTrainingSearch({
            trajectory: trajectory,
            trainingLevel: value,
          })
        )
        setValue('formEducation', null)
        setValue('formPayment', null)
        setValue('faculty', null)
        setValue('educationProfile', null)
        setValue('directionTraining', null)
      },
    }),
    formEducation: register('formEducation', {
      onChange: ({ target: { value } }) => {
        setValue('formEducation', value)
        dispatch(
          getPaymentDirectionsTrainingSearch({
            trajectory: trajectory,
            trainingLevel: watchAllFields.trainingLevel,
            formEducation: value,
          })
        )
        setValue('formPayment', null)
        setValue('faculty', null)
        setValue('educationProfile', null)
        setValue('directionTraining', null)
      },
    }),
    formPayment: register('formPayment', {
      onChange: ({ target: { value } }) => {
        setValue('formPayment', value)
        dispatch(
          getFaculty({
            trajectory: trajectory,
            trainingLevel: watchAllFields.trainingLevel,
            formEducation: watchAllFields.formEducation,
            formPayment: value,
          })
        )
        setValue('faculty', null)
        setValue('directionTraining', null)
        setValue('educationProfile', null)
      },
    }),
    faculty: register('faculty', {
      onChange: ({ target: { value } }) => {
        setValue('faculty', value)
        dispatch(
          getDirectionTrainingsList({
            trajectory: trajectory,
            trainingLevel: watchAllFields.trainingLevel,
            formEducation: watchAllFields.formEducation,
            formPayment: watchAllFields.formPayment,
            faculty: value,
          })
        )
        setValue('directionTraining', null)
        setValue('educationProfile', null)
      },
    }),
    directionTraining: register('directionTraining', {
      onChange: ({ target: { value } }) => {
        setValue('directionTraining', value)
        dispatch(
          getModeratorEducationProfile({
            trajectory: trajectory,
            trainingLevel: watchAllFields.trainingLevel,
            formEducation: watchAllFields.formEducation,
            formPayment: watchAllFields.formPayment,
            faculty: watchAllFields.faculty,
            directionTraining: value,
          })
        )
        setValue('educationProfile', null)
      },
    }),
    educationProfile: register('educationProfile', {
      onChange: ({ target: { value } }) => {
        setValue('educationProfile', value)
        onSubmit()
      },
    }),
    benefits: register('benefits', {
      onChange: ({ target: { checked } }) => {
        setValue('benefits', checked ? 1 : 0)
        watchAllFields.educationProfile && onSubmit()
      },
    }),
    userId: register('userId'),
  }
  const search = useAppSelector(selectSearchStudents)

  useEffect(() => {
    if (search !== null) {
      setValue('search', search)
      onSubmit()
    }
  }, [search])

  const page = useAppSelector(selectAdminPageDirectoriesList)
  const perPage = useAppSelector(selectAdminPerPageDirectoriesList)

  useEffect(() => {
    setValue('page', page)
    setValue('perPage', perPage)
    onSubmit()
  }, [page, perPage])

  const onSubmit = handleSubmit((data: any) => {
    const normalizedData: StudentsFilterFormData = {
      ...data,
      trainingLevel: data.trainingLevel?.value,
      formEducation: data.formEducation?.value,
      formPayment: data.formPayment?.value,
      faculty: data.faculty?.value,
      educationProfile: data.educationProfile?.value,
      directionTraining: data.directionTraining?.value,
      benefits: data.benefits || 0,
      competitive: pathname.includes('competitive'),
    }
    if (!normalizedData.directionTraining) {
      return
    }
    dispatch(getCompetitiveGroups(normalizedData))
    dispatch(getStudentsList(normalizedData))
  })

  return {
    fields,
    watchAllFields: watchAllFields as any,
    control,
    setValue,
    userIdValue,
    onSubmit,
    ...rest,
  }
}

export default useStudentsFilterForm
