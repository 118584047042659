import React from 'react'

import classnames from 'classnames'

import QuestionMark from '@assets/images/questionMark.svg'

type ProfileSectionProps = React.PropsWithChildren<{
  title?: string
  hint?: string
  markRequired?: boolean
  fileUploadingHint?: boolean
  isHintTooltip?: boolean
}>

const ProfileSection = ({
  title,
  children,
  markRequired = false,
  fileUploadingHint,
  hint = '',
  isHintTooltip = false,
}: ProfileSectionProps) => {
  return (
    <section className="profile-section">
      {title && isHintTooltip && (
        <div className="profile-section__heading">
          <h3
            className={classnames('profile-section__title', {
              'profile-section__title--required': markRequired,
            })}
          >
            {title}
          </h3>
          {fileUploadingHint && isHintTooltip && (
            <div className="profile-section__svg" title-hint={hint}>
              <QuestionMark width="24" height="24" fill="#3a79d7" />
            </div>
          )}
        </div>
      )}
      {title && !isHintTooltip && (
        <div className="profile-section__heading profile-section__heading-non-tooltip">
          <h3
            className={classnames('profile-section__title', {
              'profile-section__title--required': markRequired,
            })}
          >
            {title}
          </h3>
          <p className="profile-section__title, profile-section__hint">{hint}</p>
        </div>
      )}
      <div className="profile-section__body">{children}</div>
    </section>
  )
}

export default ProfileSection
