import React from 'react'

import AccordionTab from '@components/AccordionTab'
import ModeratorStatementsFilter from '@components/ModeratorStatementsFilter'
import ModeratorStatementsList from '@components/ModeratorStatementsList'

const ModeratorStatementsPage = () => {
  return (
    <main className="moderator-statements-page">
      <h1 className="moderator-statements-page__title">Заявления</h1>
      <AccordionTab title={'Фильтры'}>
        <ModeratorStatementsFilter />
      </AccordionTab>
      <ModeratorStatementsList />
    </main>
  )
}

export default ModeratorStatementsPage
