import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import { ViewerDirectionsTrainingSearch } from '@models'
import { createToast, getDirectionsList } from '@redux/action-types'
import api, {
  ResponseOk,
  UpdateDirectionTrainingAssignResponseNew,
} from '@services/api'

const useDirectionTrainingList = () => {
  const { statementId } = useParams()
  const navigate = useNavigate()
  const [choseArr, setChoseArr] = useState<number[]>([])
  const [choseTrials, setTrials] = useState<any>([])

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(
      getDirectionsList({
        statementId: String(statementId),
      } as ViewerDirectionsTrainingSearch)
    )
  }, [])

  const selectDirection = (id: number) => {
    let index = choseArr.indexOf(id)
    if (index > -1) {
      setChoseArr([...choseArr.filter((item) => item !== id)])
    } else {
      setChoseArr((choseArr) => [...choseArr, id])
    }
  }

  const onSubmit = () => {
    if (!statementId) {
      return
    }
    if (choseArr.length === 0) {
      navigate('../')
    }

    api.client
      .statementsDirectionTrainingsAssign({
        statement: Number(statementId),
        directionTraining: JSON.stringify(choseArr),
      })
      .then((response) => {
        const { data } = response as any
        if (data.status) {
          data.message && dispatch(createToast(data.message, 'success'))
          navigate('../')
        } else {
          data.message && dispatch(createToast(data.message, 'danger'))
        }
      })
      .catch(
        (
          response: Exclude<UpdateDirectionTrainingAssignResponseNew, ResponseOk>
        ) => {
          const { status } = response
          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
          } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        }
      )
  }

  return { choseArr, choseTrials, onSubmit, selectDirection }
}

export default useDirectionTrainingList
