import { swap } from 'types/typesafe-objects'

export type TMimeType =
  | 'image/png'
  | 'image/jpg'
  | 'image/jpeg'
  | 'image/gif'
  | 'image/tiff'
  | 'text/plain'
  | 'application/pdf'
  | 'application/msword'
  | 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  | 'text/csv'

export type TFileType =
  | 'png'
  | 'jpg'
  | 'jpeg'
  | 'gif'
  | 'tiff'
  | 'txt'
  | 'pdf'
  | 'doc'
  | 'docx'
  | 'xlsx'
  | 'csv'

export const mimeTypes: { [key in TFileType]: TMimeType } = {
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  gif: 'image/gif',
  tiff: 'image/tiff',
  txt: 'text/plain',
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'text/csv',
}

export const fileTypes = swap<TFileType, TMimeType>(mimeTypes)

export const unrenderedMimes = [
  mimeTypes.txt,
  mimeTypes.pdf,
  mimeTypes.doc,
  mimeTypes.docx,
  mimeTypes.xlsx,
]

export const defaultMimeTypes = [
  mimeTypes.png,
  mimeTypes.jpg,
  mimeTypes.jpeg,
  mimeTypes.tiff,
  mimeTypes.docx,
  mimeTypes.doc,
  mimeTypes.pdf,
]
