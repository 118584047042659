import { RootState } from '../../types'
import { SelectOption } from '@models'
import { getAdminLegalRepresentative } from '@redux/action-types/admin'
import { adminSlice } from '@redux/reducers'

export const selectAdminUserList = (state: RootState) => {
  return state.admin.entities.users.data
}

export const selectAdminAuthPageSettings = (state: RootState) =>
  state.admin.entities.directoriesList.data

export const selectAdminPageDirectoriesList = (state: RootState) =>
  state.admin.pageDirectories

export const selectAdminPerPageDirectoriesList = (state: RootState) =>
  state.admin.perPageDirectories

export const selectAdminPageDirectoriesInfo = (state: RootState) =>
  state.admin.pageDirectoriesInfo

export const selectAdminPerPageDirectoriesInfo = (state: RootState) =>
  state.admin.perPageDirectoriesInfo

export const selectAdminPageUsersList = (state: RootState) => state.admin.pageUsers

export const selectAdminPerPageUsersList = (state: RootState) =>
  state.admin.perPageUsers

export const selectAdminUsersFilterData = (state: RootState) =>
  state.admin.usersFilterData

export const selectAdminLogList = (state: RootState) =>
  state.admin.entities.logList.data

export const selectAdminPageLogList = (state: RootState) => state.admin.pageLogList

export const selectAdminDirectoriesList = (state: RootState) =>
  state.admin.entities.directoriesList.data

export const selectAdminDirectoryInfo = (state: RootState) =>
  state.admin.entities.directoryInfo.data

export const selectAdminPerPageLogList = (state: RootState) =>
  state.admin.perPageLogList

export const selectAdminRoleOptions = (state: RootState): SelectOption[] => {
  const { data } = state.admin.entities.adminRoleOptions
  if (!data || !data.length) return []
  return data.map((level) => {
    const [translate] = level.roleTranslate
    return {
      value: translate.roleId,
      label: translate.name,
    }
  })
}

export const selectAdminLegalRepresentative = (state: RootState) =>
  state.admin.entities.legalRepresentative.data

export const selectAdminStatusOptions = (state: RootState): SelectOption[] => {
  const { data } = state.admin.entities.adminStatusOptions
  if (!data || !data.length) return []
  return data.map((status) => {
    return {
      value: status.code,
      label: status.name,
    }
  })
}

export const selectAdminVisa = (state: RootState) => {
  const { data } = state.admin.entities.visa
  return data ?? null
}
export const {
  setAdminPageUsers,
  setAdminPerPageUsers,
  setAdminUsersFilterData,
  setAdminPageLog,
  setAdminPerPageLog,
  setAdminPageDirectories,
  setAdminPerPageDirectories,
  setAdminPageDirectoriesInfo,
  setAdminPerPageDirectoriesInfo,
  clearAdminDirectoriesList,
  clearAdminDirectoryInfo,
} = adminSlice.actions
