import usePaginatedEntity from './usePaginatedEntity'
import { getPartnersList } from '@redux/action-types'
import {
  selectPartnersLoading,
  selectPartnersOptions,
} from '@redux/actions/selectors'

const usePaginatedPartners = () => {
  return usePaginatedEntity({
    action: getPartnersList,
    dataSelector: selectPartnersOptions,
    loaderSelector: selectPartnersLoading,
  })
}
export default usePaginatedPartners
