import React, { useEffect } from 'react'

import Section from './components/Section'
import './compatriotDataPage.scss'
import { mimeTypes } from '@common/manual'
import terms from '@common/terms'
import ProfileForm from '@components/ProfileForm'
import ProfileSection from '@components/ProfileSection'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useDownloadTemplateDocument from '@hooks/useDownloadTemplateDocument'
import useProfileRoutesContext from '@hooks/useProfileRoutesContext'
import useProtectProfileRoute from '@hooks/useProtectProfileRoute'
import useUploadDocuments from '@hooks/useUploadDocuments'
import { createToast } from '@redux/action-types'
import { selectIsQuestionnaireBlockProfile } from '@redux/actions/selectors'
import Button from '@ui/Button'
import FileUploader from '@ui/FileUploader'
import Loader from '@ui/Loader'

const CompatriotDataPage = () => {
  const dispatch = useAppDispatch()

  const {
    docs: compatriotDocs,
    onDrop: onDropCompatriot,
    onChange: onChangeCompatriot,
    onDelete: onDeleteCompatriot,
    onUploadDocs: onUploadCompatriot,
    getUploadedDoc: getCompatriotDoc,
    documentLoaded: compatriotLoaded,
  } = useUploadDocuments('COMPATRIOT_APPLICATION', 'Заявление о самоидентификации')

  const {
    docs: birthDocs,
    onDrop: onDropBirth,
    onChange: onChangeBirth,
    onDelete: onDeleteBirth,
    onUploadDocs: onUploadBirth,
    getUploadedDoc: getBirthDoc,
    documentLoaded: birthLoaded,
  } = useUploadDocuments(
    'BIRTH_CERTIFICATE',
    'Копия свидетельства о рождении абитуриента'
  )

  const {
    docs: birthCopyDocs,
    onDrop: onDropBirthCopy,
    onChange: onChangeBirthCopy,
    onDelete: onDeleteBirthCopy,
    onUploadDocs: onUploadBirthCopy,
    getUploadedDoc: getBirthCopyDoc,
    documentLoaded: birthCopyLoaded,
  } = useUploadDocuments(
    'BIRTH_CERTIFICATES_OF_RELATIVES',
    'Копии документов, подтверждающих статус соотечественника'
  )

  useEffect(() => {
    !birthLoaded && getBirthDoc()
    !birthCopyLoaded && getBirthCopyDoc()
    !compatriotLoaded && getCompatriotDoc()
  }, [])

  const { loading } = useProtectProfileRoute('compatriot', '/profile/visa')

  const { downloadTemplateDoc } = useDownloadTemplateDocument()
  const { onNextPageNavigate } = useProfileRoutesContext()
  const readOnly = useAppSelector(selectIsQuestionnaireBlockProfile)

  const onDownloadDocument = () => {
    downloadTemplateDoc(
      'COMPATRIOT_APPLICATION',
      mimeTypes.docx,
      'docx',
      'Заявление о самоидентификации'
    )
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    Promise.allSettled([
      onUploadBirth(() => getBirthDoc()),
      onUploadBirthCopy(() => getBirthCopyDoc()),
      onUploadCompatriot(() => getCompatriotDoc()),
    ]).then((results) => {
      if (results.every((res) => res.status === 'fulfilled' && res.value.success)) {
        onNextPageNavigate()
      } else {
        dispatch(createToast(terms.NOT_ALL_REQUIRED_FIELDS_FILLED, 'danger'))
      }
    })
  }

  if (loading) {
    return <Loader />
  }

  return (
    <ProfileForm onSubmit={onSubmit} className="form">
      <Button type="button" onClick={onDownloadDocument}>
        Скачать Заявление о самоидентификации
      </Button>
      <ProfileSection
        fileUploadingHint
        title="Заявление о самоидентификации"
        markRequired
      >
        <FileUploader
          disabled={readOnly}
          onChange={onChangeCompatriot}
          onDelete={onDeleteCompatriot}
          onDrop={onDropCompatriot}
          files={compatriotDocs}
          // description="Заявление о самоидентификации"
        ></FileUploader>
      </ProfileSection>
      <ProfileSection
        fileUploadingHint
        title="Копия свидетельства о рождении абитуриента"
        markRequired
      >
        <FileUploader
          disabled={readOnly}
          onChange={onChangeBirth}
          onDelete={onDeleteBirth}
          onDrop={onDropBirth}
          files={birthDocs}
        ></FileUploader>
      </ProfileSection>
      <ProfileSection
        fileUploadingHint
        title="Копии документов, подтверждающих статус соотечественника:
        документы, подтверждающие гражданство СССР, проживание в государствах, входивших в состав СССР, получение гражданства этих государств;
        документы выходца (эмигранта) из Российского государства, Российской республики, РСФСР, СССР и Российской Федерации, имеющего соответствующую гражданскую принадлежность и ставшего гражданином иностранного государства;
        документы, подтверждающие родство по прямой восходящей  линии с вышеперечисленными гражданами"
        markRequired
      >
        <FileUploader
          disabled={readOnly}
          onChange={onChangeBirthCopy}
          onDelete={onDeleteBirthCopy}
          onDrop={onDropBirthCopy}
          files={birthCopyDocs}
        ></FileUploader>
      </ProfileSection>
    </ProfileForm>
  )
}

export default CompatriotDataPage
