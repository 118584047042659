import React, { useEffect, useMemo } from 'react'
import { FieldError } from 'react-hook-form'

import FillingStatementsForm from '@components/FillingStatementsForm'
import ProfileSection from '@components/ProfileSection'
import useReadOnlyFillingStatements from '@hooks/useReadOnlyFillingStatements'
import useTargetAgreementForm from '@hooks/useTargetAgreementForm'
import useTargetAgreementFormInitialization from '@hooks/useTargetAgreementFormInitialization'
import useUploadDocuments from '@hooks/useUploadDocuments'
import Checkbox from '@ui/Checkbox'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import Link from '@ui/Link'

const TargetAgreementForm = (scenarioKeys) => {
  const {
    noTarget,
    fields,
    clearErrors,
    formState: { errors },
    onSubmit,
    setValue,
    statement,
  } = useTargetAgreementForm()

  const { readOnly } = useReadOnlyFillingStatements()

  const { targetContract } = useTargetAgreementFormInitialization({
    setValue,
    fields,
  })

  const { onChange, docs, onDrop, onDelete, docId, getUploadedDoc } =
    useUploadDocuments('TARGET_CONTRACT')

  useEffect(() => {
    if (targetContract?.userDocId) {
      getUploadedDoc(targetContract.userDocId)
    }
  }, [targetContract?.userDocId])

  useEffect(() => {
    if (docId) {
      setValue('userDocId', docId)
    }
  }, [docId])

  useEffect(() => {
    if (docs.length) {
      clearErrors('documents')
    }
    setValue('documents', docs)
  }, [docs])

  const fileName = useMemo(() => {
    const bachelor = scenarioKeys.level_training_bachelor.tdId
    const bachelor_ino = scenarioKeys.level_training_bachelor_ino.tdId
    const bachelor_tashkent = scenarioKeys.level_training_bachelor_tashkent.tdId

    const magistra_rf = scenarioKeys.magistra_rf.tdId
    const magistra_ino = scenarioKeys.magistra_ino.tdId

    const asperatnuta_rf = scenarioKeys.asperatnuta_rf.tdId
    const level_training_preparation = scenarioKeys.level_training_preparation.tdId

    const scenarioId = statement?.scenarioId

    // Если сценарий бакалавр, специалитет
    if (
      scenarioId === bachelor ||
      scenarioId === bachelor_ino ||
      scenarioId === bachelor_tashkent
    ) {
      return 'Заявка_на_целевое_бакалавриат_специалитет.pdf'
    }

    // Если сценарий магистратура
    if (scenarioId === magistra_rf || scenarioId === magistra_ino) {
      return 'Заявка_на_целевое_магистратура.pdf'
    }

    // Если сценарий аспирантура
    if (scenarioId === asperatnuta_rf) {
      return 'Заявка_на_целевое_аспирантура.pdf'
    }

    // Если сценарий ассистентура
    if (scenarioId === level_training_preparation) {
      return 'Заявка_на_целевое_ассистентура.pdf'
    }

    return ''
  }, [statement, scenarioKeys])

  return (
    <FillingStatementsForm
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <Checkbox
        disabled={readOnly}
        className={'profile-form__checkbox--mb-24'}
        text={`Я не заключаю договор о целевом обучении`}
        {...fields.noTarget}
      />

      <div className="template-auth-form__message template-main-text">
        <p>
          С предложениями заказчиков о заключении договоров о целевом обучении Вы
          можете ознакомиться на платформе «Работа России»{' '}
          <a
            href="https://trudvsem.ru"
            target="_blank"
            rel="noopener noreferrer"
            className="link_to_information"
          >
            https://trudvsem.ru
          </a>
        </p>
      </div>

      {!noTarget && (
        <>
          <ProfileSection>
            <Link
              href={'/documents/' + fileName}
              target="_blank"
              className="blanc__upload-doc-btn"
            >
              Скачать бланк заявки на заключение договора о целевом обучении
            </Link>

            <Input
              disabled={readOnly}
              className="profile-form__field"
              placeholder={'Наименование заказчика целевого обучения'}
              {...fields.customerName}
              error={errors.customerName?.message}
              markRequired
            />

            <Input
              disabled={readOnly}
              className="profile-form__field"
              placeholder={
                'Идентификационный номер предложения заказчика о заключении договора на платформе «Работа России»'
              }
              {...fields.idNumberWorkInRussia}
              error={errors.idNumberWorkInRussia?.message}
              markRequired
            />
          </ProfileSection>

          <ProfileSection
            title={
              'Файл заполненной заявки на заключение договора о целевом обучении'
            }
            markRequired
            fileUploadingHint
          >
            <FileUploader
              files={docs}
              onChange={onChange}
              onDrop={onDrop}
              onDelete={onDelete}
              className="profile-form__file-uploader"
            />

            {errors.documents && (
              <div className="profile-form__error">
                {(errors.documents as unknown as FieldError | undefined)?.message}
              </div>
            )}
          </ProfileSection>
        </>
      )}
    </FillingStatementsForm>
  )
}

export default TargetAgreementForm
