import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

import useFillingStatementsRoutesContext, {
  FillingStatementsRoutesContextProvider,
} from '@hooks/useFillingStatementsRoutesContext'
import useSubmitFormContext, {
  SubmitFormContextProvider,
} from '@hooks/useSubmitFormContext'
import SidenavPage from '@pages/SidenavPage'

const FillingStatementsPage = (scenarioKeys) => {
  return (
    <main className="filling-statements">
      <SubmitFormContextProvider>
        <FillingStatementsRoutesContextProvider scenarioKeys={scenarioKeys}>
          <SidenavPage
            scenarioKeys={scenarioKeys}
            useHooks={useFillingStatementsRoutesContext}
            title=""
            useSubmitFormHook={useSubmitFormContext}
          >
            <Outlet />
          </SidenavPage>
        </FillingStatementsRoutesContextProvider>
      </SubmitFormContextProvider>
    </main>
  )
}

export default FillingStatementsPage
