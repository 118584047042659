import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import api, { ResponseOk } from '../services/api'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import { columnsAdminDirectoriesList } from '@common/table/moderator'
import terms from '@common/terms'
import { createToast, showModal } from '@redux/action-types'
import { getDirectoriesList, getDirectoryInfo } from '@redux/action-types/admin'
import {
  clearAdminDirectoriesList,
  clearAdminDirectoryInfo,
  selectAdminDirectoriesList,
  selectAdminDirectoryInfo,
  selectAdminPageDirectoriesInfo,
  selectAdminPerPageDirectoriesInfo,
  setAdminPageDirectoriesInfo,
} from '@redux/actions/selectors'

const directoryMapper = (list, directoryName?) =>
  list
    ? list.map((item) => {
        const itemKey = Object.keys(item).find(
          (label) =>
            label.includes('Translate') ||
            label.includes('translate') ||
            label.includes('translations') ||
            (label.includes('Training') && !label.includes('Id')) ||
            label.includes(`${label}Translate`)
        )
        const arr = <any>[]
        if (directoryName === 'langues') {
          arr.push('name')
          arr.push('code')
        }
        if (directoryName === 'olympiads') {
          arr.push(`${itemKey}Ru`)
          arr.push(`${itemKey}Eng`)
        }
        for (let key of Object.keys(item)) {
          if (
            key === 'externalId' ||
            key === 'visible' ||
            key === 'duplicate' ||
            key === 'genderId' ||
            key === 'countryVisaId' ||
            key === 'partnerTranslate' ||
            (!(
              typeof item[key] === 'object' &&
              !Array.isArray(item[key]) &&
              !!item[key]
            ) &&
              (Object.keys(item).includes('directionTraining') ||
              Object.keys(item).includes('olympiadDiscipline') ||
              Object.keys(item).includes('countryRegistration')
                ? !Array.isArray(item[key])
                : Array.isArray(item[key])) &&
              key !== 'id' &&
              key !== 'code')
          ) {
            if (itemKey === key && key !== 'directionTraining') {
              arr.push(`${key}Ru`)
              arr.push(`${key}Eng`)
            } else {
              arr.push(key)
            }
          }
        }
        return {
          ...item,
          id: item.id,
          visible: item.visible,
          externalId: item.externalId,
          directoryName: directoryName,
          nameEng:
            (item[String(itemKey)]?.length > 1 && item[String(itemKey)][1]?.name) ||
            (item[String(itemKey)]?.translations?.length > 1 &&
              item[String(itemKey)]?.translations[1]?.name) ||
            (item[String(itemKey)]?.translation?.length > 1 &&
              item[String(itemKey)]?.translation[1]?.name) ||
            (item[String(itemKey)]?.translate?.length > 1 &&
              item?.translate[1]?.name) ||
            '',
          name:
            (Array.isArray(item[String(itemKey)]) &&
              item[String(itemKey)][0]?.name) ||
            item[String(itemKey)]?.name ||
            item[String(itemKey)]?.translations[0]?.name ||
            item[String(itemKey)]?.translation[0]?.name ||
            item?.translate[0]?.name,
          connections: [...arr],
        }
      })
    : []

const useDirectoryInfo = () => {
  const changeDirectoryView = (
    id: string | number,
    visible: boolean,
    directoryName: string,
    externalId: string
  ) => {
    api.client
      .changeAdminDirectory(directoryName, { id, visible, externalId })
      .then((response) => {
        const { data } = (response as unknown) as ResponseOk
        dispatch(
          getDirectoryInfo({
            directoryName: String(directoryName),
            params: { page, perPage },
          })
        )
        dispatch(createToast(data.message, 'success'))
      })
      .catch(() => dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger')))
  }

  const deleteDirectory = (name: string, id: string | number) => {
    api.client
      .deleteAdminDirectory(name, id)
      .then((response) => {
        const { data } = (response as unknown) as ResponseOk
        dispatch(
          getDirectoryInfo({
            directoryName: String(directoryName),
            params: { page, perPage },
          })
        )
        dispatch(createToast(data.message, 'success'))
      })
      .catch(() => dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger')))
  }
  const editDirectory = (data) => {
    dispatch(showModal({ name: 'ADMIN_ADD_DICTIONARY', data: { data } }))
  }

  const createNewDirectory = (data) => {
    dispatch(showModal({ name: 'ADMIN_ADD_DICTIONARY', data: { data } }))
  }

  const { directoryName, directoryId } = useParams<{
    directoryName: string
    directoryId: string
  }>()

  const dispatch = useAppDispatch()

  const directoryInfo = useAppSelector(selectAdminDirectoryInfo)
  const directory = useAppSelector(selectAdminDirectoriesList)

  const page = useAppSelector(selectAdminPageDirectoriesInfo)
  const perPage = useAppSelector(selectAdminPerPageDirectoriesInfo)

  if (!directoryInfo?.data.length) {
    dispatch(setAdminPageDirectoriesInfo(1))
  }

  useEffect(() => {
    const searchParams = {
      directoryName: String(directoryName),
      params: { page, perPage },
    }
    dispatch(getDirectoryInfo(searchParams))
  }, [page, perPage])

  useEffect(() => {
    dispatch(
      getDirectoriesList({
        page: 1,
        perPage: 10,
        ids: directoryId,
      })
    )
    directoryName === 'phone_codes' &&
      columnsAdminDirectoriesList
        .splice(1, 0, { Header: 'Коды', accessor: 'phoneCode' })
        .join()
    return () => {
      dispatch(clearAdminDirectoriesList())
      dispatch(clearAdminDirectoryInfo())
    }
  }, [])

  const mappedData = directoryMapper(directoryInfo?.data, directoryName)

  return {
    mappedData,
    changeDirectoryView,
    deleteDirectory,
    editDirectory,
    directory,
    createNewDirectory,
    directoryInfo,
    columnsAdminDirectoriesList,
  }
}

export default useDirectoryInfo
