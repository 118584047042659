import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import terms from '@common/terms'
import FillingStatementsForm from '@components/FillingStatementsForm'
import ProfileSection from '@components/ProfileSection'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useEntrantsContractForm from '@hooks/useEntrantsContractForm'
import { SelectOption } from '@models'
import { getDirectionsListOptions } from '@redux/action-types'
import { selectDirectionListOptions } from '@redux/actions/selectors'
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'
import Select from '@ui/Select'

import './entrantsContractForm.scss'

const EntrantsContractForm = ({
  payerTypeId,
  contract,
  trajectoryId,
  scenarioId,
}: {
  payerTypeId: number
  contract?: any
  trajectoryId?: number
  scenarioId?: number
}) => {
  const { statementId } = useParams()
  const {
    control,
    onSubmit,
    setValue,
    fields,
    reset,
    formState: { errors },
    forIno,
  } = useEntrantsContractForm(
    String(statementId),
    contract,
    trajectoryId,
    scenarioId
  )

  const dispatch = useAppDispatch()

  const directionOptions = useAppSelector(selectDirectionListOptions)
  let filterOptions = directionOptions.filter((item) => item.accessСonclusion)

  useEffect(() => {
    dispatch(getDirectionsListOptions(String(statementId)))
    setValue('payerTypeId', payerTypeId)
    if (statementId) {
      setValue('statementId', Number(statementId))
    }
  }, [])

  return (
    <FillingStatementsForm
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
      }}
    >
      <ProfileSection title="">
        <div className="profile-form__fields contract">
          <Controller
            name="directionTrainingId"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                options={
                  trajectoryId === 2
                    ? filterOptions.filter(
                        (option: any) => option.formPaymentCode === '2'
                      )
                    : filterOptions
                }
                placeholder={terms.DIRECTIONS_TRAINING}
                className="profile-form__field"
                markRequired={true}
                {...(field as unknown)}
                error={errors.directionTrainingId?.message}
              />
            )}
          />

          {forIno && (
            <Checkbox
              text="Планирую брать образовательный кредит"
              className="profile-form__checkbox"
              {...fields.eduCredit}
            />
          )}
        </div>
      </ProfileSection>
      {/* TODO: rename class  */}
      <span className="contract__create">
        Заполните раздел, если Вы планируете поступать на платное обучение
      </span>
      <Button className="create-contract-form__button" theme="success">
        {terms.SUBMIT_CONTRACT_FOR_APPROVAL}
      </Button>
    </FillingStatementsForm>
  )
}

export default EntrantsContractForm
