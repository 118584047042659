import { useEffect, useState } from 'react'

import {
  STATUS_DRAFT,
  STATUS_EDIT_DIRECTION,
  STATUS_SEND_EDITING,
} from '@common/constants'
import useAppSelector from '@hooks/useAppSelector'
import { selectStatementsElement } from '@redux/actions/selectors'

const useReadOnlyFillingStatements = () => {
  const [readOnly, setReadOnly] = useState<boolean>(false)

  const statementEl = useAppSelector(selectStatementsElement)

  const codeStatus = Number(statementEl?.statusStatement?.code)
  const fixDirection = statementEl
    ? codeStatus === STATUS_EDIT_DIRECTION
      ? false
      : readOnly
    : readOnly

  useEffect(() => {
    if (statementEl) {
      if (codeStatus === STATUS_DRAFT || codeStatus === STATUS_SEND_EDITING) {
        setReadOnly(false)
      } else {
        setReadOnly(true && !location.pathname.includes('admin'))
      }
    }
  }, [statementEl])

  return {
    fixDirection,
    readOnly,
  }
}

export default useReadOnlyFillingStatements
