import React, { useState } from 'react'

import classnames from 'classnames'

import Button from '../generic/Button'
import TabArrowDown from '@assets/images/TabArrowDown.svg'

type AccordionTabProps = {
  title: string
  children?: React.ReactNode
}

const AccordionTab = ({ title, children }: AccordionTabProps) => {
  const [open, setOpen] = useState<boolean>(false)
  function toggleOpen() {
    setOpen(!open)
  }
  return (
    <div className="accordion-tab">
      <div className="accordion-tab__header" onClick={toggleOpen}>
        <h2 className="accordion-tab__header_title">{title}</h2>
        <Button
          className={classnames('accordion-tab__header_btn', open && 'rotate')}
        >
          <TabArrowDown />
        </Button>
      </div>
      <div className={classnames('accordion-tab__body', open && 'show')}>
        {children}
      </div>
    </div>
  )
}

export default AccordionTab
