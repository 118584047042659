import React from 'react'
import { Controller } from 'react-hook-form'

import useAdminUserListFilterForm from '../../hooks/useAdminUserListFilterForm'
import useAdminUserListFilterInitialization from '../../hooks/useAdminUserListFilterInitialization'
import useAppSelector from '@hooks/useAppSelector'
import { SelectOption } from '@models'
import { selectAdminUserList } from '@redux/actions/selectors'
import Button from '@ui/Button'
import Input from '@ui/Input'
import Select from '@ui/Select'

const AdminUserListFilter = () => {
  const { fields, control, reset, onSubmit } = useAdminUserListFilterForm()

  const { statusTypesOptions } = useAdminUserListFilterInitialization()

  const adminUserList = useAppSelector(selectAdminUserList) || []

  return (
    <section>
      <form onSubmit={onSubmit} className={'moderator-contract-filter'}>
        <Input
          className={'filter-form__field'}
          {...fields.userId}
          placeholder="ID пользователя"
        />
        <Input
          className={'filter-form__field'}
          {...fields.surname}
          placeholder="Фамилия"
        />
        <Input className={'filter-form__field'} {...fields.name} placeholder="Имя" />
        <Input
          className={'filter-form__field'}
          {...fields.patronymic}
          placeholder="Отчество"
        />
        <Input
          className={'filter-form__field'}
          {...fields.email}
          placeholder="Email"
        />
        <Input
          className={'filter-form__field'}
          {...fields.passportSerialDocument}
          placeholder="Серия паспорта"
        />
        <Input
          className={'filter-form__field'}
          {...fields.passportNumberDocument}
          placeholder="Номер паспорта"
        />
        <Controller
          defaultValue={null}
          name="status"
          control={control}
          render={({ field }) => (
            <Select<SelectOption>
              options={statusTypesOptions}
              placeholder="Активен"
              className="filter-form__field"
              {...(field as unknown)}
            />
          )}
        />
        <footer>
          <h2>Пользователей: {adminUserList?.total | 0}</h2>

          <div>
            <Button type={'submit'} onClick={() => reset()}>
              Сбросить фильтры
            </Button>
            <Button theme={'success'} type={'submit'}>
              Отфильтровать
            </Button>
          </div>
        </footer>
      </form>
    </section>
  )
}
export default AdminUserListFilter
