import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { FilterPersonalInformation, FilterPersonalInformationData } from '@models'
import { getModeratorPersonalInformationList } from '@redux/action-types'
import {
  selectModeratorStatementsListOrder,
  selectModeratorStatementsListPage,
  selectModeratorStatementsListPerPage,
} from '@redux/actions/selectors'

const useModeratorPersonalInformationFilter = () => {
  const dispatch = useAppDispatch()
  const { register, handleSubmit, control, reset, setValue } = useForm<
    FilterPersonalInformation
  >({ mode: 'all' })

  const fields = {
    userId: register('userId'),
    surname: register('surname'),
    name: register('name'),
    patronymic: register('patronymic'),
    email: register('email'),
    createdAt: register('createdAt'),

    levelEducationIds: register('levelEducationIds'),
    nationalityIds: register('nationalityIds'),

    perPage: register('perPage'),
    page: register('page'),
  }

  const order = useAppSelector(selectModeratorStatementsListOrder)
  const page = useAppSelector(selectModeratorStatementsListPage)
  const perPage = useAppSelector(selectModeratorStatementsListPerPage)

  useEffect(() => {
    setValue('page', page)
    setValue('perPage', perPage)
    setValue('orderCreateDate', order)
    onSubmit()
  }, [page, perPage, order])

  const onSubmit = handleSubmit((data: any) => {
    const normalizedData: FilterPersonalInformation = {
      ...data,
      levelEducationIds: data.levelEducationIds?.map((el) => {
        return el.value
      }),
      nationalityIds: data.nationalityIds?.map((el) => {
        return el.value
      }),
    }

    dispatch(getModeratorPersonalInformationList(normalizedData))
  })

  return {
    fields,
    control,
    reset,
    setValue,
    onSubmit,
  }
}

export default useModeratorPersonalInformationFilter
