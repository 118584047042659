import React, { forwardRef, useEffect, useRef, useState } from 'react'
import ReactSelect, { components, GroupBase, Props } from 'react-select'

import classNames from 'classnames'

import Checkbox from '@ui/Checkbox'

type SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> & {
  error?: string
  markRequired?: boolean
  allowSelectAll?: boolean
  invariant?: boolean
  onPaginate?: () => void
  onChange?: (v: unknown) => void
}

const SelectInner = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  {
    error,
    className,
    onFocus,
    invariant = false,
    allowSelectAll = false,
    onBlur,
    markRequired = false,
    onPaginate,
    ...rest
  }: SelectProps<Option, IsMulti, Group>,
  ref: unknown
) => {
  const selectRef = useRef<any>(null)
  const [active, setActive] = useState(false)

  const selectAllOption = {
    value: 'all',
    label: 'Выбрать все',
  }

  const handleFocusOut = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      setActive(false)
    }
  }
  useEffect(() => {
    if (rest.value && !active) {
      setActive(true)
    }
  }, [rest.value, active])

  return (
    <div
      className={classNames('select-container', className, {
        ['select-container--active']: Boolean(active),
        ['select-container--error']: Boolean(error),
      })}
    >
      {allowSelectAll ? (
        <ReactSelect
          ref={selectRef}
          noOptionsMessage={() => 'Нет данных'}
          {...rest}
          placeholder=""
          className="react-select-container"
          classNamePrefix="react-select"
          autoFocus={false}
          onFocus={(e) => {
            setActive(true)
            onFocus && onFocus(e)
          }}
          onBlur={(e) => {
            handleFocusOut(e)
            onBlur && onBlur(e)
          }}
          onMenuScrollToBottom={() => {
            onPaginate && onPaginate()
          }}
          options={[selectAllOption, ...(rest.options as any)]}
          onChange={(selected: any, current) => {
            // @ts-ignore
            if (current?.option?.value === 'all' && rest.onChange) {
              invariant &&
                current.action === 'select-option' &&
                rest.onChange([selectAllOption])
              !invariant &&
                current.action === 'select-option' &&
                rest.onChange([...(rest.options as any)])
              current.action === 'deselect-option' && rest.onChange(null)
            } else {
              rest.onChange && rest.onChange(selected)
            }
          }}
        />
      ) : (
        <ReactSelect
          ref={selectRef}
          noOptionsMessage={() => 'Нет данных'}
          {...rest}
          placeholder=""
          className="react-select-container"
          classNamePrefix="react-select"
          autoFocus={false}
          onFocus={(e) => {
            setActive(true)
            onFocus && onFocus(e)
          }}
          onBlur={(e) => {
            handleFocusOut(e)
            onBlur && onBlur(e)
          }}
          onMenuScrollToBottom={() => {
            onPaginate && onPaginate()
          }}
        />
      )}
      <span
        onClick={() => selectRef.current?.focus()}
        className={classNames('select-container__placeholder', {
          ['select-container__placeholder--required']: markRequired,
        })}
      >
        {rest.placeholder}
      </span>
      {error && <span className={'select-container__error'}>{error}</span>}
    </div>
  )
}

const Select = forwardRef(SelectInner) as <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: SelectProps<Option, IsMulti, Group> & {
    ref?: React.Ref<HTMLSelectElement>
  }
) => ReturnType<typeof SelectInner>

export default Select

export const SingleValue = (props) => (
  <components.SingleValue {...props}>{props.data.chipLabel}</components.SingleValue>
)

export const Option = (props) => {
  return (
    <div>
      <components.Option
        {...props}
        className={classNames(
          props.className,
          props?.selectProps?.value?.find((el) => el.value === 'all') &&
            'allSelected'
        )}
      >
        <Checkbox
          checked={
            props?.selectProps?.value?.find((el) => el.value === 'all')
              ? !props.isSelected
              : props.isSelected
          }
          text={''}
          onChange={() => null}
        />
        <label style={{ marginLeft: '20px' }}>{props.label}</label>
      </components.Option>
    </div>
  )
}

export const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
)
