import React from 'react'

import ModeratorDocumentsPage from '../ModeratorDocumentsPage'

const ModeratorCompatriotDataPage = () => {
  return (
    <div className={'moderator-data-list'}>
      <ModeratorDocumentsPage
        params={[
          {
            typeDoc: 'application_for_recognition_of_compatriot_status',
            title: 'Заявление о самоидентификации',
          },
          {
            typeDoc: 'copy_of_the_applicants_birth_certificate',
            title: 'Копия свидетельства о рождении абитуриента',
          },
          {
            typeDoc: 'copies_of_birth_certificates_of_next_of_kin',
            title:
              'Копии документов, подтверждающих статус соотечественника: документы, подтверждающие гражданство СССР, проживание в государствах, входивших в состав СССР, получение гражданства этих государств; документы выходца (эмигранта) из Российского государства, Российской республики, РСФСР, СССР и Российской Федерации, имеющего соответствующую гражданскую принадлежность и ставшего гражданином иностранного государства; документы, подтверждающие родство по прямой восходящей  линии с вышеперечисленными гражданами',
          },
        ]}
      />
    </div>
  )
}

export default ModeratorCompatriotDataPage
