import { useRef } from 'react'

export default function useDebouncedFunction(
  func: (...arg: any) => void,
  delay: number
) {
  const ref = useRef(null) as React.MutableRefObject<any>

  return (...args) => {
    clearTimeout(ref.current)
    ref.current = setTimeout(() => func(...args), delay)
  }
}
