import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import { getConclusionBaseDepartment } from '@redux/action-types'
import { selectConclusionBaseDepartment } from '@redux/actions/selectors'

const useConclusionBaseDepartment = () => {
  const conclusionBaseDepartment = useAppSelector(selectConclusionBaseDepartment)
  const { statementId } = useParams()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getConclusionBaseDepartment(Number(statementId)))
  }, [])

  return { conclusionBaseDepartment }
}

export default useConclusionBaseDepartment
