import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ModeratorContractIdInfo from '@components/ModeratorContractIdInfo'
import { isNumeric } from '@utils/common'

const ModeratorContractIdPage = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id?: string }>()

  useEffect(() => {
    if (!isNumeric(id)) {
      navigate('../contracts')
    } else {
    }
  }, [id])

  return (
    <main className="moderator-contract-id-page">
      <ModeratorContractIdInfo />
    </main>
  )
}

export default ModeratorContractIdPage
