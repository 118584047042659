import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useEntrantsContractFormInitialization from '@hooks/useEntrantsContractFormInitialization'
import { ContractEntrants } from '@models'
import { createToast, getContractsList } from '@redux/action-types'
import api, { AddContractEntrantsResponse, ResponseOk } from '@services/api'

const useEntrantsContractForm = (
  statementId: string | number,
  contract,
  trajectoryId?: number,
  scenarioId?: number
) => {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    setValue,
    trigger,
    getValues,
    reset,
    ...rest
  } = useForm<ContractEntrants>({ mode: 'all' })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isEditing = Boolean(contract)

  const forIno =
    trajectoryId === 1 ||
    (trajectoryId === 2 &&
      scenarioId !== 5 &&
      scenarioId !== 6 &&
      scenarioId !== 1 &&
      scenarioId !== 3)

  const fields = {
    statementId: register('statementId'),
    directionTrainingId: register('directionTrainingId', {
      required: { value: true, message: terms.REQUIRED_FIELD },
      onChange: (e) => setValue('competitiveGroupStatementId', e.target.value.id),
    }),
    payerTypeId: register('payerTypeId'),
    competitiveGroupStatementId: register('competitiveGroupStatementId'),
    eduCredit: register('eduCredit'),
    contractId: register('contractId'),
  }

  useEntrantsContractFormInitialization(contract, setValue)

  const onSubmit = handleSubmit((data: any) => {
    if (!data.directionTrainingId) {
      return
    }

    const normalizedData: ContractEntrants = {
      ...data,
      statementId: data.statementId,
      directionTrainingId: data.directionTrainingId.value,
    }

    if (isEditing) {
      api.client
        .editContractEntrants(normalizedData)
        .then((response) => {
          const { data } = response as ResponseOk
          dispatch(createToast(data.message, 'success'))
        })
        .catch((response: Exclude<AddContractEntrantsResponse, ResponseOk>) => {
          const { status } = response

          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))

            Object.keys(data.errors).forEach((key) => {
              setError(key as keyof ContractEntrants, {
                type: 'manual',
                message: data.errors[key][0],
              })
            })
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
        .finally(() => {
          dispatch(getContractsList(String(statementId)))
          navigate(`/application/${statementId}/filling-statements/contract`)
        })
    } else {
      api.client
        .addContractEntrants(normalizedData)
        .then((response) => {
          const { data } = response as ResponseOk
          dispatch(createToast(data.message, 'success'))
        })
        .catch((response: Exclude<AddContractEntrantsResponse, ResponseOk>) => {
          const { status } = response

          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))

            Object.keys(data.errors || {}).forEach((key) => {
              setError(key as keyof ContractEntrants, {
                type: 'manual',
                message: data.errors[key][0],
              })
            })
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
        .finally(() => {
          dispatch(getContractsList(String(statementId)))
          navigate(`/application/${statementId}/filling-statements/contract`)
        })
    }
  })

  return {
    fields,
    onSubmit,
    setValue,
    getValues,
    watch,
    reset,
    forIno,
    ...rest,
  }
}

export default useEntrantsContractForm
