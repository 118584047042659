import React from 'react'

import VisaForm from '@components/VisaForm'

const VisaPge = () => {
  return (
    <div>
      <VisaForm />
    </div>
  )
}

export default VisaPge
