import React, { useEffect, useState } from 'react'

import terms from '@common/terms'
import { createToast, getContractsList } from '@redux/action-types'
import { selectContractsList } from '@redux/actions/selectors'
import api, { UploadDocsRespOk, UploadDocsResponse } from '@services/api'
import { base64toFile, downloadFile } from '@utils/common'
import useDownloadDocument from '@hooks/useDownloadDocument'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import useAppSelector from './useAppSelector'
import { UploadDocKey } from '@common/uploadDocsTypes'
import HttpStatusCode from '@common/httpStatusCodes'

const useContractList = (params?: { userId: number; statementId: number }) => {
  const dispatch = useDispatch()
  const { statementId } = useParams()
  let contracts = useAppSelector(selectContractsList)
  const { downloadDocument } = useDownloadDocument()

  const [signedFilesToShow, setSignedFilesToShow] = useState({})
  const [paidReceiptFilesToShow, setPaidReceiptFilesToShow] = useState({})

  useEffect(() => {
    contracts?.data && filterSignedFiles(contracts)
  }, [contracts, contracts?.data])

  const downloadPrintContract = (contractId: number) => {
    api.client
      .getPrintContract(contractId, params)
      .then((response) =>
        base64toFile(response.data, 'application/msword', 'docx', 'Договор')
      )
      .then(downloadFile)
      .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')))
  }

  const uploadContract = (data) => {
    api.client
      .addContractUser(data)
      .then(() => dispatch(createToast('Успешно', 'success')))
    // .catch(() => dispatch(createToast(terms.UPLOAD_CONTRACT_ERROR, 'danger'))) ограничения сняты на беке
  }

  const filterSignedFiles = (contracts) => {
    setSignedFilesToShow({})
    contracts.data.map((contract) =>
      contract.contractDoc.map((doc) => {
        doc.userDoc.getTypeDocs.name === 'signed_contract'
          ? setSignedFilesToShow({
              ...signedFilesToShow,
              [doc.contractId]: doc.userDocId,
            })
          : null
      })
    )
  }
  const filterPaidReceiptFiles = (contracts) => {
    setPaidReceiptFilesToShow({})
    contracts.data.map((contract) =>
      contract.contractDoc.map((doc) => {
        doc.userDoc.getTypeDocs.name === 'receipt_paid_contract'
          ? setPaidReceiptFilesToShow({
              ...paidReceiptFilesToShow,
              [doc.contractId]: doc.userDocId,
            })
          : null
      })
    )
  }

  const onDownload = (docId: number, filename?: string) => {
    api.client
      .getDocs('SIGNED_CONTRACT', docId)
      .then((resp) =>
        base64toFile(resp.data.document || '', 'application/pdf', 'pdf', filename)
      )
      .then(downloadFile)
      .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')))
  }

  const onDelete = (docId: number, contractId: number) => {
    api.client
      .deleteDoc({ typeDoc: 'SIGNED_CONTRACT', docId })
      .then(() => dispatch(createToast(terms.FILE_DELETED, 'success')))
      .then(() => dispatch(getContractsList(String(statementId))))
      .then(() => {
        const deleted = signedFilesToShow
        delete deleted[contractId]
        setSignedFilesToShow(deleted)
      })
      .catch(() => dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger')))
  }

  return {
    onDelete,
    onDownload,
    signedFilesToShow,
    filterSignedFiles,
    paidReceiptFilesToShow,
    filterPaidReceiptFiles,
    downloadPrintContract,
    downloadDocument,
    uploadContract,
  }
}

export default useContractList
