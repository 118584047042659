import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import axios from 'axios'

import api from '../services/api'
import useAppSelector from './useAppSelector'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import { UserOtherDocument, ViewerElementStatements } from '@models'
import {
  createToast,
  getModeratorStatementsAdditionalFiles,
  getModeratorStatementsIdStatus,
  getStatementsList,
  showModal,
} from '@redux/action-types'
import {
  selectAdditionalFiles,
  selectModeratorStatementsAdditionalFile,
  selectModeratorStatementsIdStatus,
  selectModeratorStatementsIdStatusCode,
  selectStatementsList,
  selectUserIdProfile,
} from '@redux/actions/selectors'
import { getAdditionalDocs, getAdditionalDocsModerator } from '@redux/reducers'
import { base64toFile, downloadFile, trimFileExtension } from '@utils/common'

const useAdditionalFilesModerator = () => {
  const dispatch = useAppDispatch()
  const files = useAppSelector(selectAdditionalFiles)
  const { id } = useParams<{ id?: string }>()

  useEffect(() => {
    dispatch(getModeratorStatementsIdStatus(Number(id))).then((response) => {
      // @ts-ignore
      let userId = response.payload.userId
      // @ts-ignore
      dispatch(getAdditionalDocsModerator({ id, userId }))
    })
  }, [])

  const onOpenModal = () => {
    dispatch(showModal({ name: 'ADD_FILE_MODAL' }))
  }

  const onEditFile = (name, docId, date) => {
    dispatch(showModal({ name: 'EDIT_FILE_MODAL', data: { name, docId, date } }))
  }

  const onDownload = (file: any) => {
    dispatch(getModeratorStatementsIdStatus(Number(id))).then((response) => {
      // @ts-ignore
      let userId = response.payload.userId
      api.client
        .getDocs(
          file.userDoc.getTypeDocs.name === 'other' ? 'OTHER' : 'CHANGES_SURNAME',
          file.userDoc.id,
          userId
        )
        .then((resp) =>
          base64toFile(
            resp.data.document || '',
            'application/pdf',
            'pdf',
            file.userDoc.name
          )
        )
        .then(downloadFile)
        .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')))
    })
  }

  return {
    files,
    onDownload,
    onOpenModal,
    onEditFile,
  }
}

export default useAdditionalFilesModerator
