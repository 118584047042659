import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import classNames from 'classnames'

import CrossIcon from '@assets/images/Cross.svg'
import FileIcon from '@assets/images/File.svg'
import {
  CONTRACT_APPROVED,
  CONTRACT_DRAFT,
  CONTRACT_EDITING,
  CONTRACT_RECEIPT_SENT,
  CONTRACT_SIGNED,
} from '@common/constants'
import FillingStatementsForm from '@components/FillingStatementsForm'
import ProfileSection from '@components/ProfileSection'
import useAppSelector from '@hooks/useAppSelector'
import useContractList from '@hooks/useContractList'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import useNotEntrantsContractForm from '@hooks/useNotEntrantsContractForm'
import {
  deleteSignedContractList,
  getContractsList,
  setSignedContractList,
  setSignedPaidReceiptList,
  deleteSignedPaidReceiptList,
} from '@redux/action-types'
import {
  selectContractsList,
  selectSignedContractsList,
  selectSignedPaidReceiptList,
  selectTrajectoryValue,
} from '@redux/actions/selectors'
import Button from '@ui/Button'
import FileUploader from '@ui/FileUploader'
import Loader from '@ui/Loader'
import './ContractList.scss'
import useUploadDocuments from '@hooks/useUploadDocuments'

const ContractList = () => {
  const { statementId } = useParams()
  const { editContractFile, contractRevoke, contractDelete } =
    useNotEntrantsContractForm()
  const { onNextPageNavigate } = useFillingStatementsRoutesContext()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const contracts = useAppSelector(selectContractsList)
  const signedFiles = useAppSelector(selectSignedContractsList)
  const signedPaidReceiptFiles = useAppSelector(selectSignedPaidReceiptList)
  const trajectory = useAppSelector(selectTrajectoryValue)
  const {
    onDelete,
    onDownload,
    signedFilesToShow,
    paidReceiptFilesToShow,
    filterPaidReceiptFiles,
    uploadContract,
    downloadPrintContract,
    downloadDocument,
  } = useContractList()

  useEffect(() => {
    dispatch(getContractsList(String(statementId)))
  }, [])

  if (!contracts && !trajectory) {
    return <Loader />
  }

  const docFromModerator = (contract) =>
    contract.contractDoc.find(
      (item) => item.userDoc.getTypeDocs.name === 'scan_for_contract_moderator'
    )
  const receiptFromModerator = (contract) =>
    contract.contractDoc.find(
      (item) => item.userDoc.getTypeDocs.name === 'receipt_payment_contract'
    )

  return (
    <FillingStatementsForm
      onSubmit={(e) => {
        e.preventDefault()
        onNextPageNavigate()
      }}
    >
      <div>
        {contracts?.data?.map((contract, index) => (
          <div className={classNames('card')} key={index}>
            <div className="card__head">
              <span className="card__header">
                {
                  contract.competitiveGroupStatement.competitiveGroup
                    .directionTraining.translations[0].name
                }
              </span>
              <span className="card__header">
                Статус: {contract.status.translate[0].name}
              </span>
            </div>
            <span className="card__content">
              Тип платежа: <strong>Семестровый</strong>
            </span>

            {docFromModerator(contract) && (
              <div className="card__download">
                {contract.status.id === 2 && (
                  <div>
                    <Button
                      theme={'success'}
                      type={'button'}
                      onClick={() =>
                        downloadDocument(
                          'SCAN_CONTRACT_MODERATOR',
                          'application/pdf',
                          'pdf',
                          docFromModerator(contract).userDocId,
                          docFromModerator(contract).userDoc.name
                        )
                      }
                    >
                      Скачать форму
                    </Button>

                    <span className="card__download-text">
                      Скачайте, распечатайте и подпишите договор, затем загрузите
                      подписанный в область ниже
                    </span>
                  </div>
                )}
              </div>
            )}

            {signedFilesToShow[contract.id] ? (
              <ProfileSection
                title="Подписанный договор"
                fileUploadingHint={signedFilesToShow[contract.id]}
              >
                <li
                  key={contract.id}
                  className="add-files-page__item additional-file"
                >
                  <div className="additional-file__icon-wrapper">
                    <button
                      className="additional-file__del-button"
                      onClick={(e) => {
                        e.preventDefault()
                        onDelete(signedFilesToShow[contract.id], contract.id)
                      }}
                    >
                      <CrossIcon
                        width={23}
                        viewBox="-5 0 20 25"
                        height={20}
                        className="additional-file__cross"
                      />
                    </button>

                    <button
                      className="additional-file__button"
                      onClick={() =>
                        onDownload(signedFilesToShow[contract.id], 'Договор')
                      }
                    >
                      <FileIcon
                        width={46}
                        height={61}
                        className="additional-file__icon"
                      />
                    </button>
                  </div>

                  <div className="additional-file__info">
                    <div className="additional-file__name">Договор</div>
                  </div>
                </li>
              </ProfileSection>
            ) : (
              <>
                {contract.status.id === 2 && (
                  <ProfileSection
                    title="Подписанный договор"
                    fileUploadingHint={signedFilesToShow[contract.id]}
                  >
                    <FileUploader
                      files={signedFiles[contract.id] || []}
                      onDrop={(file) => {
                        if (signedFiles[contract.id]?.value) {
                          dispatch(
                            setSignedContractList({
                              id: [contract.id],
                              value: [...signedFiles[contract.id]?.value, ...file],
                            })
                          )
                        } else {
                          dispatch(
                            setSignedContractList({
                              id: [contract.id],
                              value: file,
                            })
                          )
                        }
                      }}
                      onChange={(file) => {
                        dispatch(
                          setSignedContractList({
                            id: [contract.id],
                            value: file,
                          })
                        )
                      }}
                      onDelete={() =>
                        dispatch(deleteSignedContractList({ id: [contract.id] }))
                      }
                      className="profile-form__file-uploader"
                    />
                  </ProfileSection>
                )}

                {signedFiles[contract.id] && (
                  <div>
                    {contract.status.id === 2 && (
                      <Button
                        type={'button'}
                        onClick={() =>
                          uploadContract({
                            contract_id: contract.id,
                            typeDoc: 'scan_for_contract',
                            documents: signedFiles[contract.id],
                          })
                        }
                        theme={'success'}
                      >
                        {trajectory.id === 1
                          ? 'Загрузить договор'
                          : 'Загрузить договор и отправить на проверку'}
                      </Button>
                    )}
                  </div>
                )}
              </>
            )}

            {/* блок загрузки Квитанция отображается в статусе Отправлена квитанция */}
            {contract.status.id === CONTRACT_RECEIPT_SENT && (
              <>
                {receiptFromModerator(contract) && (
                  <div className="card__download">
                    <Button
                      theme={'success'}
                      type={'button'}
                      onClick={() =>
                        downloadDocument(
                          'RECEIPT_PAYMENT_CONTRACT',
                          'application/pdf',
                          'pdf',
                          receiptFromModerator(contract).userDocId,
                          receiptFromModerator(contract).userDoc.name
                        )
                      }
                    >
                      Скачать квитанцию
                    </Button>

                    {contract.status.id === CONTRACT_RECEIPT_SENT ? (
                      <div>
                        {trajectory.id !== 1 && (
                          <div className="card__download-text">
                            Скачайте, произведите оплату, затем загрузите чек об
                            оплате ниже.
                            <br />
                            <br />
                            Вы можете оплатить обучение сразу за год (за 1-й и 2-й
                            семестры), тогда Вам нужно оплатить две квитанции.
                            <br />
                            Вы можете оплатить обучение только за полгода (1-й
                            семестр), тогда нужно оплатить одну квитанцию.
                          </div>
                        )}
                      </div>
                    ) : (
                      <span className="card__download-text">
                        Скачайте, проведите оплату, затем загрузите оплаченную
                        платежку ниже.
                      </span>
                    )}
                  </div>
                )}
                <ProfileSection
                  title="Квитанция"
                  fileUploadingHint={paidReceiptFilesToShow[contract.id]}
                >
                  {paidReceiptFilesToShow[contract.id] ? (
                    <li
                      key={contract.id}
                      className="add-files-page__item additional-file"
                    >
                      <div className="additional-file__icon-wrapper">
                        <button
                          className="additional-file__del-button"
                          onClick={(e) => {
                            e.preventDefault()
                            onDelete(
                              paidReceiptFilesToShow[contract.id],
                              contract.id
                            )
                          }}
                        >
                          <CrossIcon
                            width={23}
                            viewBox="-5 0 20 25"
                            height={20}
                            className="additional-file__cross"
                          />
                        </button>

                        <button
                          className="additional-file__button"
                          onClick={() =>
                            onDownload(
                              paidReceiptFilesToShow[contract.id],
                              'Квитанция'
                            )
                          }
                        >
                          <FileIcon
                            width={46}
                            height={61}
                            className="additional-file__icon"
                          />
                        </button>
                      </div>

                      <div className="additional-file__info">
                        <div className="additional-file__name">Квитанция</div>
                      </div>
                    </li>
                  ) : (
                    <>
                      <FileUploader
                        files={signedPaidReceiptFiles[contract.id] || []}
                        onDrop={(file) => {
                          console.log(file)
                          if (signedPaidReceiptFiles[contract.id]?.value) {
                            dispatch(
                              setSignedPaidReceiptList({
                                id: [contract.id],
                                value: [
                                  ...signedPaidReceiptFiles[contract.id]?.value,
                                  ...file,
                                ],
                              })
                            )
                          } else {
                            dispatch(
                              setSignedPaidReceiptList({
                                id: [contract.id],
                                value: file,
                              })
                            )
                          }
                        }}
                        onChange={(file) => {
                          dispatch(
                            setSignedPaidReceiptList({
                              id: [contract.id],
                              value: file,
                            })
                          )
                        }}
                        onDelete={() =>
                          dispatch(
                            deleteSignedPaidReceiptList({ id: [contract.id] })
                          )
                        }
                        className="profile-form__file-uploader"
                      />
                      {signedPaidReceiptFiles[contract.id] && (
                        <Button
                          type={'button'}
                          theme={'success'}
                          onClick={() =>
                            uploadContract({
                              contract_id: contract.id,
                              typeDoc: 'receipt_paid_contract',
                              documents: signedPaidReceiptFiles[contract.id],
                            })
                          }
                        >
                          {trajectory.id === 1
                            ? 'Загрузить квитанцию'
                            : 'Загрузить квитанцию и отправить модератору'}
                        </Button>
                      )}
                    </>
                  )}
                </ProfileSection>
              </>
            )}

            <div className="card__signature">
              {(Number(contract.status.code) === CONTRACT_EDITING ||
                Number(contract.status.code) === CONTRACT_DRAFT) && (
                <Button
                  className="button card__button-edit"
                  onClick={() =>
                    navigate(
                      `/application/${statementId}/filling-statements/contract/edit/${contract.id}`
                    )
                  }
                >
                  Редактировать
                </Button>
              )}

              {Number(contract.status.code) === CONTRACT_APPROVED && (
                <Button
                  type="button"
                  className="button card__button-edit"
                  onClick={() => contractDelete(contract.id)}
                >
                  Удалить
                </Button>
              )}

              {(Number(contract.status.code) === CONTRACT_EDITING ||
                Number(contract.status.code) === CONTRACT_DRAFT) && (
                <Button
                  type="button"
                  className="button card__button-edit"
                  onClick={() => contractRevoke(contract.id)}
                >
                  Отозвать договор
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
    </FillingStatementsForm>
  )
}

export default ContractList
