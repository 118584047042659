import React, { useEffect } from 'react'

import {
  ROLE_ADMIN,
  ROLE_MODERATOR,
  STATUS_REVOKE_APPROVED,
} from '@common/constants'
import { columnsStatements } from '@common/table/moderator'
import TableList from '@components/TableList'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import {
  clearStatementsIdStatus,
  setModeratorStatementsPage,
  setModeratorStatementsPerPage,
} from '@redux/action-types'
import {
  selectModeratorStatementsList,
  selectRoleIdProfile,
} from '@redux/actions/selectors'
import Loader from '@ui/Loader'
import Pagination from '@ui/Pagination'
import { formatDateTimeToRuFormat } from '@utils/date'

function statementMapper(arr) {
  if (!arr) return []
  const rows: any[] = []
  arr.forEach((item) => {
    const userTranslate: any = item.users.usersTranslate[0] as any[]
    const fullname: any = `${userTranslate?.surname} ${userTranslate?.name} ${
      userTranslate?.patronymic ?? ''
    }`
    const moderatorTranslate: any = item?.statementResponsible?.find(
      (el) => el?.user?.roleId === ROLE_MODERATOR || el?.user?.roleId === ROLE_ADMIN
    )?.user?.usersTranslate[0] as any[]

    rows.push({
      id: item?.id,
      userId: item?.users?.idWithPrefix || '-',
      fullName: fullname ?? '-',
      nationality:
        item?.users?.userNational[0]?.nationality?.nationalityTranslate[0]?.name ??
        '-',
      moderator: moderatorTranslate
        ? `${moderatorTranslate?.surname ?? ''} ${moderatorTranslate?.name ?? ''} ${
            moderatorTranslate?.patronymic ?? ''
          }`
        : '-',
      message: item.chat?.messagesCount ?? '-',
      status:
        Number(
          item?.statusStatementSpecial?.find(
            (el) => el?.roleStatusStatement[0]?.roleId === ROLE_ADMIN
          )?.roleStatusStatement[0]?.statusStatement?.code
        ) === STATUS_REVOKE_APPROVED
          ? item?.statusStatementSpecial?.find(
              (el) => el?.roleStatusStatement[0]?.roleId === ROLE_ADMIN
            )?.roleStatusStatement[0]?.statusStatement?.translate[0]?.name
          : item?.statusStatement?.translate[0].name ?? '-',
      number: item?.numberStatement ?? '-',
      createDate: item?.createdAt ? formatDateTimeToRuFormat(item?.createdAt) : '-',
      updateDate: item?.updatedAt ? formatDateTimeToRuFormat(item?.updatedAt) : '-',
      partner: item?.users?.partner?.partnerTranslate[0]?.name ?? '-',
      contractStatus: item?.contracts[0]?.status?.translate[0]?.name ?? '-',
      educationLevel:
        item?.competitiveGroups[0]?.trainingLevel?.trainingLevelTranslate[0]?.name ??
        '-',
      faculty: item?.competitiveGroups[0]?.faculty?.facultyTranslate[0]?.name ?? '-',
      direction:
        item?.competitiveGroups[0]?.directionTraining?.translate[0]?.name ?? '-',
      profile: item?.competitiveGroups[0]?.profile?.translate[0]?.name ?? '-',
      educationForm:
        item?.competitiveGroups[0]?.formEducation?.formEducationTranslate[0]?.name ??
        '-',
      formPayment:
        item?.competitiveGroups[0]?.formPayment?.translate[0]?.name ?? '-',
    })
  })
  return rows
}

const ModeratorStatementsList = () => {
  useEffect(() => {
    dispatch(clearStatementsIdStatus())
  }, [])

  const dispatch = useAppDispatch()
  const statementsList = useAppSelector(selectModeratorStatementsList) || []
  const roleId = useAppSelector(selectRoleIdProfile)

  if (statementsList.length === 0) {
    return <Loader />
  }
  const mappedData = statementMapper(statementsList.data)

  if (mappedData.length === 0) {
    dispatch(setModeratorStatementsPage(1))
  }

  return (
    <div className="moderator-statements-list">
      <TableList
        link={'statements'}
        mappedData={mappedData}
        columns={columnsStatements}
      />
      <Pagination
        lastPage={statementsList.lastPage}
        currentPage={statementsList.currentPage}
        perPage={statementsList.perPage}
        setPage={setModeratorStatementsPage}
        setPerPage={setModeratorStatementsPerPage}
      />
    </div>
  )
}

export default ModeratorStatementsList
