import React, { useEffect, useState } from 'react'

import PassportDataForm from '@components/PassportDataForm'
import useAppDispatch from '@hooks/useAppDispatch'

const PassportDataPage = (passportKeys) => {
  return (
    <div>
      <PassportDataForm {...passportKeys} />
    </div>
  )
}

export default PassportDataPage
