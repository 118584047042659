import React from 'react'

import Loader from 'components/generic/Loader'
import RouteButtonLink from 'components/generic/RouteButtonLink'
import TemplateAuthForm from 'components/TemplateAuthForm'
import useConfirmAccountForm from 'hooks/useConfirmAccountForm'

import terms from '@common/terms'

type EmailConfirmedFormProps = {
  title: string
}

const EmailConfirmedForm = ({ title }: EmailConfirmedFormProps) => {
  const { loading } = useConfirmAccountForm()

  if (loading) {
    return <Loader />
  }

  return (
    <TemplateAuthForm title={title}>
      <div className="auth-form__body auth-form__body--flex-column">
        <p className="auth-form__paragpraph">
          {terms.ACCOUNT_CREATED_AND_CONFIRMED}
        </p>
        <RouteButtonLink
          to="/rus-sign-in"
          theme="success"
          className="auth-form__button auth-form__button--only-child"
        >
          {terms.LOG_IN}
        </RouteButtonLink>
      </div>
    </TemplateAuthForm>
  )
}

export default EmailConfirmedForm
