import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import useAppSelector from './useAppSelector'
import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import { normalizeYearTo4Characters, validateDate } from '@lib/validators'
import { Olympiad, OlympiadsWithOptions } from '@models'
import {
  clearDocs,
  createToast,
  getDisciplineEgeOptions,
  getOlympiads,
  getPersonalData,
  hideModal,
} from '@redux/action-types'
import {
  modalDataSelector,
  selectDisciplineEgeOptions,
  selectPersonalFormData,
} from '@redux/actions/selectors'
import api, {
  AddLgotaEntrantsResponse,
  ResponseOk,
  UploadDocsRespOk,
  UploadDocsResponse,
} from '@services/api'

const useAddOlympiadForm = (scenarioKeys) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  const { register, handleSubmit, setError, getValues, setValue, watch, ...rest } =
    useForm<Olympiad>()

  const { statementId, olympiad, scenarioId } = useAppSelector(
    modalDataSelector
  ) as {
    statementId: number
    olympiad?: Olympiad
    scenarioId: { id: number; value: string }
  }

  const isRequiredOlympiadResults =
    scenarioId.id === scenarioKeys.level_training_bachelor_ino.tdId ||
    scenarioId.id === scenarioKeys.level_training_bachelor.tdId

  useEffect(() => {
    if (statementId) {
      dispatch(getDisciplineEgeOptions(statementId))
    }
  }, [statementId])

  useEffect(() => {
    !personalData && dispatch(getPersonalData())
  }, [])

  const personalData = useAppSelector(selectPersonalFormData)
  const disciplineEgeOptions = useAppSelector(selectDisciplineEgeOptions)
  const isSubject = watch('olympiadResults') === 'subject'

  const fields = {
    year: register('year', {
      required: 'Обязательное',
      onChange: () => {
        setValue('olympiadId', null)
        setValue('olympiadDocId', null)
      },
    }),
    statementId: register('statementId', { required: 'Обязательное' }),
    olympiadId: register('olympiadId', {
      required: 'Обязательное',
      onChange: () => {
        setValue('olympiadDocId', null)
      },
    }),
    olympiadDocId: register('olympiadDocId', { required: 'Обязательное' }),
    number: register('number', {
      required: 'Обязательное. Если серия и номер отсутствуют, напишите "Нет"',
    }),
    dateIssue: register('dateIssue', {
      required: 'Обязательное',
      onChange: ({ target: { value } }) =>
        value && setValue('dateIssue', normalizeYearTo4Characters(value)),
      validate: (value) => {
        return validateDate(value, personalData?.dateBirthday || '')
          ? true
          : terms.DATE_INVALID
      },
    }),
    comment: register('comment'),
    userDocId: register('userDocId'),
    olympiadResults: register('olympiadResults', {
      required: {
        value: isRequiredOlympiadResults,
        message: terms.REQUIRED_FIELD,
      },
    }),
    subject: register('subject', {
      required: {
        value: isRequiredOlympiadResults && isSubject,
        message: terms.REQUIRED_FIELD,
      },
    }),
  }

  const onSubmit = handleSubmit((data: any) => {
    setLoading(true)
    const normalizedData = {
      ...data,
      olympiadId: data.olympiadId?.value || null,
      olympiadDocId: data.olympiadDocId?.value || null,
      docId: data.userDocId,
      year: data.year.year,
      subject: data.subject?.value || '',
      olympiadResults: data.olympiadResults ? data.olympiadResults : '',
    }

    if (olympiad) {
      let status = false
      api.client
        .uploadDocs({
          documents: normalizedData.documents,
          typeDoc: 'ACHIVMENT',
          docId: normalizedData.docId,
        })
        .then(() => {
          status = true
        })
        .catch((response: Exclude<UploadDocsResponse, UploadDocsRespOk>) => {
          const { status } = response

          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
        .finally(() => {
          if (status) {
            api.client
              .editOlympiad(normalizedData)
              .then((response) => {
                const { data } = response as unknown as ResponseOk
                dispatch(createToast(data.message, 'success'))
                dispatch(getOlympiads(Number(statementId)))
                dispatch(clearDocs('ACHIVMENT'))
                dispatch(hideModal())
              })
              .catch((response: Exclude<AddLgotaEntrantsResponse, ResponseOk>) => {
                const { status } = response

                if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
                  const { data } = response
                  dispatch(createToast(data.message, 'danger'))

                  Object.keys(data.errors).forEach((key) => {
                    setError(key as keyof Olympiad, {
                      type: 'manual',
                      message: data.errors[key][0],
                    })
                  })
                } else {
                  dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
                }
              })
              .finally(() => setLoading(false))
          }
        })
        .finally(() => setLoading(false))
    } else {
      api.client
        .addOlympiad(normalizedData)
        .then((response) => {
          const { data } = response as unknown as ResponseOk
          dispatch(getOlympiads(Number(statementId)))
          dispatch(createToast(data.message, 'success'))
          dispatch(hideModal())
        })
        .catch((response: Exclude<AddLgotaEntrantsResponse, ResponseOk>) => {
          const { status } = response

          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))

            Object.keys(data.errors).forEach((key) => {
              setError(key as keyof Olympiad, {
                type: 'manual',
                message: data.errors[key][0],
              })
            })
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
            hideModal()
          }
        })
        .finally(() => setLoading(false))
    }
  })

  return {
    fields,
    onSubmit,
    loading,
    setValue,
    watch,
    getValues,
    disciplineEgeOptions,
    ...rest,
  }
}

export default useAddOlympiadForm
