import React from 'react'

import Button from 'components/generic/Button'
import Input from 'components/generic/Input'
import Link from 'components/generic/Link'
import RouteButtonLink from 'components/generic/RouteButtonLink'
import RouteLink from 'components/generic/RouteLink'
import TemplateAuthForm from 'components/TemplateAuthForm'
import useSignInForm from 'hooks/useSignInForm'

import terms from '@common/terms'

type SignInFormProps = {
  title: string
  moderator?: boolean
}

const SignInForm = ({ title, moderator }: SignInFormProps) => {
  const {
    fields,
    onSubmit,
    loading,
    formState: { errors },
  } = useSignInForm()

  return (
    <TemplateAuthForm title={title} message>
      <form onSubmit={onSubmit}>
        {moderator ? (
          <>
            <div className="auth-form__body auth-form__body-moderator">
              <div className="auth-form__policy">
                <Input
                  placeholder={terms.EMAIL}
                  className="auth-form__input"
                  error={errors.email?.message}
                  {...fields.email}
                />
              </div>
              <div className="auth-form__fields">
                <Input
                  placeholder={terms.PASSWORD}
                  enableSwitcher={true}
                  className="auth-form__input"
                  error={errors.password?.message}
                  {...fields.password}
                />
              </div>
            </div>
            <div className="auth-form__buttons">
              <Button
                theme="success"
                className="auth-form__button"
                type="submit"
                disabled={loading}
              >
                {terms.LOG_IN}
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="auth-form__body">
              <div className="auth-form__policy">
                <div className="auth-form__image">
                  <img
                    src={require('../../assets/images/SignInImage.png')}
                    alt=""
                    style={{ width: '100%', height: '100%', marginBottom: '15px' }}
                  />
                </div>
                <p className="auth-form__paragraph">
                  {terms.REVIEW_INFORMATION_ABOUT_ADMISSION + ' '}
                  <RouteLink to="/sign-up">
                    {terms.COMPLETE_REGISTRATION_PROCEDURE}
                  </RouteLink>
                </p>
                <p className="auth-form__paragraph">
                  <Link
                    href="https://abiturient.spbu.ru/programs/bakalavriat/"
                    target="_blank"
                  >
                    {terms.USER_MANUAL_FOR_APPLICANTS}
                  </Link>
                </p>
                {/*<p className="auth-form__paragraph">*/}
                {/*  <Link*/}
                {/*    href="/documents/User manual for Russian applicants.pdf"*/}
                {/*    target="_blank"*/}
                {/*  >*/}
                {/*    {terms.USER_MANUAL}*/}
                {/*  </Link>*/}
                {/*</p>*/}
                <p className="auth-form__paragraph">
                  {terms.IF_ALREADY_HAVE_ACCOUNT}
                </p>
              </div>
              <div className="auth-form__fields">
                <Input
                  placeholder={terms.EMAIL}
                  className="auth-form__input"
                  error={errors.email?.message}
                  {...fields.email}
                />
                <Input
                  placeholder={terms.PASSWORD}
                  enableSwitcher={true}
                  className="auth-form__input"
                  error={errors.password?.message}
                  {...fields.password}
                />
                <RouteLink to="/reset-password" className="auth-form__question">
                  {terms.FORGOT_PASSWORD}
                </RouteLink>
              </div>
            </div>
            <div className="auth-form__buttons">
              <RouteButtonLink to="/sign-up" className="auth-form__button">
                {terms.REGISTER}
              </RouteButtonLink>
              <Button
                theme="success"
                className="auth-form__button"
                type="submit"
                disabled={loading}
              >
                {terms.LOG_IN}
              </Button>
            </div>
          </>
        )}
      </form>
    </TemplateAuthForm>
  )
}

export default SignInForm
