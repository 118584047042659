import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { NavLink, useParams } from 'react-router-dom'

import terms from '@common/terms'
import DirectionsTrainingElementStatement from '@components/DirectionsTrainingList/DirectionsTrainingElementStatement'
import FillingStatementsForm from '@components/FillingStatementsForm'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useDirectionsTrainingElement from '@hooks/useDirectionsTrainingElement'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import useReadOnlyFillingStatements from '@hooks/useReadOnlyFillingStatements'
import useTrialsDirectionTrainingList from '@hooks/useTrialsDirectionTrainingList'
import {
  ChosenTrials,
  IndexForSelect,
  ViewerDirectionsListStatements,
} from '@models'
import {
  setDirectionsListStatements,
  getDirectionsListStatements,
  getTabEge,
} from '@redux/action-types'
import {
  selectFilteredDirectionsList,
  selectLocalizedViewerProfile,
  selectStatementsElement,
} from '@redux/actions/selectors'
import {
  selectDirectionsListStatements,
  selectTabEgeList,
} from '@redux/actions/selectors/statements'
import Button from '@ui/Button'

const DirectionsTrainingListPage = (scenarioKeys) => {
  const { statementId } = useParams()
  const { order } = useDirectionsTrainingElement()
  const { fixDirection } = useReadOnlyFillingStatements()
  const dispatch = useAppDispatch()
  const profileData = useAppSelector(selectLocalizedViewerProfile)
  const [isNationalityBy, setIsNationalityBy] = useState(false)
  const DirectionsListStatements = useAppSelector(selectDirectionsListStatements)
  const statementEl = useAppSelector(selectStatementsElement)

  useEffect(() => {
    if (statementId) {
      dispatch(getDirectionsListStatements(Number(statementId)))
      dispatch(getTabEge(Number(statementId)))
    }
  }, [])

  useEffect(() => {
    if (profileData)
      setIsNationalityBy(profileData?.userNational[0]?.nationality?.code === '112')
  })

  const reorder = (
    list: ViewerDirectionsListStatements[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination || DirectionsListStatements === null) {
      return
    }
    const items = reorder(
      DirectionsListStatements,
      result.source.index,
      result.destination.index
    )
    const itemsId = items.map((el) => {
      return el.id
    })
    order(itemsId)
    dispatch(setDirectionsListStatements(items))
  }

  const { onNextPageNavigate } = useFillingStatementsRoutesContext()
  const tabEgeData = useAppSelector(selectTabEgeList)
  let chosenTrials: ChosenTrials[] = []
  let indexForSelect: IndexForSelect[] = []
  let count: number | null | undefined = null

  DirectionsListStatements?.map((el, index) => {
    count = el.selectCount

    indexForSelect.push({
      label: String(index + 1),
      value: index + 1,
    })
  })

  if (count != null) {
    indexForSelect = indexForSelect.slice(0, -count)
  }

  const { selectTrials } = useTrialsDirectionTrainingList()

  return (
    <FillingStatementsForm
      onSubmit={(e) => {
        e.preventDefault()
        selectTrials(chosenTrials)
        //onNextPageNavigate()
      }}
    >
      <section className={'directions-training-list-page'}>
        <NavLink to={'create'} onClick={(e) => fixDirection && e.preventDefault()}>
          <Button
            type="button"
            theme={
              !(DirectionsListStatements && DirectionsListStatements.length > 0)
                ? 'success'
                : undefined
            }
          >
            {terms.CHOOSE_DIRECTION}
          </Button>
        </NavLink>

        {DirectionsListStatements?.length !== 0 &&
          statementEl?.trajectoryId === 1 &&
          (statementEl?.scenarioId === scenarioKeys.asperatnuta_rf.tdId ||
            statementEl?.scenarioId ===
              scenarioKeys.level_training_preparation.tdId) && (
            <p style={{ marginTop: '20px' }}>
              Необходимо выбрать один из предложенных иностранных языков, кроме
              “Русский язык (иностранный)”
            </p>
          )}

        {DirectionsListStatements?.length !== 0 &&
          statementEl?.trajectoryId === 2 &&
          statementEl?.scenarioId >= scenarioKeys.asperatnuta_rf.tdId &&
          statementEl?.scenarioId <=
            scenarioKeys.level_training_preparation.tdId && (
            <p style={{ marginTop: '20px' }}>
              Рекомендовано выбирать “Русский язык (иностранный)” в качестве
              вступительного испытания
            </p>
          )}

        {DirectionsListStatements && DirectionsListStatements.length > 0 && (
          <div className={'mt-24'}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {DirectionsListStatements.map((el, index) => (
                      <Draggable
                        key={el.id}
                        draggableId={el.id.toString()}
                        index={index}
                        isDragDisabled={true} //fixDirection было выключено потому что ломало заполнение инпутов todo drag droppable
                      >
                        {(provided) => (
                          <div
                            style={{ height: '50px', background: 'red' }}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <DirectionsTrainingElementStatement
                              readOnly={fixDirection}
                              item={el}
                              index={index + 1}
                              selectNumberProps={indexForSelect}
                              tabEge={tabEgeData}
                              chosenTrials={chosenTrials}
                              isNationalityBy={isNationalityBy}
                              scenarioKeys={scenarioKeys}
                              acceptId={
                                DirectionsListStatements.find(
                                  (el) => el.pivot.accept
                                )?.id
                              }
                              first={
                                DirectionsListStatements[index - 1]
                                  ? DirectionsListStatements[index - 1].id
                                  : true
                              }
                              last={
                                DirectionsListStatements[index + 1]
                                  ? DirectionsListStatements[index + 1].id
                                  : true
                              }
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        )}
      </section>
    </FillingStatementsForm>
  )
}

export default DirectionsTrainingListPage
