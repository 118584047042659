import { useForm } from 'react-hook-form'

import { AppModal } from 'types/custom-types'

import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import useDownloadDocument from './useDownloadDocument'
import { mimeTypes } from '@common/manual'
import terms from '@common/terms'
import uploadDocsTypes from '@common/uploadDocsTypes'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import { LgotaSpecialConditions } from '@models'
import { createToast, showModal } from '@redux/action-types'
import {
  getAllLgota,
  getAllPreemptive,
  selectStatementsElement,
  selectUserIdProfile,
  selectUploadedLgotaDocsState,
} from '@redux/actions/selectors'
import { getUserLgota, lgotaSlice } from '@redux/reducers'
import api, { ResponseOk } from '@services/api'
import { base64toFile, downloadFile } from '@utils/common'

type DocType = 'PREEMPTIVE' | 'PRIVILEGES'

const useHandlerLgota = (setListLgotaReason, setListLgotaSpecial) => {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    getValues,
    setValue,
    ...rest
  } = useForm<LgotaSpecialConditions>()

  const dispatch = useAppDispatch()

  const statement = useAppSelector(selectStatementsElement)
  const userId = useAppSelector(selectUserIdProfile)
  const lgotaDocs = useAppSelector(getAllLgota)
  const preemptiveRightDocs = useAppSelector(getAllPreemptive)
  const { downloadDocument } = useDownloadDocument()

  const {
    addUploadedLgotaDocsState,
    deleteUploadedLgotaDocsState,
    clearUploadedDocsState,
  } = lgotaSlice.actions

  const getUploadedLgotaDocs = (typeDoc, docId) => api.client.getDocs(typeDoc, docId)

  const onDelete = (id: number) => {
    if (!(statement && userId)) {
      return
    }
    api.client
      .userLgotaDelete({ docId: id, statementId: statement.id, userId: userId })
      .then((response) => {
        const { data } = response as unknown as ResponseOk
        dispatch(createToast(data.message, 'success'))
        dispatch(getUserLgota({ statementId: statement.id, userId: userId }))
      })
      .catch((response: ResponseOk) => {
        const { data } = response
        dispatch(createToast(data.message, 'danger'))
      })
  }

  const addLgota = (name: AppModal) => {
    dispatch(showModal({ name }))
  }

  const onEdit = (type: DocType, id: string) => {
    const dataForEdit =
      type === 'PRIVILEGES'
        ? lgotaDocs.find((el) => el.id === id)
        : preemptiveRightDocs.find((el) => el.id === id)

    dispatch(showModal({ name: type, data: dataForEdit }))
  }

  const onDownload = (docId: number) => {
    downloadDocument('LGOTA', mimeTypes.pdf, 'pdf', docId, '')
  }

  const onDownloadPreemptiveRight = (docId: number) => {
    downloadDocument('PREEMPTIVE_RIGHT', mimeTypes.pdf, 'pdf', docId, '')
  }

  const haveSpecialConditions = watch('haveSpecialConditions')
  const haveReasonConditions = watch('haveReasonConditions')

  const onUploadDocsSpecialCondition = (statementId, docs, typeDoc) => {
    if (!docs) return
    const formData = new FormData()

    formData.append('typeDoc', typeDoc)
    formData.append('statement_id', statementId)
    for (const doc of docs) {
      formData.append('documents[]', doc)
    }
    api.client
      .postLgotaSpecialDocs(formData)
      .then((response) => {
        const { data } = response as unknown as ResponseOk
        dispatch(createToast(data.message, 'success'))
      })
      .catch((response: ResponseOk) => {
        const { data } = response
        dispatch(createToast(data.message, 'danger'))
      })
  }

  const onDeleteDocs = (typeDoc: string, docId: number, fileId: string) => {
    api.client
      .deleteDoc({ typeDoc: uploadDocsTypes[typeDoc], docId })
      .then(() => dispatch(createToast(terms.FILE_DELETED, 'success')))
      .catch(() => dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger')))
      .finally(() => {
        dispatch(deleteUploadedLgotaDocsState({ id: fileId }))
        dispatch(
          getUserLgota({
            statementId: Number(statement?.id),
            userId: Number(userId),
          })
        )
      })
  }

  const onDownloadDocs = (docId: number, typeDoc: string, filename?: string) => {
    api.client
      .getDocs(uploadDocsTypes[typeDoc], docId)
      .then((resp) =>
        base64toFile(resp.data.document || '', 'application/pdf', 'pdf', filename)
      )
      .then(downloadFile)
      .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')))
  }

  const fields = {
    haveSpecialConditions: register('haveSpecialConditions', {
      onChange: ({ target: { value } }) => {
        value && setValue('commentSpecialConditions', undefined)
        value && clearErrors('commentSpecialConditions')
        value && setListLgotaSpecial([])
      },
    }),
    commentSpecialConditions: register('commentSpecialConditions', {
      required: {
        value: haveSpecialConditions,
        message: terms.REQUIRED_FIELD,
      },
    }),
    fileSpecialCondition: register('fileSpecialCondition', {
      required: { value: haveSpecialConditions, message: terms.REQUIRED_FIELD },
    }),
    haveReasonConditions: register('haveReasonConditions', {
      onChange: ({ target: { value } }) => {
        value && setValue('commentReasonConditions', undefined)
        value && clearErrors('commentReasonConditions')
        value && setListLgotaReason([])
      },
    }),
    fileReasonCondition: register('fileReasonCondition', {
      required: { value: haveReasonConditions, message: terms.REQUIRED_FIELD },
    }),
    presidentialDecree268: register('presidentialDecree_268'),
    filePresidentialDecree268: register('filePresidentialDecree_268', {
      required: {
        value: watch('presidentialDecree_268'),
        message: terms.REQUIRED_FIELD,
      },
    }),
    presidentialDecree268B: register('presidentialDecree_268B'),
    filePresidentialDecree268B: register('filePresidentialDecree_268B', {
      required: {
        value: watch('presidentialDecree_268B'),
        message: terms.REQUIRED_FIELD,
      },
    }),

    decree_245_070423: register('decree_245_070423'),
    decree_231_010323: register('decree_231_010323'),
    decree_528_030423: register('decree_528_030423'),
  }
  const { onNextPageNavigate } = useFillingStatementsRoutesContext()

  const onSubmit = handleSubmit((data: any) => {
    if (!(statement && userId)) {
      return
    }

    const normalizedData: LgotaSpecialConditions = {
      ...data,
      statementId: statement.id,
    }

    data.fileReasonCondition?.length &&
      onUploadDocsSpecialCondition(
        statement?.id,
        data.fileReasonCondition,
        'lgota_reason_conditions'
      )

    data.fileSpecialCondition?.length &&
      onUploadDocsSpecialCondition(
        statement?.id,
        data.fileSpecialCondition,
        'lgota_special_conditions'
      )
    data.filePresidentialDecree_268?.length &&
      onUploadDocsSpecialCondition(
        statement?.id,
        data.filePresidentialDecree_268,
        'presidential_decree_268'
      )
    data.filePresidentialDecree_268B?.length &&
      onUploadDocsSpecialCondition(
        statement?.id,
        data.filePresidentialDecree_268B,
        'presidential_decree_268_b'
      )

    api.client
      .postLgotaSpecialConditions(normalizedData)
      .then((response) => {
        const { data } = response as unknown as ResponseOk
        dispatch(createToast(data.message, 'success'))
        onNextPageNavigate()
      })
      .catch((response: ResponseOk) => {
        const { data } = response
        dispatch(createToast(data.message, 'danger'))
      })
  })
  return {
    setValue,
    fields,
    haveSpecialConditions,
    onSubmit,
    onDelete,
    onEdit,
    addLgota,
    onDownload,
    watch,
    haveReasonConditions,
    addUploadedLgotaDocsState,
    onDeleteDocs,
    onDownloadDocs,
    onDownloadPreemptiveRight,
    deleteUploadedLgotaDocsState,
    clearUploadedDocsState,
    onUploadDocsSpecialCondition,
    getUploadedLgotaDocs,
    ...rest,
  }
}

export default useHandlerLgota
