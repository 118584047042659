import React, { PropsWithChildren, useEffect, useState } from 'react'

import ExclamationMarkIcon from '@assets/images/Exclamation-mark.svg'
import api from '@services/api'

type TemplateFormProps = PropsWithChildren<{
  title: string
  data: any
  message?: boolean
}>

export const TemplateInoAuthForm = ({
  title,
  children,
  message,
  data,
}: TemplateFormProps) => {
  return (
    <div className="template-auth-form">
      <h1 className="template-auth-form__title">{title}</h1>
      {message && (
        <>
          <div className="auth-form-row-message">
            <div className="template-auth-form__message template-main-text">
              <div className="template-auth-form__message-item">
                <ExclamationMarkIcon width={16} height={17} />
              </div>
              <div className="template-auth-form__message-item">
                <p>{data['leftTextTitle']}</p>
                <p className="text-wrap-view">{data['leftSideText']}</p>
              </div>
            </div>
            <div className="template-auth-form__message template-main-text">
              <div className="template-auth-form__message-item">
                <ExclamationMarkIcon width={16} height={17} />
              </div>
              <div className="template-auth-form__message-item">
                <p>{data['rightTextTitle']}</p>
                <p className="text-wrap-view">{data['rightSideText']}</p>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="auth-form-row-message">{children}</div>
    </div>
  )
}

export default TemplateInoAuthForm
