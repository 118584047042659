import React, { useEffect } from 'react'

import { ROLE_SUPPORT, ROLE_USER } from '@common/constants'
import AccordionTab from '@components/AccordionTab'
import AdminUserListElement from '@components/AdminUserListElement'
import AdminUserListFilter from '@components/AdminUserListFilter'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { getAdminUserList } from '@redux/action-types/admin'
import {
  selectAdminUserList,
  selectRoleIdProfile,
  setAdminPageUsers,
  setAdminPerPageUsers,
} from '@redux/actions/selectors'
import Loader from '@ui/Loader'
import Pagination from '@ui/Pagination'

const UsersPage = () => {
  const dispatch = useAppDispatch()
  const roleId = useAppSelector(selectRoleIdProfile)

  useEffect(() => {
    dispatch(
      getAdminUserList({
        page: 1,
        perPage: 10,
        role: ROLE_USER,
        support: roleId === ROLE_SUPPORT,
      })
    )
  }, [])

  const adminUserList = useAppSelector(selectAdminUserList)

  if (!adminUserList) {
    return <Loader />
  }

  if (adminUserList.data.length === 0) {
    dispatch(setAdminPageUsers(1))
  }

  return (
    <main className="moderator-contract-page">
      <h1 className="moderator-contract-page__title">Все пользователи</h1>
      <AccordionTab title={'Фильтры'}>
        <AdminUserListFilter />
      </AccordionTab>
      {adminUserList?.data?.map((item, index) => (
        <AdminUserListElement item={item} key={index} />
      ))}
      <Pagination
        lastPage={adminUserList.lastPage}
        currentPage={adminUserList.currentPage}
        perPage={adminUserList.perPage}
        setPage={setAdminPageUsers}
        setPerPage={setAdminPerPageUsers}
      />
    </main>
  )
}

export default UsersPage
