import React from 'react'
import { Navigate } from 'react-router-dom'

import { ROLE_USER } from '@common/constants'
import useAppSelector from '@hooks/useAppSelector'
import useAuthInitialization from '@hooks/useAuthInitialization'
import { selectRoleIdProfile } from '@redux/actions/selectors'
import Loader from '@ui/Loader'

const ModeratorAuth = ({ children }: { children: JSX.Element }) => {
  const { token, loading } = useAuthInitialization()

  const roleId = useAppSelector(selectRoleIdProfile)

  if (loading) {
    return <Loader />
  }

  if (!token) {
    return <Navigate to="/admin/sign-in" />
  }

  if (token && !roleId) {
    return <Loader />
  }

  if (roleId === ROLE_USER) {
    return <Navigate to="/" />
  }

  return children
}

export default ModeratorAuth
