import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'

import uploadDocsTypes, { UploadDocKey } from '@common/uploadDocsTypes'
import {
  Gender,
  MaritalStatus,
  ViewerPasswords,
  MethodApplication,
  ProfileDataResponse,
  NationalPage,
  PersonalDataResponse,
  RelationDegree,
  TypeIdentityDocument,
  PersonalData,
  ProfileSectionKey,
  Passport,
  PassportResponse,
  FormEducation,
  LevelEducation,
  TypeDocEducation,
  EducationData,
  EducationResponse,
  VisaCountryPage,
  Residence,
  ResidenceResponse,
  PhoneCodePage,
  FacultyType,
  TargetResponseOption,
  Locations,
  StudentsFilterFormData,
  LegalRepresentative,
  VisaCity,
  VisaResponse,
  Visa,
  DirectionTrainingPage,
  TypeIdentityDocumentRus,
  Partner,
  PartnerPage,
  SubjectsRussia,
} from '@models'
import {
  getLevelRussianLanguageProficiency,
  getSpecialConditions,
} from '@redux/action-types'
import { DownloadDocdResponse, ResponseExpectationFailed } from '@services/api'
import {
  ResponseNotAcceptable,
  ResponseOk,
  ResponseUnprocessableEntity,
} from '@services/api/types'

/** @name PROFILE */

export default {
  /** @method GET */
  getVisaCountry: (value: string = '', page: number = 1) => {
    const query = new URLSearchParams({ page: String(page), searchText: value })
    return axios.get<VisaCountryPage>(`/visaCountry?${query.toString()}`)
  },

  getVisa: () => axios.get<VisaResponse[]>(`/userVisa`),
  getSpecialConditions: () => axios.get<VisaResponse[]>(`/userSpecialConditions`),

  getVisaDoc(params) {
    return axios.get('/downloadTemplateDoc', { params })
  },
  getSpecialConditionsDoc(params) {
    return axios.get('/downloadTemplateDoc', { params })
  },

  /** @method GET */
  getVisaCity: (countryId: number) =>
    axios.get<VisaCity[]>(`/visaCity?country_visa_id=${countryId}`),

  /** @method GET */
  checkProfileStatus: () => axios.get<CheckProfileStatusResponse>(`/checkAnketa`),

  /** @method GET */
  getPassport: () => axios.get<PassportResponse[]>(`/userPassport`),

  /** @method GET */
  getPersonalData: () => axios.get<PersonalDataResponse>(`/personalData`),

  getLegalRepresentative: () =>
    axios.get<PersonalDataResponse>(`/userRepresentative`),

  /** @method GET */
  getProfile: () => axios.get<ProfileDataResponse>('/profile'),

  /** @method GET */
  getRelationDegrees: () => axios.get<RelationDegree[]>('/relationDegrees'),

  /** @method GET */
  getNationals: (value: string = '', page: number = 1) => {
    const query = new URLSearchParams({ page: String(page), searchText: value })
    return axios.get<NationalPage>(`/nationals?${query.toString()}`)
  },

  /** @method GET */
  getNationalsIno: (value: string = '', page: number = 1) => {
    const query = new URLSearchParams({ page: String(page), searchText: value })
    return axios.get<NationalPage>(`/nationals-ino?${query.toString()}`)
  },

  /** @method GET */
  getNationalsRus: (value: string = '', page: number = 1) => {
    const query = new URLSearchParams({ page: String(page), searchText: value })
    return axios.get<NationalPage>(`/nationals-rus?${query.toString()}`)
  },

  /** @method GET */
  getLocations: (value: string = '', page: number = 1) => {
    const query = new URLSearchParams({ search: value })
    return axios.get<Locations>(`/locations?${query}`)
  },

  /** @method GET */
  getSubjectsRussia: (value: string = '', page: number = 1) => {
    const query = new URLSearchParams({ search: value })
    return axios.get<SubjectsRussia>(`/subjects-russia?${query}`)
  },

  /** @method GET */
  getKladr: (value: string = '', page: number = 1) => {
    const query = new URLSearchParams({ string: value, page: String(page) })
    return axios.get<any>(`/kladr/search/address?${query}`)
  },

  /** @method GET */
  getKladrRegion: (value: string = '', page: number = 1) => {
    const query = new URLSearchParams({
      string: value,
      page: String(page),
    })
    return axios.get<any>(`/kladr/search/region?${query}`)
  },

  /** @method GET */
  getKladrDistrict: (value: string = '', regionCode, page: number = 1) => {
    const query = new URLSearchParams({
      string: value,
      region_code: regionCode,
      page: String(page),
    })
    return axios.get<any>(`/kladr/search/district?${query}`)
  },

  /** @method GET */
  getKladrCity: (value: string = '', regionCode, districtCode, page: number = 1) => {
    const query = new URLSearchParams({
      string: value,
      page: String(page),
      region_code: regionCode,
    })
    if (districtCode) {
      query.append('district_code', districtCode)
    }
    return axios.get<any>(`/kladr/search/locality?${query}`)
  },

  /** @method GET */
  getKladrStreet: (value: string = '', localityCode, page: number = 1) => {
    const query = new URLSearchParams({
      string: value,
      page: String(page),
      locality_code: localityCode,
    })
    return axios.get<any>(`/kladr/search/street?${query}`)
  },

  /** @method GET */
  getKladrHouse: (
    value: string = '',
    streetCode,
    localityCode,
    page: number = 1
  ) => {
    const streetOrLocality: { locality_code?: string; street_code?: string } =
      streetCode === '0000'
        ? { locality_code: localityCode }
        : { street_code: streetCode }
    const query = new URLSearchParams({
      string: value,
      page: String(page),
      ...streetOrLocality,
    })
    return axios.get<any>(`/kladr/search/house?${query}`)
  },

  /** @method GET */
  getPhoneCodes: (value: string = '', page: number = 1) => {
    const query = new URLSearchParams({
      page: String(page),
      searchText: value.replace('+', ''),
    })
    return axios.get<PhoneCodePage>(`/phoneCode?${query.toString()}`)
  },

  /** @method GET */
  getGenders: () => axios.get<Gender[]>(`/gender`),

  /** @method GET */
  getWorks: () => axios.get<Gender[]>(`/works`),

  /** @method GET */
  getMethodApplication: () => axios.get<MethodApplication[]>(`/methodApplication`),

  /** @method GET */
  getMaritalStatus: (gender: number) =>
    axios.get<MaritalStatus[]>(`/maritalStatus?gender=${gender}`),

  /** @method GET */
  getTypeIdentityDocument: () =>
    axios.get<TypeIdentityDocument[]>(`/typeIdentityDocument`),

  /** @method GET */
  getTypeIdentityDocumentRus: () =>
    axios.get<TypeIdentityDocumentRus[]>(`/typeIdentityDocumentRus`),

  /** @method GET */
  getPreviousEduForMastersDocument: () =>
    axios.get<TypeIdentityDocument[]>(`/previous-edu-for-masters`),

  /** @method GET */
  getViewerProfilePhoto: () => axios.get<{ photo: string }>(`/userPhoto`),

  /** @method GET */
  getProfileEducation: () => axios.get<EducationResponse[]>(`/userProfileEducation`),

  /** @method GET */
  getFormEducation: (data?: StudentsFilterFormData) => {
    const params = new URLSearchParams()
    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key]) {
          params.append(key, snakeData[key].value || snakeData[key])
        }
      })
    }
    return axios.get<FormEducation[]>(`/formEducation`, {
      params: params,
    })
  },

  /** @method GET */
  getFaculty: (data?: StudentsFilterFormData) => {
    const params = new URLSearchParams()
    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key]) {
          params.append(key, snakeData[key].value || snakeData[key])
        }
      })
    }
    return axios.get<FacultyType[]>(`/faculty`, {
      params: params,
    })
  },

  /** @method GET */
  getDirectionTrainingsList: (data?: StudentsFilterFormData) => {
    const params = new URLSearchParams()
    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key]) {
          params.append(key, snakeData[key].value || snakeData[key])
        }
      })
    }
    return axios.get<any>(`/direction-trainings`, {
      params: params,
    })
  },

  /** @method GET */
  getHousingUsePaymentsOptions: () =>
    axios.get<TargetResponseOption>(`/target-contract/housing-use-payments`),

  /** @method GET */
  getPaymentForEdusOptions: () =>
    axios.get<TargetResponseOption>(`/target-contract/payment-for-edus`),

  /** @method GET */
  getFarePaymentsOptions: () =>
    axios.get<TargetResponseOption>(`/target-contract/fare-payments`),

  /** @method GET */
  getMonetaryPaymentsOptions: () =>
    axios.get<TargetResponseOption>(`/target-contract/monetary-payments`),

  /** @method GET */
  getResidence: () => axios.get<ResidenceResponse>(`/userAddress`),

  /** @method GET */
  getLevelEducation: (typeEducationDocumentId?: number) =>
    axios.get<LevelEducation[]>(
      `/levelEducation?type_education_document_id=${typeEducationDocumentId || ''}`
    ),

  /** @method GET */
  getDirectionTrainings: (value: string = '', page: number = 1) => {
    const query = new URLSearchParams({ page: String(page), searchText: value })
    return axios.get<DirectionTrainingPage>(
      `/levelLastEducation?${query.toString()}`
    )
  },

  /** @method GET */
  getTypeDocEducation: () => axios.get<TypeDocEducation[]>(`/typeDocEducation`),

  /** @method GET */
  getLevelRussianLanguageProficiency: () =>
    axios.get<TypeDocEducation[]>(`/level-rus`),

  /** @method GET */
  getWhereStudyRussian: () => axios.get<TypeDocEducation[]>(`/study-russian`),

  /** @method GET */
  getYouFindAboutPossibilityStudying: () =>
    axios.get<TypeDocEducation[]>(`/training-opportunities`),

  /** @method POST */
  updateProfileEducation: (data: EducationData) =>
    axios.post<EducationData, UpdateEducationResponse>(
      '/userProfileEducation',
      snakecaseKeys(data)
    ),

  /** @method POST */
  updateProfileAdditionalInfo: (data: any) =>
    axios.post('/personalData', snakecaseKeys(data)),

  /** @method POST */
  updatePassword: (data: ViewerPasswords) =>
    axios.post<ViewerPasswords, UpdatePasswordResponse>(
      '/updatePassword',
      snakecaseKeys(data)
    ),

  /** @method POST */
  updatePassort: (data: Passport) =>
    axios.post<Passport, UpdatePassportResponse>(
      '/userPassport',
      snakecaseKeys(data)
    ),

  /** @method POST */
  updateVisa: (data: Visa) =>
    axios.post<Visa, UpdateVisaResponse>('/userVisa', snakecaseKeys(data)),

  /** @method POST */
  updateSpecialConditions: (data: Visa) =>
    axios.post<Visa, UpdateVisaResponse>(
      '/userSpecialConditionsForm',
      snakecaseKeys(data)
    ),

  /** @method POST */
  updateLegalRepresentative: (data: LegalRepresentative) => {
    return axios.post<LegalRepresentative, UpdateLegalReprResponse>(
      '/userRepresentative',
      snakecaseKeys(data)
    )
  },

  /** @method POST */
  updatePersonalData: (data: PersonalData) =>
    axios.post<PersonalData, UpdatePersonalDataResponse>(
      '/personalData',
      snakecaseKeys(data)
    ),

  /** @method POST */
  updateResidence: (data: Residence) =>
    axios.post<Residence, UpdateResidenceResponse>(
      '/userAddress',
      snakecaseKeys(data)
    ),

  /** @method POST */
  uploadProfileImage: (photo: Blob | string) => {
    const data = new FormData()
    data.append('photo', photo)
    return axios.post<string>('/userPhoto', data)
  },

  /** @method GET */
  getPartnersList: (value: string = '', page: number = 1, perPage: number = 15) => {
    const query = new URLSearchParams({
      page: String(page),
      searchText: value,
      per_page: String(perPage),
    })
    return axios.get<PartnerPage>(`/partner?${query.toString()}`)
  },
}

// update password

export type UpdatePasswordResponse =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<ViewerPasswords>

// update legal representative
export type UpdateLegalReprResponse =
  | ResponseOk
  | ResponseExpectationFailed
  | ResponseUnprocessableEntity<LegalRepresentative>

// update passport
export type UpdatePassportResponse =
  | ResponseOk
  | ResponseUnprocessableEntity<Passport>

// update visa
export type UpdateVisaResponse = ResponseOk | ResponseUnprocessableEntity<Visa>

// update personal data
export type UpdatePersonalDataResponse =
  | ResponseOk
  | ResponseExpectationFailed
  | ResponseUnprocessableEntity<PersonalData>

// update education
export type UpdateEducationResponse =
  | ResponseOk
  | ResponseUnprocessableEntity<EducationData>

// update residence
export type UpdateResidenceResponse =
  | ResponseOk
  | ResponseUnprocessableEntity<Residence>

// check profile status
export type CheckProfileStatusUnprocessableEntity = {
  success: false
  message: { [key in ProfileSectionKey]?: string }
}
export type CheckProfileStatusOk = { success: true; message: string }

export type CheckProfileStatusResponse =
  | CheckProfileStatusOk
  | CheckProfileStatusUnprocessableEntity
