import { useState } from 'react'
import { useForm } from 'react-hook-form'

import HttpStatusCode from '@common/httpStatusCodes'
import regexp from '@common/regexp'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import { ViewerPasswords } from '@models'
import { createToast } from '@redux/action-types'
import api, { ResponseOk, UpdatePasswordResponse } from '@services/api'

const useChangePasswordForm = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  const { register, handleSubmit, setError, getValues, ...rest } = useForm<
    ViewerPasswords
  >({ mode: 'all' })

  const fields = {
    passwordCurrent: register('passwordCurrent', {
      required: terms.REQUIRED_FIELD,
    }),
    passwordNew: register('passwordNew', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: regexp.password,
        message: terms.PASSWORD_MIXED,
      },
      minLength: {
        value: 6,
        message: terms.PASSWORD_MIN_LENGTH,
      },
    }),
    passwordNewConfirm: register('passwordNewConfirm', {
      required: terms.REQUIRED_FIELD,
      validate: (value) => {
        return value === getValues('passwordNew') || terms.PASSWORDS_MUST_MATCH
      },
    }),
  }

  const onSubmit = handleSubmit((data: any) => {
    setLoading(true)
    api.client
      .updatePassword(data)
      .then((response) => {
        const { data } = response as ResponseOk
        dispatch(createToast(data.message, 'success'))
      })
      .catch((response: Exclude<UpdatePasswordResponse, ResponseOk>) => {
        const { status } = response

        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))

          Object.keys(data.errors).forEach((key) => {
            setError(key as keyof ViewerPasswords, {
              type: 'manual',
              message: data.errors[key][0],
            })
          })
        } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
      .finally(() => setLoading(false))
  })

  return {
    fields,
    onSubmit,
    getValues,
    loading,
    ...rest,
  }
}

export default useChangePasswordForm
