import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'

import { HIGHER_EDUCATION, WITHOUT_PREV_EDUCATION } from '@common/constants'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useInitializeInputs from '@hooks/useInitializeInputs'
import useInitializeSelect from '@hooks/useInitializeSelect'
import usePaginatedDirectionTraining from '@hooks/usePaginatedDirectionTraining'
import usePaginatedNationals from '@hooks/usePaginatedNationals'
import usePaginatedNationalsIno from '@hooks/usePaginatedNationalsIno'
import usePaginatedNationalsRus from '@hooks/usePaginatedNationalsRus'
import { EducationData, SelectOption } from '@models'
import {
  getForeignLanguage,
  getFormEducation,
  getLevelEducation,
  getPreviousEduForMastersDocument,
  getProfileEducation,
  getTypeDocEducation,
} from '@redux/action-types'
import {
  selectForeignLanguage,
  selectFormEducationOptions,
  selectLevelEducationOptions,
  selectPreviousEduForMasters,
  selectTypeEducationDocumentOptions,
  selectViewerProfileEducation,
} from '@redux/actions/selectors'

type useEducationDataFormSelectorsProps = {
  setValue: UseFormSetValue<EducationData>
  watch: UseFormWatch<EducationData>
  isHigherEducation: boolean
  setIsHigherEducation: Dispatch<SetStateAction<boolean>>
}

const useEducationDataFormInitialization = ({
  setValue,
  watch,
  isHigherEducation,
  setIsHigherEducation,
}: useEducationDataFormSelectorsProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    !educationData && dispatch(getProfileEducation())
    !formEducationOptions.length && dispatch(getFormEducation())
    dispatch(getTypeDocEducation())
  }, [])

  const [longForeignLanguage, setIsLongForeignLanguage] = useState<boolean>(true)
  if (longForeignLanguage) {
    setIsLongForeignLanguage(false)
    dispatch(getForeignLanguage())
  }

  const nationalSelectProps = usePaginatedNationals()
  const directionTrainingSelectProps = usePaginatedDirectionTraining()
  const nationalSelectPropsRus = usePaginatedNationalsRus()
  const nationalSelectPropsIno = usePaginatedNationalsIno()
  const typeEducationDocumentId = watch('typeEducationDocumentId') as unknown as
    | SelectOption
    | undefined

  const educationData = useAppSelector(selectViewerProfileEducation)
  const levelEducationOptions = useAppSelector(selectLevelEducationOptions)
  const formEducationOptions = useAppSelector(selectFormEducationOptions)
  const typeEducationDocumentOptions = useAppSelector(
    selectTypeEducationDocumentOptions
  )
  const foreignLanguageOptions = useAppSelector(selectForeignLanguage)
  const previousEduForMastersOptions = useAppSelector(selectPreviousEduForMasters)

  useEffect(() => {
    if (typeEducationDocumentId?.value) {
      setIsHigherEducation(
        WITHOUT_PREV_EDUCATION.includes(typeEducationDocumentId.value)
      )
      dispatch(getLevelEducation(typeEducationDocumentId.value))
    }
  }, [typeEducationDocumentId?.value])

  useEffect(() => {
    if (levelEducationOptions.length === 1) {
      setValue('levelEducationId', levelEducationOptions[0] as unknown as number)
    }
  }, [levelEducationOptions])

  /*useEffect(() => {
    if (
      educationData &&
      educationData?.typeEducationDocumentId === HIGHER_EDUCATION[0]
    ) {
      setIsHigherEducation(true)
    }
  }, [educationData, educationData?.typeEducationDocumentId])*/

  useEffect(() => {
    isHigherEducation && dispatch(getPreviousEduForMastersDocument())
  }, [isHigherEducation])

  const finishedSpbpu = watch('finishedSpbpu')
  useEffect(() => {
    finishedSpbpu && setValue('nameEducationInstitution', terms.POLYTECH_FULLNAME)
  }, [finishedSpbpu])

  useInitializeInputs<EducationData>({
    data: educationData,
    omitKeys: ['formEducationId', 'typeEducationDocumentId', 'levelEducationId'],
    onInit: (key, value) => {
      const mapper = {
        nationalityId: 'nationalOption',
        directionTrainingId: 'directionTrainingOption',
      }

      if (Object.keys(mapper).includes(key)) {
        setValue(key, educationData?.[mapper[key]] as any)
      } else {
        setValue(key, value)
      }
    },
  })

  useInitializeSelect({
    data: educationData,
    options: formEducationOptions,
    findValue: educationData?.formEducationId,
    onInit: (option) => setValue('formEducationId', option as any),
  })

  useInitializeSelect({
    data: educationData,
    options: levelEducationOptions,
    findValue: educationData?.levelEducationId,
    onInit: (option) => setValue('levelEducationId', option as any),
  })

  useInitializeSelect({
    data: educationData,
    options: typeEducationDocumentOptions,
    findValue: educationData?.typeEducationDocumentId,
    onInit: (option) => setValue('typeEducationDocumentId', option as any),
  })

  // useInitializeSelect({
  //   data: educationData,
  //   options: previousEduForMastersOptions,
  //   findValue: educationData?.directionTrainingId,
  //   onInit: (option) => setValue('directionTrainingId', option as any),
  // })

  useInitializeSelect({
    data: educationData,
    options: foreignLanguageOptions,
    findValue: educationData?.studiedLanguesTranslateId,
    onInit: (option) => setValue('foreignLanguageStudy', option as any),
  })

  return {
    directionTrainingSelectProps,
    nationalSelectProps,
    nationalSelectPropsRus,
    nationalSelectPropsIno,
    formEducationOptions,
    levelEducationOptions,
    typeEducationDocumentOptions,
    previousEduForMastersOptions,
    foreignLanguageOptions,
  }
}

export default useEducationDataFormInitialization
