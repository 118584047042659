import React, { useState } from 'react'

import classNames from 'classnames'

import useCardForDownloadFile from '@hooks/useCardForDownloadFile'
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'
import { formatDateTimeToRuFormat } from '@utils/date'

type CardForPriorityDownloadFileProps = {
  className?: string
  showCard: boolean
  title: string
  date: string
  direction?: string
  type?: string
  blankId: number
  acceptBlank: boolean
}

const CardForPriorityDownloadFile = ({
  className,
  showCard,
  title,
  date,
  direction,
  blankId,
  acceptBlank,
}: CardForPriorityDownloadFileProps) => {
  const {
    handleClickDownloadDocs,
    subscribeBlank,
    subscribeBlankPriority,
    handleClickDownloadDocsPriority,
  } = useCardForDownloadFile(acceptBlank, blankId, title)
  const [active, setActive] = useState<boolean>(true)
  const a = () => {
    handleClickDownloadDocsPriority()
    setActive(false)
  }

  return (
    <div className={classNames('card', className)}>
      <span className="card__header">{title}</span>
      <span className="card__content">
        Дата формирования:{' '}
        <strong>{date ? formatDateTimeToRuFormat(date) : '-'}</strong>
      </span>
      {direction && (
        <div className="card__content-direction">
          {' '}
          <span className="card__content">Приемная кампания / Направление: </span>
          <div>
            <strong>{direction}</strong>
          </div>
        </div>
      )}
      <div className="card__download">
        <Button type={'button'} disabled={false} onClick={a}>
          Скачать Документ
        </Button>
        <span className="card__download-text">
          Скачайте, проверьте данные и нажмите кнопку “Подписать”
        </span>
      </div>
      <div className="card__signature">
        <Checkbox
          className={acceptBlank ? 'active' : ''}
          disabled={active}
          theme="success-filled"
          checked={acceptBlank}
          onChange={subscribeBlankPriority}
          text={'Подписать и изменить приоритеты'}
        />
      </div>
    </div>
  )
}

export default CardForPriorityDownloadFile
