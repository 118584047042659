import React from 'react'

const LogTable = ({ mappedData, columns }) => {
  return (
    <section className={'table-list'}>
      <div className="table-list__main">
        <table className="table-list__body">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.accessor}>{column.Header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {mappedData.map((item, i) => {
              return (
                <tr key={item.id + '.' + i}>
                  {columns.map((column) => (
                    <td key={item.id + '.' + i + '.' + column.accessor}>
                      {item[column.accessor]}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </section>
  )
}

export default LogTable
