export const createDate = (): string => {
  const date = new Date()
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${day}.${month <= 9 ? '0' + month : month}.${year}`
}

export const formatDateTimeToRuFormat = (date: string | Date) => {
  if (!date) return '-'
  return new Date(date).toLocaleString('ru').slice(0, -3).replace(',', '')
}

export const formatDateYYYYMMDD = (date: string) => {
  if (!date) return '-'
  return `${date.slice(-2)}.${date.slice(5, 7)}.${date.slice(0, 4)}`
}
