import React from 'react'
import { LinkProps } from 'react-router-dom'

import classNames from 'classnames'

import RouteLink from '../RouteLink'

type RouteButtonLinkProps = LinkProps & {
  theme?: 'success'
}

const RouteButtonLink = ({ theme, ...rest }: RouteButtonLinkProps) => {
  return (
    <RouteLink
      {...rest}
      className={classNames('button', 'route-button-link', rest.className, {
        [`button--theme-${theme}`]: Boolean(theme),
      })}
    />
  )
}

export default RouteButtonLink
