import { useEffect } from 'react'

import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import usePaginatedNationals from './usePaginatedNationals'
import { getModeratorLevelEducation } from '@redux/action-types'
import { selectModeratorLevelEducation } from '@redux/actions/selectors'

const useModeratorPersonalInformationInitialization = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    levelEducationsOptions.length === 0 && dispatch(getModeratorLevelEducation())
  }, [])

  const nationalSelectProps = usePaginatedNationals()
  const levelEducationsOptions = useAppSelector(selectModeratorLevelEducation)

  return {
    levelEducationsOptions,
    nationalSelectProps,
  }
}

export default useModeratorPersonalInformationInitialization
