import { Toast } from 'models'

import { RootState } from '../types'
import { createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit'

export type ToastInitialState = {
  toasts: Toast[]
}

const initialState: ToastInitialState = {
  toasts: [],
}

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    createToast: {
      reducer(state, action: PayloadAction<Toast>) {
        state.toasts.push(action.payload)
      },
      prepare(text: Toast['text'], theme: Toast['theme']) {
        return {
          payload: {
            id: nanoid(),
            text,
            theme,
          },
        }
      },
    },

    deleteToast(state, action: PayloadAction<string>) {
      state.toasts = state.toasts.filter((t) => t.id !== action.payload)
    },
  },
})

export default toastSlice.reducer
