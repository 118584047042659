import React from 'react'

import classnames from 'classnames'

import useAppSelector from '@hooks/useAppSelector'
import {
  selectEmptyValue,
  selectLevelValue,
  selectTrajectoryValue,
} from '@redux/actions/selectors'

type ProgressnavElementProps = {
  route: { title: string; to: string; id: string }
  active: string | undefined
  className: string | undefined
}
const ProgressnavElement = ({
  active,
  route,
  className,
}: ProgressnavElementProps) => {
  const { title, id } = route

  const text = useAppSelector(
    id === '1'
      ? selectTrajectoryValue
      : id === '2'
      ? selectLevelValue
      : selectEmptyValue
  ).value

  return (
    <div
      className={classnames(className, {
        active: active === id,
        disabled: 0 < Number(id) - Number(active),
      })}
    >
      <h2>{title}</h2>
      <p>{text}</p>
    </div>
  )
}
export default ProgressnavElement
