import React, { useEffect } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'

import cn from 'classnames'

import AvatarIcon from '@assets/images/Avatar.svg'
import LogoutIcon from '@assets/images/Logout.svg'
import {
  ROLE_ADMIN,
  ROLE_MODERATOR,
  ROLE_SUPPORT,
  ROLE_USER,
} from '@common/constants'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useWindowsSize from '@hooks/useWindowsSize'
import { clearToken, resetStore } from '@redux/action-types'
import {
  selectLocalizedViewerProfile,
  selectMobileMenu,
  selectModeratorStatementsPersonalData,
  selectRoleIdProfile,
  selectStatementsElement,
} from '@redux/actions/selectors'
import { deleteAuthTokenFromStorage } from '@utils/auth'

const navbarUserRoutes = [
  { to: '/personal-information', title: terms.PERSONAL_INFORMATION },
  { to: '/application', title: terms.APPLICATION },
  { to: '/additional-files', title: terms.ADDITIONAL_FILES },
  { to: '/applicants', title: 'Списки поступающих' },
  //todo временная мера для верстки фронта
  // { to: '/additional-files', title: 'Вступительные испытания' },
]

const navbarAdminRoutes = [
  { to: '/admin/statements', title: 'Заявления' },
  { to: '/admin/contracts', title: 'Договоры' },
  { to: '/admin/administration', title: 'Администрирование пользователей' },
  { to: '/admin/directory', title: 'Справочники' },
  { to: '/admin/settings', title: 'Настройки сайта' },
  { to: '/admin/log', title: 'Лог действий' },
  { to: '/admin/users', title: 'Все пользователи' },
  { to: '/applicants', title: 'Списки поступающих' },
]
const navbarModeratorRoutes = [
  { to: '/admin/statements', title: 'Заявления' },
  { to: '/admin/contracts', title: 'Договоры' },
  { to: '/applicants', title: 'Списки поступающих' },
]

const navbarSupportRoutes = [
  { to: '/admin/personal-information', title: 'Анкеты' },
  { to: '/admin/statements', title: 'Заявления' },
  { to: '/admin/users', title: 'Все пользователи' },
]

const navbarAnAuthRoutes = [{ to: '/applicants', title: terms.LIST_OF_APPLICANTS }]

const Navbar = ({
  moderator,
  userIdAdmin,
}: {
  moderator?: boolean
  userIdAdmin?: boolean
}) => {
  const { pathname } = useLocation()
  const { userId } = useParams()
  const navigate = useNavigate()
  const showMobileMenu = useAppSelector(selectMobileMenu)
  const { isMobile } = useWindowsSize()
  const dispatch = useAppDispatch()

  const roleId = useAppSelector(selectRoleIdProfile)
  const localizedProfile = useAppSelector(selectLocalizedViewerProfile)
  const personalData = useAppSelector(selectModeratorStatementsPersonalData) || {}
  const statementEl = useAppSelector(selectStatementsElement)

  const userIdAdminRoutes = [
    {
      to: `/admin/users/${
        statementEl?.userId || personalData?.users?.id || userId
      }/info/personal-information`,
      title: 'Анкеты',
    },
    {
      to: `/admin/users/${personalData?.users?.id}/statement/${personalData?.id}`,
      title: 'Заявление',
    },
    {
      to: `/admin/users/${
        personalData?.users?.id || statementEl?.userId || userId
      }/additional-files`,
      title: 'Дополнительные файлы',
    },
  ]

  const onLogout = () => {
    if (
      pathname.includes('admin/users/') &&
      (pathname.includes('personal-information/') ||
        pathname.includes('statement') ||
        pathname.includes('additional-files'))
    ) {
      window.close()
    } else {
      deleteAuthTokenFromStorage()
      dispatch(clearToken())
      dispatch(resetStore())
      navigate('/rus-sign-in')
    }
  }

  useEffect(() => {
    if (
      (moderator && pathname === '/admin') ||
      (moderator && pathname === '/admin/')
    ) {
      if (roleId === ROLE_SUPPORT) {
        navigate('personal-information')
      } else {
        navigate('statements')
      }
    }
  }, [pathname])

  return (
    <div className={cn('navbar', { ['navbar-active']: showMobileMenu })}>
      <nav>
        {!isMobile ? (
          <ul className="navbar__items">
            {(userIdAdmin
              ? userIdAdminRoutes.filter((route) =>
                  document.referrer.slice(-12) === '/admin/users'
                    ? route.title !== 'Заявление'
                    : route
                )
              : roleId === ROLE_ADMIN
              ? navbarAdminRoutes
              : roleId === ROLE_MODERATOR
              ? navbarModeratorRoutes
              : roleId === ROLE_SUPPORT
              ? navbarSupportRoutes
              : roleId === ROLE_USER
              ? navbarUserRoutes
              : navbarAnAuthRoutes
            ).map(({ title, to }) => {
              return (
                <li key={to} className="navbar__item">
                  <NavLink
                    to={to}
                    className={cn('navbar__link', {
                      ['active']: pathname.includes(to),
                    })}
                  >
                    {title}
                  </NavLink>
                </li>
              )
            })}
          </ul>
        ) : showMobileMenu ? (
          <ul className="navbar__items">
            <li className="navbar__item-avatar">
              <div className="navbar__item-content">
                {localizedProfile?.photo ? (
                  <img
                    src={localizedProfile.photo}
                    alt={terms.PHOTO}
                    className="user-menu__icon"
                  />
                ) : (
                  <AvatarIcon width="40" height="40" />
                )}
                <div className="user-menu__info">
                  <div className="user-menu__name">
                    {localizedProfile?.usersTranslate?.surname || ' '}{' '}
                    {localizedProfile?.usersTranslate?.name || ' '}
                  </div>
                  <div>
                    {terms.YOUR_ID_IN_SYSTEM}: {localizedProfile?.idWithPrefix}
                  </div>
                </div>
              </div>
              <div>
                <button className="user-menu__logout" onClick={onLogout}>
                  <LogoutIcon width="14" height="12" />
                </button>
              </div>
            </li>
            {(userIdAdmin
              ? userIdAdminRoutes
              : roleId === ROLE_ADMIN
              ? navbarAdminRoutes
              : roleId === ROLE_MODERATOR
              ? navbarModeratorRoutes
              : roleId === ROLE_SUPPORT
              ? navbarSupportRoutes
              : roleId === ROLE_USER
              ? navbarUserRoutes
              : navbarAnAuthRoutes
            ).map(({ title, to }) => (
              <li key={to} className="navbar__item">
                <NavLink to={to} className="navbar__link">
                  {title}
                </NavLink>
              </li>
            ))}
          </ul>
        ) : null}
      </nav>
    </div>
  )
}

export default Navbar
