import React from 'react'
import { Controller } from 'react-hook-form'

import terms from '@common/terms'
import useAppSelector from '@hooks/useAppSelector'
import useModeratorContractFilter from '@hooks/useModeratorContractFilter'
import useModeratorContractInitialization from '@hooks/useModeratorContractInitialization'
import { SelectOption } from '@models'
import { selectModeratorContractsList } from '@redux/actions/selectors'
import Button from '@ui/Button'
import Input from '@ui/Input'
import Select from '@ui/Select'
import { MultiValue, Option } from '@ui/Select/view'

const ModeratorContractFilter = () => {
  const { fields, control, reset, onSubmit } = useModeratorContractFilter()
  const {
    paymentFormOptions,
    levelOptions,
    facultyOptions,
    statusStatementOptions,
    statusContractOptions,
    trajectoryOptions,
    nationalSelectProps,
    moderatorsListOptions,
    partnersSelectOptions,
    scenarioOptions,
  } = useModeratorContractInitialization()

  const contactList = useAppSelector(selectModeratorContractsList) || []

  return (
    <section>
      <form onSubmit={onSubmit} className={'moderator-contract-filter'}>
        <Input
          className={'filter-form__field'}
          {...fields.userId}
          placeholder="ID пользователя"
        />
        <Input
          className={'filter-form__field'}
          {...fields.userSurname}
          placeholder="Фамилия"
        />
        <Input
          className={'filter-form__field'}
          {...fields.userName}
          placeholder="Имя"
        />
        <Input
          className={'filter-form__field'}
          {...fields.userPatronymic}
          placeholder="Отчество"
        />
        <Input
          className={'filter-form__field'}
          {...fields.userEmail}
          placeholder="Email"
        />
        <Controller
          defaultValue={null}
          name="formPayment"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={paymentFormOptions}
              placeholder={'Основания поступления'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="formEducation"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={levelOptions}
              placeholder={terms.MODE_OF_STUDY}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="userNationality"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              placeholder={terms.CITIZENSHIP}
              className="filter-form__field"
              {...nationalSelectProps}
              {...(field as unknown)}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              invariant
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="statementStatus"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={statusStatementOptions}
              placeholder={'Статус заявления'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="contractStatus"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={statusContractOptions}
              placeholder={'Статус договора'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="faculty"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={facultyOptions}
              placeholder={'Подразделение'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="trajectory"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={trajectoryOptions}
              placeholder={'Приемная компания'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="moderator"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={moderatorsListOptions}
              placeholder={'Модератор'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="partners"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={partnersSelectOptions}
              placeholder={'Партнерская организация'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="scenarios"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={scenarioOptions}
              placeholder={'Сценарий поступления'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <footer>
          <h2>Количество договоров: {contactList?.total | 0}</h2>

          <div>
            <Button type={'submit'} onClick={() => reset()}>
              Сбросить фильтры
            </Button>
            <Button theme={'success'} type={'submit'}>
              Отфильтровать
            </Button>
          </div>
        </footer>
      </form>
    </section>
  )
}
export default ModeratorContractFilter
