import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { NavRoute } from 'types/custom-types'

import useAppSelector from './useAppSelector'
import { getFilteredRoutes } from '@lib/routes'
import { ModeratorStatementsSectionKey } from '@models'
import {
  selectModeratorStatementsPersonalData,
  selectModeratorUserPersonalData,
} from '@redux/actions/selectors'

type Route = NavRoute<ModeratorStatementsSectionKey>

const useModeratorStatementsProvideRoutes = (initialRoutes: Route[] = []) => {
  const currentRoute = useRef<string>()
  const lastBtn = ''
  const onNextPageNavigate = async () => {}
  const [routes, setRoutes] = useState<Route[]>(initialRoutes)
  const statement = useAppSelector(selectModeratorStatementsPersonalData)
  const users = useAppSelector(selectModeratorUserPersonalData)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname.split('/')[2] === 'personal-information') {
      changeRouteVisibility({
        achievements: false,
        privileges: false,
        examResults: false,
        directions: false,
        additionalForms: false,
        downloads: false,
        targetContract: false,
      })
    }
  }, [location.pathname])

  useEffect(() => {
    if (statement?.users?.compatriot || users?.compatriot) {
      changeRouteVisibility({ compatriotData: true })
    }
  }, [statement, users])

  const changeRouteVisibility = (params: { [key in Route['key']]?: boolean }) => {
    const keys = Object.keys(params)
    setRoutes((prev) =>
      prev.map((route) => {
        if (keys.includes(route.key)) {
          return { ...route, hidden: !params[route.key] }
        }
        return { ...route }
      })
    )
  }
  const { visitedRoutes, unvisitedRoutes, visibleRoutes, visitedSortedRoutes } =
    getFilteredRoutes(routes, currentRoute.current)

  return {
    lastBtn,
    routes: visibleRoutes,
    visitedRoutes,
    unvisitedRoutes,
    onNextPageNavigate,
    visitedSortedRoutes,
  }
}

export default useModeratorStatementsProvideRoutes
