import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import LogoutIcon from '@assets/images/Logout.svg'
import PasswordChange from '@assets/images/PasswordChange.svg'
import { changeLocale, locale } from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { clearToken, resetStore, showModal } from '@redux/action-types'
import { selectLocalizedViewerProfile } from '@redux/actions/selectors'
import Dropdown from '@ui/Dropdown'
import { deleteAuthTokenFromStorage } from '@utils/auth'

const ModeratorMenu = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onLogout = () => {
    deleteAuthTokenFromStorage()
    dispatch(clearToken())
    dispatch(resetStore())
    navigate('/admin')
  }

  useEffect(() => {
    if (locale !== 'ru') {
      changeLocale('ru')
    }
  })

  const localizedProfile = useAppSelector(selectLocalizedViewerProfile)

  return (
    <div className="user-menu">
      <div className="user-menu__info user-menu__info-moderator">
        <div className="user-menu__name">
          {localizedProfile?.usersTranslate?.surname || ' '}{' '}
          {localizedProfile?.usersTranslate?.name || ' '}
        </div>
      </div>
      <Dropdown title={''}>
        <div
          className={'dropdown-element'}
          onClick={() => dispatch(showModal({ name: 'CHANGE_PASSWORD_MODAL' }))}
        >
          <PasswordChange /> Изменить пароль
        </div>
        <div className={'dropdown-element'} onClick={onLogout}>
          <LogoutIcon width="14" height="12" /> Выйти
        </div>
      </Dropdown>
    </div>
  )
}

export default ModeratorMenu
