import React, { useEffect } from 'react'
import { Controller, FieldError } from 'react-hook-form'

import TemplateModal from '../TemplateModal'
import terms from '@common/terms'
import uploadDocsTypes from '@common/uploadDocsTypes'
import ProfileSection from '@components/ProfileSection'
import useAddPreemptiveRightForm from '@hooks/useAddPreemptiveRightForm'
import useAddPreemptiveRightFormInitialization from '@hooks/useAddPreemptiveRightFormInitialization'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useUploadDocuments from '@hooks/useUploadDocuments'
import { PreemptiveRightResponse, SelectCodeOption, SelectOption } from '@models'
import { modalDataSelector } from '@redux/actions/selectors'
import { getPreemptiveRightsDocType } from '@redux/reducers'
import Button from '@ui/Button'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import Select from '@ui/Select'

const AddPreemptiveRightModal = () => {
  const defaultData: PreemptiveRightResponse = useAppSelector(modalDataSelector)

  const {
    control,
    fields,
    setValue,
    clearErrors,
    loading,
    watch,
    onSubmit,
    formState: { errors },
  } = useAddPreemptiveRightForm(defaultData ? 'EDIT' : 'ADD')

  const {
    preemptiveType,
    preemptiveDocType,
  } = useAddPreemptiveRightFormInitialization({ setValue })

  const {
    onChange,
    docs,
    onDrop,
    onDelete,
    docId,
    getUploadedDoc,
  } = useUploadDocuments('LGOTA')
  const dispatch = useAppDispatch()

  const isEdit = defaultData
    ? terms.EDIT_PREEMPTIVE_RIGHT_TITLE
    : terms.ADD_PREEMPTIVE_RIGHT_TITLE

  useEffect(() => {
    setValue('typeDoc', uploadDocsTypes['PREEMPTIVE_RIGHT'])
    if (defaultData?.docId) {
      getUploadedDoc(defaultData.docId)
    }
  }, [defaultData])

  useEffect(() => {
    if (docId) {
      setValue('docId', docId)
    }
  }, [docId])

  const preemptiveRightId = (watch('preemptiveRightId') as unknown) as
    | SelectOption
    | undefined
  useEffect(() => {
    if (preemptiveRightId) {
      dispatch(getPreemptiveRightsDocType(Number(preemptiveRightId.value)))
    }
  }, [preemptiveRightId])

  useEffect(() => {
    if (docs.length) {
      clearErrors('documents')
    }
    setValue('documents', docs)
  }, [docs])

  return (
    <TemplateModal title={isEdit} clearTypeDoc="PREEMPTIVE_RIGHT">
      <form onSubmit={onSubmit}>
        <div className="profile-form__fields">
          <Controller
            name="preemptiveRightId"
            control={control}
            render={({ field }) => (
              <Select<SelectCodeOption>
                placeholder={terms.TYPE_PREEMPTIVE}
                options={preemptiveType}
                className="profile-form__field"
                markRequired={true}
                {...(field as unknown)}
                error={errors.preemptiveRightId?.message}
              />
            )}
          />

          <Controller
            name="preemptiveRightDocId"
            control={control}
            render={({ field }) => (
              <Select<SelectCodeOption>
                placeholder={terms.TYPE_DOCUMENT}
                options={preemptiveDocType}
                className="profile-form__field"
                markRequired={true}
                {...(field as unknown)}
                error={errors.preemptiveRightDocId?.message}
              />
            )}
          />
          <Input
            className="profile-form__field"
            placeholder={terms.NUMBER_DOCUMENT}
            markRequired={true}
            {...fields.number}
            error={errors.number?.message}
          />
          <Input
            className="profile-form__field"
            placeholder={terms.DATE_ISSUE}
            type="date"
            {...fields.dateIssue}
            error={errors.dateIssue?.message}
          />
        </div>
        <ProfileSection title={terms.DOCUMENT} markRequired={true} fileUploadingHint>
          <FileUploader
            className="profile-form__file-uploader"
            onChange={onChange}
            onDelete={onDelete}
            onDrop={onDrop}
            files={docs}
          />
          {errors.documents && (
            <div className="profile-form__error">
              {((errors.documents as unknown) as FieldError | undefined)?.message}
            </div>
          )}
        </ProfileSection>
        <Button
          theme="success"
          loading={loading}
          className="profile-form__button profile-form__button--align-left"
        >
          {terms.SAVE}
        </Button>
      </form>
    </TemplateModal>
  )
}

export default AddPreemptiveRightModal
