import { useEffect } from 'react'
import { UseFormSetValue } from 'react-hook-form'

import axios from 'axios'

import uploadDocsTypes from '@common/uploadDocsTypes'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useInitializeInputs from '@hooks/useInitializeInputs'
import useUploadDocuments from '@hooks/useUploadDocuments'
import { Achivement } from '@models'
import { getAchivementTypes } from '@redux/action-types'
import {
  selectAchivementDocTypes,
  selectAchivementTypesOptions,
} from '@redux/actions/selectors'

type usePersonalDataFormSelectorsProps = {
  setValue: UseFormSetValue<Achivement>
  achivement?: Achivement
  statementId: number
}

const useAchivementsFormInitialization = ({
  setValue,
  statementId,
  achivement = {} as Achivement,
}: usePersonalDataFormSelectorsProps) => {
  const dispatch = useAppDispatch()

  const achivementTypesOptions = useAppSelector(selectAchivementTypesOptions)
  const docTypesOptions = useAppSelector(selectAchivementDocTypes)

  const achivementFile = useUploadDocuments('OLYMPIAD', achivement.docName)
  const copyCertificateFile = useUploadDocuments(
    'COPY_CERTIFICATE',
    'Копия свидетельства'
  )

  useEffect(() => {
    if (statementId) {
      setValue('statementId', Number(statementId))
      setValue('typeDoc', uploadDocsTypes['OLYMPIAD'])
      setValue('secondTypeDoc', uploadDocsTypes['COPY_CERTIFICATE'])
      dispatch(getAchivementTypes(statementId))
    }
    if (Object.keys(achivement).length) {
      if (achivement?.docId) achivementFile.getUploadedDoc(achivement?.docId)
      if (achivement?.secondDocId)
        copyCertificateFile.getUploadedDoc(achivement?.secondDocId)
    }
  }, [])

  useInitializeInputs({
    data: achivement,
    omitKeys: [],
    onInit: (key, value) => {
      const mapper = {
        achivmentId: 'achivementTypeOption',
        achivmentDocId: 'achivementDocOption',
      }

      if (Object.keys(mapper).includes(key)) {
        setValue(key, achivement?.[mapper[key]] as any)
      } else {
        setValue(key, value)
      }
    },
  })

  return {
    achivementFile,
    copyCertificateFile,
    docTypesOptions,
    achivementTypesOptions,
  }
}

export default useAchivementsFormInitialization
