import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import {
  BLANK_AGREEMENT_TO_ENTER,
  SCENARIO_MASTER,
  STATUS_APPROVED,
} from '@common/constants'
import terms from '@common/terms'
import StudentsFilterForm from '@components/StudentsFilterForm'
import TableStudents from '@components/TableStudents'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { cleanStudentsList, getTrainingLevels } from '@redux/action-types'
import {
  selectCompetitiveGroupsTrajectory1,
  selectSearchStudents,
  selectStudentsList,
  selectTrainingLevels,
  setAdminPageDirectories,
  setAdminPerPageDirectories,
} from '@redux/actions/selectors'
import Breadcrumbs from '@ui/Breadcrumbs'
import Pagination from '@ui/Pagination'

function resultExamData(arr, examData) {
  if (!arr || !examData) return []
  const egeColumns: {
    accessor: string
    Header: string
  }[] = []

  examData.map((examList) => {
    egeColumns.push({
      accessor: examList[0]?.trial + '',
      Header: [
        ...new Set(
          examList?.map((item) => {
            return item?.disciplineName
          })
        ),
      ].join('/ '),
    })
  })

  return egeColumns
}

function studentsHeader(columnsExam, scenario) {
  const visibleSum =
    scenario === SCENARIO_MASTER
      ? []
      : [
          {
            accessor: 'sumBySubject',
            Header: '-',
          },
        ]

  return [
    {
      accessor: 'id',
      Header: 'terms.TABLE_NUMBER',
    },
    {
      accessor: 'code',
      Header: 'terms.TABLE_ID',
    },
    {
      accessor: 'olympiads',
      Header: 'terms.TABLE_OLYMPIAD',
    },
    ...columnsExam,
    ...(visibleSum as any),
    {
      accessor: 'sumById',
      Header: 'terms.TABLE_GRADE_ACHIEVEMENTS',
    },
    {
      accessor: 'sumOfBall',
      Header: 'terms.TABLE_GRADE_ALL',
    },
    {
      accessor: 'original',
      Header: 'terms.TABLE_ORIGINAL',
    },
    {
      accessor: 'agreement',
      Header: 'terms.TABLE_CONSENT',
    },
    {
      accessor: 'preemptiveRight',
      Header: 'terms.TABLE_PRIORITY',
    },
    {
      accessor: 'state',
      Header: 'terms.TABLE_STATUS',
    },
  ]
}

function studentsMapper(arr, competitiveGroups, scenario) {
  if (!arr) return []
  const rows: any[] = []

  arr.forEach((item) => {
    const rowsExam: any[] = []

    if (competitiveGroups?.length > 0) {
      competitiveGroups.map((exams) => {
        let maxResult = 0
        exams.map((exam) => {
          if (exam?.ege) {
            maxResult =
              (item?.ege?.resultEge?.length > 0 &&
                item.ege.resultEge.reduce((prev, el) => {
                  if (el?.name === exam?.disciplineName) {
                    return Math.max(prev, el?.result)
                  }
                  return prev
                }, 0)) ||
              0
          } else {
          }
        })
        rowsExam[exams[0]?.trial + ''] = maxResult
      })
    }
    const id = 0

    const visibleSum: any[] = []
    if (scenario !== SCENARIO_MASTER) {
      visibleSum['sumBySubject'] =
        Object.values(rowsExam)?.reduce((prev, current) => prev + current) || 0
    }

    rows.push({
      id: item.serialNumber,
      code: item.users.noSnils ? item.users.id : item.users.snils,
      olympiads: item.olympiads.length > 0 ? 'Да' : 'Нет',
      original: 'Нет',
      agreement: item?.statementBlanks?.find(
        (el) => el.blankId === BLANK_AGREEMENT_TO_ENTER
      )?.acceptBlank
        ? 'Да'
        : 'Нет',
      ...rowsExam,
      ...visibleSum,
      sumById: id || '-',
      sumOfBall:
        (Object.values(rowsExam)?.reduce((prev, current) => prev + current) || 0) +
        (id || 0),

      preemptiveRight: item.userPreemptiveRight.length > 0 ? 'Да' : 'Нет',
      state:
        item.statusStatement.code === '' + STATUS_APPROVED
          ? 'Подано'
          : 'Не учувствует в конкурсе',
    })
  })
  return rows
}

function studentsCompetitiveMapper(arr, competitiveGroups) {
  if (!arr) return []
  const rows: any[] = []

  arr.forEach((item) => {
    const rowsExam: any[] = []

    if (competitiveGroups?.length > 0) {
      competitiveGroups.map((exams, i) => {
        rowsExam[exams[0]?.trial + ''] = item['subject' + (i + 1)]
      })
    }

    rows.push({
      id: item?.id,
      code: (item?.user?.noSnils ? item.user?.id : item.user?.snils) || '-',
      olympiads: '-',
      original: 'Нет',
      agreement: item?.consentEnrollment ? 'Да' : 'Нет',
      ...rowsExam,
      sumBySubject: item?.sumPointsSubjects || 0,
      sumById: item?.sumPointsIndividualAchievements || 0,
      sumOfBall: item?.sumCompetitivePoints || 0,
      preemptiveRight: item?.preemptiveRightId ? 'Да' : 'Нет',
      state: (item.withoutEntranceTest && 'Без вступительных испытаний') || 'Подано',
    })
  })
  return rows
}

const ListStudents = () => {
  const dispatch = useAppDispatch()
  const { scenario } = useParams<{ scenario: string }>()
  const { pathname } = useLocation()

  const trajectory = scenario?.split('-')[0]
  const level = scenario?.split('-')[1]

  useEffect(() => {
    if (trainingLevels.length === 0) {
      dispatch(getTrainingLevels())
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch(cleanStudentsList())
    }
  }, [])

  const studentsList = useAppSelector(selectStudentsList) || []
  const trainingLevels = useAppSelector(selectTrainingLevels) || []
  const competitiveGroups = useAppSelector(selectCompetitiveGroupsTrajectory1) || []

  const columnsExam = resultExamData(studentsList.data, competitiveGroups)
  const mappedHeader = studentsHeader(columnsExam, level)
  const mappedData = pathname.includes('competitive')
    ? studentsCompetitiveMapper(studentsList.data, competitiveGroups)
    : studentsMapper(studentsList.data, competitiveGroups, level)
  const search = useAppSelector(selectSearchStudents)

  return (
    <div className="list-students">
      <Breadcrumbs
        className="list-students__breadcrumbs"
        title={`${
          pathname.includes('competitive')
            ? 'Конкурсные списки'
            : 'Списки поступающих'
        } по программам ${
          trainingLevels.find((el) => el.value === Number(level))?.label || ''
        } в 2022 году для
           ${trajectory === '1' ? 'граждан РФ' : 'иностранных граждан'}`}
      />
      <StudentsFilterForm lastUpdate={studentsList.lastUpdate} />
      {(mappedData.length > 0 || search) && (
        <>
          <TableStudents mappedData={mappedData} columns={mappedHeader} />
          <Pagination
            lastPage={studentsList.lastPage}
            currentPage={studentsList.currentPage}
            perPage={studentsList.perPage}
            setPage={setAdminPageDirectories}
            setPerPage={setAdminPerPageDirectories}
          />
        </>
      )}
    </div>
  )
}

export default ListStudents
