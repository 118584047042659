export function firstLetterUpperCase(text: string): string {
  if (!text) {
    return ''
  }

  const firstLetter = text.trim().toLowerCase().charAt(0)
  const firstLetterCap = firstLetter.toUpperCase()
  const remainingLetters = text.slice(1)
  return firstLetterCap + remainingLetters
}

export function allFirstLetterUpperCaseInStr(text: string): string {
  if (!text) {
    return ''
  }

  return text
    .split(' ')
    .map((el) =>
      el
        .toLowerCase()
        .trim()
        .split('-')
        .map((item) => firstLetterUpperCase(item))
        .join('-')
    )
    .join(' ')
}
