import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import { getTrainingLevelList } from '@redux/action-types'
import {
  selectStatementsElement,
  selectTrajectoryValue,
} from '@redux/actions/selectors'

const useTrajectoryPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const statementEl = useAppSelector(selectStatementsElement)
  useEffect(() => {
    if (statementEl && statementEl.numberStatement !== null) {
      navigate('../filling-statements')
    }
  }, [statementEl])

  const trajectory = useAppSelector(selectTrajectoryValue)

  const onSubmit = () => {
    dispatch(getTrainingLevelList(trajectory.id))
    navigate('../training-level')
  }

  return {
    trajectory,
    onSubmit,
  }
}

export default useTrajectoryPage
