import React from 'react'
import { Outlet } from 'react-router-dom'

import Navbar from '@components/Navbar'

const ViewerLayout = ({
  moderator,
  userIdAdmin,
}: {
  moderator?: boolean
  userIdAdmin?: boolean
}) => {
  return (
    <div className="viewer-layout">
      <Navbar moderator={moderator} userIdAdmin={userIdAdmin} />
      <div className="viewer-layout__content">
        <Outlet />
      </div>
    </div>
  )
}

export default ViewerLayout
