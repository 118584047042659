import React, { createContext, useContext } from 'react'

import useProfileStatements from '@common/routes/useProfileStatements'
import useProvideProfileRoutes from '@hooks/useProvideProfileRoutes'

type ProfileRoutesContextProps = ReturnType<typeof useProvideProfileRoutes>

const ProfileRoutesContext = createContext<ProfileRoutesContextProps>(
  {} as ProfileRoutesContextProps
)

export const ProfileRoutesContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { profilePageRotes } = useProfileStatements()

  return (
    <ProfileRoutesContext.Provider value={useProvideProfileRoutes(profilePageRotes)}>
      {children}
    </ProfileRoutesContext.Provider>
  )
}

export default () => {
  return useContext(ProfileRoutesContext)
}
