import React from 'react'
import { Controller } from 'react-hook-form'

import terms from '@common/terms'
import useAppSelector from '@hooks/useAppSelector'
import useModeratorPersonalInformationFilter from '@hooks/useModeratorPersonalInformationFilter'
import useModeratorPersonalInformationInitialization from '@hooks/useModeratorPersonalInformationInitialization'
import { SelectOption } from '@models'
import { selectModeratorPersonalInformationList } from '@redux/actions/selectors'
import Button from '@ui/Button'
import Input from '@ui/Input'
import Select from '@ui/Select'
import { MultiValue, Option } from '@ui/Select/view'

const ModeratorPersonalInformationFilter = () => {
  const {
    fields,
    control,
    reset,
    onSubmit,
  } = useModeratorPersonalInformationFilter()

  const {
    levelEducationsOptions,
    nationalSelectProps,
  } = useModeratorPersonalInformationInitialization()

  const personalInformationList =
    useAppSelector(selectModeratorPersonalInformationList) || []

  return (
    <section>
      <form onSubmit={onSubmit} className={'moderator-contract-filter'}>
        <Input
          className={'filter-form__field'}
          {...fields.userId}
          placeholder="ID пользователя"
        />
        <Input
          className={'filter-form__field'}
          {...fields.surname}
          placeholder="Фамилия"
        />
        <Input className={'filter-form__field'} {...fields.name} placeholder="Имя" />
        <Input
          className={'filter-form__field'}
          {...fields.patronymic}
          placeholder="Отчество"
        />
        <Input
          className={'filter-form__field'}
          {...fields.email}
          placeholder="Email"
        />
        <Controller
          defaultValue={null}
          name="nationalityIds"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              placeholder={terms.CITIZENSHIP}
              className="filter-form__field"
              {...nationalSelectProps}
              {...(field as unknown)}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              invariant
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Controller
          defaultValue={null}
          name="levelEducationIds"
          control={control}
          render={({ field }) => (
            <Select<SelectOption, true>
              isMulti
              styles={{
                valueContainer: (provided) => ({
                  ...provided,
                  maxHeight: '30px',
                  overflowY: 'auto',
                }),
                menu: (base) => ({ ...base, minWidth: '300px' }),
              }}
              className="filter-form__field"
              {...(field as unknown)}
              options={levelEducationsOptions}
              placeholder={'Вид полученного образования'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              components={{ Option, MultiValue }}
            />
          )}
        />
        <Input
          className={'filter-form__field'}
          type={'date'}
          {...fields.createdAt}
          placeholder="Дата создания аккаунта"
        />
        <footer>
          <h2>
            Количество анкет без созданных заявок:{' '}
            {personalInformationList?.total | 0}
          </h2>

          <div>
            <Button type={'submit'} onClick={() => reset()}>
              Сбросить фильтры
            </Button>
            <Button theme={'success'} type={'submit'}>
              Отфильтровать
            </Button>
          </div>
        </footer>
      </form>
    </section>
  )
}
export default ModeratorPersonalInformationFilter
