import React from 'react'
import { useForm } from 'react-hook-form'

import useAppDispatch from '@hooks/useAppDispatch'
import { setSearchStudents } from '@redux/action-types'
import Input from '@ui/Input'

const TableStudents = ({ mappedData, columns }) => {
  const { register } = useForm<{ search: string }>()
  const dispatch = useAppDispatch()

  const fields = {
    search: register('search', {
      onBlur: ({ target }) => {
        dispatch(setSearchStudents(target.value))
      },
    }),
  }
  return (
    <section className={'table-list'}>
      <header>
        <div className={'table-list__sort'}>
          <Input
            placeholder={'Поиск по таблице'}
            inputClassName={'students-search'}
            search={true}
            {...fields.search}
          />
        </div>
      </header>
      <div className="table-list__main">
        <table className="table-list__body">
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.accessor}>{column.Header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {mappedData.map((item, i) => {
              return (
                <tr key={item.id + '.' + i}>
                  {columns.map((column) => (
                    <td key={item.id + '.' + i + '.' + column.accessor}>
                      {item[column.accessor]}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </section>
  )
}

export default TableStudents
