import React from 'react'

import EducationForm from '@components/EducationForm'

const EducationDataPage = () => {
  return (
    <div>
      <EducationForm />
    </div>
  )
}

export default EducationDataPage
