import { useEffect } from 'react'

import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { Achivement } from '@models'
import { createToast, getAchivements, showModal } from '@redux/action-types'
import { selectAchivementAnketa, selectAchivements } from '@redux/actions/selectors'
import api from '@services/api'
import { base64toFile, downloadFile } from '@utils/common'
import uploadDocsTypes from '@common/uploadDocsTypes'

const useAchivements = (statementId: string) => {
  const dispatch = useAppDispatch()
  const achivements = useAppSelector(selectAchivements)
  const achivementsAnketa = useAppSelector(selectAchivementAnketa)
  useEffect(() => {
    dispatch(getAchivements(Number(statementId)))
  }, [])

  const onDownloadFile = (docId: number, filename: string, docType?: string) => {
    api.client
      .getDocs(docType ? uploadDocsTypes[docType] : 'OLYMPIAD', docId)
      .then((resp) =>
        base64toFile(resp.data.document || '', 'application/pdf', 'pdf', filename)
      )
      .then(downloadFile)
      .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')))
  }

  const onDelete = (data: { statementId: number; docId: number }) => {
    api.client
      .deleteUserAchivement(data)
      .then((response) => {
        const { message } = response.data
        dispatch(createToast(message, 'success'))
        dispatch(getAchivements(Number(statementId)))
      })
      .catch(() => {
        dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
      })
  }

  const onEdit = (achivement: Achivement) => {
    dispatch(
      showModal({
        name: 'ADD_INDIVIDUAL_ACHIVEMENT',
        data: { statementId, achivement },
      })
    )
  }

  return {
    onEdit,
    onDelete,
    achivements,
    onDownloadFile,
    achivementsAnketa,
  }
}

export default useAchivements
