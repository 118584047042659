import admin from './reducers/adminSlice'
import chat from './reducers/chatSlice'
import docs from './reducers/docsSlice'
import lgota from './reducers/lgotaSlice'
import modal from './reducers/modalSlice'
import moderator from './reducers/moderatorSlice'
import profile from './reducers/profileSlice'
import statements from './reducers/statementsSlice'
import toast from './reducers/toastSlice'
import viewer from './reducers/viewerSlice'
import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'

const combinedReducer = combineReducers({
  chat,
  docs,
  lgota,
  admin,
  modal,
  toast,
  viewer,
  profile,
  statements,
  moderator,
})

const reducer = (state, action) => {
  if (action.type === 'store/reset') {
    state = undefined
  }
  return combinedReducer(state, action)
}

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware({ serializableCheck: false }),
})

export default store
