import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  ROLE_ADMIN,
  ROLE_MODERATOR,
  ROLE_SUPPORT,
  CONTRACT_DRAFT,
  CONTRACT_REVOKE,
} from '@common/constants'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import usePaginatedNationals from '@hooks/usePaginatedNationals'
import {
  getFaculty,
  getFormDirectionsTrainingSearch,
  getModeratorModeratorsList,
  getModeratorScenario,
  getModeratorStatementsIdComment,
  getModeratorStatementsIdStatus,
  getModeratorStatusContract,
  getModeratorStatusStatement,
  getModeratorTrajectory,
  getPartnersList,
  getPaymentDirectionsTrainingSearch,
} from '@redux/action-types'
import {
  selectFacultyOptions,
  selectLevelDirectionsTrainingSearchOptions,
  selectModeratorContractIdStatusCode,
  selectModeratorContractInfo,
  selectModeratorModeratorsList,
  selectModeratorScenario,
  selectModeratorStatementsIdStatusAdmin,
  selectModeratorStatementsIdStatusCode,
  selectModeratorStatusContract,
  selectModeratorStatusStatement,
  selectModeratorTrajectory,
  selectPartnersList,
  selectPaymentDirectionsTrainingSearchOptions,
  selectRoleIdProfile,
} from '@redux/actions/selectors'
import { isNumeric } from '@utils/common'

const useModeratorContractInitialization = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { id } = useParams<{ id?: string }>()

  useEffect(() => {
    if (id) {
      if (!isNumeric(id)) {
        navigate('../')
      } else {
        dispatch(getModeratorStatementsIdStatus(id))
        if (roleId === ROLE_MODERATOR || roleId === ROLE_ADMIN) {
          dispatch(getModeratorStatementsIdComment(id))
          moderatorsListOptions.length === 0 &&
            dispatch(getModeratorModeratorsList())
        }
      }
    }
  }, [id])

  useEffect(() => {
    levelOptions.length === 0 && dispatch(getFormDirectionsTrainingSearch())
    facultyOptions.length === 0 && dispatch(getFaculty())
    paymentFormOptions.length === 0 && dispatch(getPaymentDirectionsTrainingSearch())
    trajectoryOptions.length === 0 && dispatch(getModeratorTrajectory())
    statusStatementOptions.length === 0 && dispatch(getModeratorStatusStatement())
    statusContractOptions.length === 0 && dispatch(getModeratorStatusContract())
    moderatorsListOptions.length === 0 && dispatch(getModeratorModeratorsList())
    partnersSelectOptions.length === 0 &&
      dispatch(getPartnersList({ value: '', page: 1, perPage: 100 }))
    scenarioOptions.length === 0 && dispatch(getModeratorScenario())
  }, [])

  const nationalSelectProps = usePaginatedNationals()
  const levelOptions = useAppSelector(selectLevelDirectionsTrainingSearchOptions)
  const facultyOptions = useAppSelector(selectFacultyOptions)
  const statusStatementOptions = useAppSelector(selectModeratorStatusStatement)
  const statusContractOptions = useAppSelector(selectModeratorStatusContract)
  const trajectoryOptions = useAppSelector(selectModeratorTrajectory)
  const paymentFormOptions = useAppSelector(
    selectPaymentDirectionsTrainingSearchOptions
  )
  const moderatorsListOptions = useAppSelector(selectModeratorModeratorsList)
  const roleId = useAppSelector(selectRoleIdProfile)
  const statusCode = useAppSelector(selectModeratorContractIdStatusCode)
  const partnersSelectOptions = useAppSelector(selectPartnersList)
  const scenarioOptions = useAppSelector(selectModeratorScenario)
  const statusAdmin = useAppSelector(selectModeratorStatementsIdStatusAdmin)

  useEffect(() => {
    if (statusCode) {
      if (
        roleId === ROLE_MODERATOR &&
        (statusCode === CONTRACT_DRAFT || statusAdmin === CONTRACT_REVOKE)
      ) {
        navigate('../')
      }

      if (roleId === ROLE_SUPPORT && statusCode !== CONTRACT_DRAFT) {
        navigate('../')
      }
    }
  }, [statusCode])

  return {
    paymentFormOptions,
    levelOptions,
    facultyOptions,
    statusStatementOptions,
    statusContractOptions,
    trajectoryOptions,
    nationalSelectProps,
    moderatorsListOptions,
    roleId,
    statusCode,
    statusAdmin,
    partnersSelectOptions,
    scenarioOptions,
  }
}

export default useModeratorContractInitialization
