import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ModeratorDataList from '@components/ModeratorDataList'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { DataList } from '@models'
import { getAdminLegalRepresentative } from '@redux/action-types/admin'
import { selectAdminLegalRepresentative } from '@redux/actions/selectors'
import Checkbox from '@ui/Checkbox'

const ModerationLegalRepresentative = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams<{ id?: string; userId?: string }>()

  const legalRepresentative = useAppSelector(selectAdminLegalRepresentative)

  useEffect(() => {
    if (!legalRepresentative && id) {
      dispatch(getAdminLegalRepresentative(id))
    }
  })

  const list: DataList = [
    {
      title: 'Гражданство',
      label: legalRepresentative?.nationality?.nationalityTranslate[0]?.name ?? '-',
    },

    {
      title: 'Фамилия',
      label: legalRepresentative?.surname ?? '-',
    },

    {
      title: 'Имя',
      label: legalRepresentative?.name ?? '-',
    },

    {
      title: 'Отчество',
      label: legalRepresentative?.patronymic ?? '-',
    },

    {
      title: 'Нет Отчества',
      label: legalRepresentative?.noPatronymic ? 'Да' : 'Нет',
    },

    {
      title: 'Фамилия латинскими буквами',
      label: legalRepresentative?.surnameEn ?? '-',
    },

    {
      title: 'Имя латинскими буквами',
      label: legalRepresentative?.nameEn ?? '-',
    },

    {
      title: 'Отчество (если есть) латинскими буквами',
      label: legalRepresentative?.patronymicEn ?? '-',
    },

    {
      title: 'Дата рождения',
      label: legalRepresentative?.dateBirthday ?? '-',
    },

    {
      title: 'Степень родства',
      label: legalRepresentative?.relationDegree?.name ?? '-',
    },

    {
      title: 'Код страны',
      label:
        legalRepresentative?.mobile?.split(' ').length > 1
          ? legalRepresentative?.mobile?.split(' ')[0]
          : '-',
    },

    {
      title: 'Мобильный телефон',
      label: legalRepresentative?.mobile ?? '-',
    },

    {
      title: 'Дополнительный email (при наличии)',
      label: legalRepresentative?.dopEmail ?? '-',
    },
  ]

  return (
    <div>
      <h2 className={'title'} style={{ fontSize: '20px', marginBottom: '20px' }}>
        <b>Законный представитель</b>
      </h2>

      <ModeratorDataList list={list} />
    </div>
  )
}

export default ModerationLegalRepresentative
