import React, { useEffect } from 'react'

import classNames from 'classnames'
import useAppDispatch from 'hooks/useAppDispatch'
import { Toast as ToastType } from 'models'
import { deleteToast } from 'redux/action-types'

import CheckMarkIcon from '@assets/images/Check-mark.svg'
import ExclamationMarkIcon from '@assets/images/Exclamation-mark.svg'

type ToastProps = ToastType & {
  onClose: (toastId: ToastType['id']) => void
}

const Toast = ({ id, theme, text, onClose }: ToastProps) => {
  const disptach = useAppDispatch()

  useEffect(() => {
    const timeoutid = setTimeout(() => disptach(deleteToast(id)), 5000)

    return () => {
      clearTimeout(timeoutid)
    }
  }, [])

  return (
    <div className={classNames('toast', `toast--theme-${theme}`)}>
      <div className="toast__body">
        <div>
          {theme === 'danger' ? (
            <ExclamationMarkIcon className="toast__icon" width={16} height={17} />
          ) : (
            <CheckMarkIcon className="toast__icon" width={16} height={17} />
          )}
        </div>
        <span className="toast__text">{text}</span>
      </div>
      <button
        className={classNames('toast__button', `toast__button--theme-${theme}`)}
        onClick={() => onClose(id)}
      >
        x
      </button>
    </div>
  )
}

export default Toast
