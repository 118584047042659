import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import AvatarIcon from '@assets/images/Avatar.svg'
import LogoutIcon from '@assets/images/Logout.svg'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { clearToken, resetStore } from '@redux/action-types'
import {
  selectLocalizedViewerProfile,
  selectMoodleCourses,
  selectPersonalData,
} from '@redux/actions/selectors'
import { deleteAuthTokenFromStorage } from '@utils/auth'

const UserMenu = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { pathname } = useLocation()
  const data = useAppSelector(selectLocalizedViewerProfile)

  let navTo = ''
  const onLogout = () => {
    switch (data?.code) {
      case '1':
        navTo = '/rus-sign-in'
        break
      case '2':
        navTo = '/ino-sign-in'
        break
      case '3':
        navTo = '/asp-sign-in'
        break
      default:
        navTo = '/rus-sign-in'
        break
    }

    if (
      pathname.includes('admin/users/') &&
      pathname.includes('personal-information/')
    ) {
      window.close()
    } else {
      deleteAuthTokenFromStorage()
      dispatch(clearToken())
      dispatch(resetStore())
      navigate(navTo)
    }
  }

  const localizedProfile = useAppSelector(selectLocalizedViewerProfile)

  return (
    <div className="user-menu">
      {localizedProfile?.photo ? (
        <img
          src={localizedProfile.photo}
          alt={terms.PHOTO}
          className="user-menu__icon"
        />
      ) : (
        <AvatarIcon width="40" height="40" />
      )}

      <div className="user-menu__info">
        <div className="user-menu__name">
          {localizedProfile?.usersTranslate?.surname || ' '}{' '}
          {localizedProfile?.usersTranslate?.name || ' '}
        </div>
        <div>
          {terms.YOUR_ID_IN_SYSTEM}: {localizedProfile?.idWithPrefix}
        </div>
      </div>

      <button className="user-menu__logout" onClick={onLogout}>
        <LogoutIcon width="14" height="12" />
      </button>
    </div>
  )
}

export default UserMenu
