import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { FilterContract, FilterContractData } from '@models'
import { getModeratorContractsList } from '@redux/action-types'
import {
  selectModeratorStatementsListOrder,
  selectModeratorStatementsListPage,
  selectModeratorStatementsListPerPage,
} from '@redux/actions/selectors'

const useModeratorContractFilter = () => {
  const dispatch = useAppDispatch()
  const { register, handleSubmit, control, reset, setValue } =
    useForm<FilterContract>({ mode: 'all' })

  const fields = {
    userId: register('userId'),
    userSurname: register('userSurname'),
    userName: register('userName'),
    userPatronymic: register('userPatronymic'),
    userEmail: register('userEmail'),

    formPayment: register('formPayment'),
    faculty: register('faculty'),
    trajectory: register('trajectory'),
    formEducation: register('formEducation'),
    userNationality: register('userNationality'),
    statementStatus: register('statementStatus'),
    contractStatus: register('contractStatus'),
    scenarios: register('scenarios'),

    moderator: register('moderator'),
    partners: register('partners'),

    perPage: register('perPage'),
    page: register('page'),
  }

  const order = useAppSelector(selectModeratorStatementsListOrder)
  const page = useAppSelector(selectModeratorStatementsListPage)
  const perPage = useAppSelector(selectModeratorStatementsListPerPage)

  useEffect(() => {
    setValue('page', page)
    setValue('perPage', perPage)
    setValue('orderCreateDate', order)
    onSubmit()
  }, [page, perPage, order])

  const onSubmit = handleSubmit((data: any) => {
    const normalizedData: FilterContract = {
      ...data,
      formPayment: data.formPayment?.map((el) => {
        return el.value
      }),
      formEducation: data.formEducation?.map((el) => {
        return el.value
      }),
      faculty: data.faculty?.map((el) => {
        return el.value
      }),
      trajectory: data.trajectory?.map((el) => {
        return el.value
      }),
      userNationality: data.userNationality?.map((el) => {
        return el.value
      }),
      statementStatus: data.statementStatus?.map((el) => {
        return el.value
      }),
      contractStatus: data.contractStatus?.map((el) => {
        return el.value
      }),
      moderator: data.moderator?.map((el) => {
        return el.value
      }),
      partners: data.partners?.map((el) => {
        return el.value
      }),
      scenarios: data.scenarios?.map((el) => {
        return el.value
      }),
    }

    dispatch(getModeratorContractsList(normalizedData))
  })

  return {
    fields,
    control,
    reset,
    setValue,
    onSubmit,
  }
}

export default useModeratorContractFilter
