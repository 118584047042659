import React from 'react'

import TableDirectoriesInfo from '@components/TableDirectoriesInfo'
import useDirectoryInfo from '@hooks/useDirectoryInfo'
import {
  setAdminPageDirectoriesInfo,
  setAdminPerPageDirectoriesInfo,
} from '@redux/actions/selectors'
import Loader from '@ui/Loader'
import Pagination from '@ui/Pagination'

const DirectoryInfo = () => {
  const {
    mappedData,
    changeDirectoryView,
    deleteDirectory,
    editDirectory,
    directory,
    createNewDirectory,
    directoryInfo,
    columnsAdminDirectoriesList,
  } = useDirectoryInfo()

  if (!directoryInfo?.data?.length) {
    return <Loader />
  }

  return (
    <>
      <TableDirectoriesInfo
        columns={columnsAdminDirectoriesList}
        data={mappedData}
        changeDirectoryView={changeDirectoryView}
        editDirectory={editDirectory}
        deleteDirectory={deleteDirectory}
        directory={directory}
        createNewDirectory={createNewDirectory}
      />
      <div>
        {directoryInfo?.data.length && directoryInfo.total > 10 && (
          <Pagination
            lastPage={directoryInfo.lastPage}
            currentPage={directoryInfo.currentPage}
            perPage={directoryInfo.perPage}
            setPage={setAdminPageDirectoriesInfo}
            setPerPage={setAdminPerPageDirectoriesInfo}
          />
        )}
      </div>
    </>
  )
}

export default DirectoryInfo
