import React, { useEffect, useState } from 'react'

import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useUploadDocuments from '@hooks/useUploadDocuments'
import {
  clearDocs,
  createToast,
  getModeratorStatementsAdditionalFiles,
  hideModal,
} from '@redux/action-types'
import { getAdditionalDocs } from '@redux/reducers'
import api, { UploadDocsRespOk, UploadDocsResponse } from '@services/api'
import { formatDateTimeToRuFormat } from '@utils/date'

const useAdditionalFileUploading = (name, docId, date, id?: number | string) => {
  const [filenameError, setFilenameError] = useState('')
  const [fileError, setFileError] = useState('')
  const [filename, setFilename] = useState('')
  const { onChange, docs, onDrop, onDelete, getUploadedDoc } = useUploadDocuments(
    'ADDITIONAL_FILES',
    name
  )

  const dispatch = useAppDispatch()

  useEffect(() => {
    name && setFilename(name)
    name && getUploadedDoc(Number(docId))
  }, [name])

  const isEditing = Boolean(name)

  const onSave = () => {
    if (!filename && !filenameError) {
      setFilenameError(terms.REQUIRED_FIELD)
    }
    if (!docs.length && !fileError) {
      setFileError(terms.REQUIRED_FIELD)
    }
    if (!filename || !docs.length) {
      return
    }

    if (isEditing) {
      api.client
        .uploadDocs({
          documents: docs,
          typeDoc: 'ADDITIONAL_FILES',
          name: filename,
          docId: Number(docId),
          date,
        })
        .then((response) => {
          const { data } = response as UploadDocsRespOk
          dispatch(createToast(data.message, 'success'))
          dispatch(hideModal())
        })
    } else {
      const date = formatDateTimeToRuFormat(new Date())
      api.client
        .uploadDocs({
          documents: docs,
          typeDoc: 'ADDITIONAL_FILES',
          name: filename,
          id,
          date,
        })
        .then((response) => {
          const { data } = response as UploadDocsRespOk
          dispatch(createToast(data.message, 'success'))
          // dispatch(getAdditionalDocs())
          dispatch(clearDocs('ADDITIONAL_FILES'))
          dispatch(getModeratorStatementsAdditionalFiles(id))
          dispatch(hideModal())
        })
        .catch((response: Exclude<UploadDocsResponse, UploadDocsRespOk>) => {
          const { status } = response

          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
    }
  }

  const onFilnameChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setFilename(value)
    if (value && filenameError) {
      setFilenameError('')
    }
  }

  useEffect(() => {
    if (fileError && docs.length) {
      setFileError('')
    }
  }, [docs.length, fileError])

  return {
    inputProps: {
      value: filename,
      error: filenameError,
      onChange: onFilnameChange,
    },
    uploaderProps: {
      onDrop,
      onDelete,
      onChange,
    },
    fileError,
    onSave,
    docs,
  }
}

export default useAdditionalFileUploading
