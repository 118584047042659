export const findItemIndexById = <TItem extends { id: string | number }>(
  items: TItem[],
  id: string | number
) => {
  return items.findIndex((item: TItem) => String(item.id) === String(id))
}

export const moveItem = <TItem>(array: TItem[], from: number, to: number) => {
  const item = array[from]
  return insertItemAtIndex(removeItemAtIndex(array, from), item, to)
}

export function removeItemAtIndex<TItem>(array: TItem[], index: number) {
  return [...array.slice(0, index), ...array.slice(index + 1)]
}

export function insertItemAtIndex<TItem>(
  array: TItem[],
  item: TItem,
  index: number
) {
  return [...array.slice(0, index), item, ...array.slice(index)]
}
