import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import {
  getNumberInList,
  normalizeYearTo4Characters,
  validateDate,
} from '@lib/validators'
import { Achivement, AchivementsWithOptions, SelectOption } from '@models'
import {
  clearDocs,
  createToast,
  getAchivements,
  getPassportData,
  getPersonalData,
  getTypeIdentityDocuments,
  hideModal,
} from '@redux/action-types'
import { modalDataSelector, selectPersonalFormData } from '@redux/actions/selectors'
import api, {
  AddLgotaEntrantsResponse,
  ResponseOk,
  UploadDocsRespOk,
  UploadDocsResponse,
} from '@services/api'
import { AddAchivementResponse } from '@services/api/statements-api'

const useAddIndividualAchivementForm = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  const { register, handleSubmit, setError, getValues, setValue, watch, ...rest } =
    useForm<Achivement>()

  useEffect(() => {
    !personalData && dispatch(getPersonalData())
  }, [])
  const personalData = useAppSelector(selectPersonalFormData)
  const achivmentId = watch('achivmentId') as unknown as SelectOption | undefined
  //@ts-ignore
  const is43Achivment = watch('achivmentId.value') === 43
  const isShowLinkToPubPage = achivmentId?.value === 48
  const [selectAchievement, setSelectAchievement] = useState()
  const requiredAchvments = [
    'М_Научная публикация, проиндексированная в наукометрических базах данных РИНЦ',
    'М_Научная публикация, проиндексированная в наукометрических базах данных Web of Science',
    'М_Научная публикация, проиндексированная в наукометрических базах данных Scopus',
    'М_Научная публикация, опубликованная в периодических изданиях, входящих в список ВАК',
  ]

  const fields = {
    achivementId: register('achivmentId', {
      required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) => {
        setValue('achivmentDocId', null)
        setValue('numberInList', null)
        setSelectAchievement(value.label)
      },
    }),
    typeDoc: register('typeDoc', { required: terms.REQUIRED_FIELD }),
    statementId: register('statementId', { required: terms.REQUIRED_FIELD }),
    achivementDocId: register('achivmentDocId', {
      required: terms.REQUIRED_FIELD,
    }),
    number: register('number', { required: terms.REQUIRED_FIELD_SERIAL_AND_NUMBER }),
    linkToPubPage: register('linkToPubPage', {
      required: {
        value: isShowLinkToPubPage,
        message: terms.REQUIRED_FIELD,
      },
    }),
    dateIssue: register('dateIssue', {
      required: terms.REQUIRED_FIELD,
      validate: (value) => {
        return validateDate(value, personalData?.dateBirthday || '')
          ? true
          : terms.DATE_INVALID
      },
      onChange: ({ target: { value } }) => {
        value && setValue('dateIssue', normalizeYearTo4Characters(value))
      },
    }),
    docId: register('docId'),
    quantity: register('quantity'),
    comment: register('comment', {
      onChange: (event) => {
        setValue('requireDopInfo', event.target.value)
      },
    }),
    numberInList: register('numberInList', {
      required: { value: is43Achivment, message: terms.REQUIRED_FIELD },
      ...getNumberInList(),
    }),
    requireDopInfo: register('requireDopInfo', {
      required: requiredAchvments.find((value) => value === selectAchievement)
        ? terms.REQUIRED_FIELD
        : undefined,
    }),
  }

  const { statementId, achivement } = useAppSelector(modalDataSelector) as {
    statementId: number
    achivement?: Achivement
  }

  const isEditing = Boolean(achivement)

  const onSubmit = handleSubmit((data: any) => {
    setLoading(true)

    const normalizedData: Achivement = {
      ...data,
      achivmentId: data.achivmentId.value || null,
      achivmentDocId: data.achivmentDocId.value || null,
      numberInList: data.numberInList || null,
    }

    if (normalizedData.numberInList === null) {
      delete normalizedData.numberInList
    }

    if (isEditing) {
      let status = false
      api.client
        .uploadDocs({
          documents: normalizedData.documents,
          typeDoc: 'PREEMPTIVE_RIGHT',
          docId: normalizedData.docId,
        })
        .then(() => {
          status = true
        })
        .catch((response: Exclude<UploadDocsResponse, UploadDocsRespOk>) => {
          const { status } = response

          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
        .finally(() => {
          if (status) {
            console.log(normalizedData)
            api.client
              .editAchivement(normalizedData)
              .then((response) => {
                const { data } = response as unknown as ResponseOk
                dispatch(createToast(data.message, 'success'))
                dispatch(getAchivements(Number(statementId)))
                dispatch(clearDocs('OLYMPIAD'))
                dispatch(hideModal())
              })
              .catch((response: Exclude<AddLgotaEntrantsResponse, ResponseOk>) => {
                const { status } = response

                if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
                  const { data } = response
                  dispatch(createToast(data.message, 'danger'))

                  Object.keys(data.errors).forEach((key) => {
                    setError(key as keyof Achivement, {
                      type: 'manual',
                      message: data.errors[key][0],
                    })
                  })
                } else {
                  dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
                }
              })
              .finally(() => setLoading(false))
          }
        })
    } else {
      api.client
        .addAchivement(normalizedData)
        .then((response) => {
          const { data } = response as unknown as ResponseOk
          dispatch(getAchivements(Number(statementId)))
          dispatch(createToast(data.message, 'success'))
          dispatch(hideModal())
        })
        .catch((response: Exclude<AddAchivementResponse, ResponseOk>) => {
          const { status } = response

          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))

            Object.keys(data.errors).forEach((key) => {
              setError(key as keyof Achivement, {
                type: 'manual',
                message: data.errors[key][0],
              })
            })
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
        .finally(() => setLoading(false))
    }
  })

  return {
    selectAchievement,
    requiredAchvments,
    fields,
    onSubmit,
    setValue,
    getValues,
    loading,
    watch,
    ...rest,
  }
}

export default useAddIndividualAchivementForm
