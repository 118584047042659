import { useEffect, useState } from 'react'

import {
  SelectCodePaginatedEntityResult,
  SelectPaginatedEntityResult,
} from 'types/custom-types'

import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useDebouncedFunction from '@hooks/useDebouncedFunction'
import { PaginatedThunk, RootState } from '@redux/types'

type UsePaginatedRequestProps = {
  dataSelector: (
    state: RootState
  ) => SelectPaginatedEntityResult | SelectCodePaginatedEntityResult
  loaderSelector: (state: RootState) => boolean
  action: PaginatedThunk
  actionProps?: {}
}

const usePaginatedEntity = ({
  dataSelector,
  loaderSelector,
  action,
  actionProps,
}: UsePaginatedRequestProps) => {
  const dispatch = useAppDispatch()
  const [search, setSearch] = useState('')
  const isLoading = useAppSelector(loaderSelector)
  const { options, nextPage } = useAppSelector(dataSelector)

  const debouncedAction = useDebouncedFunction(
    (value: string) => dispatch(action({ value, type: 'new', ...actionProps })),
    500
  )

  const onPaginate = () => {
    if (nextPage !== -1) {
      dispatch(action({ value: search, page: nextPage, ...actionProps }))
    }
  }

  const onInputChange = (inputValue, actionMeta) => {
    if (
      actionMeta.action === 'input-change' ||
      inputValue !== actionMeta.prevInputValue
    ) {
      debouncedAction(inputValue)
    }
    setSearch(inputValue)
  }

  useEffect(() => {
    !options.length && dispatch(action({ type: 'new' }))
  }, [dispatch])

  return {
    options,
    isLoading,
    onPaginate,
    onInputChange,
  }
}

export default usePaginatedEntity
