import { NavRoute } from 'types/custom-types'

import terms from '@common/terms'
import useCitizenshipResult from '@hooks/useCitizenshipResult'
import { ProfileSectionKey } from '@models'

const useProfileStatements = () => {
  const { nationalId } = useCitizenshipResult()

  const profilePageRotes: NavRoute<ProfileSectionKey>[] = [
    {
      to: 'basic-data',
      title: terms.BASIC_DATA,
      text: terms.BASIC_DATA_HINT,
      key: 'personalData',
      status: 'not visited',
    },
    {
      to: 'passport-data',
      title: terms.PASSPORT_DATA,
      text: terms.PASSPORT_DATA_HINT,
      key: 'passport',
      status: 'not visited',
    },
    {
      to: 'legal-representative',
      title: terms.INFORMATION_ABOUT_PARENTS,
      text: terms.INFORMATION_ABOUT_PARENTS_HINT,
      key: 'legalRepresentative',
      status: 'not visited',
    },
    {
      to: 'compatriot-data',
      title: terms.COMPATRIOT_DOCUMENTS,
      text: terms.COMPATRIOT_DOCUMENTS_HINT,
      key: 'compatriotDocument',
      status: 'not visited',
    },
    {
      to: 'address',
      title:
        nationalId === 1
          ? terms.PERMANENT_ADDRESS_AND_CURRENT_HOME_ADDRESS
          : terms.PERMANENT_ADDRESS_AND_CURRENT_HOME_ADDRESS_FOREIGN,
      text:
        nationalId === 1
          ? terms.PERMANENT_ADDRESS_AND_CURRENT_HOME_ADDRESS_HINT
          : '',
      key: 'address',
      status: 'not visited',
    },
    {
      to: 'educational-background',
      title: terms.EDUCATIONIONAL_BACKGROUND,
      text: terms.EDUCATIONIONAL_BACKGROUND_HINT,
      key: 'education',
      status: 'not visited',
    },
    {
      to: 'visa',
      title: terms.VISA,
      text: terms.VISA_HINT,
      key: 'visa',
      status: 'not visited',
    },
    {
      to: 'additional-information',
      title: terms.ADDITIONAL_INFORMATION,
      text: '',
      key: 'additionalInfo',
      status: 'not visited',
    },
  ]

  // const { citizenship } = useCitizenshipResult()

  const APPLICATION_SECTION_ROUTE = '/application'

  return {
    profilePageRotes,
    APPLICATION_SECTION_ROUTE,
  }
}

export default useProfileStatements
