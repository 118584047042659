import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import useAppDispatch from './useAppDispatch'
import {
  ViewerDirectionsTrainingSearch,
  ViewerDirectionsTrainingSearchFormData,
} from '@models'
import { getDirectionsList, setDirectionTrainingSort } from '@redux/action-types'

const useDirectionsTrainingSearch = () => {
  const { statementId } = useParams<{ statementId: string }>()
  const { register, handleSubmit, getValues, setValue, reset, ...rest } =
    useForm<ViewerDirectionsTrainingSearchFormData>({ mode: 'all' })

  const dispatch = useAppDispatch()
  const [sortValue, setSortValue] = useState()

  const fields = {
    name: register('name'),
    faculty: register('faculty', {
      onChange: (e) => {
        setValue('faculty', e.target.value)
        onSubmit()
      },
    }),
    formEducation: register('formEducation', {
      onChange: (e) => {
        setValue('formEducation', e.target.value)
        onSubmit()
      },
    }),
    formPayment: register('formPayment', {
      onChange: (e) => {
        setValue('formPayment', e.target.value)
        onSubmit()
      },
    }),
  }

  const resetForm = () => {
    reset({
      name: '',
      faculty: null,
      formEducation: null,
      formPayment: null,
    })
    onSubmit()
  }

  const onChangeSortFilter = (e) => {
    e?.value
      ? dispatch(setDirectionTrainingSort(e.value))
      : dispatch(setDirectionTrainingSort(''))
    setSortValue(e)
  }

  const onSubmit = handleSubmit((data: any) => {
    const normalizedData: ViewerDirectionsTrainingSearch = {
      ...data,
      statementId: String(statementId),
      faculty: data.faculty?.map((el) => {
        return el.value
      }),
      formEducation: data.formEducation?.value,
      formPayment: data.formPayment?.value,
    }
    dispatch(getDirectionsList(normalizedData))
  })
  return {
    fields,
    onSubmit,
    resetForm,
    setValue,
    onChangeSortFilter,
    sortValue,
    ...rest,
  }
}

export default useDirectionsTrainingSearch
