import usePaginatedEntity from '@hooks/usePaginatedEntity'
import { getNationals } from '@redux/action-types'
import {
  selectPaginatedNationals,
  selectPaginatedNationalsLoading,
} from '@redux/actions/selectors'

const usePaginatedNationals = () => {
  return usePaginatedEntity({
    action: getNationals,
    dataSelector: selectPaginatedNationals,
    loaderSelector: selectPaginatedNationalsLoading,
  })
}

export default usePaginatedNationals
