import { useEffect } from 'react'
import { UseFormSetValue } from 'react-hook-form'

import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useInitializeInputs from '@hooks/useInitializeInputs'
// import usePaginatedKladr from '@hooks/usePaginatedKladr'
// import usePaginatedKladrCity from '@hooks/usePaginatedKladrCity'
// import usePaginatedKladrDistrict from '@hooks/usePaginatedKladrDistrict'
// import usePaginatedKladrHouse from '@hooks/usePaginatedKladrHouse'
// import usePaginatedKladrRegion from '@hooks/usePaginatedKladrRegion'
// import usePaginatedKladrStreet from '@hooks/usePaginatedKladrStreet'
import usePaginatedNationals from '@hooks/usePaginatedNationals'
import usePaginatedNationalsRus from '@hooks/usePaginatedNationalsRus'
import usePaginatedNationalsIno from '@hooks/usePaginatedNationalsIno'
import { Residence } from '@models'
import { getResidence } from '@redux/action-types'
import {
  selectLocalizedViewerProfile,
  selectResidenceFormData,
} from '@redux/actions/selectors'

type useResidenceFormSelectorsProps = {
  setValue: UseFormSetValue<Residence>
  watchAllFields: any
  isRegRussia: boolean
}

const useResidenceFormInitialization = ({
  setValue,
  watchAllFields,
}: useResidenceFormSelectorsProps) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    !residence && dispatch(getResidence())
  }, [])

  //key for dadata address api
  const key = process.env.REACT_APP_ADDRESS_API_KEY_DADATA || 'none'

  const residence = useAppSelector(selectResidenceFormData)

  // let nationalSelectProps = usePaginatedNationals()
  let nationalSelectPropsRus = usePaginatedNationalsRus()
  let nationalSelectPropsIno = usePaginatedNationalsIno()

  const profileData = useAppSelector(selectLocalizedViewerProfile)
  const code = profileData?.code
  // const kladrSelectProps = usePaginatedKladr()
  // const kladrSelectPropsRegion = usePaginatedKladrRegion()
  // const kladrSelectPropsDistrict = usePaginatedKladrDistrict({
  //   regionCode: watchAllFields.regionCodeRegistration,
  // })
  // const kladrSelectPropsCity = usePaginatedKladrCity({
  //   regionCode: watchAllFields.regionCodeRegistration,
  //   districtCode: watchAllFields.districtCodeRegistration,
  // })
  // const kladrSelectPropsStreet = usePaginatedKladrStreet({
  //   localityCode: watchAllFields.localityCodeRegistration,
  // })
  // const kladrSelectPropsHouse = usePaginatedKladrHouse({
  //   streetCode: watchAllFields.streetCodeRegistration,
  //   localityCode: watchAllFields.localityCodeRegistration,
  // })
  // const kladrSelectPropsActualDistrict = usePaginatedKladrDistrict({
  //   regionCode: watchAllFields.regionCodeActual,
  // })
  // const kladrSelectPropsActualCity = usePaginatedKladrCity({
  //   regionCode: watchAllFields.regionCodeActual,
  //   districtCode: watchAllFields.districtCodeActual,
  // })
  // const kladrSelectPropsActualStreet = usePaginatedKladrStreet({
  //   localityCode: watchAllFields.localityCodeActual,
  // })
  // const kladrSelectPropsActualHouse = usePaginatedKladrHouse({
  //   streetCode: watchAllFields.streetCodeActual,
  //   localityCode: watchAllFields.localityCodeActual,
  // })

  useInitializeInputs({
    data: residence,
    omitKeys: [],
    onInit: (key, value) => {
      const mapper = {
        nationalActual: 'nationalActualOption',
        nationalRegistration: 'nationalRegOption',
        regionActual: 'regionActualOption',
        regionRegistration: 'regionRegistrationOption',

        cityActual: 'cityActualOption',
        cityRegistration: 'cityRegistrationOption',

        apiFias: 'apiFias',
        dadataField: 'dadataField',
        dadataValue: 'dadataValue',
        dadataUnrestrictedValue: 'dadataUnrestrictedValue',
        dadataFieldActual: 'dadataFieldActual',
        dadataValueActual: 'dadataValueActual',
        dadataUnrestrictedValueActual: 'dadataUnrestrictedValueActual',
        apiFiasActual: 'apiFiasActual',
        apiKladr: 'apiKladr',
        apiKladrActual: 'apiKladrActual',

        streetActual: 'streetActualOption',
        streetRegistration: 'streetRegistrationOption',

        districtActual: 'districtActualOption',
        districtRegistration: 'districtRegistrationOption',

        houseActual: 'houseActualOption',
        houseRegistration: 'houseRegistrationOption',
      }

      if (Object.keys(mapper).includes(key)) {
        if (residence?.[mapper[key]]) {
          setValue(key, residence?.[mapper[key]] as any)
        } else {
          setValue(key, value)
        }
      } else {
        setValue(key, value)
      }
    },
  })

  return {
    code,
    //  nationalSelectProps,
    nationalSelectPropsRus,
    nationalSelectPropsIno,
    // kladrSelectProps,
    // kladrSelectPropsRegion,
    // kladrSelectPropsDistrict,
    // kladrSelectPropsCity,
    // kladrSelectPropsStreet,
    // kladrSelectPropsHouse,
    // kladrSelectPropsActualDistrict,
    // kladrSelectPropsActualCity,
    // kladrSelectPropsActualStreet,
    // kladrSelectPropsActualHouse,
    residence,
    key,
  }
}

export default useResidenceFormInitialization
