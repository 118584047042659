import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import {
  ROLE_ADMIN,
  STATUS_APPROVED,
  STATUS_DENIED,
  STATUS_REVOKE_APPROVED,
  STATUS_SEND_EDITING,
  STATUS_WORK,
} from '@common/constants'
import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import {
  createToast,
  getModeratorStatementsIdComment,
  getModeratorStatementsIdStatus,
} from '@redux/action-types'
import {
  selectModeratorStatementsIdComment,
  selectRoleIdProfile,
} from '@redux/actions/selectors'
import api, {
  ResponseOk,
  UpdateComment,
  UpdateDeleteStatements,
  UpdateStatus,
} from '@services/api'

const useModeratorStatementIdForm = () => {
  const { register, handleSubmit, setValue } = useForm<{ comment: string }>({
    mode: 'all',
  })
  const { id } = useParams<{ id?: string }>()
  const navigate = useNavigate()

  const fields = {
    comment: register('comment', {
      onBlur: () => {
        onSubmit()
      },
    }),
  }
  const [loading, setLoading] = useState(false)

  const comment = useAppSelector(selectModeratorStatementsIdComment)

  useEffect(() => {
    setValue('comment', comment?.text || '')
  }, [comment])

  const roleId = useAppSelector(selectRoleIdProfile)

  const deleteStatements = (id) => {
    if (id && roleId === ROLE_ADMIN) {
      api.client
        .deleteAdminStatements(id)
        .then((response) => {
          const { data } = response
          dispatch(createToast(data.message, 'success'))
          navigate('../')
        })
        .catch((response: Exclude<UpdateDeleteStatements, ResponseOk>) => {
          const { status } = response
          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
          } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
    }
  }

  const inWork = () => {
    changeStatus(STATUS_WORK)
  }
  const reject = () => {
    changeStatus(STATUS_DENIED)
  }
  const sendUser = () => {
    changeStatus(STATUS_SEND_EDITING)
  }
  const approve = () => {
    changeStatus(STATUS_APPROVED)
  }
  const revokeApprove = () => {
    changeStatus(STATUS_REVOKE_APPROVED)
  }

  const dispatch = useAppDispatch()
  const changeStatus = (code: number, responsePersonId?: number) => {
    if (id && roleId) {
      setLoading(true)
      api.client
        .putModeratorStatusStatement(
          roleId,
          { id: Number(id), code: code },
          responsePersonId
        )
        .then((response) => {
          const { data } = response
          dispatch(createToast(data.message, 'success'))
          dispatch(getModeratorStatementsIdStatus(id))
        })
        .catch((response: Exclude<UpdateStatus, ResponseOk>) => {
          const { status } = response
          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
          } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
        .finally(() => setLoading(false))
    }
  }
  const changeModerator = (moderatorId) => {
    if (!id) {
      return
    }

    api.client
      .postNewModeratorStatements({
        statementId: id,
        moderatorId: moderatorId,
      })
      .then((response) => {
        const { data } = response
        dispatch(createToast(data.message, 'success'))
        dispatch(getModeratorStatementsIdComment(id))
      })
      .catch((response: Exclude<UpdateComment, ResponseOk>) => {
        const { status } = response
        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
  }

  const onSubmit = handleSubmit((data: any) => {
    if (!id) {
      return
    }

    const normalizedData: { statementId: string; comment: string } = {
      ...data,
      statementId: id,
    }
    api.client
      .postModeratorStatementIdComment(normalizedData)
      .then((response) => {
        const { data } = response
        dispatch(createToast(data.message, 'success'))
        dispatch(getModeratorStatementsIdComment(id))
      })
      .catch((response: Exclude<UpdateComment, ResponseOk>) => {
        const { status } = response
        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
  })
  return {
    fields,
    loading,
    deleteStatements,
    inWork,
    reject,
    approve,
    revokeApprove,
    sendUser,
    changeModerator,
  }
}

export default useModeratorStatementIdForm
