import { AppModal } from 'types/custom-types'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ModalState = {
  isShown: boolean
  modalName: AppModal | null
  data: any
}

const initialState: ModalState = {
  isShown: true,
  modalName: null,
  data: null,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (
      state,
      action: PayloadAction<{ name: AppModal; data?: unknown }>
    ) => {
      state.isShown = true
      state.modalName = action.payload.name
      state.data = action.payload.data
    },
    hideModal: (state) => {
      state.isShown = false
      state.modalName = null
      state.data = null
    },
  },
})

export default modalSlice.reducer
