import React from 'react'

import classnames from 'classnames'

import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { showModal } from '@redux/action-types'
import { selectLocalizedViewerProfile } from '@redux/actions/selectors'
import Button from '@ui/Button'
import Input from '@ui/Input'

type ProfileAuthProps = {
  className?: string
}

const ProfileAuth = ({ className }: ProfileAuthProps) => {
  const dispatch = useAppDispatch()
  const profileData = useAppSelector(selectLocalizedViewerProfile)

  return (
    <div className={classnames('profile-auth-block', className)}>
      <div className="profile-auth-block__title form__subtitle">
        {terms.AUTHORIZATION}
      </div>
      <Input
        placeholder={terms.EMAIL}
        className="profile-auth-block__input"
        readOnly={true}
        defaultValue={profileData?.email}
      />
      <Button
        className="profile-auth-block__button"
        onClick={() => dispatch(showModal({ name: 'CHANGE_PASSWORD_MODAL' }))}
        type="button"
      >
        {terms.CHANGE_PASSWORD}
      </Button>
    </div>
  )
}

export default ProfileAuth
