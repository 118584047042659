import { useParams } from 'react-router-dom'

import useAppDispatch from './useAppDispatch'
import useFillingStatementsRoutesContext from './useFillingStatementsRoutesContext'
import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import {
  createToast,
  getBlankPriorityStatement,
  getDirectionsListStatements,
  showModal,
} from '@redux/action-types'
import api, {
  ResponseOk,
  UpdateDirectionTrainingAssignResponse,
} from '@services/api'

const useDirectionsTrainingElement = () => {
  const dispatch = useAppDispatch()

  const { statementId } = useParams()

  const { onNextPageNavigate } = useFillingStatementsRoutesContext()

  const deleteElement = (directionId) => {
    if (!statementId || directionId === null) {
      return
    }
    api.client
      .statementsDirectionTrainingsDetach({
        statement: Number(statementId),
        directionTraining: JSON.stringify([directionId]),
      })
      .then((response) => {
        const { data } = response
        data.message && dispatch(createToast(data.message, 'success'))
        dispatch(getDirectionsListStatements(Number(statementId)))
      })
      .catch(
        (response: Exclude<UpdateDirectionTrainingAssignResponse, ResponseOk>[]) => {
          const { status } = response[0]
          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response[0]
            dispatch(createToast(data.message, 'danger'))
          } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
            const { data } = response[0]
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        }
      )
  }

  const order = (itemsId: number[]) => {
    if (!statementId) {
      return
    }

    api.client
      .statementsDirectionTrainingsOrder({
        competitiveGroups: itemsId,
        statement: Number(statementId),
      })
      .then((response) => {
        const { data } = response as ResponseOk
        data.message && dispatch(createToast(data.message, 'success'))
        dispatch(getDirectionsListStatements(Number(statementId)))
      })
      .catch(
        (response: Exclude<UpdateDirectionTrainingAssignResponse, ResponseOk>[]) => {
          const { status } = response[0]
          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response[0]
            dispatch(createToast(data.message, 'danger'))
          } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
            const { data } = response[0]
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        }
      )
  }

  const swap = (from: number | true, to: number | true, update: boolean) => {
    if (!statementId || from === true || to === true) {
      return
    }

    api.client
      .statementsDirectionTrainingsSwap({
        to: to,
        statement: Number(statementId),
        from: from,
      })
      .then((response) => {
        const { data } = response as ResponseOk
        update && data.message && dispatch(createToast(data.message, 'success'))
        //  update && dispatch(getDirectionsListStatements(Number(statementId)))
      })
      .catch(
        (response: Exclude<UpdateDirectionTrainingAssignResponse, ResponseOk>[]) => {
          const { status } = response[0]
          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response[0]
            dispatch(createToast(data.message, 'danger'))
          } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
            const { data } = response[0]
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        }
      )
  }

  const swapPriority = (from: number | true, to: number | true, update: boolean) => {
    if (!statementId || from === true || to === true) {
      return
    }

    api.client
      .statementsDirectionTrainingsSwapPriority({
        to: to,
        statement: Number(statementId),
        from: from,
      })
      .then((response) => {
        const { data } = response as ResponseOk
        update && data.message && dispatch(createToast(data.message, 'success'))

        //TODO IS-620 диспатчит сразу и блок заявлений и блок приоритетов надо чтобы сносил только заявление
        //dispatch(getBlankPriorityStatement(Number(statementId)))

        //  update && dispatch(getDirectionsListStatements(Number(statementId)))
      })
      .catch(
        (response: Exclude<UpdateDirectionTrainingAssignResponse, ResponseOk>[]) => {
          const { status } = response[0]
          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response[0]
            dispatch(createToast(data.message, 'danger'))
          } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
            const { data } = response[0]
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        }
      )
  }

  const assent = (id) => {
    if (statementId) {
      return api.client
        .statementsDirectionTrainingsAssent({
          statement: Number(statementId),
          directionTraining: id,
        })
        .then((response) => {
          const { data } = response as ResponseOk
          dispatch(createToast(data.message, 'success'))
          dispatch(getDirectionsListStatements(Number(statementId)))
        })
        .catch(
          (response: Exclude<UpdateDirectionTrainingAssignResponse, ResponseOk>) => {
            const { status } = response
            if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
              const { data } = response
              dispatch(createToast(data.message, 'danger'))
            } else {
              dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
            }
          }
        )
    }
  }
  const rejection = (id) => {
    if (statementId) {
      return api.client
        .statementsDirectionTrainingsRejection({
          statement: Number(statementId),
          directionTraining: id,
        })
        .then((response) => {
          const { data } = response as ResponseOk
          dispatch(createToast(data.message, 'success'))
          dispatch(getDirectionsListStatements(Number(statementId)))
        })
        .catch(
          (response: Exclude<UpdateDirectionTrainingAssignResponse, ResponseOk>) => {
            const { status } = response
            if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
              const { data } = response
              dispatch(createToast(data.message, 'danger'))
            } else {
              dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
            }
          }
        )
    }
  }

  const openModal = (itemId, reject?) => {
    if (reject) {
      dispatch(
        showModal({
          name: 'DIRECTIONS_MODAL',
          data: {
            func: () => rejection(itemId),
            text: terms.REJECT_MODAL,
            onNextPageNavigate,
          },
        })
      )
    } else {
      dispatch(
        showModal({
          name: 'DIRECTIONS_MODAL',
          data: {
            func: () => assent(itemId),
            text: terms.CONFIRM_MODAL,
            onNextPageNavigate,
          },
        })
      )
    }
  }

  return { assent, rejection, deleteElement, order, swap, swapPriority, openModal }
}

export default useDirectionsTrainingElement
