import React from 'react'

import ConfirmAccountForm from 'components/ConfirmAccountForm'
import TemplateAuthPage from 'pages/TemplateAuthPage'

import terms from '@common/terms'

const ConfirmAccountPage = () => {
  return (
    <TemplateAuthPage>
      <ConfirmAccountForm title={terms.EMAIL_CONFIRMED} />
    </TemplateAuthPage>
  )
}

export default ConfirmAccountPage
