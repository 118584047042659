import React, { useEffect } from 'react'

import classnames from 'classnames'

import AddIcon from '@assets/images/Add.svg'
import FullscreenIcon from '@assets/images/Fullscreen.svg'
import profileImagePlaceholder from '@assets/images/profile-image-placeholder.png'
import TrashIcon from '@assets/images/Trash.svg'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { showModal } from '@redux/action-types'
import {
  selectIsQuestionnaireBlockProfile,
  selectViewerProfileImage,
} from '@redux/actions/selectors'
import { getViewerProfileImage } from '@redux/reducers'

type ProfileImageProps = {
  className?: string
  error?: string
}

const ProfileImage = ({ className, error }: ProfileImageProps) => {
  const dispatch = useAppDispatch()

  const onOpenProfileImageModal = (mode: 'upload' | 'edit' | 'delete') => {
    dispatch(showModal({ name: 'EDIT_PROFILE_IMAGE_MODAL', data: { mode } }))
  }

  const image = useAppSelector(selectViewerProfileImage)
  const readOnly = useAppSelector(selectIsQuestionnaireBlockProfile)

  useEffect(() => {
    dispatch(getViewerProfileImage())
  }, [image])

  return (
    <div className={classnames(className, 'profile-image-block')}>
      <div className="profile-image-block__title form-subtitle">{terms.PHOTO}</div>
      <div className="profile-image-block__img-wrapper">
        <img
          src={image || profileImagePlaceholder}
          alt={terms.PHOTO}
          className="profile-image-block__img"
        />

        {!readOnly && (
          <div className="profile-image-block__controls image-controls">
            <div className="image-controls__top-row">
              <button
                className="image-controls__button image-controls__button--theme-rounded"
                type="button"
                onClick={() => onOpenProfileImageModal('delete')}
              >
                <TrashIcon className="image-controls__icon" />
              </button>
            </div>
            <div className="image-controls__bottom-row">
              <button
                className="image-controls__button"
                onClick={() => onOpenProfileImageModal('upload')}
                type="button"
              >
                <AddIcon className="image-controls__icon" />
                <span className="image-controls__label">{terms.SELECT_PHOTO}</span>
              </button>
              <button
                type="button"
                className="image-controls__button"
                onClick={() => onOpenProfileImageModal('edit')}
              >
                <FullscreenIcon className="image-controls__icon" />
                <span className="image-controls__label">{terms.EDIT_PHOTO}</span>
              </button>
            </div>
          </div>
        )}
      </div>
      {error && <div className="profile-image-block__error">{error}</div>}
    </div>
  )
}

export default ProfileImage
