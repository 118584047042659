import { useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  HIGHER_EDUCATION,
  WITHOUT_PREV_EDUCATION,
  WITHOUT_PREV_EDUCATION_CODE,
} from '@common/constants'
import regexp from '@common/regexp'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useProfileApiHandling from '@hooks/useProfileApiHandling'
import {
  dateLessThanCurrentOne,
  normalizeYearTo4Characters,
  validateDate,
} from '@lib/validators'
import { EducationData, EducationFormData, SelectOption } from '@models'
import { getProfileEducation } from '@redux/action-types'
import {
  selectLevelEducKeysOptions,
  selectLocalizedViewerProfile,
} from '@redux/actions/selectors'
import api from '@services/api'

const useEducationForm = () => {
  const { watch, register, handleSubmit, setError, setValue, clearErrors, ...rest } =
    useForm<EducationData>({ mode: 'all' })

  const dispatch = useAppDispatch()
  const [isHigherEducation, setIsHigherEducation] = useState<boolean>(false)
  const levelsEducationCodes = useAppSelector(selectLevelEducKeysOptions)
  const { callApi, loading } = useProfileApiHandling({
    apiFn: api.client.updateProfileEducation,
    setError,
    onSuccessCb: () => dispatch(getProfileEducation()),
  })
  let higherEducationIds: any = []
  levelsEducationCodes.map((value) => {
    if (value.uniqueKeyOnSite == WITHOUT_PREV_EDUCATION_CODE) {
      higherEducationIds.push(value.id)
    }
  })
  const typeEducation = watch('typeEducationDocumentId') as unknown as
    | SelectOption
    | undefined

  const gpaFront = watch('userScore')
  const profile = useAppSelector(selectLocalizedViewerProfile)

  let educationNationalityId = 0

  const fields = {
    levelEducationId: register('levelEducationId', {
      required: terms.REQUIRED_FIELD,
    }),
    typeEducationDocumentId: register('typeEducationDocumentId', {
      required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) => {
        setValue('levelEducationId', null)
        if (
          value?.value !== 4 &&
          value?.value !== 5 &&
          value?.value !== 6 &&
          value?.value !== 7
        ) {
          setValue('poory', undefined)
          setValue('okay', undefined)
          setValue('great', undefined)
          clearErrors('poory')
          clearErrors('okay')
          clearErrors('great')
          setValue('userScore', undefined)
        }
        value?.value &&
          setIsHigherEducation(higherEducationIds.includes(value?.value))
      },
    }),
    noMatchDirectionTraining: register('noMatchDirectionTraining', {
      onChange: () => {
        setValue('directionTrainingId', null)
      },
    }),
    directionTrainingId: register('directionTrainingId', {
      required:
        isHigherEducation && !watch('noMatchDirectionTraining')
          ? terms.REQUIRED_FIELD
          : '',
    }),
    formEducationId: register('formEducationId', {
      required: terms.REQUIRED_FIELD,
    }),
    nationalityId: register('nationalityId', {
      required: terms.REQUIRED_FIELD,
    }),
    nameEducationInstitution: register('nameEducationInstitution', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: regexp.checkingSymbolsNumberAndCyrillicDashInString,
        message: terms.CYRILLIC_AND_NUMBERS_ONLY,
      },
    }),
    numberEducationDocument: register('numberEducationDocument', {
      required: terms.REQUIRED_FIELD,
    }),
    foreignLanguageStudy: register('foreignLanguageStudy', {
      required: terms.REQUIRED_FIELD,
    }),
    dateDocumentIssue: register('dateDocumentIssue', {
      required: terms.REQUIRED_FIELD,
      validate: (value) =>
        dateLessThanCurrentOne(value)
          ? profile?.dateBirthday != undefined
            ? profile?.dateBirthday < value
              ? true
              : terms.DATE_BIRTHDAY_ISSUE_INVALID
            : ''
          : terms.DATE_DOCUMENT_ISSUE_INVALID,
      onChange: ({ target: { value } }) =>
        value && setValue('dateDocumentIssue', normalizeYearTo4Characters(value)),
    }),
    yearGraduation: register('yearGraduation', {
      required: terms.REQUIRED_FIELD,
      validate: (value) =>
        profile?.dateBirthday != undefined
          ? new Date(profile?.dateBirthday) < new Date(value + '-01-01')
            ? dateLessThanCurrentOne(value)
              ? true
              : terms.DATE_DOCUMENT_ISSUE_INVALID
            : terms.DATE_BIRTHDAY_ISSUE_INVALID
          : terms.DATE_DOCUMENT_ISSUE_INVALID,
    }),
    finishedSpbpu: register('finishedSpbpu', {
      onChange: (e) => {
        const { checked } = e.target
        if (checked) {
          setValue('nameEducationInstitution', terms.POLYTECH_FULLNAME)
          clearErrors('nameEducationInstitution')
        }
      },
    }),
    diplomaWithHonors: register('diplomaWithHonors'),
    studiedPrepatoryDepartment: register('studiedPrepatoryDepartment'),
    graduatePsychologicalAndPedagogicalClass: register(
      'graduatePsychologicalAndPedagogicalClass'
    ),
    poory: register('poory', {
      onChange: ({ target: { value } }) => {
        setValue('poory', value >= 0 ? value : 0)
        setValue('userScore.0', {
          code: 'PR-03-01',
          count: Number(value) >= 0 ? value : 0,
        })
      },
    }),
    okay: register('okay', {
      onChange: ({ target: { value } }) => {
        setValue('okay', value >= 0 ? value : 0)
        setValue('userScore.1', {
          code: 'OK-04-02',
          count: Number(value) >= 0 ? value : 0,
        })
      },
    }),
    great: register('great', {
      onChange: ({ target: { value } }) => {
        setValue('great', value >= 0 ? value : 0)
        setValue('userScore.2', {
          code: 'GR-05-03',
          count: Number(value) >= 0 ? value : 0,
        })
      },
    }),
    userScore: register('userScore'),
  }

  const onSubmit = handleSubmit((data: any) => {
    const normalizedData: EducationData = {
      ...data,
      nationalityId: data.nationalityId.value,
      formEducationId: data.formEducationId.value,
      levelEducationId: data.levelEducationId.value,
      typeEducationDocumentId: data.typeEducationDocumentId.value,
      directionTrainingId: data.directionTrainingId?.value,
    }
    callApi(normalizedData)
  })
  const isGraduatePsychologicalAndPedagogicalClass = watch(
    'graduatePsychologicalAndPedagogicalClass'
  )

  return {
    gpaFront,
    fields,
    watch,
    onSubmit,
    loading,
    setValue,
    isHigherEducation,
    typeEducation,
    setIsHigherEducation,
    isGraduatePsychologicalAndPedagogicalClass,
    educationNationalityId,
    ...rest,
  }
}

export default useEducationForm
