import HttpStatusCode from '../common/httpStatusCodes'
import terms from '../common/terms'
import api, { ResponseOk, UpdateViewerSubscribeBlankResponse } from '../services/api'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import useDownloadTemplateDocument from './useDownloadTemplateDocument'
import { mimeTypes } from '@common/manual'
import {
  createToast,
  getBlankPriorityStatement,
  getBlankStatement,
  getDirectionsListStatements,
} from '@redux/action-types'
import { selectStatementsElement } from '@redux/actions/selectors'
import useDownloadTemplateDocumentPriority from '@hooks/useDownloadTemplateDocumentPriority'

const useCardForDownloadFile = (
  acceptBlank: boolean,
  blankId: number,
  title: string
) => {
  const dispatch = useAppDispatch()
  const statement = useAppSelector(selectStatementsElement)
  const { downloadBlankPriority } = useDownloadTemplateDocumentPriority()
  const { downloadBlank } = useDownloadTemplateDocument()

  const handleClickDownloadDocs = () => {
    if (statement) {
      downloadBlank(statement.id, blankId, mimeTypes.docx, 'docx', title)
    }
  }

  const handleClickDownloadDocsPriority = () => {
    if (statement) {
      downloadBlankPriority(statement.id, blankId, mimeTypes.docx, 'docx', title)
    }
  }
  const subscribeBlankPriority = () => {
    if (acceptBlank) {
      return
    }
    if (statement) {
      api.client //TODO
        .subscribeBlank({ statement: statement.id, blankId: blankId })
        .then((response) => {
          const { data } = response as ResponseOk
          dispatch(createToast(data.message, 'success'))
          dispatch(getBlankPriorityStatement(statement.id))
          dispatch(getDirectionsListStatements(statement.id))
        })
        .catch(
          (response: Exclude<UpdateViewerSubscribeBlankResponse, ResponseOk>) => {
            const { status } = response
            if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
              const { data } = response
              dispatch(createToast(data.message, 'danger'))
            } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
              const { data } = response
              dispatch(createToast(data.message, 'danger'))
            } else {
              dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
            }
          }
        )
    }
  }

  const subscribeBlank = () => {
    if (acceptBlank) {
      return
    }
    if (statement) {
      api.client
        .subscribeBlank({ statement: statement.id, blankId: blankId })
        .then((response) => {
          const { data } = response as ResponseOk
          dispatch(createToast(data.message, 'success'))
          dispatch(getBlankStatement(statement.id))
        })
        .catch(
          (response: Exclude<UpdateViewerSubscribeBlankResponse, ResponseOk>) => {
            const { status } = response
            if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
              const { data } = response
              dispatch(createToast(data.message, 'danger'))
            } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
              const { data } = response
              dispatch(createToast(data.message, 'danger'))
            } else {
              dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
            }
          }
        )
    }
  }
  return {
    handleClickDownloadDocs,
    subscribeBlank,
    subscribeBlankPriority,
    handleClickDownloadDocsPriority,
  }
}

export default useCardForDownloadFile
