import React, { useEffect, useState } from 'react'

import './SettingAuthExchange1CPage.scss'
import Edit from '@assets/images/Edit.svg'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useSiteExchangeSetting from '@hooks/useSiteExchangeSetting'
import { getAuthPagesList } from '@redux/action-types/admin'
import { selectAdminDirectoriesList } from '@redux/actions/selectors'
import Loader from '@ui/Loader'
import api from '@services/api'
import { PageDataView } from '@models'
import { saveData } from '@hooks/useViewData'
import { useCode } from '@hooks/useСode'
import { ExchangeStatus } from '../../../models/settings'

const SettingExchange1CPage = () => {
  const { editDirectory } = useSiteExchangeSetting()

  const [status, setStatus] = useState<ExchangeStatus[] | null | undefined>(null)

  useEffect(() => {
    api.client.getExchangeStatus().then((response) => {
      const { data } = response as unknown as ExchangeStatus
      setStatus(data)
    })
  }, [setStatus])
  // let data = []
  // if (status?.data) {
  //   data = status.data[0]
  //   saveData(status.data[0])
  // }

  //Ссылки на методы бэка по обновлению справочников
  const links = [
    [
      '/exchangeLanguages',
      'языков',
      'Выгружает языки, используемые в ЭПК. Данная выгрузка должна проходить в первую очередь, т.к. почти все остальные выгрузки ссылаются на данную таблицу.',
    ],
    [
      '/exchangeScenario',
      'сценарии поступления',
      'Выгружает и обновляет сценарии поступления. К примеру: 2023 Аспирантура, 2023 Бакалавриат РФ и т.д.',
    ],
    [
      '/exchangeOlympiadDoc',
      'документов подтверждающих участие в олимпиаде',
      'Выгружает и обновляет список документов подтверждающих участие в олимпиаде. К примеру: Диплом победителя т.д.',
    ],

    [
      '/exchangeBlank',
      'бланков',
      'Выгружает типы документов такие как: согласие на зачисление, согласие на обработку персональных данных и т.д.',
    ],
    [
      '/exchangeAchivment',
      'достижений',
      'Выгружает и обновляет возможные варианты селекта "Достижения, приемущественные права" и возможных документов, подтверждающих их наличие',
    ],
    [
      '/exchangeCountryPhone',
      'телефонов',
      'Выгружает и обновляет телефонные коды стран. К примеру: +7 - Россия и т.д. ',
    ],
    [
      '/exchangeDiscipline',
      'дисциплин',
      'Выгружает и обновляет дисциплины. К примеру: Дизайн, История и т.д.',
    ],
    [
      '/exchangeDisciplineDate',
      'дат дисциплин',
      'Описание временно отсутствует, т.к. нет занесенных данных 1С',
    ],

    ['/exchangeCompetitiveGroupLimit', 'лимиты конкрусных групп', ''],

    ['/exchangeGender', 'гендеров', 'Выгружает и обновляет гендеры.'],
    [
      '/exchangeFormPayment',
      'форм оплаты',
      'Выгружает и обновляет справочник форм оплаты. К примеру: Платное обучение, Целевая квота и т.д. ',
    ],
    [
      '/exchangeFormEducation',
      'форм обучения',
      'Выгружает и обновляет справочник форм обучения. К примеру: очная, заочная и т.д.',
    ],
    // ['/exchangeDocLgota','документы подтверждающие льготу'],
    [
      '/exchangeLgota',
      'льгот',
      'Выгружает и обновляет возможные варианты селекта "Льготы" и возможных документов, подтверждающих ее наличие',
    ],
    [
      '/exchangeStudiedLanguage',
      'языков изученных в образовательных учреждениях',
      'Выгружает и обновляет возможные варианты селекта "Язык изученный в образовательном учреждении"',
    ],
    ['/exchangeDirectionTraining', 'направлений поступлений', ''],
    ['/exchangeEducationProfile', 'профилей обучения', ''],
    ['/exchangeDisciplinesEge', 'предметов егэ', ''],
    ['/exchangeMaritalStatus', 'статусов брака', ''],
    ['/exchangeVisa', 'городов и стран выдачи виз', ''],
    ['/exchangeTypeIdentityDocuments', 'идентифицирующих документов', ''],
    ['/exchangeNationality', 'национальностей', ''],
    ['/exchangeRelationDegrees', 'законных представителей', ''],
    ['/exchangePreemptive', 'приемущественных прав', ''],
    ['/exchangeLevelDocEducation', 'уровней обучения', ''],
    ['/exchangeTrialTypes', 'типов вступительных испытаний', ''],
    [
      '/exchangeLocations',
      'локаций',
      'Выгружает и обновляет данные по локациям. К примеру: Алдан(Якутия), ' +
        'Корсаков(Сахалинская область) и т.д.. Используется в селекте на вкладке "Договор" ',
    ],
    [
      '/exchangePartners',
      'список партнеров',
      'Выгружает и обновляет список партнеров.',
    ],
    ['/exchangeMethodApplication', 'способов связи', ''],
    [
      '/exchangeTrialEducationFilter',
      'связи вступительных испытаний с уровнем подготовки',
      '',
    ],
    ['/exchangeScenarioParameter', 'параметры сценариев', ''],
    [
      '/exchangeFaculty',
      'факультеты',
      'Выгружает и обновляет факультеты. К примеру: Факультет биологии, Институт физики и т.д.',
    ],
    [
      '/exchangeTrainingLevel',
      'уровней обучения',
      'Выгружает и обновляет уровни обучения. К примеру: Аспирантура, Бакалвариат, и т.д.',
    ],
    [
      '/exchangeContractType',
      'типов контрактов',
      'Выгружает и обновляет типы контрактов. К примеру: название договоров - "Договор об оказании платных образовательных услуг для студентов - граждан Российской...".',
    ],
    [
      '/exchangeCompetitiveGroupContractParameters',
      'параметров контрактов',
      'Выгружает и обновляет параметры контрактов. К примеру: год/месяц (срок обучения), принадлежность контракта к траектории и т.д.' +
        'Предварительно необходимо выгрузить справочник по типам контрактов (находится выше)',
    ],
    [
      '/exchangeCheckbox',
      'чекбоксов',
      'Выгружает и обновляет чекбосы на вкладке заявления. К примеру: подтверждение об ознакомлении, подтверждение о подаче заявления, и т.д.' +
        ' Перед данной выгрузкой необходимо произвести выгрузку "уровней обучения".',
    ],

    //  ['/exchangeDisciplineDate','дат дисциплин'],
    //  ['/exchangeEducationProfil','образовательных профилей'],
  ]

  const personal = [
    [
      '/exchangeCompetitiveGroupExchangeAsync',
      'конкурсные группы',
      'Метод выгружает конкурсные группы и все новые данные из связанных с ними таблиц: ' +
        'направления поступлений, факультетов, профилей обучения, форм обучения, форм оплаты, уровня образования.' +
        ' Данный метод не обновляет их, а просто выгружает, если не находит соответсвующий external_id в базе. ' +
        'Для обновления значений связанных таблиц используйте отдельные методы, расположенные ниже. ' +
        'Выгрузка проходит в фоновом режиме.',
    ],
    [
      '/exchangeTrialsAsync',
      'вступительных испытаний',
      'Выгружает и обновляет вступительные испытания. Перед использованием необходимо убедиться, что в базу данных уже успешно выгружены конкурсные группы. ' +
        'Выгрузка проходит в фоновом режиме.',
    ],
    [
      '/exchangeOlympiadAsync',
      'олимпиад',
      'Выгружает и обновляет список олимпиад (документы олимпиад обновляются отдельно). Перед использованием необходимо убедиться, что в базу данных уже успешно выгружены данные по сценариям поступления.' +
        'Выгрузка проходит в фоновом режиме.',
    ],
  ]

  const general = [
    [
      '/startExchange',
      'всех данных',
      'Ввиду объема, выгрузка проходит в фоновом режиме. Выгрузка может длиться до 15 минут. ' +
        'Статус последней выгрузки расположен под строкой вызова этого модального окна.',
    ],
  ]

  return (
    <div>
      <div className="settings-page-header">
        <h1 className="directory-page__title">
          {terms.EXCHANGE_GENERAL_LINKS_TITLE}
        </h1>
      </div>
      <div className="table-incoming-students">
        {general?.map(function (d) {
          return (
            <div
              key={d[0]}
              className="table-incoming-students__link edit-line-row"
              onClick={() => editDirectory(d)}
            >
              <div>Полная выгрузка данных из 1С на сайт {d[1]}</div>
              <div>
                <Edit width="18" height="18" />
              </div>
            </div>
          )
        })}
        {status?.map(function (t) {
          return (
            <div key={t['id']}>
              {t['method'] == 'fullExchange' ? (
                <div className="status-exchange-row">
                  <div>Последняя полная выгрузка была: {t['updatedAt']}</div>
                  <div>
                    Выгрузка закончилась:{' '}
                    {t['status']
                      ? 'Успешно'
                      : 'С ошибкой или еще идет. Код ошибки ' +
                        t['errorCode'] +
                        '. Если от времени последней выгрузки прошло более 15 минут, обратитесь к системному администратору'}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          )
        })}
      </div>
      <div className="settings-page-header">
        <h1 className="directory-page__title">{terms.EXCHANGE_HUGE_DATA_TITLE}</h1>
      </div>
      <div className="table-incoming-students">
        {personal?.map(function (b) {
          return (
            <div key={b[0]}>
              <div
                className="table-incoming-students__link edit-line-row with_status"
                onClick={() => editDirectory(b)}
              >
                <div>Обновить данные по справочнику {b[1]}</div>
                <div>
                  <Edit width="18" height="18" />
                </div>
              </div>
              {status?.map(function (d) {
                return (
                  <div key={d['method'] + b[0]}>
                    {d['method'] == 'competitiveGroup' &&
                    b[0] == '/exchangeCompetitiveGroupExchangeAsync' ? (
                      <div className="status-exchange-row ">
                        <div>Последняя полная выгрузка была: {d['updatedAt']}</div>
                        <div>
                          Выгрузка закончилась:{' '}
                          {d['status'] ? 'Успешно' : 'С ошибкой или еще идет.'}
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {d['method'] == 'olympiad' &&
                    b[0] == '/exchangeOlympiadAsync' ? (
                      <div className="status-exchange-row">
                        <div>Последняя полная выгрузка была: {d['updatedAt']}</div>
                        <div>
                          Выгрузка закончилась:{' '}
                          {d['status'] ? 'Успешно' : 'С ошибкой или еще идет.'}
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {d['method'] == 'trials' && b[0] == '/exchangeTrialsAsync' ? (
                      <div className="status-exchange-row">
                        <div>Последняя полная выгрузка была: {d['updatedAt']}</div>
                        <div>
                          Выгрузка закончилась:{' '}
                          {d['status'] ? 'Успешно' : 'С ошибкой или еще идет.'}
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      <div className="settings-page-header">
        <h1 className="directory-page__title">{terms.EXCHANGE_LINKS_TITLE}</h1>
      </div>
      <div className="table-incoming-students">
        {links?.map(function (d) {
          return (
            <div
              key={d[0]}
              className="table-incoming-students__link edit-line-row"
              onClick={() => editDirectory(d)}
            >
              <div>Обновить данные по справочнику {d[1]}</div>
              <div>
                <Edit width="18" height="18" />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SettingExchange1CPage
