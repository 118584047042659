import React from 'react'

import DownloadFileLgotaIcon from '@assets/images/DownloadFileLgota.svg'
import EditFileLgotaIcon from '@assets/images/EditFileLgota.svg'
import RemoveFileLgotaIcon from '@assets/images/RemoveFileLgota.svg'
import { PrivilegesTableProps } from '@models'

const PrivilegesTable = ({
  head,
  rows,
  readOnly,

  buttons = false,
  onDelete,
  onEdit,
  onDownload,
}: PrivilegesTableProps): JSX.Element => {
  return (
    <table className="privileges-table">
      <thead>
        <tr>
          {Object.values(head).map((el, index) => (
            <td key={index}>{el}</td>
          ))}
          {buttons && <td />}
        </tr>
      </thead>
      <tbody>
        {rows.map((el, index) => {
          return (
            <tr key={index}>
              <td>{el.typeLgota}</td>
              <td>{el.typeDoc}</td>
              <td>{el.number}</td>
              <td>{el.dateIssue}</td>
              {buttons && (
                <td>
                  <div className="privileges-table__icons">
                    <button type={'button'} onClick={() => onDownload?.(el.docId)}>
                      <DownloadFileLgotaIcon />
                    </button>
                    <button
                      type={'button'}
                      disabled={readOnly}
                      onClick={() => onEdit?.(el.id)}
                    >
                      <EditFileLgotaIcon />
                    </button>
                    <button
                      type={'button'}
                      disabled={readOnly}
                      onClick={() => onDelete?.(el.docId)}
                    >
                      <RemoveFileLgotaIcon />
                    </button>
                  </div>
                </td>
              )}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default PrivilegesTable
