import React from 'react'

import DownloadFilesForm from '@components/DownloadFilesForm'

const DownloadsPage = (scenarioKeys) => {
  return (
    <div>
      <DownloadFilesForm {...scenarioKeys} />
    </div>
  )
}

export default DownloadsPage
