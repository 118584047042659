import { useEffect } from 'react'
import { UseFormSetValue, UseFormWatch, useWatch } from 'react-hook-form'

import { RUSSIA_NATIONAL_ID } from '@common/constants'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useInitializeInputs from '@hooks/useInitializeInputs'
import useInitializeSelect from '@hooks/useInitializeSelect'
import usePaginatedNationals from '@hooks/usePaginatedNationals'
import usePaginatedNationalsIno from '@hooks/usePaginatedNationalsIno'
import usePaginatedNationalsRus from '@hooks/usePaginatedNationalsRus'
import usePaginatedPartners from '@hooks/usePaginatedPartners'
import usePaginatedPhoneCodes from '@hooks/usePaginatedPhoneCodes'
import { PersonalData, SelectOption } from '@models'
import {
  getGenders,
  getMaritalStatuses,
  getPartnersList,
  getPersonalData,
  getWorks,
} from '@redux/action-types'
import {
  selectGendersOptions,
  selectWorksOptions,
  selectPersonalFormData,
  selectMaritalStatusesOptions,
  selectPartnersOptions,
} from '@redux/actions/selectors'

type usePersonalDataFormSelectorsProps = {
  watch: UseFormWatch<PersonalData>
  setValue: UseFormSetValue<PersonalData>
}

const usePersonalDataFormInitialization = ({
  setValue,
  watch,
}: usePersonalDataFormSelectorsProps) => {
  const dispatch = useAppDispatch()
  const gender = watch('gender') as unknown as SelectOption

  useEffect(() => {
    !personalData && dispatch(getPersonalData())
    !genderOptions.length && dispatch(getGenders())
    !workOptions.length && dispatch(getWorks())
  }, [])

  useEffect(() => {
    gender?.value && dispatch(getMaritalStatuses(gender.value))
  }, [gender])

  const personalData = useAppSelector(selectPersonalFormData)
  const genderOptions = useAppSelector(selectGendersOptions)
  const workOptions = useAppSelector(selectWorksOptions)
  const maritalStatusOptions = useAppSelector(selectMaritalStatusesOptions)
  const partnersOptions = useAppSelector(selectPartnersOptions)

  const nationalSelectProps = usePaginatedNationals()
  const nationalSelectPropsRus = usePaginatedNationalsRus()
  const nationalSelectPropsIno = usePaginatedNationalsIno()
  const phoneCodesSelectProps = usePaginatedPhoneCodes()
  const partnersSelectProps = usePaginatedPartners()

  useInitializeInputs({
    data: personalData,
    omitKeys: ['gender'],
    onInit: (key, value) => {
      const mapper = {
        nationality: 'nationalOption',
        recruitingCompany: 'recruitingOption',
        partner: 'partnerOption',
        phoneCode: 'phoneCodeOption',
        additionalPhoneCode: 'additionalPhoneCodeOption',
        partnerOrganizationCode: 'partnerOrganizationCodeOption',
      }

      if (Object.keys(mapper).includes(key)) {
        setValue(key, personalData?.[mapper[key]] as any)
      } else {
        if (
          key === 'noSnils' &&
          !personalData?.snils &&
          value === false &&
          personalData?.nationalOption?.value !== RUSSIA_NATIONAL_ID
        ) {
          setValue('noSnils', true)
        } else {
          setValue(key, value)
        }
        if (
          key === 'noDateSnils' &&
          !personalData?.dateSnils &&
          value === false &&
          personalData?.dateSnils === null &&
          personalData?.snils !== null
        ) {
          setValue('noDateSnils', true)
        } else {
          setValue(key, value)
        }
      }
    },
  })

  useInitializeSelect({
    data: personalData,
    options: genderOptions,
    findValue: personalData?.gender,
    onInit: (option) => setValue('gender', option as any),
  })

  useInitializeSelect({
    data: personalData,
    options: workOptions,
    findValue: personalData?.workId,
    onInit: (option) => setValue('workId', option as any),
  })

  useInitializeSelect({
    data: personalData,
    options: maritalStatusOptions,
    findValue: personalData?.maritalStatusId,
    onInit: (option) => setValue('maritalStatusId', option as any),
  })

  useInitializeSelect({
    data: personalData,
    options: partnersSelectProps.options,
    findValue: personalData?.recruitingCompany,
    onInit: (option) => setValue('recruitingCompany', option as any),
  })

  return {
    genderOptions,
    nationalSelectProps,
    nationalSelectPropsRus,
    nationalSelectPropsIno,
    phoneCodesSelectProps,
    partnersSelectProps,
    workOptions,
    maritalStatusOptions,
    personalData,
  }
}

export default usePersonalDataFormInitialization
