import React, { useEffect, useState } from 'react'

import terms from '@common/terms'
import FillingStatementsForm from '@components/FillingStatementsForm'
import PrivilegesTable from '@components/PrivilegesTable'
import ProfileSection from '@components/ProfileSection'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useHandlerLgotaInitialization from '@hooks/useHandlerLgotaInitialization'
import useReadOnlyFillingStatements from '@hooks/useReadOnlyFillingStatements'
import useHandlerLgota from '@hooks/useReferenceMSELgota'
import useSpecialConditionsForm from '@hooks/useSpecialConditionsForm'
import useUploadDocuments from '@hooks/useUploadDocuments'
import { IHeadTable, LgotaSpecialDocs } from '@models'
import {
  listLgotaDoc,
  listPreemptiveRight,
  selectStatementsElement,
  selectUserIdProfile,
  selectSpecialCondition,
  selectUploadedLgotaDocsState,
} from '@redux/actions/selectors'
import { getUserLgota } from '@redux/reducers/'
import Button from '@ui/Button'
import Checkbox from '@ui/Checkbox'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import { base64ToPdf, trimFileExtension } from '@utils/common'

const PrivilegesAndSpecialConditionsPage = (scenarioKeys) => {
  const dispatch = useAppDispatch()
  const listLgota = useAppSelector(listLgotaDoc)
  const listPreemptive = useAppSelector(selectSpecialCondition)
  const listSpecialCondition = useAppSelector(selectSpecialCondition)
  const listReference_MSE = useAppSelector(selectSpecialCondition)
  const uploadedLgotaDocs = useAppSelector(selectUploadedLgotaDocsState)
  const [listLgotaReason, setListLgotaReason] = useState<LgotaSpecialDocs[]>([])
  const [listLgotaSpecial, setListLgotaSpecial] = useState<LgotaSpecialDocs[]>([])
  const [listLgotaReference_MSE, setListLgotaReference_MSE] = useState<
    LgotaSpecialDocs[]
  >([])
  const [listPresidentLgota, setListPresidentLgota] = useState<LgotaSpecialDocs[]>(
    []
  )
  const [listPresidentLgotaB, setListPresidentLgotaB] = useState<LgotaSpecialDocs[]>(
    []
  )
  const {
    setValue,
    fields,
    formState: { errors },
    haveSpecialConditions,
    onSubmit,
    haveReferenceMse,
    notHaveLgot,
    addUploadedLgotaDocsState,
    deleteUploadedLgotaDocsState,
    getUploadedLgotaDocs,
    clearUploadedDocsState,
    watch,
  } = useHandlerLgota(setListLgotaReason, setListLgotaSpecial)
  const {
    getDoc,
    formState: {},
  } = useSpecialConditionsForm()
  useHandlerLgotaInitialization({ setValue })

  const { readOnly } = useReadOnlyFillingStatements()
  const statement = useAppSelector(selectStatementsElement)
  const userId = useAppSelector(selectUserIdProfile)
  const isVisibleHaveReferenceMse =
    statement?.scenarioId !== scenarioKeys.asperatnuta_rf.tdId &&
    statement?.scenarioId !== scenarioKeys.level_training_preparation.tdId

  useEffect(() => {
    if (statement && userId) {
      dispatch(getUserLgota({ statementId: statement.id, userId: userId }))
      dispatch(clearUploadedDocsState({}))
    }
  }, [statement, userId])

  useEffect(() => {
    if (uploadedLgotaDocs['lgota_special_conditions'])
      setValue('fileSpecialCondition', uploadedLgotaDocs['lgota_special_conditions'])
  }, [uploadedLgotaDocs['lgota_special_conditions']])

  useEffect(() => {
    if (uploadedLgotaDocs['reference_MSE'])
      setValue('fileReferenceMse', uploadedLgotaDocs['reference_MSE'])
  }, [uploadedLgotaDocs['reference_MSE']])

  useEffect(() => {
    listSpecialCondition[0]?.statementDoc.length &&
      setListLgotaSpecial(
        listSpecialCondition[0]?.statementDoc.filter(
          (file) => file.userDoc.getTypeDocs.name === 'lgota_special_conditions'
        )
      )
    listReference_MSE[0]?.statementDoc.length &&
      setListLgotaReference_MSE(
        listReference_MSE[0]?.statementDoc.filter(
          (file) => file.userDoc.getTypeDocs.name === 'reference_MSE'
        )
      )
  }, [listSpecialCondition, listReference_MSE])

  useEffect(() => {
    listLgotaSpecial.length &&
      getUploadedLgotaDocs('LGOTA_SPECIAL', listLgotaSpecial[0].userDocId)
        .then(({ data }) =>
          base64ToPdf(
            data.document,
            trimFileExtension(listLgotaSpecial[0].userDoc.name)
          )
        )
        .then((data) =>
          dispatch(
            addUploadedLgotaDocsState({
              ['lgota_special_conditions']: [data],
            })
          )
        )
    listLgotaReference_MSE.length &&
      getUploadedLgotaDocs('REFERENCE_MSE', listLgotaReference_MSE[0].userDocId)
        .then(({ data }) =>
          base64ToPdf(
            data.document,
            trimFileExtension(listLgotaReference_MSE[0].userDoc.name)
          )
        )
        .then((data) =>
          dispatch(
            addUploadedLgotaDocsState({
              ['reference_MSE']: [data],
            })
          )
        )
  }, [listLgotaSpecial, listLgotaReason, listPresidentLgota, listPresidentLgotaB])

  return (
    <FillingStatementsForm
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit()
        // onUploadReferenceMSE(() => getReferenceMSEDoc())
        // onUploadSpecialConditions(() => getSpecialConditionsDoc())
      }}
    >
      <div className="privileges-page">
        <Checkbox
          readOnly
          disabled={readOnly}
          text={'У меня нет специальных условий'}
          {...fields.notHaveLgot}
        />
        {!notHaveLgot && (
          <div className="privileges-page">
            <div>
              {isVisibleHaveReferenceMse && (
                <Checkbox
                  disabled={readOnly}
                  text={
                    'Имею основание для поступления за счет бюджетных ассигнований федерального бюджета на основании пункта 8.1 статьи 79 Федерального закона от 29.12.2012 N 273-ФЗ «Об образовании в Российской Федерации»'
                  }
                  {...fields.haveReferenceMse}
                />
              )}
              {haveReferenceMse && (
                <div className="special-docs__container">
                  <ProfileSection
                    title="Копия документа"
                    markRequired={true}
                    fileUploadingHint
                    hint={'Справка МСЭ об установлении инвалидности'}
                  >
                    <FileUploader
                      files={uploadedLgotaDocs['reference_MSE'] || []}
                      onDrop={(file) =>
                        dispatch(
                          addUploadedLgotaDocsState({
                            ['reference_MSE']: file,
                          })
                        )
                      }
                      onChange={(file) =>
                        dispatch(
                          addUploadedLgotaDocsState({
                            ['reference_MSE']: file,
                          })
                        )
                      }
                      onDelete={() =>
                        dispatch(
                          deleteUploadedLgotaDocsState({
                            id: 'reference_MSE',
                          })
                        )
                      }
                      className="profile-form__file-uploader  have_reference_mse"
                    />
                  </ProfileSection>
                </div>
              )}
            </div>
            <div>
              <Checkbox
                disabled={readOnly}
                text={
                  'Нужны специальные условия для проведения вступительных испытаний'
                }
                {...fields.haveSpecialConditions}
              />

              <a
                className="link_to_information"
                href="/documents/Особенности_проведения_вступительных_испытаний_для_инвалидов.pdf"
                target="_blank"
              >
                {terms.LINK_FEATURES_OF_TESTING}
              </a>
              {haveSpecialConditions && (
                <div className="special-docs__container">
                  {isVisibleHaveReferenceMse && (
                    <ProfileSection>
                      <p>
                        {terms.DOWNLOAD_THE_APPLICATION_FORM_FOR_SPECIAL_CONDITION}
                      </p>
                      <div className="profile-form__fields">
                        <div onClick={getDoc} className="blanc__upload-doc-btn">
                          {terms.DOWNLOAD_THE_APPLICATION_FORM_FOR_SPECIAL_CONDITION}
                        </div>
                      </div>
                    </ProfileSection>
                  )}

                  <Input
                    disabled={readOnly}
                    className="profile-form__field profile-form__field--fb-100"
                    {...fields.commentSpecialConditions}
                    placeholder={'Специальные условия'}
                    markRequired={true}
                    error={errors.commentSpecialConditions?.message}
                  />
                  <ProfileSection
                    title="Копия документа"
                    hint={
                      scenarioKeys.magistra_rf.onesKey == statement?.scenario.code
                        ? 'Загрузите документ, подтверждающий право на предоставление специальных условий для прохождения вступительных испытаний, и заполненный бланк заявления для специальных условий'
                        : 'Загрузите документ, подтверждающий право на предоставление специальных условий для прохождения вступительных испытаний'
                    }
                    markRequired={true}
                    fileUploadingHint
                  >
                    <FileUploader
                      files={uploadedLgotaDocs['lgota_special_conditions'] || []}
                      onDrop={(file) =>
                        dispatch(
                          addUploadedLgotaDocsState({
                            ['lgota_special_conditions']: file,
                          })
                        )
                      }
                      onChange={(file) =>
                        dispatch(
                          addUploadedLgotaDocsState({
                            ['lgota_special_conditions']: file,
                          })
                        )
                      }
                      onDelete={() =>
                        dispatch(
                          deleteUploadedLgotaDocsState({
                            id: 'lgota_special_conditions',
                          })
                        )
                      }
                      className="profile-form__file-uploader lgota-file-uploader"
                    />
                  </ProfileSection>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </FillingStatementsForm>
  )
}

export default PrivilegesAndSpecialConditionsPage
