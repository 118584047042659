import React from 'react'

import useChangePayerDetailsJuridical from '../../hooks/useChangePayerDetailsJuridical'
import terms from '@common/terms'
import ProfileSection from '@components/ProfileSection'
import Button from '@ui/Button/view'
import Input from '@ui/Input/view'

const ModeratorEditContractJuridicalForm = ({
  competitiveGroupStatementId,
  payerTypeId,
  contractJuridical = undefined,
}) => {
  const {
    fields,
    formState: { errors },
    onSubmit,
  } = useChangePayerDetailsJuridical(
    competitiveGroupStatementId,
    payerTypeId,
    contractJuridical
  )

  return (
    <form
      className="moderator-edit-contract-form"
      onSubmit={(e) => {
        onSubmit(e)
        e.preventDefault()
      }}
    >
      <>
        <ProfileSection title="Основные данные о плательщике">
          <div className="profile-form__fields">
            <Input
              className="profile-form__field"
              placeholder={'Полное наименование'}
              {...fields?.organization}
              error={errors.organization?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={'Краткое наименование'}
              {...fields.shortName}
              error={errors.shortName?.message}
            />
            <Input
              placeholder={'Генеральный директор'}
              className="profile-form__field"
              {...fields.generalManager}
              error={errors.generalManager?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={'Адрес'}
              {...fields.organizationAddress}
              error={errors.organizationAddress?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={'ОГРН'}
              {...fields.ogrn}
              error={errors.ogrn?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={'ИНН'}
              {...fields.inn}
              error={errors.inn?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={'КПП'}
              {...fields.kpp}
              error={errors.kpp?.message}
            />
            <Input
              className="profile-form__field profile-form__field--fb-100"
              placeholder="Фактический адрес"
              {...fields.actualAddress}
              error={errors.actualAddress?.message}
            />
          </div>
        </ProfileSection>
        <ProfileSection title="">
          <div className="profile-form__fields">
            <Input
              className="profile-form__field"
              placeholder={'Руководитель действует на основании'}
              {...fields?.managementBases}
              error={errors.managementBases?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={'Наименование банка'}
              {...fields?.nameBank}
              error={errors.nameBank?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={'БИК банка'}
              {...fields?.bicBank}
              error={errors.bicBank?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={'Расчетный счет'}
              {...fields?.paymentAccount}
              error={errors.paymentAccount?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={'Корреспондентский счет'}
              {...fields?.correspondentAccount}
              error={errors.correspondentAccount?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={'Контактный телефон'}
              {...fields?.phone}
              error={errors.phone?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={'E-mail'}
              {...fields?.email}
              error={errors.email?.message}
            />
          </div>
        </ProfileSection>
        <Button type="submit">Сохранить</Button>
      </>
    </form>
  )
}

export default ModeratorEditContractJuridicalForm
