import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

import classNames from 'classnames'

const RouteLink = (props: LinkProps) => {
  return <Link {...props} className={classNames('link', props.className)} />
}

export default RouteLink
