import React, { forwardRef, PropsWithChildren } from 'react'

import classnames from 'classnames'

import useSubmitFormContext from '@hooks/useSubmitFormContext'

const FillingStatementsForm = forwardRef(
  (
    {
      children,
      ...rest
    }: PropsWithChildren<React.FormHTMLAttributes<HTMLFormElement>>,
    ref: React.Ref<any>
  ) => {
    const { HiddenButton } = useSubmitFormContext()

    return (
      <form {...rest} className={classnames(rest.className)} ref={ref}>
        <HiddenButton />
        {children}
      </form>
    )
  }
)

export default FillingStatementsForm
