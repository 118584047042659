import React, { useEffect } from 'react'

import useAppDispatch from '../hooks/useAppDispatch'
import useAppSelector from '../hooks/useAppSelector'
import { selectLevelValue, selectOlympiads } from '../redux/actions/selectors'
import { base64toFile, downloadFile } from '../utils/common'
import terms from '@common/terms'
import { Olympiad } from '@models'
import {
  createToast,
  getAchivements,
  getOlympiads,
  showModal,
} from '@redux/action-types'
import api from '@services/api'

const useOlympiads = (statementId: string | number) => {
  const dispatch = useAppDispatch()
  const olympiads = useAppSelector(selectOlympiads)
  const scenarioId = useAppSelector(selectLevelValue)

  useEffect(() => {
    dispatch(getOlympiads(Number(statementId)))
  }, [])

  const onDownloadFile = (docId: number, filename: string) => {
    api.client
      .getDocs('ACHIVMENT', docId)
      .then((resp) =>
        base64toFile(resp.data.document || '', 'application/pdf', 'pdf', filename)
      )
      .then(downloadFile)
      .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')))
  }

  const onDelete = (data: { statementId: number; userOlympiadId: number }) => {
    api.client
      .deleteUserOlympiad(data)
      .then((response) => {
        dispatch(createToast('Олимпиада успешно удалена', 'success'))
        dispatch(getOlympiads(Number(statementId)))
      })
      .catch(() => {
        dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
      })
  }

  const onEdit = (olympiad: Olympiad) => {
    dispatch(
      showModal({
        name: 'ADD_OLYMPIAD_MODAL',
        data: { statementId, olympiad, scenarioId },
      })
    )
  }

  return {
    olympiads,
    onDelete,
    onDownloadFile,
    onEdit,
  }
}

export default useOlympiads
