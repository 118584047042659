import React from 'react'

import classNames from 'classnames'

import Loader from '@ui/Loader'

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  theme?: 'success'
  loading?: boolean
}

const Button = ({ theme, loading = false, ...rest }: ButtonProps) => {
  return (
    <button
      {...rest}
      disabled={loading || rest.disabled}
      className={classNames('button', rest.className, {
        [`button--theme-${theme}`]: Boolean(theme),
      })}
    >
      {loading ? <Loader className={'btn-loader'} /> : rest.children}
    </button>
  )
}

export default Button
