import { Chat, ChatType, ReceivePayload } from '@models'
import { getAdminMessages, getUserMessages } from '@redux/action-types'
import { StateStatus } from '@redux/types'
import { ActionReducerMapBuilder, AsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialChatState = {
  data: null,
  status: 'idle',
  error: null,
} as const

const initialState = {
  entities: {
    adminMessages: initialChatState,
    userMessages: initialChatState,
  },
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {},
  extraReducers(builder) {
    setupExtraReducer(builder, getAdminMessages, 'adminMessages')
    setupExtraReducer(builder, getUserMessages, 'userMessages')
  },
})

function setupExtraReducer(
  builder: ActionReducerMapBuilder<any>,
  asyncThunk: AsyncThunk<any, any, {}>,
  key: keyof any['entities']
) {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state.entities[key].status = 'loading'
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      state.entities[key].status = 'succeeded'
      state.entities[key].data = action.payload
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      state.entities[key].status = 'failed'
      state.entities[key].error = action.payload
      state.entities[key].data = null
    })
}

export default chatSlice.reducer
