import React, { createContext, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import useModeratorStatementsProvideRoutes from './useModeratorStatementsProvideRoutes'
import { moderatorStatementsRoutes } from '@common/routes/moderatorStatements'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { getAdminVisa } from '@redux/action-types/admin'
import { selectAdminVisa } from '@redux/actions/selectors'

type ModeratorStatementsRoutesContextProps = ReturnType<
  typeof useModeratorStatementsProvideRoutes
>

const ModeratorStatementsRoutesContext =
  createContext<ModeratorStatementsRoutesContextProps>(
    {} as ModeratorStatementsRoutesContextProps
  )

export const ModeratorStatementsRoutesContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <ModeratorStatementsRoutesContext.Provider
      value={useModeratorStatementsProvideRoutes(moderatorStatementsRoutes)}
    >
      {children}
    </ModeratorStatementsRoutesContext.Provider>
  )
}

export default () => {
  return useContext(ModeratorStatementsRoutesContext)
}
