import React from 'react'

import EntranceExamsForm from '@components/EntranceExamsForm'
import FillingStatementsForm from '@components/FillingStatementsForm'
import useAppSelector from '@hooks/useAppSelector'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import { selectStatementsElement } from '@redux/actions/selectors'

const EntranceExamsPage = (scenarioKeys) => {
  const { onNextPageNavigate } = useFillingStatementsRoutesContext()
  const statement = useAppSelector(selectStatementsElement)
  const isVisibleHints =
    (statement?.trajectoryId === 1 &&
      statement?.scenarioId !== scenarioKeys.asperatnuta_rf.tdId &&
      statement?.scenarioId !== scenarioKeys.level_training_preparation.tdId) ||
    (statement?.trajectoryId === 2 &&
      statement?.scenarioId !== scenarioKeys.asperatnuta_rf.tdId &&
      statement?.scenarioId !== scenarioKeys.level_training_preparation.tdId &&
      statement?.scenarioId !== scenarioKeys.level_training_bachelor.tdId &&
      statement?.scenarioId !== scenarioKeys.magistra_rf.tdId &&
      statement?.scenarioId !== scenarioKeys.magistra_ino.tdId &&
      statement?.scenarioId !== scenarioKeys.level_training_bachelor_ino.tdId)

  return (
    <FillingStatementsForm
      className="entr-exams__form"
      onSubmit={(e) => {
        e.preventDefault()
        onNextPageNavigate()
      }}
    >
      {(statement?.scenarioId === scenarioKeys.asperatnuta_rf.tdId ||
        statement?.scenarioId === scenarioKeys.level_training_preparation.tdId) && (
        <div>
          <p className={'mb-12'}>
            С результатами сдачи вступительных испытаний можно ознакомиться в разделе
            Новости - “Результаты сдачи вступительных испытаний 2023/2024»{' '}
            <a className={'link_to_information'} href="" target={'_blank'}>
              по ссылке
            </a>
            .
          </p>
          <p className={'mb-12'}>
            В соответствии с Правилами приема 2023, поступающие, не сдавшие
            вступительное испытание и (или) не набравшие минимальное количество
            баллов, равное трём, при сдаче вступительного испытания, не допускаются к
            сдаче следующего вступительного испытания и выбывают из конкурса".
          </p>
          <p className={'mb-24'} style={{ fontWeight: 'bold' }}>
            При сдачи вступительного испытания в очной форме для прохода на
            территорию СПбГУ, Вам необходимо предъявить документ, удостоверяющий
            личность.
          </p>
        </div>
      )}

      <EntranceExamsForm {...scenarioKeys} />

      {isVisibleHints && (
        <div className="entr-exams__texts">
          <a style={{ color: 'blue' }}>
            Информация об особенностях проведения вступительных испытаний
          </a>
          <p>
            При возникновении технического сбоя во время вступительного испытания
            обращаться по e-mail:
            {/*<a style={{ color: 'blue' }} href="mailto:">*/}
            {/*  e-mail*/}
            {/*</a>*/}
          </p>
          <p>
            Заявление на перенос вступительного испытания на резервный день
            отправлять по e-mail: {/*<a style={{ color: 'blue' }} href="mailto:">*/}
            {/*  e-mail*/}
            {/*</a>*/}
          </p>
          <p>
            Апелляционное заявление по результатам вступительного испытания
            отправлять по e-mail: {/*<a style={{ color: 'blue' }} href="mailto:">*/}
            {/*  e-mail*/}
            {/*</a>*/}
          </p>
        </div>
      )}
    </FillingStatementsForm>
  )
}

export default EntranceExamsPage
