import React, { useEffect, useState } from 'react'

import LegalRepresentativeForm from '@components/LegalRepresentativeForm'
import useAppDispatch from '@hooks/useAppDispatch'
import { getPassportKeys } from '@redux/action-types'

const LegalRepresentativePage = () => {
  const dispatch = useAppDispatch()
  const [keys, setKeys] = useState()
  useEffect(() => {
    dispatch(
      getPassportKeys({
        model: 'TypeDocument',
        unique_keys: [49, 90, 128, 153, 152, 47, 89],
      })
    ).then((res) => setKeys(res.payload))
  }, [])
  if (!keys) {
    return <div></div>
  }
  let docKeys
  let passportKeys
  docKeys = keys
  passportKeys = {
    foreign_citizen_passport_code: docKeys.find(
      (value) => value.uniqueKeyOnSite == 49
    ),
    identity_card_stateless_person_code: docKeys.find(
      (value) => value.uniqueKeyOnSite == 90
    ),
    other_document_code: docKeys.find((value) => value.uniqueKeyOnSite == 128),
    passport_non_citizen_code: docKeys.find((value) => value.uniqueKeyOnSite == 153),
    russia_certificate_temporary_asylum_code: docKeys.find(
      (value) => value.uniqueKeyOnSite == 152
    ),
    russia_citizen_passport_code: docKeys.find(
      (value) => value.uniqueKeyOnSite == 47
    ),
    russia_foreign_passport_code: docKeys.find(
      (value) => value.uniqueKeyOnSite == 89
    ),
  }
  return (
    <div>
      <LegalRepresentativeForm {...passportKeys} />
    </div>
  )
}

export default LegalRepresentativePage
