import React from 'react'

import { NavRoute } from 'types/custom-types'

import { routes } from '@common/routes/progressTab'
import ProgressnavElement from '@components/ProgressTab/ProgressTabElement'

type SideNavProps = {
  className?: string
  classNameElement?: string
  activePage?: NavRoute
}

const ProgressTab = ({ className, classNameElement, activePage }: SideNavProps) => {
  return (
    <div className={className}>
      {routes.map((route) => (
        <ProgressnavElement
          key={route.id}
          active={activePage?.text}
          className={classNameElement}
          route={route}
        />
      ))}
    </div>
  )
}

export default ProgressTab
