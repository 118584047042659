import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'

import Loader from '@ui/Loader'

export const withRouter = (component: () => React.ReactNode) => () => {
  return (
    <BrowserRouter>
      <Suspense
        fallback={
          <div>
            <Loader />
          </div>
        }
      >
        {component()}
      </Suspense>
    </BrowserRouter>
  )
}
