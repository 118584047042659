import React, { forwardRef, PropsWithChildren } from 'react'

import classnames from 'classnames'

import useSubmitFormContext from '@hooks/useSubmitFormContext'

const ProfileForm = forwardRef(
  (
    {
      children,
      ...rest
    }: PropsWithChildren<React.FormHTMLAttributes<HTMLFormElement>>,
    ref: React.Ref<any>
  ) => {
    const { HiddenButton } = useSubmitFormContext()
    return (
      <form
        {...rest}
        className={classnames('profile-form', rest.className)}
        ref={ref}
      >
        <HiddenButton />
        {children}
      </form>
    )
  }
)

export default ProfileForm
