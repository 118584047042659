import { useState } from 'react'
import { useParams } from 'react-router-dom'

import api, { ResponseOk, UpdateEgeTabResponse } from '../services/api'
import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import { createToast, getModeratorExamResultData } from '@redux/action-types'

const useModeratorExamResultPage = () => {
  const [checkLoading, setCheckLoading] = useState(false)
  const { id } = useParams<{ id?: string }>()
  const dispatch = useAppDispatch()

  const checkResult = () => {
    if (!id) {
      return
    }
    setCheckLoading(true)
    api.client
      .getModeratorCheckExamResult(id)
      .then((response) => {
        const { data } = response as ResponseOk
        dispatch(createToast(data.message, 'success'))
        dispatch(getModeratorExamResultData(id))
      })
      .catch((response: Exclude<UpdateEgeTabResponse, ResponseOk>) => {
        const { status } = response
        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
      .finally(() => setCheckLoading(false))
  }
  return {
    checkLoading,
    checkResult,
  }
}

export default useModeratorExamResultPage
