import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { RUSSIA_NATIONAL_ID } from '@common/constants'
import regexp from '@common/regexp'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import usePassportDataFormInitialization from '@hooks/usePassportDataFormInitialization'
import useProfileApiHandling from '@hooks/useProfileApiHandling'
import {
  getDivisionCode,
  getPassportNumber,
  getPassportSeries,
  normalizeYearTo4Characters,
  validateDate,
  validIssueDate,
} from '@lib/validators'
import { SelectOption, Passport } from '@models'
import { getPassportData } from '@redux/action-types'
import { getViewerProfile } from '@redux/reducers'
import api from '@services/api'
import issuingAuthorityApi from '@services/api/issuing-authority-api'
import { logDOM } from '@testing-library/react'

//import {values} from "pusher-js/types/src/core/utils/collections";

const usePassportForm = (passportIds) => {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    setValue,
    getValues,
    clearErrors,
    ...rest
  } = useForm<Passport>({ mode: 'all' })
  const { personalData } = usePassportDataFormInitialization(passportIds, {
    setValue,
  })

  const listIssuingAuthority: SelectOption[] = []

  const IssuingAuthorityOnChange = (event) => {
    listIssuingAuthority.length = 0
    setValue('whoIssueDocument', '')
    if (event.target.value.length === 7) {
      issuingAuthorityApi
        .getIssuingAuthority(event.target.value)
        .then(function (response) {
          let i = 1

          response.data.suggestions.forEach((item) => {
            listIssuingAuthority.push({ value: i, label: item.value })
            i += 1
          })
        })
    }
  }

  const dispatch = useAppDispatch()
  const { callApi, loading } = useProfileApiHandling({
    setError,
    apiFn: api.client.updatePassort,
    onSuccessCb: () => {
      dispatch(getPassportData())
      dispatch(getViewerProfile())
    },
  })

  const typeDocId = watch('typeIdentityDocumentId') as unknown as
    | SelectOption
    | undefined
  const isSeriesRequired =
    typeDocId?.value === passportIds.russia_citizen_passport_code.tdId

  const isRussianPassport =
    typeDocId?.value === passportIds.russia_citizen_passport_code.tdId

  const isRussianForeignPassport =
    typeDocId?.value === passportIds.russia_foreign_passport_code.tdId

  const isForeignPassport = passportIds.foreign_citizen_passport_code
    ? typeDocId?.value === passportIds.foreign_citizen_passport_code.tdId
    : false

  const isDateOfExpirationExists =
    typeDocId?.value !== passportIds.russia_citizen_passport_code.tdId

  const useQuota = !!watch('quota_minobr')

  const [isNameAuthorityMissing, setIsNameAuthorityMissing] = useState(false)
  const changeMissingDocValue = async (event) => {
    clearErrors()
    if (isNameAuthorityMissing) {
      dispatch(getPassportData()).then((resp) => {
        const test = {
          // @ts-ignore
          label: resp?.payload[0]?.whoIssueDocument,
          id: Date.now(),
        }
        // @ts-ignore
        setValue('whoIssueDocument', test)
      })
    } else {
      setValue('whoIssueDocument', '')
    }
    setIsNameAuthorityMissing(!isNameAuthorityMissing)
  }

  const fields = {
    typeIdentityDocumentId: register('typeIdentityDocumentId', {
      required: terms.REQUIRED_FIELD,
      onChange: (e) => {
        e.target.value && clearErrors('serialDocument')
        e.target.value && clearErrors('numberDocument')
        if (e.target.value.value == 1) {
          setValue('documentValidPeriod', undefined)
        }
        setValue('whoIssueDocument', '')
        setValue('divisionCode', '')
      },
    }),
    serialDocument: register('serialDocument', {
      required: terms.REQUIRED_FIELD,
      ...getPassportSeries(isSeriesRequired),
      pattern: {
        value: isRussianPassport
          ? regexp.passportSeries
          : isRussianForeignPassport
          ? regexp.passportRussianForeignSeries
          : regexp.passportSeriesForeignSymbols,
        message: isRussianPassport
          ? terms.PASSPORT_SERIES_LENGTH
          : isRussianForeignPassport
          ? terms.PASSPORT_RUSSIAN_FOREIGN_SERIES_LENGTH
          : terms.LATIN_CYRILLIC_CHARACTERS_AND_NUMBER_ONLY,
      },
    }),
    numberDocument: register('numberDocument', {
      required: terms.REQUIRED_FIELD,
      ...getPassportNumber(isRussianPassport),

      pattern: {
        value: isRussianPassport
          ? regexp.passportNumber
          : isRussianForeignPassport
          ? regexp.passportRussianForeignNumber
          : regexp.passportNumberForeign,
        message: isRussianPassport
          ? terms.PASSPORT_NUMBER_LENGTH
          : isRussianForeignPassport
          ? terms.PASSPORT_RUSSIAN_FOREIGN_NUMBER_LENGTH
          : terms.ONLY_NUMBER,
      },
    }),
    divisionCode: register('divisionCode', {
      required: { value: isRussianPassport, message: terms.REQUIRED_FIELD },
      ...getDivisionCode(),
    }),
    dateOfIssue: register('dateOfIssue', {
      required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) => {
        value &&
          setValue('dateOfIssue', normalizeYearTo4Characters(value)) &&
          clearErrors('dateOfIssue')
      },
      validate: (value) => {
        return validateDate(value, personalData?.dateBirthday || '')
          ? true
          : terms.DATE_INVALID
      },
    }),
    whoIssueDocument: register('whoIssueDocument', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: !isNameAuthorityMissing
          ? regexp.ruSymbolAndNumber
          : regexp.ruAndSymbols,
        message: terms.CYRILLIC_CHARACTERS_ONLY,
      },
    }),

    documentValidPeriod: register('documentValidPeriod', {
      required: { value: isDateOfExpirationExists, message: terms.REQUIRED_FIELD },
      onChange: ({ target: { value } }) =>
        value &&
        setValue('documentValidPeriod', normalizeYearTo4Characters(value)) &&
        clearErrors('documentValidPeriod'),
      validate: (value) => {
        return validIssueDate(value || null) ? true : terms.DATE_INVALID
      },
    }),
    placeBirthday: register('placeBirthday', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: regexp.ruSymbolAndNumberAndComa,
        message: terms.CYRILLIC_CHARACTERS_ONLY,
      },
    }),
    nationalityId: register('nationalityId', {
      onChange: (event) => {
        const nationalId: SelectOption | null = event.target.value
        const isNationalRussia = nationalId?.value === RUSSIA_NATIONAL_ID
        if (isNationalRussia) {
          setValue('haveVnz', false)
        }
      },
    }),
    vnzIssue: register('vnzIssue', {
      onChange: ({ target: { value } }) =>
        value && setValue('vnzIssue', normalizeYearTo4Characters(value)),
    }),
    vnzValidPeriod: register('vnzValidPeriod', {
      onChange: ({ target: { value } }) =>
        value && setValue('vnzValidPeriod', normalizeYearTo4Characters(value)),
    }),
    vnzWhoIssue: register('vnzWhoIssue', {
      pattern: {
        value: regexp.ruSymbolAndNumberAndComa,
        message: terms.CYRILLIC_CHARACTERS_ONLY,
      },
    }),
    vnzNumber: register('vnzNumber'),
    haveVnz: register('haveVnz'),
    //needVisa: register('needVisa'),
    compatriot: register('compatriot'),
    quota_minobr: register('quota_minobr'),
    reg_number_eduinru: register('reg_number_eduinru', {
      required: {
        value: useQuota,
        message: terms.REQUIRED_FIELD,
      },
    }),
  }

  const onSubmit = handleSubmit((data: any) => {
    if (!validateDate(getValues('dateOfIssue'), personalData?.dateBirthday || '')) {
      setError('dateOfIssue', {
        type: 'custom',
        message: terms.DATE_INVALID,
      })
      return
    }

    if (!validIssueDate(getValues('documentValidPeriod') || null)) {
      setError('documentValidPeriod', {
        type: 'custom',
        message: terms.DATE_INVALID,
      })
      return
    }

    const normalizedData: Passport = {
      ...data,
      divisionCode: data.divisionCode || undefined,
      regNumberEduinru: data.reg_number_eduinru || null,
      documentValidPeriod: data.documentValidPeriod || undefined,
      typeIdentityDocumentId: data.typeIdentityDocumentId.value,
      haveVnz: !!data.haveVnz,
      quota_minobr: data.quota_minobr ?? false,
      reg_number_eduinru: data.quota_minobr ? data.reg_number_eduinru : null,
      whoIssueDocument:
        typeof data.whoIssueDocument === 'string'
          ? data.whoIssueDocument
          : data.whoIssueDocument?.label,
    }

    callApi(normalizedData)
  })

  return {
    fields,
    watch,
    onSubmit,
    loading,
    setValue,
    isSeriesRequired,
    isRussianPassport,
    isForeignPassport,
    isDateOfExpirationExists,
    listIssuingAuthority,
    IssuingAuthorityOnChange,
    isNameAuthorityMissing,
    changeMissingDocValue,
    isRussianForeignPassport,
    ...rest,
  }
}

export default usePassportForm
