import React, { useEffect } from 'react'

import FillingStatementsForm from '../../../components/FillingStatementsForm'
import Button from '../../../components/generic/Button'
import useAppDispatch from '../../../hooks/useAppDispatch'
import useAppSelector from '../../../hooks/useAppSelector'
import useDownloadDocument from '../../../hooks/useDownloadDocument'
import useFillingStatementsRoutesContext from '../../../hooks/useFillingStatementsRoutesContext'
import { mimeTypes } from '@common/manual'
import { getConsentToEnrollmentHistory } from '@redux/action-types'
import {
  selectModeratorStatementsIdStatus,
  selectUserConsentToEnrollmentHistory,
} from '@redux/actions/selectors'

const DirectionTrainingsModerator = () => {
  const { onNextPageNavigate } = useFillingStatementsRoutesContext()
  const { downloadDocument } = useDownloadDocument()
  const dispatch = useAppDispatch()
  const history = useAppSelector(selectUserConsentToEnrollmentHistory)
  const statement = useAppSelector(selectModeratorStatementsIdStatus)

  useEffect(() => {
    if (statement) {
      dispatch(
        getConsentToEnrollmentHistory({ id: statement.id, user: statement.userId })
      )
    }
  }, [])

  function downloadDoc() {
    downloadDocument(
      'CONSENT_TO_ENROLLMENT',
      mimeTypes.docx,
      'docx',
      history[0].userDoc.id,
      history[0].userDoc.name,
      statement.id,
      statement.userId
    )
  }

  return (
    <FillingStatementsForm
      onSubmit={(e) => {
        e.preventDefault()
        onNextPageNavigate()
      }}
    >
      {history[0] && (
        <div className={'direction-training-card__list'}>
          <div className={'direction-training-card'}>
            <h4>Согласие на зачисление</h4>

            <p>
              Научная специальность:
              {history[0].directionTraining.translations[0].name}
            </p>
            <p>
              Дата формирования: {history[0].createdAt.substr(8, 2)}.
              {history[0].createdAt.substr(5, 2)}.{history[0].createdAt.substr(0, 4)}
            </p>

            <p className={'direction-training-card__time'}>
              Форма оплаты:{' '}
              {history[0].competitiveGroup.formPayment.translate[0].name}
            </p>

            <div className={'direction-training-card__download-button'}>
              <Button onClick={downloadDoc} type={'button'}>
                Скачать документ
              </Button>
              <p>Скачайте, проверьте данные</p>
            </div>
          </div>
        </div>
      )}
    </FillingStatementsForm>
  )
}

export default DirectionTrainingsModerator
