import React from 'react'

import CheckMark from '@assets/images/Check-mark.svg'
import Cross from '@assets/images/Cross.svg'
import Edit from '@assets/images/Edit.svg'
import Eye from '@assets/images/Eye.svg'
import Trash from '@assets/images/Trash.svg'
import Button from '@ui/Button'

import './TableDirectoriesInfo.scss'

type TableDirectoriesInfoProps = {
  columns: Array<{ Header: string; accessor: string }>
  data: any
  directory: any
  changeDirectoryView: (
    id: string | number,
    visible: boolean,
    name: string,
    externalId
  ) => void
  createNewDirectory: (item) => void
  deleteDirectory: (name: string, id: string | number) => void
  editDirectory: (data) => void
}

const view = ({
  columns,
  data,
  changeDirectoryView,
  deleteDirectory,
  directory,
  createNewDirectory,
  editDirectory,
}: TableDirectoriesInfoProps): JSX.Element => {
  return (
    <main className="directory-page">
      <div className="directory-page-header">
        <h1 className="directory-page__title">
          {directory?.data[0]?.translation[0]?.name}
        </h1>
        <Button
          type="button"
          theme={'success'}
          onClick={() => {
            createNewDirectory({
              connections: data[0].connections,
              directoryName: data[0].directoryName,
            })
          }}
        >
          Добавить раздел в справочник
        </Button>
      </div>
      <section className={'table-list'}>
        <div className="table-list__main">
          <table className="table-list__body">
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column.accessor}>{column.Header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  {columns.map((column, index) => {
                    if (column.accessor === 'controls') {
                      return (
                        <td key={index} className="table-list__body__item-controls">
                          <Eye
                            width="16"
                            height="20"
                            onClick={() =>
                              changeDirectoryView(
                                item.id,
                                !item.visible,
                                directory?.data[0]?.table,
                                item.externalId
                              )
                            }
                          />
                          <Trash
                            width="16"
                            height="18"
                            onClick={() =>
                              deleteDirectory(directory?.data[0]?.table, item.id)
                            }
                          />
                          <Edit
                            width="18"
                            height="18"
                            onClick={() => editDirectory(item)}
                          />
                        </td>
                      )
                    }
                    if (column.accessor === 'visible') {
                      return (
                        <td key={index}>
                          {item[column.accessor] ? (
                            <CheckMark width="16" height="17" />
                          ) : (
                            <Cross
                              width="16"
                              height="20"
                              style={{ color: 'gray' }}
                            />
                          )}
                        </td>
                      )
                    }
                    if (column.accessor === 'phoneCode') {
                      return <td key={index}>{item.phone[0]?.code}</td>
                    }
                    return <td key={index}>{item[column.accessor]}</td>
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </main>
  )
}

export default view
