import { ROLE_ADMIN } from '@common/constants'
import { FilterContract, FilterStatement, StudentsFilterFormData } from '@models'
import { moderatorSlice } from '@redux/reducers/moderatorSlice'
import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '@services/api'

export const {
  clearStatementsIdStatus,
  clearStatementsPersonalData,
  clearSupportPersonalData,
} = moderatorSlice.actions

export const getModeratorStatementsList = createAsyncThunk(
  'moderator/statementsList',
  async ({
    roleId,
    normalizedData,
  }: {
    roleId: number
    normalizedData?: FilterStatement
  }) => {
    const response = await api.client.getModeratorStatementsList(
      roleId,
      normalizedData
    )
    return response.data
  }
)

export const getModeratorPersonalInformationList = createAsyncThunk(
  'moderator/personalInformationList',
  async (normalizedData?: FilterStatement) => {
    const response = await api.client.getModeratorPersonalInformationList(
      normalizedData
    )
    return response.data
  }
)

export const getModeratorStatementsPersonalData = createAsyncThunk(
  'moderator/personalData',
  async (id: string | number) => {
    const response = await api.client.getModeratorStatementsPersonalData(id)
    return response.data
  }
)

export const getModeratorUserPersonalData = createAsyncThunk(
  'moderator/supportPersonalData',
  async (id: string | number) => {
    const response = await api.client.getModeratorUserPersonalData(id)
    return response.data
  }
)

export const getModeratorStatementsTargetContractData = createAsyncThunk(
  'moderator/targetContractData',
  async ({ id }: { id: string | number }) => {
    const response = await api.client.getModeratorStatementsTargetContractData(id)
    return response.data
  }
)

export const getModeratorContractInfo = createAsyncThunk(
  'moderator/contractInfo',
  async (id: string | number) => {
    const response = await api.client.getModeratorContractInfo(id)
    return response.data
  }
)

export const getModeratorStatementsPassportData = createAsyncThunk(
  'moderator/passportData',
  async ({ id, userId }: { id: string | undefined; userId: string | undefined }) => {
    if (id) {
      const response = await api.client.getModeratorStatementsPassportData(id)
      return response.data
    }
    if (userId) {
      const response = await api.client.getModeratorUserPassportData(userId)
      return response.data
    }
  }
)

export const getModeratorStatementsAddressData = createAsyncThunk(
  'moderator/addressData',
  async ({ id, userId }: { id: string | undefined; userId: string | undefined }) => {
    if (id) {
      const response = await api.client.getModeratorStatementsAddressData(id)
      return response.data
    }
    if (userId) {
      const response = await api.client.getModeratorUserAddressData(userId)
      return response.data
    }
  }
)

export const getModeratorStatementsEducationData = createAsyncThunk(
  'moderator/educationData',
  async ({ id, userId }: { id: string | undefined; userId: string | undefined }) => {
    if (id) {
      const response = await api.client.getModeratorStatementsEducationData(id)
      return response.data
    }
    if (userId) {
      const response = await api.client.getModeratorUserEducationData(userId)
      return response.data
    }
  }
)

export const getModeratorStatementsDocumentsData = createAsyncThunk(
  'moderator/documentsData',
  async ({ id, userId }: { id: string | undefined; userId: string | undefined }) => {
    if (id) {
      const response = await api.client.getModeratorStatementsDocumentsData(id)
      return response.data
    }
    if (userId) {
      const response = await api.client.getModeratorUserDocumentsData(userId)
      return response.data
    }
  }
)

export const getModeratorStatementsAchievementsAndPrivilegesData = createAsyncThunk(
  'moderator/achievementsAndPrivilegesData',
  async (id: string | number) => {
    const response =
      await api.client.getModeratorStatementsAchievementsAndPrivilegesData(id)
    return response.data
  }
)

export const getModeratorBlankStatementsData = createAsyncThunk(
  'moderator/blankStatementData',
  async (id: string | number) => {
    const response = await api.client.getModeratorBlankStatementsData(id)
    return response.data
  }
)

export const getModeratorExamResultData = createAsyncThunk(
  'moderator/examResultData',
  async (id: string | number) => {
    const response = await api.client.getModeratorExamResultData(id)
    return response.data
  }
)

export const getModeratorStatementsIdStatus = createAsyncThunk(
  'moderator/statementsIdStatus',
  async (id: string | number) => {
    const response = await api.client.getModeratorStatementsIdStatus(id)
    return response.data
  }
)

export const getModeratorStatementsIdComment = createAsyncThunk(
  'moderator/statementsIdComment',
  async (id: string | number) => {
    const response = await api.client.getModeratorStatementsIdComment(id)
    return response.data
  }
)

export const getModeratorContractsList = createAsyncThunk(
  'moderator/contractsList',
  async (data?: FilterContract) => {
    const response = await api.client.getModeratorContractsList(data)
    return response.data
  }
)

export const getModeratorEducationProfile = createAsyncThunk(
  'moderator/educationProfile',
  async (data?: StudentsFilterFormData) => {
    const response = await api.client.getModeratorEducationProfile(data)
    return response.data
  }
)

export const getModeratorStatusesList = createAsyncThunk(
  'moderator/statusesList',
  async () => {
    const response = await api.client.getModeratorStatusesList()
    return response.data
  }
)

export const getModeratorModeratorsList = createAsyncThunk(
  'moderator/moderatorsList',
  async () => {
    const response = await api.client.getModeratorModeratorsList()
    return response.data
  }
)

export const getModeratorStatementsAdditionalFiles = createAsyncThunk(
  'moderator/additionalFiles',
  async (id?: string | number) => {
    const response = await api.client.getModeratorStatementsAdditionalFiles(id)
    return response.data
  }
)

export const getModeratorScenario = createAsyncThunk(
  'moderator/scenario',
  async () => {
    const response = await api.client.getModeratorScenario()
    return response.data
  }
)
export const getModeratorTrajectory = createAsyncThunk(
  'moderator/trajectory',
  async () => {
    const response = await api.client.getModeratorTrajectory()
    return response.data
  }
)
export const getModeratorLevelEducation = createAsyncThunk(
  'moderator/levelEducation',
  async () => {
    const response = await api.client.getModeratorLevelEducation()
    return response.data
  }
)
export const getAllStatusStatement = createAsyncThunk(
  'moderator/statusStatement',
  async () => {
    const response = await api.client.getStatusStatement()
    return response.data
  }
)

export const getModeratorStatusStatement = createAsyncThunk(
  'moderator/statusStatementModerator',
  async (role: number | null | undefined) => {
    const response = await api.client.getStatusStatement(
      role === ROLE_ADMIN ? '' : 'MODERATOR_STATUSES'
    )
    return response.data
  }
)

export const getModeratorStatusContract = createAsyncThunk(
  'moderator/statusContract',
  async () => {
    const response = await api.client.getModeratorStatusContract()
    return response.data
  }
)

export const getModeratorDirectionsTrainings = createAsyncThunk(
  'moderator/directionsTrainings',
  async (statement_id: number) => {
    const response = await api.client.getModeratorDirectionsTrainings(statement_id)
    return response.data
  }
)
export const {
  setModeratorStatementsListOrder,
  setModeratorStatementsPage,
  setModeratorStatementsPerPage,
  setSearchStudents,
} = moderatorSlice.actions
