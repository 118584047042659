import React from 'react'

import ResidenceForm from '@components/ResidenceForm'

const ResidencePage = () => {
  return (
    <div>
      <ResidenceForm />
    </div>
  )
}

export default ResidencePage
