import React, { useEffect } from 'react'

import AdminAdministrationTable from '@components/AdminAdministrationTable'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { showModal } from '@redux/action-types'
import { getAdminUserList } from '@redux/action-types/admin'
import {
  selectAdminPageUsersList,
  selectAdminPerPageUsersList,
  selectAdminUserList,
  setAdminPageUsers,
  setAdminPerPageUsers,
} from '@redux/actions/selectors'
import Button from '@ui/Button'
import Loader from '@ui/Loader'
import Pagination from '@ui/Pagination'

const AdministrationPage = () => {
  const dispatch = useAppDispatch()

  const page = useAppSelector(selectAdminPageUsersList)
  const perPage = useAppSelector(selectAdminPerPageUsersList)

  useEffect(() => {
    dispatch(getAdminUserList({ page: page, perPage: perPage }))
  }, [page, perPage])

  const adminUserList = useAppSelector(selectAdminUserList)

  if (!adminUserList) {
    return <Loader />
  }

  if (adminUserList.data.length === 0) {
    dispatch(setAdminPageUsers(1))
  }

  const onOpenModal = () => {
    dispatch(showModal({ name: 'ADMIN_USER', data: {} }))
  }

  return (
    <main className="administration-page">
      <div className={'administration-page-header'}>
        <h1 className="administration-page__title">
          Администрирование пользователей
        </h1>
        <Button type="button" theme={'success'} onClick={() => onOpenModal()}>
          Добавить пользователя
        </Button>
      </div>
      <AdminAdministrationTable adminUserList={adminUserList?.data || []} />
      {adminUserList?.data && (
        <Pagination
          lastPage={adminUserList.lastPage}
          currentPage={adminUserList.currentPage}
          perPage={adminUserList.perPage}
          setPage={setAdminPageUsers}
          setPerPage={setAdminPerPageUsers}
        />
      )}
    </main>
  )
}

export default AdministrationPage
