import { StateStatus } from '../types'
import {
  AdminRoleOptions,
  AdminStatusOptions,
  AdminUserResponseData,
  AdminUserResponsePage,
  DirectoriesListResponse,
  DirectoryInfo,
  FilterAdminUserList,
  LegalRepresentative,
  LogPage,
  VisaResponse,
} from '@models'
import {
  getAdminRole,
  getAdminStatus,
  getAdminUserId,
  getAdminUserList,
  getAdminVisa,
  getDirectoriesList,
  getDirectoryInfo,
  getAdminLegalRepresentative,
  getLogList,
} from '@redux/action-types/admin'
import { ActionReducerMapBuilder, AsyncThunk, createSlice } from '@reduxjs/toolkit'

type AdminStateEntity<D> = {
  data: any
  status: StateStatus
  error: unknown
}

type AdminInitialState = {
  entities: {
    users: AdminStateEntity<AdminUserResponsePage>
    userId: AdminStateEntity<AdminUserResponseData>
    adminRoleOptions: AdminStateEntity<AdminRoleOptions>
    adminStatusOptions: AdminStateEntity<AdminStatusOptions>
    logList: AdminStateEntity<LogPage>
    directoriesList: AdminStateEntity<DirectoriesListResponse[]>
    directoryInfo: AdminStateEntity<DirectoryInfo[]>
    legalRepresentative: AdminStateEntity<LegalRepresentative>
    visa: AdminStateEntity<VisaResponse>
  }
  pageUsers: number
  perPageUsers: number
  pageLogList: number
  perPageLogList: number
  pageDirectories: number
  perPageDirectories: number
  pageDirectoriesInfo: number
  perPageDirectoriesInfo: number
  usersFilterData: FilterAdminUserList
}

const initialAdminEntityState = {
  data: null,
  status: 'idle',
  error: null,
} as const

const initialState: AdminInitialState = {
  entities: {
    users: initialAdminEntityState,
    userId: initialAdminEntityState,
    adminRoleOptions: initialAdminEntityState,
    adminStatusOptions: initialAdminEntityState,
    logList: initialAdminEntityState,
    directoriesList: initialAdminEntityState,
    directoryInfo: initialAdminEntityState,
    legalRepresentative: initialAdminEntityState,
    visa: initialAdminEntityState,
  },
  pageUsers: 1,
  perPageUsers: 10,
  pageLogList: 1,
  perPageLogList: 10,
  pageDirectories: 1,
  perPageDirectories: 10,
  pageDirectoriesInfo: 1,
  perPageDirectoriesInfo: 10,
  usersFilterData: {
    page: 1,
    perPage: 10,
  },
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAdminPageUsers(state, action) {
      state.pageUsers = action.payload
    },
    setAdminPerPageUsers(state, action) {
      state.perPageUsers = action.payload
    },
    setAdminPageLog(state, action) {
      state.pageLogList = action.payload
    },
    setAdminPerPageLog(state, action) {
      state.perPageLogList = action.payload
    },
    setAdminUsersFilterData(state, action) {
      state.usersFilterData = action.payload
    },
    setAdminPageDirectories(state, action) {
      state.pageDirectories = action.payload
    },
    setAdminPerPageDirectories(state, action) {
      state.perPageDirectories = action.payload
    },
    setAdminPageDirectoriesInfo(state, action) {
      state.pageDirectoriesInfo = action.payload
    },
    setAdminPerPageDirectoriesInfo(state, action) {
      state.perPageDirectoriesInfo = action.payload
    },
    clearAdminDirectoriesList(state) {
      state.entities.directoriesList = initialAdminEntityState
    },
    clearAdminDirectoryInfo(state) {
      state.entities.directoryInfo = initialAdminEntityState
    },
  },
  extraReducers(builder) {
    setupExtraReducer(builder, getAdminUserList, 'users')
    setupExtraReducer(builder, getAdminUserId, 'userId')
    setupExtraReducer(builder, getAdminRole, 'adminRoleOptions')
    setupExtraReducer(builder, getAdminStatus, 'adminStatusOptions')
    setupExtraReducer(builder, getLogList, 'logList')
    setupExtraReducer(builder, getDirectoriesList, 'directoriesList')
    setupExtraReducer(builder, getDirectoryInfo, 'directoryInfo')
    setupExtraReducer(builder, getAdminVisa, 'visa')
    setupExtraReducer(builder, getAdminLegalRepresentative, 'legalRepresentative')
  },
})

function setupExtraReducer(
  builder: ActionReducerMapBuilder<AdminInitialState>,
  asyncThunk: AsyncThunk<any, any, {}>,
  key: keyof AdminInitialState['entities']
) {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state.entities[key].status = 'loading'
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      state.entities[key].status = 'succeeded'
      state.entities[key].data = action.payload
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      state.entities[key].status = 'failed'
      state.entities[key].error = action.payload
      state.entities[key].data = null
    })
}

export default adminSlice.reducer
