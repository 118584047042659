import { useFieldArray, useForm } from 'react-hook-form'

import { ViewerResultExamFormData, ViewerResultGTrialTypeForm } from '@models'

const useTrialsTypeForm = () => {
  const { register, watch, control, handleSubmit, getValues, setValue, ...rest } =
    useForm<ViewerResultGTrialTypeForm>({ mode: 'all' })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'resultEge',
  })

  return {
    fields,
    control,
    setValue,
    append,
    remove,
    register,
    getValues,
    ...rest,
  }
}

export default useTrialsTypeForm
