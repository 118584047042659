import React from 'react'

import { NavRoute } from 'types/custom-types'

import ProgressTab from '@components/ProgressTab'

type PageWithSidenavProps = React.PropsWithChildren<{
  activePage: NavRoute | undefined
}>

const PageWithProgressTab = ({ children, activePage }: PageWithSidenavProps) => {
  return (
    <div className="progresstab_page">
      <h1 className="progresstab_page__title">{activePage?.title}</h1>
      <div className="progresstab_page__tab-container">
        <ProgressTab
          activePage={activePage}
          className="progresstab_page__tab"
          classNameElement="progresstab_page__tab_element"
        />
      </div>
      <div className="progresstab_page__content">
        <div className="progresstab_page__inner">{children}</div>
      </div>
    </div>
  )
}

export default PageWithProgressTab
