import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import EntranceExamsTable from './components/Table'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import {
  getStatementDisciplines,
  getMoodleCourses,
  getDisciplineTrials,
} from '@redux/action-types'
import {
  selectDisciplines,
  selectLocalizedViewerProfile,
  selectMoodleCourses,
} from '@redux/actions/selectors'

const EntranceExamsForm = (scenarioKeys) => {
  const dispatch = useAppDispatch()

  const disciplines = useAppSelector(selectDisciplines) || []
  const profile = useAppSelector(selectLocalizedViewerProfile)
  const { statementId } = useParams()
  const courses = useAppSelector(selectMoodleCourses)

  useEffect(() => {
    if (statementId) {
      dispatch(getStatementDisciplines(Number(statementId)))
    }
  }, [])

  useEffect(() => {
    profile?.externalId &&
      dispatch(getMoodleCourses({ externalId: profile.externalId }))

    profile?.id && dispatch(getDisciplineTrials(`${profile.id}`))
  }, [profile?.externalId, profile?.id])

  return (
    <>
      <div className="entr-exams__prompt">
        <p className="entr-exams__text entr-exams__prompt-text">
          Расписание вступительных испытаний будет установлено позже. Следите за
          обновлениями данного раздела.
        </p>
      </div>
      {disciplines.length > 0 && (
        <p className="entr-exams__text">
          В день экзамена Вам необходимо зайти в данный раздел и приступить к
          экзамену, нажав кнопку “Начать экзамен”
        </p>
      )}
      <EntranceExamsTable {...scenarioKeys} />
    </>
  )
}

export default EntranceExamsForm
