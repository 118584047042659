import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ModeratorDataList from '@components/ModeratorDataList'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { DataList, ModeratorTargetContractData } from '@models'
import ModeratorDocumentsPage from '@pages/moderator/ModeratorDocumentsPage'
import { getModeratorStatementsTargetContractData } from '@redux/action-types'
import { selectModeratorStatementsTargetContractData } from '@redux/actions/selectors'

const ModeratorTargetContractPage = () => {
  const dispatch = useAppDispatch()

  const { id } = useParams<{ id?: string }>()

  useEffect(() => {
    if (id) {
      dispatch(getModeratorStatementsTargetContractData({ id: id }))
    }
  }, [id])

  const { data, status }: { data: ModeratorTargetContractData; status: boolean } =
    useAppSelector(selectModeratorStatementsTargetContractData) || {
      data: {},
      status: false,
    }

  const list: DataList = [
    {
      title: 'Наименование заказчика целевого обучения:',
      label: data?.customerName || '-',
    },
    {
      title:
        'Идентификационный номер предложения заказчика о заключении договора на платформе «Работа России»:',
      label: data?.idNumberWorkInRussia || '-',
    },
  ]

  return (
    <div className={'moderator-data-list'}>
      {status ? (
        <>
          <ModeratorDataList list={list} />
          <ModeratorDocumentsPage
            params={[
              {
                typeDoc: 'target_contract',
                title:
                  'Файл заполненной заявки на заключение договора о целевом обучении',
              },
            ]}
          />
        </>
      ) : (
        <p>Нет целевого договора</p>
      )}
    </div>
  )
}

export default ModeratorTargetContractPage
