import { PageDataView } from '../models'
import { logo, textLogo } from '../types/custom-types'

export const getData = () => {
  let dataVIew = []
  dataVIew['name'] = localStorage.getItem('name') || null
  dataVIew['logo'] = localStorage.getItem('logo') || null
  dataVIew['picture'] = localStorage.getItem('picture') || null
  dataVIew['textLogo'] = localStorage.getItem('textLogo') || null
  dataVIew['textPicture'] = localStorage.getItem('textPicture') || null
  dataVIew['textPictureLink'] = localStorage.getItem('textPictureLink') || null
  dataVIew['link'] = localStorage.getItem('link') || null
  dataVIew['titleLogin'] = localStorage.getItem('titleLogin') || null
  dataVIew['titleRegister'] = localStorage.getItem('titleRegister') || null
  dataVIew['leftSideText'] = localStorage.getItem('leftSideText') || null
  dataVIew['leftTextTitle'] = localStorage.getItem('leftTextTitle') || null
  dataVIew['rightSideText'] = localStorage.getItem('rightSideText') || null
  dataVIew['rightTextTitle'] = localStorage.getItem('rightTextTitle') || null
  return dataVIew as logo
}

export const saveData = (data: PageDataView) => {
  localStorage.setItem('textLogo', data['textLogo'])
  localStorage.setItem('logo', data['logo'])
  localStorage.setItem('picture', data['picture'])
  localStorage.setItem('name', data['name'])
  localStorage.setItem('textPicture', data['textPicture'])
  localStorage.setItem('textPictureLink', data['textPictureLink'])
  localStorage.setItem('link', data['link'])
  localStorage.setItem('titleLogin', data['titleLogin'])
  localStorage.setItem('titleRegister', data['titleRegister'])
  localStorage.setItem('leftSideText', data['leftSideText'])
  localStorage.setItem('leftTextTitle', data['leftTextTitle'])
  localStorage.setItem('rightSideText', data['rightSideText'])
  localStorage.setItem('rightTextTitle', data['rightTextTitle'])
}
