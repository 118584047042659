import { any } from 'pusher-js/types/src/core/utils/collections'

import regexp from '@common/regexp'
import terms from '@common/terms'

// Дата в формате yyyy-mm-dd
export const normalizeYearTo4Characters = (date: string) => {
  if (!date) {
    return date
  }
  let [year, ...rest] = date.split('-')
  if (year.length > 4) {
    year = year.slice(1, 5)

    if (year.startsWith('0')) {
      year = '1' + year.slice(1, 4)
    }
  }
  return [year, ...rest].join('-')
}

export const moreThan18age = (date: string | Date) => {
  const d = new Date(date)
  return new Date(d.getFullYear() + 18, d.getMonth(), d.getDate()) <= new Date()
}

export const dateInRange = (
  value: string | Date,
  minYear: number = 10,
  maxYear = 100
) => {
  const diffBetweenNow =
    new Date(Date.now() - new Date(value).getTime()).getUTCFullYear() - 1970
  return diffBetweenNow >= minYear && diffBetweenNow <= maxYear
}

export const dateLessThanCurrentOne = (date: string | Date) => {
  return new Date(date) < new Date()
}

// Снилс в формате nnn-nnn-nnn nn
export const validateSNILS = (snils: string) => {
  const number = snils.toString().replace(/\D/g, '')

  // Цифр всегда 11
  if (number.length != 11) return false

  const controlCode = Number(number.substr(-2))
  let calcCode = number
    .substr(0, 9)
    .split('')
    .map((N, idx) => Number(N) * (9 - idx))
    .reduce((Sum, N) => Sum + N)
  return (
    (calcCode < 100 && calcCode === controlCode) ||
    ((calcCode === 100 || calcCode === 101) && controlCode === 0) ||
    calcCode % 101 === controlCode ||
    (calcCode % 101 === 100 && controlCode === 0)
  )
}

export const normalizeSNILS = (snils: string) => {
  const part1 = snils
    .replace(/\D/g, '')
    .match(/.{1,3}/g)
    ?.join('-')
    .substring(0, 11)

  const lastChar = snils[snils.length - 1]
  if (snils.length === 12 && lastChar !== ' ') {
    return part1 + ' ' + lastChar
  }

  const hasSecondPart = snils.length > 11
  const part2 = hasSecondPart ? snils.substring(12, 14) : ''
  return part1 + (hasSecondPart ? ' ' + part2 : '') || ''
}

export const getMobileValidators = (phoneCode?: string) => {
  return {
    pattern: { value: regexp.mobile, message: terms.VALID_MOBILE },
    maxLength: {
      value: phoneCode === '+7' ? 20 : 1000000000000,
      message: terms.PHONE_LENGTH,
    },
    minLength: {
      value: phoneCode === '+7' ? 2 : 0,
      message: terms.PHONE_LENGTH,
    },
  }
}

export const getPassportNumber = (isRussianPassport: boolean) => {
  return {
    pattern: {
      value: isRussianPassport
        ? regexp.passportNumber
        : regexp.passportNumberForeign,
      message: terms.PASSPORT_NUMBER_LENGTH,
    },
    maxLength: {
      value: isRussianPassport ? 6 : 10000000000,
      message: terms.PASSPORT_NUMBER_LENGTH,
    },
    minLength: {
      value: isRussianPassport ? 6 : 0,
      message: terms.PASSPORT_NUMBER_LENGTH,
    },
  }
}
export const getPassportSeries = (required: boolean) => {
  return {
    pattern: {
      value: required ? regexp.passportSeries : regexp.passportSeriesForeign,
      message: terms.PASSPORT_SERIES_LENGTH,
    },
    maxLength: {
      value: required ? 4 : 1000000000,
      message: terms.PASSPORT_SERIES_LENGTH,
    },
    minLength: {
      value: required ? 4 : 0,
      message: terms.PASSPORT_SERIES_LENGTH,
    },
  }
}

export const getNumberInList = () => {
  return {
    pattern: {
      value: regexp.numberInList,
      message: terms.NUMBER_IN_LIST_LENGTH,
    },
    maxLength: {
      value: 3,
      message: terms.NUMBER_IN_LIST_LENGTH,
    },
    minLength: {
      value: 3,
      message: terms.NUMBER_IN_LIST_LENGTH,
    },
  }
}

export const getDivisionCode = () => {
  return {
    pattern: {
      value: regexp.divisionCode,
      message: terms.DIVISION_CODE_TYPE,
    },
    maxLength: {
      value: 7,
      message: terms.DIVISION_CODE_LENGTH,
    },
    minLength: {
      value: 7,
      message: terms.DIVISION_CODE_LENGTH,
    },
  }
}

export const getBankIdentifierCode = () => {
  return {
    pattern: {
      value: regexp.bankIdentifierCode,
      message: terms.BANK_IDENTIFIERR_CODE,
    },
  }
}

export const getCheckingAccount = () => {
  return {
    pattern: {
      value: regexp.checkingAndCorrespondentAccounts,
      message: terms.CHECKING_ACCOUNT,
    },
  }
}

export const getCorrespondentAccount = () => {
  return {
    pattern: {
      value: regexp.checkingAndCorrespondentAccounts,
      message: terms.CORRESPONDENT_ACCOUNT,
    },
  }
}
export const getNumAndCyrillicValid = () => {
  return {
    pattern: {
      value: regexp.checkingNumberAndCyrillicInString,
      message: terms.CYRILLIC_AND_NUMBERS_ONLY,
    },
  }
}

export const getNumAndCyrillicValidHouse = (isRegRussia: boolean) => {
  return {
    pattern: {
      value: isRegRussia
        ? regexp.textArea
        : regexp.checkingNumberAndCyrillicInString,
      message: terms.CYRILLIC_AND_NUMBERS_ONLY,
    },
  }
}

export const getNumAndCyrillcAndLatinValid = () => {
  return {
    pattern: {
      value: regexp.checkingNumberAndCyrillicAndLatinInString,
      message: terms.CYRILLIC_AND_Latin_AND_NUMBERS_ONLY,
    },
  }
}
export const getNumAndCyrillcAndDashValid = () => {
  return {
    pattern: {
      value: regexp.checkingNumberAndCyrillicDashInString,
      message: terms.CYRILLIC_AND_Latin_AND_NUMBERS_ONLY,
    },
  }
}
export const validateDate = (date: string | null, birthDate: string) => {
  if (!date) {
    return false
  }

  const dateItems = date.split('-')
  const birthdayItems = birthDate?.split('-')
  let afterBirthday: boolean

  if (birthDate && birthDate.length > 0) {
    afterBirthday =
      new Date(
        Number(dateItems[0]),
        Number(dateItems[1]) - 1,
        Number(dateItems[2])
      ) >
      new Date(
        Number(birthdayItems[0]),
        Number(birthdayItems[1]) - 1,
        Number(birthdayItems[2])
      )
  } else {
    afterBirthday = true
  }

  if (dateItems.length === 3) {
    return (
      new Date(
        Number(dateItems[0]),
        Number(dateItems[1]) - 1,
        Number(dateItems[2])
      ) <= new Date() && afterBirthday
    )
  } else {
    return true
  }
}

export const validIssueDate = (date: string | null) => {
  if (!date) {
    return true
  }

  const dateItems = date.split('-')

  if (dateItems.length === 3) {
    return (
      new Date(
        Number(dateItems[0]),
        Number(dateItems[1]) - 1,
        Number(dateItems[2])
      ) >= new Date()
    )
  } else {
    return true
  }
}

// valid date format yyyy-mm-dd
export const dateLastValidDate = (date: string | null, validDate: string) => {
  if (!date) {
    return true
  }

  const dateItems = date.split('-')
  const validDateItems = validDate.split('-')

  if (dateItems.length === 3) {
    return (
      new Date(
        Number(dateItems[0]),
        Number(dateItems[1]) - 1,
        Number(dateItems[2])
      ) >=
      new Date(
        Number(validDateItems[0]),
        Number(validDateItems[1]) - 1,
        Number(validDateItems[2])
      )
    )
  } else {
    return true
  }
}
