import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import { ROLE_SUPPORT, ROLE_USER } from '@common/constants'
import { FilterAdminUserList, FilterAdminUserListData } from '@models'
import { getAdminUserList } from '@redux/action-types/admin'
import {
  selectAdminPageUsersList,
  selectAdminPerPageUsersList,
  selectRoleIdProfile,
  setAdminUsersFilterData,
} from '@redux/actions/selectors'

const useAdminUserListFilterForm = () => {
  const dispatch = useAppDispatch()

  const { register, handleSubmit, control, reset, setValue } = useForm<
    FilterAdminUserList
  >({ mode: 'all' })

  const fields = {
    userId: register('userId'),
    surname: register('surname'),
    name: register('name'),
    patronymic: register('patronymic'),
    email: register('email'),
    status: register('status'),
    passportNumberDocument: register('passportNumberDocument'),
    passportSerialDocument: register('passportSerialDocument'),

    perPage: register('perPage'),
    page: register('page'),
  }

  const roleId = useAppSelector(selectRoleIdProfile)
  const page = useAppSelector(selectAdminPageUsersList)
  const perPage = useAppSelector(selectAdminPerPageUsersList)

  useEffect(() => {
    setValue('page', page)
    setValue('perPage', perPage)
    onSubmit()
  }, [page, perPage])

  const onSubmit = handleSubmit((data: any) => {
    const normalizedData: FilterAdminUserList = {
      ...data,
      status: data.status?.value,
      role: ROLE_USER,
      support: roleId === ROLE_SUPPORT,
    }
    dispatch(setAdminUsersFilterData(normalizedData))
    dispatch(getAdminUserList(normalizedData))
  })

  return {
    fields,
    control,
    reset,
    onSubmit,
  }
}

export default useAdminUserListFilterForm
