import React, { useEffect, useMemo, useState } from 'react'
import { Controller } from 'react-hook-form'

import { EN_LOCALE_ID, HIGH_EDUCATION_CODE, RU_LOCALE_ID } from '@common/constants'
import terms, { locale } from '@common/terms'
import ProfileForm from '@components/ProfileForm'
import ProfileSection from '@components/ProfileSection'
import useAppSelector from '@hooks/useAppSelector'
import useEducationForm from '@hooks/useEducationForm'
import useEducationFormInitialization from '@hooks/useEducationFormInitialization'
import useProfileRoutesContext from '@hooks/useProfileRoutesContext'
import useUploadDocuments from '@hooks/useUploadDocuments'
import { SelectOption } from '@models'
import {
  selectIsQuestionnaireBlockProfile,
  selectLevelEducKeysOptions,
  selectLocalizedViewerProfile,
} from '@redux/actions/selectors'
import Checkbox from '@ui/Checkbox'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import Select from '@ui/Select'

/**
 * Список стран и их ID в системе, для которых не должен показываться раздел загрузки
 * "Иных" документов
 */
enum countriesId {
  RUSSIA = 1,
  BELARUS = 4,
  KAZAKHSTAN = 88,
  KYRGYZSTAN = 95,
  TAJIKISTAN = 3,
}

const EducationForm = () => {
  const profileData = useAppSelector(selectLocalizedViewerProfile)

  const {
    gpaFront,
    watch,
    fields,
    control,
    onSubmit,
    getValues,
    setValue,
    isHigherEducation,
    typeEducation,
    setIsHigherEducation,
    isGraduatePsychologicalAndPedagogicalClass,
    formState: { errors },
    educationNationalityId,
  } = useEducationForm()

  const readOnly = useAppSelector(selectIsQuestionnaireBlockProfile)
  const isInoSignUpCode = !!profileData && profileData.code === '2'
  const { onNextPageNavigate } = useProfileRoutesContext()

  /**
   * Гражданство пользователя совпадает со странами,
   * для которых не надо показывать раздел "Иные" документы?
   */
  const isExemptFromOtherDocs = useMemo<boolean>(() => {
    if (!profileData) {
      return false
    }

    if (profileData.userNational.length === 0) {
      return false
    }

    const profileNationalityId = profileData.userNational[0].nationalityId

    return countriesId[profileNationalityId] ? true : false
  }, [profileData, countriesId])

  const summ = gpaFront?.reduce(
    (prev, elem) => {
      return {
        sum:
          prev.sum +
          elem.count *
            ((elem.code === 'PR-03-01' && 3) ||
              (elem.code === 'OK-04-02' && 4) ||
              (elem.code === 'GR-05-03' && 5) ||
              0),
        count: prev.count + Number(elem.count),
      }
    },
    { sum: 0, count: 0 }
  ) || { sum: 0, count: 0 }

  const {
    // nationalSelectProps,
    directionTrainingSelectProps,
    nationalSelectPropsRus,
    nationalSelectPropsIno,
    formEducationOptions,
    levelEducationOptions,
    typeEducationDocumentOptions,
    previousEduForMastersOptions,
    foreignLanguageOptions,
  } = useEducationFormInitialization({
    setValue,
    watch,
    isHigherEducation,
    setIsHigherEducation,
  })

  const educationNationalityID = (watch('nationalityId') as any)?.value
  const educationNationalityIsRussia = educationNationalityID === countriesId.RUSSIA
  const levelsEducationCodes = useAppSelector(selectLevelEducKeysOptions)
  const [isShowGraduateInstitutionCheckbox, setIsShowGraduateInstitutionCheckbox] =
    useState(false)

  useEffect(() => {
    if (
      profileData?.userNational[0].nationalityId === RU_LOCALE_ID &&
      educationNationalityIsRussia
    ) {
      setIsShowGraduateInstitutionCheckbox(true)
    } else if (
      profileData?.userNational[0].nationalityId === RU_LOCALE_ID &&
      !educationNationalityIsRussia
    ) {
      setIsShowGraduateInstitutionCheckbox(false)
    } else if (
      profileData?.userNational[0].nationalityId !== RU_LOCALE_ID &&
      educationNationalityIsRussia
    ) {
      setIsShowGraduateInstitutionCheckbox(true)
    } else {
      setIsShowGraduateInstitutionCheckbox(false)
    }
  }, [educationNationalityIsRussia, profileData])

  let higherEducationDocumentsIds: any = []
  levelsEducationCodes.map((value) => {
    if (value.uniqueKeyOnSite == HIGH_EDUCATION_CODE) {
      higherEducationDocumentsIds.push(value.id)
    }
  })
  const {
    docs,
    onDrop,
    onDelete,
    onChange,
    onUploadDocs,
    getUploadedDoc,
    documentLoaded,
  } = useUploadDocuments('EDUCATION', terms.EDUCATIONAL_DOCUMENT)

  const {
    docs: scanTranslationDocEdu,
    onDrop: scanTranslationDocEduOnDrop,
    onDelete: scanTranslationDocEduOnDelete,
    onChange: scanTranslationDocEduOnChange,
    onUploadDocs: scanTranslationDocEduOnUploadDocs,
    getUploadedDoc: scanTranslationDocEduGetUploadedDoc,
    documentLoaded: scanTranslationDocEduDocumentLoaded,
  } = useUploadDocuments('SCAN_TRANSLATION_DOC_EDY', terms.SCAN_TRANSLATION_DOC_EDY)

  const {
    docs: nostrificationEdu,
    onDrop: nostrificationEduOnDrop,
    onDelete: nostrificationEduOnDelete,
    onChange: nostrificationEduOnChange,
    onUploadDocs: nostrificationEduOnUploadDocs,
    getUploadedDoc: nostrificationEduGetUploadedDoc,
    documentLoaded: nostrificationEduDocumentLoaded,
  } = useUploadDocuments('NOSTRIFICATION_EDU', terms.NOSTRIFICATION_EDU)

  const {
    docs: otherDocuments,
    onDrop: otherDocumentsOnDrop,
    onDelete: otherDocumentsOnDelete,
    onChange: otherDocumentsOnChange,
    onUploadDocs: otherDocumentsOnUploasDocs,
    getUploadedDoc: otherDocumentsGetUpLoadedDoc,
    documentLoaded: otherDocumentsDocumentLoaded,
  } = useUploadDocuments('OTHER_DOCUMENTS', terms.OTHER_DOCUMENTS)

  const {
    docs: graduatePsychologicalAndPedagogicalClassDocs,
    onDrop: graduatePsychologicalAndPedagogicalClassOnDrop,
    onDelete: graduatePsychologicalAndPedagogicalClassOnDelete,
    onChange: graduatePsychologicalAndPedagogicalClassOnChange,
    onUploadDocs: graduatePsychologicalAndPedagogicalClassOnUploadDocs,
    getUploadedDoc: graduatePsychologicalAndPedagogicalClassGetUploadedDoc,
    documentLoaded: graduatePsychologicalAndPedagogicalClassDocumentLoaded,
  } = useUploadDocuments('SUPPORTING_DOCUMENT', terms.SUPPORTING_DOCUMENT)

  useEffect(() => {
    if (!documentLoaded) {
      getUploadedDoc()
    }
    if (!graduatePsychologicalAndPedagogicalClassDocumentLoaded) {
      graduatePsychologicalAndPedagogicalClassGetUploadedDoc()
    }

    if (!nostrificationEduDocumentLoaded) {
      nostrificationEduGetUploadedDoc()
    }

    if (!otherDocumentsDocumentLoaded) {
      otherDocumentsGetUpLoadedDoc()
    }

    if (!scanTranslationDocEduDocumentLoaded) {
      scanTranslationDocEduGetUploadedDoc()
    }
  }, [])

  useEffect(() => {
    setIsHigherEducation(
      higherEducationDocumentsIds.includes(typeEducation?.value || 1)
    )
  }, [watch('typeEducationDocumentId')])

  return (
    <ProfileForm
      onSubmit={
        readOnly
          ? (e) => {
              e.preventDefault()
              onNextPageNavigate()
            }
          : (e) => {
              onSubmit(e)
              if (Object.keys(errors).length === 0) {
                onUploadDocs(() => getUploadedDoc())

                graduatePsychologicalAndPedagogicalClassOnUploadDocs(() =>
                  graduatePsychologicalAndPedagogicalClassGetUploadedDoc()
                )

                nostrificationEduOnUploadDocs(() =>
                  nostrificationEduGetUploadedDoc()
                )

                scanTranslationDocEduOnUploadDocs(() =>
                  scanTranslationDocEduGetUploadedDoc()
                )

                otherDocumentsOnUploasDocs(() => otherDocumentsGetUpLoadedDoc())
              }
            }
      }
    >
      <ProfileSection>
        <div className="profile-form__fields">
          <Controller
            name="typeEducationDocumentId"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                className="profile-form__field"
                {...(field as unknown)}
                isDisabled={readOnly}
                options={typeEducationDocumentOptions}
                placeholder={terms.DOCUMENT_TYPE}
                error={errors.typeEducationDocumentId?.message}
                markRequired={true}
              />
            )}
          />
          <div className="profile-form__field">
            <Input
              placeholder={terms.EDUCATIONAL_INSTITUTION}
              type="text"
              markRequired={true}
              {...fields.nameEducationInstitution}
              disabled={readOnly}
              readOnly={watch('finishedSpbpu')}
              error={errors.nameEducationInstitution?.message}
            />
            <Checkbox
              text={terms.GRADUATED_FROM_SPBPU}
              className="profile-form__checkbox"
              {...fields.finishedSpbpu}
              disabled={readOnly}
            />
          </div>
          <Controller
            name="levelEducationId"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                className="profile-form__field"
                {...(field as unknown)}
                isDisabled={readOnly}
                options={levelEducationOptions}
                placeholder={terms.STUDY_LEVEL}
                error={errors.levelEducationId?.message}
                markRequired={true}
              />
            )}
          />
          <Controller
            name="nationalityId"
            control={control}
            render={({ field }) =>
              isInoSignUpCode ? (
                <Select<SelectOption>
                  className="profile-form__field"
                  placeholder={terms.COUNTRY_WHERE_YOU_STUDIED}
                  markRequired={true}
                  {...(field as unknown)}
                  isDisabled={readOnly}
                  {...nationalSelectPropsIno}
                  error={errors.nationalityId?.message}
                />
              ) : (
                <Select<SelectOption>
                  className="profile-form__field"
                  placeholder={terms.COUNTRY_WHERE_YOU_STUDIED}
                  markRequired={true}
                  {...(field as unknown)}
                  isDisabled={readOnly}
                  {...nationalSelectPropsRus}
                  error={errors.nationalityId?.message}
                />
              )
            }
          />
          <Input
            className="profile-form__field"
            placeholder={terms.DOCUMENT_SERIES_AND_NUMBER}
            type="text"
            markRequired={true}
            {...fields.numberEducationDocument}
            disabled={readOnly}
            error={errors.numberEducationDocument?.message}
          />
          <Controller
            name="formEducationId"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                className="profile-form__field"
                {...(field as unknown)}
                isDisabled={readOnly}
                options={formEducationOptions}
                placeholder={terms.MODE_OF_STUDY}
                error={errors.formEducationId?.message}
                markRequired={true}
              />
            )}
          />
          <Input
            className="profile-form__field"
            placeholder={terms.DATE_OF_ISSUE}
            type="date"
            markRequired={true}
            {...fields.dateDocumentIssue}
            disabled={readOnly}
            error={errors.dateDocumentIssue?.message}
          />
          <Input
            className="profile-form__field"
            placeholder={terms.YEAR_OF_GRADUATION}
            type="text"
            maxLength={4}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault()
              }
            }}
            markRequired={true}
            {...fields.yearGraduation}
            disabled={readOnly}
            error={errors.yearGraduation?.message}
          />
          {/*<div>
            <Checkbox
              text={terms.DIPLOMA_WITH_DISTINCTION}
              className="profile-form__checkbox"
              {...fields.diplomaWithHonors}
              disabled={readOnly}
            />
          </div>*/}
          <Controller
            name="foreignLanguageStudy"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                className="profile-form__field"
                {...(field as unknown)}
                isDisabled={readOnly}
                options={foreignLanguageOptions}
                placeholder={terms.FOREIGN_lANGUAGE_STUDY}
                error={errors.foreignLanguageStudy?.message}
                markRequired={true}
              />
            )}
          />
          {isHigherEducation && (
            <Controller
              name="directionTrainingId"
              control={control}
              render={({ field }) => (
                <div className="profile-form__field profile-form-select__checkbox">
                  <Select<SelectOption>
                    {...(field as unknown)}
                    isDisabled={watch('noMatchDirectionTraining') ? true : readOnly}
                    {...directionTrainingSelectProps}
                    placeholder={terms.SPECIALIZATION_OF_LAST_EDU}
                    error={errors.directionTrainingId?.message}
                    markRequired={true}
                  />
                  <Checkbox
                    disabled={readOnly}
                    text={terms.HAVE_NO_MATCH}
                    className="profile-form__checkbox"
                    {...fields.noMatchDirectionTraining}
                  />
                </div>
              )}
            />
          )}
        </div>
      </ProfileSection>
      {profileData?.userNational[0].nationalityId === RU_LOCALE_ID && (
        <Checkbox
          disabled={readOnly}
          text={terms.STUDIED_IN_RGPU_FOUNDATION_PROGRAM}
          className={`profile-form__checkbox ${
            !isShowGraduateInstitutionCheckbox ? 'profile-form__checkbox--mb-24' : ''
          }`}
          {...fields.studiedPrepatoryDepartment}
        />
      )}
      {/*{isShowGraduateInstitutionCheckbox && (*/}
      {/*  <Checkbox*/}
      {/*    disabled={readOnly}*/}
      {/*    text={terms.GRADUATE_INSTITUTION}*/}
      {/*    className="profile-form__checkbox profile-form__checkbox--mb-24"*/}
      {/*    {...fields.graduatePsychologicalAndPedagogicalClass}*/}
      {/*  />*/}
      {/*)}*/}
      <p>
        При расхождении ФИО в документе об образовании и документе, удостоверяющем
        личность, загрузите скан-копию подтверждающего документа в раздел
        «Дополнительные файлы» после создания заявления на поступление.
      </p>
      <br />
      {isGraduatePsychologicalAndPedagogicalClass && (
        <ProfileSection
          title={terms.SUPPORTING_DOCUMENT}
          hint={terms.HINT_SUPPORTING_DOCUMENT}
          fileUploadingHint
        >
          <FileUploader
            disabled={readOnly}
            files={graduatePsychologicalAndPedagogicalClassDocs}
            onDrop={graduatePsychologicalAndPedagogicalClassOnDrop}
            onChange={graduatePsychologicalAndPedagogicalClassOnChange}
            onDelete={graduatePsychologicalAndPedagogicalClassOnDelete}
            className="profile-form__file-uploader"
          />
        </ProfileSection>
      )}
      <ProfileSection
        title={terms.EDUCATIONAL_DOCUMENT}
        fileUploadingHint
        hint={
          educationNationalityID === RU_LOCALE_ID
            ? terms.YOU_NEED_DOWNLOAD_SCAN_CERTIFICATE_PAGE
            : terms.YOU_NEED_DOWNLOAD_SCAN_CERTIFICATE_PAGE_INO
        }
        isHintTooltip={false}
        markRequired={true}
      >
        <FileUploader
          disabled={readOnly}
          files={docs}
          onDrop={onDrop}
          onChange={onChange}
          onDelete={onDelete}
          className="profile-form__file-uploader"
        />
      </ProfileSection>

      <br />

      {!educationNationalityIsRussia && (
        <ProfileSection
          title={
            educationNationalityID === RU_LOCALE_ID
              ? terms.SCAN_TRANSLATION_DOC_EDY
              : terms.SCAN_TRANSLATION_DOC_EDY_INO
          }
          fileUploadingHint
          isHintTooltip={false}
          markRequired={true}
        >
          <FileUploader
            disabled={readOnly}
            files={scanTranslationDocEdu}
            onDrop={scanTranslationDocEduOnDrop}
            onChange={scanTranslationDocEduOnChange}
            onDelete={scanTranslationDocEduOnDelete}
            className="profile-form__file-uploader"
          />
        </ProfileSection>
      )}

      {!educationNationalityIsRussia && (
        <ProfileSection
          title={terms.EDUCATIONAL_DOCUMENT_SECOND}
          fileUploadingHint
          isHintTooltip={false}
        >
          <FileUploader
            disabled={readOnly}
            files={nostrificationEdu}
            onDrop={nostrificationEduOnDrop}
            onChange={nostrificationEduOnChange}
            onDelete={nostrificationEduOnDelete}
            className="profile-form__file-uploader"
          />
        </ProfileSection>
      )}

      {!isExemptFromOtherDocs && (
        <ProfileSection
          title={terms.OTHER_DOCUMENTS}
          fileUploadingHint
          isHintTooltip={false}
        >
          <FileUploader
            disabled={readOnly}
            files={otherDocuments}
            onDrop={otherDocumentsOnDrop}
            onChange={otherDocumentsOnChange}
            onDelete={otherDocumentsOnDelete}
            className="profile-form__file-uploader"
          />
        </ProfileSection>
      )}
    </ProfileForm>
  )
}

export default EducationForm
