import React from 'react'
import { NavLink } from 'react-router-dom'

import {
  ROLE_ADMIN,
  ROLE_SUPPORT,
  STATUS_USER_ACTIVE,
  STATUS_USER_BLOCKED,
  STATUS_USER_NOT_ACTIVE,
} from '@common/constants'
import useAdminUserListElement from '@hooks/useAdminUserListElement'
import useAppSelector from '@hooks/useAppSelector'
import { AdminUserResponseData } from '@models'
import { selectRoleIdProfile } from '@redux/actions/selectors'
import { formatDateTimeToRuFormat, formatDateYYYYMMDD } from '@utils/date'

const AdminUserListElement = ({ item }: { item: AdminUserResponseData }) => {
  const { deleteUser, changeStatusUser } = useAdminUserListElement()

  const userTranslate = item?.usersTranslate?.length
    ? item.usersTranslate[0]
    : ({
        name: item?.fullName,
      } as any)
  const roleId = useAppSelector(selectRoleIdProfile)

  return (
    <div className={'admin-user-element'}>
      <div className={'admin-user-element__info'}>
        <h2>
          {`${userTranslate?.surname || ''} ${userTranslate?.name || ''} ${
            userTranslate?.patronymic || ''
          }`}
        </h2>
        <p>
          <b>ID пользователя:</b> {item?.idWithPrefix}
        </p>
        <p>
          <b>Почта:</b> <a href={`mailto:${item?.email}`}>{item?.email}</a>
        </p>
        <p>
          <b>Дата рождения:</b> {formatDateYYYYMMDD(item?.dateBirthday)}
        </p>
        <p>
          <b>Дата регистрации:</b> {formatDateTimeToRuFormat(item?.createdAt)}
        </p>
        <p>
          <b>Статус аккаунта:</b>{' '}
          {item?.confirmed === STATUS_USER_ACTIVE
            ? 'Активный'
            : item?.confirmed === STATUS_USER_BLOCKED
            ? 'Заблокированный'
            : item?.confirmed === STATUS_USER_NOT_ACTIVE ||
              item?.confirmed?.length > 2
            ? 'Неактивный '
            : '-'}
        </p>
      </div>
      {roleId === ROLE_SUPPORT &&
        (item?.confirmed === STATUS_USER_NOT_ACTIVE ||
          item?.confirmed?.length > 2) && (
          <div className={'admin-user-element__actions'}>
            <h2>Действия:</h2>
            <div
              onClick={() => changeStatusUser(item?.id, STATUS_USER_ACTIVE)}
              className={'admin-user-element__actions-btn'}
            >
              Активировать
            </div>
          </div>
        )}
      {roleId === ROLE_ADMIN && (
        <div className={'admin-user-element__actions'}>
          <h2>Действия:</h2>
          {(item?.confirmed === STATUS_USER_NOT_ACTIVE ||
            item?.confirmed?.length > 2) && (
            <div
              onClick={() => changeStatusUser(item?.id, STATUS_USER_ACTIVE)}
              className={'admin-user-element__actions-btn'}
            >
              Активировать
            </div>
          )}
          {item?.confirmed === STATUS_USER_ACTIVE && (
            <div
              onClick={() => changeStatusUser(item?.id, STATUS_USER_BLOCKED)}
              className={'admin-user-element__actions-btn'}
            >
              Заблокировать
            </div>
          )}
          <NavLink to={'/admin/users/' + item?.id || ''} target="_blank">
            <div className={'admin-user-element__actions-btn'}>
              Зайти в личный кабинет пользователя
            </div>
          </NavLink>
          <div
            onClick={() => deleteUser(item?.id)}
            className={'admin-user-element__actions-btn'}
          >
            Удалить пользователя
          </div>
        </div>
      )}
    </div>
  )
}

export default AdminUserListElement
