import useAppSelector from './useAppSelector'
import { selectTabEgeList } from '@redux/actions/selectors'

const useTypeExamResult = () => {
  const tabEgeData = useAppSelector(selectTabEgeList)

  const type: string = tabEgeData?.type || ''

  return { type }
}

export default useTypeExamResult
