import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { NavLink, useParams } from 'react-router-dom'

import ModeratorEditContractForm from './../ModeratorEditContractForm/view'
import ModeratorEditContractJuridicalForm from './../ModeratorEditContractJuridicalForm/view'
import ModeratorContractIdInfoEntrants from './components/ModeratorContractIdInfoEntrants'
import ModeratorContractIdInfoJuridical from './components/ModeratorContractIdInfoJuridical'
import ModeratorContractIdInfoNotEntrants from './components/ModeratorContractIdInfoNotEntrants'
import {
  CONTRACT_WORK,
  CONTRACT_EDITED,
  CONTRACT_EDITING,
  PAYER_TYPE_MATERNAL_CAPITAL,
  ROLE_ADMIN,
  ROLE_MODERATOR,
  CONTRACT_SIGNED,
  CONTRACT_SIGNED_APPLICANT,
  CONTRACT_RECEIPT_RECEIVED,
  CONTRACT_RECEIPT_SENT,
  CONTRACT_DRAFT,
  CONTRACT_APPROVED,
} from '@common/constants'
import NotEntrantsContractForm from '@components/CreateContractForm/components/NotEntrantsContractForm/view'
import ModeratorFile from '@components/ModeratorFile'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useDownloadDocument from '@hooks/useDownloadDocument'
import useModeratorContractIdInfoForm from '@hooks/useModeratorContractIdInfoForm'
import useModeratorContractInitialization from '@hooks/useModeratorContractInitialization'
import useUploadDocuments from '@hooks/useUploadDocuments'
import { SelectOption } from '@models'
import {
  createToast,
  getModeratorContractInfo,
  getTrainingLevels,
} from '@redux/action-types'
import {
  selectModeratorContractInfo,
  selectTrainingLevels,
  selectContract,
  selectStatementsElement,
  selectTypeDocsOptions,
  selectLocalizedViewerProfile,
} from '@redux/actions/selectors'
import api from '@services/api'
import Breadcrumbs from '@ui/Breadcrumbs'
import Button from '@ui/Button'
import FileUploader from '@ui/FileUploader'
import Loader from '@ui/Loader'
import ReactSelect from '@ui/Select'
import Select from '@ui/Select'
import { base64ToPdf, downloadFile } from '@utils/common'

const ModeratorContractIdInfo = () => {
  const { id } = useParams<{ id: string; contractId: string }>()
  const { moderatorsListOptions, roleId, statusCode, statusAdmin } =
    useModeratorContractInitialization()

  const {
    control,
    reject,
    send,
    denied,
    approve,
    uploadContractModerator,
    downloadGeneratedContract,
    downloadGeneratedReceived,
    getModeratorDoc,
    fields,
    inWork,
    sendUser,
    loading,
    signed,
    receiptSent,
    revokeApprove,
    changeModerator,
    setValue,
  } = useModeratorContractIdInfoForm(id)

  const { downloadDocument } = useDownloadDocument(true)
  const dispatch = useAppDispatch()

  const {
    data: {
      competitiveGroupStatement: { statement, competitiveGroup },
      payerType,
      payerTypeId,
      paymentType,
      status,
      contractJuridical,
      contractIndividual,
      contractDoc,
      competitiveGroupStatementId,
      responsible,
    },
  } = useAppSelector(selectModeratorContractInfo) || {
    data: {
      competitiveGroupStatement: { statement: {}, competitiveGroup: {} },
      payerType: {},
      paymentType: {},
      status: {},
      payerTypeId: 0,
      contractJuridical: {},
      contractIndividual: {},
      contractDoc: [],
      responsible: [],
    },
  }

  const {
    docs: scanDocs,
    onDrop: onDropScan,
    onChange: onChangeScan,
    onDelete: onDeleteScan,
    onUploadDocs: onUploadScan,
    getUploadedDoc: getScantDoc,
    documentLoaded: passportLoaded,
    setDocs: setScanDocs,
  } = useUploadDocuments('CONTRACT_SCAN_MODERATOR', 'Копия договора')

  const {
    docs: scanDocsReceipt,
    onDrop: onDropScanReceipt,
    onChange: onChangeScanReceipt,
    onDelete: onDeleteScanReceipt,
    onUploadDocs: onUploadScanReceipt,
    getUploadedDoc: getScantDocReceipt,
    documentLoaded: receiptLoaded,
    setDocs: setScanDocsReceipt,
  } = useUploadDocuments('RECEIPT_PAYMENT_CONTRACT', 'Копия квитанции')

  const trainingLevels = useAppSelector(selectTrainingLevels)
  const docKeys = useAppSelector(selectTypeDocsOptions)
  let passportKeys
  passportKeys = {
    foreign_citizen_passport_code: docKeys.find(
      (value) => value.uniqueKeyOnSite == 49
    ),
    identity_card_stateless_person_code: docKeys.find(
      (value) => value.uniqueKeyOnSite == 90
    ),
    other_document_code: docKeys.find((value) => value.uniqueKeyOnSite == 128),
    passport_non_citizen_code: docKeys.find((value) => value.uniqueKeyOnSite == 153),
    russia_certificate_temporary_asylum_code: docKeys.find(
      (value) => value.uniqueKeyOnSite == 152
    ),
    russia_citizen_passport_code: docKeys.find(
      (value) => value.uniqueKeyOnSite == 47
    ),
    russia_foreign_passport_code: docKeys.find(
      (value) => value.uniqueKeyOnSite == 89
    ),
  }
  const formEduc = new Map([
    [1, 'Очная'],
    [2, 'Заочная'],
    [3, 'Очно-заочная'],
  ])
  useEffect(() => {
    dispatch(getTrainingLevels())
    dispatch(getModeratorContractInfo(String(id)))
  }, [])

  useEffect(() => {
    if (responsible[0]?.responsePersonId) {
      const moderator = moderatorsListOptions.find((el) => {
        return el.value === responsible[0].responsePersonId
      })

      setValue('moderatorId', moderator)
    }
  }, [responsible])

  useEffect(() => {
    const doc = contractDoc.find(
      (item) => item.userDoc.getTypeDocs.name === 'scan_for_contract_moderator'
    )
    const receipt = contractDoc.find(
      (item) => item.userDoc.getTypeDocs.name === 'receipt_payment_contract'
    )

    doc &&
      getModeratorDoc(doc.userDoc.getTypeDocs.name, doc.userDocId).then(({ data }) =>
        base64ToPdf(data.document, doc.userDoc.name.slice(0, -4)).then((file) =>
          setScanDocs([file])
        )
      )
    receipt &&
      getModeratorDoc(receipt.userDoc.getTypeDocs.name, receipt.userDocId).then(
        ({ data }) =>
          base64ToPdf(data.document, receipt.userDoc.name.slice(0, -4)).then(
            (file) => setScanDocsReceipt([file])
          )
      )
  }, [contractDoc])

  const isEditModerator = Number(status.id) === 4 || Number(status.id) === 2

  function sendTo1C() {
    api.client.getSendTo1C(Number(id))
  }

  function backToWorkContract() {
    api.client.putBackToWork({ id: Number(id), code: 2 })
  }

  function rejectContract() {
    api.client.putBackToWork({ id: Number(id), code: 7 })
  }

  if (!payerTypeId) {
    return <Loader />
  }

  return (
    <div className="moderator-contract-info">
      <div className="moderator-contract__header">
        <Breadcrumbs
          className={'moderator-contract-id-page__title'}
          title={'Договор'}
          link={'../contracts'}
        />

        {roleId === ROLE_ADMIN &&
          (statusCode && statusCode >= CONTRACT_WORK ? (
            <Controller
              name="moderatorId"
              control={control}
              render={({ field }) => (
                <Select<SelectOption>
                  className={'moderator-statements-id-page__header-select'}
                  {...(field as unknown)}
                  options={moderatorsListOptions}
                  onChange={(e) => {
                    if (e) {
                      field.onChange(e)
                      changeModerator(e.value)
                    }
                  }}
                  placeholder={'Модератор'}
                />
              )}
            />
          ) : (
            <Button
              className={'moderator-statements-id-page__header-btn'}
              onClick={inWork}
            >
              Взять в работу
            </Button>
          ))}

        {roleId === ROLE_MODERATOR &&
          (statusCode && statusCode < CONTRACT_WORK ? (
            <Button
              className={'moderator-statements-id-page__header-btn'}
              onClick={inWork}
            >
              Взять в работу
            </Button>
          ) : (
            <Button className={'moderator-statements-id-page__header-btn'}>
              В работе
            </Button>
          ))}
      </div>

      <div className="moderator-contract-info__block">
        <h2>
          <span>
            {statement?.users?.usersTranslate?.length &&
              `${statement?.users?.usersTranslate[0]?.surname || ''} ${
                statement?.users?.usersTranslate[0]?.name || ''
              } ${statement?.users?.usersTranslate[0]?.patronymic || ''}`}
          </span>
        </h2>

        {payerType?.code === PAYER_TYPE_MATERNAL_CAPITAL && (
          <h2>(Материнский капитал)</h2>
        )}
        <p>
          ID: <span>{statement?.userId}</span>
        </p>

        <p>
          Вид образования:{' '}
          <span>
            {trainingLevels.length &&
              competitiveGroup &&
              trainingLevels.find(
                (item) => item.value === competitiveGroup?.trainingLevelId
              )?.label}
          </span>
        </p>
      </div>

      <div className="moderator-contract-info__block">
        <h2>
          Направление и профиль поступления:{' '}
          <span>{`${competitiveGroup?.directionTraining?.translations[0].name} (${competitiveGroup?.profile?.translate[0]?.name})`}</span>
        </h2>
        <p>
          Форма обучения:{' '}
          <span>{formEduc.get(competitiveGroup?.formEducationId)}</span>
        </p>
        <p>
          Подразделение:{' '}
          <span>{competitiveGroup?.faculty?.facultyTranslate[0]?.name}</span>
        </p>

        <p>
          Статус договора:{' '}
          <span>{status?.translate?.length && status?.translate[0]?.name}</span>
        </p>

        <p>
          Тип платежа:{' '}
          <span>
            {paymentType?.translations?.length && paymentType?.translations[0]?.name}
          </span>
        </p>

        <p>
          Плательщик:{' '}
          <span>
            {payerType?.translations?.length &&
              payerType?.translations?.find((item) => item.localeId === 1)?.name}
          </span>
        </p>
      </div>

      {payerTypeId === 1 ? (
        <ModeratorContractIdInfoEntrants statement={statement} />
      ) : payerTypeId === 2 ? (
        isEditModerator ? (
          <ModeratorEditContractForm
            payerTypeId={payerTypeId}
            competitiveGroupStatementId={competitiveGroupStatementId}
            contractNotEntrants={contractIndividual}
            passportIds={passportKeys}
          />
        ) : (
          <ModeratorContractIdInfoNotEntrants
            contractNotEntrants={contractIndividual}
          />
        )
      ) : payerTypeId === 3 ? (
        isEditModerator ? (
          <ModeratorEditContractJuridicalForm
            payerTypeId={payerTypeId}
            competitiveGroupStatementId={competitiveGroupStatementId}
            contractJuridical={contractJuridical}
          />
        ) : (
          <ModeratorContractIdInfoJuridical contractJuridical={contractJuridical} />
        )
      ) : null}

      <div className="moderator-contract-info__block">
        <h2>Загрузка договора</h2>

        <div className="moderaotr-contract-info__block_file-container">
          <FileUploader
            files={scanDocs || []}
            onChange={onChangeScan}
            onDrop={onDropScan}
            onDelete={onDeleteScan}
          />
          <div className="upload-contract__block-button">
            <Button
              theme={scanDocs.length ? 'success' : undefined}
              className="upload-contract__button"
              onClick={() =>
                uploadContractModerator({
                  contract_id: id,
                  documents: scanDocs,
                  typeDoc: 'scan_for_contract_moderator',
                  user_id: statement?.userId,
                })
              }
            >
              Загрузить договор
            </Button>

            <Button
              theme={scanDocs.length ? 'success' : undefined}
              className="upload-contract__button"
              onClick={() =>
                downloadGeneratedContract({
                  contract_id: id,
                })
              }
            >
              Скачать
            </Button>
          </div>
        </div>

        <h2>Печатные формы:</h2>

        <div className="moderator-contract-info__block_file-container">
          <ModeratorFile
            title={'Договор'}
            upload={() => {
              contractDoc.length
                ? downloadDocument(
                    'SCAN_CONTRACT',
                    'application/pdf',
                    'pdf',
                    contractDoc?.find(
                      (item) =>
                        item?.userDoc?.getTypeDocs?.name === 'scan_for_contract'
                    )?.userDocId
                  )
                : dispatch(
                    createToast('Нет документа с типом scan_for_contract', 'danger')
                  )
            }}
          />
        </div>
      </div>

      <div className="moderator-contract-info__block">
        <h2>Сканированные документы:</h2>

        <div className="moderator-contract-info__block_file-container">
          <ModeratorFile
            title={'Паспорт плательщика'}
            upload={() => {
              const passportDoc = contractDoc.find(
                (item) =>
                  item.userDoc.getTypeDocs.name === 'copy_passport_for_dogovor'
              )

              passportDoc &&
                getModeratorDoc(
                  passportDoc.userDoc.getTypeDocs.name,
                  passportDoc.userDocId
                ).then(({ data }) =>
                  base64ToPdf(
                    data.document,
                    passportDoc.userDoc.name.slice(0, -4)
                  ).then(downloadFile)
                )
            }}
          />
        </div>
      </div>

      {statusCode &&
        (statusCode === CONTRACT_SIGNED ||
          statusCode === CONTRACT_RECEIPT_SENT ||
          statusCode === CONTRACT_RECEIPT_RECEIVED) && (
          <div className="moderator-contract-info__block">
            <h2>Квитанция:</h2>
            <div className="moderaotr-contract-info__block_file-container">
              <FileUploader
                files={scanDocsReceipt || []}
                onChange={onChangeScanReceipt}
                onDrop={onDropScanReceipt}
                onDelete={onDeleteScanReceipt}
              />
              <div className="upload-contract__block-button">
                <Button
                  theme={scanDocsReceipt.length ? 'success' : undefined}
                  className="upload-contract__button"
                  onClick={() =>
                    uploadContractModerator({
                      contract_id: id,
                      documents: scanDocsReceipt,
                      typeDoc: 'receipt_payment_contract',
                      user_id: statement?.userId,
                    }).then((res) => res.payload.status && receiptSent())
                  }
                >
                  Загрузить квитанцию
                </Button>
                <Button
                  theme={scanDocsReceipt.length ? 'success' : undefined}
                  className="upload-contract__button"
                  onClick={() =>
                    downloadGeneratedReceived({
                      contract_id: id,
                    })
                  }
                >
                  Скачать
                </Button>
              </div>
            </div>
          </div>
        )}
      {statusCode && statusCode === CONTRACT_RECEIPT_RECEIVED && (
        <div className="moderator-contract-info__block">
          <h2>Платежка:</h2>

          <div className="moderator-contract-info__block_file-container">
            <ModeratorFile
              title={'Оплаченная квитанция'}
              upload={() => {
                const receiptDoc = contractDoc.find(
                  (item) => item.userDoc.getTypeDocs.name === 'receipt_paid_contract'
                )

                receiptDoc
                  ? getModeratorDoc(
                      receiptDoc.userDoc.getTypeDocs.name,
                      receiptDoc.userDocId
                    ).then(({ data }) =>
                      base64ToPdf(
                        data.document,
                        receiptDoc.userDoc.name.slice(0, -4)
                      ).then(downloadFile)
                    )
                  : dispatch(
                      createToast(
                        'Нет документа с типом receipt_paid_contract',
                        'danger'
                      )
                    )
              }}
            />
          </div>
        </div>
      )}

      <Controller
        name="moderatorId"
        control={control}
        render={({ field }) => (
          <ReactSelect<SelectOption>
            options={[]}
            placeholder="Модератор по доверенности"
            {...(field as unknown)}
            className="moderator-contract-info__select"
          />
        )}
      />

      <div className="moderator-contract-info__footer">
        <div className="moderator-contract-info__footer-btn">
          <NavLink to={`/admin/users/${statement?.userId}`} target="_blank">
            <Button type={'button'}>Зайти в личный кабинет пользователя</Button>
          </NavLink>

          <Button
            disabled={
              statement.trajectoryId === 1
                ? statusCode !== CONTRACT_WORK &&
                  statusCode !== CONTRACT_EDITED &&
                  statusCode !== CONTRACT_APPROVED
                : statusCode !== CONTRACT_APPROVED &&
                  statusCode !== CONTRACT_SIGNED &&
                  statusCode !== CONTRACT_SIGNED_APPLICANT &&
                  statusCode !== CONTRACT_RECEIPT_SENT &&
                  statusCode !== CONTRACT_WORK &&
                  statusCode !== CONTRACT_EDITED
            }
            onClick={sendTo1C}
          >
            Отправить в 1С
          </Button>

          <Button onClick={backToWorkContract}>Вернуть в работу</Button>

          {/*<Button onClick={rejectContract}>Отклонить1</Button>*/}
        </div>

        {(status?.id === CONTRACT_WORK || status?.id === CONTRACT_EDITED) && (
          <div className="moderator-contract-info__footer-btn">
            {status?.id === CONTRACT_EDITED && (
              <Button
                onClick={() => reject(id)}
                className="moderator-contract-info__footer-btn-reject"
              >
                Удалить
              </Button>
            )}
            <Button onClick={() => denied()}>Отклонить</Button>
            <Button onClick={() => send()}>Отправить на редактирование</Button>
            {status?.id !== CONTRACT_SIGNED_APPLICANT && (
              <Button onClick={() => approve()} theme={'success'}>
                Одобрить
              </Button>
            )}
          </div>
        )}

        {Number(status?.code) === CONTRACT_SIGNED_APPLICANT && (
          <Button onClick={() => signed()} theme={'success'}>
            Одобрить
          </Button>
        )}

        {status?.id === CONTRACT_SIGNED && (
          <div className="moderator-contract-info__footer-btn">
            <Button onClick={() => denied()}>Отклонить</Button>
          </div>
        )}

        {status?.id === CONTRACT_EDITING && (
          <div className="moderator-contract-info__footer-btn">
            <Button
              onClick={() => reject(id)}
              className="moderator-contract-info__footer-btn-reject"
            >
              Удалить
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ModeratorContractIdInfo
