import { Locale } from 'types/custom-types'

import en from './terms.en'
import ru from './terms.ru'

const terms = {
  ru,
  en,
}

const getLocale = () => {
  const locale = localStorage.getItem('locale') || 'ru'

  return locale as Locale
}

export const locale = getLocale()

export const changeLocale = (newLocale: Locale) => {
  localStorage.setItem('locale', newLocale)
  window.location.reload()
}

export default terms[locale] || terms.ru
