import { StateStatus } from '../types'
import {
  BlankStatement,
  EducationProfileModerator,
  LevelEducation,
  ModeratorAchievementsAndPrivilegesData,
  ModeratorAddressData,
  ModeratorContact,
  ModeratorDirectionsTrainings,
  ModeratorDocumentsData,
  ModeratorEducationData,
  ModeratorModeratorsList,
  ModeratorPassportData,
  ModeratorPersonalInformation,
  ModeratorStatement,
  ModeratorStatementPersonalDate,
  ModeratorStatementsStatus,
  ModeratorStatusContract,
  ModeratorTargetContractDataResponse,
  PersonalDataResponse,
  StatusStatement,
  ViewerScenarioStatements,
  ViewerTabEgeData,
  ViewerTrajectoryListStatements,
} from '@models'
import {
  getModeratorEducationProfile,
  getModeratorScenario,
  getModeratorStatementsList,
  getAllStatusStatement,
  getModeratorContractsList,
  getModeratorTrajectory,
  getModeratorStatementsIdStatus,
  getModeratorStatementsPersonalData,
  getModeratorStatementsIdComment,
  getModeratorStatementsPassportData,
  getModeratorDirectionsTrainings,
  getModeratorStatusContract,
  getModeratorStatementsAddressData,
  getModeratorStatementsEducationData,
  getModeratorStatementsDocumentsData,
  getModeratorStatementsAchievementsAndPrivilegesData,
  getModeratorBlankStatementsData,
  getModeratorModeratorsList,
  getModeratorStatusesList,
  getModeratorPersonalInformationList,
  getModeratorExamResultData,
  getModeratorUserPersonalData,
  getModeratorStatementsAdditionalFiles,
  getModeratorLevelEducation,
  getModeratorStatusStatement,
  getModeratorStatementsTargetContractData,
  getModeratorContractInfo,
} from '@redux/action-types'
import { ActionReducerMapBuilder, AsyncThunk, createSlice } from '@reduxjs/toolkit'

type ModeratorStateEntity<D> = {
  data: any
  status: StateStatus
  error: unknown
}

type ModeratorInitialState = {
  entities: {
    statementsList: ModeratorStateEntity<ModeratorStatement>
    contractsList: ModeratorStateEntity<ModeratorContact>
    personalInformationList: ModeratorStateEntity<ModeratorPersonalInformation>
    personalData: ModeratorStateEntity<ModeratorStatementPersonalDate>
    supportPersonalData: ModeratorStateEntity<PersonalDataResponse>
    // TODO: написать типы запросов, когда появится свагер
    passportData: ModeratorStateEntity<ModeratorPassportData[]>
    addressData: ModeratorStateEntity<ModeratorAddressData>
    educationData: ModeratorStateEntity<ModeratorEducationData[]>
    documentsData: ModeratorStateEntity<ModeratorDocumentsData>
    achievementsAndPrivilegesData: ModeratorStateEntity<ModeratorAchievementsAndPrivilegesData>
    targetContractData: ModeratorStateEntity<ModeratorTargetContractDataResponse>
    blankStatementData: ModeratorStateEntity<BlankStatement>
    examResultData: ModeratorStateEntity<ViewerTabEgeData>
    statementsIdStatus: ModeratorStateEntity<ModeratorStatementsStatus>
    statementsIdComment: ModeratorStateEntity<{ text: string }>
    educationProfile: ModeratorStateEntity<EducationProfileModerator[]>
    moderatorsList: ModeratorStateEntity<ModeratorModeratorsList>
    statusesList: ModeratorStateEntity<any>
    statusStatement: ModeratorStateEntity<StatusStatement[]>
    statusStatementModerator: ModeratorStateEntity<StatusStatement[]>
    statusContract: ModeratorStateEntity<ModeratorStatusContract[]>
    additionalFiles: ModeratorStateEntity<any>
    scenario: ModeratorStateEntity<ViewerScenarioStatements[]>
    trajectory: ModeratorStateEntity<ViewerTrajectoryListStatements[]>
    levelEducation: ModeratorStateEntity<LevelEducation[]>
    directionTrainings: ModeratorStateEntity<ModeratorDirectionsTrainings>
    contractInfo: ModeratorStateEntity<any>
  }
  orderStatements: 'ASC' | 'DESC' | undefined
  pageStatements: number
  perPageStatements: number
  searchStudents: string | null
}

const initialStatementsEntityState = {
  data: null,
  status: 'idle',
  error: null,
} as const

const initialState: ModeratorInitialState = {
  entities: {
    statementsList: initialStatementsEntityState,
    personalInformationList: initialStatementsEntityState,
    personalData: initialStatementsEntityState,
    supportPersonalData: initialStatementsEntityState,
    passportData: initialStatementsEntityState,
    addressData: initialStatementsEntityState,
    educationData: initialStatementsEntityState,
    documentsData: initialStatementsEntityState,
    targetContractData: initialStatementsEntityState,
    achievementsAndPrivilegesData: initialStatementsEntityState,
    examResultData: initialStatementsEntityState,
    blankStatementData: initialStatementsEntityState,
    statementsIdStatus: initialStatementsEntityState,
    statementsIdComment: initialStatementsEntityState,
    contractsList: initialStatementsEntityState,
    educationProfile: initialStatementsEntityState,
    moderatorsList: initialStatementsEntityState,
    statusesList: initialStatementsEntityState,
    scenario: initialStatementsEntityState,
    trajectory: initialStatementsEntityState,
    levelEducation: initialStatementsEntityState,
    statusStatement: initialStatementsEntityState,
    statusStatementModerator: initialStatementsEntityState,
    directionTrainings: initialStatementsEntityState,
    statusContract: initialStatementsEntityState,
    additionalFiles: initialStatementsEntityState,
    contractInfo: initialStatementsEntityState,
  },
  orderStatements: undefined,
  pageStatements: 1,
  perPageStatements: 10,
  searchStudents: null,
}

export const moderatorSlice = createSlice({
  name: 'moderator',
  initialState,
  reducers: {
    clearStatementsIdStatus(state) {
      state.entities.statementsIdStatus = initialStatementsEntityState
    },
    clearStatementsPersonalData(state) {
      state.entities.personalData = initialStatementsEntityState
    },
    clearSupportPersonalData(state) {
      state.entities.supportPersonalData = initialStatementsEntityState
    },
    setModeratorStatementsListOrder(state, action) {
      state.orderStatements = action.payload
    },
    setModeratorStatementsPage(state, action) {
      state.pageStatements = action.payload
    },
    setModeratorStatementsPerPage(state, action) {
      state.perPageStatements = action.payload
    },
    setSearchStudents(state, action) {
      state.searchStudents = action.payload
    },
  },
  extraReducers(builder) {
    setupExtraReducer(builder, getModeratorStatementsList, 'statementsList')
    setupExtraReducer(
      builder,
      getModeratorPersonalInformationList,
      'personalInformationList'
    )
    setupExtraReducer(builder, getModeratorStatementsPersonalData, 'personalData')
    setupExtraReducer(builder, getModeratorUserPersonalData, 'supportPersonalData')
    setupExtraReducer(
      builder,
      getModeratorStatementsTargetContractData,
      'targetContractData'
    )
    setupExtraReducer(
      builder,
      getModeratorStatementsAdditionalFiles,
      'additionalFiles'
    )
    setupExtraReducer(builder, getModeratorStatementsPassportData, 'passportData')
    setupExtraReducer(builder, getModeratorStatementsAddressData, 'addressData')
    setupExtraReducer(builder, getModeratorStatementsEducationData, 'educationData')
    setupExtraReducer(builder, getModeratorStatementsDocumentsData, 'documentsData')
    setupExtraReducer(
      builder,
      getModeratorStatementsAchievementsAndPrivilegesData,
      'achievementsAndPrivilegesData'
    )
    setupExtraReducer(builder, getModeratorBlankStatementsData, 'blankStatementData')
    setupExtraReducer(builder, getModeratorExamResultData, 'examResultData')
    setupExtraReducer(builder, getModeratorStatementsIdStatus, 'statementsIdStatus')
    setupExtraReducer(
      builder,
      getModeratorStatementsIdComment,
      'statementsIdComment'
    )
    setupExtraReducer(builder, getModeratorContractsList, 'contractsList')
    setupExtraReducer(builder, getModeratorTrajectory, 'trajectory')
    setupExtraReducer(builder, getModeratorLevelEducation, 'levelEducation')
    setupExtraReducer(builder, getModeratorModeratorsList, 'moderatorsList')
    setupExtraReducer(builder, getModeratorStatusesList, 'statusesList')
    setupExtraReducer(builder, getModeratorEducationProfile, 'educationProfile')
    setupExtraReducer(builder, getModeratorScenario, 'scenario')
    setupExtraReducer(builder, getAllStatusStatement, 'statusStatement')
    setupExtraReducer(
      builder,
      getModeratorStatusStatement,
      'statusStatementModerator'
    )
    setupExtraReducer(builder, getModeratorContractInfo, 'contractInfo')
    setupExtraReducer(builder, getModeratorStatusContract, 'statusContract')
    setupExtraReducer(builder, getModeratorDirectionsTrainings, 'directionTrainings')
  },
})

function setupExtraReducer(
  builder: ActionReducerMapBuilder<ModeratorInitialState>,
  asyncThunk: AsyncThunk<any, any, {}>,
  key: keyof ModeratorInitialState['entities']
) {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state.entities[key].status = 'loading'
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      state.entities[key].status = 'succeeded'
      state.entities[key].data = action.payload
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      state.entities[key].status = 'failed'
      state.entities[key].error = action.payload
      state.entities[key].data = null
    })
}

export default moderatorSlice.reducer
