import React from 'react'

import DownloadIcon from '@assets/images/Download.svg'
import EditIcon from '@assets/images/Edit.svg'
import TrashIcon from '@assets/images/Trash.svg'
import { Olympiad } from '@models'
import { formatDateYYYYMMDD } from '@utils/date'

const headers = [
  'Название олимпиады',
  'Подтверждающий документ',
  'Серия и номер',
  'Дата выдачи',
]

type TableProps = {
  items: Olympiad[]
  onEdit: (achivement: Olympiad) => void
  onDelete: (docId: number) => void
  onDownload: (docId: number, filename: string) => void
  readOnly: boolean
}

const Table = ({ items, onDelete, onDownload, onEdit, readOnly }: TableProps) => {
  return (
    <table className="achivements-table">
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item, id) => {
          return (
            <tr key={id}>
              <td>{item.name}</td>
              <td>{item.olympiadDoc}</td>
              <td>{item.number}</td>
              <td>{item.dateIssue ? formatDateYYYYMMDD(item.dateIssue) : '---'}</td>
              <td>
                <div className="buttons">
                  <button
                    type="button"
                    onClick={() =>
                      onDownload(Number(item.userDocId), item.name || '')
                    }
                  >
                    <DownloadIcon />
                  </button>
                  <button
                    type="button"
                    disabled={readOnly}
                    onClick={() => onEdit(item)}
                  >
                    <EditIcon />
                  </button>
                  <button
                    type="button"
                    disabled={readOnly}
                    onClick={() => onDelete(Number(item.userOlympiadId))}
                  >
                    <TrashIcon />
                  </button>
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default Table
