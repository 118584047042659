import React from 'react'

import useAppSelector from '@hooks/useAppSelector'
import useLogListFilter from '@hooks/useLogListFilter'
import { selectAdminLogList } from '@redux/actions/selectors'
import Button from '@ui/Button'
import Input from '@ui/Input'

const LogFilter = () => {
  const { fields, reset, onSubmit } = useLogListFilter()

  const logList = useAppSelector(selectAdminLogList) || []

  return (
    <section>
      <form onSubmit={onSubmit} className={'moderator-statements-filter'}>
        <Input
          className={'filter-form__field'}
          {...fields.enrolleeId}
          placeholder="ID пользователя"
        />
        <Input
          className={'filter-form__field'}
          {...fields.moderatorId}
          placeholder="ID модератора"
        />
        <Input
          className={'filter-form__field'}
          type={'date'}
          {...fields.dateFrom}
          placeholder="Дата с"
        />
        <Input
          className={'filter-form__field'}
          type={'date'}
          {...fields.dateTo}
          placeholder="Дата по"
        />
        <footer>
          <h2>Количество заявлений: {logList?.total | 0}</h2>

          <div>
            <Button type={'submit'} onClick={() => reset()}>
              Сбросить фильтры
            </Button>
            <Button theme={'success'} type={'submit'}>
              Отфильтровать
            </Button>
          </div>
        </footer>
      </form>
    </section>
  )
}

export default LogFilter
