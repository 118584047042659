import usePaginatedEntity from '@hooks/usePaginatedEntity'
import { getPhoneCodes } from '@redux/action-types'
import {
  selectPaginatedPhoneCodes,
  selectPaginatedPhoneCodesLoading,
} from '@redux/actions/selectors'

const usePaginatedPhoneCodes = () => {
  return usePaginatedEntity({
    action: getPhoneCodes,
    dataSelector: selectPaginatedPhoneCodes,
    loaderSelector: selectPaginatedPhoneCodesLoading,
  })
}

export default usePaginatedPhoneCodes
