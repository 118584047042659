import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import { Attestat, sendUserSchoolSubjects } from '@models'
import { getUserSchoolSubjects, getEstimates } from '@redux/action-types'
import {
  selectUserEstimates,
  selectUserIdProfile,
  selectUserSchoolSubjects,
} from '@redux/actions/selectors'
import { getViewerProfile } from '@redux/reducers'
import api from '@services/api'

const useAttestat = () => {
  const dispatch = useAppDispatch()
  const userId = useAppSelector(selectUserIdProfile)

  const { getValues, register, handleSubmit, watch, setValue } = useForm<Attestat>({
    mode: 'all',
  })

  const subjects = useAppSelector(selectUserSchoolSubjects)
  const estimates = useAppSelector(selectUserEstimates)

  useEffect(() => {
    dispatch(getViewerProfile()).then((response) => {
      // @ts-ignore
      dispatch(getUserSchoolSubjects(response?.payload?.id))
      // @ts-ignore
      dispatch(getEstimates(response?.payload?.id)).then(() => {})
    })
  }, [])

  useEffect(() => {
    if (!!estimates) {
      setValue('UserEstimate.average', estimates?.average ?? null)
      setValue('UserEstimate.three', estimates?.three ?? null)
      setValue('UserEstimate.four', estimates?.four ?? null)
      setValue('UserEstimate.five', estimates?.five ?? null)
    }
  }, [estimates])

  useEffect(() => {
    if (!!subjects) {
      setValue('sendUserSchoolSubjects.data', subjects)
    }
  }, [subjects])

  const fields = {
    data: register('sendUserSchoolSubjects.data'),
    average: register('UserEstimate.average'),
    five: register('UserEstimate.five', {
      onChange: (event) => {
        if (event.target.value > 99) {
          setValue('UserEstimate.five', 99)
        }

        if (event.target.value < 0) {
          setValue('UserEstimate.five', 0)
        }

        let aver = (
          (event.target.value * 5 +
            (getValues('UserEstimate.four') ?? 0) * 4 +
            (getValues('UserEstimate.three') ?? 0) * 3) /
          (Number(event.target.value) +
            Number(getValues('UserEstimate.four') ?? 0) +
            Number(getValues('UserEstimate.three') ?? 0))
        ).toFixed(2)

        setValue('UserEstimate.average', aver)
      },
    }),
    four: register('UserEstimate.four', {
      onChange: (event) => {
        if (event.target.value > 99) {
          setValue('UserEstimate.four', 99)
        }

        if (event.target.value < 0) {
          setValue('UserEstimate.four', 0)
        }

        let aver = (
          (event.target.value * 4 +
            (getValues('UserEstimate.five') ?? 0) * 5 +
            (getValues('UserEstimate.three') ?? 0) * 3) /
          (Number(event.target.value) +
            Number(getValues('UserEstimate.five') ?? 0) +
            Number(getValues('UserEstimate.three') ?? 0))
        ).toFixed(2)

        setValue('UserEstimate.average', aver)
      },
    }),
    three: register('UserEstimate.three', {
      onChange: (event) => {
        if (event.target.value > 99) {
          setValue('UserEstimate.three', 99)
        }

        if (event.target.value < 0) {
          setValue('UserEstimate.three', 0)
        }

        let aver = (
          (event.target.value * 3 +
            (getValues('UserEstimate.five') ?? 0) * 5 +
            (getValues('UserEstimate.four') ?? 0) * 4) /
          (Number(event.target.value) +
            Number(getValues('UserEstimate.four') ?? 0) +
            Number(getValues('UserEstimate.five') ?? 0))
        ).toFixed(2)

        setValue('UserEstimate.average', aver)
      },
    }),
  }

  const onSubmit = handleSubmit((data: any) => {
    if (!userId) {
      return
    }

    const estimateNormalizedData: sendUserSchoolSubjects = {
      ...data.UserEstimate,
    }

    const subjectsNormalizedData: sendUserSchoolSubjects = {
      ...data.sendUserSchoolSubjects.data,
    }

    Promise.all([
      api.client.editUserSchoolSubjects({ data: subjectsNormalizedData }),
      api.client.editEstimates(estimateNormalizedData),
    ]).then(() => {
      dispatch(getUserSchoolSubjects(userId))
      dispatch(getEstimates(userId))
    })
  })

  return {
    fields,
    getValues,
    watch,
    register,
    setValue,
    onSubmit,
  }
}

export default useAttestat
