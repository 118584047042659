import axios from 'axios'

const key = process.env.REACT_APP_ISSUING_AUTORHORITY_API_KEY_DADATA || 'none'
export default {
  getIssuingAuthority: (divisionCode: string) => {
    const config = {
      headers: {
        authorization: 'Token ' + key,
      },
      params: {
        query: divisionCode,
      },
    }

    return axios.get(
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit',
      config
    )
  },
}
