import { useEffect } from 'react'
import { UseFormSetValue } from 'react-hook-form'

import useAppSelector from './useAppSelector'
import useInitializeSelect from './useInitializeSelect'
import useAppDispatch from '@hooks/useAppDispatch'
import {
  PreemptiveRightCreate,
  PreemptiveRightResponse,
  SelectCodeOption,
} from '@models'
import {
  modalDataSelector,
  selectPreemptiveDocTypeOptions,
  selectPreemptiveTypeOptions,
} from '@redux/actions/selectors'
import { getPreemptiveRightsType } from '@redux/reducers'

type useAddPrivilegesFormSelectorsProps = {
  setValue: UseFormSetValue<PreemptiveRightCreate>
}

const useAddPreemptiveRightForminitialization = ({
  setValue,
}: useAddPrivilegesFormSelectorsProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    !preemptiveType.length && dispatch(getPreemptiveRightsType())
  })

  const preemptiveType: SelectCodeOption[] = useAppSelector(
    selectPreemptiveTypeOptions
  )
  const preemptiveDocType: SelectCodeOption[] = useAppSelector(
    selectPreemptiveDocTypeOptions
  )
  const defaultData: PreemptiveRightResponse = useAppSelector(modalDataSelector)
  useEffect(() => {
    if (defaultData) {
      setValue('number', defaultData.number)
      setValue('dateIssue', defaultData.dateIssue)
      setValue('docId', defaultData.docId)
    }
  }, [defaultData])

  useInitializeSelect({
    data: preemptiveType,
    options: preemptiveType,
    findValue: Number(defaultData?.preemptiveRight.id),
    onInit: (option) => setValue('preemptiveRightId', option as any),
  })
  useInitializeSelect({
    data: preemptiveDocType,
    options: preemptiveDocType,
    findValue: Number(defaultData?.preemptiveRightDoc.id),
    onInit: (option) => setValue('preemptiveRightDocId', option as any),
  })

  return {
    preemptiveType,
    preemptiveDocType,
  }
}

export default useAddPreemptiveRightForminitialization
