import React, { useEffect, useRef } from 'react'
import ReactCrop, { Crop } from 'react-image-crop'

import FlipIcon from '@assets/images/FlipIcon.svg'
import signImagePlaceholder from '@assets/images/sign-image-placeholder.png'
import TrashIcon from '@assets/images/Trash.svg'
import terms from '@common/terms'
import useAppSelector from '@hooks/useAppSelector'
import TemplateModal from '@modals/TemplateModal'
import { modalDataSelector } from '@redux/actions/selectors'
import Button from '@ui/Button'
import useViewerAuthImage from '@hooks/useViewerAuthImage'

const EditAuthImageModal = () => {
  const { crop, image, onSave, onLoad, onClear, setCrop, onUpload, onRotate } =
    useViewerAuthImage()
  const uploadButtonRef = useRef<HTMLInputElement>(null)

  const modalData = useAppSelector(modalDataSelector) as {
    mode: 'upload' | 'edit' | 'delete'
    data
  }

  useEffect(() => {
    if (modalData.mode === 'upload') {
      uploadButtonRef.current?.click()
    } else if (modalData.mode === 'delete') {
      onClear()
    }
  }, [modalData])

  return (
    <TemplateModal title={terms.SIGN_IMAGE}>
      <div className="edit-profile-img-modal">
        <div className="edit-profile-img-modal__cropper">
          <ReactCrop
            className="edit-profile-img-modal__area"
            src={image || signImagePlaceholder}
            crop={crop}
            disabled={!image}
            onChange={setCrop}
            onImageLoaded={onLoad}
            imageStyle={{ objectFit: 'contain', maxHeight: '500px' }}
          />
        </div>
        <div className="edit-profile-img-modal__controls">
          <Button
            className="edit-profile-img-modal__control"
            onClick={() => onRotate('left')}
            disabled={!image}
            type="button"
          >
            <FlipIcon />
          </Button>
          <Button
            className="edit-profile-img-modal__control"
            onClick={() => onRotate('right')}
            disabled={!image}
            type="button"
          >
            <FlipIcon style={{ transform: 'scale(-1, 1)' }} />
          </Button>
          <Button
            className="edit-profile-img-modal__control"
            onClick={onClear}
            disabled={!image}
            type="button"
          >
            <TrashIcon />
          </Button>
        </div>
        <div>
          <p>{terms.IMAGE_REQUIREMENTS_TEXT}</p>

          <p>{terms.IMAGE_REQUIREMENTS}</p>

          <p>{terms.PHOTO_REQUIREMENTS_SIZE}</p>
          <p>{terms.SITES_IMAGE_REQUIREMENTS_EXTENSION}</p>
          <p>
            {terms.PHOTO_REQUIREMENTS_CARD.length > 0 && (
              <p>{terms.PHOTO_REQUIREMENTS_CARD}</p>
            )}
          </p>
        </div>
        <footer className="edit-profile-img-modal__footer">
          <label className="button edit-profile-img-modal__button">
            <input
              type="file"
              className="edit-profile-img-modal__file-input"
              onChange={onUpload}
              ref={uploadButtonRef}
            />
            {terms.SELECT_ANOTHER_IMAGE}
          </label>
          <Button
            theme="success"
            className="edit-profile-img-modal__button"
            onClick={onSave}
            type="button"
          >
            {terms.SAVE}
          </Button>
        </footer>
      </div>
    </TemplateModal>
  )
}

export default EditAuthImageModal
