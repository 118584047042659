import { RootState } from '../../types'
import { EN_LOCALE_ID, ROLE_USER, RU_LOCALE_ID } from '@common/constants'
import { locale } from '@common/terms'

export const selectToken = (state: RootState) => state.viewer.token

export const selectLocalizedViewerProfile = (state: RootState) => {
  const localeId = locale === 'ru' ? RU_LOCALE_ID : EN_LOCALE_ID
  const data = state.viewer.profileData
  if (!data) return null
  const translate =
    data.usersTranslate.length === 1
      ? data.usersTranslate[0]
      : data.usersTranslate.find((t) => t.localeId === localeId)

  return {
    ...data,
    usersTranslate: translate,
    photo: data.photo ? `data:image/jpeg;base64,${data.photo}` : null,
  }
}

export const selectRoleIdProfile = (state: RootState) => {
  const data = state.viewer.profileData
  if (!data) return null
  return data.roleId || ROLE_USER
}

export const selectIsQuestionnaireBlockProfile = (state: RootState) => {
  const data = state.viewer.profileData
  if (!data) return undefined
  return data.isQuestionnaireBlock || false
}

export const selectUserIdProfile = (state: RootState) => {
  const data = state.viewer.profileData
  if (!data) return undefined
  return data.id
}

export const selectProfileLoading = (state: RootState) =>
  state.viewer.status === 'loading'

export const selectViewerProfileImage = (state: RootState) => {
  const image = state.viewer.profileImageBase64
  return image ? `data:image/jpeg;base64,${image}` : null
}
