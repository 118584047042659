import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'

import { statementsTabRotes } from '@common/routes'
import ChatWidget from '@components/ChatWidget'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import ProgresstabPage from '@pages/ProgressTabPage'
import {
  setLevelValue,
  setTrajectoryValue,
  getStatementsElement,
} from '@redux/action-types'
import { selectStatementsElement } from '@redux/actions/selectors'
import { isNumeric } from '@utils/common'

const StatementIdPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { statementId } = useParams<{ statementId?: string }>()

  useEffect(() => {
    if (!isNumeric(statementId)) {
      navigate('/admission')
    } else {
      dispatch(getStatementsElement(Number(statementId)))
    }
  }, [statementId])

  const statementEl = useAppSelector(selectStatementsElement)

  useEffect(() => {
    if (statementEl) {
      dispatch(
        setTrajectoryValue({
          id: statementEl.trajectory?.id | 0,
          value: statementEl.trajectory?.translate[0]?.name || '',
        })
      )
      dispatch(
        setLevelValue({
          id: statementEl.scenario?.id | 0,
          value: statementEl.scenario?.scenarioTranslate[0]?.name || '',
        })
      )
    }
  }, [statementEl])

  return (
    <ProgresstabPage
      activePage={statementsTabRotes.find((l) => location.pathname.includes(l.to))}
    >
      <Outlet />
      {window.location.pathname.split('/')[2] !== 'users' && statementEl && (
        <ChatWidget user id={'1'} />
      )}
    </ProgresstabPage>
  )
}

export default StatementIdPage
