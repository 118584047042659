import { useForm } from 'react-hook-form'

import { AppModal } from 'types/custom-types'

import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import useDownloadDocument from './useDownloadDocument'
import { mimeTypes } from '@common/manual'
import terms from '@common/terms'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import { LgotaSpecialConditions } from '@models'
import { createToast, showModal } from '@redux/action-types'
import {
  getAllLgota,
  getAllPreemptive,
  selectStatementsElement,
  selectUserIdProfile,
  selectUploadedLgotaDocsState,
} from '@redux/actions/selectors'
import { getUserLgota, lgotaSlice } from '@redux/reducers'
import api, { ResponseOk } from '@services/api'
import uploadDocsTypes from '@common/uploadDocsTypes'
import { base64toFile, downloadFile } from '@utils/common'

type DocType = 'PREEMPTIVE' | 'PRIVILEGES'

const useHandlerLgota = (setListLgotaReason, setListLgotaSpecial) => {
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    getValues,
    setValue,
    reset,
    ...rest
  } = useForm<LgotaSpecialConditions>()

  const dispatch = useAppDispatch()

  const statement = useAppSelector(selectStatementsElement)
  const userId = useAppSelector(selectUserIdProfile)
  const lgotaDocs = useAppSelector(getAllLgota)
  const preemptiveRightDocs = useAppSelector(getAllPreemptive)
  const { downloadDocument } = useDownloadDocument()

  const {
    addUploadedLgotaDocsState,
    deleteUploadedLgotaDocsState,
    clearUploadedDocsState,
  } = lgotaSlice.actions

  const getUploadedLgotaDocs = (typeDoc, docId) => api.client.getDocs(typeDoc, docId)

  const onDelete = (id: number) => {
    if (!(statement && userId)) {
      return
    }
    api.client
      .userLgotaDelete({ docId: id, statementId: statement.id, userId: userId })
      .then((response) => {
        const { data } = response as unknown as ResponseOk
        dispatch(createToast(data.message, 'success'))
        dispatch(getUserLgota({ statementId: statement.id, userId: userId }))
      })
      .catch((response: ResponseOk) => {
        const { data } = response
        dispatch(createToast(data.message, 'danger'))
      })
  }

  const addLgota = (name: AppModal) => {
    dispatch(showModal({ name }))
  }

  const onEdit = (type: DocType, id: string) => {
    const dataForEdit =
      type === 'PRIVILEGES'
        ? lgotaDocs.find((el) => el.id === id)
        : preemptiveRightDocs.find((el) => el.id === id)

    dispatch(showModal({ name: type, data: dataForEdit }))
  }

  const onDownload = (docId: number) => {
    downloadDocument('LGOTA', mimeTypes.pdf, 'pdf', docId, '')
  }

  const haveSpecialConditions = watch('haveSpecialConditions')
  const haveReferenceMse = watch('haveReferenceMse')

  const onUploadDocsSpecialCondition = (statementId, docs, typeDoc) => {
    if (!docs) return
    const formData = new FormData()
    formData.append('typeDoc', typeDoc)
    formData.append('statement_id', statementId)
    for (const doc of docs) {
      formData.append('documents[]', doc)
    }
    api.client
      .postLgotaSpecialDocs(formData)
      .then((response) => {
        const { data } = response as unknown as ResponseOk
        dispatch(createToast(data.message, 'success'))
      })
      .catch((response: ResponseOk) => {
        const { data } = response
        dispatch(createToast(data.message, 'danger'))
      })
  }

  const onDeleteDocs = (typeDoc: string, docId: number, fileId: string) => {
    api.client
      .deleteDoc({ typeDoc: uploadDocsTypes[typeDoc], docId })
      .then(() => dispatch(createToast(terms.FILE_DELETED, 'success')))
      .catch(() => dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger')))
      .finally(() => {
        dispatch(deleteUploadedLgotaDocsState({ id: fileId }))
        dispatch(
          getUserLgota({
            statementId: Number(statement?.id),
            userId: Number(userId),
          })
        )
      })
  }
  const notHaveLgot = Boolean(watch('notHaveLgot'))
  const onDownloadDocs = (docId: number, typeDoc: string, filename?: string) => {
    api.client
      .getDocs(uploadDocsTypes[typeDoc], docId)
      .then((resp) =>
        base64toFile(resp.data.document || '', 'application/pdf', 'pdf', filename)
      )
      .then(downloadFile)
      .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')))
  }

  const fields = {
    haveSpecialConditions: register('haveSpecialConditions', {
      onChange: ({ target: { value } }) => {
        value && setValue('commentSpecialConditions', undefined)
        value && clearErrors('commentSpecialConditions')
        value && setListLgotaSpecial([])
      },
    }),
    commentSpecialConditions: register('commentSpecialConditions', {
      required: {
        value: haveSpecialConditions,
        message: terms.REQUIRED_FIELD,
      },
    }),
    fileSpecialCondition: register('fileSpecialCondition', {
      required: { value: haveSpecialConditions, message: terms.REQUIRED_FIELD },
    }),
    haveReferenceMse: register('haveReferenceMse'),
    fileReferenceMse: register('fileReferenceMse', {
      required: { value: haveReferenceMse, message: terms.REQUIRED_FIELD },
    }),
    notHaveLgot: register('notHaveLgot', {
      onChange: ({ target: { checked } }) => {
        if (checked) {
          reset()
          clearErrors()
          setValue('notHaveLgot', true)
          checked && setValue('commentSpecialConditions', undefined)
          checked && clearErrors('commentSpecialConditions')
          setValue('haveReferenceMse', false)
          setValue('haveSpecialConditions', false)
          setListLgotaSpecial([])
        }
      },
    }),
  }
  const { onNextPageNavigate } = useFillingStatementsRoutesContext()

  const onSubmit = handleSubmit((data: any) => {
    if (!(statement && userId)) {
      return
    }

    const normalizedData: LgotaSpecialConditions = {
      ...data,
      statementId: statement.id,
    }
    data.fileReferenceMse?.length &&
      onUploadDocsSpecialCondition(
        statement?.id,
        data.fileReferenceMse,
        'reference_MSE'
      )

    data.fileSpecialCondition?.length &&
      onUploadDocsSpecialCondition(
        statement?.id,
        data.fileSpecialCondition,
        'lgota_special_conditions'
      )

    api.client
      .postLgotaSpecialConditions(normalizedData)
      .then((response) => {
        const { data } = response as unknown as ResponseOk
        dispatch(createToast(data.message, 'success'))
        onNextPageNavigate()
      })
      .catch((response: ResponseOk) => {
        const { data } = response
        dispatch(createToast(data.message, 'danger'))
      })
  })
  return {
    setValue,
    fields,
    haveSpecialConditions,
    onSubmit,
    onDelete,
    onEdit,
    addLgota,
    onDownload,
    watch,
    haveReferenceMse,
    addUploadedLgotaDocsState,
    onDeleteDocs,
    onDownloadDocs,
    deleteUploadedLgotaDocsState,
    clearUploadedDocsState,
    onUploadDocsSpecialCondition,
    getUploadedLgotaDocs,
    notHaveLgot,
    ...rest,
  }
}

export default useHandlerLgota
