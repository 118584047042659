import { useState } from 'react'
import { useForm } from 'react-hook-form'

import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useProfileApiHandling from '@hooks/useProfileApiHandling'
import { dateLastValidDate, validIssueDate } from '@lib/validators'
import { Visa } from '@models'
import { getVisa, getVisaDoc } from '@redux/action-types'
import { selectLocalizedViewerProfile } from '@redux/actions/selectors'
import api from '@services/api'

const useVisaForm = () => {
  const {
    clearErrors,
    getValues,
    register,
    handleSubmit,
    setError,
    watch,
    setValue,
    ...rest
  } = useForm<Visa>({ mode: 'all' })

  const dispatch = useAppDispatch()
  const targetVisa = watch('noTargetVisa') === '0'
  const noTargetVisa = watch('noTargetVisa') === '1'
  const { callApi, loading } = useProfileApiHandling({
    setError,
    apiFn: api.client.updateVisa,
    onSuccessCb: () => dispatch(getVisa()),
  })
  const profile = useAppSelector(selectLocalizedViewerProfile)
  const getDoc = () => {
    dispatch(getVisaDoc()).then((resp: any) => {
      const href = URL.createObjectURL(resp.payload)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute(
        'download',
        'Анкета для приглашения, ' +
          profile?.usersTranslate?.surname +
          ' ' +
          profile?.usersTranslate?.name[0] +
          '. ' +
          (profile?.usersTranslate?.patronymic?.[0]
            ? profile?.usersTranslate?.patronymic?.[0] + '. '
            : '') +
          '.pdf'
      )
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }

  const fields = {
    countryVisaId: register('countryVisaId', {
      required: { value: noTargetVisa, message: terms.REQUIRED_FIELD },
      onChange: () => setValue('cityVisaId', null),
    }),
    cityVisaId: register('cityVisaId', {
      required: { value: noTargetVisa, message: terms.REQUIRED_FIELD },
    }),
    // studyVisa: register('studyVisa', { required: terms.REQUIRED_FIELD }),
    estimatedDate: register('estimatedDate', {
      required: { value: noTargetVisa, message: terms.REQUIRED_FIELD },
      validate: (value) => {
        return noTargetVisa
          ? dateLastValidDate(value || null, '2024-08-20')
            ? true
            : terms.INVALID_VISA_DATE
          : true
      },
    }),
    qa: register('qa'),
    illCovid: register('qa.illCovid', {
      // required: { value: noTargetVisa, message: terms.REQUIRED_FIELD },
    }),
    vaccinatedCovid: register('qa.vaccinatedCovid', {
      // required: { value: noTargetVisa, message: terms.REQUIRED_FIELD },
    }),
    goingVaccinatedCovid: register('qa.goingVaccinatedCovid', {
      // required: { value: noTargetVisa, message: terms.REQUIRED_FIELD },
    }),
    phd: register('phd'),
    assistantship: register('assistantship'),
    noTargetVisa: register('noTargetVisa', {
      onChange: (event) => {
        const target = event.target
        if (target.value === '1') {
          setValue('noTargetVisa', '1')
        } else {
          setValue('noTargetVisa', '0')
        }
      },
    }),
  }

  const onSubmit = handleSubmit((data: any) => {
    clearErrors('estimatedDate')
    if (
      noTargetVisa &&
      !dateLastValidDate(getValues('estimatedDate') || null, '2023-08-20')
    ) {
      setError(
        'estimatedDate',
        { type: 'validate', message: terms.INVALID_VISA_DATE },
        { shouldFocus: true }
      )
      return
    }

    const normalizedData: Visa = {
      ...data,
      qa: JSON.stringify({
        illCovid: data.qa.illCovid ?? null,
        vaccinatedCovid: data.qa.vaccinatedCovid ?? null,
        goingVaccinatedCovid: data.qa.goingVaccinatedCovid ?? null,
      }),
      countryVisaId: noTargetVisa ? data.countryVisaId.value : '',
      cityVisaId: noTargetVisa ? data.cityVisaId.value : '',
      phd: data.phd,
      assistantship: data.assistantship,
    }

    callApi(normalizedData)
  })

  return {
    fields,
    watch,
    loading,
    setValue,
    getDoc,
    onSubmit,
    getValues,
    setError,
    clearErrors,
    noTargetVisa,
    targetVisa,
    ...rest,
  }
}

export default useVisaForm
