import React from 'react'

import terms from '@common/terms'

const ru = {
  // Электронная приемная коммиссия
  SPBPU_ONLINE_APPLICATION_SYSTEM: 'Электронная приемная комиссия СПбГУ',
  SPBPU_ONLINE_APPLICATION_SYSTEM_ASP: 'Электронная приемная комиссия аспирантура',
  SPBPU_ONLINE_APPLICATION_SYSTEM_INO: 'Электронная приемная комиссия иностранных',
  REVIEW_INFORMATION_ABOUT_ADMISSION: 'Для поступления в университет внимательно ознакомьтесь с материалами на сайте приёмной комиссии, а затем',
  COMPLETE_REGISTRATION_PROCEDURE: 'пройдите процедуру регистрации',
  USER_MANUAL_FOR_RUSSIAN_APPLICANTS: 'Инструкция по заполнению Личного кабинета абитуриента для граждан РФ и соотечественников',
  USER_MANUAL_FOR_FOREIGNERS: 'Инструкция по заполнению Личного кабинета абитуриента для иностранных граждан',
  USER_MANUAL_FOR_APPLICANTS: 'Информация для поступающих',
  USER_MANUAL: 'Руководство пользователя',
  USER_VIDEO_INSTRUCTION_RUSSIAN: 'Видеоинструкция по работе с личным кабинетом на русском языке',
  USER_VIDEO_INSTRUCTION_CHINA: 'Видеоинструкция по работе с личным кабинетом на китайском языке',
  IF_ALREADY_HAVE_ACCOUNT: 'Если Вы уже зарегистрированы в системе, то используйте Ваш зарегистрированный адрес электронной почты и пароль для входа в Личный кабинет',
  IN_TO_CABINET: 'ВХОД В ЛИЧНЫЙ КАБИНЕТ АБИТУРИЕНТА',
  EMAIL: 'Email',
  PASSWORD: 'Пароль',
  FORGOT_PASSWORD: 'Забыли пароль?',
  REGISTER: 'Зарегистрироваться',
  CAPTCHA: 'Введите символы с картинки',
  LOG_IN: 'Войти',

  // Регистрация
  REGISTRATION: 'Регистрация',
  BASIC_DATA: 'Основные данные',
  SURNAME_IN_RUSSIAN: 'Фамилия',
  CYRILLIC_CHARACTERS_ONLY: 'Только кириллицей и с заглавной буквы',
  LATIN_CYRILLIC_CHARACTERS_AND_NUMBER_ONLY: 'Только кириллицей, латиницей и цифрами',
  SURNAME_IN_LATIN: 'Фамилия латинскими буквами',
  LATIN_CHARACTERS_ONLY: 'Только латинскими буквами',
  NAME_IN_RUSSIAN: 'Имя',
  NAME_IN_LATIN: 'Имя латинскими буквами',
  PATRONYMC_IN_LATIN: 'Отчество (если есть) латинскими буквами',
  PATRONYMIC_IN_RUSSIAN: 'Отчество (если есть)',
  DATE_OF_BIRTH: 'Дата рождения',
  JOB: 'Работа',
  CHILDREN_AMOUNT: 'Количество детей',
  CITIZENSHIP: 'Гражданство',
  AUTHORIZATION: 'Данные для авторизации',
  CREATE_PASSWORD: 'Придумайте пароль',
  PASS_HINT: 'Пароль должен содержать не менее 6 знаков, включать строчные и заглавные латинские буквы, цифры и специальные символы',
  CONSENT_TO_DATA_PROCESSING: 'Нажимая кнопку "Зарегистрироваться", Вы даёте свое согласие на обработку введенной персональной информации в соответствии с Федеральным Законом №152-ФЗ от 27.07.2006 "О персональных данных"',
  BACK: 'Назад',
  EMAIL_CONFIRMED: 'Email подтвержден',
  ACCOUNT_CREATED_AND_CONFIRMED: 'Аккаунт успешно создан и подтвержден. Теперь Вы можете войти в личный кабинет абитуриента',
  PASSWORD_RESET: 'Сброс пароля',
  ENTER_YOUR_EMAIL_FOR_PASSWORD_RESET: 'Введите свой email, с которым вы регистрировались ранее, и мы вышлем Вам инструкции для сброса пароля',
  RESET: 'Сбросить',
  IN_RUSSIA: 'Нахожусь в России',
  YOUR_ID_IN_SYSTEM: 'Ваш id в системе',
  CONTACT_TECHNICAL_SUPPORT: 'Обратиться в техническую поддержку',
  NO_NATIONALITY: 'Нет Гражданства',

  // Анкета
  PERSONAL_INFORMATION: 'Анкета',
  APPLICATION: 'Заявление на поступление',
  ADDITIONAL_FILES: 'Дополнительные файлы',
  LIST_OF_APPLICANTS: 'Списки поступающих',
  BASIC_DATA_HINT: 'Заполните в соответствии с паспортом или другим документом удостоверяющим личность',
  COMPATRIOT_DOCUMENTS: 'Документы, подтверждающие статус соотечественника',
  COMPATRIOT_DOCUMENTS_HINT: 'Заполните заявление о самоидентификации и загрузите подтверждающие документы',
  INFORMATION_ABOUT_PARENTS: 'Законный представитель',
  INFORMATION_ABOUT_PARENTS_HINT: 'Заполните данные Вашего родителя или опекуна',
  PASSPORT_DATA: 'Паспортные данные',
  PASSPORT_DATA_HINT: 'Заполните в соответствии с паспортом',
  VISA: 'Виза',
  VISA_HINT: 'Если Вам необходима виза, Ваш паспорт должен быть действителен не менее 18 месяцев',
  ADDITIONAL_INFORMATION: 'Дополнительные сведения',
  PERMANENT_ADDRESS_AND_CURRENT_HOME_ADDRESS: 'Адрес регистрации (по паспорту) и адрес проживания в настоящий момент',
  PERMANENT_ADDRESS_AND_CURRENT_HOME_ADDRESS_FOREIGN: 'Адрес проживания в стране гражданства',
  PERMANENT_ADDRESS_AND_CURRENT_HOME_ADDRESS_HINT: 'Укажите адрес регистрации по паспорту и адрес проживания в настоящий момент',
  EDUCATIONIONAL_BACKGROUND: 'Сведения об образовании',
  EDUCATIONIONAL_BACKGROUND_HINT: 'Заполните в соответствии с информацией в дипломе',
  SAVE_AND_CONTINUE: 'Сохранить и продолжить',
  DIPLOMA_WITH_HONOR: 'Aттестат/диплом с отличием',
  CREATED: 'Создан',
  INVALID_VISA_DATE: 'Дата не ранее 20.08.2024',

  // Раздел "Основные данные"
  PHOTO: 'Фото',
  CHANGE_PASSWORD: 'Изменить пароль',
  SURNAME: 'Фамилия',
  NAME: 'Имя',
  PATRONYMIC: 'Отчество',
  NO_PATRONYMIC: 'Нет отчества',
  GENDER: 'Пол',
  MOBILE_PHONE: 'Мобильный телефон',
  ADDITIONAL_MOBILE_PHONE_POSTSCRIPT: 'Дополнительный телефон (при наличии)',
  ADDITIONAL_MOBILE_PHONE: 'Дополнительный телефон',
  ADDITIONAL_EMAIL_IF_ANY: 'Дополнительный email (при наличии)',
  RECRUITING_COMPANY: 'Партнерская организация',
  PARTNER_ORGANIZATION_EMAIL: 'Email партнерской организации',
  PARTNER_ORGANIZATION_PHONE: 'Телефон партнерской организации',
  SNILS: 'СНИЛС',
  NO_SNILS: 'Нет СНИЛС',
  NO_DATE_SNILS: 'СНИЛС без даты',
  DATE_OF_ISSUE_SNILS: 'Дата СНИЛС',
  COUNTRY_CODE: 'Код страны',

  // Окно "Изменить пароль"
  CURRENT_PASSWORD: 'Текущий пароль',
  NEW_PASSWORD: 'Новый пароль',
  REPEAT_NEW_PASSWORD: 'Повторите новый пароль',
  SAVE: 'Сохранить',

  // Обновление справочников
  EXCHANGE_LINKS_TITLE: 'Обновление отдельных справочников через 1С',
  EXCHANGE_PERSONAL_TITLE: 'Обновление справочников заполнения анкеты пользователя ',
  EXCHANGE_HUGE_DATA_TITLE: 'Обновление больших справочников ',
  EXCHANGE_GENERAL_LINKS_TITLE: 'Полное обновление',
  EXCHANGE_UPDATE_MODAL_TITLE: 'Выгрузка из 1С',
  EXCHANGE_UPDATE_YEAR: 'Выгружаемый год',
  EXCHANGE_DELETE_OLD: 'Стереть старые записи перед выгрузкой?',
  EXCHANGE_ACCEPT: 'Выгрузить',
  EXCHANGE_MESSAGE: 'Идет обмен данных с 1С',

  // Окно "Редактировать приветственное фото"
  // и вкладка редактирования страниц авторизации
  LOGIN_REGISTER_PAGES: 'Страницы авторизации',
  AUTH_SETTING_TITLE: 'Редактирование страницы входа',
  LOGIN_PAGE: 'Страница логина',
  LOGIN_PAGE_NAME: 'Название страницы в админке',
  LOGIN_PAGE_TITLE: 'Заголовок страницы логина',
  REGISTER_PAGE_TITLE: 'Заголовок страницы регистрации',
  SELECT_IMAGE: 'Выбрать картинку',
  SELECT_LOGO: 'Выбрать логотип',
  SELECT_ANOTHER_IMAGE: 'Выбрать другую картинку',
  SIGN_IMAGE: 'Приветственная картинка',
  LEFT_CELL_TITLE: 'Заголовок левой ячейки с текстом',
  LEFT_CELL_TEXT: 'Текст левой ячейки с текстом',
  TEXT_ON_IMAGE: 'Текст на картинке',
  LINK_ON_IMAGE: 'Текст ссылки на картинке',
  URL_LINK_ON_IMAGE: 'Ссылка на картинке /rus-sign-up',
  SITE_AUTH_LOGO: 'Логотип сайта',
  SITE_AUTH_LOGO_TEXT: 'Текст справа от логотипа',
  RIGHT_CELL_TITLE: 'Заголовок правой ячейки с текстом',
  RIGHT_CELL_TEXT: 'Текст правой ячейки с текстом',
  EDIT_SIGN_IMAGE: 'Редактировать картинку',
  SUCCESS_IMAGE_DOWNLOAD: 'Картинка успешно обновлена',
  IMAGE_REQUIREMENTS: 'Лучшее соотношение сторон 2 к 1',
  LOGO_REQUIREMENTS_RATIO: 'Лучшее соотношение сторон 1 к 1',

  // Окно "Редактировать фотографию"
  SELECT_PHOTO: 'Выбрать фотографию',
  SELECT_ANOTHER_PHOTO: 'Выбрать другую фотографию',
  PHOTO_REQUIREMENTS: 'Просим Вас следовать следующим рекомендациям к загружаемой фотографии:',
  IMAGE_REQUIREMENTS_TEXT: 'Просим Вас следовать следующим рекомендациям к загружаемой картинке:',
  LOGO_REQUIREMENTS: 'Просим Вас следовать следующим рекомендациям к загружаемому логотипу:',
  PHOTO_REQUIREMENTS_BG: 'светлый (белый или светло-серый) фон без рисунков',
  PHOTO_REQUIREMENTS_FACE: 'лицо должно занимать не менее 70% площади фотографии',
  PHOTO_REQUIREMENTS_WIDTH: 'ширина фотографии не менее 300 пикселей',
  PHOTO_REQUIREMENTS_HEIGHT: 'высота фотографии не менее 400 пикселей разрешение фотографии не менее',
  PHOTO_REQUIREMENTS_PX: '300 точек на дюйм',
  PHOTO_REQUIREMENTS_SIZE: 'размер файла не более 1 Мб',
  PHOTO_REQUIREMENTS_EXTENSION: 'допустимые расширения файлов: .jpg, .jpeg и .png',
  SITES_IMAGE_REQUIREMENTS_EXTENSION: 'допустимые расширения файлов: .jpg и .png',
  PHOTO_REQUIREMENTS_CARD: '',
  PROFILE_PHOTO_UPDATED: 'Фото профиля обновлено',
  AUTH_PHOTO_UPDATED: 'Приветственное фото на странице регистрации обновлено',
  EDIT_PHOTO: 'Редактировать фото',

  // Раздел "Документы, подтверждающие статус соотечественника"
  FILE_UPLOADER_TITLE: 'Нажмите на иконку или перетащите в эту область файлы для загрузки. \n' + 'Можно загрузить изображения JPG, JPEG (будут объединены в один\n' + 'PDF) или один PDF документ. Размер файла не более 5 Мб',
  FILE_UPLOADER_DESCRIPTION: 'Можно загрузить изображения JPG (будут объединены в один PDF) или один PDF документ',

  // Раздел "Законный представитель"
  RELATIONSHIP: 'Степень родства',
  PASSPORT_DATA_NOT_NECESSARY: 'Паспортные данные (не обязательно)',
  DOCUMENT_TYPE: 'Тип документа',
  SERIES: 'Серия',
  HINT_SERIES_FOREIGN: 'Если у Вас в паспорте серия не выделена, а номер паспорта состоит из букв и цифр, то в поле «серия паспорта» указывайте буквы, в поле «номер паспорта» цифры.',
  NUMBER: 'Номер',
  DIVISION_CODE: 'Код подразделения',
  DATE_OF_ISSUE: 'Когда выдан',
  ISSUING_AUTHORITY: 'Кем выдан',
  TRIAL_TYPE: 'Форма испытаний',
  PRIORITY_GROUP: 'Приоритет направления',
  ITEM_TRIAL: 'Предмет',
  PERMANENT_ADDRESS_NOT_NECESSARY: 'Место регистрации (не обязательно)',
  HOME_ADDRESS_NOT_NECESSARY: 'Домашний адрес (не обязательно)',
  COUNTRY: 'Страна',
  REGION: 'Регион',
  DISTRICT: 'Район',
  CITY: 'Населенный пункт',
  STREET: 'Улица',
  NO_STREET: 'Нет улицы',
  BUILDING: 'Дом',
  SITE: 'Корпус',
  Apartment: 'Квартира',
  ZIP_CODE: 'Индекс',
  PERMANENT_ADDRESS_AND_HOME_ADDRESS_THE_SAME: 'Место постоянного проживания и домашний адрес совпадают',
  CONSENT_OF_PERSONAL_DATA_PROCESSING: 'Согласие на обработку персональных данных от законного представителя',
  NAME_AUTHORITY_ISSUED_IDENTITY_DOCUMENT_MISSING: 'В списке отсутствует наименование органа, выдавшего документ, удостоверяющий личность.',

  // Раздел "Паспортные данные"
  TRANSLATION_OF_THE_IDENTITY_DOCUMENT: 'Указать строго в соответствии с переводом документа, удостоверяющего личность.',
  DATE_OF_EXPIRATION: 'Срок действия',
  COUNTRY_OF_BIRTH: 'Страна рождения',
  PLACE_OF_BIRTH: 'Место рождения',
  I_HAVE_DUAL_CITIZENSHIP: 'Имеется второе гражданство',
  DUAL_CITIZENSHIP: 'Страна второго гражданства',
  COPY_OF_PASSPORT: 'Копия паспорта',
  NO_RUSSIA_PASSPORT: 'Скан 2-3 страниц паспорта или свидетельства о предоставлении временного убежища на территории РФ',
  NOTARIZED_PASSPORT: 'Скан нотариально заверенного перевода паспорта (страница 2-3) (если в паспорте не содержится информация на русском языке)',
  I_AM_COMPATRIOT: 'Являюсь соотечественником',
  I_AM_COMPATRIOT_HINT: 'Заполняется только поступающими на бюджетные места наравне с гражданами Российской Федерации при наличии подтверждающих документов.',
  I_NEED_VISA: 'Нужна виза',
  I_NOT_NEED_VISA: 'Не нужна виза',
  I_NEED_VISA_YES: 'Да',
  I_NEED_VISA_NO: 'Нет',
  I_HAVE_VNZ: 'Имею вид на жительство в России',
  I_HAVE_VNZ_HINT: 'Заполняется только поступающими на бюджетные места наравне с гражданами Российской Федерации при наличии подтверждающих документов.',
  RECIEVED_MINOBR_QUOTA: 'Поступаю по квоте Минобрнауки России/являюсь победителем или призером олимпиады Open Doors',
  I_HAVE_VISA_OR_RVP: 'Имею российскую визу / РВП в России',
  DOCUMENT_NUMBER: 'Номер',
  VNZ_COPY: 'Копия вида на жительство',
  VNZ_DESCRIPTION: 'Необходимо загрузить страницу вида на жительство с основными данными и страницу вида на жительство с регистрацией',
  VNZ: 'Вид на жительство в России',
  REG_NUMBER_EDUCATION_IN_RUSSIA: 'Регистрационный номер на сайте education-in-russia.com',
  MSHE_CONFIRMATION_LETTER: 'Направление Минобрнауки РФ',
  PASSPORT_DATA_COPY_PASSPORT_HINT: 'Необходимо загрузить страницу паспорта с основными данными и страницу паспорта с регистрацией',
  QUOTA_MINOBR: 'Поступаю по квоте Минобрнауки России',
  REG_NUMBER_EDUINRU: 'Регистрационный номер',
  HINT_NUMBER_EDUINRU: 'на сайте Минобрнауки России russia-edu.minobrnauki.gov.ru',

  // Раздел "Виза"
  PLACE_OF_VISA_APPLICATION: 'Место получения визы',
  COUNTRY_OF_VISA_APPLICATION: 'Страна получения визы',
  CITY_OF_VISA_APPLICATION: 'Город получения визы',
  HAVE_BEEN_TO_RUSSIA_WITH_VISA: 'Приезжали ли Вы когда-нибудь в Россию по учебной визе?',
  VISA_TITLE: 'Если для въезда на территорию Российской Федерации с целью обучения Вам требуется виза, Вам необходимо получить приглашение от Университета. С этой целью, пожалуйста, поставьте галочку «Нужна виза» и заполните анкету на приглашение.',
  VISA_PLACE: 'Место получения визы',
  YES: 'Да',
  NO: 'Нет',
  COVID_QUESTIONS: 'Ответьте на вопросы',
  SICK_COVID: 'Болели COVID-19?',
  HAVE_VACCINATION: 'Делали прививку от COVID-19?',
  CAN_DO_VACCINATION_IN_RUSSIA: 'Собираетесь делать прививку от COVID-19 в Российской Федерации?',

  //Раздел Дополнительная информация

  HOW_DID_YOU_FIND_OUT_ABOUT_TRAINING_OPPORTUNITY: 'Как Вы узнали о возможности обучения в СПбГУ?',
  EVALUATE_LEVEL_RUSSIAN_LANGUAGE_PROFICIENCY: 'Оцените Ваш уровень владения русским языком',
  WHERE_YOU_STUDY_RUSSIAN: 'Где Вы изучали русский язык?',

  // Адрес регистрации (по паспорту)
  PERMANENT_ADDRESS: 'Адрес регистрации (по паспорту)',
  PERMANENT_PASSPORT_ADDRESS_AND_HOME_ADDRESS_THE_SAME: 'Адрес регистрации (по паспорту) и адрес проживания в настоящий момент совпадают',
  CHECK_ADDRESS: 'Проверка адреса',
  CURRENT_HOME_ADDRESS: 'Адрес проживания в настоящий момент ',
  I_NEED_PLACE_OF_DORMITORY: 'Необходимость в общежитии на время учебы',
  LINK_INFO: 'Информация о поселении в общежитие',
  NEED: 'Требуется',
  NO_NEED: 'Не требуется',
  NOTICE: 'Поселение в общежития Санкт-Петербургского государственного университета осуществляется на основании Положения о предоставлении мест в общежитиях Санкт-Петербургского государственного университета, утвержденного Приказом первого проректора по молодежной политике и организации приема СПбГУ от 17.08.2023 № 10793/1',
  NOTICE_LIST_1: 'Места в общежитиях на весь период освоения образовательной программы предоставляются всем обучающимся очной формы за счет бюджетных ассигнований федерального бюджета, не обеспеченным жилым помещением на территории Санкт-Петербурга. Обучающимся по основным образовательным программам на договорной (платной) основе обучения места в общежитиях могут предоставляться во время освоения ими образовательной программы при наличии вакантных мест после поселения обучающихся, осваивающих основные и дополнительные образовательные программы за счет ассигнований федерального бюджета. Сведения о поселении в общежития размещены на сайте Санкт-Петербургского государственного университета по адресам:',
  NOTICE_LIST_2: '',

  // Раздел "Сведения об образовании"
  COUNTRY_WHERE_YOU_STUDIED: 'Страна образовательного учреждения',
  MODE_OF_STUDY: 'Форма обучения',
  STUDIED_IN_RGPU_FOUNDATION_PROGRAM: 'Обучался на подготовительных курсах СПбГУ',
  GRADUATE_INSTITUTION: 'Являюсь выпускником психолого-педагогического класса',
  HAVE_NO_MATCH: 'Иное Направление/Специальность',
  EDUCATIONAL_INSTITUTION: 'Наименование образовательного учреждения',
  YEAR_OF_GRADUATION: 'Год окончания образовательного учреждения',
  STUDY_LEVEL: 'Вид образования',
  DOCUMENT_SERIES_AND_NUMBER: 'Серия (если есть) и номер документа',
  DIPLOMA_WITH_DISTINCTION: 'Аттестат / Диплом с отличием',
  GRADUATED_FROM_SPBPU: 'Окончил СПбГУ',
  STUDIED_IN_SPBPU_FOUNDATION_PROGRAM: 'Учился на подготовительном отделении в СПбПУ (для иностранных граждан)',
  EDUCATIONAL_DOCUMENT: 'Документ об образовании',
  YOU_NEED_DOWNLOAD_SCAN_CERTIFICATE_PAGE: 'Необходимо загрузить скан страницы аттестата / диплома и сканы всех страниц приложения к аттестату / диплому',
  YOU_NEED_DOWNLOAD_SCAN_CERTIFICATE_PAGE_INO: 'Скан оригинала документа об образовании, включая приложение (скан должен быть в цветном виде)',
  EDUCATIONAL_DOCUMENT_SECOND: 'Свидетельство о признании документа об образовании (нострификация)',
  SUPPORTING_DOCUMENT: 'Подтверждающий документ',
  FOREIGN_lANGUAGE_STUDY: 'Иностранный язык, изучавшийся в образовательном учреждении',
  SPECIALIZATION_OF_LAST_EDU: 'Направление/Специальность предыдущего образования',
  GPA: 'Средний балл',
  SCAN_TRANSLATION_DOC_EDY: ' Скан перевода документа об образовании',
  SCAN_TRANSLATION_DOC_EDY_INO: 'Скан нотариально заверенного перевода документа об образовании, либо скан перевода документа об образовании оформленный в установленном законодательством Российской Федерации  (включая приложение)',
  NOSTRIFICATION_EDU: 'Свидетельство о признании документа об образовании',
  OTHER_DOCUMENTS: 'Иные документы',

  // Дополнительные файлы
  ADD_FILE: 'Добавить файл',
  EDIT_FILE: 'Редактировать файл',
  EDIT: 'Редактировать',
  FILE: 'Файл',
  FILE_DELETED: 'Файл удалён',
  DESIGNATION: 'Наименование',
  AVAILABLE_FORMATS: 'Данный формат файла не поддерживается. Допустимы только pdf или jpeg',

  // Валидация
  SNILS_VALID: 'Снилс должен быть валидным полем',
  NOT_ALL_REQUIRED_FIELDS_FILLED: 'Не все обязательные поля заполнены корректно',
  REQUIRED_FIELD: 'Обязательно к заполнению',
  REQUIRED_FIELD_SERIAL_AND_NUMBER: 'Обязательно к заполнению. Если серия и номер отсутствуют, напишите "Нет"',
  REQUIRED_CHECK_ADDRESS: 'Необходимо выбрать один из вариантов в поле "Проверка адреса"',
  TWO_CHARACTERS_MIN: 'Не менее двух символов',
  ONLY_LOCALE_IS_ALLOWED: 'Только кириллицей и с заглавной буквы',
  DATE_RANGE: 'Дата рождения должна быть не менее 10 и не более 100 лет назад',
  VALID_EMAIL: 'Значением поля должен быть валидный email',
  VALID_MOBILE: 'Значением поля должен быть валидный номер',
  PASSWORD_MIXED: 'Пароль должен обязательно содержать большие и маленькие латинские буквы, цифры и специальные символы',
  PASSWORD_MIN_LENGTH: 'Минимальная длина пароля 6 символов',
  PHONE_LENGTH: 'Длина номера 10 символов',
  PASSPORT_SERIES_LENGTH: 'Длина серии 4 символа',
  PASSPORT_RUSSIAN_FOREIGN_SERIES_LENGTH: 'Длина серии 2 символа',
  DIVISION_CODE_LENGTH: 'Длина кода подразделения 7 символов',
  DIVISION_CODE_TYPE: 'Код подразделения имеет вид: 000-000',
  PASSPORT_NUMBER_LENGTH: 'Длина номера 6 символов',
  PASSPORT_RUSSIAN_FOREIGN_NUMBER_LENGTH: 'Длина номера 7 символов',
  DATE_DOCUMENT_ISSUE_INVALID: 'Дата выдачи не может быть больше текущей',
  DATE_BIRTHDAY_ISSUE_INVALID: 'Дата выдачи не может быть меньше даты рождения',
  NUMBER_IN_LIST_LENGTH: 'Длина номера в перечне 3 символа',
  ONLY_NUMBER: 'Только цифры',

  // FileUploader
  ONLY_ONE_PDF: 'Только один pdf файл',
  FORMAT: 'Формат',
  NOT_AVAILABLE: 'не поддерживается',
  ONLY_JPG: 'Только jpg и jpeg форматы',
  SIZE_BIGGER_5: 'Размер файлов превышает 5МБ',

  // Раздел Бланки документов
  BLANK_DOCUMENTS_LIST_TITLE: 'Чтобы отправить заявление в приемную комиссию, Вам необходимо:',
  BLANK_DOCUMENTS_LIST_ITEM_1: 'Отметить ВСЕ галочки внизу данной страницы.',
  BLANK_DOCUMENTS_LIST_ITEM_2: 'Скачать и ознакомиться с печатной формой заявления на прием документов.',
  BLANK_DOCUMENTS_LIST_ITEM_3: 'Подписать печатную форму заявления на прием документов, нажав на соответствующую кнопку. Согласие на обработку персональных данных сформировано автоматически при регистрации в личном кабинете абитуриента.',
  DONLOAD_FILES_CHECKBOX1: 'С информацией об ответственности за достоверность сведений, указанных в заявлении, и за подлинность документов, подаваемых при поступлении ознакомлен(а)',
  DONLOAD_FILES_CHECKBOX2: 'С Уставом СПбПУ, Лицензией на право осуществления образовательной деятельности, приложениями к ней, Свидетельством о государственной аккредитации и приложением к нему, Правилами приема СПбПУ, Правилами внутреннего распорядка обучающихся и информацией, регламентирующей организацию и осуществление образовательной деятельности ознакомлен(а)',
  DONLOAD_FILES_CHECKBOX3: 'Подтверждаю отсутствие диплома бакалавра, диплома специалиста, диплома магистра (действительно только при поступлении на обучение на бюджетное место)',
  DONLOAD_FILES_CHECKBOX4: 'Заявления о приеме поданы мной не более чем в пять вузов',
  DONLOAD_FILES_CHECKBOX5: 'Подтверждаю подачу заявления о приеме на основании особого права без вступительных испытаний только в СПбПУ (действительно только для лиц, имеющих право на прием без вступительных испытаний)',
  APPLICATION_DATE: 'Дата формирования',
  APPLICATION_DOWNLOAD: 'Скачать документ',
  HINT_APPLICATION_DOWNLOAD: 'Скачайте, проверьте данные и нажмите кнопку “Подписать”',

  //ошибки логина и регистрации
  INVALID_EMAIL_OR_PASSWORD: 'Неверно введен email или пароль',

  // Неотсортированное
  MASTER_PROGRAM: 'магистратуры',
  SPECIALIST_PROGRAM: 'специалитета',
  ERROR_HAS_OCCURED: 'К сожалению, произошла ошибка. Пожалуйста, попробуйте ещё раз',
  NOT_USE_CONTRACT: 'Подача документов на данную траекторию поступления прекращена',
  FULL_EXCHANGE_WAS_SENT: 'Полная выгрузка данных из 1С успешно инициирована.',
  TEMP_PASSWORD_SEND: 'Временный пароль отправлен на адрес',
  PASSWORDS_MUST_MATCH: 'Пароли должны совпадать',
  MARITAL_STATUS: 'Семейное положение',
  //NEXT: 'Вперед',
  NEXT: 'Сохранить и продолжить',
  REPRESENTATIVE_OVER_18: 'Должен быть старше 18 лет',
  SNILS_INVALID: 'СНИЛС введен некорректно',
  DATE_INVALID: 'Некорректная дата',

  CREATE_APPLICATION: 'Создать заявление',
  NEW_APPLICATION: 'Новое заявление на поступление',
  TRAJECTORY_FOR_ADMISSION: 'Траектория поступления',
  LEVEL_OF_TRAINING: 'Уровень образования',
  BUDGET_CONTRACT: 'Бюджет/Контракт',
  FREE_EDU: 'Бюджетная основа',
  PAYMENT_EDU: 'Платное обучение',
  FILLING_APPLICATION: 'Заявление',
  SAVE_AND_NEXT: 'Сохранить и продолжить',
  ADD_MORE: 'Добавить еще',
  DATA_NOT_RECEIVED: 'Данные не получены',
  RESET_FILTER: 'Сбросить фильтры',

  STATEMENT: 'Заявление',
  DATE_AND_TIME: 'Дата и время подачи',
  PAYMENT_TYPE_FORM: 'Форма оплаты',
  STATUS: 'Статус',
  CONCLUSION_BASE_DEPARTMENT: 'Заключение базовой кафедры',
  ACHIEVEMENTS: 'Достижения',
  PRIORITY_ENROLLMENT: 'Льготы',
  CONTRACT: 'Договор',
  CONTRACT_TEXT: 'Заполните раздел, если Вы планируете поступать на платное обучение',
  FORMS_OF_DOCUMENTS: 'Бланки документов',
  FORMS_OF_DOCUMENTS_TEXT: 'Ознакомьтесь с печатными формами документов',
  DIRECTIONS_TRAINING: 'Направления поступления',
  DIRECTIONS_TRAINING_PRIORITY: 'Изменение приоритетов',
  DIRECTIONS_TRAINING_PRIORITY_TEXT: 'Вы можете поменять приоритеты направлений поступления',
  ENTRANCE_EXAMS: 'Вступительные испытания',
  SELECT_EXAM_DATE: 'Укажите дату экзамена',
  PASSING_SCORE: 'Минимальный балл',
  EDUCATIONAL_PROGRAM: 'Образовательная программа',
  ENTRANCE_EXAMS_TEXT: 'Выберите дату и время для прохождения вступительного испытания',
  BASIC_EXAMS: 'Результаты ОГЭ',
  BASIC_EXAMS_TEXT: 'Заполните раздел, если Вы сдавали ОГЭ или Централизованное тестирование (для граждан Республики Беларусь)',
  UNIFRED_EXAMS: 'Результаты ЕГЭ/ЦЭ/ЦТ',
  UNIFRED_EXAMS_TEXT: 'Заполните раздел, если сдавали ЕГЭ или Централизованное Тестирование/Централизованный экзамен (для граждан Республики Беларусь)',
  NUMBER_IN_LIST: 'Номер в перечне',

  CHOOSE_DIRECTION: 'Выбрать направление',
  DIRECTION: 'Направление',
  FORM_PAYMENT_ID: 'Форма оплаты',
  FIND: 'найти',
  CONFIRM: 'Подтвердить выбор',
  CHOOSE: 'Выбрать',

  PAYMENT_TYPE: 'Тип платежа',
  PAYER_APPLICANT_THE_SAME: 'Плательщик по договору (заказчик) и абитуриент одно и то же лицо',
  PAYER_IS_NOT_APPLICANT: 'Плательщик по договору (заказчик) не является абитуриентом',
  PAYER_IS_ORGANIZATION: 'Плательщик по договору (заказчик) является юридической организацией',

  BANK_IDENTIFIERR_CODE: 'Длина БИК 9 цифр',
  CHECKING_ACCOUNT: 'Длина расчетного счета 20 цифр',
  CORRESPONDENT_ACCOUNT: 'Длина корреспондентского счета 20 цифр',

  POLYTECH_FULLNAME: 'ФГБОУ ВО «Санкт-Петербургский государственный университет»',
  DOWNLOAD_FILE_ERROR: 'Ошибка при скачивании файла',
  UPLOAD_CONTRACT_ERROR: `Загрузить договор можно только в статусах 'Одобрен' и 'Редактирование договора'`,

  MODERATOR_STATEMENTS: 'Заявления',
  MODERATOR_CONTRACTS: 'Договоры',
  MODERATOR_APPLICANTS: 'Списки поступающих',

  TITLE_BLANK_NULL: 'Вам необходимо заполнить все разделы. После этого вы сможете ознакомиться с документами и подписать их.',
  TITLE_BLANK_HEADER: 'Чтобы отправить заявление в приемную комиссию, Вам необходимо: ',
  TITLE_BLANK_EL1: '1.  Отметить ВСЕ галочки вверху данной страницы. ',
  TITLE_BLANK_EL2: '2.  Скачать и ознакомиться с печатной формой заявления на прием документов. ',
  TITLE_BLANK_EL3: '3.  Подписать печатную форму заявления на прием документов, нажав на соответствующую кнопку.Согласие на обработку персональных данных сформировано автоматически при регистрации в личном кабинете абитуриента.',
  TITLE_BLANK_EL3_INO: '3.  Подписать печатную форму Заявления на прием документов, нажав на соответствующую кнопку',
  TITLE_BLANK_EL3_ANOTHER: '3.  Подписать печатную форму заявления на прием документов, нажав на соответствующую кнопку. Согласие на обработку персональных данных сформировано автоматически при регистрации в личном кабинете абитуриента.',
  TITLE_BLANK_EL4: '4.  Отправить заявление на проверку.',
  TITLE_BLANK_EL4_ANOTHER: '4.  Подписать печатную форму заявления на прием документов, нажав на соответствующую кнопку. Согласие на обработку персональных данных сформировано автоматически при регистрации в личном кабинете абитуриента.',
  TITLE_BLANK_EL5: '5.  Отправить заявление на проверку.',

  EDIT_APPLICATION: 'Забрать на редактирование',
  SEND_APPLICATION: 'Отправить заявление на проверку',
  EDIT_FIELDS: 'Редактирование направлений',
  PLAN_CONTINUE: 'Планирую поступать после окончания подготовительного факультета на:',
  STUDY_FIELD: 'Направление поступления',
  WITHDRAW_APPLICATION: 'Отозвать заявление',

  HINT_EXAM_DATE: 'Выберите дату вступительных испытаний. В день экзамена Вам необходимо зайти в данный раздел и приступить к экзамену, нажав кнопку “Начать экзамен”',
  EXAM: 'Предмет',
  EXAM_DATE: 'Дата и время экзамена',
  SCORE: 'Оценка (балл)',
  NOT_GRADED: 'Оценки еще нет',
  EXAM_DATE_MOSCOW: 'Дата и время экзамена',
  HINT_NOT_AVAILABLE: 'Нет данных',
  SEMESTER_PAYMENTS: 'Семестровый',
  DOWNLOAD_FORM: 'Скачать форму',
  HINT_DOWNLOAD_FORM: 'Скачайте, распечатайте и подпишите договор, затем загрузите подписанный в область ниже',
  SIGNED_CONTRACT: 'Подписанный договор',
  SEND: 'Отправить',
  STUDY_CONTRACT: 'Договор',
  CONTRACT_SENT: 'Договор был отправлен на проверку',

  SING: 'Подписать',
  START_EXAM: 'Расписание вступительных испытаний будет установлено позже. Следите за обновлениями данного раздела.',

  //Льготы и приемущественные права
  ADD_PRIVILEGES_TITLE: 'Добавить льготу',
  EDIT_PRIVILEGES_TITLE: 'Редактировать льготу',
  ADD_PREEMPTIVE_RIGHT_TITLE: 'Добавить преимущественное право',
  EDIT_PREEMPTIVE_RIGHT_TITLE: 'Редактировать преимущественное право',
  TYPE_PREEMPTIVE: 'Причина',
  TYPE_LGOTA: 'Тип льготы',
  TYPE_DOCUMENT: 'Тип документа',
  NUMBER_DOCUMENT: 'Серия (если есть) и номер документа',
  DATE_ISSUE: 'Дата выдачи',
  DOCUMENT: 'Документ',
  CHECKBOX_LGOTA: 'У меня нет льгот или премущественного права поступления',
  ADD_BUTTON: 'Добавить',
  LGOTS: 'Льготы',
  LGOT: 'Льгота',
  SEPARATE_QUOTA: 'Отдельная квота',
  SPECIAL_QUOTA: 'Особая квота',
  PREEMPTIVE_RIGHTS: 'Преимущественное право',
  CONFIRMATION_DOCUMENT: 'Подтверждающий документ',
  SERIES_AND_NUMBER: 'Серия и номер',

  //Направление подготовки (модератор)
  RECENTLY_ADDED: 'Недавно добавлено',
  REMOVED: 'Удалено',
  DISCIPLINES: 'Предметы',
  SOURCE_OF_FINANCING: 'Источник финансирования',
  FORM_OF_STUDY: 'Форма обучения',
  THE_LEVEL_OF_EDUCTION: 'Уровень образования',
  SUBDIVISION: 'Подразделение',
  PROFILE_SCORE: 'Профильный балл',
  CONSENT_TO_ENROLLMENT: 'Согласие на зачисление от',
  RECEIVED: 'Принято',
  DENIED: 'Отказано',
  DELETE: 'Удалить',
  NAME_CONSENT: 'Название',
  REVOCATION_DATE: 'Дата отзыва',
  CONFIRM_MODAL: 'Подтвердите действие. Нажимая "Да", Вы подписываете заявление о согласии на зачисление',
  REJECT_MODAL: 'Подтвердите действие. Нажимая "Да", Вы подписываете заявление об отзыве согласия на зачисление',

  //ЕГЭ
  NO_RESULTS_OF_USE_CE_CCT: 'Если Вы сдавали ЕГЭ, но на данный момент не получили результаты, то необходимо внести минимальные баллы ЕГЭ по каждому предмету',
  DIDNT_TAKE: 'Не сдавал(а) ЕГЭ/ЦЭ/ЦТ',
  TAKE_EGE: 'Сдавал(а) ЕГЭ',
  CENTRALIZED_EXAM: 'имею результаты Централизованного экзамена',
  CENTRALIZED_TEST: 'имею результаты Централизованного тестирования',
  SUBJECT: 'Предмет',
  ADD_SUBJECT: 'Добавить предмет',
  ADD_ANOTHER_PASSPORT: 'Добавить документ',
  EXAM_YEAR: 'Год сдачи',
  MINIMUM_SCORE: 'Минимальный балл',
  CHECK_RESULTS: 'Проверить результаты ЕГЭ',
  ANOTHER_PASSPORT: 'Сдавал(а) ЕГЭ по другому документу',
  EGE_LINK_INFO_AVERAGE_SCORE: 'минимальные баллы',
  EGE_LINK_START_TEXT: 'Если Вы сдавали ЕГЭ, но на данный момент не получили результаты, то необходимо внести ',
  EGE_LINK_END_TEXT: ' ЕГЭ по каждому предмету',
  EGE_RESULT_OF_CT_CE_TEXT: 'Для учёта результатов ЦТ или ЦЭ в поле «Форма испытания» выберите «ЕГЭ/ЦТ/ЦЭ»',

  //Олимпиады
  WITHOUT_ENTRANCE_TEST: 'Без вступительных испытаний',
  PROFILE_SUBJECT: '100 баллов по профильному предмету',

  //Договора
  CREATE_CONTRACT: 'Новый договор',
  PAYER_INFORMATION: 'Основные данные о плательщике',
  PAYER_PASSPORT: 'Паспортные данные плательщика',
  SUBMIT_CONTRACT_FOR_APPROVAL: 'Отправить договор на согласование',

  //Чат
  CHAT_TITLE: 'Чат поддержки абитуриентов',
  CHAT_SUBTITLE: 'Задайте вопрос',
  CHAT_PLACEHOLDER: 'Введите сообщение...',

  //Подсказки
  HINT_SUPPORTING_DOCUMENT: 'необязательно',

  //Адрес проживание в стране гражданства
  CYRILLIC_AND_NUMBERS_ONLY: 'Только кириллица и цифры',
  CYRILLIC_AND_Latin_AND_NUMBERS_ONLY: 'Только кириллица, латиница и цифры',

  //Льготы
  LINK_FEATURES_OF_TESTING: 'Особенности проведения вступительных испытаний для инвалидов и лиц с ограниченными возможностями здоровья',
  DOWNLOAD_THE_APPLICATION_FORM_FOR_SPECIAL_CONDITION: 'Скачать бланк заявления для специальных условий при проведении вступительных испытаний',
}

export type TermKey = keyof typeof ru
export default ru
