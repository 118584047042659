import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import ModeratorDirectionsElement from '@components/ModeratorDirectionsElement'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import {
  getModeratorDirectionsTrainings,
  getModeratorStatementsAchievementsAndPrivilegesData,
} from '@redux/action-types'
import {
  selectModeratorDirectionsTraining,
  selectModeratorStatementsIdStatus,
} from '@redux/actions/selectors'
import Loader from '@ui/Loader'

const ModeratorDirectionsPage = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const directions = useAppSelector(selectModeratorDirectionsTraining)
  const statement = useAppSelector(selectModeratorStatementsIdStatus)

  let isApprovedStatement = statement?.statusStatement.code === '5' || false

  useEffect(() => {
    dispatch(getModeratorDirectionsTrainings(Number(id)))
    dispatch(getModeratorStatementsAchievementsAndPrivilegesData(Number(id)))
  }, [id])

  if (!directions) return <Loader />

  const newDate =
    directions.reduce((newDate, el) => {
      if (el.deleted) {
        return newDate
      }
      let date1 = new Date(el.date).valueOf()
      let date2 = new Date(newDate ? newDate : el.date).valueOf()
      return date1 - date2 >= 0 ? el.date : newDate
    }, directions?.find((el) => !el.deleted)?.date) || ''

  return (
    <div>
      {directions.map((item, index) => {
        return (
          <ModeratorDirectionsElement
            {...item}
            key={index}
            newDate={newDate}
            isApprovedStatement={isApprovedStatement}
          />
        )
      })}
    </div>
  )
}

export default ModeratorDirectionsPage
