import { useEffect } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import useAppSelector from './useAppSelector'
import useInitializeSelect from './useInitializeSelect'
import {
  ROLE_ADMIN,
  ROLE_MODERATOR,
  ROLE_SUPPORT,
  RUSSIA_NATIONAL_ID,
  STATUS_DRAFT,
  STATUS_REVOKE_APPROVED,
} from '@common/constants'
import useAppDispatch from '@hooks/useAppDispatch'
import {
  getModeratorModeratorsList,
  getModeratorStatementsIdComment,
  getModeratorStatementsIdStatus,
} from '@redux/action-types'
import {
  selectModeratorModeratorsList,
  selectModeratorStatementsIdStatus,
  selectModeratorStatementsIdStatusAdmin,
  selectModeratorStatementsIdStatusCode,
  selectRoleIdProfile,
} from '@redux/actions/selectors'
import { isNumeric } from '@utils/common'

type useModeratorStatementsIdPageInitializationProps = {
  setValue: UseFormSetValue<{ moderatorId: number }>
}

const useModeratorStatementsIdPageInitialization = ({
  setValue,
}: useModeratorStatementsIdPageInitializationProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams<{ id?: string }>()

  useEffect(() => {
    if (id) {
      if (!isNumeric(id)) {
        navigate('../')
      } else {
        dispatch(getModeratorStatementsIdStatus(id))
        if (roleId === ROLE_MODERATOR || roleId === ROLE_ADMIN) {
          dispatch(getModeratorStatementsIdComment(id))
          moderatorsListOptions.length === 0 &&
            dispatch(getModeratorModeratorsList())
        }
      }
    }
  }, [id])

  const roleId = useAppSelector(selectRoleIdProfile)
  const status = useAppSelector(selectModeratorStatementsIdStatus)
  const statusCode = useAppSelector(selectModeratorStatementsIdStatusCode)
  const statusAdmin = useAppSelector(selectModeratorStatementsIdStatusAdmin)
  useEffect(() => {
    if (statusCode) {
      if (
        roleId === ROLE_MODERATOR &&
        (statusCode === STATUS_DRAFT || statusAdmin === STATUS_REVOKE_APPROVED)
      ) {
        navigate('../')
      }

      if (roleId === ROLE_SUPPORT && statusCode !== STATUS_DRAFT) {
        navigate('../')
      }
    }
  }, [statusCode])

  const moderatorsListOptions = useAppSelector(selectModeratorModeratorsList)

  const moderatorInit =
    status?.statementResponsible?.find((el) => el?.user?.roleId === ROLE_MODERATOR)
      ?.user?.id ||
    status?.statementResponsible?.find((el) => el?.user?.roleId === ROLE_ADMIN)?.user
      ?.id ||
    null

  useInitializeSelect({
    data: moderatorInit,
    options: moderatorsListOptions,
    findValue: Number(moderatorInit),
    onInit: (option) => setValue('moderatorId', option as any),
  })

  return {
    moderatorsListOptions,
    roleId,
    status,
    statusAdmin,
    statusCode,
    id,
  }
}

export default useModeratorStatementsIdPageInitialization
