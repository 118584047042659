import React, { forwardRef, PropsWithChildren } from 'react'
import classNames from 'classnames'

type RadioButtonProps = PropsWithChildren<{
  className?: string
  error?: string
  markRequired?: boolean
}>

const RadioButton = ({ children, className, error }: RadioButtonProps) => {
  return (
    <div
      className={classNames('radio-button', className, {
        ['select-container--error']: Boolean(error),
      })}
    >
      {children}
      {error && <span className={'select-container__error'}>{error}</span>}
    </div>
  )
}

type RadioButtonRadioProps = React.InputHTMLAttributes<HTMLInputElement> & {
  text?: string
}

RadioButton.Radio = forwardRef(
  ({ text, ...rest }: RadioButtonRadioProps, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label className="radio-button__label">
        <input ref={ref} {...rest} type="radio" className="radio-button__input" />
        {text && <span className="radio-button__text">{text}</span>}
      </label>
    )
  }
)

export default RadioButton
