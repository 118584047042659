import { useEffect, useState } from 'react'

import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import { UploadDocKey } from '@common/uploadDocsTypes'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { createToast } from '@redux/action-types'
import { selectDocuments } from '@redux/actions/selectors'
import { getDocs } from '@redux/reducers'
import api, { UploadDocsRespOk, UploadDocsResponse } from '@services/api'
import { base64ToPdf } from '@utils/common'

const useUploadDocuments = (typeDoc: UploadDocKey, filename?: string) => {
  const [docs, setDocs] = useState<File[]>([])
  const [docId, setDocId] = useState<number>()
  const [errors, setErrors] = useState<{
    documents?: string[]
    typeDoc?: string[]
  } | null>(null)

  const dispatch = useAppDispatch()
  const uploadedDocs = useAppSelector(selectDocuments)

  const onUploadDocs = (cb?: Function): Promise<{ success: boolean }> => {
    if (docs.length === 0) return Promise.resolve({ success: false })

    return api.client
      .uploadDocs({ documents: docs, typeDoc })
      .then((response) => {
        const { data } = response as UploadDocsRespOk
        dispatch(createToast(data.message, 'success'))
        setErrors(null)
        setDocId(data.id)

        return { success: true, id: data.id }
      })
      .catch((response: Exclude<UploadDocsResponse, UploadDocsRespOk>) => {
        const { status } = response

        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          setErrors(data.errors)
          dispatch(createToast(data.message, 'danger'))
        } else {
          setErrors({ documents: [terms.ERROR_HAS_OCCURED] })
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }

        return { success: false }
      })
      .finally(() => {
        if (cb) {
          cb()
        }
      })
  }

  const onChange = (files: File[]) => setDocs(files)

  const onDrop = (files: File[]) => setDocs((prev) => [...prev, ...files])

  const onDelete = (file: File) =>
    setDocs((prev) => prev.filter((f) => f.name !== file.name))

  const getUploadedDoc = (docId?: number) => {
    dispatch(getDocs({ typeDoc, docId }))
  }

  useEffect(() => {
    if (uploadedDocs[typeDoc]?.data) {
      const { data } = uploadedDocs[typeDoc]
      if (!data.document) return
      if (typeof data.document === 'string') {
        base64ToPdf(data.document, filename).then((file) => setDocs([file]))
      } else {
        base64ToPdf(
          data.document[
            Object.keys(data.document).sort((a, b) => Number(b) - Number(a))[0]
          ],
          filename
        ).then((file) => setDocs([file]))
      }
    }
  }, [uploadedDocs[typeDoc]])

  return {
    docs,
    docId,
    errors,
    onDrop,
    onChange,
    onDelete,
    uploadedDocs,
    onUploadDocs,
    getUploadedDoc,
    setDocs,
    documentLoaded: Boolean(uploadedDocs[typeDoc]?.data?.document),
  }
}

export default useUploadDocuments
