import React from 'react'

import CreateContractForm from '@components/CreateContractForm'

const CreateContractPage = () => {
  return (
    <div>
      <CreateContractForm />
    </div>
  )
}

export default CreateContractPage
