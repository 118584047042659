import HttpStatusCode from '../common/httpStatusCodes'
import terms from '../common/terms'
import api, {
  ResponseOk,
  UpdateDeleteStatements,
  UpdateStatementStatus,
} from '../services/api'
import useAppDispatch from './useAppDispatch'
import {
  STATUS_DRAFT,
  STATUS_EDIT_DIRECTION,
  STATUS_REVOKE,
} from '@common/constants'
import {
  createToast,
  getStatementsList,
  getTrajectoryList,
} from '@redux/action-types'

const useStatementsList = () => {
  const dispatch = useAppDispatch()

  const deleteStatements = (id) => {
    api.client
      .deleteStatements(id)
      .then((response) => {
        const { data } = response
        dispatch(createToast(data.message, 'success'))
        dispatch(getStatementsList())
        dispatch(getTrajectoryList())
      })
      .catch((response: Exclude<UpdateDeleteStatements, ResponseOk>) => {
        const { status } = response
        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
  }

  const fixStatements = (id) => {
    changeStatus(id, STATUS_DRAFT)
  }

  const fixDirection = (id) => {
    changeStatus(id, STATUS_EDIT_DIRECTION)
  }

  const revoke = (id) => {
    return changeStatus(id, STATUS_REVOKE)
  }

  const changeStatus = (id, code) => {
    return api.client
      .putStatusStatement({ id: Number(id), code: code })
      .then((response) => {
        const { data } = response
        dispatch(createToast(data.message, 'success'))
        dispatch(getStatementsList())
      })
      .catch((response: Exclude<UpdateStatementStatus, ResponseOk>) => {
        const { status } = response
        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else if (status === HttpStatusCode.NOT_ACCEPTABLE) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
  }

  return {
    fixDirection,
    fixStatements,
    deleteStatements,
    revoke,
  }
}

export default useStatementsList
