import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import { createToast, getModeratorDirectionsTrainings } from '@redux/action-types'
import api, {
  ResponseOk,
  UpdateGpa,
  UploadDocsRespOk,
  UploadDocsResponse,
} from '@services/api'

const useModeratorDirectionsElement = (
  competitiveId: number,
  competitiveGroupStatementId: number,

  trainingLevel: string,
  accessConclusion: boolean
) => {
  const { register, handleSubmit, setError, setValue, ...rest } = useForm({
    mode: 'all',
  })

  const { id } = useParams()
  const dispatch = useAppDispatch()

  const approveContact = async (idCG) => {
    await api.client
      .putApproveContract({ id: idCG, access_conclusion: !accessConclusion })
      .then(({ status }) => {
        if (status === 200) {
          dispatch(getModeratorDirectionsTrainings(Number(id)))
        }
      })
      .catch((error) => console.log(error))
  }

  const handleClick = async () => {
    if (id && competitiveId) {
      await api.client
        .subscribeBlank({ statement: Number(id), blankId: 2 })
        .then((res) => {
          const { data } = res as unknown as ResponseOk
          dispatch(createToast(data.message, 'success'))
        })
        .catch((response: Exclude<UploadDocsResponse, UploadDocsRespOk>) => {
          const { status } = response

          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
    }
  }

  return {
    handleClick,
    approveContact,
    ...rest,
  }
}

export default useModeratorDirectionsElement
