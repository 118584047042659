import React from 'react'

import AdditionalInformationForm from '@components/AdditionalInformation'

const AdditionalInformationPage = () => {
  return (
    <div>
      <AdditionalInformationForm />
    </div>
  )
}

export default AdditionalInformationPage
