import { useEffect, useState } from 'react'

import { SelectOption } from '@models'

type UseInitializeSelectProps<D> = {
  data: D | null
  options: SelectOption[]
  findValue?: number | null
  onInit: (option: SelectOption) => void
}

const useInitializeSelect = <D>({
  options,
  findValue,
  data,
  onInit,
}: UseInitializeSelectProps<D>) => {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (!initialized && data && options.length > 0) {
      const candidate = options.find((o) => o.value === findValue)
      if (candidate) {
        onInit(candidate)
      }
      setInitialized(true)
    }
  }, [data, options])
}

export default useInitializeSelect
