import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import HttpStatusCode from 'common/httpStatusCodes'
import useAppDispatch from 'hooks/useAppDispatch'
import { createToast } from 'redux/action-types'
import api, { ConfirmAccountResponse, ConfirmAccountRespOk } from 'services/api'

const useConfirmAccountForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(true)
  const { code } = useParams<{ code: string }>()

  useEffect(() => {
    if (!code) {
      return navigate('/')
    }

    api.client
      .confirmAccount(code)
      .then((response) => {
        const { data, status } = response
        if (status === HttpStatusCode.OK) {
          dispatch(createToast(data.message, 'success'))
          setLoading(false)
        }
      })
      .catch((response: Exclude<ConfirmAccountResponse, ConfirmAccountRespOk>) => {
        const { data } = response
        dispatch(createToast(data.message, 'danger'))
        navigate('/')
      })
  }, [])

  return { loading }
}

export default useConfirmAccountForm
