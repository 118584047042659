import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { AuthSettingEdit, ExchangeSetting } from '../models/settings'
import useAppDispatch from './useAppDispatch'
import terms from '@common/terms'
import { createToast, hideModal, showModal } from '@redux/action-types'
import api, { ResponseOk } from '@services/api'

const useSiteExchangeSetting = () => {
  const editDirectory = (data) => {
    dispatch(showModal({ name: 'EXCHANGE_MODAL', data: { data } }))
  }

  const { register, handleSubmit, setError, control, reset, setValue, watch } =
    useForm<ExchangeSetting>({ mode: 'all' })

  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)

  const useExchange = (data) => {
    if (
      data['url'] == '/startExchange' ||
      data['url'] == '/exchangeOlympiadAsync' ||
      data['url'] == '/exchangeTrialsAsync' ||
      data['url'] == '/exchangeCompetitiveGroupExchangeAsync'
    ) {
      api.client.putExchange(data)
      dispatch(createToast(terms.FULL_EXCHANGE_WAS_SENT, 'success'))
      dispatch(hideModal())
    } else {
      setLoading(true)
      api.client
        .putExchange(data)
        .then((response) => {
          const { data } = response as unknown as ResponseOk
          if (data.success) {
            dispatch(createToast(data.message, 'success'))
          } else {
            dispatch(createToast(data.message, 'danger'))
          }
          //  dispatch(hideModal())
        })
        .catch(() => {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          //  hideModal()
        })
        .finally(() => setLoading(false))
    }
  }

  const fields = {
    exchangeYear: register('exchangeYear', { required: terms.REQUIRED_FIELD }),
    clearDatabase: register('clearDatabase'),
    url: register('url', { required: terms.REQUIRED_FIELD }),
  }

  const onSubmit = handleSubmit((data: any) => {
    const normalizedData: ExchangeSetting = {
      ...data,
      exchange_year: data.exchangeYear,
      clear_database: data.clearDatabase,
      url: data.url,
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useExchange(normalizedData)
  })

  return {
    onSubmit,
    loading,
    fields,
    editDirectory,
  }
}

export default useSiteExchangeSetting
