import { useState } from 'react'

import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'

import useFillingStatementsRoutesContext from './useFillingStatementsRoutesContext'
import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import { createToast } from '@redux/action-types'
import { ResponseOk, UpdateEgeTabResponse } from '@services/api'

type useFillingStatementsApiHandlingProps<T> = {
  apiFn: Function
  onSuccessCb: Function
}

const useFillingStatementsApiHandling = <T>({
  apiFn,
  onSuccessCb,
}: useFillingStatementsApiHandlingProps<T>) => {
  const [loading, setLoading] = useState(false)

  const dispatch = useAppDispatch()
  const { onNextPageNavigate } = useFillingStatementsRoutesContext()

  const callApi = <D>(data: D) => {
    setLoading(true)

    apiFn(data)
      .then((response) => {
        const { data } = response as ResponseOk
        dispatch(createToast(data.message, 'success'))
        onSuccessCb()
        onNextPageNavigate()
      })
      .catch((response: Exclude<UpdateEgeTabResponse, ResponseOk>) => {
        const { status } = response
        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
  }

  const callApiV2 = <D>(data: D) => {
    setLoading(true)
    const snakecaseKeysData = snakecaseKeys(data)

    const form = new FormData()

    // @ts-ignore
    if (data.passports) {
      // @ts-ignore
      data.passports.forEach((file, index) => {
        file.files.forEach((item) => {
          form.append(`passport-file-${index}[]`, item)
        })
      })
    }

    for (const [key, value] of Object.entries(snakecaseKeysData)) {
      form.append(key, JSON.stringify(value))
    }

    apiFn(form)
      .then((response) => {
        const { data } = response as ResponseOk
        dispatch(createToast(data.message, 'success'))
        onSuccessCb()
        onNextPageNavigate()
      })
      .catch((response: Exclude<UpdateEgeTabResponse, ResponseOk>) => {
        const { status } = response
        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
  }

  return { callApi, callApiV2, loading }
}

export default useFillingStatementsApiHandling
