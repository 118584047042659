import React, { useEffect } from 'react'

import './DirectoryPage.scss'
import TableDirectories from '@components/TableDirectories'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { getDirectoriesList } from '@redux/action-types/admin'
import {
  selectAdminDirectoriesList,
  selectAdminPageDirectoriesList,
  selectAdminPerPageDirectoriesList,
  setAdminPageDirectories,
  setAdminPerPageDirectories,
} from '@redux/actions/selectors'
import Loader from '@ui/Loader'
import Pagination from '@ui/Pagination'

const DirectoryPage = () => {
  const dispatch = useAppDispatch()

  const directoriesList = useAppSelector(selectAdminDirectoriesList) || { data: [] }

  const page = useAppSelector(selectAdminPageDirectoriesList)
  const perPage = useAppSelector(selectAdminPerPageDirectoriesList)

  useEffect(() => {
    dispatch(getDirectoriesList({ page, perPage }))
  }, [page, perPage])

  if (!directoriesList?.data.length) {
    dispatch(setAdminPageDirectories(1))
    return <Loader />
  }

  return (
    <main className="directory-page">
      <div className="directory-page-header">
        <h1 className="directory-page__title">Справочники</h1>
      </div>
      <TableDirectories rows={directoriesList?.data} />
      <div className="directory-page__pagination">
        {directoriesList?.data.length && (
          <Pagination
            lastPage={directoriesList.lastPage}
            currentPage={directoriesList.currentPage}
            perPage={directoriesList.perPage}
            setPage={setAdminPageDirectories}
            setPerPage={setAdminPerPageDirectories}
          />
        )}
      </div>
    </main>
  )
}

export default DirectoryPage
