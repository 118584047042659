import React, { useEffect } from 'react'

import TrajectoryElement from '@components/TrajectoryList/TrajectoryElement'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { setTrajectoryValue, getTrajectoryList } from '@redux/action-types'
import {
  selectTrajectoryList,
  selectTrajectoryValue,
} from '@redux/actions/selectors'

const TrajectoryList = () => {
  const active = useAppSelector(selectTrajectoryValue)

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(getTrajectoryList())
  }, [])

  const setActive = (value) => {
    dispatch(setTrajectoryValue(value))
  }

  const TrajectoryDataList = useAppSelector(selectTrajectoryList)

  return (
    <section className="trajectory-list">
      {TrajectoryDataList?.map(
        ({ translate, id, scenarioId }) =>
          translate.length > 0 && (
            <TrajectoryElement
              key={id}
              setActive={setActive}
              id={id}
              scenarioId={scenarioId}
              active={id === active.id}
              name={translate[0].name}
              content={translate[0].content}
            />
          )
      )}
    </section>
  )
}

export default TrajectoryList
