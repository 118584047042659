import React, { createContext, useContext } from 'react'

import useFillingStatementsProvideRoutes from './useFillingStatementsProvideRoutes'
import useFillingStatements from '@common/routes/useFillingStatements'

type FillingStatementsRoutesContextProps = ReturnType<
  typeof useFillingStatementsProvideRoutes
>

const FillingStatementsRoutesContext =
  createContext<FillingStatementsRoutesContextProps>(
    {} as FillingStatementsRoutesContextProps
  )

export const FillingStatementsRoutesContextProvider = ({
  children,
  scenarioKeys,
}: {
  children: React.ReactNode
  scenarioKeys: any
}) => {
  const { fillingStatementsRoutes } = useFillingStatements(scenarioKeys)

  return (
    <FillingStatementsRoutesContext.Provider
      value={useFillingStatementsProvideRoutes(
        fillingStatementsRoutes,
        scenarioKeys
      )}
    >
      {children}
    </FillingStatementsRoutesContext.Provider>
  )
}

export default () => {
  return useContext(FillingStatementsRoutesContext)
}
