import React from 'react'

import classnames from 'classnames'

const LevelListElement = ({ id, name, active, setActive, className }) => {
  const changeTrajectory = () => {
    setActive({
      id: id,
      value: name,
    })
  }
  return (
    <div
      className={classnames('level-list__level-element', { active }, className)}
      onClick={changeTrajectory}
    >
      <h2>{name}</h2>
    </div>
  )
}

export default LevelListElement
