import React from 'react'
import { Controller } from 'react-hook-form'

import classnames from 'classnames'

import Select from '../../../generic/Select'
import useAppSelector from '@hooks/useAppSelector'
import useEntranceExamsRowForm from '@hooks/useEntranceExamsRowForm'
import { SelectOption } from '@models'
import { selectMoodleCourses } from '@redux/actions/selectors'
import Button from '@ui/Button'
import { formatDateTimeToRuFormat } from '@utils/date'

const EntranceExamsRow = ({ discipline, trial }): JSX.Element | null => {
  const { control } = useEntranceExamsRowForm()
  const courses = useAppSelector(selectMoodleCourses)

  const course = courses?.courses.find((el) =>
    el?.externalId.includes(discipline?.externalId)
  )

  if (!course && !trial) {
    return null
  }

  return (
    <tr className="entr-exams-table__row">
      <td className="entr-exams-table__cell">
        <span className="entr-exams-table__discipline">
          {discipline ? discipline?.subject || '' : trial.disciplineName || ''}
        </span>
      </td>
      <td className="entr-exams-table__cell">
        {course ? (
          <Controller
            name="discipline"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                placeholder={'Дата экзамена'}
                className="students-form__field"
                markRequired={true}
                {...(field as unknown)}
                value={
                  course?.time
                    ? {
                        label: `${formatDateTimeToRuFormat(course?.data).slice(
                          0,
                          -5
                        )} ${course?.time} `,
                        value: 0,
                      }
                    : undefined
                }
                options={
                  course?.time
                    ? [
                        {
                          label: ` ${formatDateTimeToRuFormat(course?.data).slice(
                            0,
                            -5
                          )} ${course?.time}`,
                          value: 0,
                        },
                      ]
                    : undefined
                }
                isDisabled={!course?.time}
              />
            )}
          />
        ) : (
          <span className="entr-exams-table__estimate">
            {trial?.schedule ? formatDateTimeToRuFormat(trial?.schedule) : ''}
          </span>
        )}
      </td>
      <td
        className={classnames(
          'entr-exams-table__cell',
          'entr-exams-table__cell--align-center'
        )}
      >
        {course ? (
          <span className="entr-exams-table__estimate">
            {course?.value || 'Оценки еще нет'}
          </span>
        ) : (
          <span className="entr-exams-table__estimate">
            {trial?.trialTypeName || ''}
          </span>
        )}
      </td>
      <td
        className={classnames(
          'entr-exams-table__cell',
          'entr-exams-table__cell--align-center'
        )}
      >
        {course ? (
          course?.link ? (
            <Button
              theme="success"
              onClick={(e) => {
                e.preventDefault()
                window.open(course?.link)
              }}
            >
              Начать экзамен
            </Button>
          ) : (
            <Button disabled>Укажите дату экзамена</Button>
          )
        ) : (
          <span className="entr-exams-table__estimate">{trial?.place || ''}</span>
        )}
      </td>
    </tr>
  )
}

export default EntranceExamsRow
