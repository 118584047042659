import { useEffect } from 'react'
import { UseFormSetValue } from 'react-hook-form'

import useAppSelector from './useAppSelector'
import useInitializeSelect from './useInitializeSelect'
import useAppDispatch from '@hooks/useAppDispatch'
import { LgotaDocs, PrivilegesCreate, SelectCodeOption } from '@models'
import {
  modalDataSelector,
  selectLgotaDocTypeOptions,
  selectLgotaTypeOptions,
} from '@redux/actions/selectors'
import { getLgotaType } from '@redux/reducers'

type useAddPrivilegesFormSelectorsProps = {
  setValue: UseFormSetValue<PrivilegesCreate>
}

const useAddPrivilegesForminitialization = ({
  setValue,
}: useAddPrivilegesFormSelectorsProps) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    !typeDoc.length && dispatch(getLgotaType())
  }, [])

  const typeDoc: SelectCodeOption[] = useAppSelector(selectLgotaTypeOptions)
  const lgotaDocType: SelectCodeOption[] = useAppSelector(selectLgotaDocTypeOptions)

  const defaultData: LgotaDocs = useAppSelector(modalDataSelector)
  useEffect(() => {
    if (defaultData) {
      setValue('number', defaultData.number)
      setValue('dateIssue', defaultData.dateIssue)
      setValue('docId', defaultData.docId)
    }
  }, [defaultData])

  useInitializeSelect({
    data: typeDoc,
    options: typeDoc,
    findValue: Number(defaultData?.lgota.id),
    onInit: (option) => setValue('lgotaId', option as any),
  })
  useInitializeSelect({
    data: lgotaDocType,
    options: lgotaDocType,
    findValue: Number(defaultData?.lgotaDoc.id),
    onInit: (option) => setValue('lgotaDocId', option as any),
  })

  return {
    typeDoc,
    lgotaDocType,
  }
}

export default useAddPrivilegesForminitialization
