import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { saveAuthTokenToStorage } from 'utils/auth'

import HttpStatusCode from '@common/httpStatusCodes'
import regexp from '@common/regexp'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import { UserCredentials } from '@models'
import { createToast, setToken } from '@redux/action-types'
import api, {
  ConfirmAccountRespOk,
  ConfirmAccountResponse,
  SignInRespForbidden,
  SignInRespOk,
  SignInResponse,
  SignInRespUnauthorized,
  SignInRespUnpocessableEntity,
} from '@services/api'
import { useParams } from 'react-router-dom'

const useSignInForm = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()

  const { register, handleSubmit, setError, ...rest } = useForm<UserCredentials>()

  const fields = {
    email: register('email', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: regexp.email,
        message: terms.VALID_EMAIL,
      },
    }),
    password: register('password', {
      required: terms.REQUIRED_FIELD,
    }),
  }
  const { code } = useParams<{ code: string }>()

  useEffect(() => {
    if (code) {
      api.client
        .confirmAccount(code)
        .then((response) => {
          const { data, status } = response
          if (status === HttpStatusCode.OK) {
            dispatch(createToast(data.message, 'success'))
            setLoading(false)
          }
        })
        .catch((response: Exclude<ConfirmAccountResponse, ConfirmAccountRespOk>) => {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        })
    }
  }, [])

  const onSubmit = handleSubmit((data: any) => {
    data['email'] = data['email'].toLowerCase()
    setLoading(true)
    api.client
      .signin(data)
      .then((response) => {
        const data = response.data as SignInRespOk['data']
        dispatch(setToken(data.token))
        dispatch(createToast(data.message, 'success'))
        saveAuthTokenToStorage(data.token)
      })
      .catch((response: Exclude<SignInResponse, SignInRespOk>) => {
        const { status } = response

        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response as SignInRespUnpocessableEntity
          dispatch(createToast(terms.INVALID_EMAIL_OR_PASSWORD, 'danger')) //data.message
          //отключено ввиду необходимости доработки и отсутствия конкретной задачи
          // if (data.errors['email']) {
          //   setError('email', {
          //     type: 'manual',
          //     message: data.errors['email'][0],
          //   })
          // }
        } else if (status == HttpStatusCode.UNAUTHORIZED) {
          const { data } = response as SignInRespUnauthorized
          dispatch(createToast(data.message, 'danger'))
        } else if (status == HttpStatusCode.FORBIDDEN) {
          const { data } = response as SignInRespForbidden
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
      .finally(() => {
        setLoading(false)
      })
  })

  return {
    fields,
    onSubmit,
    loading,
    ...rest,
  }
}

export default useSignInForm
