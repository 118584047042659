import { useEffect } from 'react'

import useInitializeInputs from '@hooks/useInitializeInputs'

const useEntrantsContractFormInitialization = (contract, setValue) => {
  useEffect(() => {
    if (contract) {
      setValue('directionTrainingId', {
        label:
          contract?.competitiveGroupStatement?.competitiveGroup?.directionTraining
            ?.translations[0]?.name,
        value:
          contract?.competitiveGroupStatement?.competitiveGroup?.directionTraining
            ?.translations[0]?.directionTrainingId,
      })
    }
  }, [contract])

  useInitializeInputs({
    data: contract,
    omitKeys: [],
    onInit: (key, value) => {
      setValue(key, value)
      setValue('contractId', contract?.id)
    },
  })
}

export default useEntrantsContractFormInitialization
