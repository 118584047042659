import useAppSelector from './useAppSelector'
import { selectLocalizedViewerProfile } from '@redux/actions/selectors'

const useCitizenshipResult = () => {
  const profileData = useAppSelector(selectLocalizedViewerProfile)

  const nationalId = profileData?.userNational[0]?.nationalityId

  return { nationalId }
}

export default useCitizenshipResult
