import {
  SelectCodePaginatedEntityResult,
  SelectPaginatedEntityResult,
} from 'types/custom-types'

import { RootState } from '../../types'
import { EN_LOCALE_ID, RU_LOCALE_ID } from '@common/constants'
import {
  SelectOption,
  Passport,
  PersonalData,
  Residence,
  National,
  VisaCountry,
  VisaCity,
  EducationData,
  PhoneCode,
  LocationsElement,
  SelectCodeOption,
  LegalRepresentative,
  NationalOfBirth,
  PlaceOfBirth,
  Visa,
  DirectionTraining,
  SelectOptionRus,
  Partner,
  SubjectRussia,
} from '@models'

export const selectMobileMenu = (state: RootState) => state.profile.showMobileMenu

export const selectPersonalData = (state: RootState) =>
  state.profile.entities.personalData.data

export const selectPaginatedNationals = (
  state: RootState
): SelectPaginatedEntityResult => {
  const { nationals } = state.profile.entities
  return {
    options: nationals.data.map(extractNationalOption),
    nextPage: nationals.nextPage,
  }
}

export const selectPaginatedDirectionTrainings = (
  state: RootState
): SelectPaginatedEntityResult => {
  const { directionTraining } = state.profile.entities
  return {
    options: directionTraining.data.map(extractDirectionTrainingOption),
    nextPage: directionTraining.nextPage,
  }
}

export const selectPaginatedNationalsRus = (
  state: RootState
): SelectPaginatedEntityResult => {
  const { nationalsRus } = state.profile.entities
  return {
    options: nationalsRus.data.map(extractNationalOption),
    nextPage: nationalsRus.nextPage,
  }
}
export const selectPaginatedNationalsIno = (
  state: RootState
): SelectPaginatedEntityResult => {
  const { nationalsIno } = state.profile.entities
  return {
    options: nationalsIno.data.map(extractNationalOption),
    nextPage: nationalsIno.nextPage,
  }
}

export const selectPaginatedLocations = (
  state: RootState
): SelectPaginatedEntityResult => {
  const { location } = state.profile.entities
  if (!location.data || !location.data.length) return { options: [], nextPage: 1 }
  return {
    options: location.data.map(extractLocationOption),
    nextPage: 1,
  }
}

export const selectPaginatedSubjectsRussia = (
  state: RootState
): SelectPaginatedEntityResult => {
  const { subjectsRussia } = state.profile.entities

  return {
    options: subjectsRussia.data.map(extractSubjectRussiaOption),
    nextPage: subjectsRussia.nextPage,
  }
}

export const selectPaginatedPhoneCodes = (
  state: RootState
): SelectPaginatedEntityResult => {
  const { phoneCodes } = state.profile.entities
  return {
    options: phoneCodes.data.map(extractPhoneCodeOption),
    nextPage: phoneCodes.nextPage,
  }
}

export const selectPaginatedPhoneCodesLoading = (state: RootState) => {
  const { phoneCodes } = state.profile.entities
  return phoneCodes.status === 'loading'
}

export const selectPaginatedKladr = (
  state: RootState
): SelectCodePaginatedEntityResult => {
  const { kladr } = state.profile.entities

  if (!kladr.data || !kladr.data.length) return { options: [], nextPage: 1 }

  return {
    options: kladr.data.map(extractKladrOption) as SelectCodeOption[],
    nextPage: kladr.nextPage,
  }
}

export const selectPaginatedKladrRegion = (
  state: RootState
): SelectPaginatedEntityResult => {
  const { kladrRegion } = state.profile.entities
  if (!kladrRegion.data || !kladrRegion.data.length)
    return { options: [], nextPage: 1 }

  return {
    options: kladrRegion.data.map(extractKladrRegionOption) as SelectCodeOption[],
    nextPage: kladrRegion.nextPage,
  }
}

export const selectPaginatedKladrDistrict = (
  state: RootState
): SelectCodePaginatedEntityResult => {
  const { kladrDistrict } = state.profile.entities

  if (!kladrDistrict.data || !kladrDistrict.data.length)
    return { options: [], nextPage: 1 }

  return {
    options: kladrDistrict.data.map(
      extractKladrDistrictOption
    ) as SelectCodeOption[],
    nextPage: kladrDistrict.nextPage,
  }
}
export const selectPaginatedKladrCity = (
  state: RootState
): SelectCodePaginatedEntityResult => {
  const { kladrCity } = state.profile.entities
  if (!kladrCity.data || !kladrCity.data.length) return { options: [], nextPage: 1 }

  return {
    options: kladrCity.data.map(extractKladrCityOption) as SelectCodeOption[],
    nextPage: kladrCity.nextPage,
  }
}

export const selectPaginatedKladrStreet = (
  state: RootState
): SelectCodePaginatedEntityResult => {
  const { kladrStreet } = state.profile.entities

  if (!kladrStreet.data || !kladrStreet.data.length)
    return { options: [], nextPage: 1 }

  return {
    options: kladrStreet.data.map(extractKladrStreetOption) as SelectCodeOption[],
    nextPage: kladrStreet.nextPage,
  }
}

export const selectPaginatedKladrHouse = (
  state: RootState
): SelectPaginatedEntityResult => {
  const { kladrHouse } = state.profile.entities

  if (!kladrHouse.data || !kladrHouse.data.length)
    return { options: [], nextPage: 1 }

  return {
    options: kladrHouse.data.map(extractKladrHouseOption) as SelectCodeOption[],
    nextPage: kladrHouse.nextPage,
  }
}

export const selectPassportData = (state: RootState) => {
  const result = state.profile.entities.passportData.data
  return result && result.length > 0 ? result[0] : null
}

export const selectPassportFormData = (state: RootState): Passport | null => {
  const result = state.profile.entities.passportData.data
  if (!result || result.length === 0) return null
  const [data] = result
  return {
    ...data,
    compatriot: data.user.compatriot,
    needVisa: data.user.needVisa,
    nationalOption: extractNationalOption(data.nationality),
    quota_minobr: !!data.regNumberEduinru,
    reg_number_eduinru: data.regNumberEduinru,
  }
}

export const selectPaginatedNationalsLoading = (state: RootState) => {
  const { nationals } = state.profile.entities
  return nationals.status === 'loading'
}

export const selectPaginatedDirectionTrainingLoading = (state: RootState) => {
  const { nationals } = state.profile.entities
  return nationals.status === 'loading'
}

export const selectPaginatedLocationLoading = (state: RootState) => {
  const { location } = state.profile.entities
  return location.status === 'loading'
}

export const selectPaginatedSubjectsRussiaLoading = (state: RootState) => {
  const { subjectsRussia } = state.profile.entities
  return subjectsRussia.status === 'loading'
}

export const selectPaginatedKladrLoading = (state: RootState) => {
  const { kladr } = state.profile.entities
  return kladr.status === 'loading'
}

export const selectPaginatedKladrRegionLoading = (state: RootState) => {
  const { kladrRegion } = state.profile.entities
  return kladrRegion.status === 'loading'
}

export const selectPaginatedKladrDistrictLoading = (state: RootState) => {
  const { kladrDistrict } = state.profile.entities
  return kladrDistrict.status === 'loading'
}

export const selectPaginatedKladrDistrictCity = (state: RootState) => {
  const { kladrCity } = state.profile.entities
  return kladrCity.status === 'loading'
}

export const selectPaginatedKladrDistrictStreet = (state: RootState) => {
  const { kladrStreet } = state.profile.entities
  return kladrStreet.status === 'loading'
}

export const selectPaginatedKladrDistrictHouse = (state: RootState) => {
  const { kladrHouse } = state.profile.entities
  return kladrHouse.status === 'loading'
}

export const selectPaginatedVisaCountries = (
  state: RootState
): SelectPaginatedEntityResult => {
  const { visaCountries } = state.profile.entities

  return {
    options: visaCountries.data.map(extractVisaCountryOption),
    nextPage: visaCountries.nextPage,
  }
}

export const selectPaginatedVisaCountryLoading = (state: RootState) => {
  const { visaCountries } = state.profile.entities
  return visaCountries.status === 'loading'
}

export const selectLegalRepresentativeFormData = (
  state: RootState
): LegalRepresentative | null => {
  const { data } = state.profile.entities.legalRepresentative
  if (!data) return null
  return {
    ...data,
    mobile:
      data.mobile && data.mobile.split(' ').length > 1
        ? data.mobile.split(' ')[1]
        : data.mobile,
    phoneCode: null,
    nationality: data?.nationalityId,
    nationalOption: extractNationalOption(data.nationality),
    countryOfBirth: data?.countryOfBirthId,
    countryOfBirthOption: extractCountyOfBirthOption(data.countryOfBirth),
    placeOfBirthday: data?.placeOfBirthdayId,
    placeBirthday: data?.placeBirthday,

    // placeOfBirthdayOption: extractPlaceOfBirthOption(data.placeOfBirthday),
    phoneCodeOption: createPhoneCodeOption(data.mobile),
  }
}

export const selectResidenceFormData = (state: RootState): Residence | null => {
  const { data } = state.profile.entities.residence
  if (!data) return null
  const actual = data.address.find((a) => a.typeAddress === 'actual')
  const reg = data.address.find((a) => a.typeAddress === 'registration')

  return {
    kladr1: undefined,
    kladr2: undefined,
    needHostel:
      typeof data.needHostel === 'boolean'
        ? data.needHostel
          ? 'need'
          : 'no need'
        : null,
    noStreetActual: Boolean(actual?.noStreet),
    noStreetRegistration: Boolean(reg?.noStreet),
    kladrActual: actual?.kladr || null,
    nationalActual: actual?.nationalityId || null,
    cityActual: actual?.city || null,
    regionActual: actual?.region || null,
    districtActual: actual?.district || null,
    streetActual: actual?.street || null,
    houseActual: actual?.house || null,
    buildingActual: actual?.building || null,
    apartmentActual: actual?.apartment || null,
    postcodeActual: actual?.postcode || null,
    apiKladrActual: actual?.apiKladr || null,
    apiFiasActual: actual?.apiFias || null,
    dadataFieldActual: actual?.dadataField || null,
    dadataValueActual: actual?.dadataValue || null,
    dadataUnrestrictedValueActual: actual?.dadataUnrestrictedValue || null,

    kladrRegistration: reg?.kladr || null,
    nationalRegistration: reg?.nationalityId || null,
    cityRegistration: reg?.city || null,
    regionRegistration: reg?.region || null,
    districtRegistration: reg?.district || null,
    streetRegistration: reg?.street || null,
    houseRegistration: reg?.house || null,
    buildingRegistration: reg?.building || null,
    apartmentRegistration: reg?.apartment || null,
    apiKladr: reg?.apiKladr || null,
    apiFias: reg?.apiFias || null,
    dadataField: reg?.dadataField || null,
    dadataValue: reg?.dadataValue || null,
    dadataUnrestrictedValue: reg?.dadataUnrestrictedValue || null,
    postcodeRegistration: reg?.postcode || null,
    actisreg: data.actisreg,

    regionCodeActual: actual?.kladr?.slice(0, 2) || null,
    districtCodeActual: actual?.kladr?.slice(2, 5) || null,
    localityCodeActual: actual?.kladr?.slice(0, 13) || null,
    streetCodeActual: !Boolean(actual?.noStreet)
      ? actual?.kladr?.slice(0, 17) || null
      : '0000',

    regionCodeRegistration: reg?.kladr?.slice(0, 2) || null,
    districtCodeRegistration: reg?.kladr?.slice(2, 5) || null,
    localityCodeRegistration: reg?.kladr?.slice(0, 13) || null,
    streetCodeRegistration: !Boolean(reg?.noStreet)
      ? reg?.kladr?.slice(0, 17) || null
      : '0000',

    apiFiasOption: null,
    apiKladrOption: null,
    dadataFieldOption: null,
    dadataValueOption: null,
    dadataUnrestrictedValueOption: null,
    regionActualOption: actual?.kladr
      ? {
          value: Number(actual.kladr.slice(0, 2)),
          label: actual.region,
          code: actual.kladr.slice(0, 2),
        }
      : null,
    regionRegistrationOption: reg?.kladr
      ? {
          value: Number(reg.kladr.slice(0, 2)),
          label: reg.region,
          code: reg.kladr.slice(0, 2),
        }
      : null,

    districtActualOption: actual?.kladr
      ? {
          value: Number(actual.kladr.slice(2, 5)),
          label: actual.district,
          code: actual.kladr.slice(2, 5),
        }
      : null,
    districtRegistrationOption: reg?.kladr
      ? {
          value: Number(reg.kladr.slice(2, 5)),
          label: reg.district,
          code: reg.kladr.slice(2, 5),
        }
      : null,

    cityActualOption: actual?.kladr
      ? {
          value: Number(actual.kladr.slice(0, 13)),
          label: actual.city,
          code: actual.kladr.slice(0, 13),
        }
      : null,
    cityRegistrationOption: reg?.kladr
      ? {
          value: Number(reg.kladr.slice(0, 13)),
          label: reg.city,
          code: reg.kladr.slice(0, 13),
        }
      : null,

    streetActualOption: actual?.kladr
      ? {
          value: Number(actual.kladr.slice(0, 17)),
          label: actual.street,
          code: actual.kladr.slice(0, 17),
        }
      : null,
    streetRegistrationOption: reg?.kladr
      ? {
          value: Number(reg.kladr.slice(0, 17)),
          label: reg.street,
          code: reg.kladr.slice(0, 17),
        }
      : null,

    houseActualOption: actual?.kladr
      ? { value: Number(actual.kladr), label: actual.house, code: actual.kladr }
      : null,
    houseRegistrationOption: reg?.kladr
      ? { value: Number(reg.kladr), label: reg.house, code: reg.kladr }
      : null,

    nationalActualOption: extractNationalOption(actual?.nationality),
    nationalRegOption: extractNationalOption(reg?.nationality),
  }
}

export const selectPersonalFormData = (state: RootState): PersonalData | null => {
  const { data } = state.profile.entities.personalData
  if (!data) return null
  const ruTranslate = data.usersTranslate.find((t) => t.localeId === RU_LOCALE_ID)
  const enTranslate = data.usersTranslate.find((t) => t.localeId === EN_LOCALE_ID)
  const [national] = data.userNational
  return {
    needVisa: data?.needVisa ?? false,
    hasPhoto: false,
    nameRu: ruTranslate?.name,
    patronymicRu: ruTranslate?.patronymic,
    amountChild: data.amountChild,
    workId: data.workId,
    code: data.code,
    surnameRu: ruTranslate?.surname,
    nameEn: enTranslate?.name,
    patronymicEn: enTranslate?.patronymic,
    surnameEn: enTranslate?.surname,
    nationality: national?.nationalityId,
    dateBirthday: data.dateBirthday,
    gender: data.genderId,
    inRussia: data.inRussia,
    mobile:
      data.mobile && data.mobile.split(' ').length > 1
        ? data.mobile.split(' ')[1]
        : data.mobile,
    additionalMobile:
      data.additionalMobile && data.additionalMobile.split(' ').length > 1
        ? data.additionalMobile.split(' ')[1]
        : data.additionalMobile,
    dopEmail: data.dopEmail,
    methodApplicationId: data.methodApplicationId,
    snils: data.snils || null,
    dateSnils: data.dateSnils,
    noPatronymic: !ruTranslate?.patronymic,
    nationalOption: extractNationalOption(national?.nationality),
    noSnils: data.noSnils,
    noDateSnils: data.noSnils,
    phoneCode: null,
    additionalPhoneCode: null,
    phoneCodeOption: createPhoneCodeOption(data.mobile),
    additionalPhoneCodeOption: createAdditionalPhoneCodeOption(
      data.additionalMobile
    ),
    maritalStatusId: data?.maritalStatusId,
    partnerOrganizationEmail: data.partnerOrganizationEmail,
    partnerOrganizationCode: null,
    partnerOrganizationCodeOption: createPhoneCodeOption(
      data.partnerOrganizationPhone
    ),
    partnerOrganizationPhone:
      data.partnerOrganizationPhone &&
      data.partnerOrganizationPhone.split(' ').length > 1
        ? data.partnerOrganizationPhone.split(' ')[1]
        : data.partnerOrganizationPhone,
    recruitingCompany: data.recruitingCompaniesId,
    recruitingOption: extractPartnerOption(data.partner),
    trainingOpportunityId: data.trainingOpportunityId,
    otherTrainingOpportunity: data.otherTrainingOpportunity,
    lvlRusLangId: data.lvlRusLangId,
    studyRussianId: data.studyRussianId,
    otherStudyRussian: data.otherStudyRussian,
  }
}

export const selectRelationDegreesOptions = (state: RootState): SelectOption[] => {
  const {
    relationDegrees: { data },
  } = state.profile.entities
  if (!data) return []
  return data.map((r) => ({
    label: r.name,
    value: r.id,
  }))
}

export const selectTypeIdentityDocumentOptions = (
  state: RootState
): SelectOption[] => {
  const {
    typeIdentityDocuments: { data },
  } = state.profile.entities
  if (!data) return []
  return data.map((d) => {
    const [doc] = d.typeIdentityDocTranslate
    return {
      label: doc.name,
      value: doc.typeIdentityDocumentsId,
      code: d.code,
      id: d.id,
    }
  })
}

export const selectTypeIdentityDocumentOptionsRus = (
  state: RootState
): SelectOptionRus[] => {
  const {
    typeIdentityDocumentsRus: { data },
  } = state.profile.entities
  if (!data) return []

  return data.map((d) => {
    const [doc] = d.typeIdentityDocTranslate
    return {
      label: doc.name,
      value: doc.typeIdentityDocumentsId,
    }
  })
}

export const selectGendersOptions = (state: RootState): SelectOption[] => {
  const {
    genders: { data },
  } = state.profile.entities
  if (!data) return []
  return data.map((g) => {
    const [gender] = g.genderTranslate
    return {
      label: gender.name,
      value: gender.genderId,
    }
  })
}

export const selectWorksOptions = (state: RootState): SelectOption[] => {
  const {
    works: { data },
  } = state.profile.entities
  if (!data) return []
  return data.map((w) => {
    const [work] = w.translate
    return {
      label: work.name,
      value: work.id,
    }
  })
}

export const selectMethodApplicationOptions = (state: RootState): SelectOption[] => {
  const {
    methodApplication: { data },
  } = state.profile.entities
  if (!data) return []
  return data.map((m) => {
    const [method] = m.methodApplicationTranslate
    return {
      label: method.name,
      value: method.methodApplicationId,
    }
  })
}

export const selectMaritalStatusesOptions = (state: RootState): SelectOption[] => {
  const {
    maritalStatuses: { data },
  } = state.profile.entities
  if (!data) return []
  return data.map((s) => {
    const [status] = s.maritalStatusTranslate
    return {
      label: status.name,
      value: status.maritalStatusId,
    }
  })
}

export const selectLevelEducationOptions = (state: RootState): SelectOption[] => {
  const {
    levelEducation: { data },
  } = state.profile.entities
  if (!data) return []
  return data.map((n) => {
    const [level] = n.levelTranslate
    return {
      label: level.name,
      value: level.levelEducationId,
    }
  })
}

export const selectFormEducationOptions = (state: RootState): SelectOption[] => {
  const {
    formEducation: { data },
  } = state.profile.entities
  if (!data) return []
  return data.map((n) => {
    const [formEducation] = n.formEducationTranslate
    return {
      label: formEducation.name,
      value: formEducation.formEducationId,
    }
  })
}

export const selectPreviousEduForMasters = (state: RootState) => {
  const data = state.profile.entities.previousEduForMasters.data
  if (!data) return []
  return data.map((n) => {
    const [previosEduTranslate] = n.translation
    return {
      label: previosEduTranslate.name,
      value: previosEduTranslate.previousEduForMasterId,
    }
  })
}

export const selectVisa = (state: RootState): Visa | null => {
  const data = state.profile.entities.visa.data
  if (!data) return null
  return {
    ...data,
    studyVisa:
      typeof data.studyVisa === 'boolean' ? (data.studyVisa ? 'yes' : 'no') : null,
    cityVisaOption: extractVisaCityOption(data.cityVisa),
    countryVisaOption: extractVisaCountryOption(data.countryVisa),
    estimatedDate: data.estimatedDate,
    qa: data.qa ? JSON.parse(data.qa) : '',
    phd: data.phd,
    assistantship: data.assistantship,
  }
}

export const selectVisaCityOptions = (state: RootState) => {
  const {
    visaCities: { data },
  } = state.profile.entities
  if (!data) return []
  return data.map(extractVisaCityOption) as SelectOption[]
}

export const selectTypeDocsOptions = (state: RootState) => {
  const {
    typeDocs: { data },
  } = state.profile.entities
  return data
}

export const selectScenarioKeysOptions = (state: RootState) => {
  const {
    scenarioKeys: { data },
  } = state.profile.entities
  return data
}

export const selectLevelEducKeysOptions = (state: RootState) => {
  const {
    levelEducationKeys: { data },
  } = state.profile.entities
  return data
}

export const selectTypeEducationDocumentOptions = (
  state: RootState
): SelectOption[] => {
  const {
    typeDocEducation: { data },
  } = state.profile.entities
  if (!data) return []
  return data.map((n) => {
    const [typeIdentityDoc] = n.typeEduDocTranslate
    return {
      label: typeIdentityDoc.name,
      value: typeIdentityDoc.typeEduDocumentId,
    }
  })
}

export const selectViewerProfileEducation = (
  state: RootState
): EducationData | null => {
  const { data } = state.profile.entities.profileEducation
  if (!data || data.length === 0) return null
  const [edu] = data
  return {
    ...edu,
    nationalOption: extractNationalOption(edu.nationality),
    directionTrainingOption: extractDirectionTrainingOption(edu.directionTraining),

    poory:
      edu?.userScore?.find((el) => el?.score?.code === 'PR-03-01')?.count ||
      (edu?.userScore?.find((el) => el?.score?.code === 'PR-03-01')?.count === 0
        ? 0
        : undefined),
    okay:
      edu?.userScore?.find((el) => el?.score?.code === 'OK-04-02')?.count ||
      (edu?.userScore?.find((el) => el?.score?.code === 'OK-04-02')?.count === 0
        ? 0
        : undefined),
    great:
      edu?.userScore?.find((el) => el?.score?.code === 'GR-05-03')?.count ||
      (edu?.userScore?.find((el) => el?.score?.code === 'GR-05-03')?.count === 0
        ? 0
        : undefined),
    userScore: [
      {
        code: 'PR-03-01',
        count:
          edu?.userScore?.find((el) => el?.score?.code === 'PR-03-01')?.count || 0,
      },
      {
        code: 'OK-04-02',
        count:
          edu?.userScore?.find((el) => el?.score?.code === 'OK-04-02')?.count || 0,
      },
      {
        code: 'GR-05-03',
        count:
          edu?.userScore?.find((el) => el?.score?.code === 'GR-05-03')?.count || 0,
      },
    ],
  }
}

export const selectForeignLanguage = (state: RootState): SelectOption[] => {
  const { data } = state.profile.entities.foreignLanguage
  if (!data) return []
  return data.map((item) => {
    return {
      label: item.name,
      value: Number(item.studiedLanguesId),
    }
  })
}

function extractPhoneCodeOption(
  phoneCode?: PhoneCode | null
): (SelectOption & { chipLabel: string }) | undefined {
  if (!phoneCode) return

  const [phone] = phoneCode.phone
  const [translate] = phoneCode.translate
  return {
    label: phone.code + ' ' + translate.name,
    chipLabel: phone.code,
    value: phone.countryPhoneId,
  }
}

function extractNationalOption(
  nationality?: National | null
): SelectOption | undefined {
  if (!nationality) return

  const [translate] = nationality.nationalityTranslate
  return {
    label: translate?.name,
    value: translate?.nationalityId,
  }
}

function extractPartnerOption(partner?: any): SelectOption | undefined {
  if (!partner) return

  const [translate] = partner.partnerTranslate
  return {
    label: translate?.name,
    value: translate?.partnerId,
  }
}

function extractDirectionTrainingOption(
  directionTraining?: DirectionTraining | null
): SelectOption | undefined {
  if (!directionTraining) return

  const [translate] = directionTraining.translations
  return {
    label: translate?.name,
    value: translate?.directionTrainingId,
  }
}

function extractCountyOfBirthOption(
  countryOfBirth?: NationalOfBirth | null
): SelectOption | undefined {
  if (!countryOfBirth) return

  const [translate] = countryOfBirth.nationalityTranslate
  return {
    label: translate?.name,
    value: translate?.nationalityId,
  }
}

function extractPlaceOfBirthOption(
  placeBirthday?: PlaceOfBirth | null
): SelectOption | undefined {
  if (!placeBirthday) return

  const [translate] = placeBirthday.nationalityTranslate
  return {
    label: translate?.name,
    value: translate?.nationalityId,
  }
}

function extractLocationOption(
  locationsElement?: LocationsElement | null
): SelectOption | undefined {
  if (!locationsElement) return
  return {
    label: locationsElement.name,
    value: locationsElement.id,
  }
}

function extractSubjectRussiaOption(
  subjectRussia?: SubjectRussia | null
): SelectOption | undefined {
  if (!subjectRussia) return

  const [translate] = subjectRussia.translates

  return {
    label: translate.name,
    value: translate.subjectsRussiaId,
  }
}

function createPhoneCodeOption(mobile?: string) {
  if (!mobile) return

  if (mobile.split(' ').length <= 1) return

  const code = mobile.split(' ')[0]
  return {
    chipLabel: code,
    label: code,
    value: -1,
  }
}

function createAdditionalPhoneCodeOption(additionalMobile?: string) {
  if (!additionalMobile) return

  if (additionalMobile.split(' ').length <= 1) return

  const code = additionalMobile.split(' ')[0]
  return {
    chipLabel: code,
    label: code,
    value: -1,
  }
}

function extractKladrOption(
  kladrElement?: {
    main: {
      name: string
      distinctName: string
      regionName: string
    }
    street: {
      name: string
    }
    building: {
      code: string
      building: string
      zip: string
    }
  } | null
):
  | (SelectCodeOption & {
      region: string
      district: string
      city: string
      street: string
      building: string
      zip: string
    })
  | undefined {
  if (!kladrElement) return

  return {
    label: `${kladrElement?.main?.regionName ?? ''}, ${
      (kladrElement?.main?.regionName !== kladrElement?.main?.distinctName &&
        kladrElement?.main?.distinctName + ', ') ||
      ''
    }${
      (kladrElement?.main?.name !== kladrElement?.main?.distinctName &&
        kladrElement?.main?.name + ', ') ||
      ''
    }${kladrElement?.street?.name ?? ''}, ${kladrElement?.building?.building ?? ''}`,
    value: Number(kladrElement?.building?.code) || 0,
    code: kladrElement?.building?.code || '',
    region: kladrElement?.main?.regionName ?? '',
    district: kladrElement?.main?.distinctName ?? '',
    city: kladrElement?.main?.name ?? '',
    street: kladrElement?.street?.name ?? '',
    building: kladrElement?.building?.building ?? '',
    zip: kladrElement?.building?.zip ?? '',
  }
}

function extractKladrRegionOption(
  kladrElement: {
    name: string
    region: string
    type: string
  } | null
): SelectCodeOption | undefined {
  if (!kladrElement) return

  return {
    label: `${kladrElement.type} ${kladrElement.name}`,
    value: Number(kladrElement?.region) || 0,
    code: kladrElement?.region || '',
  }
}

function extractKladrDistrictOption(
  kladrElement?: {
    distinct: string
    name: string
    region: string
    type: string
  } | null
): SelectCodeOption | undefined {
  if (!kladrElement) return

  return {
    label: kladrElement.name,
    value: Number(kladrElement.distinct),
    code: kladrElement.distinct,
  }
}

function extractKladrCityOption(
  kladrElement?: {
    localityCode: string
    name: string
    type: string
  } | null
): SelectCodeOption | undefined {
  if (!kladrElement) return

  return {
    label: `${kladrElement.type} ${kladrElement.name}`,
    value: Number(kladrElement.localityCode),
    code: kladrElement.localityCode,
  }
}

function extractVisaCityOption(
  visaCity?: VisaCity | null
): SelectOption | undefined {
  if (!visaCity) return

  const [translate] = visaCity.cityTranslate
  return {
    label: translate.name,
    value: translate.cityVisaId,
  }
}

function extractVisaCountryOption(
  visaCountry?: VisaCountry | null
): SelectOption | undefined {
  if (!visaCountry) return

  const [translate] = visaCountry.countryTranslate
  return {
    label: translate.name,
    value: translate.countryVisaId,
  }
}

function extractKladrStreetOption(
  kladrElement?: {
    streetCode: string
    name: string
    type: string
  } | null
): SelectCodeOption | undefined {
  if (!kladrElement) return

  return {
    label: kladrElement.name,
    value: Number(kladrElement.streetCode),
    code: kladrElement.streetCode,
  }
}

function extractKladrHouseOption(
  kladrElement?: {
    code: string
    building: string
    type: string
    zip: string
  } | null
): (SelectCodeOption & { zip: string }) | undefined {
  if (!kladrElement) return

  return {
    label: kladrElement.building,
    value: Number(kladrElement.code),
    code: kladrElement.code,
    zip: kladrElement.zip,
  }
}

function extractPartnersOption(partners?: Partner | null): SelectOption | undefined {
  if (!partners) return

  const [translate] = partners.partnerTranslate
  return {
    label: translate?.name,
    value: translate?.partnerId,
  }
}

export const selectPartnersLoading = (state: RootState) => {
  const { nationals } = state.profile.entities
  return nationals.status === 'loading'
}

export const selectPartnersOptions = (
  state: RootState
): SelectPaginatedEntityResult => {
  const { partners } = state.profile.entities
  return {
    options: partners.data.map(extractPartnersOption),
    nextPage: partners.nextPage,
  }
}

export const selectPartnersList = (state: RootState): SelectOption[] => {
  const { partners } = state.profile.entities

  if (!partners || !partners.data.length) return []

  return partners.data.map((partner) => {
    const [translate] = partner.partnerTranslate

    return {
      value: partner.id,
      label: translate.name,
    }
  })
}
