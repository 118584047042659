import { Viewer } from '@models'
import { StateStatus } from '@redux/types'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import api from '@services/api'

type ViewerInitialState = Viewer & {
  status: StateStatus
}

const initialState: ViewerInitialState = {
  token: null,
  profileData: null,
  profileImageBase64: null,
  authPictureBase64: null,
  status: 'idle',
}

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload
    },

    clearToken(state) {
      state.token = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getViewerProfile.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(getViewerProfile.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.profileData = action.payload
      })
      .addCase(getViewerProfile.rejected, (state) => {
        state.status = 'failed'
        state.profileData = null
      })

    builder
      .addCase(getViewerProfileImage.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.profileImageBase64 = action.payload.photo
      })
      .addCase(getViewerProfileImage.rejected, (state) => {
        state.status = 'failed'
        state.profileImageBase64 = null
      })
  },
})

export const getViewerProfile = createAsyncThunk('viewer/profileData', async () => {
  try {
    const response = await api.client.getProfile()
    return response.data
  } catch (e) {
    function isError(error: any): error is Error {
      if (error.status === 401) {
        localStorage.removeItem('auth:token')
        location.href = '/rus-sign-in'
      }
      return error
    }
    isError(e)
  }
  return null
})

export const getViewerProfileImage = createAsyncThunk(
  'viewer/profilePhoto',
  async () => {
    const response = await api.client.getViewerProfilePhoto()
    return response.data
  }
)

export const getViewerAuthPicture = createAsyncThunk(
  'viewer/profilePhoto',
  async () => {
    const response = await api.client.getViewerAuthPicture()
    return response.data
  }
)

export default viewerSlice.reducer
