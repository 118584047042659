import { RootState } from '../../types'
import {
  IRowsTable,
  LgotaDocs,
  PreemptiveRightResponse,
  SelectCodeOption,
  SpecialCondition,
} from '@models'

export const selectLgotaTypeOptions = (state: RootState): SelectCodeOption[] => {
  const { data } = state.lgota.typeLgota
  if (!data) return []
  return data.map((dl) => {
    const lgota = dl.lgotaTranslate[0]
    return {
      label: lgota.name,
      code: dl.code,
      value: lgota.lgotaId,
    }
  })
}

export const selectPreemptiveTypeOptions = (
  state: RootState
): SelectCodeOption[] => {
  const { data } = state.lgota.typePreemptive
  if (!data) return []
  return data.map((dl) => {
    const lgota = dl.preemptiveRightTranslate[0]
    return {
      label: lgota.name,
      code: dl.code,
      value: lgota.preemptiveRightId,
    }
  })
}

export const getAllLgota = (state: RootState): LgotaDocs[] => {
  if (!state.lgota.docs.data) return []
  return state.lgota.docs.data.lgota
}

export const selectSpecialCondition = (state: RootState): SpecialCondition[] => {
  if (!state.lgota.docs.data) return []
  return state.lgota.docs.data.specialCondition
}

export const getAllPreemptive = (state: RootState): PreemptiveRightResponse[] => {
  if (!state.lgota.docs.data) return []
  return state.lgota.docs.data.preemptiveRight
}

export const selectUploadedLgotaDocsState = (state: RootState) =>
  state.lgota.uploadedLgotaDocsState

export const listLgotaDoc = (state: RootState): IRowsTable[] => {
  const { data } = state.lgota.docs
  if (!data?.lgota) return []
  return data.lgota.map((el) => {
    const lgota = el.lgota.lgotaTranslate[0]
    const doc = el.lgotaDoc.lgotaDocTranslate[0]
    return {
      id: el.id,
      typeLgota: lgota.name,
      typeDoc: doc.name,
      docId: el.docId,
      number: el.number,
      dateIssue: el.dateIssue,
    }
  })
}

export const listPreemptiveRight = (state: RootState): IRowsTable[] => {
  const { data } = state.lgota.docs
  if (!data?.preemptiveRight) return []
  return data.preemptiveRight.map((el) => {
    const preemptive = el.preemptiveRight.preemptiveRightTranslate[0]
    const doc = el.preemptiveRightDoc.preemptiveRightDocTranslate[0]
    return {
      id: el.id,
      typeLgota: preemptive.name,
      typeDoc: doc.name,
      docId: el.docId,
      number: el.number,
      dateIssue: el.dateIssue,
    }
  })
}

export const selectLgotaDocTypeOptions = (state: RootState): SelectCodeOption[] => {
  const { data } = state.lgota.typeLgotaDocs
  if (!data) return []
  return data.map((dl) => {
    const lgota = dl.lgotaDocTranslate[0]
    return {
      label: lgota.name,
      code: dl.code,
      value: lgota.lgotaDocId,
    }
  })
}

export const selectPreemptiveDocTypeOptions = (
  state: RootState
): SelectCodeOption[] => {
  const { data } = state.lgota.typePreemptiveDocs
  if (!data) return []
  return data.map((dl) => {
    const lgota = dl.preemptiveRightDocTranslate[0]
    return {
      label: lgota.name,
      code: dl.code,
      value: lgota.preemptiveRightDocId,
    }
  })
}
