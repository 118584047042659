import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import ModeratorFile from '@components/ModeratorFile'
import ProfileSection from '@components/ProfileSection'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { getModeratorStatementsDocumentsData } from '@redux/action-types'
import { selectModeratorStatementsDocumentsData } from '@redux/actions/selectors'
import Loader from '@ui/Loader'
import { base64toFile, downloadFile } from '@utils/common'

const ModeratorDocumentsPage = ({ params }) => {
  const dispatch = useAppDispatch()

  const { id, userId } = useParams<{ id?: string; userId?: string }>()
  useEffect(() => {
    if (id || userId) {
      dispatch(getModeratorStatementsDocumentsData({ id: id, userId: userId }))
    }
  }, [id, userId])

  const documents = useAppSelector(selectModeratorStatementsDocumentsData)

  if (!documents) {
    return <Loader />
  }

  return (
    <div className={'moderator-documents-page'}>
      {documents.documents.length !== 0 &&
        params.map((param) =>
          documents.documents.map((file, index) => {
            if (param.typeDoc === file.docType) {
              return (
                <ProfileSection title={param.title} key={index}>
                  <ModeratorFile
                    title={file.name}
                    content={file.createdAt}
                    upload={() => {
                      base64toFile(
                        file.file || '',
                        'application/pdf',
                        'pdf',
                        file.name
                      ).then(downloadFile)
                    }}
                  />
                </ProfileSection>
              )
            }
          })
        )}
    </div>
  )
}

export default ModeratorDocumentsPage
