import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ModeratorDataList from '@components/ModeratorDataList'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { DataList } from '@models'
import { getModeratorStatementsPersonalData } from '@redux/action-types'
import { selectModeratorStatementsPersonalData } from '@redux/actions/selectors'

const ModeratorEducationPage = () => {
  const dispatch = useAppDispatch()

  const { id } = useParams<{ id?: string }>()
  useEffect(() => {
    if (id) {
      dispatch(getModeratorStatementsPersonalData(id))
    }
  }, [id])

  const statement = useAppSelector(selectModeratorStatementsPersonalData) || {}

  const list: DataList = [
    // {
    //   title: 'Как Вы узнали о возможности обучения в СПбГУ:',
    //   label:
    //     statement?.users?.trainingOpportunity?.trainingOpportunityTranslate[0]
    //       ?.name || '-',
    // },
    // {
    //   title: 'Как Вы узнали о возможности обучения в СПбГУ:',
    //   label: statement?.users?.otherTrainingOpportunity || '-',
    // },
    // {
    //   title: 'Оцените Ваш уровень владения русским языком:',
    //   label: statement?.users?.lvlRusLang?.lvlRusLangTranslate[0]?.name || '-',
    // },
    // {
    //   title: 'Где Вы изучали русский язык:',
    //   label: statement?.users?.studyRussian?.studyRussianTranslate[0]?.name || '-',
    // },
    // {
    //   title: 'Где Вы изучали русский язык:',
    //   label: statement?.users?.otherStudyRussian || '-',
    // },
    {
      title: 'В этом разделе не предусмотрено никаких данных',
      label: '',
    },
  ]
  return (
    <div className="moderator__education-page">
      <ModeratorDataList list={list} />
    </div>
  )
}

export default ModeratorEducationPage
