import getLocale from './uselocale'

export const useCode = (location) => {
  let cod

  switch (location.pathname) {
    case '/sign-in':
      cod = 'RF'
      break
    case '/sign-up':
      cod = 'RF'
      break
    case '/rus-sign-in':
      cod = 'RF'
      break
    case '/rus-sign-up':
      cod = 'RF'
      break
    case '/ino-sign-in':
      cod = 'IN'
      break
    case '/ino-sign-up':
      cod = 'IN'
      break
    case '/asp-sign-in':
      cod = 'AS'
      break
    case '/asp-sign-up':
      cod = 'AS'
      break
    default:
      cod = 'code=RF_01'
      break
  }

  let Locale = getLocale
  let lang

  switch (Locale) {
    case 'ru':
      lang = '01'
      break
    case 'en':
      lang = '02'
      break
    default:
      lang = '01'
      break
  }

  return 'code=' + cod + '_' + lang
}
