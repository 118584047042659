import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import { ViewerTargetContractForm } from '@models'
import { createToast, getPlaceEmploymentsList } from '@redux/action-types'
import {
  selectPlaceEmploymentsList,
  selectStatementsElement,
  selectTargetContract,
} from '@redux/actions/selectors'
import api, {
  AddLgotaEntrantsResponse,
  ResponseOk,
  UploadDocsRespOk,
  UploadDocsResponse,
} from '@services/api'

const useTargetAgreementForm = () => {
  const dispatch = useAppDispatch()

  const {
    register,
    setError,
    clearErrors,
    watch,
    control,
    handleSubmit,
    setValue,
    reset,
    ...rest
  } = useForm<ViewerTargetContractForm>({ mode: 'all' })

  const [loading, setLoading] = useState(false)

  const statement = useAppSelector(selectStatementsElement)
  const targetContract = useAppSelector(selectTargetContract)

  const { onNextPageNavigate } = useFillingStatementsRoutesContext()

  const placeEmploymentsList = useAppSelector(selectPlaceEmploymentsList)

  const noTarget = Boolean(watch('noTarget'))

  const fields = {
    noTarget: register('noTarget', {
      onChange: ({ target: { checked } }) => {
        if (checked) {
          reset()
          clearErrors()
          setValue('noTarget', true)
        }
      },
    }),

    // Наименование заказчика целевого обучения
    customerName: register('customerName', {
      required: { value: !noTarget, message: terms.REQUIRED_FIELD },
    }),
    // Идентификационный номер предложения заказчика о заключении договора на платформе «Работа России»
    idNumberWorkInRussia: register('idNumberWorkInRussia', {
      required: { value: !noTarget, message: terms.REQUIRED_FIELD },
    }),

    documents: register('documents', {
      required: {
        value: !noTarget,
        message: terms.REQUIRED_FIELD,
      },
    }),
  }

  const onSubmit = handleSubmit((data: any) => {
    if (!statement) {
      return
    }

    const normalizedData: ViewerTargetContractForm = {
      ...data,
      statementId: statement.id,
    }

    //догоовра не было, и не добавили
    if (!targetContract && noTarget) {
      onNextPageNavigate()
      return
    }

    // договор был, изменили
    if (targetContract && !noTarget) {
      let status = false
      api.client
        .uploadDocs({
          documents: normalizedData.documents,
          typeDoc: 'TARGET_CONTRACT',
          docId: normalizedData.userDocId,
        })
        .then(() => {
          status = true
        })
        .catch((response: Exclude<UploadDocsResponse, UploadDocsRespOk>) => {
          const { status } = response
          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
        .finally(() => {
          if (status) {
            api.client
              .putTargetContract(normalizedData)
              .then((response) => {
                const { data } = response as unknown as ResponseOk
                dispatch(createToast(data.message, 'success'))
                onNextPageNavigate()
              })
              .catch((response: Exclude<AddLgotaEntrantsResponse, ResponseOk>) => {
                const { status } = response
                if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
                  const { data } = response
                  Object.keys(data.errors).forEach((key) => {
                    setError(key as keyof ViewerTargetContractForm, {
                      type: 'manual',
                      message: data.errors[key][0],
                    })
                  })
                  dispatch(createToast(data.message, 'danger'))
                } else {
                  dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
                }
              })
              .finally(() => setLoading(false))
          }
        })
    }

    //догоовра был, удалили
    if (targetContract && noTarget) {
      api.client
        .deleteTargetContract(statement.id)
        .then((response) => {
          const { data } = response as unknown as ResponseOk
          dispatch(createToast(data.message, 'success'))
          onNextPageNavigate()
        })
        .catch((response: Exclude<AddLgotaEntrantsResponse, ResponseOk>) => {
          const { status } = response
          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            Object.keys(data.errors).forEach((key) => {
              setError(key as keyof ViewerTargetContractForm, {
                type: 'manual',
                message: data.errors[key][0],
              })
            })
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
    }

    //догоовра не было, добавили
    if (!targetContract && !noTarget) {
      api.client
        .postTargetContract(normalizedData)
        .then((response) => {
          const { data } = response as unknown as ResponseOk
          dispatch(createToast(data.message, 'success'))
          onNextPageNavigate()
        })
        .catch((response: Exclude<AddLgotaEntrantsResponse, ResponseOk>) => {
          const { status } = response
          if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
            const { data } = response
            Object.keys(data.errors).forEach((key) => {
              setError(key as keyof ViewerTargetContractForm, {
                type: 'manual',
                message: data.errors[key][0],
              })
            })
            dispatch(createToast(data.message, 'danger'))
          } else {
            dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
          }
        })
    }
  })

  useEffect(() => {
    !placeEmploymentsList?.length && dispatch(getPlaceEmploymentsList())
  }, [])

  return {
    loading,
    noTarget,
    control,
    fields,
    clearErrors,
    onSubmit,
    setValue,
    watch,
    placeEmploymentsList,
    statement,
    ...rest,
  }
}

export default useTargetAgreementForm
