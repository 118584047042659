import React from 'react'

import PersonalDataForm from '@components/PersonalDataForm'

const PersonalDataPage = () => {
  return (
    <div>
      <PersonalDataForm />
    </div>
  )
}

export default PersonalDataPage
