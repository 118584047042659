import React from 'react'

import ModeratorContractFilter from '../../../components/ModeratorContractFilter'
import ModeratorContractList from '../../../components/ModeratorContractList'
import AccordionTab from '@components/AccordionTab'

const ModeratorContractPage = () => {
  return (
    <main className="moderator-contract-page">
      <h1 className="moderator-contract-page__title">Договоры</h1>
      <AccordionTab title={'Фильтры'}>
        <ModeratorContractFilter />
      </AccordionTab>
      <ModeratorContractList />
    </main>
  )
}

export default ModeratorContractPage
