import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'

import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useFillingStatementsApiHandling from '@hooks/useFillingStatementsApiHandling'
import useResultExamFormInitialization from '@hooks/useResultExamFormInitialization'
import { ViewerResultExamForm, ViewerResultExamFormData } from '@models'
import { createToast, getTabEge, getTabEgeV2 } from '@redux/action-types'
import {
  selectStatementsElement,
  selectTabEgeList,
  selectTabEgeListV2,
} from '@redux/actions/selectors'
import api, { ResponseOk, UpdateEgeTabResponse } from '@services/api'
import { values } from 'pusher-js/types/src/core/utils/collections'

const useResultExamForm = (passportIds) => {
  const { register, watch, control, handleSubmit, getValues, setValue, ...rest } =
    useForm<ViewerResultExamFormData>({ mode: 'all' })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'resultEge',
  })

  const {
    fields: fieldsPassports,
    append: appendPassports,
    remove: removePassports,
  } = useFieldArray({
    control,
    name: 'passports',
  })

  const { personData } = useResultExamFormInitialization({
    remove,
    append,
    setValue,
    appendPassports,
    removePassports,
  })

  const dispatch = useAppDispatch()
  const noEge = Boolean(watch('noEge'))
  const takeEge = Boolean(watch('takeEge'))
  // @ts-ignore
  const resultCentralExam = Boolean(watch('resultCentralExam'))
  // @ts-ignore
  const resultCentralTesting = Boolean(watch('resultCentralTesting'))
  const anotherPassport = Boolean(watch('anotherPassport'))
  const typeDocId = watch('typeIdentityDocumentId')
  const egesDocs = useAppSelector(selectTabEgeList)

  const isSeriesRequired =
    typeDocId?.value === passportIds.russia_citizen_passport_code.tdId

  const [checkLoading, setCheckLoading] = useState(false)
  const fieldsForm = {
    noEge: register('noEge'),

    takeEge: register('takeEge', {
      onChange: (e) => {
        const { checked } = e.target
        setValue('takeEge', checked)
      },
    }),
    centralizedExam: register('centralizedExam', {
      onChange: (e) => {
        const { checked } = e.target
        setValue('centralizedExam', checked)
      },
    }),
    centralizedTest: register('centralizedTest', {
      onChange: (e) => {
        const { checked } = e.target
        setValue('centralizedTest', checked)
      },
    }),

    anotherPassport: register('anotherPassport', {
      onChange: (e) => {
        const { checked } = e.target
        if (!checked) {
          setValue('passports', [])
        }

        setValue('anotherPassport', checked)
      },
    }),
  }
  const statement = useAppSelector(selectStatementsElement)
  useEffect(() => {
    //@ts-ignore
    if (egesDocs?.personalData && egesDocs?.personalData.length != 0) {
      setValue('anotherPassport', true)
    }
  }, [egesDocs?.personalData])
  const removeElement = (id) => {
    if (!statement) {
      return
    }
    api.client
      .deleteTabEge({
        statementId: statement.id,
        disciplineEgeId: id.value,
      })
      .then((response) => {
        const { data } = response as ResponseOk
        dispatch(createToast(data.message, 'success'))
        dispatch(getTabEge(statement.id))
      })
      .catch((response: Exclude<UpdateEgeTabResponse, ResponseOk>) => {
        const { status } = response
        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
  }

  const { callApiV2, loading } = useFillingStatementsApiHandling({
    apiFn: api.client.addTabEgeV2,
    onSuccessCb: () => {
      if (!statement) {
        return
      }
      dispatch(getTabEge(statement.id))
    },
  })

  const checkResult = handleSubmit((data: any) => {
    if (!statement) {
      return
    }

    const normalizedData: ViewerResultExamForm = {
      ...data,
      statementId: statement.id,
      typeIdentityDocumentId: data.typeIdentityDocumentId?.value || undefined,
      centralizedExam: data.resultCentralExam,
      centralizedTest: data.resultCentralTesting,
      resultEge: [
        ...data.resultEge.map((item) => {
          return {
            disciplineEgeId: item.disciplineEgeId.value,
            year: item.year?.label,
            result: item.result,
            // score: item.score || '0',
            // centralizedTesting: item.centralizedTesting?.value === 1,
            resultCentralExam: item.resultCentralExam,
            resultCentralTesting: item.resultCentralTesting,
            verified: false,
            doc_id: item.doc_id,
            doc: item.doc,
          }
        }),
      ],
    }

    setCheckLoading(true)
    let status = false
    api.client
      .addTabEge(normalizedData)
      .then(() => {
        status = true
      })
      .catch((response: Exclude<UpdateEgeTabResponse, ResponseOk>) => {
        const { status } = response
        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
      })
      .finally(() => {
        if (status) {
          api.client
            .checkEge(statement.id)
            .then((response) => {
              const { data } = response as ResponseOk
              dispatch(createToast(data.message, 'success'))
              dispatch(getTabEge(statement.id))
            })
            .catch((response: Exclude<UpdateEgeTabResponse, ResponseOk>) => {
              const { status } = response
              if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
                const { data } = response
                dispatch(createToast(data.message, 'danger'))
              } else {
                dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
              }
            })
            .finally(() => setCheckLoading(false))
        } else {
          setCheckLoading(false)
        }
      })
  })

  const onSubmit = handleSubmit((data: any) => {
    if (!statement) {
      return
    }

    if (watch('noEge')) {
      setValue('resultEge', [])
      setValue('serial', undefined)
      setValue('number', undefined)
      setValue('patronymic', undefined)
      setValue('name', undefined)
      setValue('surname', undefined)
      setValue('anotherPassport', undefined)
      setValue('noEge', false)
      setValue('centralizedExam', false)
      setValue('centralizedTest', false)
      setValue('dateOfIssue', '')
      setValue('typeIdentityDocumentId', null)
      setValue('passports', [])
    } else {
      if (watch('anotherPassport') === undefined) {
        setValue('anotherPassport', false)
      }
    }

    const normalizedData: ViewerResultExamForm = {
      ...data,
      statementId: statement.id,
      typeIdentityDocumentId: data.typeIdentityDocumentId?.value || undefined,
      resultCentralExam: data.centralizedExam,
      resultCentralTesting: data.centralizedTesting,
      resultEge: [
        ...data.resultEge.map((item) => {
          return {
            disciplineEgeId: item.disciplineEgeId.value,
            year: item.year?.label,
            result: item.result,
            // score: item.score || '0',
            // centralizedTesting: item.centralizedTesting?.value === 1,
            resultCentralExam: item.resultCentralExam,
            resultCentralTesting: item.resultCentralTesting,
            verified: false,
            doc_id: item.doc_id,
            doc: item.doc,
          }
        }),
      ],
    }

    callApiV2(normalizedData)
  })

  return {
    fieldsPassports,
    appendPassports,
    removePassports,
    fieldsForm,
    fields,
    control,
    checkLoading,
    checkResult,
    onSubmit,
    setValue,
    getValues,
    watch,
    loading,
    append,
    removeElement,
    remove,
    noEge,
    takeEge,
    anotherPassport,
    register,
    isSeriesRequired,
    resultCentralExam,
    resultCentralTesting,
    ...rest,
  }
}

export default useResultExamForm
