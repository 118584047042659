import axios, { AxiosResponse } from 'axios'

type params = {
  model: string
  unique_keys: number[]
}
export default {
  /**@method GET*/
  getIdentityDocKey: (params: params) => {
    return axios.get<any>('/identity-doc-keys', { params }).then((res) => res.data)
  },

  getScenarioKey: (params: string) => {
    return axios.get<any>('/scenarios-keys', { params }).then((res) => res.data)
  },

  getLevelEducationKeys: (params: string) => {
    return axios.get<any>('/level-educ-keys', { params }).then((res) => res.data)
  },
}
