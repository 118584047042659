import { useForm } from 'react-hook-form'

import { AuthSettingEdit } from '../models/settings'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import terms from '@common/terms'
import useSettingFormInitialization from '@hooks/useSettingFormInitialization'
import { createToast, getPersonalData, hideModal } from '@redux/action-types'
import { getAuthPagesList, getDirectoryInfo } from '@redux/action-types/admin'

import api, { ResponseOk } from '@services/api'

const useAddSettingAdminModal = () => {
  const { register, handleSubmit, setError, control, reset, setValue, watch } =
    useForm<AuthSettingEdit>({ mode: 'all' })

  const dispatch = useAppDispatch()

  const updateAuthPage = (data) => {
    api.client
      .getUpdateAuthPage(data)
      .then((response) => {
        const { data } = response as unknown as ResponseOk
        dispatch(createToast(data.message, 'success'))
        dispatch(getAuthPagesList())
        dispatch(hideModal())
      })
      .catch(() => {
        dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        hideModal()
      })
  }

  const fields = {
    name: register('name', { required: terms.REQUIRED_FIELD }),
    textPicture: register('textPicture', { required: terms.REQUIRED_FIELD }),
    textLogo: register('textLogo', { required: terms.REQUIRED_FIELD }),
    textPictureLink: register('textPictureLink', { required: terms.REQUIRED_FIELD }),
    link: register('link', { required: terms.REQUIRED_FIELD }),
    titleLogin: register('titleLogin', { required: terms.REQUIRED_FIELD }),
    titleRegister: register('titleRegister', { required: terms.REQUIRED_FIELD }),
    leftSideText: register('leftSideText', { required: terms.REQUIRED_FIELD }),
    leftTextTitle: register('leftTextTitle', { required: terms.REQUIRED_FIELD }),
    rightSideText: register('rightSideText', { required: terms.REQUIRED_FIELD }),
    rightTextTitle: register('rightTextTitle', { required: terms.REQUIRED_FIELD }),
    id: register('id'),
  }

  const onSubmit = handleSubmit((data: any) => {
    const normalizedData: AuthSettingEdit = {
      ...data,
      name: data.name,
      text_logo: data.textLogo,
      text_picture: data.textPicture,
      text_picture_link: data.textPictureLink,
      link: data.link,
      title_login: data.titleLogin,
      title_register: data.titleRegister,
      left_side_text: data.leftSideText,
      left_text_title: data.leftTextTitle,
      right_side_text: data.rightSideText,
      right_text_title: data.rightTextTitle,
      id: data.id,
    }
    updateAuthPage(normalizedData)
  })

  return {
    watch,
    setValue,
    onSubmit,
    fields,
  }
}

export default useAddSettingAdminModal
