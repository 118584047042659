import React, { useEffect, useState } from 'react'

import IsDeleteApplication from 'modals/IsDeleteApplication'

import useAppSelector from '@hooks/useAppSelector'
import AddDictionaryAdminModal from '@modals/AddDictionaryAdminModal'
import AddFileModal from '@modals/AddFileModal'
import AddIndividualAchivementModal from '@modals/AddIndividualAchivementModal'
import AddOlympiadModal from '@modals/AddOlympiadModal'
import AddPreemptiveRightModal from '@modals/AddPreemptiveRightModal'
import AddPrivilegesModal from '@modals/AddPrivilegesModal'
import AddSettingAuthAdminModal from '@modals/AddSettingAuthAdminModal'
import AddUserAdminModal from '@modals/AddUserAdminModal'
import ChangePasswordModal from '@modals/ChangePasswordModal'
import DirectionsModal from '@modals/DirectionsModal'
import EditAuthImageModal from '@modals/EditAuthImageModal'
import EditAuthLogoModal from '@modals/EditAuthLogoModal'
import EditProfileImageModal from '@modals/EditProfileImageModal'
import ExchangeAdminModal from '@modals/ExchangeAdminModal'
import IsDeleteApplicationAdmission from '@modals/IsDeleteApplicationAdmission'
import WithdrawModal from '@modals/WithdrawModal'
import { modalNameSelector } from '@redux/actions/selectors'
import { getScrollbarWidth } from '@utils/common'
import useAppDispatch from '@hooks/useAppDispatch'
import {
  ASPERATNUTA_RF,
  BACHELOR_INO,
  BACHELOR_RF,
  BACHELOR_TASHKENT,
  MAGISTRA_INO,
  MAGISTRA_RF,
  MAGISTRA_TASHKENT,
  QUOTA_ID,
  SPO_ID,
  TRAINING_PREPARATION,
} from '@common/constants'
import { getScenarioKeys } from '@redux/action-types'

const ModalLayer = () => {
  const modalName = useAppSelector(modalNameSelector)

  useEffect(() => {
    if (modalName) {
      document.documentElement.style.maxHeight = '100vh'
      document.documentElement.style.overflow = 'hidden'
      document.documentElement.style.marginRight = getScrollbarWidth() + 'px'
    } else {
      document.documentElement.style.maxHeight = 'unset'
      document.documentElement.style.overflow = 'unset'
      document.documentElement.style.marginRight = 'auto'
    }
  }, [modalName])

  const [keys, setKeys] = useState()
  const dispatch = useAppDispatch()
  let sKeys
  useEffect(() => {
    dispatch(getScenarioKeys('Scenario')).then((res) => setKeys(res.payload))
  }, [])
  if (!keys) {
    return <div></div>
  }
  sKeys = keys
  /**
   *  интеграция кодов сценариев с таблицы service_keys
   *  где хранятся соответствия между захардкжеными кодами на сайте и кодами сценариев с 1с
   */
  let scenarioKeys = {
    level_training_spo: sKeys.find((value) => value.uniqueKeyOnSite == SPO_ID),
    level_training_preparation: sKeys.find(
      (value) => value.uniqueKeyOnSite == TRAINING_PREPARATION
    ),
    level_training_bachelor: sKeys.find(
      (value) => value.uniqueKeyOnSite == BACHELOR_RF
    ),
    level_training_bachelor_ino: sKeys.find(
      (value) => value.uniqueKeyOnSite == BACHELOR_INO
    ),
    level_training_bachelor_tashkent: sKeys.find(
      (value) => value.uniqueKeyOnSite == BACHELOR_TASHKENT
    ),
    magistra_tashkent: sKeys.find(
      (value) => value.uniqueKeyOnSite == MAGISTRA_TASHKENT
    ),
    magistra_rf: sKeys.find((value) => value.uniqueKeyOnSite == MAGISTRA_RF),
    magistra_ino: sKeys.find((value) => value.uniqueKeyOnSite == MAGISTRA_INO),
    asperatnuta_rf: sKeys.find((value) => value.uniqueKeyOnSite == ASPERATNUTA_RF),
    quota: sKeys.find((value) => value.uniqueKeyOnSite == QUOTA_ID),
  }
  switch (modalName) {
    case 'CHANGE_PASSWORD_MODAL':
      return <ChangePasswordModal />

    case 'EDIT_PROFILE_IMAGE_MODAL':
      return <EditProfileImageModal />

    case 'EDIT_AUTH_IMAGE_MODAL':
      return <EditAuthImageModal />

    case 'EDIT_AUTH_LOGO_MODAL':
      return <EditAuthLogoModal />

    case 'ADD_INDIVIDUAL_ACHIVEMENT':
      return <AddIndividualAchivementModal />

    case 'ADD_OLYMPIAD_MODAL':
      return <AddOlympiadModal {...scenarioKeys} />

    case 'PRIVILEGES':
      return <AddPrivilegesModal />

    case 'PREEMPTIVE':
      return <AddPreemptiveRightModal />

    case 'ADD_FILE_MODAL':
      return <AddFileModal />

    case 'EDIT_FILE_MODAL':
      return <AddFileModal />

    case 'ADMIN_USER':
      return <AddUserAdminModal />

    case 'ADMIN_ADD_DICTIONARY':
      return <AddDictionaryAdminModal />

    case 'ADMIN_EDIT_AUTH_SETTING':
      return <AddSettingAuthAdminModal />

    case 'EXCHANGE_MODAL':
      return <ExchangeAdminModal />

    case 'DIRECTIONS_MODAL':
      return <DirectionsModal />

    case 'WITHDRAW_MODAL':
      return <WithdrawModal />

    case 'IS_DELETE_APPLICATION_ADMISSION':
      return <IsDeleteApplicationAdmission />

    case 'IS_DELETE_APPLICATION':
      return <IsDeleteApplication />

    default:
      return null
  }
}

export default ModalLayer
