import { useEffect, useState } from 'react'

import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { createToast, getStatementsList, showModal } from '@redux/action-types'
import {
  selectAdditionalFiles,
  selectStatementsList,
} from '@redux/actions/selectors'
import { getAdditionalDocs } from '@redux/reducers'
import api from '@services/api'
import { base64toFile, downloadFile } from '@utils/common'

const useAdditionalFiles = () => {
  const dispatch = useAppDispatch()
  const files = useAppSelector(selectAdditionalFiles)

  const statementsList = useAppSelector(selectStatementsList)

  useEffect(() => {
    if (!statementsList || statementsList.length === 0) {
      dispatch(getStatementsList()).then((response) => {
        // @ts-ignore
        if (!!response?.payload[0]?.id) {
          // @ts-ignore
          dispatch(getAdditionalDocs(response.payload[0].id))
        }
      })
    }
  }, [])

  const onOpenModal = () => {
    dispatch(showModal({ name: 'ADD_FILE_MODAL' }))
  }

  const onEditFile = (name, docId, date) => {
    dispatch(showModal({ name: 'EDIT_FILE_MODAL', data: { name, docId, date } }))
  }

  const onDownload = (file: any) => {
    api.client
      .getDocs(
        file.userDoc.getTypeDocs.name === 'other' ? 'OTHER' : 'CHANGES_SURNAME',
        file.userDoc.id
      )
      .then((resp) =>
        base64toFile(
          resp.data.document || '',
          'application/pdf',
          'pdf',
          file.userDoc.name
        )
      )
      .then(downloadFile)
      .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')))
  }

  return {
    files,
    onDownload,
    onOpenModal,
    onEditFile,
  }
}

export default useAdditionalFiles
