import React from 'react'
import { useParams } from 'react-router-dom'

import Table from './components/Table'
import useAchivements from '@hooks/useAchivements'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useReadOnlyFillingStatements from '@hooks/useReadOnlyFillingStatements'
import { showModal } from '@redux/action-types'
import { selectLevelValue } from '@redux/actions/selectors'
import Button from '@ui/Button'

const AchivementsForm = (scenarioKeys) => {
  const dispatch = useAppDispatch()
  const { statementId } = useParams()

  const onOpenAchivementsModal = () => {
    dispatch(showModal({ name: 'ADD_INDIVIDUAL_ACHIVEMENT', data: { statementId } }))
  }

  const headers = [
    'Тип достижения',
    'Подтверждающий документ',
    'Серия и номер',
    'Дата выдачи',
  ]

  const { onDelete, onDownloadFile, onEdit, achivements, achivementsAnketa } =
    useAchivements(String(statementId))

  const { readOnly } = useReadOnlyFillingStatements()
  const scenarioId = useAppSelector(selectLevelValue)

  return (
    <>
      <h4 className="achivements__title">Индивидуальные достижения</h4>
      {(scenarioId.id === scenarioKeys.level_training_bachelor_ino.tdId ||
        scenarioId.id === scenarioKeys.level_training_bachelor.tdId) && (
        <div className={'achievements__subtitle'}>
          <a
            href={
              'https://abiturient.spbu.ru/medialibrary/ru/2024/bac/2024_individual_dostizheniya.pdf'
            }
            target={'_blank'}
          >
            «Индивидуальные достижения»
          </a>
        </div>
      )}

      {(scenarioId.id === scenarioKeys.magistra_rf.tdId ||
        scenarioId.id === scenarioKeys.magistra_ino.tdId) && (
        <div className={'achievements__subtitle'}>
          <a
            href={
              'https://abiturient.spbu.ru/medialibrary/ru/2024/bac/2024_individual_dostizheniya.pdf'
            }
            target={'_blank'}
          >
            «Индивидуальные достижения»
          </a>
        </div>
      )}

      <Button
        type={'button'}
        className="achivements__button"
        onClick={onOpenAchivementsModal}
        disabled={readOnly}
      >
        Добавить
      </Button>
      <table className="achivements-table">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>

        {achivementsAnketa.length > 0 && (
          <Table
            items={achivementsAnketa}
            onDownload={onDownloadFile}
            readOnly={readOnly}
          />
        )}
        {achivements.length > 0 && (
          <Table
            items={achivements}
            onDelete={(docId) =>
              onDelete({ statementId: Number(statementId), docId })
            }
            onDownload={onDownloadFile}
            onEdit={onEdit}
            readOnly={readOnly}
          />
        )}
      </table>
    </>
  )
}

export default AchivementsForm
