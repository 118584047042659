import {
  LgotaDocType,
  LgotaType,
  PreemptiveRightDocType,
  PreemptiveRightType,
  UserLgota,
} from '@models'
import { StateStatus } from '@redux/types'
import {
  ActionReducerMapBuilder,
  AsyncThunk,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit'
import api from '@services/api'

type LgotaInitialStateEntity<T> = {
  data: T | null
  status: StateStatus
  error: unknown
}

const initialLgotaEntityState = {
  data: null,
  status: 'idle',
  error: null,
} as const

type LgotaInitialState = {
  typeLgota: LgotaInitialStateEntity<LgotaType>
  typeLgotaDocs: LgotaInitialStateEntity<LgotaDocType>
  typePreemptive: LgotaInitialStateEntity<PreemptiveRightType>
  typePreemptiveDocs: LgotaInitialStateEntity<PreemptiveRightDocType>
  docs: LgotaInitialStateEntity<UserLgota>
  uploadedLgotaDocsState: any
}

const initialState: LgotaInitialState = {
  typeLgota: initialLgotaEntityState,
  typeLgotaDocs: initialLgotaEntityState,
  typePreemptive: initialLgotaEntityState,
  typePreemptiveDocs: initialLgotaEntityState,
  docs: initialLgotaEntityState,
  uploadedLgotaDocsState: {},
}

export const lgotaSlice = createSlice({
  name: 'lgota',
  initialState,
  reducers: {
    addUploadedLgotaDocsState: (state, action) => {
      state.uploadedLgotaDocsState = {
        ...state.uploadedLgotaDocsState,
        ...action.payload,
      }
    },
    deleteUploadedLgotaDocsState: (state, action) => {
      const deleted = state.uploadedLgotaDocsState
      delete deleted[action.payload.id]
      state.uploadedLgotaDocsState = { ...deleted }
    },
    clearUploadedDocsState: (state, action) => {
      state.uploadedLgotaDocsState = {}
    },
  },
  extraReducers: (builder) => {
    setupExtraReducer(builder, getUserLgota, 'docs')
    setupExtraReducer(builder, getLgotaDocType, 'typeLgotaDocs')
    setupExtraReducer(builder, getLgotaType, 'typeLgota')
    setupExtraReducer(builder, getPreemptiveRightsType, 'typePreemptive')
    setupExtraReducer(builder, getPreemptiveRightsDocType, 'typePreemptiveDocs')
  },
})

function setupExtraReducer(
  builder: ActionReducerMapBuilder<LgotaInitialState>,
  asyncThunk: AsyncThunk<any, any, {}>,
  key: keyof LgotaInitialState
) {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state[key].status = 'loading'
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      state[key].status = 'succeeded'
      state[key].data = action.payload
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      state[key].status = 'failed'
      state[key].error = action.payload
      state[key].data = null
    })
}

export const getUserLgota = createAsyncThunk(
  'lgota/userLgota',
  async ({ statementId, userId }: { statementId: number; userId: number }) => {
    const response = await api.client.getUserLgota(statementId, userId)
    return response.data
  }
)

export const getLgotaDocType = createAsyncThunk(
  'lgota/userLgotaDocType',
  async (id: number) => {
    const response = await api.client.getUserLgotaDocType(id)
    return response.data
  }
)

export const getLgotaType = createAsyncThunk('lgota/userLgotaType', async () => {
  const response = await api.client.getUserLgotaType()
  return response.data
})

export const getPreemptiveRightsType = createAsyncThunk(
  'lgota/preemptiveType',
  async () => {
    const response = await api.client.getUserPreemptiveRightsType()
    return response.data
  }
)

export const getPreemptiveRightsDocType = createAsyncThunk(
  'lgota/preemptiveDocType',
  async (id: number) => {
    const response = await api.client.getPreemptiveRightDocType(id)
    return response.data
  }
)

export default lgotaSlice.reducer
