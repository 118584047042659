import { mimeTypes, TFileType, TMimeType } from '@common/manual'

export const getRandomStr = () => {
  return Math.random().toString(16).slice(2)
}

export const isNumeric = (n) => {
  //если число, вернет true
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export const downloadFile = (file: File) => {
  const url = URL.createObjectURL(file)
  const pdfWindow = window.open()
  if (pdfWindow) {
    pdfWindow.location.href = url
    pdfWindow.name = file.name
  }
}

export const urltoFile = (url: string, filename: string, mimeType: TMimeType) =>
  fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }))

export const base64toFile = (
  base64: string,
  mimeType: TMimeType,
  fileType: TFileType,
  filename?: string
) =>
  urltoFile(
    `data:${mimeType};base64,${base64}`,
    `${filename || getRandomStr()}.${fileType}`,
    mimeType
  )

export const base64ToPdf = (base64: string, filename?: string) =>
  base64toFile(base64, mimeTypes.pdf, 'pdf', filename)

export const getScrollbarWidth = () => {
  const outer = document.createElement('div')
  outer.style.visibility = 'hidden'
  outer.style.overflow = 'scroll' // forcing scrollbar to appear
  ;(outer.style as any).msOverflowStyle = 'scrollbar' // needed for WinJS apps
  document.body.appendChild(outer)
  const inner = document.createElement('div')
  outer.appendChild(inner)
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth
  outer?.parentNode?.removeChild(outer)
  return scrollbarWidth
}

export const trimFileExtension = (filename: string) => {
  return filename.replace(/\.[^/.]+$/, '')
}
