import usePaginatedEntity from '@hooks/usePaginatedEntity'
import { getNationalsIno } from '@redux/action-types'
import {
  selectPaginatedNationals,
  selectPaginatedNationalsIno,
  selectPaginatedNationalsLoading,
} from '@redux/actions/selectors'

const usePaginatedNationalsIno = () => {
  return usePaginatedEntity({
    action: getNationalsIno,
    dataSelector: selectPaginatedNationalsIno,
    loaderSelector: selectPaginatedNationalsLoading,
  })
}

export default usePaginatedNationalsIno
