import { useEffect } from 'react'

import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import { getAdminStatus } from '@redux/action-types/admin'
import {
  selectAdminStatusOptions,
  selectRoleIdProfile,
} from '@redux/actions/selectors'

const useAdminUserListFilterInitialization = () => {
  const dispatch = useAppDispatch()

  const roleId = useAppSelector(selectRoleIdProfile)
  useEffect(() => {
    !statusTypesOptions.length && dispatch(getAdminStatus(roleId))
  }, [])

  const statusTypesOptions = useAppSelector(selectAdminStatusOptions)
  return {
    statusTypesOptions,
  }
}

export default useAdminUserListFilterInitialization
