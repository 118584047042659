import { useState } from 'react'
import { Path, UseFormSetError } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

import HttpStatusCode from '@common/httpStatusCodes'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useProfileRoutesContext from '@hooks/useProfileRoutesContext'
import { createToast } from '@redux/action-types'
import { ResponseOk } from '@services/api'

type UseProfileApiHandlingProps<T> = {
  apiFn: Function
  onSuccessCb: Function
  setError: UseFormSetError<T>
}

const useProfileApiHandling = <T>({
  apiFn,
  onSuccessCb,
  setError,
}: UseProfileApiHandlingProps<T>) => {
  const [loading, setLoading] = useState(false)
  const { pathname } = useLocation()

  const dispatch = useAppDispatch()
  const { onNextPageNavigate } = useProfileRoutesContext()

  const callApi = <D>(data: D) => {
    setLoading(true)

    apiFn(data)
      .then((response) => {
        const { data } = response as ResponseOk
        dispatch(createToast(data.message, 'success'))
        onSuccessCb()
        !(
          pathname.includes('admin/users/') &&
          pathname.includes('personal-information/educational-background')
        ) && onNextPageNavigate()
      })
      .catch((response) => {
        const { status } = response

        if (status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))

          Object.keys(data.errors).forEach((key) => {
            setError(key as Path<T>, {
              type: 'manual',
              message: data.errors[key][0],
            })
          })
        } else if (status === HttpStatusCode.EXPECTATION_FAILED) {
          const { data } = response
          dispatch(createToast(data.message, 'danger'))
        } else {
          dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        }
        setLoading(false)
      })
  }

  return { callApi, loading }
}

export default useProfileApiHandling
