import React from 'react'

import cn from 'classnames'

import { LEVEL_TRAINING_PREPARATION, LEVEL_TRAINING_SPO } from '@common/constants'
import terms from '@common/terms'
import useAppSelector from '@hooks/useAppSelector'
import { ViewerDirectionsListStatements } from '@models'
import { selectLevelValue, selectTrajectoryValue } from '@redux/actions/selectors'
import Checkbox from '@ui/Checkbox'

type DirectionsTrainingElementProps = {
  item: ViewerDirectionsListStatements
  readOnly: boolean
  choseArr: number[]
  changeArr: (id: number) => void
  scenarioKeys: any
}
const DirectionsTrainingElement = ({
  readOnly,
  item,
  choseArr,
  changeArr,
  scenarioKeys,
}: DirectionsTrainingElementProps) => {
  const level = useAppSelector(selectLevelValue)
  const trajectory = useAppSelector(selectTrajectoryValue)

  return (
    <div
      className={cn('directions-training-element', {
        ['special-quota']: item.specialQuota,
      })}
    >
      <h2>
        {item.directionTraining.translate[0]['name']}
        <p>{item.name || '-'}</p>
      </h2>
      <div className={'row'}>
        <div className={'column'}>
          {item.specialQuota ? (
            <p>
              <b>{terms.SEPARATE_QUOTA}</b>
            </p>
          ) : item.benefits ? (
            <p>
              <b>{terms.SPECIAL_QUOTA}</b>
            </p>
          ) : null}
          <p>
            {terms.MODE_OF_STUDY}:{' '}
            <b>{item.formEducation?.formEducationTranslate[0]?.name || '-'}</b>
          </p>
          <p>
            {terms.FORM_PAYMENT_ID}:{' '}
            <b>{item.formPayment?.translate[0]?.name || '-'}</b>
          </p>
        </div>
        <div className={'column'}>
          <p>
            {terms.LEVEL_OF_TRAINING}:{' '}
            <b>{item.trainingLevel?.trainingLevelTranslate[0]?.name || '-'}</b>
          </p>
          <p>
            {terms.EDUCATIONAL_PROGRAM}:{' '}
            <b>{item?.profile?.translate[0]?.name || '-'}</b>
          </p>
        </div>
      </div>
      <div className="row">
        <div className={'column'}>
          <p>
            <u className={'unit-uderline'}>{terms.SUBDIVISION}</u>:{' '}
            <b>{item.faculty?.facultyTranslate[0].name || '-'}</b>
          </p>
        </div>
      </div>
      <div className="row">
        {/*(trajectory.id === 2 &&
        (level.scenarioId == undefined
        ? level.id === LEVEL_TRAINING_PREPARATION
        : level.scenarioId === LEVEL_TRAINING_PREPARATION))*/}
        <div className="column">
          {!(
            trajectory.id === 3 ||
            (level.scenarioId == undefined
              ? level.id === scenarioKeys.level_training_spo.tdId
              : level.scenarioId === scenarioKeys.level_training_spo.tdId)
          ) && (
            <>
              <p>
                {terms.ENTRANCE_EXAMS} / {terms.PASSING_SCORE}
              </p>
              {item.specialQuota &&
                item?.trialQuota?.length > 0 &&
                item.trialQuota.map((trial, index) => {
                  if (trial.disciplineTrialDistinct.length === 0) {
                    return
                  }
                  return (
                    <b key={index}>
                      {trial?.disciplineTrialDistinct.map((discipline, index) => {
                        return (
                          <>
                            {`${index !== 0 ? `/` : ''} ${discipline?.name} - ${
                              level.id === scenarioKeys.level_training_spo.tdId
                                ? discipline?.passingScore === 2
                                  ? 'Зачет'
                                  : discipline?.passingScore
                                : discipline?.passingScore
                            }`}
                          </>
                        )
                      })}
                    </b>
                  )
                })}

              {!item.specialQuota &&
                item?.trials.length > 0 &&
                item.trials.map((trial, index) => {
                  if (trial.disciplineTrialDistinct.length === 0) {
                    return
                  }
                  return (
                    <b key={index}>
                      {trial?.disciplineTrialDistinct.map((discipline, index) => {
                        return (
                          <>
                            {`${index !== 0 ? `/` : ''} ${discipline?.name} - ${
                              level.id === scenarioKeys.level_training_spo.tdId
                                ? discipline?.passingScore === 2
                                  ? 'Зачет'
                                  : discipline?.passingScore
                                : discipline?.passingScore
                            }`}
                          </>
                        )
                      })}
                    </b>
                  )
                })}
            </>
          )}
        </div>
      </div>
      <div className="directions-training-element__control-btn-container">
        <Checkbox
          disabled={readOnly}
          checked={choseArr.indexOf(item.id) !== -1}
          onChange={(e) => {
            changeArr(item.id)
          }}
          text={terms.CHOOSE}
          theme={'success-filled'}
        />
      </div>
      {item.dopInfo && <div className="row">{item.dopInfo}</div>}
    </div>
  )
}

export default DirectionsTrainingElement
