import axios, { type AxiosRequestConfig } from 'axios'
import camelcaseKeys from 'camelcase-keys'

import { EN_LOCALE_ID, RU_LANG_KEY, RU_LOCALE_ID } from '@common/constants'
import { locale } from '@common/terms'
import { SERVER_API_URL } from '@common/urls'
import { getAuthTokenFromStorage } from '@utils/auth'

/** Параметры, добавляемые к каждому запросу по умолчанию */
const defaultParams: AxiosRequestConfig['params'] = {
  /** Язык запроса */
  locale_id: locale === RU_LANG_KEY ? RU_LOCALE_ID : EN_LOCALE_ID,
}

const configureAxios = () => {
  axios.defaults.baseURL = SERVER_API_URL
  // устанавливаем передаваемые параметры к каждому запросу
  axios.defaults.params = defaultParams

  axios.interceptors.request.use(async (config) => {
    config.headers['Authorization'] = `Bearer ${getAuthTokenFromStorage()}`
    config.headers['X-localization'] = locale // TODO: что-то устаревшее
    return { ...config, metadata: { startTime: Date.now() } }
  })

  axios.interceptors.response.use(
    (resp) => camelcaseKeys(resp, { deep: true }),
    (err) => {
      throw camelcaseKeys(err.response, { deep: true })
    }
  )
}

export default configureAxios
