import React from 'react'

import TrajectoryList from '../../../components/TrajectoryList'
import terms from '@common/terms'
import useTrajectoryPage from '@hooks/useTrajectoryPage'
import Button from '@ui/Button'

const TrajectoryPage = () => {
  const { trajectory, onSubmit } = useTrajectoryPage()

  return (
    <main className={'trajectory-page'}>
      <TrajectoryList />
      <Button onClick={onSubmit} theme={'success'} disabled={trajectory.id === 0}>
        {terms.SAVE_AND_NEXT}
      </Button>
    </main>
  )
}

export default TrajectoryPage
