import { useForm } from 'react-hook-form'

import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import terms from '@common/terms'
import useDirectoryFormInitialization from '@hooks/useDirectoryFormInitialization'
import { createToast, hideModal } from '@redux/action-types'
import { getDirectoryInfo } from '@redux/action-types/admin'
import {
  selectAdminPageDirectoriesInfo,
  selectAdminPerPageDirectoriesInfo,
} from '@redux/actions/selectors'
import api, { ResponseOk } from '@services/api'

const createDynamicLabels = (labelName, inputs, inputName, childrenLabelName) => {
  if (!inputs[inputName]) return
  return {
    [labelName]: inputs[inputName].split(',').reduce((acc, item) => {
      acc.push({ [childrenLabelName]: item })
      return acc
    }, []),
  }
}

const useAddDictionaryAdminModal = (data) => {
  const dispatch = useAppDispatch()
  const page = useAppSelector(selectAdminPageDirectoriesInfo)
  const perPage = useAppSelector(selectAdminPerPageDirectoriesInfo)
  const { register, handleSubmit, setError, setValue, getValues } = useForm()

  const fields = data.connections.reduce((acc, connect) => {
    return {
      ...acc,
      [connect]: register(`${connect}`),
    }
  }, {})

  const onSubmit = handleSubmit((inputs) => {
    const normalizedData = {
      ...inputs,
      translation: inputs.name
        ? [
            {
              name: inputs.name,
            },
          ]
        : [
            {
              locale_id: 1,
              name: inputs[data.connections.find((item) => item.includes('Ru'))],
            },
            {
              locale_id: 2,
              name: inputs[data.connections.find((item) => item.includes('Eng'))],
            },
          ],
      ...(Object.keys(inputs).includes('typeEduDocToLevel') &&
        createDynamicLabels(
          'typeEducationDocToLevels',
          inputs,
          'typeEduDocToLevel',
          'levelEducationId'
        )),
      ...(Object.keys(inputs).includes('preemptiveRightToPrDoc') &&
        createDynamicLabels(
          'preemptiveRightToPrDoc',
          inputs,
          'preemptiveRightToPrDoc',
          'preemptiveRightDocId'
        )),
      ...(Object.keys(inputs).includes('achivmentDocToAchivment') &&
        createDynamicLabels(
          'achivment',
          inputs,
          'achivmentDocToAchivment',
          'achivmentId'
        )),
      ...(Object.keys(inputs).includes('achivmentToTraningLevel') &&
        createDynamicLabels(
          'scenario',
          inputs,
          'achivmentToTraningLevel',
          'scenarioId'
        )),
      ...(Object.keys(inputs).includes('phone') &&
        createDynamicLabels('phoneCodes', inputs, 'phone', 'code')),
      visible: !!inputs['visible'],
      id: data?.id || undefined,
    }
    data.name
      ? postEditedDirectory(data.directoryName, normalizedData)
      : postNewDirectoryModal(data.directoryName, normalizedData)
  })

  useDirectoryFormInitialization(setValue, data)

  const postNewDirectoryModal = (name, data) => {
    const searchParams = {
      directoryName: String(name),
      params: { page, perPage },
    }
    api.client
      .postAdminDirectory(name, data)
      .then((response) => {
        const { data } = (response as unknown) as ResponseOk
        dispatch(getDirectoryInfo(searchParams))
        dispatch(createToast(data.message, 'success'))
        dispatch(hideModal())
      })
      .catch(() => {
        dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        hideModal()
      })
  }

  const postEditedDirectory = (name, data) => {
    const searchParams = {
      directoryName: String(name),
      params: { page, perPage },
    }
    api.client
      .changeAdminDirectory(name, data)
      .then((response) => {
        const { data } = (response as unknown) as ResponseOk
        dispatch(getDirectoryInfo(searchParams))
        dispatch(createToast(data.message, 'success'))
        dispatch(hideModal())
      })
      .catch(() => {
        dispatch(createToast(terms.ERROR_HAS_OCCURED, 'danger'))
        hideModal()
      })
  }

  return {
    onSubmit,
    fields,
  }
}

export default useAddDictionaryAdminModal
