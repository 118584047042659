import React from 'react'

const ModeratorContractIdInfoEntrants = ({ statement: { users } }) => {
  const registrationAdress = users.address.find(
    (item) => item.typeAddress === 'registration'
  )

  const actualAdress = users.address.find((item) => item.typeAddress === 'actual')
  return (
    <div className="moderator-contract-info__inline">
      <div className="moderator-contract-info__block">
        <h2>Основные данные плательщика:</h2>
        <div className="moderator-contract-info__block-row">
          <p>Фамилия, Имя, Отчество:</p>
          <span>
            {users?.usersTranslate?.length &&
              `${users?.usersTranslate[0]?.surname || ''} ${
                users?.usersTranslate[0]?.name || ''
              } ${users?.usersTranslate[0]?.patronymic || ''}`}
          </span>
        </div>
        <div className="moderator-contract-info__block-row">
          <p>Дата рождения:</p>
          <span>{users?.dateBirthday}</span>
        </div>
        <div className="moderator-contract-info__block-row">
          <p>Мобильный телефон:</p>
          <span>{users?.mobile}</span>
        </div>
        <div className="moderator-contract-info__block-row">
          <p>Адрес по прописке:</p>
          <span>
            {registrationAdress.region === registrationAdress.city
              ? `${registrationAdress.region} ул.${registrationAdress.street} д.${registrationAdress.house}`
              : `${registrationAdress.region} г.${registrationAdress.city} ${
                  registrationAdress.district
                    ? `район ${registrationAdress.district}`
                    : ''
                } ул.${registrationAdress.street} д.${registrationAdress.house}`}
          </span>
        </div>
        <div className="moderator-contract-info__block-row">
          <p>Адрес фактический:</p>
          <span>
            {actualAdress.region === actualAdress.city
              ? `${actualAdress.region} ул.${actualAdress.street} д.${actualAdress.house}`
              : `${actualAdress.region} г.${actualAdress.city} ${
                  actualAdress.district ? `район ${registrationAdress.district}` : ''
                } ул.${actualAdress.street} д.${actualAdress.house}`}
          </span>
        </div>
        <div className="moderator-contract-info__block-row">
          <p>Email:</p>
          <span>{users.email}</span>
        </div>
      </div>
      <div className="moderator-contract-info__block">
        <h2>Паспортные данные плательщика:</h2>
        <div className="moderator-contract-info__block-row">
          <p>Тип документа:</p>{' '}
          <span>
            {users?.userPassport?.typeIdentityDoc?.typeIdentityDocTranslate[0]?.name}
          </span>
        </div>
        <div className="moderator-contract-info__block-row">
          <p>Серия:</p>
          <span>{users?.userPassport?.serialDocument}</span>
        </div>
        <div className="moderator-contract-info__block-row">
          <p>Номер:</p>
          <span>{users?.userPassport?.numberDocument}</span>
        </div>
        <div className="moderator-contract-info__block-row">
          <p>Когда выдан:</p>
          <span>{users?.userPassport?.dateOfIssue}</span>
        </div>
        <div className="moderator-contract-info__block-row">
          <p>Срок действия:</p>
          <span>{users?.userPassport?.documentValidPeriod}</span>
        </div>
        <div className="moderator-contract-info__block-row">
          <p>Кем выдан:</p>
          <span>{users?.userPassport?.whoIssueDocument}</span>
        </div>
        <div className="moderator-contract-info__block-row">
          <p>Код подразделения:</p>
          <span>{users?.userPassport?.divisionCode}</span>
        </div>
        <div className="moderator-contract-info__block-row">
          <p>Место рождения:</p>
          <span>{users?.userPassport?.placeBirthday}</span>
        </div>
      </div>
    </div>
  )
}

export default ModeratorContractIdInfoEntrants
