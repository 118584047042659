import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import EntrantsContractForm from './components/EntrantsContractForm'
import JuridicalContractForm from './components/JuridicalContractForm'
import NotEntrantsContractForm from './components/NotEntrantsContractForm'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useCreateContractForm from '@hooks/useCreateContractForm'
import { cleanContract, getContract } from '@redux/action-types'
import { selectContract, selectStatementsElement } from '@redux/actions/selectors'
import RadioButton from '@ui/RadioButton'

const CreateContractPage = () => {
  const [contractType, setContractType] = useState<1 | 2 | 3 | 4>(1)
  const [editingPayerType, setEditingPayerType] = useState<number>()

  const { contractId } = useParams()
  const { payerTypes } = useCreateContractForm()

  const dispatch = useAppDispatch()
  const contract = useAppSelector(selectContract)
  const statement = useAppSelector(selectStatementsElement)

  const onChangeContractType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setContractType(Number(event.target.value) as typeof contractType)
    contract && setEditingPayerType(Number(event.target.value))
  }

  useEffect(() => {
    dispatch(cleanContract())
  }, [])

  useEffect(() => {
    contractId && dispatch(getContract(Number(contractId)))
  }, [contractId])

  useEffect(() => {
    contract &&
      setEditingPayerType(
        payerTypes?.data.filter((type) => type.id === contract.payerTypeId)[0]?.id
      )
    contract && setContractType(contract.payerTypeId)
  }, [contract, contractId])

  return (
    <div>
      <RadioButton className="create-contract-form__radio">
        {(statement?.trajectoryId === 2
          ? payerTypes?.data.slice(0, 2)
          : payerTypes?.data
        )?.map((type, index) => (
          <RadioButton.Radio
            onChange={onChangeContractType}
            value={type.translations[0].payerTypeId}
            name="contract"
            text={type.translations[0].name}
            key={index}
            checked={(editingPayerType ?? contractType) === type.id}
          />
        ))}
      </RadioButton>

      {(editingPayerType ?? contractType) === 1 ? (
        <EntrantsContractForm
          payerTypeId={contractType}
          contract={contract}
          trajectoryId={statement?.trajectoryId}
          scenarioId={statement?.scenarioId}
        />
      ) : (editingPayerType ?? contractType) === 3 ? (
        <JuridicalContractForm
          payerTypeId={contractType}
          contract={contract}
          trajectoryId={statement?.trajectoryId}
        />
      ) : (
        <NotEntrantsContractForm
          payerTypeId={contractType}
          contract={contract}
          trajectoryId={statement?.trajectoryId}
        />
      )}
    </div>
  )
}

export default CreateContractPage
