import axios from 'axios'
import snakecaseKeys from 'snakecase-keys'

import { ExchangeSetting, ExchangeStatus } from '../../models/settings'
import { ROLE_SUPPORT } from '@common/constants'
import {
  AdminAuthSettingsOptions,
  AdminRoleOptions,
  AdminStatusOptions,
  AdminUserResponseData,
  AdminUserResponsePage,
  DirectoriesInfoResponsePage,
  DirectoriesListResponsePage,
  FilterAdminUserList,
  FilterLogList,
  LegalRepresentative,
  LogPage,
  NewUserForm,
  PageDataView,
  PutUserForm,
  VisaResponse,
} from '@models'
import {
  ResponseNotAcceptable,
  ResponseOk,
  ResponseUnprocessableEntity,
} from '@services/api/types'

export default {
  /** @method GET */
  getAdminUserList: (data?: FilterAdminUserList) => {
    const params = new URLSearchParams()
    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key] || (key === 'status' && snakeData[key] === 0)) {
          params.append(key, snakeData[key])
        }
      })
    }
    return axios.get<AdminUserResponsePage>(`/admin/users`, { params: params })
  },

  /** @method GET */
  getSupportUserList: (data?: FilterAdminUserList) => {
    const params = new URLSearchParams()
    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key] || (key === 'status' && snakeData[key] === 0)) {
          params.append(key, snakeData[key])
        }
      })
    }
    return axios.get<AdminUserResponsePage>(`/support/users`, { params: params })
  },

  /** @method GET */
  getAdminUserId: (id: string | number) => {
    return axios.get<AdminUserResponseData>(`/admin/users/${id}`)
  },

  /** @method GET */
  getAdminRole: () => {
    return axios.get<AdminRoleOptions>(`/role`)
  },

  /** @method GET */
  getAdminStatus: (roleId: number | null) => {
    if (roleId === ROLE_SUPPORT) {
      return axios.get<AdminStatusOptions>(`/support/users/statuses`)
    }
    return axios.get<AdminStatusOptions>(`/admin/users/statuses`)
  },

  /** @method GET */
  getAuthPagesList: () => {
    return axios.get<AdminAuthSettingsOptions>(`/admin/dictionary/setting_list`)
  },

  /** @method POST */
  getUpdateAuthPage: (data) => {
    return axios.put<AdminAuthSettingsOptions>(
      `/admin/dictionary/setting_auth_update`,
      {
        data,
        //  id: data.id,
      }
    )
  },

  /** @method GET */
  getExchangeStatus: () => {
    return axios.get<ExchangeStatus>(`/admin/getExchangeStatus`)
  },

  /** @method POST */
  putExchange: (data) => {
    return axios.post<ExchangeSetting>(`/admin` + data['url'], {
      data,
    })
  },

  /** @method POST */
  uploadAuthImage: (photo: Blob | string, data2) => {
    const data = new FormData()
    data.append('photo', photo)
    data.append('data', data2.id)
    return axios.post<string>('/admin/dictionary/image', data, data2)
  },

  /** @method POST */
  uploadAuthLogo: (photo: Blob | string, data2) => {
    const data = new FormData()
    data.append('photo', photo)
    data.append('data', data2.id)
    return axios.post<string>('/admin/dictionary/logo', data, data2)
  },

  /** @method GET */
  getViewerAuthPicture: () => axios.get<{ picture: string }>(`/userPhoto`),

  /** @method GET */
  getDirectoriesList: (params) => {
    const param = new URLSearchParams()
    if (params) {
      const snakeData = snakecaseKeys(params)
      Object.keys(snakeData).forEach((key) => param.append(key, snakeData[key]))
    }
    if (params.ids) {
      return axios.get<DirectoriesListResponsePage>(`/admin/dictionary/sp_lists`, {
        params: { ids: [params.ids] },
      })
    }

    return axios.get<DirectoriesListResponsePage>(`/admin/dictionary/sp_lists`, {
      params: param,
    })
  },

  /** @method GET */
  getDirectoryInfo: ({ directoryName, params }) => {
    const param = new URLSearchParams()
    if (params) {
      const snakeData = snakecaseKeys(params)
      Object.keys(snakeData).forEach((key) => param.append(key, snakeData[key]))
    }
    return axios.get<DirectoriesInfoResponsePage>(
      `/admin/dictionary/${directoryName}`,
      { params: param }
    )
  },

  /** @method GET */
  getLogList: (data?: FilterLogList) => {
    const params = new URLSearchParams()
    if (data) {
      const snakeData = snakecaseKeys(data)
      Object.keys(snakeData).forEach((key) => {
        if (snakeData[key] || (key === 'status' && snakeData[key] === 0)) {
          params.append(key, snakeData[key])
        }
      })
    }
    return axios.get<LogPage>(`/admin/history`, { params: params })
  },

  /** @method POST */
  postAdminNewUser: (data: NewUserForm) =>
    axios.post<NewUserForm, UpdateUser>(`/admin/users`, snakecaseKeys(data)),

  /** @method POST */
  postAdminDirectory: (name: string, data) =>
    axios.post<NewUserForm, UpdateUser>(`/admin/dictionary/${name}`, {
      dictionary: [snakecaseKeys(data)],
    }),

  /** @method PUT */
  putAdminChangeUser: (data: PutUserForm) => {
    if (!data?.password) {
      delete data?.password
      delete data?.passwordConfirmation
    }
    return axios.put<PutUserForm, UpdateUser>(
      `/admin/users/${data.id}`,
      snakecaseKeys(data)
    )
  },

  /** @method PUT */
  putAdminChangeUserStatus: (data: {
    id: number
    status: number
    roleId: number | null
  }) => {
    const { roleId, ...normalizedData } = data
    if (roleId === ROLE_SUPPORT) {
      return axios.put<{ userId: number }, UpdateUserStatus>(
        `/support/users/activate`,
        snakecaseKeys({ userId: normalizedData.id })
      )
    }
    return axios.put<{ id: number; status: number }, UpdateUserStatus>(
      `/admin/users/status/`,
      snakecaseKeys(normalizedData)
    )
  },

  /** @method PUT */
  changeAdminDirectory: (name: string, data) => {
    const normalizedData = { dictionary: [data] }
    return axios.put<NewUserForm, UpdateUser>(
      `/admin/dictionary/${name}`,
      snakecaseKeys(normalizedData)
    )
  },

  /** @method DELETE */
  deleteAdminUserId: (id: number | string) =>
    axios.delete<{ id: number }, UpdateAdminDelete>(`/admin/users?id=${id}`),

  /** @method DELETE */
  deleteAdminStatements: (id: number | string) =>
    axios.delete<{ id: number }, UpdateAdminDelete>(`/admin/statements?id=${id}`),

  /** @method DELETE */
  deleteAdminDirectory: (name: string, id: string | number) =>
    axios.delete<{ name: string }>(`/admin/dictionary/${name}`, {
      params: { ids: [id] },
    }),

  /** @method GET */
  getAdminVisa: (id: string | number) =>
    axios.get<VisaResponse[]>(`/admin/statements/visa?statement=${id}`),

  /** @method GET */
  getAdminLegalRepresentative: (statementId: string | number) =>
    axios.get<LegalRepresentative>(
      `/admin/statements/legal-representative?statement=${statementId}`
    ),
}

//deleteStatements
export type UpdateAdminDelete =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<{ id: number }>

export type UpdateUser =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<{ id: number }>

export type UpdateUserStatus =
  | ResponseOk
  | ResponseNotAcceptable
  | ResponseUnprocessableEntity<{ id: number; status: number }>
