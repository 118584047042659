import { useEffect } from 'react'

import useInitializeInputs from './useInitializeInputs'
import useUploadDocuments from './useUploadDocuments'
import { trimFileExtension } from '@utils/common'

const useNotEntrantsContractFormInitialization = (setValue, contract) => {
  const { docs, onDrop, onChange, onDelete, getUploadedDoc } = useUploadDocuments(
    'COPY_PASSPORT_FOR_CONTRACT',
    (contract?.contractDoc.length &&
      trimFileExtension(contract?.contractDoc[0].userDoc?.name)) ||
      'Паспорт'
  )

  useEffect(() => {
    if (
      contract &&
      contract?.contractDoc[0] &&
      Object.keys(contract?.contractDoc[0]).length
    ) {
      getUploadedDoc(contract.contractDoc[0].userDocId)
    }
    if (contract) {
      setValue('directionTrainingId', {
        label:
          contract?.competitiveGroupStatement?.competitiveGroup?.directionTraining
            ?.translations[0]?.name,
        value:
          contract?.competitiveGroupStatement?.competitiveGroup?.directionTraining
            ?.translations[0]?.directionTrainingId,
      })
    }
  }, [contract])

  useInitializeInputs({
    data: contract?.contractIndividual,
    omitKeys: [],
    onInit: (key, value) => {
      setValue(key, value)
      setValue('typeIdentityDocumentId', {
        label:
          contract.contractIndividual.typeIdentityDocument
            .typeIdentityDocTranslate[0].name,
        value:
          contract.contractIndividual.typeIdentityDocument
            .typeIdentityDocTranslate[0].typeIdentityDocumentsId,
      })
      setValue('directionTrainingId', {
        label:
          contract.competitiveGroupStatement.competitiveGroup.directionTraining
            .translations[0].name,
        value:
          contract.competitiveGroupStatement.competitiveGroup.directionTraining
            .translations[0].directionTrainingId,
      })
    },
  })
  return {
    docs,
    onDelete,
    onChange,
    onDrop,
  }
}

export default useNotEntrantsContractFormInitialization
