const AUTH_TOKEN_KEY = 'auth:token'

export const saveAuthTokenToStorage = (token: string) => {
  window.localStorage.setItem(AUTH_TOKEN_KEY, token)
}

export const deleteAuthTokenFromStorage = () => {
  window.localStorage.removeItem(AUTH_TOKEN_KEY)
}

export const getAuthTokenFromStorage = () => {
  return window.localStorage.getItem(AUTH_TOKEN_KEY)
}
