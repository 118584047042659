import { useEffect, useState } from 'react'

import api from '@services/api'

const useCreateContractForm = () => {
  const [payerTypes, setPayerTypes] = useState<any>()

  useEffect(() => {
    const fetchData = async () => {
      setPayerTypes(await api.client.getPayerTypes())
    }
    fetchData()
  }, [])

  return {
    payerTypes,
  }
}

export default useCreateContractForm
