import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import useCheckProfileValid from '@hooks/useCheckProfileValid'
import Loader from '@ui/Loader'

const StatementsPage = () => {
  const { loading, valid } = useCheckProfileValid()

  if (loading) {
    return <Loader />
  }

  if (!valid) {
    return <Navigate to="/personal-information" />
  }

  return <Outlet />
}

export default StatementsPage
