import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ModeratorDataList from '@components/ModeratorDataList'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { DataList } from '@models'
import ModeratorDocumentsPage from '@pages/moderator/ModeratorDocumentsPage'
import { getModeratorStatementsAddressData } from '@redux/action-types'
import { selectModeratorStatementsAddressData } from '@redux/actions/selectors'
import Checkbox from '@ui/Checkbox'

const ModeratorRegistrationAddressPage = () => {
  const dispatch = useAppDispatch()

  const { id, userId } = useParams<{ id?: string; userId?: string }>()
  useEffect(() => {
    if (id || userId) {
      dispatch(getModeratorStatementsAddressData({ id: id, userId: userId }))
    }
  }, [id, userId])

  const address = useAppSelector(selectModeratorStatementsAddressData) || {}
  const list: DataList = [
    {
      title: 'Адрес регистрации(по паспорту):',
      label: '',
    },
    {
      title: 'Страна:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'registration')
            ?.nationality?.nationalityTranslate[0]?.name) ||
        '-',
    },
    {
      title: 'Регион:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'registration')
            ?.region) ||
        '-',
    },
    {
      title: 'Населенный пункт:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'registration')?.city) ||
        '-',
    },
    {
      title: 'Улица:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'registration')
            ?.street) ||
        '-',
    },
    {
      title: 'Дом:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'registration')
            ?.house) ||
        '-',
    },
    {
      title: 'Корпус:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'registration')
            ?.building) ||
        '-',
    },
    {
      title: 'Квартира:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'registration')
            ?.apartment) ||
        '-',
    },
    {
      title: 'Индекс:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'registration')
            ?.postcode) ||
        '-',
    },
    {
      title: 'Номер Fias:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'registration')
            ?.apiFias) ||
        '-',
    },
    {
      title: 'Номер Kladr:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'registration')
            ?.apiKladr) ||
        '-',
    },
  ]

  const listActual: DataList = [
    {
      title: 'Адрес Фактического Проживания:',
      label: '',
    },
    {
      title: 'Страна:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'actual')?.nationality
            ?.nationalityTranslate[0]?.name) ||
        '-',
    },
    {
      title: 'Регион:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'actual')?.region) ||
        '-',
    },
    {
      title: 'Населенный пункт:',
      label: (address?.address?.length && address?.address[0]?.city) || '-',
    },
    {
      title: 'Улица:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'actual')?.street) ||
        '-',
    },
    {
      title: 'Дом:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'actual')?.house) ||
        '-',
    },
    {
      title: 'Корпус:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'actual')?.building) ||
        '-',
    },
    {
      title: 'Квартира:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'actual')?.apartment) ||
        '-',
    },
    {
      title: 'Индекс:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'actual')?.postcode) ||
        '-',
    },
    {
      title: 'Номер Fias:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'registration')
            ?.apiFias) ||
        '-',
    },
    {
      title: 'Номер Kladr:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'registration')
            ?.apiKladr) ||
        '-',
    },
    {
      title: 'Необходимость в общежитии на время учебы:',
      label: (address && (address.needHostel ? 'Да' : 'Нет')) || '-',
    },
  ]
  return (
    <div className={'moderator-data-list'}>
      <ModeratorDataList list={list} />
      <Checkbox
        text={'Нет улицы'}
        checked={
          (address.address?.length &&
            address?.address?.find((el) => el.noStreet === 'registration')
              ?.apiFias) ||
          false
        }
        readOnly
      />

      <ModeratorDataList list={listActual} />
      <Checkbox
        text={'Нет улицы'}
        checked={
          (address.address?.length &&
            address?.address?.find((el) => el.noStreet === 'actual')?.apiFias) ||
          false
        }
        readOnly
      />

      <ModeratorDocumentsPage
        params={[{ typeDoc: 'document_vnz', title: 'Вид на жительство' }]}
      />
    </div>
  )
}

export default ModeratorRegistrationAddressPage
