import React from 'react'

import DownloadIcon from '@assets/images/Download.svg'
import EditIcon from '@assets/images/Edit.svg'
import TrashIcon from '@assets/images/Trash.svg'
import { Achivement, AchivementAnketa } from '@models'
import { formatDateYYYYMMDD } from '@utils/date'
import { trimFileExtension } from '@utils/common'

type TableProps = {
  items: Achivement[] | AchivementAnketa[]
  onEdit?: (achivement: Achivement) => void
  onDelete?: (docId: number) => void
  onDownload: (docId: number, filename: string, docType?: string) => void
  readOnly: boolean
}

const Table = ({ items, onDelete, onDownload, onEdit, readOnly }: TableProps) => {
  return (
    <tbody>
      {items.map((item, id) => {
        return (
          <tr key={id}>
            <td>{item.name || item.achivmentDocTranslate.name}</td>
            <td>{item.docName || trimFileExtension(item.doc[0].name)}</td>
            <td>{item.number || item.numberEducationDocument}</td>
            <td>
              {item.dateIssue
                ? formatDateYYYYMMDD(item.dateIssue)
                : item.dateDocumentIssue
                ? item.dateDocumentIssue
                : '---'}
            </td>
            <td>
              <div className="buttons">
                <button
                  type="button"
                  onClick={() =>
                    onDownload(
                      item.doc ? item.doc[0].id : item.docId,
                      item.doc
                        ? trimFileExtension(item.doc[0].name)
                        : item.docName || '',
                      item.doc ? 'EDUCATION' : undefined
                    )
                  }
                >
                  <DownloadIcon />
                </button>
                {onEdit ? (
                  <button
                    type="button"
                    disabled={readOnly}
                    onClick={() => onEdit(item)}
                  >
                    <EditIcon />
                  </button>
                ) : null}
                {onDelete ? (
                  <button
                    type="button"
                    disabled={readOnly}
                    onClick={() => onDelete(item.docId)}
                  >
                    <TrashIcon />
                  </button>
                ) : null}
              </div>
            </td>
          </tr>
        )
      })}
    </tbody>
  )
}

export default Table
