import usePaginatedEntity from '@hooks/usePaginatedEntity'
import { getDirectionTrainings, getNationals } from '@redux/action-types'
import {
  selectPaginatedDirectionTrainingLoading,
  selectPaginatedDirectionTrainings,
} from '@redux/actions/selectors'

const usePaginatedDirectionTraining = () => {
  return usePaginatedEntity({
    action: getDirectionTrainings,
    dataSelector: selectPaginatedDirectionTrainings,
    loaderSelector: selectPaginatedDirectionTrainingLoading,
  })
}

export default usePaginatedDirectionTraining
