import React, { HTMLAttributes } from 'react'

import cn from 'classnames'

type H1Props = HTMLAttributes<HTMLElement> & {
  children: string
}

const H1 = ({ children, className, ...rest }: H1Props): JSX.Element => {
  return (
    <h1 {...rest} className={cn('h1', className)}>
      {children}
    </h1>
  )
}

export default H1
