import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import terms from '@common/terms'
import ProfileSection from '@components/ProfileSection'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useConclusionBaseDepartment from '@hooks/useConclusionBaseDepartment'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import useUploadDocuments from '@hooks/useUploadDocuments'
import { createToast, getConclusionBaseDepartment } from '@redux/action-types'
import { selectConclusionBaseDepartment } from '@redux/actions/selectors'
import api, { ResponseOk } from '@services/api'
import FileUploader from '@ui/FileUploader'

const ModeratorConclusionBaseDepartmentPage = () => {
  const conclusionBaseDepartment = useAppSelector(selectConclusionBaseDepartment)
  const { id } = useParams<{ id?: string }>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getConclusionBaseDepartment(Number(id)))
  }, [])

  const { onChange, onDelete, onDrop, docs, onUploadDocs, getUploadedDoc, setDocs } =
    useUploadDocuments(
      'CONCLUSION_FROM_BASE_DEPARTMENT',
      terms.CONCLUSION_BASE_DEPARTMENT
    )

  const uploadDoc = (docs) => {
    if (!docs) return

    const formData = new FormData()
    formData.append('typeDoc', 'conclusion_from_base_department')
    formData.append('statement_id', `${id}`)
    for (const doc of docs) {
      formData.append('documents[]', doc)
    }

    api.client
      .postConclusionBaseDepartmentDocs(formData)
      .then((response) => {
        const { data } = response as unknown as ResponseOk
        dispatch(createToast(data.message, 'success'))

        setDocs(docs)
      })
      .catch((response: ResponseOk) => {
        const { data } = response
        dispatch(createToast(data.message, 'danger'))
      })
  }

  const deleteDoc = () => {
    api.client
      .deleteConclusionBaseDepartmentDoc({
        statementId: Number(id),
        docId: conclusionBaseDepartment?.status?.id,
      })
      .finally(() => {
        setDocs([])
      })
  }

  useEffect(() => {
    console.log(conclusionBaseDepartment)
    if (conclusionBaseDepartment?.status?.id) {
      getUploadedDoc(conclusionBaseDepartment?.status?.id)
    }
  }, [conclusionBaseDepartment])

  return (
    <div className={'moderator-documents-page'}>
      <h1 style={{ marginBottom: '40px' }}>{terms.CONCLUSION_BASE_DEPARTMENT}</h1>

      {!!docs.length && (
        <ProfileSection
          title={'Заключение базовой кафедры'}
          markRequired
          fileUploadingHint
        >
          <FileUploader
            files={docs}
            onDrop={(file) => uploadDoc(file)}
            onChange={(file) => uploadDoc(file)}
            onDelete={deleteDoc}
            className="profile-form__file-uploader"
            disabled={true}
          />
        </ProfileSection>
      )}
    </div>
  )
}

export default ModeratorConclusionBaseDepartmentPage
