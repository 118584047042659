import React from 'react'
import { Controller } from 'react-hook-form'

import terms from '@common/terms'
import useAddUserAdminModalForm from '@hooks/useAddUserAdminModalForm'
import useAddUserAdminModalInitialization from '@hooks/useAddUserAdminModalInitialization'
import useAppSelector from '@hooks/useAppSelector'
import TemplateModal from '@modals/TemplateModal'
import { NewUserForm, SelectOption } from '@models'
import { modalDataSelector } from '@redux/actions/selectors'
import Button from '@ui/Button'
import Input from '@ui/Input'
import Select from '@ui/Select'

const AddUserAdminModal = () => {
  const { adminUserIdData } = useAppSelector(modalDataSelector) as {
    adminUserIdData?: NewUserForm
  }
  const isEditing = Boolean(adminUserIdData)

  const {
    control,
    fields,
    onSubmit,
    setValue,
    loading,
    formState: { errors },
  } = useAddUserAdminModalForm({ adminUserIdData, isEditing })

  const {
    statusTypesOptions,
    roleTypesOptions,
  } = useAddUserAdminModalInitialization({ setValue, adminUserIdData })

  return (
    <TemplateModal
      title={isEditing ? 'Редактировать пользователя' : 'Добавить пользователя'}
    >
      <form onSubmit={onSubmit}>
        <div className="profile-form__fields">
          <Controller
            name="status"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                options={statusTypesOptions}
                placeholder="Активен"
                className="profile-form__field"
                markRequired={true}
                {...(field as unknown)}
                error={errors.status?.message}
              />
            )}
          />
          <Controller
            name="roleId"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                options={roleTypesOptions}
                placeholder="Роль"
                className="profile-form__field"
                markRequired={true}
                {...(field as unknown)}
                error={errors.roleId?.message}
              />
            )}
          />
          <Input
            placeholder={'ФИО'}
            className="profile-form__field profile-form__input--fb-66"
            {...fields.nameRu}
          />
          <Input
            className="profile-form__field profile-form__input--fb-33"
            placeholder="Дата рождения"
            type="date"
            markRequired={true}
            {...fields.dateBirthday}
            error={errors.dateBirthday?.message}
          />
          <Input
            placeholder={'Логин/email'}
            className="profile-form__field profile-form__field--fb-100"
            markRequired={true}
            {...fields.email}
            error={errors.email?.message}
          />
          <Input
            className="profile-form__field profile-form__field--fb-100"
            hint={terms.PASS_HINT}
            placeholder={'Пароль'}
            enableSwitcher={true}
            markRequired={true}
            {...fields.password}
            error={errors.password?.message}
          />
          <Input
            className="profile-form__field profile-form__field--fb-100"
            placeholder={'Подтверждение пароля'}
            enableSwitcher={true}
            markRequired={true}
            {...fields.passwordConfirmation}
            error={errors.passwordConfirmation?.message}
          />
        </div>
        <Button
          theme="success"
          loading={loading}
          className="profile-form__button profile-form__button--align-left"
        >
          {terms.SAVE}
        </Button>
      </form>
    </TemplateModal>
  )
}

export default AddUserAdminModal
