import React, { useEffect, useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import classnames from 'classnames'

import terms from '@common/terms'
import EntranceExamsRow from '@components/EntranceExamsForm/components/Row'
import useAppSelector from '@hooks/useAppSelector'
import { Olympiad, SelectOption } from '@models'
import { createToast } from '@redux/action-types'
import {
  modalDataSelector,
  selectDisciplines,
  selectDisciplineTrials,
  selectMoodleCourses,
  selectStatementsElement,
} from '@redux/actions/selectors'
import api from '@services/api'
import Button from '@ui/Button'
import { base64toFile, downloadFile } from '@utils/common'

const EntranceExamsTable = (scenarioKeys) => {
  const disciplines = useAppSelector(selectDisciplines) || []
  const courses = useAppSelector(selectMoodleCourses)
  const trials = useAppSelector(selectDisciplineTrials)
  const [noOnlineExam, setNoOnlineExam] = useState(false)
  const dispatch = useDispatch()
  const { statementId } = useParams()
  const statement = useAppSelector(selectStatementsElement)

  useEffect(() => {
    if (!!trials && trials.length > 0) {
      trials.forEach((trial) => {
        if (trial.place !== 'Онлайн') {
          setNoOnlineExam(true)
        }
      })
    }
  })

  function loadDoc() {
    api.client
      .getExamDoc(statementId ?? '')
      .then((resp) =>
        base64toFile(
          resp.data.document || '',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'docx',
          'Пропуск на ВИ'
        )
      )
      .then(downloadFile)
      .catch(() => dispatch(createToast(terms.DOWNLOAD_FILE_ERROR, 'danger')))
  }

  return (
    <div>
      {trials && trials.length > 0 && (
        <>
          <p className={'h1 mb-12'}>Расписание</p>
          <table className="entr-exams-table mb-24">
            <thead className="entr-exams-table__head">
              <tr className="entr-exams-table__row">
                <th className="entr-exams-table__head-cell">Предмет</th>
                <th className="entr-exams-table__head-cell">
                  Дата и время экзамена
                </th>
                <th
                  className={classnames(
                    'entr-exams-table__head-cell',
                    'entr-exams-table__head-cell--align-center'
                  )}
                >
                  Тип испытания
                </th>
                <th
                  className={classnames(
                    'entr-exams-table__head-cell',
                    'entr-exams-table__head-cell--align-center'
                  )}
                >
                  Место проведения
                </th>
              </tr>
            </thead>
            <tbody className="entr-exams-table__body">
              {trials?.map((d, index) => (
                <EntranceExamsRow discipline={null} trial={d} key={index} />
              ))}
            </tbody>
          </table>
        </>
      )}

      {courses && courses.courses && courses.courses.length > 0 && (
        <>
          <p className={'h1 mb-12'}>Вход на экзамен</p>
          <table className="entr-exams-table">
            <thead className="entr-exams-table__head">
              <tr className="entr-exams-table__row">
                <th className="entr-exams-table__head-cell">Предмет</th>
                <th className="entr-exams-table__head-cell">
                  Дата и время экзамена
                </th>
                <th
                  className={classnames(
                    'entr-exams-table__head-cell',
                    'entr-exams-table__head-cell--align-center'
                  )}
                >
                  Оценка (балл)
                </th>
                <th
                  className={classnames(
                    'entr-exams-table__head-cell',
                    'entr-exams-table__head-cell--align-center'
                  )}
                ></th>
              </tr>
            </thead>
            <tbody className="entr-exams-table__body">
              {courses?.courses?.map((d, index) => (
                <EntranceExamsRow discipline={d} trial={null} key={index} />
              ))}
            </tbody>
          </table>
        </>
      )}

      {statement?.scenarioId !== scenarioKeys.asperatnuta_rf.tdId &&
        statement?.scenarioId !== scenarioKeys.level_training_preparation.tdId &&
        statement?.scenarioId !==
          scenarioKeys.level_training_bachelor_tashkent.tdId &&
        statement?.scenarioId !== scenarioKeys.magistra_tashkent.tdId &&
        statement?.scenarioId !== scenarioKeys.level_training_bachelor_ino.tdId &&
        statement?.scenarioId !== scenarioKeys.magistra_ino.tdId && (
          <Button
            disabled={!noOnlineExam}
            onClick={loadDoc}
            style={{ marginBottom: '30px' }}
            type={'button'}
          >
            Скачать пропуск на вступительные испытания
          </Button>
        )}
    </div>
  )
}

export default EntranceExamsTable
