import React from 'react'

import classNames from 'classnames'

type LoaderProps = {
  className?: string
}

const Loader = ({ className }: LoaderProps) => {
  return <div className={classNames('loader', className)} />
}

export default Loader
