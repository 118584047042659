import React from 'react'

import ModeratorPersonalInformationList from '../../../components/ModeratorPersonalInformationList'
import AccordionTab from '@components/AccordionTab'
import ModeratorPersonalInformationFilter from '@components/ModeratorPersonalInformationFilter'

const ModeratorPersonalInformationPage = () => {
  return (
    <main className="moderator-contract-page">
      <h1 className="moderator-contract-page__title">Анкеты</h1>
      <AccordionTab title={'Фильтры'}>
        <ModeratorPersonalInformationFilter />
      </AccordionTab>
      <ModeratorPersonalInformationList />
    </main>
  )
}

export default ModeratorPersonalInformationPage
