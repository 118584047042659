import $ from 'jquery'

const useChatResize = () => {
  const onRendered = () =>
    $('#rcw-conversation-container').ready(() => {
      const $container = $('#rcw-conversation-container')
      let startX, startWidth

      const resize = (e) => $container.width(startWidth - e.clientX + startX + 'px')

      const stopResize = () => {
        window.removeEventListener('mousemove', resize)
        window.removeEventListener('mouseup', stopResize)
      }

      const initResize = (e) => {
        startX = e.clientX
        if (document.defaultView) {
          startWidth = $('#rcw-conversation-container').width()
          window.addEventListener('mousemove', resize)
          window.addEventListener('mouseup', stopResize)
        }
      }

      $container
        .children()
        .first()
        .before('<div class="rcw-conversation-resizer" />')
      $('.rcw-conversation-resizer').on('mousedown', initResize)
    })

  return { onRendered }
}

export default useChatResize
