export const rotateBase64Image90deg = (
  base64Image: string,
  isClockwise = true,
  canvas?: HTMLCanvasElement
) => {
  if (!base64Image) return null
  const canvas_ = canvas ?? document.createElement('canvas')
  const ctx = canvas_.getContext('2d')
  if (!ctx) return

  const img = new Image()
  img.src = base64Image

  canvas_.height = img.width
  canvas_.width = img.height

  if (isClockwise) {
    ctx.rotate((90 * Math.PI) / 180)
    ctx.translate(0, -canvas_.width)
  } else {
    ctx.rotate((-90 * Math.PI) / 180)
    ctx.translate(-canvas_.height, 0)
  }
  ctx.drawImage(img, 0, 0)

  return canvas_.toDataURL('image/jpeg', 100)
}

export const getCroppedImg = async (
  crop: { width: number; height: number; x: number; y: number },
  canvas: HTMLCanvasElement,
  image: HTMLImageElement
): Promise<Blob | null> => {
  const ctx = canvas.getContext('2d')
  if (!ctx) return null
  if (!crop.width && !crop.height) {
    canvas.width = image.width
    canvas.height = image.height
    ctx.drawImage(image, 0, 0, image.width, image.height)
  } else {
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const pixelRatio = window.devicePixelRatio
    canvas.width = crop.width * pixelRatio * scaleX
    canvas.height = crop.height * pixelRatio * scaleY

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )
  }

  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob)
      },
      'image/jpeg',
      'high'
    )
  })
}
