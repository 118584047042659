// import React, { useEffect } from 'react'
// import { useParams } from 'react-router-dom'
//
// import {
//   DeleteInterceptor,
//   UserAdminInterceptor,
// } from '@utils/axios-user-admin-auth'
//
// const UserIdAdminAuth = ({ children }) => {
//   const { userId, statementId } = useParams()
//   let myInterceptor = 0
//   myInterceptor = UserAdminInterceptor(userId, statementId)
//
//   useEffect(() => {
//     return () => DeleteInterceptor(myInterceptor)
//   }, [])
//
//   return children
// }
//
// export default UserIdAdminAuth
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import {
  DeleteInterceptor,
  UserAdminInterceptor,
} from '@utils/axios-user-admin-auth'

const UserIdAdminAuth = ({ children }) => {
  const { userId, statementId } = useParams()
  if (statementId) {
    sessionStorage.setItem('statementId', statementId)
  }
  let myInterceptor = 0
  myInterceptor = UserAdminInterceptor(userId, statementId)

  useEffect(() => {
    return () => DeleteInterceptor(myInterceptor)
  }, [])

  return children
}

export default UserIdAdminAuth
