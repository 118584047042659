import React, { createContext, useCallback, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

type SubmitFormContextProps = {
  onSubmitForm: () => void
  HiddenButton: () => JSX.Element
}

const SubmitFormContext = createContext<SubmitFormContextProps>(
  {} as SubmitFormContextProps
)

export const SubmitFormContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const ref = useRef<HTMLButtonElement>(null)
  const navigate = useNavigate()

  const HiddenButton = useCallback(() => <button ref={ref} />, [])

  const onSubmitForm = () => {
    ref.current?.click()
  }

  return (
    <SubmitFormContext.Provider value={{ onSubmitForm, HiddenButton }}>
      {children}
    </SubmitFormContext.Provider>
  )
}

export default () => {
  return useContext(SubmitFormContext)
}
