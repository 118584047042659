import axios, { AxiosResponse } from 'axios'
import camelize from 'camelize'
import HttpStatusCode from 'common/httpStatusCodes'
import { PageDataView, RegistrationDataAll, UserCredentials } from 'models'
import { Email, ConfirmationCode } from 'types/shared-kernel'
import { Modify } from 'types/types'

/** @name AUTH */

export default {
  /** @method GET */
  getAuthPageData: (code) => {
    return axios.get<PageDataView>(`/view?${code}`)
  },

  /** @method GET */
  getCaptcha: () =>
    axios
      .get('/captcha', {
        params: {
          width: 300,
          bgColor: '#ffffff',
        },
      })
      .then((resp) => resp.data),

  /** @method POST */
  signin: (data: UserCredentials) =>
    axios.post<UserCredentials, SignInResponse>(`/login`, data).then((resp) => ({
      ...resp,
      data: camelize(resp.data) as SignInResponse['data'],
    })),

  //ломало сообщения об ошибках
  //.catch((err) => {
  //         throw {
  //           ...err.response,
  //           data: camelize(err.response.data) as SignInResponse['data'],
  //         }
  //       })

  /** @method POST */
  signup: (data: RegistrationDataAll) =>
    axios.post<RegistrationDataAll, SignUpResponse>(`/register`, data),

  /** @method POST */
  confirmAccount: (code: ConfirmationCode) =>
    axios.post<Email, ConfirmAccountResponse>(`/confirmAccount`, {
      CODE: code,
    }),

  /** @method POST */
  resetPassword: (email: Email) =>
    axios.post<Email, ResetPasswordResponse>(`/resetPassword`, { email }),
}

export type SignInRespUnpocessableEntity = Modify<
  AxiosResponse<{
    message: string
    errors: {
      email?: string[]
      password?: string[]
    }
  }>,
  { status: HttpStatusCode.UNPROCESSABLE_ENTITY }
>

export type SignInRespUnauthorized = Modify<
  AxiosResponse<{
    message: string
    success: false
    token: ''
  }>,
  { status: HttpStatusCode.UNAUTHORIZED }
>

export type SignInRespForbidden = Modify<
  AxiosResponse<{
    message: string
    success: false
    token: ''
  }>,
  { status: HttpStatusCode.FORBIDDEN }
>

export type SignInRespOk = Modify<
  AxiosResponse<{
    message: string
    success: true
    token: string
  }>,
  { status: HttpStatusCode.OK }
>

export type SignUpRespUnpocessableEntity = Modify<
  AxiosResponse<{
    message: string
    errors: {
      email?: string[]
      password?: string[]
    }
  }>,
  { status: HttpStatusCode.UNPROCESSABLE_ENTITY }
>

export type SignUpRespForbidden = Modify<
  AxiosResponse<{
    message: string
    success: false
  }>,
  { status: HttpStatusCode.FORBIDDEN }
>

export type SignUpRespOk = Modify<
  AxiosResponse<{
    message: string
    success: true
  }>,
  { status: HttpStatusCode.OK }
>

export type ResetPasswordRespNotFound = Modify<
  AxiosResponse<{
    success: true
    message: string
  }>,
  { status: HttpStatusCode.NOT_FOUND }
>

export type ResetPasswordRespOk = Modify<
  AxiosResponse<{
    code: string
    success: false
    message: string
  }>,
  { status: HttpStatusCode.OK }
>

export type ConfirmAccountRespNotFound = Modify<
  AxiosResponse<{
    success: true
    message: string
  }>,
  { status: HttpStatusCode.NOT_FOUND }
>

export type ConfirmAccountRespOk = Modify<
  AxiosResponse<{
    success: false
    message: string
  }>,
  { status: HttpStatusCode.OK }
>

export type SignUpResponse =
  | SignUpRespUnpocessableEntity
  | SignUpRespForbidden
  | SignUpRespOk

export type ConfirmAccountResponse =
  | ConfirmAccountRespOk
  | ConfirmAccountRespNotFound

export type ResetPasswordResponse = ResetPasswordRespNotFound | ResetPasswordRespOk

export type SignInResponse =
  | SignInRespUnpocessableEntity
  | SignInRespForbidden
  | SignInRespOk
  | SignInRespUnauthorized
