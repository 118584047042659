import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { getDirectionsListOptions } from '../redux/action-types'
import {
  selectDirectionListOptions,
  selectStatementsElement,
  selectUserConsentToEnrollmentHistory,
} from '../redux/actions/selectors'
import useAppDispatch from './useAppDispatch'
import useAppSelector from './useAppSelector'
import { FilterContract } from '@models'
import api from '@services/api'

const useDirectionTrainingModeration = ({ statement }: { statement?: any }) => {
  const { register, control, watch, getValues, setValue } = useForm<{
    optionId: any
  }>({
    mode: 'all',
  })
  const dispatch = useAppDispatch()
  const directionOptions = useAppSelector(selectDirectionListOptions)
  const [options, setOption] = useState<any>([])

  const history = useAppSelector(selectUserConsentToEnrollmentHistory)

  useEffect(() => {
    if (statement && directionOptions.length === 0) {
      dispatch(getDirectionsListOptions(String(statement.id)))
    }
  }, [statement])

  useEffect(() => {
    if (directionOptions.length > 0) {
      setOption(
        directionOptions.map((i) => ({
          label: `${i.label} - ${i.formEducation}`,
          value: i.id,
          id: i.groupId,
        }))
      )
    }
  }, [directionOptions])

  useEffect(() => {
    if (history[0]) {
      setValue(
        'optionId',
        options.find((option) => option.id === history[0].directionTraining.id)
      )
    }
  }, [])

  const fields = {
    optionId: register('optionId'),
  }

  return {
    directionOptions,
    fields,
    control,
    options,
    watch,
    getValues,
  }
}

export default useDirectionTrainingModeration
