import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ModeratorDataList from '@components/ModeratorDataList'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { DataList } from '@models'
import { getModeratorStatementsAddressData } from '@redux/action-types'
import { selectModeratorStatementsAddressData } from '@redux/actions/selectors'

const ModeratorActualAddressPage = () => {
  const dispatch = useAppDispatch()

  const { id, userId } = useParams<{ id?: string; userId?: string }>()
  useEffect(() => {
    if (id || userId) {
      dispatch(getModeratorStatementsAddressData({ id: id, userId: userId }))
    }
  }, [id, userId])

  const address = useAppSelector(selectModeratorStatementsAddressData) || {}
  const list: DataList = [
    {
      title: 'Страна:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'actual')?.nationality
            ?.nationalityTranslate[0]?.name) ||
        '-',
    },
    {
      title: 'Населенный пункт:',
      label: (address?.address?.length && address?.address[0]?.city) || '-',
    },
    {
      title: 'Улица:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'actual')?.street) ||
        '-',
    },
    {
      title: 'Дом:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'actual')?.house) ||
        '-',
    },
    {
      title: 'Корпус:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'actual')?.building) ||
        '-',
    },
    {
      title: 'Квартира:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'actual')?.apartment) ||
        '-',
    },
    {
      title: 'Индекс:',
      label:
        (address?.address?.length &&
          address?.address?.find((el) => el.typeAddress === 'actual')?.postcode) ||
        '-',
    },
    {
      title: 'Необходимость в общежитии на время учебы:',
      label: (address && (address.needHostel ? 'Да' : 'Нет')) || '-',
    },
  ]
  return (
    <div>
      <ModeratorDataList list={list} />
    </div>
  )
}

export default ModeratorActualAddressPage
